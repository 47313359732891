export const ON_FEEDBACK_CLICK = 'ON_FEEDBACK_CLICK';
export const FEEDBACK_SEND = 'FEEDBACK_SEND';

export const onFeedbackClick = () => {
  return {
    type: ON_FEEDBACK_CLICK
  }
};

export const feedbackSend = (feedbackContent) => {
  return {
    url: window.__env.apiUrl + `/feedback`,
    type: FEEDBACK_SEND,
    feedbackContent
  }
};
