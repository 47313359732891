import _ from 'lodash';
import api from '../../components/myMeetings/utils/api';
import { setHeroProfileErrors, setHeroProfileIsLoading } from '../heroProfile';
import { fetchProfile } from '../accountActions';

/*
 * Async Actions
 */
export const saveHeroProfile = heroProfile => dispatch => {
  dispatch(setHeroProfileIsLoading(true));
  return new Promise((resolve, reject) => {
    api
      .post('/whitelabel/profile/store', heroProfile)
      .then(() => {
        dispatch(fetchProfile());
        resolve();
      })
      .catch(({ response }) => {
        dispatch(setHeroProfileErrors(_.get(response, 'data.errors')));
        reject();
      })
      .finally(() => {
        dispatch(setHeroProfileIsLoading(false));
      });
  });
};
