import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import Uploads from './components/uploads';
import CommunityInfo from './components/info';
import CommunityPageHeader from './components/communityPage-header';
import CreatePostForm from './components/create-post';
import InfoToast from './common/info-toast/InfoToast';
import PostCard from './components/community-post';
import LoadingGif from '../../assets/images/loader.gif';
import blockIncompleteProfileAction from '../../utils/blockIncompleteProfileAction';

class Community extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPostModal: false,
    };
    this.setPostModalVisibility = this.setPostModalVisibility.bind(this);
    this.displayPostsModal = this.displayPostsModal.bind(this);
  }

  componentDidMount() {
    const { match, fetchCommunity, location, history, showCommunitiesPageToast } = this.props;
    const urlSlug = _.get(match.params, 'slug', '');
    const queryParams = _.get(location, 'search');

    fetchCommunity(urlSlug + queryParams)
      .then()
      .catch(response => {
        if (response === 'incompleteProfile') {
          blockIncompleteProfileAction().then(() => {
            history.push({ pathname: '/account/profile/incomplete', fromUrl: location.pathname + queryParams });
          });
        } else if (response === 'communityNotFound') {
          showCommunitiesPageToast('toast.nonExistingCommunity');
        } else if (response === 'nonExistingMember') {
          showCommunitiesPageToast('toast.notCommunityMember');
        } else if (response === 'profileIncompleteMember') {
          showCommunitiesPageToast('toast.profileIncompleteMember');
        }
        history.replace({
          pathname: '/communities',
        });
      });
  }

  setPostModalVisibility() {
    const { showPostModal } = this.state;
    this.setState({ showPostModal: !showPostModal });
  }

  displayPostsModal() {
    const { heroIsCommunityMember, showCommunityPageToast } = this.props;
    if (!heroIsCommunityMember) {
      return showCommunityPageToast('toast.onlyMemberCanPost');
    }
    this.setState({ showPostModal: true });
  }

  renderCreatePostModal() {
    const { translations } = this.props;
    return <CreatePostForm translations={translations} closeModal={this.setPostModalVisibility} />;
  }

  render() {
    const { history, translations, isLoading, communityToastMessage, match, heroIsCommunityMember } = this.props;
    const { showPostModal } = this.state;
    const createPostModal = showPostModal ? this.renderCreatePostModal() : null;

    if (isLoading) {
      return (
        <div className="community-loader__container">
          <div className="community-loader__text">{_.get(translations, 'communityPage.loadingCommunity', 'Loading your community...')}</div>
          <img className="community-loader__image" src={LoadingGif} alt="Loading" />
        </div>
      );
    }
    return (
      <Scrollbars>
        {communityToastMessage && <InfoToast message={_.get(translations, communityToastMessage, communityToastMessage)} />}

        <div className="community__container">
          <div className="community-grid">
            <div className="community-grid__column community-grid__column--left background-left">
              <button
                type="button"
                onClick={() => history.push('/communities')}
                className="navigation__back-to-button community-page__back-to-button"
              >
                {translations.backToCommunities}
              </button>
              <div className="create-new-post-main-container">
                <div>
                  <CommunityPageHeader action={this.displayPostsModal} />
                  {createPostModal}
                </div>
                <PostCard translations={translations} action={this.displayPostsModal} />
              </div>
            </div>
            <div className="community-grid__column community-grid__column--right">
              <CommunityInfo translations={translations} />
              {heroIsCommunityMember && <Uploads translations={translations} displayModalAction={this.displayPostsModal} />}
            </div>
          </div>
        </div>
      </Scrollbars>
    );
  }
}

export default withRouter(Community);
