import {fetchPermissions, fetchProfile, savePermission} from "../../actions/accountActions";
import {connect} from "react-redux";
import GeneralSettings from './../../components/generalSettings/generalSettings';


const mapStateToProps = state => ({
    profileDetails: state.account.get('profile'),
    translations: state.translations,
    permissions: state.account.get('permissions')
});

const mapDispatchProps = dispatch => ({
    fetchProfile: () => dispatch(fetchProfile()),
    fetchPermissions: () => dispatch(fetchPermissions()),
    savePermission: (item) => dispatch(savePermission(item)),
});

const GeneralSettingsView = connect(mapStateToProps, mapDispatchProps)(GeneralSettings);

export default GeneralSettingsView;
