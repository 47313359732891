import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

class OfficeCancelation extends React.Component {

  renderConditions = (policy) => {

    return (
        <div className="cancelation-table">
            <table width="100%">
                <thead>
                <tr>
                    <th>{this.props.trans.location.cancelationTable.bookingDuration}</th>
                    {policy.policy_intervals.map((interval, index) => {
                        return <th key={index}>{(!interval.hidden) ?  _.get(interval, 'main_label.label', '') : ''}</th>
                    })}
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>{policy.officetype_name}</td>
                    {policy.policy_intervals.map((interval, index) => {
                        return <td key={index}>
                            <p>
                                {(!interval.hidden) ? _.get(interval, 'after_label.label', '') : ''}
                            </p>
                        </td>
                    })}
                </tr>
                <tr>
                    <td className="small-td">{this.props.trans.location.cancelationTable.penalty}</td>
                    {policy.policy_intervals.map((interval, index) => {
                        return <td key={index} className="small-td">{(!interval.hidden) ? interval.after_penalty_value + '%' : ""} </td>
                    })}
                </tr>
                </tbody>
            </table>
        </div>
    );
  }

  renderPolicies = () => {
    let {policies} = this.props;

    if(!policies || policies.length === 0) {
      return null;
    }

    return (
      <div>
        {Object.keys(policies).map((key, index) => {
          return <div key={`pC_${index}`}>
            {this.renderConditions(policies[key])}
          </div>
        })}
      </div>
    )
  }

  render() {
    const { office, bookedStatus } = this.props;
    const classCancelation = (!bookedStatus) ? 'office-cancelation' : 'office-cancelation office-cancelation-location-booked';
    try {
      if(window.__env.InterOfficesId.split(',').map(item => parseInt(item, 10)).indexOf(office.id) > -1)
      {
          return (
              <div className={ classCancelation } id='office-cancellation-policy'>
                  <div className="office-cancelation-title">{this.props.trans.location.cancelationPolicy}</div>
                  <p className="office-cancelation-disclaimer">{this.props.trans.location.disclaimer.first}<br /><br />{this.props.trans.location.disclaimer.second}</p>
              </div>
          );
      }
    } catch(e) {}
    return (
      <div className={ classCancelation } id='office-cancellation-policy'>
          <div className="office-cancelation
      </div>
    )-title">{this.props.trans.location.cancelationPolicy}</div>
          <p className="office-cancelation-disclaimer">{this.props.trans.location.disclaimer.first}<br /><br />{this.props.trans.location.disclaimer.second}</p>
          { this.renderPolicies()}
      </div>
    )
  }
}

OfficeCancelation.propTypes = {
  policy: PropTypes.array.isRequired,
};

OfficeCancelation.defaultProps = {
  policy: []
};

export default OfficeCancelation;
