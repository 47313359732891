import React from 'react';
import { injectStripe } from 'react-stripe-elements';

class CheckoutForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cardErrors: null
        };
    }

    componentDidMount() {
        this.props.stripe.confirmPaymentIntent(this.props.intent, {
            return_url: window.__env.apiUrl+'/stripe-approve'
        }).then(function (result) {
            window.location.href = result.paymentIntent.next_action.redirect_to_url.url;
        });
    }
    render () {
        return null;
    }
}

export default injectStripe(CheckoutForm);