import React, { Component } from 'react';
import onClickOutside from 'react-onclickoutside';
import { withRouter } from 'react-router-dom';
import '../../header/css/search.scss';
import { Scrollbars } from 'react-custom-scrollbars';
import _ from 'lodash';
import defaultAvatar from '../../../assets/images/defaultAvatar.png';
import PropTypes from 'prop-types';
import addNewHeroIcon from '../../../assets/svg/add_new.svg'
import OutsideClickHandler from 'react-outside-click-handler';
import classnames from "classnames";
import searchLoader from "../../../assets/svg/search-loader.svg";

class Search extends Component {
  context;

  static contextTypes = {
    router: PropTypes.object,
  };

  constructor(props){
    super(props);

    this.state = {
      typing: false,
      results: null,
      query: '',
      selectedResultIndex: 0,
      globalSearchSelectedLi: null
    }
  }

  handleChange =  (e) => {
    let text = e.target.value;
    this.setState({
      query: text
    }, this.delaySearch());
  }

  handleGlobalSearchLiSelectionChange = (index) => {
    let elementKey = "liElement" + this.state.selectedResultIndex;
    let selectedLi = this.refs[elementKey];
    let oneElementHeight = 0;
    if(selectedLi !== undefined)
    {
      oneElementHeight = selectedLi.offsetHeight;
    }
    if(this.refs[elementKey] != null){
      this.setState({globalSearchSelectedLi: selectedLi});
    }
    if(typeof selectedLi !== "undefined"){

      let elementStartsAtPosition = oneElementHeight * index;
      let scrollbarValues = this.refs.scrollbar.getValues();
      if(elementStartsAtPosition + oneElementHeight  > scrollbarValues.scrollTop + this.refs.scrollbar.getClientHeight()){
        let scrollingValue = oneElementHeight - ((scrollbarValues.scrollTop + this.refs.scrollbar.getClientHeight()) % oneElementHeight);
        if(scrollingValue === 0){
          scrollingValue = oneElementHeight;
        }
        this.refs.scrollbar.scrollTop(scrollingValue + scrollbarValues.scrollTop);
      }

      if(elementStartsAtPosition < scrollbarValues.scrollTop){
        let scrollingValue = 0;
        if(elementStartsAtPosition !== 0){
          let scrollingDifference = scrollbarValues.scrollTop % oneElementHeight;
          if(scrollingDifference === 0){
            scrollingDifference = oneElementHeight;
          }
          scrollingValue = scrollbarValues.scrollTop - scrollingDifference;
        }
        this.refs.scrollbar.scrollTop(scrollingValue);
      }
    }
  }

  handleResultMouseOver = (index) => {
    this.setState({selectedResultIndex: index});
    this.handleGlobalSearchLiSelectionChange(index);
  }


  handleEnterOnSelectedOptionForSearch = () => {
    if(this.state.globalSearchSelectedLi != null){
      this.state.globalSearchSelectedLi.click();
    }
  }

  handleKeyUp =  (e) => {
    if(e.nativeEvent.keyCode === 13){//ENTER
      this.handleEnterOnSelectedOptionForSearch();
    }
    if(e.nativeEvent.keyCode === 38 || e.nativeEvent.keyCode === 40)
    {
      if(this.props.globalSearchResults !== null)
      {
        let maxResultIndex = -1;
        if(this.props.globalSearchResults !== null)
        {
          if(this.props.globalSearchResults.q !== undefined)
          {
            maxResultIndex = -1;
          }

          if(this.props.globalSearchResults !== undefined && this.props.globalSearchResults.length > 0)
          {
            maxResultIndex += this.props.globalSearchResults.length;
          }
        }

        let nextIndex = this.state.selectedResultIndex;
        if(this.state.selectedResultIndex > 0 && e.nativeEvent.keyCode === 38) // UP
        {
          nextIndex = nextIndex - 1;
        }
        if(this.state.selectedResultIndex < maxResultIndex && e.nativeEvent.keyCode === 40) // DOWN
        {
          nextIndex = nextIndex + 1;
        }
        this.setState({selectedResultIndex : nextIndex}, () => {
          this.handleGlobalSearchLiSelectionChange(nextIndex);
        });
      }
    }

    let state = ((this.state.query.length !== 0 && e.nativeEvent.keyCode !== 27 ) ? true : false);
    if(state !== this.state.typing)
    {
      this.setState({
        typing: state
      });
    }
  }
 
  delaySearch = _.debounce(function(e){
    this.props.globalSearchTrigger();
    this.props.globalSearch(this.state.query, true);
  }, 300);

  componentDidUpdate = () => {
    if(this.state.globalSearchSelectedLi === null && this.props.globalSearchResults !== null)
    {
      this.handleGlobalSearchLiSelectionChange(0);
    }
  }

  handleClickOutside = evt => {
    this.setState({
      typing: false,
      query: ''
    });
  }

  handleFocus = e => {
    e.preventDefault();
    let state = ((this.state.query !== 0) ? true : false);
    this.setState({
      typing: state
    });
  }

  handleClick = result => {
    this.setState({
      typing: false,
      results: null,
      query: '',
      selectedResultIndex: 0,
      globalSearchSelectedLi: null
    });
    this.props.handleClick(result);

  }

  renderResults() {

    let results = [];
    if(this.props.globalSearching === true)
    {
      results.push(<li className="liSearching"  key="searching">{this.props.trans.header.searching} <img alt="" src={searchLoader} /></li>);
    }
      if(this.props.globalSearchResults !== null && this.props.globalSearchResults.length > 0)
      {
        this.props.globalSearchResults.map((result) => {
          let currentIndex = results.length;
          results.push(
            <li
              ref={"liElement" + currentIndex} onMouseOver={() => {this.handleResultMouseOver(currentIndex);}}
              className={this.state.selectedResultIndex === results.length ? 'selectedResult heroResult' : 'heroResult'}
              key={result.id+'h'}
              onClick={() => {this.handleClick(result)}}
            >
              {result.imageUrl ? (
                <img alt="" className='hero-results__profilePicture' src={result.imageUrl !== null ? result.imageUrl : defaultAvatar} />
              ) : (
                <div className='hero-initials search-hero-initials'>{result.heroInitials}</div>
              )}
              <div className="hero-results__details">
                <span className="hero-results__name">{result.firstName} {result.lastName}</span>
                <span className="hero-results__headline" title={result.headline}>{result.headline}</span>
              </div>
            </li>
          );
          return null;
        });
      }
    return (results);
  }

  render () {
    const typed = (this.state.typing ? ' search-typed' : '');
    const {isInternalHero} = this.props;
    let placeHolder = _.get(this.props, 'placeholder', null);
    const { query } = this.state;
    let noResults = 0;
    if(this.props.globalSearchResults !== null)
    {
      if(this.props.globalSearchResults !== undefined && this.props.globalSearchResults.length > 0)
      {
        this.props.globalSearchResults.map((result) => {
          noResults++;
          return null;
        });
      }
    }
    let searchBarResultsHeight = 0;
    if(this.props.globalSearching === true)
    {
      searchBarResultsHeight = '46px';
    }
    else
    {
      if(noResults >= 3)
      {
        searchBarResultsHeight = '186px';
      }
      else
      {
        if(noResults !== 0)
        {
          searchBarResultsHeight = 'calc(' + noResults * 63 + 'px)';
        } else if(!isInternalHero){
          searchBarResultsHeight = '57px';
        }
      }
    }
    return (
      <OutsideClickHandler onOutsideClick={() => {
        this.handleClickOutside();
      }}>
        <div className={classnames(this.props.className, typed)}>
          {this.props.isWhiteLabel ? (<span className="search-title">Search for collegues in the building</span>) : null}
          <input
            disabled={this.props.disabled}
            onClick={() => { this.setState({typing: true}, ()=>{this.nameInput.focus();}); this.props.globalSearch('')}}
            className="search-input"
            type="text"
            placeholder={placeHolder}
            onChange={this.handleChange}
            onKeyUp={this.handleKeyUp}
            value={this.state.query}
            onFocus={()=>{  }}
            onBlur={() => { if(this.state.typing === true){ this.nameInput.focus(); }}}
            ref={(input) => { this.nameInput = input; }}
          />
          <div className={classnames("search-results", (query.length ? 'hasSearch' : ''))}  style={{ height: searchBarResultsHeight }}>
            <Scrollbars
              ref={"scrollbar"}
            >
              <ul>
                { this.renderResults() }
                {!isInternalHero ? (
                <li className="heroResult" onClick={() => {this.props.addNewHero(query);}}>
                  <img src={addNewHeroIcon} alt="" className='add-new__hero-results__profilePicture'/>
                  <div className="hero-results__details">
                    <span className="hero-results__name"> {query}</span>
                    <span className="hero-results__headline">Enter name to invite</span>
                  </div>
                </li>) : null
                }
              </ul>
            </Scrollbars>
          </div>
        </div>
      </OutsideClickHandler>
    );
  }
};

export default withRouter(Search);
