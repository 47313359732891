import api from '../../components/myMeetings/utils/api';
import { voucherOffsetSelector } from '../../selectors/accountPaymentSettings/vouchers';

export const SET_LOADER = 'SET_LOADER';
export const SET_VOUCHERS = 'SET_VOUCHERS';
export const INCREMENT_VOUCHERS_OFFSET = 'INCREMENT_VOUCHERS_OFFSET';
export const SET_HAS_MORE = 'SET_HAS_MORE';

export const setLoader = isLoading => ({
  type: SET_LOADER,
  isLoading,
});
export const setVouchers = vouchers => ({
  type: SET_VOUCHERS,
  vouchers,
});
export const incrementVouchersOffset = () => ({
  type: INCREMENT_VOUCHERS_OFFSET,
});
export const setHasMore = hasMore => ({
  type: SET_HAS_MORE,
  hasMore,
});

export const fetchVoucherHistory = () => (dispatch, getState) => {
  const offset = voucherOffsetSelector(getState());
  return new Promise((resolve, reject) => {
    dispatch(setLoader(true));

    api
      .get(`/hero/vouchers/history/page/${offset}`)
      .then(({ data }) => {
        if (data) {
          dispatch(setVouchers(data.data));
          dispatch(setHasMore(data.hasMore));
          dispatch(incrementVouchersOffset());
          resolve();
        }
      })
      .catch(() => {
        reject();
      })
      .finally(() => {
        dispatch(setLoader(false));
      });
  });
};

export const validateVoucher = code => dispatch => {
  return new Promise((resolve, reject) => {
    api
      .get(`/hero/vouchers/validate/${code}`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({response}) => {
        reject(response.data.error);
      })
      .finally(() => {});
  });
};
