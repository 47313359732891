import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import classnames from 'classnames';
import { withRouter } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars';
import MemberCard from './member-card';
import CommunitySearch from '../../common/community-search';

const noResults = noResultText => {
  return <div className="manage-community__label manage-community__label--regular">{noResultText}</div>;
};

class ManageCommunityModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 'members',
      searchString: '',
    };
    this.buildModalTabs.bind(this);
    this.setSearchString.bind(this);
  }

  componentDidMount() {
    const { fetchCommunityMembers, fetchCommunityInvitations, communityAudienceType } = this.props;
    fetchCommunityMembers();
    if (communityAudienceType === 'closed') {
      fetchCommunityInvitations();
    }
  }

  setSearchString(searchString) {
    this.setState({ searchString });
  }

  buildModalTabs() {
    const { translations, communityAudienceType } = this.props;
    const { activeTab } = this.state;
    return (
      <div className="manage-community__tabs">
        <button
          type="button"
          onClick={() => {
            this.setState({ activeTab: 'members' });
          }}
          id="manage-community__members"
          className={classnames('manage-community__tab', { 'manage-community__tab--active': activeTab === 'members' })}
        >
          {_.toUpper(_.get(translations, 'manageCommunityModal.members', 'manageCommunityModal.members'))}
        </button>
        {communityAudienceType === 'closed' ? (
          <button
            type="button"
            onClick={() => {
              this.setState({ activeTab: 'invited' });
            }}
            id="manage-community__invited"
            className={classnames('manage-community__tab', { 'manage-community__tab--active': activeTab === 'invited' })}
          >
            {_.toUpper(_.get(translations, 'manageCommunityModal.invited', 'manageCommunityModal.invited'))}
          </button>
        ) : null}

        <button
          type="button"
          onClick={() => {
            this.setState({ activeTab: 'blocked' });
          }}
          id="manage-community__blocked"
          className={classnames('manage-community__tab', { 'manage-community__tab--active': activeTab === 'blocked' })}
        >
          {_.toUpper(_.get(translations, 'manageCommunityModal.blocked', 'manageCommunityModal.blocked'))}
        </button>
      </div>
    );
  }

  render() {
    const {
      translations,
      admins,
      activeMembers,
      blockedMembers,
      communityInvitations,
      heroProfile,
      makeHeroAdmin,
      removeHeroFromCommunity,
      blockHeroFromCommunity,
      unblockMember,
      resendInvitation,
      cancelInvitation,
      isLoading,
    } = this.props;
    const { activeTab, searchString } = this.state;
    const authId = _.get(heroProfile, 'id', null);
    const activeMembersCount = activeMembers.length;
    const membersCount = admins.length + activeMembersCount;
    let filteredAdmins = [...admins];
    let filteredMembers = [...activeMembers];
    let filteredBlocked = [...blockedMembers];
    let filteredInvitations = [...communityInvitations];

    if (searchString) {
      filteredAdmins = admins.filter(admin => _.toLower(admin.name).includes(_.toLower(searchString)));
      filteredMembers = activeMembers.filter(admin => _.toLower(admin.name).includes(_.toLower(searchString)));
      filteredBlocked = blockedMembers.filter(admin => _.toLower(admin.name).includes(_.toLower(searchString)));
      filteredInvitations = communityInvitations.filter(admin => _.toLower(admin.name).includes(_.toLower(searchString)));
    }

    const noResultText = _.get(translations, 'inviteMembersModal.noResult', 'inviteMembersModal.noResult');

    return (
      <div className="community-form manage-community__form">
        <div className="manage-community__tabs-containers">{this.buildModalTabs()}</div>
        <div className="manage-community__grey-container">
          <CommunitySearch
            searchCallback={event => {
              this.setSearchString(event.target.value);
            }}
          />
          <div className="manage-community__heroes">
            {isLoading ? (
              <div className="manage-community__loading-message">
                {_.get(translations, 'manageCommunityModal.loadingMessage', 'manageCommunityModal.loadingMessage')}
              </div>
            ) : (
              <Scrollbars className="styleScroll">
                {activeTab === 'members' ? (
                  <>
                    <div className="field-label manage-community__label">
                      {_.get(translations, 'manageCommunityModal.admins', 'manageCommunityForm.admins')}
                    </div>
                    {filteredAdmins.length
                      ? filteredAdmins.map(admin => (
                          <MemberCard
                            key={`admin${admin.id}`}
                            member={admin}
                            memberType="active"
                            authId={authId}
                            translations={translations}
                          />
                        ))
                      : noResults(noResultText)}

                    <div className="field-label manage-community__label manage-community__label--margin-top">
                      {_.get(translations, 'manageCommunityModal.members', 'manageCommunityModal.members')}
                    </div>
                    {activeMembers.length
                      ? filteredMembers.length
                        ? filteredMembers.map((activeMember, index) => (
                            <MemberCard
                              key={`activeMember${activeMember.id}`}
                              member={activeMember}
                              memberType="active"
                              translations={translations}
                              makeHeroAdmin={makeHeroAdmin}
                              removeHeroFromCommunity={removeHeroFromCommunity}
                              blockHeroFromCommunity={blockHeroFromCommunity}
                              isLast={index > activeMembersCount - 3 && membersCount >= 4}
                            />
                          ))
                        : noResults(noResultText)
                      : null}
                  </>
                ) : null}
                {activeTab === 'blocked' ? (
                  <>
                    {blockedMembers.length
                      ? filteredBlocked.length
                        ? filteredBlocked.map(blockedMember => (
                            <MemberCard
                              key={`blockedMember${blockedMember.id}`}
                              member={blockedMember}
                              memberType="blocked"
                              translations={translations}
                              unblockMember={unblockMember}
                            />
                          ))
                        : noResults(noResultText)
                      : null}
                  </>
                ) : null}
                {activeTab === 'invited' ? (
                  <>
                    {communityInvitations.length
                      ? filteredInvitations.length
                        ? filteredInvitations.map(invitation => (
                            <MemberCard
                              key={`invitation${invitation.invitationId}`}
                              member={invitation}
                              memberType="invited"
                              translations={translations}
                              resendInvitation={resendInvitation}
                              cancelInvitation={cancelInvitation}
                            />
                          ))
                        : noResults(noResultText)
                      : null}
                  </>
                ) : null}
              </Scrollbars>
            )}
          </div>
        </div>
      </div>
    );
  }
}

ManageCommunityModal.propTypes = {
  translations: PropTypes.shape({}).isRequired,
  community: PropTypes.shape({}).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  admins: PropTypes.arrayOf(PropTypes.shape).isRequired,
  activeMembers: PropTypes.arrayOf(PropTypes.shape).isRequired,
  blockedMembers: PropTypes.arrayOf(PropTypes.shape).isRequired,
  communityInvitations: PropTypes.arrayOf(PropTypes.shape).isRequired,
  heroProfile: PropTypes.shape({}).isRequired,
  communityAudienceType: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  makeHeroAdmin: PropTypes.func.isRequired,
  removeHeroFromCommunity: PropTypes.func.isRequired,
  blockHeroFromCommunity: PropTypes.func.isRequired,
  unblockMember: PropTypes.func.isRequired,
  resendInvitation: PropTypes.func.isRequired,
  cancelInvitation: PropTypes.func.isRequired,
  fetchCommunityMembers: PropTypes.func.isRequired,
  fetchCommunityInvitations: PropTypes.func.isRequired,
};

ManageCommunityModal.defaultProps = {};

export default withRouter(ManageCommunityModal);
