import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars';
import { StripeProvider, Elements } from 'react-stripe-elements';
import ModalDialog from '../modalDialog/modalDialog';
import classnames from 'classnames';
import ReactTooltip from 'react-tooltip';
import _ from 'lodash';
import {
  setSelectedRoom,
  setSelectedProduct,
  setSelectedOptions,
  setSelectedServices,
  clearBookingErrors,
  setEventRequestDetails,
  onChangeSection,
  setUseSpendingLimit,
} from './../../actions/locationActions';
import './css/index.scss';
import {  withRouter} from 'react-router-dom';
import CheckoutForm from './CheckoutForm'
import CheckoutFormBancontact from "./CheckoutFormBancontact";
import PaymentProfile from "./newPaymentProfile";

let lang = (localStorage.getItem('lang') !== null && localStorage.getItem('lang') !== 'default') ? localStorage.getItem('lang') : 'en';

class AddBokingPayment extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      paymentInProgress: false,
      paymentProfileErrors: null,
      profileUpdating: false,
      requestModalOpened: false,
      selectedPaymentProfileId: null,
      validatingData: false,
      voucherApplied: false,
      voucherCode: "",
      showVoucherLoader: false,
      errorsShown: false,
      scheduleVisitDate: null, // schedule
      scheduleVisitDateMoment: null,
      scheduleVisitHour: null, // schedule
      sidePopupPage: 'initial', // side Popup
      contactLocation: '', // contact
      contactPeriod: '',  // contact
      contactOfficeSize: '', // contact
      contactNoOfPeople: '', // contact
      contactParking: true, // contact
      contactDetails: '', // contact
      paymentProfileAddress: null,
      paymentProfilePostcode: null,
      paymentProfileCity: null,
      paymentProfileCountry: null,
      paymentProfileCompany: null,
      paymentProfileVat: null,
    };
    this.setEventRequestDetails = this.setEventRequestDetails.bind(this);
    this.modifyPaymentMethod = this.modifyPaymentMethod.bind(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { useSpendingLimit: oldSpendingLimit } = prevProps;
    const { useSpendingLimit } = this.props;
    if (oldSpendingLimit !== useSpendingLimit) {
      this._fetchBookingValidate();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.bookingRequestResponse === null && nextProps.bookingRequestResponse !== null) {
      switch (this.state.sidePopupPage) {
        case 'schedule-visit':
          this.setState({ sidePopupPage: 'schedule-visit-confirmation' });
          break;
        case 'contact':
          this.setState({ sidePopupPage: 'contact-confirmation' });
          break;
        default:
          break;
      }
    }

    if (nextProps.voucherInfo !== null && nextProps.voucherInfo.toJS().errors !== undefined) {
      this.setState({ voucherCode: "" });
    }

    this.delayHideVoucherLoader();

    if (nextProps.bookingResponse !== null && nextProps.bookingResponse.eventRoomRequestSent !== undefined && nextProps.bookingResponse.eventRoomRequestSent === true) {
      this.setState({ requestModalOpened: true });
    }

    if (this.state.voucherApplied === false && nextProps.voucherInfo !== null) {
      if (nextProps.voucherInfo.toJS().valid === true) {
        this.setState({
          voucherApplied: true
        }, () => {
          this._fetchBookingValidate();
        });
      }
    }

    if (nextProps.bookingErrors !== null && this.state.paymentInProgress === true) {
      this.setState({ paymentInProgress: false, errorsShown: true });
    }

    if (this.state.voucherApplied === true && nextProps.voucherInfo !== null) {
      if (nextProps.voucherInfo.toJS().valid === false) {
        this.setState({
          voucherCode: "",
          voucherApplied: false
        }, () => {
          this._fetchBookingValidate();
        });
      }
    }

    if (nextProps.paymentProfileErrors !== null) {
      this.setState({
        connectWithStripeFormActive: true,
        newCreditCardAdded: false,
        validatingData: false,
        paymentProfileErrors: nextProps.paymentProfileErrors
      });
    }
  }

  onFinishBooking = (args) => {
    this.setState({
      paymentInProgress: true,
    });
    this.props.onFinishBooking(args);
  }

  _fetchBookingValidate() {
    if (this.props.selectedRoom !== null && this.props.selectedRoom.type === this.props.selectedProduct.type) {
      let services = [];
      let equipments = [];
      let voucherCode = null;

      if (this.props.voucherInfo !== null && this.props.voucherInfo.toJS().valid === true) {
        voucherCode = this.props.voucherInfo.toJS().code;
      }

      if (this.props.selectedServices.length > 0) {
        this.props.selectedServices.map((selectedService) => {
          if (selectedService.includes('service')) {
            let [serviceId] = selectedService.split('service');
            services.push(parseInt(serviceId, 10));
          }
          if (selectedService.includes('equipment')) {
            let [equipmentId] = selectedService.split('equipment');
            equipments.push(parseInt(equipmentId, 10));
          } return null;
        });
      }

      let args = {
        officeId: this.props.officeLocation.id,
        officeTypeId: this.props.selectedRoom !== null ? this.props.selectedRoom.id : null,
        productId: this.props.selectedRoom !== null ? this.props.selectedProduct.id : null,
        fromDate: this.props.selectedOptions.startDate,
        toDate: this.props.selectedOptions.endDate,
        weekDays: this.props.selectedOptions.selectedWeekDays,
        hoursFrom: this.props.selectedOptions.hoursFrom,
        hoursUntil: this.props.selectedOptions.hoursUntil,
        servicesIds: services,
        equipmentIds: equipments,
        voucherCode: voucherCode,
        paymentProfileId: _.get(this.props, 'selectedPaymentMethod.payment_profile_id', null),
        useSpendingLimit: this.props.useSpendingLimit,
      };
      this.props.fetchBookingValidate(args);
    }
  }

  handleUseSpendingLimitChange = () => {
    const { useSpendingLimit, setUseSpendingLimit } = this.props;
    setUseSpendingLimit(!useSpendingLimit);
  }

  handleVoucherCodeChange = (value) => {
    this.setState({ voucherCode: value });
  }

  redeemCode = () => {
    if (this.state.voucherCode !== '') {
      this.setState({ showVoucherLoader: true }, () => {
        let args = {};
        let bookingValidate = this.props.bookingValidate.toJS();
        args.officeTypeId = this.props.selectedRoom !== null ? this.props.selectedRoom.id : null;
        args.productId = bookingValidate.productId;
        args.totalPriceVatIncluded = bookingValidate.price.totalPriceVatIncluded ? bookingValidate.price.totalPriceVatIncluded : bookingValidate.price.subtotal;
        this.props.redeemVoucher(this.state.voucherCode, args);
      });
    }
  }

  removeCoupon = () => {
    this.props.redeemVoucher(null);
    this.setState({
      voucherCode: "",
      voucherApplied: false
    }, () => {
      this._fetchBookingValidate();
    });
    this.props.onVoucherDelete();
  }


  renderBooking = () => {
    return (
        <div className="booking-footer-item">
          <span className="item-label subTotalStyle">{_.get(this.props.trans, 'booking.payment.booking', '')}</span>
          <span className="item-value subTotalStyle">{this.props.bookingValidate.toJS().price.subtotalVoucherApplied.toFixed(2).toString().replace(".", window.__env.decimalSeparator)}€</span>
        </div>
    );
  }

  renderSubtotal = () => {
    return (
        <div className="booking-footer-item">
          <span className="item-label subTotalStyle">{_.get(this.props.trans, 'booking.payment.subtotal', '')}</span>
          <span className="item-value subTotalStyle">{(this.props.bookingValidate.toJS().price.total + this.props.bookingValidate.toJS().price.paidAmountVoucher).toFixed(2).toString().replace(".", window.__env.decimalSeparator)}€</span>
        </div>
      );
  }

  renderTotal = () => {
    let bookingValidate = this.props.bookingValidate.toJS();

    return (
      <div className="booking-footer-item mt20">
        <span className="item-label subTotalStyle">{_.get(this.props.trans, 'booking.payment.total', '')}</span>
        <span className="item-value subTotalStyle">{bookingValidate.price.total.toFixed(2).toString().replace(".", window.__env.decimalSeparator) + '€'}</span>
      </div>
    );
  }

  renderPaymentItem = (label, price) => {
    const { officeLocation, selectedRoom } = this.props;
    const isBookableEvent = _.get(officeLocation, 'bookableEvent', false);
    const isRoomEvent = _.get(selectedRoom, 'type', null) === 'event';

    let priceDisplay = null;
    if (!isBookableEvent || !isRoomEvent) {
      priceDisplay = <span className="item-value">{price}</span>;
    }

    return (
      <div className="booking-payment-item">
        <span className="item-label">{label}</span>
        {priceDisplay}
      </div>
    )
  }

  renderProductPayment = (label, price) => {
    const { officeLocation, selectedRoom } = this.props;
    const isBookableEvent = _.get(officeLocation, 'bookableEvent', false);
    const isRoomEvent = _.get(selectedRoom, 'type', null) === 'event';

    let subscriptionApplied = this.props.bookingValidate.getIn(['price', 'subscriptionApplied']);
    let infoTooltip = subscriptionApplied === true ? (
      <div className="info-tooltip" style={{ position: 'relative' }}>
        <span>
          <a data-tip data-for={label}> <span className={'info-button'}></span> </a>
          <ReactTooltip id={label} type="dark" effect='solid'>
            Subscription Applied
          </ReactTooltip>
        </span>
      </div>
    ) : null;

    return (
      <div className={classnames("booking-payment-item", { "free": price === 0 && subscriptionApplied === true })}>
        <span className="item-label">
          {label}{infoTooltip}
          {!isBookableEvent || !isRoomEvent ? this.renderProductPriceBreakDown() : null}
        </span>
        {(price > 0 && (!isBookableEvent || !isRoomEvent)) ? (<span className="item-value">{price.toFixed(2).toString().replace(".", window.__env.decimalSeparator) + '€'}</span>) : null}

      </div>
    )
  }
  getInfoPrice = (label, price, subLabel = null) => {
    const { officeLocation, selectedRoom } = this.props;
    const isBookableEvent = _.get(officeLocation, 'bookableEvent', false);
    const isRoomEvent = _.get(selectedRoom, 'type', null) === 'event';

    let priceText = (price === 0 || (isBookableEvent && isRoomEvent)) ? null : price;
    return (
      <div className={"booking-payment-subitem " + (price === 0 ? 'free' : '')}>
        <span className="item-label">{label}</span>
        <span className="item-value">{priceText}</span>
        {(subLabel !== null && (!isBookableEvent && !isRoomEvent)) ?
          <div className="info-tooltip" style={{ position: 'relative' }}>
            <span>
              <a data-tip data-for={label}> <span className={'info-button'}></span> </a>
              <ReactTooltip id={label} type="dark" effect='solid'>
                {subLabel}
              </ReactTooltip>
            </span>
          </div>
          :
          ''
        }
      </div>
    );
  };

  renderAmenitiesList = (amenities) => {

    return (
      <div>
        {amenities.map((amenity, index) => (
          <div key={index}>
            {this.getInfoPrice(amenity.name, 0, null)}
          </div>
        ))}
      </div>
    )
  }

  renderEquipmentsList = (equipments) => {
    const { trans } = this.props;
    let serviceItems = equipments.map((equipment, index) => {

      let priceText = (equipment.totalPrice === 0) ? 0 : equipment.totalPrice.toFixed(2).toString().replace(".", window.__env.decimalSeparator) + '€';
      let subText = [];

      switch (equipment.priceType) {
        case 'time':
          if (equipment.hours > 0 && equipment.priceHour !== null && equipment.priceHour > 0) {
            subText.push(
              <div className="tooltip-item" key={"epriceH" + equipment.id}>
                <span className={"price-info-title"}>{_.get(trans, 'booking.hour', '')}:</span> <span className={"price-info-value"}>{equipment.priceHour.toFixed(2).toString().replace(".", window.__env.decimalSeparator) + '€ × ' + equipment.hours}</span>
              </div>
            );
          }

          if (equipment.days > 0 && equipment.priceDay !== null && equipment.priceDay > 0) {
            subText.push(
              <div className="tooltip-item" key={"epriceD" + equipment.id}>
                <span className={"price-info-title"}>{_.get(trans, 'booking.day', '')}:</span> <span className={"price-info-value"}>{equipment.priceDay.toFixed(2).toString().replace(".", window.__env.decimalSeparator) + '€ × ' + equipment.days}</span>
              </div>
            );
          }

          if (equipment.weeks > 0 && equipment.priceWeek !== null && equipment.priceWeek > 0) {
            subText.push(
              <div className="tooltip-item" key={"epriceW" + equipment.id}>
                <span className={"price-info-title"}>{_.get(trans, 'booking.week', '')}:</span> <span className={"price-info-value"}>{equipment.priceWeek.toFixed(2).toString().replace(".", window.__env.decimalSeparator) + '€ × ' + equipment.weeks}</span>
              </div>
            );
          }
          if (subText.length === 0) subText = null;
          break;
        default:
          if (equipment.totalPrice > 0) {
            subText.push(
              <div className="tooltip-item" key={"epriceX" + equipment.id}>
                <span className={"price-info-title"}>{_.capitalize(equipment.priceType)}:</span> <span className={"price-info-value"}>{equipment.totalPrice.toFixed(2).toString().replace(".", window.__env.decimalSeparator) + '€ × '}</span>
              </div>
            );
          }
          else {
            subText = null;
          }
          break;
      }

      if (subText !== null) {
        subText = (<span className="tooltip-content">{subText}</span>);
      }

      return (
        <div key={index}>
          {this.getInfoPrice(equipment.name, priceText, subText)}
        </div>
      )
    });

    return (
      <div>
        {serviceItems}
      </div>
    )
  }

  renderServicesList = (services) => {
    const { trans } = this.props;
    let serviceItems = services.map((service, index) => {

      let priceText = (service.totalPrice === 0) ? 0 : service.totalPrice.toFixed(2).toString().replace(".", window.__env.decimalSeparator) + '€';
      let subText = [];

      switch (service.priceType) {
        case 'time':

          if (service.hours > 0 && service.priceHour !== null && service.priceHour > 0) {
            subText.push(
              <div key={"spriceH" + service.id} className="tooltip-item">
                <span className={"price-info-title"}>{_.get(trans, 'booking.hour', '')}:</span> <span className="price-info-value">{service.priceHour.toFixed(2).toString().replace(".", window.__env.decimalSeparator) + '€ × ' + service.hours}</span>
              </div>
            );
          }

          if (service.days > 0 && service.priceDay !== null && service.priceDay > 0) {
            subText.push(
              <div key={"spriceD" + service.id} className="tooltip-item">
                <span className={"price-info-title"}>{_.get(trans, 'booking.day', '')}:</span> <span className="price-info-value">{service.priceDay.toFixed(2).toString().replace(".", window.__env.decimalSeparator) + '€ × ' + service.days}</span>
              </div>
            );
          }

          if (service.weeks > 0 && service.priceWeek !== null && service.priceWeek > 0) {
            subText.push(
              <div key={"spriceW" + service.id} className="tooltip-item">
                <span className={"price-info-title"}>{_.get(trans, 'booking.week', '')}:</span> <span className="price-info-value">{service.priceWeek.toFixed(2).toString().replace(".", window.__env.decimalSeparator) + '€ × ' + service.weeks}</span>
              </div>
            );
          }
          if (subText.length === 0) subText = null;
          break;
        default:
          if (service.priceUnit > 0) {
            subText.push(
              <div key={"spriceX" + service.id} className="tooltip-item">
                <span className={"price-info-title"}>{_.capitalize(service.priceType)}:</span> <span className="price-info-value">{service.priceUnit.toFixed(2).toString().replace(".", window.__env.decimalSeparator)}€</span>
              </div>
            );
          }
          else {
            subText = null;
          }
          break;
      }

      if (subText !== null) {
        subText = (<span className="tooltip-content">{subText}</span>);
      }

      return (
        <div key={index}>
          {this.getInfoPrice(service.name, priceText, subText)}
        </div>
      )
    });

    return (
      <div>
        {serviceItems}
      </div>
    )
  }

  delayHideVoucherLoader = _.debounce(async function (e) {
    this.setState({
      showVoucherLoader: false
    });
  }, 1000);

  delayFetchPaymentProfiles = _.debounce(async function (e) {
    this.props.fetchPaymentProfiles();
  }, 5000);


  renderProductPriceBreakDown = () => {

    let bookingValidate = this.props.bookingValidate.toJS();
    let { product } = bookingValidate;
    let subText = [];

    if (product.priceType === 'time') {
      if (product.hours > 0 && product.priceHour !== null && product.priceHour > 0) {
        subText.push(
          <div className="tooltip-item" key={"productPriceH" + product.id}>
            <span className={"price-info-title"}>Hour:</span> <span className="price-info-value">{product.priceHour.toFixed(2).toString().replace(".", window.__env.decimalSeparator) + '€ × ' + product.hours}</span>
          </div>
        );
      }

      if (product.days > 0 && product.priceDay !== null && product.priceDay > 0) {
        subText.push(
          <div className="tooltip-item" key={"productPriceD" + product.id}>
            <span className={"price-info-title"}>Day:</span> <span className="price-info-value">{product.priceDay.toFixed(2).toString().replace(".", window.__env.decimalSeparator) + '€ × ' + product.days}</span>
          </div>
        );
      }

      if (product.weeks > 0 && product.priceWeek !== null && product.priceWeek > 0) {
        subText.push(
          <div className="tooltip-item" key={"productPriceW" + product.id}>
            <span className={"price-info-title"}>Week:</span> <span className="price-info-value">{product.priceWeek.toFixed(2).toString().replace(".", window.__env.decimalSeparator) + '€ × ' + product.weeks}</span>
          </div>
        );
      }

      if (product.months > 0 && product.priceMonth !== null && product.priceMonth > 0) {
        subText.push(
          <div className="tooltip-item" key={"productPriceM" + product.id}>
            <span className={"price-info-title"}>Month:</span> <span className="price-info-value">{product.priceMonth.toFixed(2).toString().replace(".", window.__env.decimalSeparator) + '€ × ' + product.months}</span>
          </div>
        );
      }

      if (subText.length === 0) subText = null;
    } else {
      subText = null;
    }

    if (subText !== null) {
      subText = (<div className="tooltip-content">{subText}</div>);
      return (
        <div className="info-tooltip">
          <span>
            <a data-tip data-for={'break-down-product-type'}> <span className={'info-button'}></span> </a>
            <ReactTooltip id={'break-down-product-type'} type="dark" effect='solid'>
              {subText}
            </ReactTooltip>
          </span>
        </div>
      )
    }
  }

  renderSelectedOptions = () => {

    let bookingValidate = this.props.bookingValidate.toJS();
    if (bookingValidate !== undefined) {
      let totalPriceServices = 0;
      let totalPriceEquipments = 0;
      bookingValidate.services.map((service) => {
        totalPriceServices += service.totalPrice;
        return null;
      }, 0);

      bookingValidate.equipments.map((equipment) => {
        totalPriceEquipments += equipment.totalPrice;
        return null;
      }, 0);

      let totalPriceAmentiesAndServices = 0 + totalPriceServices;

      let totalPriceAmentiesAndServicesLabel = totalPriceAmentiesAndServices > 0 ? (totalPriceAmentiesAndServices.toFixed(2).toString().replace(".", window.__env.decimalSeparator) + '€') : '';
      return (
        <div>
          {this.renderProductPayment(bookingValidate.name, bookingValidate.price.productPrice)}
          <div className="spacer-30"></div>
          {(bookingValidate.amenities.length + bookingValidate.services.length) > 0 ? this.renderPaymentItem(this.props.trans.booking.payment.amenitiesAndServices, totalPriceAmentiesAndServicesLabel) : null}
          {(bookingValidate.amenities.length + bookingValidate.services.length) > 0 ? this.renderAmenitiesList(bookingValidate.amenities) : null}
          {(bookingValidate.amenities.length + bookingValidate.services.length) > 0 ? this.renderServicesList(bookingValidate.services) : null}
          {(bookingValidate.amenities.length + bookingValidate.services.length) > 0 ? <div className="spacer-30"></div> : null}
          {bookingValidate.equipments.length > 0 ? this.renderPaymentItem(this.props.trans.booking.payment.equipments, totalPriceEquipments.toFixed(2).toString().replace(".", window.__env.decimalSeparator) + '€') : null}
          {this.renderEquipmentsList(bookingValidate.equipments)}
        </div>
      );
    }
  }


  closeRequestModal = () => {
    this.setState({ requestModalOpened: false });
  }

  onProceedRequestModal = () => {
    window.location.reload();
  }

  renderFooter = () => {
    const { selectedServices, selectedOptions, officeLocation, selectedRoom, selectedPaymentMethod, trans } = this.props;
    const isBookableEvent = _.get(officeLocation, 'bookableEvent', false);
    const isRoomEvent = _.get(selectedRoom, 'type', null) === 'event';
    let bookingValidate = this.props.bookingValidate.toJS();
    let args = {};
    let services = [];
    let equipments = [];
    let voucherCode = null;

    if (this.props.voucherInfo !== null && this.props.voucherInfo.toJS().valid === true) {
      voucherCode = this.props.voucherInfo.toJS().code;
    }

    if (selectedServices.length > 0) {
      selectedServices.map((selectedService) => {
        if (selectedService.includes('service')) {
          let [serviceId] = selectedService.split('service');
          services.push(parseInt(serviceId, 10));
        }
        if (selectedService.includes('equipment')) {
          let [equipmentId] = selectedService.split('equipment');
          equipments.push(parseInt(equipmentId, 10));
        }
        return null;
      });
    }
    args.officeTypeId = bookingValidate.officeTypeId;
    args.productId = bookingValidate.productId;
    args.fromDate = selectedOptions.startDate;
    args.toDate = selectedOptions.endDate;
    args.weekDays = selectedOptions.selectedWeekDays;
    args.hoursFrom = selectedOptions.hoursFrom;
    args.hoursUntil = selectedOptions.hoursUntil;
    args.servicesIds = services;
    args.equipmentIds = equipments;
    args.voucherCode = voucherCode;
    args.paymentMethodId = _.get(selectedPaymentMethod, 'id', null);
    args.useSpendingLimit = this.props.useSpendingLimit;
    args.meetingRoomTitle = this.props.meetingRoomTitle;
    args.meetingRoomComment = this.props.meetingRoomComment;

    if (this.props.eventRequestDetails && this.props.eventRequestDetails.length) {
      args.eventRequestDetails = this.props.eventRequestDetails;
    }

    let buttonText = _.get(trans, 'booking.payment.payAndFinishBooking');
    let bookErrors = null;
    if (this.props.bookingErrors !== null) {
      bookErrors = <div className="book-errors">{_.capitalize(this.props.bookingErrors.toJS().error)}</div>;
    }

    let payButtonDisabled = false;
    let voucherDisabled = false;
    if (this.state.paymentInProgress) {
      payButtonDisabled = true;
      if (bookErrors !== null) {
        payButtonDisabled = false;
      }
    }
    else {
      if (bookingValidate.price.total === 0) {
        payButtonDisabled = false;
      }
      else {
        if (!this.props.selectedPaymentMethod || _.isEmpty(this.props.selectedPaymentMethod)) {
          payButtonDisabled = true;
        }
      }
    }

    if (this.props.selectedRoom !== null && this.props.selectedRoom.id === 4) // event room
    {
      payButtonDisabled = false;
      if (this.state.paymentInProgress) {
        payButtonDisabled = true;
      }
      buttonText = _.get(trans, 'booking.payment.sendRequest');
    }

    if (this.props.bookingValidate.getIn(['price', 'totalPriceVatIncluded']) === 0) {
      buttonText = _.get(trans, 'booking.payment.confirmAndFinishBooking');
    }

    if (bookingValidate.price.paidAmountHero + bookingValidate.price.paidAmountCompany === 0) {
      payButtonDisabled = false;
    }

    let companySection = null;
    if (bookingValidate.price.paidAmountCompany > 0 && bookingValidate.officeTypeId !== 4) {
      let negativePaidAmountCompany = bookingValidate.price.paidAmountCompany * (-1);
      companySection = (
        <div>
          {this.renderFooterItem(bookingValidate.companyName, negativePaidAmountCompany.toFixed(2).toString().replace(".", window.__env.decimalSeparator) + '€')}
        </div>
      );
    }

    let totalVatDisplay = null;
    let totalPriceDisplay = null;
    let voucherDisplay = null;

    if (!isBookableEvent || !isRoomEvent) {
      totalVatDisplay = this.renderFooterItem(
        _.get(trans, 'booking.payment.vat'),
        bookingValidate.price.vat.toFixed(2).toString().replace(".", window.__env.decimalSeparator) + '€'
      );
      totalPriceDisplay = this.renderTotal();
    }

    if (_.get(bookingValidate, 'price.paidAmountVoucher')) {
      voucherDisplay = this.renderFooterItem(
          _.get(trans, 'booking.payment.voucher'),
          `-${(_.get(bookingValidate, 'price.paidAmountVoucher')).toFixed(2).toString().replace(".", window.__env.decimalSeparator)} €`
      );
    }

    return (
      <div className="booking-payment-footer">
        {this.renderBooking()}
        {companySection}
        {totalVatDisplay}
        {this.renderSubtotal()}
        {voucherDisplay}
        {totalPriceDisplay}
        {bookErrors}
        {this.state.paymentInProgress ? <div className="LoaderInverseSmall" style={{ marginTop: '22px', height: '18px' }}></div> : null}
        <div
          className={classnames({ 'disabled': payButtonDisabled }, "booking-payment-finish")}
          onClick={() => {
            localStorage.setItem('showPopup', 1);
            if (payButtonDisabled === false) {
              if (bookErrors !== null) {
                this.props.clearBookingErrors();
              }
              this.onFinishBooking(args);
            }
          }}>{buttonText}</div>

        {this.renderCheckoutForm()}
        {this.renderBancontactCheckoutForm()}
      </div>
    );
  }

  renderCheckoutForm = () => {

    if (this.props.paymentConfirmationRequiresAction !== null) {
      return <StripeProvider apiKey={window.__env.stripeApiKey} >
        <Elements locale={lang}>
          <CheckoutForm intent={this.props.paymentConfirmationRequiresAction} />
        </Elements>
      </StripeProvider>
    } else {
      return null;
    }
  }

  renderBancontactCheckoutForm = () => {
    if (typeof this.props.externalPaymentIntent !== 'undefined') {
      return <StripeProvider apiKey={window.__env.stripeApiKey}>
        <Elements locale={lang}>
          <CheckoutFormBancontact bookingId={this.props.bookingId} paymentIntent={this.props.externalPaymentIntent}/>
        </Elements>
      </StripeProvider>
    }
  }


  renderFooterItem = (label, price) => {
    return (
      <div className="booking-footer-item">
        <span className="item-label">{label}</span>
        <span className="item-value">{price}</span>
      </div>
    )
  }

  setEventRequestDetails(event) {
    this.props.setEventRequestDetails(event.target.value);
  }

  buildEventRequestDetails() {
    const { eventRequestDetails, bookingErrors, trans } = this.props;
    let eventRequestDetailsValidationError = null;
    if (bookingErrors) {
      const bookErrorsJs = bookingErrors.toJS();
      if (bookErrorsJs && bookErrorsJs.eventRequestDetails) {
        eventRequestDetailsValidationError = (<p className="events-details__error">{bookErrorsJs.eventRequestDetails}</p>)
      }
    }
    const eventRequestDetailsLength = typeof eventRequestDetails === 'string' ? parseInt(eventRequestDetails.length, 10) : 0;
    const eventRequestDetailsErrorClass = eventRequestDetailsLength > 1500 ? 'events-details__counter--error' : '';
    return (
      <div className="events-details">
        <label className="events-details__label">
          {_.get(trans, 'location.detailRequest', '')}
           <span className={`events-details__counter ${eventRequestDetailsErrorClass}`}>({eventRequestDetailsLength}/1500)</span>
        </label>
        <textarea name="request_details" className="events-details__textarea" rows="7" value={eventRequestDetails} onChange={this.setEventRequestDetails}></textarea>
        {eventRequestDetailsValidationError}
      </div>
    )
  }

  onButtonClick = () => {
    window.location.href = "/booking";
  }

  modifyPaymentMethod() {
    const { onModifyMethod, clearBookingErrors } = this.props;
    clearBookingErrors();
    onModifyMethod();
  }


  render() {
    const { officeLocation, selectedRoom, bookingValidate, useSpendingLimit } = this.props;
    const { voucherApplied, voucherCode, showVoucherLoader, paymentInProgress } = this.state;
    const isRoomEvent = _.get(selectedRoom, 'type', null) === 'event';
    const isBookableEvent = _.get(officeLocation, 'bookableEvent', false);

    return (
      <div className="booking-payment">
        <div className="booking-payment-content">
          <Scrollbars className="styleScroll">
            <label onClick={() => { this.props.onBack(); this.props.clearVoucher(); }} className="back-button back-button--padding">{this.props.trans.booking.payment.back}</label>
            {_.get(selectedRoom, 'id', null) !== 4 && (
                <PaymentProfile
                    paymentInProgress = {paymentInProgress}
                    selectedRoom={this.props.selectedRoom}
                    bookingValidate={this.props.bookingValidate.toJS()}
                    paymentMethod={this.props.selectedPaymentMethod}
                    modifyPaymentMethod={this.modifyPaymentMethod}
                    fetchBookingValidate={this._fetchBookingValidate}
                    useSpendingLimit={useSpendingLimit}
                    handleUseSpendingLimitChange={this.handleUseSpendingLimitChange}
                    voucherInfo={this.props.voucherInfo ? this.props.voucherInfo.toJS() : null}
                    voucherApplied={voucherApplied}
                    voucherCode={voucherCode}
                    showVoucherLoader={showVoucherLoader}
                    redeemVoucher={this.redeemCode}
                    removeVoucher={this.removeCoupon}
                    handleVoucherCodeChange={this.handleVoucherCodeChange}
                    bookingPrice={bookingValidate.toJS().price.subtotalVoucherApplied.toFixed(2)}
                />
            )}

            <div className="booking-payment-scrollable">

              <div className="booking-payment-title">{this.props.trans.booking.payment.bookingDetailsLabel}</div>
              {this.renderSelectedOptions()}
              {(isRoomEvent && isBookableEvent) ? this.buildEventRequestDetails() : null}
            </div>
            {this.renderFooter()}
          </Scrollbars>
        </div>
        <ModalDialog
          label="Event Room Booking Request"
          title={this.props.trans.modals.eventRoomBookingRequest.title}
          type="event-room-book-request"
          modalOpened={this.state.requestModalOpened}
          onModalClose={this.closeRequestModal}
          onProceed={this.onProceedRequestModal}
          showCancel={false}
          proceedText={this.props.trans.modals.eventRoomBookingRequest.ok}
          className="business-dialog-modal"
          trans={this.props.trans}
        >
          <div>{this.props.trans.modals.eventRoomBookingRequest.info}</div>
        </ModalDialog>
      </div>
    );
  }
}

AddBokingPayment.propTypes = {
  onFinishBooking: PropTypes.func.isRequired,
  officeLocation: PropTypes.object.isRequired,
  selectedOptions: PropTypes.object.isRequired
};

AddBokingPayment.defaultProps = {
  selectedOptions: {}
};

const mapStateToProps = state => {
  return {
    selectedRoom: state.addBooking.get('selectedRoom'),
    selectedProduct: state.addBooking.get('selectedProduct'),
    selectedOptions: state.addBooking.get('selectedOptions'),
    selectedServices: state.addBooking.get('selectedServices'),
    bookingOptions: state.addBooking.get('bookingOptions'),
    bookingOptionsErrors: state.addBooking.get('bookingOptionsErrors'),
    bookingValidateErrors: state.addBooking.get('bookingValidateErrors'),
    eventRequestDetails: state.addBooking.get('eventRequestDetails'),
    selectedPaymentMethod: state.addBooking.get('selectedPaymentMethod'),
    useSpendingLimit: state.addBooking.get('useSpendingLimit'),
    meetingRoomTitle: state.addBooking.get('meetingRoomTitle'),
    meetingRoomComment: state.addBooking.get('meetingRoomComment'),
  };
};

const mapDispatchProps = dispatch => {
  return {
    setSelectedServices: (selectedServices) => {
      dispatch(setSelectedServices(selectedServices));
    },
    setSelectedOptions: (selectedOptions) => {
      dispatch(setSelectedOptions(selectedOptions));
    },
    setSelectedRoom: (room) => {
      dispatch(setSelectedRoom(room));
    },
    setSelectedProduct: (product) => {
      dispatch(setSelectedProduct(product));
    },
    clearBookingErrors: () => {
      dispatch(clearBookingErrors());
    },
    onChangeSection: (bookingSection) => {
      dispatch(onChangeSection(bookingSection));
    },
    setEventRequestDetails: (details) => dispatch(setEventRequestDetails(details)),
    setUseSpendingLimit: (value) => dispatch(setUseSpendingLimit(value)),
  }
};

export default withRouter(connect(mapStateToProps, mapDispatchProps)(AddBokingPayment));
