import { connect } from 'react-redux';
import _ from 'lodash';
import FloorPlan from './floorPlan';

import { fetchOfficeDetails } from '../../../../actions/locationActions';
import { setSelectedProductId } from '../../../../actions/whiteLabel/booking';
import { mapProductsSelector } from '../../../../selectors/whiteLabel/products';
import { selectedPinSelector, selectedProductIdSelector, selectedProductTypeSelector } from '../../../../selectors/whiteLabel/booking';
import { heroesBookingListSelector } from '../../../../selectors/whiteLabel/heroes-booking-list';

const mapStateToProps = state => {
  const data = state.location.get('details').toJS();
  return {
    whiteLabel: _.get(data, 'white_label', []),
    translations: state.translations,
    mapProducts: mapProductsSelector(state),
    selectedProductId: selectedProductIdSelector(state),
    openingHour: _.get(data, 'openingHours.0.fromHour', null),
    openingMinute: _.get(data, 'openingHours.0.fromMinute', null),
    closingHour: _.get(data, 'openingHours.0.toHour', null),
    closingMinute: _.get(data, 'openingHours.0.toMinute', null),
    bookings: heroesBookingListSelector(state),
    selectedPin: selectedPinSelector(state),
    selectedProductType: selectedProductTypeSelector(state),
    ssoProvider: state.global.get('ssoProvider'),
  };
};

const mapDispatchProps = dispatch => {
  return {
    fetchOfficeDetails: id => {
      dispatch(fetchOfficeDetails(id));
    },
    setSelectedProductId: id => {
      dispatch(setSelectedProductId(id));
    },
  };
};

export default connect(mapStateToProps, mapDispatchProps)(FloorPlan);
