import getLang from './../utils/lang';

export const FETCH_BOOKING_DETAILS        = 'FETCH_BOOKING_DETAILS';
export const BEFORE_FETCH_BOOKING_DETAILS = 'BEFORE_FETCH_BOOKING_DETAILS';
export const FETCH_BOOKING_SUMMARY        = 'FETCH_BOOKING_SUMMARY';
export const CANCEL_BOOKING               = 'CANCEL_BOOKING';

const lang = getLang() !== null ? getLang() : 'en';

export const fetchBookingDetails = (id) => {
  return {
    type: FETCH_BOOKING_DETAILS,
    url: window.__env.apiUrl + `/booking/details/${id}?language=${lang}`
  }
};

export const beforeFetchBookingDetails = () => {
  return {
    type: BEFORE_FETCH_BOOKING_DETAILS
  }
};

export const fetchBookingSummary = () => {
  return {
    type: FETCH_BOOKING_SUMMARY,
    url: window.__env.apiUrl + '/hero/bookings/summary'
  }
};

export const fetchGuestBookingSummary = () => {
  return {
    type: FETCH_BOOKING_SUMMARY,
    url: window.__env.apiUrl + '/public/hero/bookings/summary'
  }
};

export const cancelBooking = (id) => {
  return {
    type: CANCEL_BOOKING,
    id,
    url: window.__env.apiUrl + '/booking/cancel'
  }
};
