import api from '../../components/community/utils/api';
import { fetchCommunityUploads } from './community-uploads';
import { fetchCommunityPost } from './community-posts';

/*
 * Constants
 */
export const SET_COMMUNITY_POST_FORM_IS_LOADING = 'SET_COMMUNITY_POST_FORM_IS_LOADING';
export const SET_COMMUNITY_POST_ATTACHMENT_IS_LOADING = 'SET_COMMUNITY_POST_ATTACHMENT_IS_LOADING';
export const SET_COMMUNITY_UPLOAD_PROGRESS = 'SET_COMMUNITY_UPLOAD_PROGRESS';
export const SET_COMMUNITY_POST_FORM_ERRORS = 'SET_COMMUNITY_POST_FORM_ERRORS';
/*
 * Sync Actions
 */

export const setCommunityPostFormIsLoading = isLoading => ({
  type: SET_COMMUNITY_POST_FORM_IS_LOADING,
  isLoading,
});
export const setCommunityPostIsUploading = isUploading => ({
  type: SET_COMMUNITY_POST_ATTACHMENT_IS_LOADING,
  isUploading,
});

export const setUploadProgress = percent => ({
  type: SET_COMMUNITY_UPLOAD_PROGRESS,
  percent,
});

export const setFormError = errors => ({
  type: SET_COMMUNITY_POST_FORM_ERRORS,
  errors,
});

const afterSavePost = () => dispatch => {
  dispatch(fetchCommunityUploads());
  dispatch(fetchCommunityPost());
};

/*
 * Async Actions
 */

export const savePost = data => dispatch => {
  dispatch(setCommunityPostFormIsLoading(true));
  const formData = new FormData();
  for (const key in data) {
    if (data[key] === null) {
      continue;
    }
    formData.append(key, data[key]);
  }

  return new Promise((resolve, reject) => {
    api
      .post(`posts/create`, formData)
      .then(() => {
        dispatch(setCommunityPostFormIsLoading(false));
        dispatch(afterSavePost());
        resolve(true);
      })
      .catch(({ response }) => {
        if (response.data.error) {
          dispatch(setFormError(response.data.error));
          dispatch(setCommunityPostFormIsLoading(false));
        }
        reject(response.data);
      });
  });
};

export const updatePost = data => dispatch => {
  dispatch(setCommunityPostFormIsLoading(true));
  const formData = new FormData();
  for (const key in data) {
    if (data[key] === null) {
      continue;
    }
    formData.append(key, data[key]);
  }

  return new Promise((resolve, reject) => {
    api
      .post(`posts/update`, formData)
      .then(() => {
        dispatch(setCommunityPostFormIsLoading(false));
        dispatch(afterSavePost());
        resolve(true);
      })
      .catch(({ response }) => {
        if (response.data.error) {
          dispatch(setFormError(response.data.error));
          dispatch(setCommunityPostFormIsLoading(false));
        }
        reject(response.data);
      });
  });
};

export const uploadAttachment = data => dispatch => {
  dispatch(setCommunityPostIsUploading(true));
  return new Promise((resolve, reject) => {
    const config = {
      onUploadProgress(progressEvent) {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        if (percentCompleted <= 98) {
          dispatch(setUploadProgress(percentCompleted));
        }
      },
      cancelToken: data.cancelToken.token,
    };
    const formData = new FormData();
    formData.append('attachment', data.attachment);
    api
      .post(`posts/upload`, formData, config)
      .then(response => {
        dispatch(setCommunityPostIsUploading(false));
        dispatch(setUploadProgress(100));
        resolve(response.data.data);
      })
      .catch(({ response }) => {
        if (response && response.data.error) {
          dispatch(setFormError(response.data.error));
          dispatch(setCommunityPostIsUploading(false));
        } else {
          dispatch(setUploadProgress(null));
          dispatch(setCommunityPostIsUploading(false));
          reject();
        }
      });
  });
};

export const clearErrors = () => dispatch => {
  dispatch(setFormError([]));
};
