import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import noPostsIcon from '../../../assets/svg/community/community_no_posts.svg';

const NoPosts = ({ translations, action }) => {
  const trans = _.get(translations, 'communityPage', '');
  return (
    <div className="no-communities__container no-posts__container">
      <img src={noPostsIcon} alt="No Posts  Icon" />
      <p className="no-communities__main-title">{_.get(trans, 'noPostsTitle', '')}</p>
      <p className="no-communities__sub-title">{_.get(trans, 'noPostsSubtitle', '')}</p>
      <button type="button" className="entity-button entity-button--border no-communities__btn" onClick={() => action()}>
        {_.get(trans, 'noPostsButton', '')}
      </button>
    </div>
  );
};

NoPosts.propTypes = {
  translations: PropTypes.shape({
    noCommunityTitle: PropTypes.string,
    noCommunitySubtitle: PropTypes.string,
    startCommunityButton: PropTypes.string,
  }),
  action: PropTypes.func.isRequired,
};

NoPosts.defaultProps = {
  translations: {},
};

export default NoPosts;
