import React, { Component } from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import InputField from '../inputField';

class ResetPasswordForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: '',
      password: '',
      passwordConfirmation: '',
      errors: {},
      isLoading: false,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  componentDidMount() {
    const { history } = this.props;
    const params = queryString.parse(window.location.search);
    const token = _.get(params, 'token', null);
    if (token) {
      this.setState({ token });
    } else {
      history.replace('/dashboard');
    }
  }

  handleInputChange(key, value) {
    switch (key) {
      case 'password':
        this.setState({ password: value });
        break;
      case 'passwordConfirmation':
        this.setState({ passwordConfirmation: value });
        break;
      default:
    }
  }

  handleFormSubmit() {
    const { password, passwordConfirmation, token } = this.state;
    const { history, reset } = this.props;
    const data = {
      token,
      password,
      passwordConfirmation,
    };
    this.setState({ isLoading: true });
    reset(data)
      .then(response => {
        history.push('/login');
      })
      .catch(error => {
        this.setState({ isLoading: false });
        const errorType = _.get(error, 'response.data.type', '');
        if (errorType === 'password') {
          this.setState({ errors: { password: _.get(error, 'response.data.message', null) } });
        } else if (errorType === 'passwordConfirmation') {
          this.setState({ errors: { passwordConfirmation: _.get(error, 'response.data.message', null) } });
        }
      });
  }

  render() {
    const { translations } = this.props;
    const { password, passwordConfirmation, errors, isLoading } = this.state;
    const requiredLabel = _.get(translations, 'required', '(required)');
    const passwordLabel = `${_.get(translations, 'newPassword', 'New password')} ${requiredLabel}`;
    const passwordConfirmationLabel = `${_.get(translations, 'confirmNewPassword', 'Confirm new password')} ${requiredLabel}`;
    const submitMessage = _.get(translations, 'resetPassword.action', 'Save new password');
    const signupTranslations = _.get(translations, 'signup', []);
    const passwordError = _.get(errors, 'password', null) ? _.get(signupTranslations, errors.password, true) : false;
    const passwordConfirmationError = _.get(errors, 'passwordConfirmation', null)
      ? _.get(signupTranslations, errors.passwordConfirmation, true)
      : false;
    return (
      <form
        className="login-form__container"
        onSubmit={event => {
          event.preventDefault();
          event.stopPropagation();
          this.handleFormSubmit();
        }}
      >
        <div className="login-form__row">
          <InputField
            label={passwordLabel}
            name="password"
            value={password}
            type="password"
            handleInputChange={this.handleInputChange}
            error={passwordError}
          />
        </div>
        <div className="login-form__row">
          <InputField
            label={passwordConfirmationLabel}
            name="passwordConfirmation"
            value={passwordConfirmation}
            type="password"
            handleInputChange={this.handleInputChange}
            error={passwordConfirmationError}
          />
        </div>

        <div className="login-form__row">
          <button id="open-forgot" className="login-form__submit-button" type="submit">
            {!isLoading ? submitMessage : <div className="open-login__loader" />}
          </button>
        </div>
      </form>
    );
  }
}

export default withRouter(ResetPasswordForm);
