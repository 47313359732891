import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import virtualTourIcon from '../../assets/svg/officeDetails/virtual-tour-icon.svg';
import virtualTourBackground from '../../assets/svg/officeDetails/virtual-tour.jpg';

const OfficeVirtualTour = props => {
  const { trans, virtualTourLink } = props;
  const takeVirtualTourDescription = _.get(
    trans,
    'description',
    "Take a virtual tour of the Open Campus at Procter & Gamble's Brussels Innovation Centre in 360°"
  );
  const takeVirtualTourButton = _.get(trans, 'button', 'Take the tour');

  return (
    <div
      className="virtual-tour__background-image"
      style={{ backgroundImage: `url(${virtualTourBackground})`, backgroundRepeat: 'no repeat' }}
    >
      <div className="virtual-tour__description">{takeVirtualTourDescription}</div>
      <a href={virtualTourLink} target="_blank" className="virtual-tour__button">
        <img alt="Virtual Tour" src={virtualTourIcon} className="virtual-tour__icon" />
        <span className="virtual-tour__button-text">{takeVirtualTourButton}</span>
      </a>
    </div>
  );
};

OfficeVirtualTour.propTypes = {
  virtualTourLink: PropTypes.string.isRequired,
  trans: PropTypes.shape(),
};
OfficeVirtualTour.defaultProps = {
  trans: {},
};

export default OfficeVirtualTour;
