import { connect } from 'react-redux';
import Tile from './Tile';

const mapStateToProps = state => ({
  translations: state.translations,
  heroProfile: state.global.get('heroProfile'),
  isWhiteLabel: state.global.get('isWhiteLabel'),
});

export default connect(mapStateToProps)(Tile);
