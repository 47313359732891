import PropTypes from 'prop-types';
import React from 'react';
import deskOfficeIcon from '../../../../assets/svg/dashboard/desk-office-icon.svg';
import eventRoomIcon from '../../../../assets/svg/dashboard/event-room-icon.svg';
import privateOfficeIcon from '../../../../assets/svg/dashboard/private-office-icon.svg';
import meetingRoomIcon from '../../../../assets/svg/dashboard/meeting-room-icon.svg';
import deskOfficeHover from '../../../../assets/svg/dashboard/desk-office-icon--hover.svg';
import eventRoomHover from '../../../../assets/svg/dashboard/event-room-icon--hover.svg';
import privateOfficeHover from '../../../../assets/svg/dashboard/private-office-icon--hover.svg';
import meetingRoomHover from '../../../../assets/svg/dashboard/meeting-room-icon--hover.svg';

const IconMap = {
  deskOfficeIcon,
  eventRoomIcon,
  privateOfficeIcon,
  meetingRoomIcon,
  deskOfficeHover,
  eventRoomHover,
  privateOfficeHover,
  meetingRoomHover,
};

const buildFooter = ({ id, link, alt, count }, url) =>
  count > 0 ? (
    <a key={id} className="booking-recommendation-footer__item-container" href={url}>
      <span className="booking-recommendation-footer__item">
        <img className="booking-recommendation-footer__item-icon" src={IconMap[`${link}Icon`]} alt={alt} />{' '}
        <img className="booking-recommendation-footer__item-icon--hover" src={IconMap[`${link}Hover`]} alt={alt} />{' '}
        <span className="booking-recommendation-footer__item-count">{count}</span>
      </span>
    </a>
  ) : null;

const BookingRecommendationFooter = ({ footerData, url }) => {
  return <div className="booking-recommendation__office-space-footer">{footerData.map(x => buildFooter(x, url))}</div>;
};

buildFooter.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  link: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
};

BookingRecommendationFooter.propTypes = {
  footerData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  url: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default BookingRecommendationFooter;
