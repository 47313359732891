import React, { Component } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import './css/modalDialogCenter.scss';

/**
 * @todo: Maybe this was supposed to be general since it is a pattern?
 */

class ModalDialogCenterEdit extends Component {
  props;

  render () {
    return (
        <div className="dialog-center-edit">
          <Scrollbars>
            <div className="dialog-center-edit-header">
              <div onClick={this.props.onModalClose} className="modal-close-button"></div>
              <h3>{this.props.title}</h3>
            </div>
            <div className="dialog-center-edit-body">{this.props.body}</div>
            <div className="dialog-center-edit-action">
              <span className="tertiary-button" onClick={this.props.onModalClose}>{this.props.trans.modals.editAccount.cancel}</span>
              <span className="secondary-button" onClick={this.props.onSave}>{this.props.trans.modals.editAccount.save}</span>
            </div>
          </Scrollbars>
        </div>
    );
  }
};

export default ModalDialogCenterEdit;
