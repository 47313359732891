import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import Sidebar from '../../../rightSidebar/rightSidebar';
import ProfileBox from '../../../profileBox/profileBox';

import '../../../accountProfile/css/index.scss';

class AccountProfile extends React.Component {
  constructor(props) {
    super(props);
    this.editProfile = this.editProfile.bind(this);
  }

  componentDidMount() {
    const { profileDetails } = this.props;
    if (!profileDetails.size) {
      this.props.fetchProfile();
    }
  }

  getInfo(sectionTitle, data) {
    let infoString = '';
    let arr = [];
    if (!data || data.size === 0) {
      infoString = '-';
    } else {
      arr = [];
      data.forEach((item, key) => {
        arr.push(item.get('name'));
      });
      infoString = arr.join(', ');
    }

    return (
      <div className="connection-sidebarInfo">
        <div className="connection-sectionTitle --smallMargin">{sectionTitle}</div>
        <div id={_.kebabCase(sectionTitle)} className="connection-sidebarInfoText">
          {infoString}
        </div>
      </div>
    );
  }

  getProfileBox() {
    const { profileDetails } = this.props;
    if (profileDetails && profileDetails.get('id')) {
      return (
        <ProfileBox
          id={profileDetails.get('id')}
          fetchConversation={false}
          className="--wide"
          grade={profileDetails.get('grade')}
          firstName={profileDetails.get('firstName')}
          distance={profileDetails.get('distance')}
          lastName={profileDetails.get('lastName')}
          options={{ distance: true, location: true }}
          avatar={profileDetails.get('profilePictureUrl')}
          jobName={profileDetails.getIn(['positions', 0, 'title'])}
          headline={profileDetails.get('headline')}
          currentLocation={profileDetails.getIn(['positions', 0, 'company'])}
          matchValue={null}
          hasActiveSubscription={profileDetails.get('hasCompanySubscription')}
          subscriptionCompanyName={profileDetails.get('subscriptionCompanyName')}
          hideConnect
          noLink
          showGrade={false}
          showSpendingLimit={false}
          trans={this.props.translations}
          isProfilePage
          heroInitials={profileDetails.get('heroInitials')}
        />
      );
    }
    return null;
  }

  editProfile() {
    const { history } = this.props;
    history.push('/profile');
  }

  render() {
    const { profileDetails, translations } = this.props;

    if (translations === null || !profileDetails.size) {
      return <div className="officeDetailsLoader" />;
    }

    const skillsLabel = _.get(this.props, 'translations.profile.accountProfile.yourSkills', 'Your skills def');
    const trainingsLabel = _.get(this.props, 'translations.profile.accountProfile.yourTrainings', 'Your trainings def');

    return (
      <div className="connection">
        <section className="connection-content">
          <Scrollbars className="styleScroll">
            <button
              type="button"
              onClick={this.props.history.goBack}
              className="navigation__back-to-button navigation__back-to-button--margin-left"
            >
              {_.get(this.props.translations, 'profile.connection.back', 'connection.back')}
            </button>
            {this.getProfileBox()}
          </Scrollbars>
        </section>

        <Sidebar className="--full-height">
          <Scrollbars className="styleScroll">
            <div className="connection-sidebarContent">
              <div onClick={this.editProfile} className="edit-button edit-button-sidebar" />
              {this.getInfo(skillsLabel, profileDetails.get('heroSpecificSkills'))}
              {this.getInfo(trainingsLabel, profileDetails.get('heroTrainingSkills'))}
            </div>
          </Scrollbars>
        </Sidebar>
      </div>
    );
  }
}

export default withRouter(AccountProfile);
