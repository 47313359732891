import { connect } from 'react-redux';

import toJS from '../../utils/to-js';
import {
  isLoadingSelector,
  isReplyLoadingSelector,
  formErrorsSelector,
  replyFormErrorsSelector,
} from '../../../../selectors/community/create-comment';
import { saveComment, clearErrors } from '../../../../actions/community/create-comment';

import CommentForm from './CommentForm';
import { showCommunityPageToast } from '../../../../actions/community/community-toasts';
import { heroIsCommunityMemberSelector } from '../../../../selectors/community/community-page';

const mapDispatchToProps = dispatch => ({
  saveComment: data => dispatch(saveComment(data)),
  clearErrors: () => dispatch(clearErrors()),
  showCommunityPageToast: message => dispatch(showCommunityPageToast(message)),
});
const mapStateToProps = state => ({
  isLoading: isLoadingSelector(state),
  isReplyLoading: isReplyLoadingSelector(state),
  errors: formErrorsSelector(state),
  replyErrors: replyFormErrorsSelector(state),
  heroProfile: state.global.get('heroProfile'),
  heroIsCommunityMember: heroIsCommunityMemberSelector(state),
});
export default connect(mapStateToProps, mapDispatchToProps)(toJS(CommentForm));
