import Immutable, { fromJS } from 'immutable/dist/immutable';
import { SET_HERO_PROFILE, SET_HERO_PROFILE_LOADING, SET_HERO_PROFILE_ERRORS } from '../actions/heroProfile';

const initialState = Immutable.Map({
  heroProfile: fromJS({}),
  isLoading: false,
  errors: fromJS([]),
});

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_HERO_PROFILE:
      return state.setIn(['heroProfile'], fromJS(action.heroProfile));
    case SET_HERO_PROFILE_LOADING:
      return state.setIn(['isLoading'], action.isLoading);
    case SET_HERO_PROFILE_ERRORS:
      return state.setIn(['errors'], action.errors);
    default:
      return state;
  }
};
