import classnames from 'classnames';
import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';

const Interest = props => {
  const { id, name, value, onInterestClick } = props;
  return (
    <div
      className={classnames('community-audience__tag', { 'community-audience__tag-checked': value })}
      onClick={() => onInterestClick(id)}
    >
      {name}
    </div>
  );
};

const InterestPicker = props => {
  const { allInterests, selectedInterests, onInterestClick, error } = props;

  const hmapChecked = {};
  for (let i = 0; i < selectedInterests.length; i++) {
    hmapChecked[selectedInterests[i]] = true;
  }

  return (
    <div className="community-audience__picker">
      <div id="community-audience__tags">
        {allInterests.map(interest => (
          <Interest
            id={interest.id}
            key={interest.id}
            name={interest.name}
            value={!!hmapChecked[interest.id]}
            onInterestClick={onInterestClick}
          />
        ))}
      </div>
    </div>
  );
};

InterestPicker.propTypes = {
  allInterests: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ),
  selectedInterests: PropTypes.arrayOf(PropTypes.number),
  onInterestClick: PropTypes.func.isRequired,
};
InterestPicker.defaultProps = {
  allInterests: [],
  selectedInterests: [],
};

Interest.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  value: PropTypes.bool,
  onInterestClick: PropTypes.func.isRequired,
};
Interest.defaultProps = {
  id: null,
  name: null,
  value: null,
};

export default InterestPicker;
