import React from 'react';
import _ from 'lodash';
import { withRouter, Link } from 'react-router-dom';
import moment from 'moment-timezone';
import Truncate from 'react-truncate';
import sizedImage from '../../../../utils/imageHelper';
import accordionSvg from '../../../../assets/svg/accordion/accordion-arrow-down.svg';
import companyNoLogoPlaceholder from '../../../../assets/images/booking/company-logo.png';
import { getWorkDays } from '../../../../utils/weekDaysUtils';
import getLang from '../../../../utils/lang';

const lang = getLang() !== null ? getLang() : 'en';

class HeroBookings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expandedBooking: null,
      showMoreBookingsArray: [],
    };

    this.expandBookingBtn = this.expandBookingBtn.bind(this);
    this.showMoreBookingsBtn = this.showMoreBookingsBtn.bind(this);
    this.buildBookingDetails = this.buildBookingDetails.bind(this);
    this.buildingBuildingData = this.buildingBuildingData.bind(this);
    this.bookNextTo = this.bookNextTo.bind(this);
  }

  expandBookingBtn(bookingId) {
    const { expandedBooking } = this.state;
    this.setState({ expandedBooking: bookingId === expandedBooking ? null : bookingId });
  }

  bookNextTo(booking, officeId, officeTz) {
    const { history, setSelectedPin, setSelectedDate, setSelectedProductType } = this.props;
    const productId = _.get(booking, 'product_id', null);
    const floorId = _.get(booking, 'floor_id', null);
    const startDate = new Date(_.get(booking, 'from_date', null));
    const productType = _.get(booking, 'product_type', null);
    const productTypeId = productType === 'Desk' ? 1 : 3;
    const stDate = moment(startDate);

    const now = moment.tz(officeTz);
    // update date only if the booking starts in the future
    if (stDate.isAfter(now)) {
      setSelectedDate(stDate.format('MMM D, YYYY'));
    } else {
      setSelectedDate(now.format('MMM D, YYYY'));
    }
    setSelectedPin(productId);
    setSelectedProductType(productTypeId);
    history.push({ pathname: `/location/${officeId}`, state: { fromHero: true, floorId } });
  }

  showMoreBookingsBtn(officeId) {
    const { showMoreBookingsArray } = this.state;
    if (showMoreBookingsArray.indexOf(officeId) > -1) {
      const index = showMoreBookingsArray.indexOf(officeId);
      showMoreBookingsArray.splice(index, 1);
      this.setState({
        showMoreBookingsArray,
      });
    } else {
      showMoreBookingsArray.push(officeId);
      this.setState({
        showMoreBookingsArray,
      });
    }
    return true;
  }

  showBookingNextToBtn(heroName, booking, officeId, officeTz) {
    const { translations } = this.props;

    return (
      <div className="colleague-booking-book-next">
        <button type="button" onClick={() => this.bookNextTo(booking, officeId, officeTz)}>
          {_.get(translations, 'whiteLabel.bookNextTo', 'Book next to')} {heroName}
        </button>
      </div>
    );
  }

  buildingBuildingData(buildingData) {
    const { translations } = this.props;
    const bookings = _.get(buildingData, 'bookings', []);
    const buildingImage = _.get(buildingData, 'office_image.url', companyNoLogoPlaceholder);
    const officeId = _.get(buildingData, 'office_id', null);
    const officeTz = _.get(buildingData, 'office_timezone', null);
    const { showMoreBookingsArray } = this.state;
    const limitedBookingsArray = showMoreBookingsArray.indexOf(officeId) > -1 ? bookings : bookings.slice(0, 3);

    return (
      <div className="hero-bookings-container">
        <div className="colleague-card__image-container">
          <Link to={`/location/${officeId}`}>
            <img src={sizedImage(buildingImage, 297, 201)} alt="name" />
          </Link>
        </div>
        <div className="office-card__info-wrapper hero-bookings-wrap">
          <div className="office-card__info-container">
            <div className="colleague-booking-title">{_.get(buildingData, 'office_name', null)}</div>
            {limitedBookingsArray.map(booking => this.buildBookingDetails(booking, officeId, officeTz))}

            {bookings.length >= 4 ? (
              <div onClick={() => this.showMoreBookingsBtn(officeId)} className="colleague-booking-date">
                <button type="button" className="dashboard-button dashboard-button--border">
                  {showMoreBookingsArray.indexOf(officeId) > -1
                    ? _.get(translations, 'myBookings.showLess', '')
                    : _.get(translations, 'myBookings.showMore', '')}
                </button>
              </div>
            ) : (
              <div />
            )}
          </div>
        </div>
      </div>
    );
  }

  buildBookingDetails(booking, officeId, officeTz) {
    const { expandedBooking } = this.state;
    const { heroData, translations } = this.props;
    const bookingId = _.get(booking, 'id', null);
    const heroName = _.get(heroData, 'first_name', null);
    let currentBooking;
    const startDate = moment(_.get(booking, 'from_date', null))
      .locale(lang)
      .format('MMM D, YYYY');
    const endDate = moment(_.get(booking, 'to_date', null))
      .locale(lang)
      .format('MMM D, YYYY');
    const weekDays = _.get(booking, 'weekDays', null);
    const bookingDate = startDate === endDate ? startDate : `${startDate} - ${endDate}`;
    if (_.get(booking, 'is_checked_out', null)) {
      currentBooking = (
          <span className="colleague-booking-status">{_.get(translations, 'whiteLabel.checkedOutLabel', 'Checked out')}</span>
      );
    } else if (_.get(booking, 'is_current_booking', null)) {
      currentBooking = (
        <span className="colleague-booking-status">{_.get(translations, 'whiteLabel.currentBooking', 'Current booking')}</span>
      );
    }

    return (
      <div className="colleague-booking-details">
        <div onClick={() => this.expandBookingBtn(bookingId)} className="colleague-booking-date">
          {bookingDate}
          <img src={accordionSvg} className={expandedBooking !== bookingId ? 'accordion__icon' : 'accordion__icon rotate'} alt="Expand" />
        </div>
        <div className="colleague-booking-type working-days">
          <Truncate
            title={getWorkDays(weekDays, _.get(translations, 'location.weekDaysMap', []))}
            lines={1}
            ellipsis={<>...</>}
            width={230}
          >
            {getWorkDays(weekDays, _.get(translations, 'location.weekDaysMap', []))}
          </Truncate>
        </div>
        <div className="colleague-booking-type">
          {_.get(booking, 'product_type', null)} {currentBooking}
        </div>
        {expandedBooking === bookingId ? <>{this.showBookingNextToBtn(heroName, booking, officeId, officeTz)}</> : null}
      </div>
    );
  }

  render() {
    const { heroBookings, translations } = this.props;
    if (!heroBookings.length) {
      return (
        <div className="no-bookings">
          <div className="no-bookings-title">
            {_.get(translations, 'whiteLabel.heroBookingsNoBookings', 'whiteLabel.heroBookingsNoBookings')}
          </div>
        </div>
      );
    }
    return (
      <div className="">
        <p className="colleague-booking-section">{_.get(translations, 'whiteLabel.bookings', 'Bookings')}</p>
        <div className="colleague-container">{heroBookings.map(this.buildingBuildingData)}</div>
      </div>
    );
  }
}

export default withRouter(HeroBookings);
