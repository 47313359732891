import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import _ from 'lodash';

import Dropdown from './dropdown';
import DatePicker from '../datePicker/datePicker';
import CheckAvailabilityTable from './checkAvailabilityTable';
import { DATE_FORMAT, CHECK_AVAILABILITY_NUMBER_OF_MONTHS, OFFICE_TYPE_EVENT, DATE_FORMAT_CHECK_AVAILABILITY } from '../../constants';
import { fetchCheckAvailability } from '../../actions/locationActions';

import './css/checkAvailability.scss';
import getLang from '../../utils/lang';

const lang = getLang() !== null ? getLang() : 'en';

class CheckAvailability extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      date: undefined,
      months: [],
      monthsFormatted: [],
      selectedMonth: '',
      maxDate: null,
    };

    this.onDateChange = this.onDateChange.bind(this);
    this.onMonthChange = this.onMonthChange.bind(this);
  }

  componentDidMount() {
    const { fetchCheckAvailability, officeId } = this.props;
    moment.locale(lang);
    const now = moment();
    now.utc();
    const months = this.constructor.getNextAvailabileMonths(CHECK_AVAILABILITY_NUMBER_OF_MONTHS);
    const maxDate = moment(now);
    maxDate.add(6, 'month');
    maxDate.startOf('day');
    this.setState(
      {
        date: now.format(DATE_FORMAT),
        months,
        monthsFormatted: months.map(this.constructor.formatMomentToStringMonth),
        selectedMonth: months.length ? this.constructor.formatMomentToStringMonth(months[0]) : '',
        maxDate: maxDate.toDate(),
      },
      () => fetchCheckAvailability(officeId, now)
    );
  }

  static getNextAvailabileMonths(count) {
    const start = moment();
    start.startOf('month');
    const months = [start];
    for (let i = 0; i < count - 1; i++) {
      const nextMonth = moment(months[i]);
      nextMonth.locale(lang);
      nextMonth.add(1, 'month');
      months.push(nextMonth);
    }

    return months;
  }

  static formatMomentToStringMonth(month) {
    return month.format('MMMM');
  }

  onDateChange(changedDate) {
    const { fetchCheckAvailability, officeId } = this.props;
    const { date, months } = this.state;
    const newDate = moment(changedDate);
    const newState = {
      date: newDate.format(DATE_FORMAT),
    };
    if (!newDate.isSame(moment(date, DATE_FORMAT), 'month')) {
      for (let i = 0; i < months.length; i++) {
        if (months[i].isSame(newDate, 'month')) {
          newState.selectedMonth = this.constructor.formatMomentToStringMonth(months[i]);
          break;
        }
      }
    }
    this.setState(newState, () => fetchCheckAvailability(officeId, newDate));
  }

  onMonthChange(changedMonth) {
    const { fetchCheckAvailability, officeId } = this.props;
    const { months, monthsFormatted } = this.state;
    if (!months.length) {
      return;
    }
    let newMonth;
    for (let i = 0; i < monthsFormatted.length; i++) {
      if (monthsFormatted[i] === changedMonth) {
        newMonth = months[i];
        break;
      }
    }
    if (!newMonth) {
      return;
    }
    const selectedMonthBase = moment(newMonth);
    selectedMonthBase.locale(lang);
    const selectedMonth = this.constructor.formatMomentToStringMonth(selectedMonthBase);
    const today = moment();
    today.locale(lang);
    if (newMonth.isBefore(today)) {
      today.locale(false);
      return this.setState({ date: moment(today).format(DATE_FORMAT), selectedMonth }, () => fetchCheckAvailability(officeId, today));
    }
    if (!newMonth.isSame(today, 'month')) {
      newMonth.locale(false);
      return this.setState({ date: moment(newMonth).format(DATE_FORMAT), selectedMonth }, () => fetchCheckAvailability(officeId, newMonth));
    }
  }

  render() {
    const { closed, hidden, trans, toggle, checkAvailabilityData } = this.props;
    const { date, maxDate, monthsFormatted, selectedMonth } = this.state;
    const selectADayToCheckAvailabilityTrans = _.get(
      trans,
      'location.checkAvailability.selectADayToCheckAvailability',
      'Select a day to check availability'
    );
    const selectAMonthTrans = _.get(trans, 'location.checkAvailability.selectAMonth', 'Select a month');

    if (hidden) {
      return null;
    }
    return (
      <div className={`check-availability ${closed ? 'check-availability--closed' : ''}`}>
        <div className="check-availability__container">
          <div className="check-availability__toggle" onClick={toggle}>
            <i className="icon icon-arrow" />
          </div>
          <div className="check-availability__content">
            <div className="check-availability__action">
              <div className="check-availability__date-container">
                <DatePicker
                  startDate={date}
                  maxDate={maxDate}
                  onChange={this.onDateChange}
                  inline
                  single
                  placeholder={selectADayToCheckAvailabilityTrans}
                  trans={trans}
                />
              </div>
              <div className="check-availability__month-container">
                <Dropdown
                  placeHolder={selectAMonthTrans}
                  onChange={this.onMonthChange}
                  options={monthsFormatted}
                  selected={selectedMonth}
                />
              </div>
            </div>
            <div className="check-availability__display">
              <CheckAvailabilityTable data={checkAvailabilityData} trans={trans} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

CheckAvailability.propTypes = {
  closed: PropTypes.bool.isRequired,
  hidden: PropTypes.bool.isRequired,
  trans: PropTypes.object.isRequired,
  toggle: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  checkAvailabilityData: state.addBooking.get('checkAvailability'),
});

const mapDispatchProps = dispatch => ({
  fetchCheckAvailability: (officeId, date) =>
    dispatch(fetchCheckAvailability(officeId, date.format(DATE_FORMAT_CHECK_AVAILABILITY), OFFICE_TYPE_EVENT)),
});

export default connect(mapStateToProps, mapDispatchProps)(CheckAvailability);
