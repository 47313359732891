import React, { Component } from 'react';
import classnames from 'classnames';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
import 'react-datepicker/dist/react-datepicker.css';
import PositionSection from '../position-section';
import ProfileInputField from '../profile-input-field';
import VerticalPicker from '../vertical-picker';
import ProfileButtons from '../profile-buttons';
import ComunicationEmail from '../comunication-email';

class ProfileForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isChecked: null,
    };
    this.goToRef = React.createRef();
    this.onInputChange = this.onInputChange.bind(this);
    this.onDateChange = this.onDateChange.bind(this);
    this.onCheckboxChange = this.onCheckboxChange.bind(this);
    this.onVerticalClick = this.onVerticalClick.bind(this);
    this.onGoalClick = this.onGoalClick.bind(this);
    this.onDefaultVerticalClick = this.onDefaultVerticalClick.bind(this);
    this.saveHeroProfileData = this.saveHeroProfileData.bind(this);
    this.addPositionSection = this.addPositionSection.bind(this);
    this.removePositionSection = this.removePositionSection.bind(this);
  }

  componentDidMount() {
    const { fetchHeroProfile } = this.props;
    fetchHeroProfile();
  }

  onInputChange(event, path) {
    const { heroProfile, setHeroProfile } = this.props;
    const { value } = event.target;
    _.set(heroProfile, path, value);
    setHeroProfile(heroProfile);
  }

  onDateChange(date, path) {
    const { heroProfile, setHeroProfile } = this.props;
    if (date) {
      _.set(heroProfile, `${path}Month`, date.getMonth() + 1);
      _.set(heroProfile, `${path}Year`, date.getFullYear());
    } else {
      _.set(heroProfile, `${path}Month`, null);
      _.set(heroProfile, `${path}Year`, null);
    }
    setHeroProfile(heroProfile);
  }

  onCheckboxChange() {
    const { heroProfile, setHeroProfile } = this.props;
    const isChecked = this.state.isChecked !== null ? this.state.isChecked : heroProfile.communicationBusinessEmail;
    if (isChecked) {
      heroProfile.businessEmail = null;
      heroProfile.communicationBusinessEmail = false;
      heroProfile.communicationPersonalEmail = true;
    } else {
      heroProfile.communicationBusinessEmail = true;
      heroProfile.communicationPersonalEmail = false;
    }
    this.setState({ isChecked: !isChecked });
    setHeroProfile(heroProfile);
  }

  onVerticalClick(id) {
    const { heroProfile, setHeroProfile } = this.props;
    let clickedVertical = heroProfile.heroVerticals.find(vertical => vertical.id === id);
    if (clickedVertical) {
      const index = heroProfile.heroVerticals.indexOf(clickedVertical);
      heroProfile.heroVerticals.splice(index, 1);
    } else {
      clickedVertical = heroProfile.allVerticals.find(vertical => vertical.id === id);
      heroProfile.heroVerticals.push(clickedVertical);
    }
    setHeroProfile(heroProfile);
  }

  onDefaultVerticalClick(id) {
    const { heroProfile, setHeroProfile } = this.props;
    heroProfile.defaultVertical = heroProfile.allVerticals.find(vertical => vertical.id === id);
    setHeroProfile(heroProfile);
  }

  onGoalClick(id) {
    const { heroProfile, setHeroProfile } = this.props;
    let clickedGoal = heroProfile.heroGoals.find(vertical => vertical.id === id);
    if (clickedGoal) {
      const index = heroProfile.heroGoals.indexOf(clickedGoal);
      heroProfile.heroGoals.splice(index, 1);
    } else {
      clickedGoal = heroProfile.allGoals.find(vertical => vertical.id === id);
      heroProfile.heroGoals.push(clickedGoal);
    }
    setHeroProfile(heroProfile);
  }

  saveHeroProfileData(goTo = null) {
    const { heroProfile, saveHeroProfile, history, fetchProfile } = this.props;
    const args = {};
    args.communicationBusinessEmail = _.get(heroProfile, 'communicationBusinessEmail');
    args.communicationPersonalEmail = _.get(heroProfile, 'communicationPersonalEmail');
    args.businessEmail = _.get(heroProfile, 'businessEmail');
    args.positions = heroProfile.positions.map(position => {
      return {
        id: _.get(position, 'id'),
        company: _.get(position, 'company'),
        title: _.get(position, 'title'),
        summary: _.get(position, 'summary'),
        startYear: _.get(position, 'startYear'),
        startMonth: _.get(position, 'startMonth'),
        endYear: _.get(position, 'endYear'),
        endMonth: _.get(position, 'endMonth'),
      };
    });
    args.heroVerticals = _.get(heroProfile, 'heroVerticals');
    args.defaultVertical = _.get(heroProfile, 'defaultVertical');
    args.heroGoals = _.get(heroProfile, 'heroGoals');
    args.language = _.get(heroProfile, 'language');
    const communityJoinUrl = localStorage.getItem('community-join-url');
    saveHeroProfile(args).then(() => {
      fetchProfile();
      localStorage.setItem('lang', _.get(heroProfile, 'language'));
      if (communityJoinUrl) {
        localStorage.removeItem('community-join-url');
        history.push(communityJoinUrl);
        return;
      }
      if (goTo) {
        history.replace({ pathname: goTo, fromUrl: history.location.fromUrl });
        return;
      }
      if (history.location.fromUrl) {
        history.push(history.location.fromUrl);
      }
      history.push('/dashboard');
    });
  }

  addPositionSection() {
    const { heroProfile, setHeroProfile } = this.props;
    const positions = _.get(heroProfile, 'positions', [{}]);
    const positionsLength = positions.length;
    positions.push({
      id: null,
      company: '',
      title: '',
      startMonth: null,
      startYear: null,
      endMonth: null,
      endYear: null,
      summary: '',
      singlePosition: positionsLength === 0,
    });
    _.set(heroProfile, 'positions', positions);
    setHeroProfile(heroProfile);
    setTimeout((ref = this.goToRef) => {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }, 100);
  }

  removePositionSection(id) {
    const { heroProfile, setHeroProfile } = this.props;
    const positions = _.get(heroProfile, 'positions', [{}]);
    _.remove(positions, position => position.positionId === id);
    _.set(heroProfile, 'positions', positions);
    setHeroProfile(heroProfile);
  }

  render() {
    const { heroProfile, errors, translations } = this.props;

    const firstName = _.get(heroProfile, 'firstName', '');
    const lastName = _.get(heroProfile, 'lastName', '');
    const email = _.get(heroProfile, 'email', '');
    const businessEmail = _.get(heroProfile, 'businessEmail', '');
    const profileCompleted = _.get(heroProfile, 'profileCompleted', false);
    const positions = _.get(heroProfile, 'positions', [{}]);
    if (!_.isEmpty(positions)) {
      positions.map((position, index) => _.set(position, 'positionId', index));
    } else {
      this.addPositionSection();
    }
    const allVerticals = _.get(heroProfile, 'allVerticals', null);
    const heroVerticals = _.get(heroProfile, 'heroVerticals', null);
    const defaultVertical = _.get(heroProfile, 'defaultVertical', null);
    const allGoals = _.get(heroProfile, 'allGoals', null);
    const heroGoals = _.get(heroProfile, 'heroGoals', null);

    const isChecked = this.state.isChecked !== null ? this.state.isChecked : heroProfile.communicationBusinessEmail;

    return (
      <form className="profile-form__form">
        <div className="profile-form__left" ref={this.goToRef}>
          <div className="profile-form__row">
            <div className="profile-form__columns">
              <ProfileInputField label={_.get(translations, 'openAuth.firstName', '')} name="firstName" value={firstName} />
              <ProfileInputField label={_.get(translations, 'openAuth.surname', '')} name="lastName" value={lastName} changeable={false} />
            </div>
          </div>
          <div className="profile-form__row">
            <ProfileInputField label={_.get(translations, 'profile.primaryEmail', '')} name="email" value={email} changeable={false} />
          </div>
          <div className="profile-form__row profile-form__row-margin-top">
            <span
              className={classnames('profile-form__checkbox', { 'profile-form__input-value': isChecked })}
              onClick={this.onCheckboxChange}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className={classnames('icon', { 'icon-checked': isChecked }, { 'icon-not-checked': !isChecked })}
              >
                <polyline points="13 3 7 12 3 8" />
              </svg>
            </span>
            <div
              className={classnames('profile-form__label profile-form__label-checkbox', {
                'profile-form__label-checkbox-checked': isChecked,
              })}
            >
              {_.get(translations, 'profile.differentEmail', '')}
            </div>
          </div>
          {isChecked ? (
            <div className="profile-form__row ">
              <ProfileInputField
                label=""
                name="businessEmail"
                placeholder={_.get(translations, 'profile.placeholderBusinessEmail', '')}
                value={businessEmail}
                changeable
                onInputChange={this.onInputChange}
                path="businessEmail"
                error={!!errors.businessEmail}
              />
            </div>
          ) : null}

          {positions.map(position => (
            <PositionSection
              key={position.positionId}
              position={position}
              onInputChange={this.onInputChange}
              onDateChange={this.onDateChange}
              errors={errors}
              onDelete={this.removePositionSection}
            />
          ))}
          {/* <CommunicationEmail /> */}

          <ProfileButtons
            profileCompleted={profileCompleted}
            addPositionSection={this.addPositionSection}
            saveHeroProfileData={this.saveHeroProfileData}
          />
        </div>
        <div className="profile-form__right">
          <ProfileButtons
            isTop
            profileCompleted={profileCompleted}
            addPositionSection={this.addPositionSection}
            saveHeroProfileData={this.saveHeroProfileData}
          />
          {allVerticals ? (
            <>
              <VerticalPicker
                key="heroVerticals"
                question={_.get(translations, 'profile.verticalText', '')}
                allVerticals={allVerticals}
                checkedVerticals={heroVerticals}
                onVerticalClick={this.onVerticalClick}
                error={!!errors.heroVerticals}
              />
              <VerticalPicker
                key="defaultVertical"
                question={_.get(translations, 'profile.defaultVerticalText', '')}
                allVerticals={allVerticals}
                checkedVerticals={[defaultVertical]}
                onVerticalClick={this.onDefaultVerticalClick}
                error={!!errors.defaultVertical}
              />
            </>
          ) : null}
          {allGoals ? (
            <VerticalPicker
              key="goals"
              question={_.get(translations, 'profile.goalsText', '')}
              allVerticals={allGoals}
              checkedVerticals={heroGoals}
              onVerticalClick={this.onGoalClick}
              error={!!errors.heroGoals}
            />
          ) : null}
        </div>
      </form>
    );
  }
}

ProfileForm.propTypes = {
  heroProfile: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    businessEmail: PropTypes.string,
    communicationBusinessEmail: PropTypes.bool,
    communicationPersonalEmail: PropTypes.bool,
    positions: PropTypes.arrayOf(),
    allVerticals: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
      })
    ),
    heroVerticals: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
      })
    ),
    defaultVertical: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
    allGoals: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
      })
    ),
    heroGoals: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
      })
    ),
  }),
  fetchHeroProfile: PropTypes.func.isRequired,
  setHeroProfile: PropTypes.func.isRequired,
  saveHeroProfile: PropTypes.func.isRequired,
};
ProfileForm.defaultProps = {
  heroProfile: {},
};

export default withRouter(ProfileForm);
