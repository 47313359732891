import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Elements, StripeProvider } from 'react-stripe-elements';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import InjectedCardForm from '../../accountAddEditPaymentProfile/CardForm';
import checkSymbol from '../../../assets/svg/check-symbol.svg';

const lang = localStorage.getItem('lang') !== null && localStorage.getItem('lang') !== 'default' ? localStorage.getItem('lang') : 'en';

class PaymentMethods extends Component {
  constructor(props) {
    super(props);
    this.state = {
      voucher: '',
      paymentType: null,
      cardConnected: false,
      showCardForm: false,
      paymentProfileErrors: null,
      voucherApplied: false,
      stripeResult: null,
      voucherInfo: null,
      voucherError: null,
    };

    this.handleOptionChange = this.handleOptionChange.bind(this);
    this.renderCreditCardSection = this.renderCreditCardSection.bind(this);
    this.renderVoucherSection = this.renderVoucherSection.bind(this);
    this.applyVoucher = this.applyVoucher.bind(this);
    this.removeVoucher = this.removeVoucher.bind(this);
    this.removeCreditCard = this.removeCreditCard.bind(this);
    this.updateValidating = this.updateValidating.bind(this);
    this.updateStripeToken = this.updateStripeToken.bind(this);
    this.handleContinueToBooking = this.handleContinueToBooking.bind(this);
    this.enableContinueToBookingButton = this.enableContinueToBookingButton.bind(this);
    this.handleCancelButton = this.handleCancelButton.bind(this);
    this.redirectToBooking = this.redirectToBooking.bind(this);
  }

  handleOptionChange(e) {
    this.setState({
      paymentType: parseInt(e.target.value, 10),
    });
  }

  handleCancelButton() {
    const { history, onChangeSection, resetBooking } = this.props;
    const previousLocation = _.get(history, 'location.state.from', '/dashboard');
    resetBooking();
    onChangeSection('options');
    history.push(previousLocation);
  }

  enableContinueToBookingButton() {
    const { stripeResult, paymentType, voucherApplied } = this.state;
    if (stripeResult || voucherApplied || paymentType === 1) return 1;
    return 0;
  }

  handleContinueToBooking() {
    const { accountData, createHeroPaymentProfile, fetchPaymentProfiles, firstPaymentProfile, createPaymentMethod } = this.props;
    const { paymentType, stripeResult, voucherInfo } = this.state;

    if (!firstPaymentProfile) this.props.handleContinueClick();

    const stripeId = _.get(stripeResult, 'id', null);

    if (paymentType === 0) {
      accountData.type = 'stripe';
      accountData.token = stripeId;
    }

    if (paymentType === 1) {
      accountData.type = 'bancontact';
    }

    if (paymentType === 2) {
      accountData.type = 'voucher';
    }

    accountData.payment_method = {
      profile_id: _.get(accountData, 'paymentProfileId', null),
      is_default: _.get(accountData, 'isDefault', 0),
      type: accountData.type,
      card: _.get(stripeResult, 'card', {}),
      voucher: voucherInfo,
      stripeToken: _.get(stripeResult, 'id', ''),
    };
    // create payment method for selected profile
    if (accountData.paymentProfileId) {
      createPaymentMethod(accountData, data => {
        if (_.get(data, 'success', false)) {
          this.redirectToBooking();
        } else {
          const { error } = data;
          this.setState({ paymentProfileErrors: error });
        }
      });
      return true;
    }

    if (paymentType === 2 && stripeId) {
      accountData.type = 'stripe';
      accountData.token = stripeId;
      createHeroPaymentProfile(accountData, data => {
        if (_.get(data, 'success', false)) {
          fetchPaymentProfiles();
          this.redirectToBooking();
        } else {
          const { error } = data;
          this.setState({ paymentProfileErrors: error });
        }
      });
      return true;
    }

    createHeroPaymentProfile(accountData, data => {
      if (_.get(data, 'success', false)) {
        fetchPaymentProfiles();
        this.redirectToBooking();
      } else {
        const { error } = data;
        this.setState({ paymentProfileErrors: error });
      }
    });

    return true;
  }

  redirectToBooking() {
    if (!this.props.firstPaymentProfile) {
      const redirectTo = this.props.location.state ? this.props.location.state.from : '/';
      this.props.openBookingOptions();
      this.props.onChangeSection('payment');
      this.props.history.push(redirectTo);
    }
    return true;
  }

  applyVoucher() {
    const { voucher } = this.state;
    const { validateVoucher } = this.props;

    if (voucher) {
      validateVoucher(voucher)
        .then(response => {
          const voucherStatus = _.get(response, 'status', false);
          if (voucherStatus === 'active') {
            this.setState({ voucherInfo: response, voucherApplied: true, voucherError: null });
          } else {
            const voucherError = _.get(response, 'error.details', null) || _.get(response, 'error.message', null);
            this.setState({ voucherError, voucherApplied: false });
          }
        })
        .catch(response => {
          const voucherError = _.get(response, 'message', null);
          this.setState({ voucherInfo: { response }, voucherError, voucherApplied: false });
        });
    }
  }

  removeVoucher() {
    this.setState({ voucherApplied: false, voucher: '' }, () => {});

    this.setState({ voucherApplied: false, voucherInfo: null, voucher: '' });
  }

  removeCreditCard() {
    this.setState({ stripeResult: null, cardConnected: false, paymentProfileErrors: null });
  }

  updateValidating() {
    this.setState({ stripeResult: null, showCardLoader: true });
  }

  updateStripeToken(token) {
    this.setState({ stripeResult: token, cardConnected: true, showCardLoader: false });
  }

  renderCreditCardSection() {
    const { cardConnected, showCardForm, paymentProfileErrors } = this.state;
    const { translations } = this.props;

    if (cardConnected) {
      return (
        <>
          <div className="payment-method_cc">
            <div className="card-connected">
              <div className="credit-card-connected">
                <img src={checkSymbol} className="check-symbol" alt="Check" />
                {_.get(translations, 'creditCardConnected', 'Credit Card Connected')}
              </div>
              <button onClick={this.removeCreditCard} className="credit-card-remove-button remove-voucher" type="button">
                {_.get(translations, 'removeCreditCard', 'Remove Credit Card')}
              </button>
            </div>
          </div>
          {paymentProfileErrors ? <div className="card-error-text">{paymentProfileErrors}</div> : ''}
        </>
      );
    }

    if (showCardForm) {
      return (
        <div className="payment-method_cc">
          <StripeProvider apiKey={window.__env.stripeApiKey}>
            <Elements locale={lang}>
              <InjectedCardForm
                updateValidating={this.updateValidating}
                updateStripeToken={this.updateStripeToken}
                customerName={`${localStorage.getItem('first-name')} ${localStorage.getItem('last-name')}`}
                buttonText="Add Card"
                trans={translations}
              />
            </Elements>
          </StripeProvider>
        </div>
      );
    }

    return (
      <div className="payment-method_cc">
        <div className="add-cc-container">
          <span className="card-title">{_.get(translations, 'connectYourCC', 'Connect your Credit Card')}</span>
          <div className="spacer" />
          <button
            type="button"
            className="card-button"
            onClick={() => {
              this.setState({ showCardForm: true });
            }}
          >
            {_.get(translations, 'connectCC', 'Connect Credit Card')}
          </button>
        </div>
      </div>
    );
  }

  renderVoucherSection() {
    const { voucher, voucherInfo, voucherError, voucherApplied } = this.state;
    const { translations, formatString } = this.props;

    const errorSection = voucherError ? (
      <div className="voucher-errors-container">
        <div className="error-item">{voucherError}</div>
      </div>
    ) : null;

    const voucherBalance = _.get(voucherInfo, 'balance', 0);

    let voucherConfirmation = null;
    if (voucherInfo && voucherApplied) {
      voucherConfirmation = (
        <div className="payment-method_cc voucher-added-group">
          <div className="voucher-applied">{_.get(translations, 'applyVoucher', 'Apply Voucher')}</div>
          <div className="voucher-balance">
            <img src={checkSymbol} className="check-symbol" alt="Check" />
            {_.get(translations, 'remainOnVoucher', null)
              ? formatString(translations.remainOnVoucher, [
                  voucherBalance
                    .toFixed(2)
                    .toString()
                    .replace('.', window.__env.decimalSeparator),
                ])
              : `Voucher balance ${voucherBalance
                  .toFixed(2)
                  .toString()
                  .replace('.', window.__env.decimalSeparator)} euros`}
          </div>
          <button className="remove-voucher" type="button" onClick={this.removeVoucher}>
            {_.get(translations, 'removeVoucher', 'Remove voucher')}
          </button>
        </div>
      );
    }
    return (
      <>
        <div className="payment-method_voucher">
          <input
            id="voucher-input"
            type="text"
            className={`payment-info-input voucher-input ${voucher ? 'payment-info-input-completed' : ''}`}
            name="voucher"
            value={voucher}
            placeholder={_.get(translations, 'placeholderVoucher', '')}
            onChange={e => {
              this.setState({ voucher: e.target.value });
            }}
          />
          <button type="button" className="voucher-button" onClick={this.applyVoucher}>
            {_.get(translations, 'applyVoucher', 'Apply Voucher')}
          </button>
        </div>
        {errorSection}
        {voucherConfirmation}
      </>
    );
  }

  render() {
    const { paymentType } = this.state;
    const { translations, firstPaymentProfile, accountData } = this.props;
    const enableContinueToBookingButton = this.enableContinueToBookingButton();
    const accountType = _.get(accountData, 'account_type', 'personal');

    return (
      <div className="select-payment-methods">
        <div className="account-types">
          <div className="account-type_title">{_.get(translations, 'selectPaymentMethod', 'Select payment method')}</div>
          <div className="text-field__content">
            <div className="account-type-select">
              <label htmlFor="cc_method">
                <input
                  id="cc_method"
                  type="radio"
                  name="payment_type"
                  value={0}
                  checked={paymentType === 0}
                  onChange={this.handleOptionChange}
                  className="radio-button"
                />
                <span>{_.get(translations, 'CreditCard', 'Credit card')}</span>
              </label>
            </div>
            <div className="account-type-select">
              <label htmlFor="bc_method">
                <input
                  id="bc_method"
                  type="radio"
                  name="payment_type"
                  value={1}
                  checked={paymentType === 1}
                  onChange={this.handleOptionChange}
                  className="radio-button"
                />
                <span>{_.get(translations, 'Maestro', 'Maestro')}</span>
              </label>
            </div>
            {accountType === 'business' && (
              <div className="account-type-select">
                <label htmlFor="voucher_method">
                  <input
                    id="voucher_method"
                    type="radio"
                    name="payment_type"
                    value={2}
                    checked={paymentType === 2}
                    onChange={this.handleOptionChange}
                    className="radio-button"
                  />
                  <span>{_.get(translations, 'voucher', 'Voucher')}</span>
                </label>
              </div>
            )}
          </div>
        </div>
        {paymentType === 0 && this.renderCreditCardSection()}
        {paymentType === 2 && this.renderVoucherSection()}
        <div className="account-type-buttons">
          {firstPaymentProfile ? (
            <button
              className={`payment-method-btn ${enableContinueToBookingButton ? 'continue-to-payment' : 'continue-to-payment-disabled'}`}
              type="button"
              onClick={() => {
                this.handleContinueToBooking();
              }}
              disabled={!enableContinueToBookingButton}
            >
              {_.get(translations, 'save', '')}
            </button>
          ) : (
            <>
              <button
                className={`payment-method-btn ${enableContinueToBookingButton ? 'continue-to-payment' : 'continue-to-payment-disabled'}`}
                type="button"
                onClick={() => {
                  this.handleContinueToBooking();
                }}
                disabled={!enableContinueToBookingButton}
              >
                {_.get(translations, 'continueToBooking', 'Continue to booking')}
              </button>
              <button
                className="cancel-booking-payment"
                type="button"
                onClick={() => {
                  this.handleCancelButton();
                }}
              >
                {_.get(translations, 'bookLater', "I don't want to book now")}
              </button>
            </>
          )}
        </div>
      </div>
    );
  }
}
PaymentMethods.propTypes = {
  translations: PropTypes.shape({}),
  firstPaymentProfile: PropTypes.bool,
};

PaymentMethods.defaultProps = {
  translations: {},
  firstPaymentProfile: false,
};

export default withRouter(PaymentMethods);
