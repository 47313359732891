/*
 * Constants
 */
import api from '../../components/myMeetings/utils/api';

export const SET_FLOOR_PLANS = 'SET_FLOOR_PLANS';
export const SET_SELECTED_FLOOR_INDEX = 'SET_SELECTED_FLOOR_INDEX';
export const SET_BUILDING_HEROES = 'SET_BUILDING_HEROES';
export const SET_FEATURES = 'SET_FEATURES';
export const SET_SELECTED_FEATURES = 'SET_SELECTED_FEATURES';

/*
 * Sync Actions
 */
export const setFloorPlans = floorPlans => ({
  type: SET_FLOOR_PLANS,
  floorPlans,
});

export const setSelectedFloorIndex = selectedFloorIndex => ({
  type: SET_SELECTED_FLOOR_INDEX,
  selectedFloorIndex,
});

export const setBuildingHeroes = buildingHeroes => ({
  type: SET_BUILDING_HEROES,
  buildingHeroes,
});

export const setFeatures = features => ({
  type: SET_FEATURES,
  features,
});

export const setSelectedFeatures = features => ({
  type: SET_SELECTED_FEATURES,
  features,
});
/*
 * Async Actions
 */
export const fetchBuildingHeroes = officeId => dispatch => {
  return new Promise((resolve, reject) => {
    api
      .get(`whitelabel/heroes/officeid/${officeId}`)
      .then(({ data = {} }) => {
        if (data) {
          dispatch(setBuildingHeroes(data));
          resolve(data);
        }
      })
      .catch(() => {})
      .finally(() => {});
  });
};
