import { connect } from 'react-redux';
import _ from 'lodash';
import LoginForm from './LoginForm';
import { login, setHeroDataAfterLogin } from '../../../actions/openAuth/login';
import { translations } from '../../../selectors/translations';
import { setIsWhiteLabel } from '../../../actions/global';

const mapStateToProps = state => ({
  translations: _.get(translations(state), 'openAuth', {}),
});

const mapDispatchProps = dispatch => ({
  login: data => dispatch(login(data)),
  setHeroDataAfterLogin: data => dispatch(setHeroDataAfterLogin(data)),
  setIsWhiteLabel: isWhiteLabel => dispatch(setIsWhiteLabel(isWhiteLabel)),
});

export default connect(mapStateToProps, mapDispatchProps)(LoginForm);
