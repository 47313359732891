import bookingRecommendation from './booking-recommendation';
import heroRecommendation from './hero-recommendation';
import newCompanyProfile from './new-company-profile';
import mediaCompanyProfile from './media-company-profile';
import mediaFilters from './media-filters';
import mediaArticle from './media-article';
import news from './news';
import upcomingBookings from './upcoming-bookings';
import mostActiveHeroes from './most-active-heroes';
import coCreationProjects from './co-creation-projects';
import communityActivity from './community-activity';
import similarMedia from './similar-media';
import submissionForm from './submission-form';
import toasts from './toasts';

export default {
  bookingRecommendation,
  heroRecommendation,
  newCompanyProfile,
  mediaCompanyProfile,
  mediaFilters,
  mediaArticle,
  upcomingBookings,
  mostActiveHeroes,
  coCreationProjects,
  communityActivity,
  news,
  similarMedia,
  submissionForm,
  toasts,
};
