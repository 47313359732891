/**
 * Renders the contact visual
 * @module DirectChat
 * @class Contact
 */
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import TimeAgo from '../../timeAgo/timeAgo';

class Contact extends Component {
  // componentDidMount();

  render() {
    const avatarStyle =
      this.props.item.getIn(['hero', 'profilePictureUrl']) !== null
        ? {
            backgroundSize: 'cover',
            backgroundImage: `url('${this.props.item.getIn(['hero', 'profilePictureUrl'])}')`,
          }
        : {};

    let newMessages = false;
    if (this.props.heroProfile !== undefined && this.props.heroProfile !== null) {
      if (this.props.item.get('isRead') !== true && this.props.item.getIn(['lastMessage', 'heroId']) !== this.props.heroProfile.id) {
        newMessages = true;
      }
    }

    const heroInitials = this.props.item.getIn(['hero', 'heroInitials']);

    return (
      <div
        className={classnames('directChat-contacts-conversation', {
          active: this.props.item.get('active') === true,
          newMessages: newMessages === true,
          muted: this.props.item.get('muted') === true,
        })}
        onClick={e => {
          // this.props.setScroll();
          this.props.actions.openConversationAndFetch(this.props.item.getIn(['hero', 'id']), this.props.item.get('id'));
          // this.props.actions.openConversation(this.props.item.getIn(['hero', 'id']));
          // this.props.actions.fetchConversationMessages(this.props.item.get('id'));
        }}
      >
        {this.props.item.getIn(['hero', 'profilePictureUrl']) ? (
          <div
            className={classnames('directChat-contacts-conversationAvatar', 'directChat-contacts-conversationImage', {
              defaultAvatar2: this.props.item.getIn(['hero', 'profilePictureUrl']) === null,
            })}
            style={avatarStyle}
          />
        ) : (
          <div className="hero-initials direct-message-hero-initials">{heroInitials}</div>
        )}

        <div className="directChat-contacts-conversationDetails">
          <div className="directChat-contacts-conversationPoster">
            <div className="directChat-contacts-conversationPosterName">
              {this.props.item.getIn(['hero', 'firstName'])}
              <span className="directChat-contacts-conversationPosterLastName">{` ${this.props.item.getIn(['hero', 'lastName'])}`}</span>
              {this.props.item.get('isOnline') === true ? <span className="directChat-contacts-conversationOnline" /> : undefined}
            </div>
            <span className="directChat-contacts-conversationAgo">
              <TimeAgo epoch={this.props.item.getIn(['lastMessage', 'timestamp'])} now={this.props.now} trans={this.props.trans} />
            </span>
          </div>
          <div className="directChat-contacts-conversationPreview">
            <p
              className={classnames('directChat-contacts-conversationPreviewText', {
                notMuted: this.props.item.get('muted') !== true,
              })}
            >
              {this.props.heroProfile !== undefined &&
              this.props.heroProfile !== null &&
              this.props.item.getIn(['lastMessage', 'heroId']) === this.props.heroProfile.id
                ? ''
                : ''}
              {this.props.item.getIn(['lastMessage', 'text'])}
            </p>
            {this.props.item.get('muted') === true ? <span className="directChat-contacts-conversationMuted icon icon-muted" /> : undefined}
          </div>
        </div>
        <button
          className="directChat-contacts-conversationDelete icon icon-delete"
          style={{ display: 'none' }}
          onClick={e => {
            e.stopPropagation();
            this.props.actions.deleteConversationConfirmation(this.props.item.get('id'));
          }}
        />
      </div>
    );
  }
}

Contact.propTypes = {
  /**
   * Contains all available dispatched actions for this component
   * @type {Object}
   */
  actions: PropTypes.object.isRequired,
  /**
   * Contains latest conversation data for the respective contact
   * @type {Immutable.List<Conversation>}
   */
  item: PropTypes.object.isRequired,
  /**
   * Representative date object of NOW
   * @type {Object}
   */
  now: PropTypes.object.isRequired,
};

export default Contact;
