import { connect } from 'react-redux';
import VoucherHistoryView from './voucherHistoryView';
import { fetchVoucherHistory } from '../../actions/accountPaymentSettings/vouchers';
import {
  vouchersSelector,
  vouchersLoadingSelector,
  voucherOffsetSelector,
  hasMoreSelector,
} from '../../selectors/accountPaymentSettings/vouchers';

const mapStateToProps = state => ({
  translations: state.translations,
  vouchers: vouchersSelector(state),
  offset: voucherOffsetSelector(state),
  isLoading: vouchersLoadingSelector(state),
  hasMore: hasMoreSelector(state),
});

const mapDispatchProps = dispatch => ({
  fetchVoucherHistory: () => dispatch(fetchVoucherHistory()),
});

export default connect(
  mapStateToProps,
  mapDispatchProps
)(VoucherHistoryView);
