export const MARKER_CLICK               = 'MARKER_CLICK';
export const MARKER_CLOSE               = 'MARKER_CLOSE';
export const ON_BOUNDS_CHANGED          = 'ON_BOUNDS_CHANGED';
export const ON_LOCATION_CHANGE         = 'ON_LOCATION_CHANGE';
export const ON_LOCATION_CHANGE_SEARCH  = 'ON_LOCATION_CHANGE_SEARCH';
export const ON_RADIUS_CHANGE           = 'ON_RADIUS_CHANGE';
export const ON_ZOOM_CHANGED            = 'ON_ZOOM_CHANGED';
export const SET_ZOOM_TO_FIT_APPLIED    = 'SET_ZOOM_TO_FIT_APPLIED';

export const markerClick = (markerID, location, googleMap) => {
  return {
    type: MARKER_CLICK,
    markerID,
    location,
    googleMap
  }
};

export const markerClose = (markerID) => {
  return {
    type: MARKER_CLOSE,
    markerID
  }
};

export const setZoomToFitApplied = (applied = true) => {
  return {
    type: SET_ZOOM_TO_FIT_APPLIED,
    applied
  }
};

export const onRadiusChange = (map) => {
  return {
    type: ON_RADIUS_CHANGE,
    map
  }
};

export const onBoundsChanged = (newMap) => {
  return {
    type: ON_BOUNDS_CHANGED,
    newMap
  }
};

export const onZoomChanged = (zoom) => {
  return {
    type: ON_ZOOM_CHANGED,
    zoom
  }
};


export const onLocationChange = (value) => {
  const apiPrefix = localStorage.getItem('access-key') ? '' : '/public';
  return {
    type: ON_LOCATION_CHANGE,
    url: window.__env.apiUrl + apiPrefix + "/offices/search",
    value
  }
};

export const onLocationChangeSearch = () => {
  const apiPrefix = localStorage.getItem('access-key') ? '' : '/public';
  return {
    type: ON_LOCATION_CHANGE_SEARCH,
    url: window.__env.apiUrl + apiPrefix + "/offices/search"
  }
};
