import Immutable from 'immutable';

const initialState = Immutable.Map({
  isAuthenticated: false,
  showWelcomePopUp: false,
});
export default (state = initialState, action) => {
  switch (action.type) {
    case 'SET_HERO_AUTHENTICATION':
      return state.setIn(['isAuthenticated'], action.isAuthenticated);
    case 'CLEAR_HERO_AUTHENTICATION':
      // to be discussed what data needs to be cleared after logout
      return state.setIn(['isAuthenticated'], false);
    default:
      return state;
  }
};
