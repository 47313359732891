import React, { Component } from 'react';

import './css/invitation.scss';
import { withRouter } from 'react-router-dom';

const queryString = require('query-string');

class Invitation extends Component {
  componentDidMount() {
    const { history, acceptInvitation, fetchProfile, fetchMyBookings, fetchBookingSummary, clearPopularCommunities } = this.props;
    try {
      const params = queryString.parse(window.location.search);

      if (params.invitation_key === undefined) {
        history.replace('/dashboard');
      } else {
        const data = { invitationKey: params.invitation_key };
        acceptInvitation(data)
          .then(response => {
            fetchProfile();
            fetchMyBookings();
            fetchBookingSummary();
            clearPopularCommunities();
            localStorage.removeItem('invitation-email');
            localStorage.removeItem('invitation-whitelabel');
            history.replace('/dashboard');
          })
          .catch(error => {
            history.replace('/dashboard');
          });
      }
    } catch (e) {
      // console.log('error', e);
    }
  }

  render() {
    return <div className="Loader" />;
  }
}

export default withRouter(Invitation);
