import classnames from 'classnames';
import React from 'react';

const ProfileInputField = props => {
  const { label, name, value, placeholder, changeable, onInputChange, path, error } = props;
  return (
    <div className="profile-form__field">
      {label ? <div className={classnames('profile-form__label', { 'profile-form__label-error': error })}>{label}</div> : null}
      <input
        key={value}
        className={classnames(
          'profile-form__input profile-form__text',
          { 'profile-form__input-value': value },
          { 'profile-form__input-error': error }
        )}
        type="text"
        required
        name={name}
        defaultValue={value || ''}
        placeholder={placeholder}
        onBlur={changeable ? event => onInputChange(event, path) : null}
        disabled={!changeable}
      />
    </div>
  );
};

export default ProfileInputField;
