import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { withRouter, Link } from 'react-router-dom';
import _ from 'lodash';
import classnames from 'classnames';
import Footer from './Footer';
import setCommunityUrlInLocalStorage from './utils/setCommunityUrlInLocalStorage';
import AzureLogo from '../../assets/svg/azure-ad-logo.png';
import GoogleLogo from '../../assets/svg/google-logo.svg';
import ModalDialog from '../modalDialog/modalDialog';
import LoginForm from './loginForm';

const queryString = require('query-string');

class CustomLogin extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showErrorPopup: false,
      errorCode: null,
      loading: null,
    };

    this.handleSSOButtonClick = this.handleSSOButtonClick.bind(this);
    this.closePopup = this.closePopup.bind(this);
    this.renderPopup = this.renderPopup.bind(this);
  }

  componentDidMount() {
    const { history, location, impersonate } = this.props;
    const errorHash = _.get(location, 'hash', null);
    const errorCode = errorHash && errorHash.includes('#error=') ? `${errorHash.replace('#error=', '')}` : null;
    if (errorCode) {
      this.setState({ showErrorPopup: true, errorCode });
    }

    let queryParams = _.get(location, 'search');
    if (queryParams) {
      const params = queryString.parse(queryParams);
      const impersonationKey = _.get(params, 'impersonation', null);
      const source = _.get(params, 'source', null);
      if (impersonationKey) {
        const data = { impersonationKey, source };
        this.setState({ loading: true });
        impersonate(data)
          .then(response => {
            this.setState({ loading: false });
            history.push(_.get(response, 'data.redirectTo', '/'));
          })
          .catch(error => {
            this.setState({ loading: false });
            history.push('/');
          });
      }
    } else {
      queryParams = _.get(history, 'location.state.queryParams', null);
      if (queryParams) {
        const params = queryString.parse(queryParams);
        const invitationKey = _.get(params, 'invitation_key', null);
        if (invitationKey) {
          localStorage.setItem('invitation-key', invitationKey);
        }
        const meetingKey = _.get(params, 'meeting_attendee_key', null);
        if (meetingKey) {
          localStorage.setItem('meeting-attendee-key', meetingKey);
          const meetingId = _.get(history, 'location.state.from', null).split('/')[2];
          localStorage.setItem('meeting-id', meetingId);
        }
      }
      const historyState = _.get(history, 'location.state', null);
      if (_.startsWith(_.get(historyState, 'from', null), '/community')) {
        setCommunityUrlInLocalStorage(historyState);
      }
      if (localStorage.getItem('invitation-whitelabel')) {
        this.setState({ showForm: true });
      }
    }
  }

  handleSSOButtonClick() {
    const { loginSSO } = this.props;
    loginSSO()
      .then(response => {
        if (response.loginLink) {
          window.location.href = response.loginLink;
        }
      })
      .catch(response => {
        console.log('catch');
      });
  }

  closePopup() {
    this.setState({ showErrorPopup: false, errorCode: null });
    const { history } = this.props;
    history.push('/login');
  }

  renderPopup() {
    const { translations } = this.props;
    const { errorCode, showErrorPopup } = this.state;
    const errorMessage = _.get(translations, `sso.errors.${errorCode}`, null);
    const popupTitle = _.get(translations, `sso.errors.title`, null);

    return (
      <ModalDialog modalOpened={showErrorPopup} label="cancelBooking">
        <div className="sso-errors">
          <div className="modal-close-button" onClick={this.closePopup} />
          <div className="bookedOffice-popup-title">{popupTitle}</div>
          <div className="bookedOffice-popup-body">{errorMessage}</div>
          <div className="actions">
            <button className="main-button" onClick={this.closePopup}>
              OK
            </button>
          </div>
        </div>
      </ModalDialog>
    );
  }

  render() {
    const { translations, history, location, ssoProvider } = this.props;
    const { loading } = this.state;
    const loginTranslations = _.get(translations, 'login', null);
    if (loading) {
      return <div className="Loader" />;
    }
    return (
      <Scrollbars className="styleScroll">
        <div className="open-login__container--full">
          <button type="button" onClick={() => history.goBack()} className="navigation__back-to-button">
            {_.get(translations, 'backToPreviousPage', 'Back to previous page')}
          </button>
          <div className="open-login__title">{_.get(loginTranslations, 'title', 'Log in')}</div>
          <div className="open-login__subtitle">
            {_.get(loginTranslations, 'subtitle', 'Please use your credential below to enter your account.')}
          </div>
          <div className={classnames('open-login__container--small')}>
            <div className="sso">
              <span className={classnames('wrapper')}>
                {ssoProvider && ssoProvider === 'Azure' && (
                  <button type="button" className="login-form__third-party-button azure" onClick={() => this.handleSSOButtonClick()}>
                    <img src={AzureLogo} alt="azure logo" className="azure-login-icon" />
                    <p className="auth-button-text">{_.get(loginTranslations, 'azureAction', 'Log in with Azure AD')}</p>
                  </button>
                )}
                {ssoProvider && ssoProvider === 'Google' && (
                  <button type="button" className="login-form__third-party-button google" onClick={() => this.handleSSOButtonClick()}>
                    <img src={GoogleLogo} alt="google logo" className="google-login-icon" />
                    <p className="auth-button-text">{_.get(loginTranslations, 'googleAction', 'Log in with Google')}</p>
                  </button>
                )}
              </span>
            </div>
            <LoginForm />
            <div className="open-login__account-message">
              <div className="open-login__info-message">{_.get(loginTranslations, 'footerMessage', "You don't have an account?")}</div>
              <Link
                to={{
                  pathname: '/sign-up',
                  fromUrl: _.get(location, 'fromUrl', location.pathname),
                  state: { fromBooking: _.get(location, 'state.fromBooking', false) },
                }}
                className="open-login__action-message"
              >
                {_.get(loginTranslations, 'footerAction', 'Sign up')}
              </Link>
            </div>
          </div>
          {this.renderPopup()}
        </div>
        <Footer translations={translations} />
      </Scrollbars>
    );
  }
}

export default withRouter(CustomLogin);
