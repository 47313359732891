import React from 'react';
import classnames from 'classnames';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import Search from './search/search';
import defaultAvatar from '../../assets/images/defaultAvatar.png';
import AddNewHeroForm from './addNewHero/addNewHeroForm';
import InfoToast from '../community/common/info-toast/InfoToast';
import DeleteModal from './delete/deleteModal';

class MeetingAttendeesBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showRenderAddNewHero: false,
      showSearchHero: true,
      newHeroName: null,
      showDeleteModal: false,
      selectedHero: [],
    };
    this.handleClick = this.handleClick.bind(this);
    this.renderInvitedHeroes = this.renderInvitedHeroes.bind(this);
    this.changeStateAddNewHero = this.changeStateAddNewHero.bind(this);
    this.changeStateShowSearchHero = this.changeStateShowSearchHero.bind(this);
    this.handleInviteHeroes = this.handleInviteHeroes.bind(this);
    this.renderStatusButtons = this.renderStatusButtons.bind(this);
    this.changeStateDeleteModal = this.changeStateDeleteModal.bind(this);
  }

  componentDidMount() {
    const { fetchHeroInvitations, bookingId } = this.props;
    fetchHeroInvitations(bookingId);
  }

  handleClick(hero) {
    const { addInvitedHeroToList } = this.props;
    hero.company = _.get(hero, 'position.company', '');
    addInvitedHeroToList(hero);
    this.changeStateShowSearchHero();
  }

  changeStateAddNewHero(newHero) {
    const { showRenderAddNewHero } = this.state;
    this.changeStateShowSearchHero();
    this.setState({ showRenderAddNewHero: !showRenderAddNewHero, newHeroName: newHero });
  }

  changeStateShowSearchHero() {
    const { showSearchHero } = this.state;
    this.setState({ showSearchHero: !showSearchHero });
  }

  changeStateDeleteModal() {
    const { showDeleteModal } = this.state;
    this.setState({ showDeleteModal: !showDeleteModal });
  }

  handleInviteHeroes(hero) {
    this.changeStateShowSearchHero();
    const { sendInviteToMeeting, bookingId } = this.props;
    sendInviteToMeeting({
      booking_id: bookingId,
      hero_id: hero.id,
      email: hero.email,
      first_name: hero.firstName,
      last_name: hero.lastName,
      company: hero.company,
    });
    if (!hero.id) {
      this.changeStateAddNewHero(null);
    }
  }

  renderStatusButtons(status, hero) {
    const { bookingStatus, diffInMinutes } = this.props;
    let notAllowed = '';
    if (diffInMinutes <= 0 || _.includes(['canceled', 'pending', 'closed'], bookingStatus)) {
      notAllowed = 'disabledButton';
    }
    if (!status) {
      return (
        <div className="booking-invite-member__button-section">
          <span
            type="button"
            className="hero-invite-button"
            onClick={() => {
              this.handleInviteHeroes(hero);
            }}
          >
            {_.get(this.props.translations, 'meetingAttendees.inviteButton', 'Invite')}
          </span>
          <span
            type="button"
            className="button-delete-invitation"
            onClick={() => {
              this.props.deleteInvitedHero(hero.heroId);
              this.setState({ showSearchHero: true });
            }}
          >
            {_.get(this.props.translations, 'meetingAttendees.cancelButton', 'Cancel')}
          </span>
        </div>
      );
    }
    if (status === 'sent') {
      return (
        <div className="booking-invite-member__button-section">
          <span
            className={`button-resend-invitation ${notAllowed}`}
            onClick={() => {
              this.props.resendInvitation(hero.attendeeId);
            }}
          >
            {_.get(this.props.translations, 'meetingAttendees.resendButton', 'Resend')}
          </span>
          <span
            className={`button-delete-invitation ${notAllowed}`}
            onClick={() => {
              this.changeStateDeleteModal();
              this.setState({ selectedHero: hero });
            }}
          >
            {_.get(this.props.translations, 'meetingAttendees.deleteButton', 'Delete')}
          </span>
        </div>
      );
    }
    if (status === 'accepted') {
      return (
        <div className="booking-invite-member__button-section">
          <span className="button-status-invitation --confirmed">
            {_.get(this.props.translations, 'meetingAttendees.statusConfirmed', 'Confirmed')}
          </span>
          <span
            className={`button-delete-invitation ${notAllowed}`}
            onClick={() => {
              this.changeStateDeleteModal();
              this.setState({ selectedHero: hero });
            }}
          >
            {_.get(this.props.translations, 'meetingAttendees.deleteButton', 'Delete')}
          </span>
        </div>
      );
    }
    if (status === 'declined') {
      return (
        <div className="booking-invite-member__button-section">
          <span className="button-status-invitation --declined">
            {_.get(this.props.translations, 'meetingAttendees.statusDeclined', 'Declined')}
          </span>
          <span
            className={`button-delete-invitation ${notAllowed}`}
            onClick={() => {
              this.changeStateDeleteModal();
              this.setState({ selectedHero: hero });
            }}
          >
            {_.get(this.props.translations, 'meetingAttendees.deleteButton', 'Delete')}
          </span>
        </div>
      );
    }
    return null;
  }

  renderInvitedHeroes(hero) {
    const heroName = `${_.get(hero, 'firstName', null)} ${_.get(hero, 'lastName', null)}`;
    const profilePicture = _.get(hero, 'imageUrl', null);
    const heroInitials = _.get(hero, 'heroInitials', null);
    const email = _.get(hero, 'email', null);
    const companyName = _.get(hero, 'company', null);
    const status = _.get(hero, 'status', null);
    return (
      <>
        <div className="booking-invite-members__selected">
          <div className="booking-invite-members__image-section">
            {profilePicture || heroInitials === 'N/A' ? (
              <img
                alt=""
                style={{ height: '40px', width: '40px', marginRight: '10px' }}
                src={profilePicture !== null ? profilePicture : defaultAvatar}
              />
            ) : (
              <div className="hero-initials search-hero-initials">{heroInitials}</div>
            )}
            <span className="hero-name">{heroName}</span>
          </div>
          <div className="hero-email">{email}</div>
          <div className="hero-company-name">{companyName}</div>
          {this.renderStatusButtons(status, hero)}
        </div>
      </>
    );
  }

  render() {
    const {
      isAuthenticated,
      globalSearchHeroes,
      globalSearchTrigger,
      globalSearchResults,
      globalSearching,
      translations,
      capacity,
      invitedHeroes,
      isLoadingInvitedMember,
      meetingAttendeesToastMessage,
      deleteInvitedAttendee,
      diffInMinutes,
      isInternalEnv,
      bookingStatus,
      heroProfile,
    } = this.props;
    const { showRenderAddNewHero, showSearchHero, newHeroName, showDeleteModal, selectedHero } = this.state;
    const isInternalHero = _.get(heroProfile, 'isInternal', 0);
    const addNewHeroForm = showRenderAddNewHero ? (
      <AddNewHeroForm
        heroName={newHeroName}
        handleInviationGuest={this.handleInviteHeroes}
        handleCancel={this.changeStateAddNewHero}
        translations
      />
    ) : null;
    const roomCapacityWithoutOrganizer = capacity - 1;
    let countInvitedHeroes = invitedHeroes.length;
    if (showRenderAddNewHero) {
      countInvitedHeroes += 1;
    }
    let searchResultsWithoutSelectedOnes = null;
    if (globalSearchResults && globalSearchResults.heroes) {
      const ids = _.map(invitedHeroes, hero => {
        return hero.heroId;
      });
      searchResultsWithoutSelectedOnes = _.filter(globalSearchResults.heroes, function(hero) {
        return !_.includes(ids, hero.id);
      });
    }
    if ((diffInMinutes <= 0 && invitedHeroes.length === 0) || bookingStatus === 'pending') {
      return null;
    }

    return (
      <div className="booking-invite-members__wrapper">
        {meetingAttendeesToastMessage && (
          <InfoToast message={_.get(translations, meetingAttendeesToastMessage, meetingAttendeesToastMessage)} />
        )}
        {showDeleteModal && (
          <DeleteModal
            deleteAction={deleteInvitedAttendee}
            hideDeleteModal={this.changeStateDeleteModal}
            selectedHero={selectedHero}
            translations={translations}
          />
        )}
        <div className="booking-invite-member__description">
          <span className="booking-invite-members__title">
            {_.get(translations, 'meetingAttendees.sectionTitle', 'Who will join you?')} ({countInvitedHeroes}/
            {roomCapacityWithoutOrganizer})
          </span>
          <span className="booking-invite-members__subtitle">
            {_.get(
              translations,
              'meetingAttendees.sectionSubtitle',
              ' Enter the name of the people you want to invite. If they are not in the Workero network we will ask for more details to invite\n' +
                'them.'
            )}
          </span>
        </div>
        {!isLoadingInvitedMember ? invitedHeroes.map(this.renderInvitedHeroes) : null}
        {!isInternalHero ? addNewHeroForm : null}
        {showSearchHero &&
        invitedHeroes.length !== roomCapacityWithoutOrganizer &&
        diffInMinutes > 0 &&
        isInternalEnv &&
        !_.includes(['canceled', 'pending', 'closed'], bookingStatus) ? (
          <Search
            placeholder={_.get(translations, 'meetingAttendees.placeholderName', '')}
            globalSearch={globalSearchHeroes}
            globalSearchTrigger={globalSearchTrigger}
            globalSearchResults={searchResultsWithoutSelectedOnes}
            globalSearching={globalSearching}
            handleClick={this.handleClick}
            trans={translations}
            className="booking-invite-members__search"
            isAuth={isAuthenticated}
            isInternalHero={isInternalHero}
            addNewHero={this.changeStateAddNewHero}
          />
        ) : null}
      </div>
    );
  }
}

export default withRouter(MeetingAttendeesBox);
