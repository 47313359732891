import React from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import PropTypes from 'prop-types';

const buildTag = ({ id, name }) => (
  <div key={id} className="dashboard__tag">
    {name}
  </div>
);

buildTag.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
};

const buildTags = (tags, tagsContainerClass, translations) => {
  const elements = [];

  const filteredTags = tags.filter(x => _.has(x, 'name') && _.isString(x.name) && x.name.toLowerCase() !== 'other');

  if (filteredTags.length <= 3) {
    elements[0] = filteredTags.map(buildTag);
  } else {
    elements[0] = filteredTags.slice(0, 3).map(buildTag);
    elements[1] = <div key={0} className="tags__more">{`+${filteredTags.length - 3} ${_.get(translations, 'MORE', 'MORE')}`}</div>;
  }
  return <div className={classnames('dashboard__tags', { [tagsContainerClass]: !!tagsContainerClass })}>{elements}</div>;
};

export default buildTags;
