import Immutable from 'immutable';
import {
  SET_NO_COMMUNITIES,
  SET_POPULAR_COMMUNITIES,
  SET_POPULAR_COMMUNITIES_IS_LOADING,
  SET_POPULAR_COMMUNITIES_IS_LOADING_MORE,
  SET_LOAD_MORE_COMMUNITIES_OFFSET,
  ADD_POPULAR_COMMUNITIES,
  SET_HAS_MORE_COMMUNITIES,
  INCREMENT_LOAD_MORE_COMMUNITIES_OFFSET,
} from '../../actions/community/popular-communities';

import { CARDS_PER_PAGE } from '../../components/community/constants';

const initialState = Immutable.Map({
  popularCommunities: [],
  isLoading: false,
  noCommunities: false,
  isLoadingMore: false,
  loadMoreCommunitiesOffset: 0,
  hasMoreCommunities: true,
});

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_POPULAR_COMMUNITIES:
      return state.setIn(['popularCommunities'], action.popularCommunities);
    case SET_POPULAR_COMMUNITIES_IS_LOADING:
      return state.setIn(['isLoading'], action.isLoading);
    case SET_POPULAR_COMMUNITIES_IS_LOADING_MORE:
      return state.setIn(['isLoadingMore'], action.isLoadingMore);
    case SET_NO_COMMUNITIES:
      return state.setIn(['noCommunities'], action.noCommunities);
    case ADD_POPULAR_COMMUNITIES:
      return state.setIn(['popularCommunities'], state.get('popularCommunities', []).concat(action.popularCommunities));
    case SET_LOAD_MORE_COMMUNITIES_OFFSET:
      return state.setIn(['loadMoreCommunitiesOffset'], action.loadMoreCommunitiesOffset);
    case INCREMENT_LOAD_MORE_COMMUNITIES_OFFSET:
      return state.setIn(['loadMoreCommunitiesOffset'], state.get('loadMoreCommunitiesOffset', 0) + CARDS_PER_PAGE);
    case SET_HAS_MORE_COMMUNITIES:
      return state.setIn(['hasMoreCommunities'], action.hasMoreCommunities);
    default:
      return state;
  }
};
