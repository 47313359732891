import React from 'react';
import PropTypes from 'prop-types';
import './../css/deletePaymentAccount.scss';
import _ from 'lodash';

class MakeDefaultMethod extends React.Component {

  onDelete = () => {
    this.props.onAction(this.props.profileId);
  };

  onCancelDelete = () => {
    this.props.onCancelAction(false);
  };

  render () {
    const { trans, paymentType } = this.props;

    let textMessage;
    switch (paymentType) {
      case 'stripe' : textMessage = _.get(trans, 'modals.makeDefaultMethod.cc', 'You are about to set this Credit Card as default'); break;
      case 'bancontact' : textMessage = _.get(trans, 'modals.makeDefaultMethod.bancontact', 'You are about to set Maestro/Bancontact as default'); break;
      case 'voucher' : textMessage = _.get(trans, 'modals.makeDefaultMethod.voucher', 'You are about to set this Voucher as default'); break;
      default : textMessage = _.get(trans, 'modals.makeDefaultMethod.defaultPayment', 'You are about to set this Payment Method as default'); break;
    }

    return (
      <div className="deletePaymentAccount">
        <div onClick={ this.onCancelDelete } className="modal-close-button" />
        <div className="deletePaymentAccount-title items-style1">{_.get(trans,'modals.makeDefaultMethod.title', 'Payment Method Default')}</div>
        <div className="deletePaymentAccount-warning">{textMessage}</div>
        <div className="deletePaymentAccount-actions">
          <button
            className="deletePaymentAccount-blockButton secondary-button"
            onClick={ this.onDelete }>{_.get(trans, 'modals.makeDefaultMethod.makeDefaultPaymentAccountButtonLabel', 'Make Payment Method Default')}</button>
          <button
            className="deletePaymentAccount-cancelButton main-button"
            onClick={ this.onCancelDelete }>{_.get(trans,'modals.makeDefaultMethod.changedMyMindButtonLabel', 'I Changed My Mind')}</button>
        </div>
      </div>
    );
  }
}

MakeDefaultMethod.propTypes = {
  onAction: PropTypes.func
};

export default MakeDefaultMethod;
