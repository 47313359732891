import api from '../../components/dashboard/utils/api';

/*
 * Constants
 */

export const SET_UPCOMING_BOOKINGS = 'SET_UPCOMING_BOOKINGS';
export const SET_UPCOMING_BOOKINGS_IS_LOADING = 'SET_UPCOMING_BOOKINGS_IS_LOADING';
/*
 * Sync Actions
 */

export const setBookings = bookings => ({
  type: SET_UPCOMING_BOOKINGS,
  bookings,
});
export const setUpcomingBookingsIsLoading = isLoading => ({
  type: SET_UPCOMING_BOOKINGS_IS_LOADING,
  isLoading,
});

/*
 * Async Actions
 */
export const fetchUpcomingBookings = () => dispatch => {
  dispatch(setUpcomingBookingsIsLoading(true));
  api
    .get('/sidebar/upcoming')
    .then(({ data = {} }) => {
      dispatch(setBookings(data.bookings));
    })
    .catch(() => {})
    .finally(() => {
      dispatch(setUpcomingBookingsIsLoading(false));
    });
};
