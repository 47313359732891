/* Component's props should be text and action
 * ex: const contextItems = [{ text: 'Text', action: this.handleClick }];
 * <ContextMenu items={contextItems} />
 * */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

class ContextMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showMenu: false,
    };

    this.showMenu = this.showMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
    this.buildMenuItem = this.buildMenuItem.bind(this);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.closeMenu);
  }

  showMenu(event) {
    event.preventDefault();

    this.setState({
      showMenu: true,
    });

    document.addEventListener('click', this.closeMenu);
  }

  closeMenu() {
    this.setState({
      showMenu: false,
    });

    document.removeEventListener('click', this.closeMenu);
  }

  buildMenuItem(item, index) {
    const isDisabled = _.get(item, 'isDisabled', false);
    if (isDisabled) return <div className="context-menu-item context-menu-item-disabled">{item.text}</div>;
    return (
      <div key={`action-${index}`} className="context-menu-item" onClick={item.action}>
        {item.text}
      </div>
    );
  }

  render() {
    const { items } = this.props;
    const { showMenu } = this.state;

    return (
      <div className="context-menu" onClick={this.showMenu}>
        <span className="context-menu-dots">• • •</span>
        {showMenu ? <div className="context-menu-items">{items.map(this.buildMenuItem)}</div> : null}
      </div>
    );
  }
}

ContextMenu.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({ text: PropTypes.string, action: PropTypes.func })).isRequired,
};

export default ContextMenu;
