import { connect } from 'react-redux';
import _ from 'lodash';

import { translations } from '../../selectors/translations';
import OpenLogin from './OpenLogin';
import CustomLogin from './CustomLogin';
import CustomSignup from './CustomSignup';
import OpenSignUp from './OpenSignUp';
import OpenForgotPassword from './OpenForgotPassword';
import OpenResetPassword from './OpenResetPassword';
import './index.scss';
import { auth, impersonate, loginSSO } from '../../actions/openAuth/login';
import validateInvitation from '../../actions/openAuth/validateInvitation';
import { setIsWhiteLabel } from '../../actions/global';

const mapStateToProps = state => ({
  translations: _.get(translations(state), 'openAuth', {}),
  ssoProvider: state.global.get('ssoProvider'),
});

const mapDispatchToProps = dispatch => ({
  auth: () => dispatch(auth()),
  impersonate: data => dispatch(impersonate(data)),
  validateInvitation: data => dispatch(validateInvitation(data)),
  loginSSO: () => dispatch(loginSSO()),
  setIsWhiteLabel: isWhiteLabel => dispatch(setIsWhiteLabel(isWhiteLabel)),
});

const OpenLoginWithProps = connect(mapStateToProps, mapDispatchToProps)(OpenLogin);
const CustomLoginWithProps = connect(mapStateToProps, mapDispatchToProps)(CustomLogin);
const OpenSignUpWithProps = connect(mapStateToProps, mapDispatchToProps)(OpenSignUp);
const OpenForgotPasswordWithProps = connect(mapStateToProps, mapDispatchToProps)(OpenForgotPassword);
const OpenResetPasswordWithProps = connect(mapStateToProps, mapDispatchToProps)(OpenResetPassword);
const CustomSignUpWithProps = connect(mapStateToProps, mapDispatchToProps)(CustomSignup);
export {
  OpenLoginWithProps as OpenLogin,
  OpenSignUpWithProps as OpenSignUp,
  OpenForgotPasswordWithProps as OpenForgotPassword,
  OpenResetPasswordWithProps as OpenResetPassword,
  CustomLoginWithProps as CustomLogin,
  CustomSignUpWithProps as CustomSignup,
};
