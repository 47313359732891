export const filesizeFormatter = attachmentSize => {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (attachmentSize === 0) {
    return '0 Byte';
  }
  const i = Math.floor(Math.log(attachmentSize) / Math.log(1024));
  return `${(attachmentSize / 1024 ** i).toFixed(1)} ${sizes[i]}`;
};


