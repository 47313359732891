import React, { createRef } from 'react';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment';
import { Scrollbars } from 'react-custom-scrollbars';
import experienceIcon from '../../assets/svg/experience.svg';
import deskIcon from '../../assets/svg/dashboard/desk-office-icon.svg';
import privateOfficeIcon from '../../assets/svg/dashboard/private-office-icon.svg';
import meetingRoomIcon from '../../assets/svg/dashboard/meeting-room-icon.svg';
import eventRoomIcon from '../../assets/svg/dashboard/event-room-icon.svg';

import PaymentDetails from './payment-details';
import {fetchPaymentMethods, fetchPaymentProfiles} from "../../actions/locationActions";

class BookingPayment extends React.Component {
  constructor(props) {
    super(props);
    this.handleContinueClick = this.handleContinueClick.bind(this);
    this.getOfficeIcon = this.getOfficeIcon.bind(this);
    this.clickedOnContinueRef = createRef(false);
  }

  componentDidMount() {
    const { fetchOfficeDetails, location, beforeFetchOfficeDetails, history, locationDetails, fetchPaymentProfiles, fetchCountries } = this.props;
    const officeId = _.get(location, 'state.from', null) ? location.state.from.match(/\d+/g).pop() : 0;
    const fromUrl = _.get(location, 'state.from', '/');
    fetchCountries();
    fetchPaymentProfiles();
    if (officeId) {
      beforeFetchOfficeDetails();
      fetchOfficeDetails(officeId);
    }
    // on refresh redirect back to location page
    history.push({ state: { from: location.fromUrl ? location.fromUrl : fromUrl } });
    if (!locationDetails.size) {
      const redirectTo = location.state ? location.state.from : '/';
      const { resetBooking, onChangeSection } = this.props;
      resetBooking();
      onChangeSection('options');
      history.push(redirectTo);
    }
  }

  handleContinueClick() {
    this.clickedOnContinueRef.current = true;
  }

  componentWillUnmount() {
    if (!this.clickedOnContinueRef.current) {
      this.props.resetBooking();
      this.props.resetVoucherInfo();
      this.props.onChangeSection('options');
    }
    this.props.fetchPaymentMethods();
  }

  getOfficeIcon(officeTypeId) {
    switch (officeTypeId) {
      case 1:
        return deskIcon;
      case 2:
        return privateOfficeIcon;
      case 3:
        return meetingRoomIcon;
      case 4:
        return eventRoomIcon;
      default:
        return deskIcon;
    }
  }

  calculateDate(heroExperience) {
    const month = heroExperience.startMonth ? `${moment(heroExperience.startMonth, 'MM').format('MMMM')} ` : '';
    const endMonth = heroExperience.endMonth ? `${moment(heroExperience.endMonth, 'MM').format('MMMM')} ` : '';
    let present = '';
    if ((endMonth === '' && heroExperience.endYear === '') || heroExperience.endYear === null) {
      present = ` - Present`;
    } else {
      present = ` - ${endMonth} ${heroExperience.endYear}`;
    }
    return `${month} ${heroExperience.startYear} ${present} `;
  }

  calculatePrice(price) {
    return `${price
      .toFixed(2)
      .toString()
      .replace('.', window.__env.decimalSeparator)}€`;
  }

  renderAccountDetails() {
    const { heroProfile, translations } = this.props;
    const heroName = `${_.get(heroProfile, 'firstName', '')} ${_.get(heroProfile, 'lastName', '')}`;
    const heroEmail = _.get(heroProfile, 'email', '');
    return (
      <div className="account-details">
        <h2 className="account-details__title">{_.get(translations, 'yourAccount', 'Your account')}</h2>
        <div className="account-details-content">
          <span className="icon icon-connections account-details__icon" />
          <div className="account-details__section">
            <span className="account-details__hero-name">{heroName}</span>
            <span className="account-details__email">{heroEmail}</span>
          </div>
        </div>
      </div>
    );
  }

  renderProfileDetails() {
    const { heroProfile, translations } = this.props;
    const heroDefaultVertical = _.get(heroProfile, 'defaultVertical.name', '-');
    const heroGoals = _.get(heroProfile, 'heroGoals', []);
    const heroVerticals = _.get(heroProfile, 'heroVerticals', []);
    const heroCompany = _.get(heroProfile, 'positions.0', '');
    const heroHeadline = _.get(heroProfile, 'headline', '');
    return (
      <div className="profile-details">
        <h2 className="profile-details__title">{_.get(translations, 'yourProfile', 'Your profile')}</h2>
        <div className="profile-details__headline">
          <div className="profile-details__image-container">
            <img className="profile-details__image" src={experienceIcon} alt="" />
          </div>
          <div className="profile-details__description-container">
            <span className="profile-details__job-description">{heroHeadline || `${heroCompany.title} at ${heroCompany.company}`}</span>
            <span className="profile-details__company">{heroCompany.company}</span>
            <span className="profile-details__experience">{this.calculateDate(heroCompany)}</span>
          </div>
        </div>
        <div className="profile-details__grid">
          <div className="profile-details__row">
            <span className="profile-details__row-title">{_.get(translations, 'news', 'News')}</span>
            <span className="profile-details__row-description">
              {heroVerticals
                .map(verticals => {
                  return verticals.name;
                })
                .join(', ')}
            </span>
          </div>
          <div className="profile-details__row">
            <span className="profile-details__row-title">{_.get(translations, 'dashboard', 'Dashboard')}</span>
            <span className="profile-details__row-description">{heroDefaultVertical}</span>
          </div>
          <div className="profile-details__row">
            <span className="profile-details__row-title">{_.get(translations, 'usage', 'Usage')}</span>
            <span className="profile-details__row-description">
              {heroGoals
                .map(goals => {
                  return goals.name;
                })
                .join(', ')}
            </span>
          </div>
        </div>
      </div>
    );
  }

  renderBookingDetails() {
    const { translations, locationDetails } = this.props;
    const locationDetail = locationDetails && locationDetails.toJS() ? locationDetails.toJS() : {};
    const bookingValidate = this.props.bookingValidate && this.props.bookingValidate.toJS() ? this.props.bookingValidate.toJS() : {};
    const officeIcon = this.getOfficeIcon(_.get(bookingValidate, 'officeTypeId', null));
    const locationName = _.get(locationDetail, 'name', '');
    const amenities = _.get(bookingValidate, 'amenities', []);
    const services = _.get(bookingValidate, 'services', []);
    const equipments = _.get(bookingValidate, 'equipments', []);
    const bookingAmenitiesAndServices = amenities.concat(services).concat(equipments);
    const productName = _.get(bookingValidate, 'name', '');
    const productPrice = _.get(bookingValidate, 'price.productPrice', 0);
    const voucherPrice = _.get(bookingValidate, 'price.paidAmountVoucher', 0);
    const bookingPriceTotal = _.get(bookingValidate, 'price.subtotal', 0);
    const vat = _.get(bookingValidate, 'price.vat', 0);
    const paidAmountCompany = _.get(bookingValidate, 'price.paidAmountCompany', 0);
    const priceTotal = _.get(bookingValidate, 'price.total', 0);
    let priceServices = 0;
    services.concat(equipments).map(data => {
      priceServices += data.totalPrice;
      return priceServices;
    });

    return (
      <div className="booking-details">
        <h2 className="booking-details__title">{_.get(translations, 'payForBooking', 'Pay for your booking')}</h2>
        <div className="booking-details-content">
          <div className="booking-details__image-container">
            <img className="booking-details__image" alt="Office Icon" src={officeIcon} />
          </div>
          <div className="booking-details__info">
            <span className="booking-details__location-title">{locationName}</span>
            <span className="booking-details__product-name">{productName}</span>
          </div>
          <span className="booking-details__price">{this.calculatePrice(productPrice)}</span>
        </div>
        <div className="booking-details__info">
          <div className="booking-details__info-row">
            <div className="booking-details__section">
              <span className="booking-details__section-title">{_.get(translations, 'amenitiesAndServices', 'Amenities & Services')}</span>
              <span className="booking-details__amenities-list">
                {bookingAmenitiesAndServices
                  .map(data => {
                    return data.name;
                  })
                  .join(', ')}
              </span>
            </div>
            <span className="booking-details__price">{this.calculatePrice(priceServices)}</span>
          </div>
          <div className="booking-details__info-row">
            <div className="booking-details__section">
              <span className="booking-details__section-title">{_.get(translations, 'booking', 'Booking')}</span>
            </div>
            <span className="booking-details__price">{this.calculatePrice(bookingPriceTotal)}</span>
          </div>
          {paidAmountCompany ? (
            <div className="booking-details__info-row">
              <div className="booking-details__section">
                <span className="booking-details__section-title">{_.get(bookingValidate, 'companyName', '')}</span>
              </div>
              <span className="booking-details__price">-{this.calculatePrice(paidAmountCompany)}</span>
            </div>
          ) : null}
          <div className="booking-details__info-row">
            <div className="booking-details__section">
              <span className="booking-details__section-title">{_.get(translations, 'vat', 'VAT (21%)')}</span>
            </div>
            <span className="booking-details__price">{this.calculatePrice(vat)}</span>
          </div>
          <div className="booking-details__info-row">
            <div className="booking-details__section">
              <span className="booking-details__section-title">{_.get(translations, 'subtotal', 'Subtotal')}</span>
            </div>
            <span className="booking-details__price">{this.calculatePrice(priceTotal + voucherPrice)}</span>
          </div>
          <div className="booking-details__info-row">
            <div className="booking-details__section">
              <span className="booking-details__section-title">{_.get(translations, 'voucher', 'Voucher')}</span>
            </div>
            <span className="booking-details__price">
              {voucherPrice > 0 ? `-${this.calculatePrice(voucherPrice)}` : this.calculatePrice(voucherPrice)}
            </span>
          </div>
          <div className="booking-details__info-row">
            <div className="booking-details__section">
              <span className="booking-details__section-title">{_.get(translations, 'total', 'Total')}</span>
            </div>
            <span className="booking-details__price">{this.calculatePrice(priceTotal)}</span>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <Scrollbars>
        <div className="payment-container">
          <div className="payment-content">
            <div className="payment-content__left-area">
              {/* <h1 className="payment-content__title">Payment details</h1> */}
              <PaymentDetails handleContinueClick={this.handleContinueClick} />
            </div>
            <div className="payment-content__right-area">
              {this.renderBookingDetails()}
              {this.renderProfileDetails()}
              {this.renderAccountDetails()}
            </div>
          </div>
        </div>
      </Scrollbars>
    );
  }
}
export default withRouter(BookingPayment);
