import { connect } from 'react-redux';
import { geolocated } from 'react-geolocated';
import MyBookingsPage from '../../components/myBookingsPage/myBookingsPage';
import { fetchMyBookings } from '../../actions/officesActions';

const mapStateToProps = state => {
  return {
    myBookings: state.offices.get('myBookings'),
    isLoading: state.offices.get('isLoading'),
    translations: state.translations,
    hero: state.account.get('profile'),
  };
};

const mapDispatchProps = dispatch => {
  return {
    fetchMyBookings: () => {
      dispatch(fetchMyBookings());
    },
  };
};

const MyBookingsView = connect(mapStateToProps, mapDispatchProps)(MyBookingsPage);

export default geolocated({
  positionOptions: {
    enableHighAccuracy: true,
  },
  userDecisionTimeout: 5000,
})(MyBookingsView);
