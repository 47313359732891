import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import BancontactIcon from '../icons/bancontact.svg';
import MastercardIcon from '../icons/mastercard.svg';
import VisaIcon from '../icons/visa.svg';
import AmexIcon from '../icons/amex.svg';
import VoucherIcon from '../icons/voucher.svg';

const DefaultPaymentProfile = props => {
  const { trans, paymentMethod, heroProfile, modifyPaymentMethod, formatString } = props;
  const { card_brand, last_digits, expire_date, payment_profile, type, voucher } = paymentMethod;
  let name = _.get(payment_profile, 'company_name', null);
  let profileType = _.get(trans, 'payment.business', 'Business');
  if (!name) {
    const { firstName, lastName } = heroProfile;
    name = `${firstName} ${lastName}`;
    profileType = _.get(trans, 'payment.personal', 'Personal');
  }
  const modifyLabel = _.get(trans, 'payment.modify', 'Modify');
  const expiresOnArray = expire_date ? _.split(expire_date, '-') : null;
  const expiresOnData = expiresOnArray ? `${expiresOnArray[1]}/${expiresOnArray[0]}` : null;
  const endingInLabel = _.get(trans, 'payment.endingIn', 'ending in');
  const expiresOnLabel = _.get(trans, 'payment.expiresOn', 'expires on');
  const expiresOn = expiresOnData ? `, ${expiresOnLabel} ${expiresOnData}` : '';
  const balance = _.get(voucher, 'balance', '0');
  const balanceAvailable = formatString(_.get(trans, 'payment.availableBalance', `{0}€ available`), balance);

  const defaultLabel = _.get(paymentMethod, 'is_default', 0) ? _.get(trans, 'payment.defaultLabel', '(default)') : '';
  const defaultPPLabel = _.get(paymentMethod, 'is_default', 0)
    ? _.get(trans, 'payment.defaultPaymentProfile', 'Default payment profile')
    : _.get(trans, 'payment.standardPaymentProfile', 'Payment profile');

  let iconSrc;
  let methodType;
  if (type === 'voucher') {
    iconSrc = VoucherIcon;
    methodType = _.get(trans, 'payment.voucher', 'Voucher');
  } else {
    methodType = _.get(trans, 'payment.creditCard', 'Credit card');
    iconSrc = VisaIcon;
    switch (card_brand) {
      case 'Visa':
        iconSrc = VisaIcon;
        break;
      case 'Mastercard':
        iconSrc = MastercardIcon;
        break;
      case 'Amex':
        iconSrc = AmexIcon;
        break;
      case 'Bancontact':
        iconSrc = BancontactIcon;
        methodType = _.get(trans, 'payment.bancontact', 'Maestro');
        break;
      default:
        break;
    }
  }

  return (
    <div className="default-profile__container">
      <div className="default-profile__header">
        <div className="default-profile__title">{defaultPPLabel}</div>
        <div className="default-profile__modify-button" onClick={modifyPaymentMethod}>
          {modifyLabel}
        </div>
      </div>
      <div className="default-profile__wrapper">
        <div className="default-profile__icon-container">
          <img className="default-profile__icon" src={iconSrc} alt="mastercard" />
        </div>
        <div className="default-profile__info">
          <div className="default-profile__method-info">
            <div className="default-profile__type-info">{`${methodType} ${defaultLabel} `}</div>
            {last_digits ? <div className="default-profile__expiry-info">{` ${endingInLabel} ${last_digits}`}</div> : null}
          </div>
          {type === 'voucher' ? <div className="default-profile__profile-info">{balanceAvailable}</div> : null}
          <div className="default-profile__profile-info">{`${profileType}, ${name}${expiresOn}`}</div>
        </div>
      </div>
    </div>
  );
};

DefaultPaymentProfile.propTypes = {
  trans: PropTypes.shape({}),
  paymentMethod: PropTypes.shape({
    card_brand: PropTypes.string,
    last_digits: PropTypes.string,
    expire_date: PropTypes.string,
    payment_profile: PropTypes.shape({}),
    type: PropTypes.string,
  }),
  heroProfile: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }).isRequired,
  modifyPaymentMethod: PropTypes.func.isRequired,
};

DefaultPaymentProfile.defaultProps = {
  trans: {},
  paymentMethod: {},
};

export default DefaultPaymentProfile;
