import { connect } from 'react-redux';
import ProfileMenu from './profileMenu';
import { clearHeroAuthenticationData } from '../../actions/openAuth/login';

const mapStateToProps = state => ({
  isWhiteLabel: state.global.get('isWhiteLabel'),
});

const mapDispatchProps = dispatch => ({
  clearHeroAuthenticationData: data => dispatch(clearHeroAuthenticationData(data)),
});

export default connect(mapStateToProps, mapDispatchProps)(ProfileMenu);
