import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

const NoMorePosts = ({ trans }) => {
  return (
    <div className="community-page__no-more-posts-container">
      <p className="community-page__no-more-posts-message">{_.get(trans, 'communityPage.noMorePosts', 'There are no more posts')}</p>
    </div>
  );
};

NoMorePosts.propTypes = {
  translations: PropTypes.shape({
    noMorePosts: PropTypes.string,
  }),
};

NoMorePosts.defaultProps = {
  translations: {},
};

export default NoMorePosts;
