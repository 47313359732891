export const dateDifferenceToDhm = (date, now, trans = null) => {
  const diff = now - date;
  const weeks = Math.floor(diff / (1000 * 60 * 60 * 24 * 7));
  
  if (weeks > 0) {
    return `${weeks}` + (trans !== null ? trans.timeAgo.dateDifferenceToDhm.w : 'w');
  }

  const days = Math.floor(diff / (1000 * 60 * 60 * 24));

  if (days > 0) {
    return `${days}` + (trans !== null ? trans.timeAgo.dateDifferenceToDhm.d : 'd');
  }

  const hours = Math.floor(diff / (1000 * 60 * 60));

  if (hours > 0) {
    return `${hours}` + (trans !== null ? trans.timeAgo.dateDifferenceToDhm.h : 'h');
  }

  const mins = Math.floor(diff / (1000 * 60));

  if (mins > 0) {
    return `${mins}` + (trans !== null ? trans.timeAgo.dateDifferenceToDhm.m : 'm');
  }

  return (trans !== null ? trans.timeAgo.dateDifferenceToDhm.now : 'now');
};

export const dateDifferenceToDay = (date, now, trans = null) => {
  const diff = now - date;
  const mins = Math.floor(diff / (1000 * 60));
  if (mins === 0) {
    return (trans !== null ? trans.timeAgo.dateDifferenceToDay.justNow : 'Just now');
  }

  if (mins < 60) {
    return trans !== null ? (mins === 1 ? (trans.timeAgo.dateDifferenceToDay.minuteAgo) : (trans.formatString(trans.timeAgo.dateDifferenceToDay.minutesAgo, mins))) : '';
  }

  const hours = Math.floor(diff / (1000 * 60 * 60));

  if (hours < 24) {
    return trans !== null ? (hours === 1 ? (trans.timeAgo.dateDifferenceToDay.hourAgo) : (trans.formatString(trans.timeAgo.dateDifferenceToDay.hoursAgo, hours))) : '';
  }

  const days = Math.floor(diff / (1000 * 60 * 60 * 24));

  if (days === 1) {
    return trans.timeAgo.dateDifferenceToDay.yesterday;
  } else if (days < 7) {
    if (date instanceof Date) {
      return trans.timeAgo.weekdays[Date.prototype.getDay.call(date)];
    } else {
      return trans.timeAgo.weekdays[Date.prototype.getDay.call(new Date(date/1000))];
    }
  }
  
  const weeks = Math.floor(diff / (1000 * 60 * 60 * 24 * 7));
  return trans !== null ? (weeks === 1 ? (trans.timeAgo.dateDifferenceToDay.weekAgo) : (trans.formatString(trans.timeAgo.dateDifferenceToDay.weeksAgo, weeks))) : '';
};
