import PropTypes from 'prop-types';
import React from 'react';


import './css/modalDirectChat.scss';

const ModalShare = props => (
  <div>
    <h3 className="directChat-dialog-title">
      {`${props.modalData.get('type')} conversation with ${props.modalData.getIn(['conversation', 'poster', 'firstName'])}`}
    </h3>
    <p className="directChat-dialog-message">
      {`You are about to ${props.modalData.get('type').toLowerCase()} your conversation with ${props.modalData.getIn(['conversation', 'poster', 'firstName'])}`}
    </p>
    <p className="directChat-dialog-info">
      {
        props.modalData.get('type') === 'Mute'
          ? 'You can unmute this conversation at any time through the conversation options menu'
          : 'Once performed this action cannot be undone!'
      }
    </p>
    <div className="directChat-dialog-actions">
      <button
        className="directChat-dialog-action directChat-dialog-actionCancel"
        onClick={() => {
          props.onModalClose();
        }}
      >
        I changed my mind
      </button>
      <button
        className="directChat-dialog-action directChat-dialog-actionShare"
        type="submit"
        onClick={() => {
          switch(props.modalData.get('type')) {
            case 'Delete':
              props.actions.deleteConversation(props.modalData.get('id'));
              break;
            case 'Mute':
              props.actions.muteConversation(props.modalData.get('id'), true);
              break;
            case 'Report':
              props.actions.reportConversation(props.modalData.get('id'));
              break;
            default:
          }

          props.onModalClose();
        }}
      >
        {props.modalData.get('type')}
      </button>
    </div>
  </div>
);

ModalShare.propTypes = {
  /**
   * Contains all available dispatched actions for this component
   * @type {Object}
   */
  actions: PropTypes.object.isRequired,
  /**
   * Native React Modal onClose request
   * @type {Function}
   */
  onModalClose: PropTypes.func.isRequired,
  /**
   * Representative data for the modal instance
   * @type {Immutable.Map<ModalData>}
   */
  modalData: PropTypes.object.isRequired
};

export default ModalShare;
