import React from 'react';
import Rating from 'react-rating';

import './starRating.scss';
 
const StarRating = (props) => {
  let {theme, ...ratingProps} = props;
  return (
    <Rating 
      empty={<div className={`empty-rating-icon ${theme}`}></div>}
      full={<div className={`full-rating-icon ${theme}`}></div>}
      {...ratingProps} />
  )
}

StarRating.defaultProps = {
  theme: 'theme-blue'
}

export default StarRating;
