import api from '../../components/dashboard/utils/api';

/*
 * Constants
 */
export const SET_MEDIA_COMPANY = 'SET_MEDIA_COMPANY';
export const SET_MEDIA_COMPANY_LOGO = 'SET_MEDIA_COMPANY_LOGO';
export const SET_MEDIA_COMPANY_IS_LOADING = 'SET_MEDIA_COMPANY_IS_LOADING';

/*
 * Sync Actions
 */
export const setMediaCompany = company => ({
  type: SET_MEDIA_COMPANY,
  company,
});

export const setMediaCompanyLogo = companyLogo => ({
  type: SET_MEDIA_COMPANY_LOGO,
  companyLogo,
});

export const setMediaCompanyIsLoading = isLoading => ({
  type: SET_MEDIA_COMPANY_IS_LOADING,
  isLoading,
});

/*
 * Async Actions
 */
export const fetchMediaCompanyProfile = companyId => dispatch => {
  const url = companyId ? `media/company/${companyId}` : 'media/company';
  dispatch(setMediaCompanyIsLoading(true));
  api
    .get(url)
    .then(({ data = {} }) => {
      dispatch(setMediaCompany(data.company));
      dispatch(setMediaCompanyLogo(data.companyLogo));
    })
    .catch(() => {})
    .finally(() => {
      dispatch(setMediaCompanyIsLoading(false));
    });
};
