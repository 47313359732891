import React, {Component} from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import Immutable from 'immutable';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import './css/index.scss';
import blockIncompleteProfileAction from "../../utils/blockIncompleteProfileAction";


class ProfileBox extends Component {
    context;

    static contextTypes = {
        router: PropTypes.object.isRequired
    };

    RED_COLOR_LIMIT = 30;
    GREEN_COLOR_LIMIT = 70;

    UNSAFE_componentWillMount() {
      this.setState({
        currentConversationId: null,
      });
      if(this.props.fetchConversation) {
          this.props.fetchDirectChatConversations();
      }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if(this.props.fetchConversation) {
            let currentConversation = nextProps.conversations.find(conversation => conversation.getIn(['hero', 'id']) === nextProps.id);
            if(currentConversation !== undefined) {
                this.setState({
                    currentConversationId: currentConversation.get('id'),
                });
            }
        }
    }


    /**
     * Computes color class depending on match percentage
     * @method _getColorClass
     * @returns {string}
     * @private
     */
    _getColorClass(matchPercentage) {
        if (matchPercentage <= this.RED_COLOR_LIMIT) {
            return '--red';
        }

        if (matchPercentage >= this.GREEN_COLOR_LIMIT) {
            return '--green';
        }

        return '--yellow';
    }

    sendMessage = () => {
        if(this.state.currentConversationId !== null) {
            this.props.openConversationAndFetch(this.props.id, this.state.currentConversationId);
        } else {
            this.props.openConversation(this.props.currentUser, Immutable.fromJS(this.props));
        }
        this.props.history.push('/direct-chat');
    };

    /**
     * Display the send message button for all user, display nothing on current user profile page
     * @returns {ReactElement}
     * @private
     */
    _getProfileAction() {
        if (this.props.hideConnect) {
            return null;
        }

        return (
            <div className="profileBox-action profileBox-messageBullet"
                 title={`${this.props.trans.connection.message} ${this.props.firstName}`}
                 onClick={() => blockIncompleteProfileAction().then(() => {
                     this.props.history.push({ pathname: '/account/profile/incomplete', fromUrl: this.props.location.pathname });
                 }).catch(()=> {
                     this.sendMessage();
                 })}></div>
        );
    }

    /**
     * Decides whether distance info should be displayed or not
     */
    _getDistance = () => {
        if (!this.props.options.distance || !this.props.distance) {
            return null;
        }

        return <div className="profileBox-infoSmall">{this.props.distance}km</div>;
    }


    /**
     * Decides job title for hero
     */
    _getJobTitle = () => {
        const {trans } = this.props;
        const headline = _.get(this.props, 'headline', null);
        const currentLocation = _.get(this.props, 'currentLocation', null);
        const jobName = _.get(this.props, 'jobName', null);

        if (headline) {
            return <div className="profileBox-infoSmall">{headline}</div>;
        }
        if (currentLocation) {
            return <div className="profileBox-infoSmall">{jobName} {_.get(trans, 'profile.at', '')} {currentLocation}</div>;
        }
        return null;
    };

    _getSummary = () => {
        if (!this.props.summary) {
            return <div className="profileBox-infoSmall"></div>;
        }
        return <div className="profileBox-infoSmall">{this.props.summary}</div>;
    };

    _getCompanyDescription = () => {
        if (!this.props.companyDescription) {
            return <div className="profileBox-infoSmall"></div>;
        }
        return <div className="profileBox-infoSmall">{this.props.companyDescription}</div>;
    };

    _getMatchingValue = () => {
        if (this.props.isProfilePage) {
            return null;
        }
        if (this.props.matchValue  && this.props.totalScore) {
            const matchingPercentage = parseInt(this.props.matchValue * 100 / this.props.totalScore);
            return <div className={`profileBox-match --green`}>{matchingPercentage}% {this.props.trans.connections.suggestions.matching}</div>;
        }
        return null;
    }

    /**
     * Renders strictly basic profile info
     * @method _getProfileInfo
     * @returns {ReactElement}
     * @private
     */
    _getProfileInfo = () => {
        const gradeText = null;
        let spendingLimit = this.props.showSpendingLimit === true && this.props.spendingLimit > 0 ? (
            <div className="spending-limit">
                <div className="label">{this.props.trans.connection.spent} / {this.props.trans.connection.spendingLimit}</div>
                <div className="values">
                    <span className="spent">{this.props.spent.toFixed(2).toString().replace(".", window.__env.decimalSeparator)}€</span> / {this.props.spendingLimit.toFixed(2).toString().replace(".", window.__env.decimalSeparator)}€
                </div>
            </div>) : null;

        let bgImgStyle = {};
        if (this.props.avatar !== null) {
            bgImgStyle = {backgroundImage: `url(${this.props.avatar})`};
        }

        let activeSubscription = this.props.hasActiveSubscription === true ? <div className="active-subscription">{this.props.trans.accountProfile.activeSubscriptionFrom} {this.props.subscriptionCompanyName}</div> : null;
        const { heroInitials } = this.props;
        return (
            <div className={`profileBox-container`}>
                {this.props.avatar ? (
                    <div className={classnames("profileBox-image", {'defaultAvatar': !this.props.avatar})} style={bgImgStyle}></div>
                ) : (
                    <div className="hero-initials profile-hero-initials">{heroInitials}</div>
                )}

                <div className="profileBox-info">
                    <div className="profileBox-name items-style1">
                        <span className="profileBox-firstName">{this.props.firstName} <span className="profileBox-lastName">{this.props.lastName}</span></span>

                        {gradeText}
                    </div>

                    {this._getJobTitle()}
                    {this._getSummary()}
                    {this._getCompanyDescription()}
                    {this._getDistance()}
                    {spendingLimit}
                    {activeSubscription}
                     {this._getMatchingValue()}
                     {this._getProfileAction()}
                </div>
            </div>
        )
    }

    /**
     * Decides if the main container should be a Link or a simple div,
     * based on props input
     * @method _getProfileContainer
     * @returns {ReactELement}
     * @private
     */
    _getProfileContainer() {
        if (this.props.noLink) {
            return (
                <div>
                    {this._getProfileInfo()}
                </div>
            );
        }

        return (
          <div className={`profileBox ${this.props.className}`}>
            <Link to={`/connection/${this.props.id}`} className="profileBox-link">
              {this._getProfileInfo()}
            </Link>
            {this._getProfileAction()}
          </div>
        )
    }

    render() {

      // console.log('conversations', this.state);
        return (
            <div className={`profileBox ${this.props.className}`}>
                {this._getProfileContainer()}
            </div>
        );
    }
}

ProfileBox.propTypes = {
    /**
     * Additional options to help displaying certain info
     * @property options
     * @type {object}
     */
    store: PropTypes.object,

    /**
     * Additional classes to apply to profile box
     * @property className
     * @type {string}
     */
    className: PropTypes.string,
    /**
     * Connection grade
     * @property grade
     * @type {string}
     */
    grade: PropTypes.string,

    /**
     * The contact's first name
     * @property firstName
     * @type {string}
     * @required
     */
    firstName: PropTypes.string.isRequired,

    /**
     * Contact's last name
     * @property lastName
     * @type {string}
     */
    lastName: PropTypes.string,

    /**
     * The distance @ which the contact is located
     * @property distance
     * @type {number}
     */
    distance: PropTypes.number,

    /**
     * Additional options to help displaying certain info
     * @property options
     * @type {object}
     */
    options: PropTypes.object,

    /**
     * The client's avatar image
     * @property avatar
     * @type {string}
     */
    avatar: PropTypes.string,

    /**
     * The name of the contact's current job
     * @property jobName
     * @type {string}
     */
    jobName: PropTypes.string,

    /**
     * The name of the contact's current job
     * @property position
     * @type {string}
     */
    position: PropTypes.string,

    /**
     * The name of the user's current location
     * @property currentLocation
     * @type {string}
     */
    currentLocation: PropTypes.string,

    /**
     * Indicates the value of the match indicator between
     * the connected user and the contact as score
     * @property matchValue
     * @type {number}
     */
    matchValue: PropTypes.number,

    /**
     * Indicates the total score of the hero
     * number of verticals and number of goals in a weighted sum
     * @property matchValue
     * @type {number}
     */
    totalScore: PropTypes.number,

    /**
     * Flag which helps us know if the user is connected with the contact
     * @property connected
     * @type {boolean}
     */
    connected: PropTypes.bool,

    /**
     * Flag which tells the component whether to render as a link or not
     * @property noLink
     * @type {boolean}
     */
    noLink: PropTypes.bool,

    /**
     * Provide message modal method when no context is available
     * @property showMessageModal,
     * @type {function}
     */
    showMessageModal: PropTypes.func,
};

ProfileBox.defaultProps = {
    className: '',
    lastName: '',
    avatar: '',
    noLink: false,
    fetchConversation: true
};

export default withRouter(ProfileBox);
