import _ from 'lodash';
import api from '../../components/myMeetings/utils/api';
/*
 * Constants
 */
export const SET_MAP_PRODUCTS = 'SET_MAP_PRODUCTS';
export const SET_MAP_PRODUCTS_IS_LOADING = 'SET_MAP_PRODUCTS_IS_LOADING';
export const SET_MUST_LOGOUT = 'SET_MUST_LOGOUT';

/*
 * Sync Actions
 */
export const setMapProducts = mapProducts => ({
  type: SET_MAP_PRODUCTS,
  mapProducts,
});

export const setMapProductsIsLoading = isLoading => ({
  type: SET_MAP_PRODUCTS_IS_LOADING,
  isLoading,
});

export const setMustLogout = mustLogout => ({
  type: SET_MUST_LOGOUT,
  mustLogout,
});

/*
 * Async Actions
 */
export const fetchMapProducts = (officeId, floorNumber, productType, startDate = null, endDate = null, features = null) => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(setMapProductsIsLoading(true));
    const dateParam = `/startdate/${startDate}/enddate/${endDate}`;
    const featuresString = features ? features.filter(f => f !== 0).join('&') : '';
    const featuresParam = featuresString ? `/features/${featuresString}` : '';
    api
      .get(`whitelabel/products/floor/${floorNumber}/officeid/${officeId}/type/${productType}${dateParam}${featuresParam}`)
      .then(({ data = {} }) => {
        if (data) {
          const mustLogout = _.get(data, 'must_logout', false);
          dispatch(setMustLogout(mustLogout));
          const products = _.get(data, 'products', []);
          dispatch(setMapProducts(products));
          resolve(products);
        }
      })
      .catch(() => {})
      .finally(() => {
        dispatch(setMapProductsIsLoading(false));
      });
  });
};

export const getFirstAvailable = (floorNumber, officeId, productType, startDate = null, endDate = null, features = null) => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(setMapProductsIsLoading(true));
    const dateParam = `/startdate/${startDate}/enddate/${endDate}`;
    const featuresString = features ? features.filter(f => f !== 0).join('&') : '';
    const featuresParam = featuresString ? `/features/${featuresString}` : '';
    api
      .get(`whitelabel/floor/${floorNumber}/officeid/${officeId}/type/${productType}${dateParam}${featuresParam}`)
      .then(({ data = {} }) => {
        if (data) {
          resolve(data);
        }
        resolve(data);
      })
      .catch(() => {})
      .finally(() => {
        dispatch(setMapProductsIsLoading(false));
      });
  });
};
