import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Scrollbars } from 'react-custom-scrollbars';
import _ from 'lodash';
import onClickOutside from 'react-onclickoutside';

class Dropdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      expanded: false,
      selected: {},
    };

    this.toggleExpand = this.toggleExpand.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.buildOption = this.buildOption.bind(this);
  }

  handleClickOutside = (e) => {
    this.setState({
      expanded: false
    });
  }

  onSelect(id, value) {
    const { onChange } = this.props;
    this.setState({
      selected: { id, value },
      expanded: false,
    });

    onChange(id);
  }

  toggleExpand() {
    const { expanded } = this.state;
    this.setState({
      expanded: !expanded,
    });
  }

  buildOption({ id, value }) {
    return (
      <div key={id} className="option" onClick={this.onSelect.bind(null, id, value)}>
        {value}
      </div>
    );
  }

  render() {
    const { selectedId, placeHolder, className, options, customText } = this.props;
    const { expanded, selected } = this.state;
    const selectedItem = _.has(selected, 'id') ? selected : options.find(x => x.id === selectedId);
    let firstLine = _.get(selectedItem, 'value', placeHolder);
    if (customText) {
      firstLine = (
        <>
          <span>{customText}</span>
          {firstLine}
        </>
      );
    }

    return (
      <div className={classnames({ expanded }, className, 'dropdown')}>
        <div className={classnames({'completed': !_.isEmpty(selected) || selectedId }, 'dropdown-input')} onClick={this.toggleExpand}>
          {firstLine}
        </div>
        <div className="container">
          <Scrollbars className="styleScroll">{options.map(this.buildOption)}</Scrollbars>
        </div>
      </div>
    );
  }
}

Dropdown.propTypes = {
  placeHolder: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({})),
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  selectedId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  customText: PropTypes.string,
};

Dropdown.defaultProps = {
  placeHolder: '',
  options: [],
  selectedId: null,
  className: '',
  customText: '',
};

export default onClickOutside(Dropdown);
