import {connect} from 'react-redux';
import AccountGeneralSettings from './../../components/accountGeneralSettings/accountGeneralSettings';
import {fetchProfile, requestExportData, requestDeleteData} from './../../actions/accountActions';

const mapStateToProps = state => {

    return {
        profileDetails: state.account.get('profile'),
        translations: state.translations,
    };
};

const mapDispatchProps = dispatch => {
    return {
        fetchProfile: () => {
           dispatch(fetchProfile());
        },
        requestExportData: () => {
           dispatch(requestExportData());
        },
        requestDeleteData: () => {
           dispatch(requestDeleteData());
        },
    }
};

const AccountGeneralSettingsView = connect(mapStateToProps, mapDispatchProps)(AccountGeneralSettings);

export default AccountGeneralSettingsView;
