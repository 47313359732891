import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import sizedImage from './../../utils/imageHelper';
import OfficeGalleryLightbox from './officeGalleryLightbox';
import _ from 'lodash';
const PRICE_LABELS = [
  'perHour',
  'perDay',
  'perWeek',
  'perMonth',
  'rentalPrice',
  'priceDay',
  'priceHalfDay'
];

class OfficeSpace extends React.Component {

  constructor() {
    super();

    this.state ={
      expandedRooms: {},
      showGallery: {},
      galleryCurrentImage: 0,
      imageNr: 0,
      availableWidth: 0
    };

    this.onOpenGallery            = this.onOpenGallery.bind(this);
    this.onCloseGallery           = this.onCloseGallery.bind(this);
    this.updateGaleryDimensions   = this.updateGaleryDimensions.bind(this);
  }

  componentDidMount() {
    let currentElement = this.grid.getBoundingClientRect();
    if(this.state.availableWidth !== currentElement.width) {
      this.setState({availableWidth: currentElement.width});
      this.updateGaleryDimensions();
    }
    window.addEventListener("resize", this.updateGaleryDimensions);
  }

  componentWillUnmount() {
      window.removeEventListener("resize", this.updateGaleryDimensions);
  }

  updateGaleryDimensions = () => {

    let galeryWidth = this.grid.getBoundingClientRect();
    if (this.galeryItem !== undefined) {
      let galeryItem = this.galeryItem.getBoundingClientRect();
      let galeryItemWidth = galeryItem.width + 29;
      let imageNr = Math.floor(galeryWidth.width / galeryItemWidth) - 1;
      this.setState({imageNr});
    }
  }


  getRoomInfo = ({pricing, extraOptions}) => {
    let optionList =[];
    if(extraOptions)
    {
      Object.keys(extraOptions).forEach(key => {
        optionList.push(this.getOption(key, extraOptions[key]));
      });
    }
    if(pricing)
    {
      Object.keys(pricing).forEach(key => {
        if (pricing[key])
            optionList.push(this.getOption(key, pricing[key]));
      });
    }
    return optionList;
  }

  getOption(key, value) {
    if(typeof value === 'object') {
      /* extra if for more cases in the future */
      if(value !== null && value.currency) {
        value = `${value.value}${value.currency}`;
      }
    }

    if(typeof value === 'boolean') {
        value = <span className={value ? 'available' : 'unavailable'}></span>;
    }

    if(value === null) {
        return null;
    }
    if(PRICE_LABELS.includes(key))
    {
        value = value.toFixed(2).toString().replace(".", window.__env.decimalSeparator) + '€' + (key === 'rentalPrice' ? '/event' : '');
    }
    return (
      <div key={key} className={"office-space-option " + key}>
        <div className="label">{this.props.trans.location.roomLabels[key]}</div>
        <div className="value">{value}</div>
      </div>
    )
  }

  onOpenGallery(roomType, id, index) {
    let showGallery = {};
    showGallery[roomType] = {};
    showGallery[roomType][id] = true;
    this.setState({
      showGallery,
      galleryCurrentImage: index
    });
  }

  onCloseGallery() {
    this.setState({
      showGallery: {}
    });
  }

  getGalleryEntity = (room, roomType) => {
    let {images} = room;

    let galleryImages = [];
    images.map((image) => {
      galleryImages.push({src: sizedImage(image.url, 1280, 720)});
      return null;
    });

    let showGallery = (Object.keys(this.state.showGallery).length > 0 && this.state.showGallery[roomType] !== undefined && this.state.showGallery[roomType][room.id] !== undefined && this.state.showGallery[roomType][room.id] === true) ? true : false;

    if(galleryImages.length === 0) return null;

    return <OfficeGalleryLightbox
      images={galleryImages}
      isOpen={showGallery}
      onCloseGallery={this.onCloseGallery}
      updateCurrentImage={()=>{}}
      currentImage={this.state.galleryCurrentImage}
    />
  }

  getGalleryImages = (room, roomType) => {
    let {images} = room;

    let galleryItems = [],
        galleryLength,
        moreItemsLabel;

    if (images) {
        galleryLength = images.length;
        const itemsLeft = galleryLength - this.state.imageNr - 1;
        moreItemsLabel = itemsLeft ? this.props.trans.formatString(this.props.trans.location.plusXmore, itemsLeft) : '';

        images.some((image, index) => {
            let galleryItem = (
                <div
                    ref={ r => this.galeryItem = r }
                    key={index}
                    onClick={() => {
                        this.onOpenGallery(roomType, room.id, index);
                    }}
                    className="office-space-galleryItem"
                    data-label={index === this.state.imageNr ? moreItemsLabel : ''}
                    style={{
                        backgroundImage: `url(${ sizedImage(image.url, 270, 190) })`,
                        backgroundPosition: 'center center',
                        cursor: 'pointer',
                    }}></div>
            );
            galleryItems.push(galleryItem);

            return index === this.state.imageNr;
        });

        return galleryItems;
    }
  }

  renderRooms = () => {
    return (
      <div>
        <div>{this.renderRoomType(this.props.products.desks, 'desk')}</div>
        <div>{this.renderRoomType(this.props.products.privateOffices, 'private')}</div>
        <div>{this.renderRoomType(this.props.products.meetingRooms, 'meeting')}</div>
        <div>{this.renderRoomType(this.props.products.eventRooms, 'event')}</div>
      </div>
    )
  }

  objLenghtWhithoutNull = (obj) => {
      Object.keys(obj).forEach((key) => (obj[key] == null) && delete obj[key]);
      return Object.keys(obj).length;
  }

  renderRoomType = (rooms, roomType) => {

    return rooms.map(room => {
      const images = this.getGalleryImages(room, roomType);
      if (!images || !images.length) {
        room.images = [
        ];
      }

      room.extraOptions = {};

      switch(roomType)
      {
        case 'desk':
            room.extraOptions.floor = room.floor;
            room.extraOptions.desks = room.count;
            room.optionsCount       = this.objLenghtWhithoutNull(room.pricing) + this.objLenghtWhithoutNull(room.extraOptions);
          break;
        case 'event':
            room.extraOptions.floor         = room.floor;
            room.extraOptions.seats         = room.seats;
            room.extraOptions.eventRoomName = room.name;
            if( this.props.bookableEvent === true)
            {
              room.extraOptions.priceDay   = room.price;  
              room.extraOptions.priceHalfDay   = room.priceHalfDay;  
            }
            else
            {
              room.extraOptions.rentalPrice   = room.price;  
            }
            room.extraOptions.area          = room.surface;
            room.extraOptions.style         = room.style;
            room.extraOptions.directions    = room.directions;
            room.optionsCount               = this.objLenghtWhithoutNull(room.extraOptions);

          break;
        case 'meeting':
            room.extraOptions.floor         = room.floor;
            room.extraOptions.seats         = room.seats;
            room.extraOptions.name          = room.name;
            room.extraOptions.setup         = room.setup;
            room.extraOptions.area          = room.surface;
            room.optionsCount               = this.objLenghtWhithoutNull(room.pricing)+ this.objLenghtWhithoutNull(room.extraOptions);

          break;
        case 'private':
            room.extraOptions.floor         = room.floor;
            room.extraOptions.seats         = room.seats;
            room.extraOptions.type          = this.props.trans.location.roomLabels[roomType];
            room.extraOptions.area          = room.surface;
            room.extraOptions.minRentalDays = room.minRentalDays;
            room.extraOptions.maxRentalDays = room.maxRentalDays;
            room.optionsCount               = this.objLenghtWhithoutNull(room.pricing)+ this.objLenghtWhithoutNull(room.extraOptions);
          break;
        default:
           break;
      }

      let roomLabel = this.props.trans.location.roomLabels[roomType];
      
      if(roomType === 'desk') {
        let roomFloor = `, ${_.get(this.props.trans.location, 'groundFloor', 'GroundFloor')}`;
        if (room.floor > 0) {
          roomFloor = `, ${_.get(this.props.trans.location, 'floor', 'Floor')} ${room.floor}`;
        }
        roomLabel = (room.count + ' ' + (room.count > 1 ? this.props.trans.location.deskPl : this.props.trans.location.desk) + roomFloor);
      } 

      if (roomType === 'private') {
        roomLabel = room.name;
      }

      return (
        <div key={room.id} className={`office-space-type-${roomType}`}>
            <div className="officeTitleRow">
                <div className="office-space-name">{roomLabel}</div>
                <div className="office-space-gallery-button">
                  <button onClick={() => this.props.selectProduct(room.id, roomType)}
                      onMouseEnter={() => { if(this.props.bookingSection === 'payment')
                  {this.props.openSidePopup(); }}} className="main-button">{this.props.trans.location.osBook}</button>
                </div>
            </div>
          <div className="office-space-info">

            <div className={this.getToogleClass(room)}>
              {this.getRoomInfo(room)}
            </div>
            {this.renderToggleButton(room, room.optionsCount > 6)}
          </div>
          <div className="office-space-gallery" ref={ r => this.grid = r }>

            <div className="office-space-gallery-clear"></div>
            <div className="office-space-gallery-items">
              {this.getGalleryImages(room, roomType)}
              {this.getGalleryEntity(room, roomType)}
            </div>
          </div>
        </div>
      )
    });
  }

  getToogleClass = ({id}) => {
    return classNames('office-space-options', {'expanded': this.state.expandedRooms[id]});
  }

  renderToggleButton = ({id}, show = false) => {
    let toggleClass = 'collapsed',
        toggleLabel = this.props.trans.location.expandLabel;

    if(this.state.expandedRooms[id]) {
      toggleClass = 'expanded';
      toggleLabel = this.props.trans.location.collapseLabel;
    }

    if(show)
    return (
      <div
        className={`office-space-${toggleClass}`}
        onClick={() => this.onToggleClick(id)}>{toggleLabel}</div>
    );
  }

  onToggleClick = (roomId) => {
    let {expandedRooms} = this.state;
    expandedRooms[roomId] = !expandedRooms[roomId];

    this.setState({ expandedRooms });
  }

  render() {

    return (
      <div id="office-space" className="office-space">
        <div className="office-space-title">{this.props.trans.location.officeSpace}</div>
        <div className="office-space-list">
        {this.renderRooms()}
        </div>
      </div>
    )
  }
}

OfficeSpace.propTypes = {
  products: PropTypes.object.isRequired,
};

export default withRouter(OfficeSpace);
