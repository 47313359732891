import _ from 'lodash';
import api from '../../components/openAuth/utils/api';
import api2 from '../../components/openAuth/utils/api2';
import { fetchProfile, fetchRestrictions } from '../accountActions';
import { resetBookingsMap } from '../officesActions';
import { setZoomToFitApplied } from '../map';
import { getCurrentHostname } from '../../utils/currentCompanyHostname';

export const SET_HERO_AUTHENTICATION = 'SET_HERO_AUTHENTICATION';
export const CLEAR_HERO_AUTHENTICATION = 'CLEAR_HERO_AUTHENTICATION';

export const setHeroAuthentication = isAuthenticated => ({
  type: SET_HERO_AUTHENTICATION,
  isAuthenticated,
});
export const clearHeroAuthenticationData = heroData => ({
  type: CLEAR_HERO_AUTHENTICATION,
  heroData,
});

export const setHeroDataAfterLogin = data => dispatch => {
  dispatch(setHeroAuthentication(data));
  dispatch(fetchProfile());
  dispatch(fetchRestrictions());
};
export const login = data => dispatch => {
  return new Promise((resolve, reject) => {
    api
      .post(`/alternative/login`, data)
      .then(response => {
        dispatch(resetBookingsMap(true));
        dispatch(setZoomToFitApplied(false));
        resolve(response);
      })
      .catch(error => {
        reject(error);
      });
  });
};
export const loginSSO = () => dispatch => {
  const currentCompany = getCurrentHostname();
  return new Promise((resolve, reject) => {
    api
      .get(`/sso/${currentCompany}/login`)
      .then(response => {
        console.log(response);
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
};
export const auth = data => dispatch => {
  return new Promise((resolve, reject) => {
    const source = _.get(data, 'source', '');
    const tokenData = _.get(data, 'token', '');
    const invitationKey = localStorage.getItem('invitation-key');

    const formData = new FormData();
    formData.append('token', tokenData);
    formData.append('invitation_key', invitationKey);

    api2
      .post(`/auth/${source}`, formData)
      .then(response => {
        const accessKey = _.get(response, 'data.access-key', null);
        if (accessKey) {
          localStorage.setItem('access-key', accessKey);
          localStorage.setItem('first-name', _.get(response, 'data.heroData.firstName', null));
          localStorage.setItem('last-name', _.get(response, 'data.heroData.lastName', null));
          dispatch(setHeroDataAfterLogin(true));
          resolve(response);
        }
      })
      .catch(error => {
        reject(error);
      });
  });
};
export const impersonate = data => dispatch => {
  return new Promise((resolve, reject) => {
    clearHeroAuthenticationData(null);
    localStorage.removeItem('access-key');
    api2
      .post(`impersonate`, data)
      .then(response => {
        const accessKey = _.get(response, 'data.access-key', null);
        if (accessKey) {
          const firstName = _.get(response, 'data.heroData.first_name', null);
          const lastName = _.get(response, 'data.heroData.last_name', null);
          const email = _.get(response, 'data.heroData.email', null);
          const source = _.get(data, 'source', null);
          if (source === 'admin') {
            const impersonatingHero = `${firstName} ${lastName} (${email})`;
            localStorage.setItem('impersonatingHero', impersonatingHero);
          }

          localStorage.setItem('access-key', accessKey);
          localStorage.setItem('first-name', _.get(response, 'data.heroData.firstName', null));
          localStorage.setItem('last-name', _.get(response, 'data.heroData.lastName', null));
          dispatch(setHeroDataAfterLogin(true));
          resolve(response);
        }
      })
      .catch(error => {
        reject(error);
      });
  });
};

export default login;
