import React, { Component } from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router';
import LinkedinIcon from '../../assets/images/badges/badgeLinkedin.png';

const queryString = require('query-string');

class LinkedinButton extends Component {
  render() {
    const { translations, clickedButton, history, location } = this.props;
    let actionMessage = _.get(translations, '3rdPartyAction', 'Log in with');
    if (clickedButton) {
      actionMessage = _.get(translations, 'continueWith', 'Continue with');
    }
    const LinkedinAction = `${actionMessage} Linkedin`;
    const params = queryString.parse(window.location.search);
    const fromBooking = _.get(location, 'state.fromBooking', false);
    const fromUrl = _.get(location, 'fromUrl', '/');
    //const state = params.state || JSON.stringify({ fromUrl, fromBooking }) || '98765EeFWf45A53sdfKef4233';
    // Generate unique identifier in order to protect against CSRF attack
    const state = this.uuidv4();
    let authurl = window.__env.linkedinUrl;
    authurl = authurl.replace('##BASE_URL##', window.__env.baseUrl);
    authurl = authurl.replace('##LINKEDIN_CLIENT_ID##', window.__env.linkedinClientId);
    authurl = authurl.replace('##LINKEDIN_STATE##', state);
    return (
      <a
        href={authurl}
        onClick={() => {}}
        id="login-form__linkedin-button"
        className="login-form__third-party-button linkedin-login-button"
      >
        <img src={LinkedinIcon} alt="" className="linkedin-login-icon" />
        <div className="linkedin-login-text">{LinkedinAction}</div>
      </a>
    );
  }
  uuidv4() {
    return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
  }
}

export default withRouter(LinkedinButton);
