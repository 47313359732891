import React from 'react';
import _ from 'lodash';
import PropsTypes from "prop-types";

class PaymentProfilesOptions extends React.Component {
  renderPersonalProfilesSection() {
    const { paymentProfiles, translations, heroProfile, handleAccountType, handleProfileAccount } = this.props;
    const firstName = _.get(heroProfile, 'firstName', '');
    const lastName = _.get(heroProfile, 'lastName', '');
    const personalProfile = _.filter(paymentProfiles.toJS().payment_profiles, ['payment_profile_type', 'personal']);
    return (
      <>
        <div className="account-type__title">{_.get(translations, 'typeOptions.personal', 'Personal')}</div>
        <div className="text-field__content">
          {!personalProfile.length ? (
            <div className="account-type-select">
              <label htmlFor="personal_account">
                <input
                  id="personal_account"
                  type="radio"
                  name="account_type"
                  value={0}
                  onChange={e => handleAccountType(e)}
                  className="radio-button"
                />
                <span>{_.get(translations, 'typeOptions.newPersonalProfile', 'New personal profile')}</span>
              </label>
            </div>
          ) : (
            personalProfile.map(profile => {
              return (
                <div className="account-type-select">
                  <label htmlFor="personal_account">
                    <input
                      id="personal_account"
                      type="radio"
                      name="account_type"
                      value={0}
                      onChange={() => {
                        handleProfileAccount(profile);
                      }}
                      className="radio-button"
                    />
                    <span>{`${firstName} ${lastName}`}</span>
                  </label>
                </div>
              );
            })
          )}
        </div>
      </>
    );
  }

  renderBusinessProfilesSection() {
    const { paymentProfiles, translations, handleProfileAccount, handleAccountType } = this.props;
    const businessProfile = _.filter(paymentProfiles.toJS().payment_profiles, ['payment_profile_type', 'company']);

    return (
      <>
        <div className="account-type__title">{_.get(translations, 'typeOptions.company', 'Business')}</div>
        <div className="text-field__content">
          {businessProfile.map(profile => {
            return (
              <div className="account-type-select">
                <label htmlFor={`business_account_${profile.id}`}>
                  <input
                    id={`business_account_${profile.id}`}
                    type="radio"
                    name="account_type"
                    value={1}
                    onChange={() => {
                      handleProfileAccount(profile);
                    }}
                    className="radio-button"
                  />
                  <span>{profile.company_name}</span>
                </label>
              </div>
            );
          })}
          <div className="account-type-select">
            <label htmlFor="business_account">
              <input
                id="business_account"
                type="radio"
                name="account_type"
                value={1}
                onChange={e => handleAccountType(e)}
                className="radio-button"
              />
              <span>{_.get(translations, 'typeOptions.newBusinessProfile', 'New business profile')}</span>
            </label>
          </div>
        </div>
      </>
    );
  }

  render() {
    const { paymentType } = this.props;
    return (
      <>
        { paymentType !== 2 ? this.renderPersonalProfilesSection() :  null}
        {this.renderBusinessProfilesSection()}
      </>
    );
  }
}
PaymentProfilesOptions.propsTypes ={
  paymentType : PropsTypes.int,
  paymentProfiles:PropsTypes.shape({}),
  translations: PropsTypes.shape({}),
  handleProfileAccount: PropsTypes.func,
  handleAccountType: PropsTypes.func,
}
PaymentProfilesOptions.defaultProps = {
   paymentType: null,
   paymentProfiles: {},
  translations: {}
}
export default PaymentProfilesOptions;
