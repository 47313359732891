import Immutable, { fromJS } from 'immutable';
import { SET_SIMILAR_MEDIA, SET_ADDITIONAL_MEDIA, SET_SIMILAR_MEDIA_IS_LOADING } from '../../actions/dashboard/similar-media';

const initialState = Immutable.Map({
  similarMedia: [],
  additionalMedia: [],
  isLoading: false,
});

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_SIMILAR_MEDIA:
      return state.setIn(['similarMedia'], fromJS(action.similarMedia));
    case SET_ADDITIONAL_MEDIA:
      return state.setIn(['additionalMedia'], fromJS(action.additionalMedia));
    case SET_SIMILAR_MEDIA_IS_LOADING:
      return state.setIn(['isLoading'], action.isLoading);
    default:
      return state;
  }
};
