import { connect } from 'react-redux';
import HeroCommunities from './HeroCommunities';
import toJS from '../../utils/to-js';
import addLoader from '../../../dashboard/utils/add-loader';
import { fetchHeroCommunities } from '../../../../actions/community/hero-communities';
import { heroCommunitiesSelector, heroCommunitiesIsLoadingSelector } from '../../../../selectors/community/hero-communities';

const mapStateToProps = state => ({
  heroCommunities: heroCommunitiesSelector(state),
  isLoading: heroCommunitiesIsLoadingSelector(state),
});

const mapDispatchToProps = dispatch => ({
  fetchHeroCommunities: () => dispatch(fetchHeroCommunities()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(toJS(addLoader(HeroCommunities)));
