import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import _ from 'lodash';
import './css/paymentOptions.scss';
import { withRouter } from 'react-router';
import VoucherIcon from './icons/voucher.svg';
import VisaIcon from './icons/visa.svg';
import MastercardIcon from './icons/mastercard.svg';
import AmexIcon from './icons/amex.svg';
import BancontactIcon from './icons/bancontact.svg';

class PaymentOptions extends React.Component {
  constructor(props) {
    super(props);

    this.buildOptionSelection = this.buildOptionSelection.bind(this);
  }

  buildOptionSelection(paymentMethod) {
    const { trans, selectedPaymentMethod, setSelectedPaymentMethod, heroProfile, formatString } = this.props;
    const { card_brand, last_digits, expire_date, payment_profile } = paymentMethod;
    const expiresOnArray = expire_date ? _.split(expire_date, '-') : null;
    const expiresOnData = expiresOnArray ? `${expiresOnArray[1]}/${expiresOnArray[0]}` : null;
    const defaultLabel = _.get(paymentMethod, 'is_default', 0) ? _.get(trans, 'defaultLabel', '(default)') : '';
    const endingInLabel = _.get(trans, 'endingIn', 'ending in');
    const expiresOnLabel = _.get(trans, 'expiresOn', 'expires on');
    let cardInfo = '';
    let iconSrc;
    let methodType = _.get(trans, 'creditCard', 'Credit card');

    switch (card_brand) {
      case 'Visa':
        iconSrc = VisaIcon;
        cardInfo = ` ${endingInLabel} ${last_digits}`;
        break;
      case 'MasterCard':
        iconSrc = MastercardIcon;
        cardInfo = ` ${endingInLabel} ${last_digits}`;
        break;
      case 'American Express':
        iconSrc = AmexIcon;
        break;
      case 'Bancontact':
        iconSrc = BancontactIcon;
        methodType = _.get(trans, 'bancontact', 'Maestro');
        break;
      case 'Voucher':
        iconSrc = VoucherIcon;
        methodType = _.get(trans, 'voucher', 'Voucher');
        cardInfo = formatString(_.get(trans, 'availableBalance', `{0}€ available`), _.get(paymentMethod, 'voucher.balance', '0'));
        break;
      default:
        break;
    }

    let paymentProfileInfo = '';
    if (payment_profile.payment_profile_type === 'personal') {
      const heroName = `${_.get(heroProfile, 'firstName', '')} ${_.get(heroProfile, 'lastName', '')}`;
      paymentProfileInfo = `${_.get(trans, 'personal', '')}, ${heroName}`;
    } else {
      paymentProfileInfo = `${_.get(trans, 'business', '')}, ${paymentMethod.payment_profile.company_name}`;
    }
    if (expire_date !== null) {
      paymentProfileInfo = `${paymentProfileInfo}, ${expiresOnLabel} ${expiresOnData}`;
    }
    return (
      <div className="payment-method-option" key={`payment_method_${paymentMethod.id}`}>
        <input
          id={`payment_${paymentMethod.id}`}
          name="payment_selected"
          type="radio"
          checked={paymentMethod.id === selectedPaymentMethod.id}
          onChange={() => {
            setSelectedPaymentMethod(paymentMethod);
          }}
        />
        <label htmlFor={`payment_${paymentMethod.id}`}>
          <img src={iconSrc} alt="" className="payment-option-icon" />
          <div className="payment-method__content">
            <div className="payment-method__card-details">
              <span className="payment-method__card-title">
                {methodType} {`${defaultLabel} `}
                <span className="payment-method__card-info">{cardInfo}</span>
              </span>
            </div>
            <span className="payment-method__profile-info">{paymentProfileInfo}</span>
          </div>
        </label>
      </div>
    );
  }

  render() {
    const { paymentMethods, onBack, history, location, trans } = this.props;

    const paymentMethodsOrder = _.orderBy(
      paymentMethods,
      method => {
        return method.payment_profile.payment_profile_type;
      },
      ['desc']
    );
    return (
      <Scrollbars className="styleScroll">
        <div className="booking-payment-options">
          <div className="booking-payment-options__content">
            <div className="booking-payment-options-scrollable">
              <label
                htmlFor=""
                onClick={() => {
                  onBack();
                }}
                className="back-button"
              >
                {_.get(trans, 'backToOption', 'Back payment option')}
              </label>
              <span className="booking-payment-options__title">{_.get(trans, 'yourPaymentMethod', 'Your payment')}</span>
              <div className="booking-payment-options__section">{paymentMethodsOrder.map(this.buildOptionSelection)}</div>
              <div className="booking-payment-options__footer">
                <span className="title">{_.get(trans, 'needToAddPaymentMethod', '')}</span>
                <span className="description">{_.get(trans, 'acceptsPaymentMethod', '')}</span>
                <span
                  className="add-payment-button"
                  onClick={() => {
                    history.push({ pathname: '/booking/payment', state: { from: location.pathname } });
                  }}
                >
                  {_.get(trans, 'addPaymentMethod', '')}
                </span>
              </div>
            </div>
          </div>
        </div>
      </Scrollbars>
    );
  }
}

export default withRouter(PaymentOptions);
