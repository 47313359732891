import React, { useState } from 'react';
import _ from 'lodash';
import moment from 'moment';

const ExtendBookingPopup = props => {
  const [extendedBookingHour, setExtendedBookingHour] = useState([]);
  const { booking, formatString, officeDetails, trans } = props;
  const id = _.get(booking, 'id', null);
  const closingOfficeTime = moment.utc().set({
    hour: _.get(officeDetails, 'openingHours.0.toHour', ''),
    minute: _.get(officeDetails, 'openingHours.0.toMinute', ''),
  });
  const location = _.get(booking, 'office.name', null);
  const officeType = _.get(booking, 'product.officeTypeId', 0) === 1 ? 'desk' : 'meeting room';
  const startHours = `${_.get(booking, 'hours.from.hour')}:${_.get(booking, 'hours.from.minute')}`;
  const endHours = `${_.get(booking, 'hours.to.hour')}:${_.get(booking, 'hours.to.minute')}`;
  const officeCloseHour = `${_.get(officeDetails, 'openingHours.0.toHour', '')}:${_.get(officeDetails, 'openingHours.0.toMinute', '')}`;
  const bookingTimeEnd = moment.utc().set({
    hour: _.get(booking, 'hours.to.hour'),
    minute: _.get(booking, 'hours.to.minute'),
  });
  let extendTextChoice = '';
  switch (extendedBookingHour) {
    case 30:
      extendTextChoice = _.get(trans, 'whitelabel.30minutes', '');
      break;
    case 60:
      extendTextChoice = _.get(trans, 'whitelabel.60minutes', '');
      break;
    default:
      extendTextChoice = _.get(trans, 'whitelabel.untilEndOfDay', '');
      break;
  }
  return (
    <div className="cancelBooking">
      <div onClick={props.onCancelAction} className="modal-close-button" />
      <div className="bookedOffice-popup-title">{_.get(trans, 'whiteLabel.extendTitle', 'whiteLabel.extendTitle')}</div>
      <div className="bookedOffice-popup-body">
        <span className="bookedOffice-text">
          {formatString(_.get(trans, 'whiteLabel.extendCustomMessage', ''), officeType, startHours, location, endHours)}
        </span>
        <div className="bookedOffice-extendBooking">
          <input
            id="endOfTheDay"
            name="extendBooking"
            type="radio"
            checked={extendedBookingHour !== 30 && extendedBookingHour !== 60}
            onChange={() => {
              setExtendedBookingHour();
            }}
          />
          <label htmlFor="endOfTheDay">
            {_.get(trans, 'whiteLabel.untilEndOfDay')} ({officeCloseHour})
          </label>
          <input
            id="oneHour"
            name="extendBooking"
            type="radio"
            checked={extendedBookingHour === 60}
            disabled={Math.floor(closingOfficeTime.diff(bookingTimeEnd, 'minutes')) < 60}
            onChange={() => {
              setExtendedBookingHour(60);
            }}
          />
          <label htmlFor="oneHour">1 hour</label>
          <input
            id="halfHour"
            name="product_selected"
            type="radio"
            checked={extendedBookingHour === 30}
            disabled={Math.floor(closingOfficeTime.diff(bookingTimeEnd, 'minutes')) < 30}
            onChange={() => {
              setExtendedBookingHour(30);
            }}
          />
          <label htmlFor="halfHour">30 minutes</label>
        </div>
      </div>
      <div className="cancelBooking-actions">
        <button style={{ marginLeft: '0' }} className="cancelBooking-blockButton secondary-button" onClick={props.onCancelAction}>
          Cancel
        </button>
        <button
          style={{ marginLeft: '0' }}
          className="cancelBooking-cancelButton bookedOffice-submitButton main-button"
          onClick={() => {
            props
              .onAction(id, extendedBookingHour)
              .then(response => {
                props.setToastMessage(response.message);
              })
              .catch((response = {}) => {
                props.setToastMessage(response);
              });
            props.onCancelAction();
          }}
        >
          {_.get(trans, 'whiteLabel.add', '')} {extendTextChoice}
        </button>
      </div>
    </div>
  );
};
export default ExtendBookingPopup;
