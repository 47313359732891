import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { getDistance as geolibGetDistance } from 'geolib';
import ConnectionsData from './connectionsData';
import StarRating from '../starRating/starRating';
import buildLocationUrl from "../../utils/buildLocationUrl";

const STATUS_LABELS = {
  'no-capacity': 'No Capacity',
  'office-closed': 'Office Closed',
  'under-rated': 'Under Rated',
  'other-industry': 'Other Industry',
  'amenity-not-available': 'Amenity Not Available',
  'service-not-available': 'Service Not Available',
  'fully-booked': 'Fully Booked',
  'office-type-not-available': 'Office Type Not Available',
};

class DefaultOfficeInfo extends React.Component {
  /**
   * Decides whether or not si required and renders connections info
   *
   * @method getConnections
   * @param {Immutable.Map} item
   * @returns {ReactElement}
   */
  getConnections(item) {
    const connections = item.get('connections');

    if (!this.props.showConnections || !connections || !connections.size) {
      return null;
    }

    return <ConnectionsData first={connections.getIn([0, 'name'])} total={connections.size} />;
  }

  getDistance() {
    if (this.props.item.get('officeStatus') !== 'available') {
      return <div className="officeBox-infoSmall">{STATUS_LABELS[this.props.item.get('officeStatus')]}</div>;
    }

    if (this.props.geoLocationCoords !== null) {
      const dist = geolibGetDistance(
        { latitude: this.props.geoLocationCoords.latitude, longitude: this.props.geoLocationCoords.longitude },
        { latitude: this.props.item.toJS().location.latitude, longitude: this.props.item.toJS().location.longitude }
      );
      const distance = dist / 1000;

      return (
        <div className="officeBox-infoSmall">
          {this.props.item.get('distance')}{' '}
          {distance > 10
            ? distance.toFixed(0)
            : distance
                .toFixed(2)
                .toString()
                .replace('.', window.__env.decimalSeparator)}{' '}
          km away
        </div>
      );
    }
    return null;
  }

  getRating() {
    if (this.props.item.getIn(['rating', 'average']) > 0) {
      return (
        <div className="officeBox-infoSmall">
          {this.props.item.getIn(['rating', 'votes'])}
          <StarRating initialRate={this.props.item.getIn(['rating', 'average'])} theme="theme-yellow-small" readonly />
        </div>
      );
    }
    return null;
  }

  render() {
    return (
      <div>
        <div className="officeBox-title items-style1">{this.props.item.get('name')}</div>
        {this.getDistance()}
        {this.getRating()}
        {this.getConnections(this.props.item)}
        <Link to={buildLocationUrl(this.props.item.toJS())} className="officeBox-location" />
      </div>
    );
  }
}

DefaultOfficeInfo.propTypes = {
  /**
   * Immutable map containing an office description
   * @param item
   * @type Immutable.Map
   */
  item: PropTypes.object.isRequired,
  /**
   * Flag which determines if connections are displayed or not
   * @param showConnections
   * @type boolean
   */
  showConnections: PropTypes.bool,
};

export default DefaultOfficeInfo;
