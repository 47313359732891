import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';

import OfficeGallery from './officeGallery';
import OfficeReviews from './officeReviews';
import OfficeContactInfo from './officeContactInfo';
import OfficeSchedule from './officeSchedule';
//import OfficeConnections from './officeConnections';
import OfficeAmenities from './officeAmenities';
import OfficeServices from './officeServices';
import OfficeRules from './officeRules';
import OfficeSpace from './officeSpace';
import OfficeCancelation from './officeCancelation';
import OfficeBooked from './officeBooked';

import './css/index.scss';
import OfficeHealthMeasures from "./officeHealthMeasures";
import OfficeVirtualTour from "./officeVirtualTour";

class OfficeDetails extends Component {
  props;
  context;

  static contextTypes = {
    router: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      loaded: null,
      scrolled: false
    };
  }

  componentDidMount() {
    this.setState({loaded: true});
  }

  componentDidUpdate() {
    if(window.location.hash === '#office-cancellation-policy') {
      const element = document.getElementById('office-cancellation-policy');
      if(element !== null && this.state.scrolled === false) {
        element.scrollIntoView({behavior: 'smooth'});
        this.setState({scrolled: true});
      }
    }
  }

  renderSections() {

    const sections = [];
    const { details, amenities:defaultAmenities, services: servicesData, trans } = this.props;
    const { id, amenities:whitelabelAmenities, images, reviews, contactPerson, openingHours, amenityIds, services, servicePackages, houseRules, products, policies, name, email, phoneNumber, address, location, closedDays, bookableEvent, healthMeasures, virtualTourLink} = details;
    const bookedStatus = typeof details['booked-status-location' + id] !== "undefined" ? details['booked-status-location' + id] : false;
    const isWhitelabel = _.get(details, 'white_label', null);
    const amenities = defaultAmenities || whitelabelAmenities;
    /* may need refactored */

    if(images
        && images.length > 0
        && !bookedStatus) {
      sections.push(<OfficeGallery
          key={1}
          images={images}
          trans={this.props.trans}
      />);
    }

    if(virtualTourLink) {
      sections.push(<OfficeVirtualTour trans={_.get(trans, 'location.virtualTour', {})} virtualTourLink={virtualTourLink} />);
    }

    if(reviews && reviews.length > 0 && !bookedStatus) {
      sections.push(<OfficeReviews key={2} reviews={reviews} />);
    }

    if(contactPerson) {
      let map = {
        defaultZoom: 10,
        zoom: 15,
        center: { lat: location.latitude, lng: location.longitude },
        activeMarkerID: null,
        activeMarker: null,
        id: null,
        markers: [
          {
            id: 1,
            location: {
              latitude: location.latitude,
              longitude: location.longitude
            }
          }
        ]
      };
      let contactInfo = {
        map: map,
        address: address,
        name: contactPerson.name,
        phone: (!phoneNumber.countryCode || !phoneNumber.number) ? null : phoneNumber.countryCode + ' ' + phoneNumber.number,
        emailPerson: contactPerson.email,
        emailOffice: email
      };

      sections.push(<OfficeContactInfo
          key={3}
          contactInfo={contactInfo}
          bookedStatus={ bookedStatus }
          title={ name }
          trans={this.props.trans}
      />);
    }
    if(openingHours && openingHours.length && !bookedStatus) {
      sections.push(<OfficeSchedule
          key={5}
          workingHours={openingHours}
          closedDays={closedDays}
          trans={this.props.trans}
      />);
    }

    if(amenityIds && !bookedStatus && amenities) {
      sections.push(<OfficeAmenities
          key={6}
          availableAmenities={amenityIds}
          amenities={amenities}
          trans={this.props.trans}
      />);
    }

    if(services && !bookedStatus && servicesData) {
      sections.push(<OfficeServices
          key={7}
          services={services}
          servicesData={servicesData}
          packages={servicePackages}
          trans={this.props.trans}
      />);

    }
    if(healthMeasures && !bookedStatus) {
      sections.push(<OfficeHealthMeasures
          key={13}
          measures={healthMeasures}
          trans={this.props.trans}
      />);
    }
    if(houseRules && !bookedStatus) {
      sections.push(<OfficeRules
          key={9}
          rules={houseRules}
          trans={this.props.trans}
      />);
    }

    if(products && !bookedStatus && !isWhitelabel) {
      sections.push(<OfficeSpace
          key={10}
          selectProduct={this.props.selectProduct}
          openSidePopup={this.props.openSidePopup}
          bookingSection={this.props.bookingSection}
          products={products}
          bookableEvent={bookableEvent}
          trans={this.props.trans}
      />);
    }

    if(bookedStatus) {
      sections.push(<OfficeBooked key={11} details={ this.props.details } />);
    }

    if(sections.length > 0  && !isWhitelabel)
    {
      sections.push(<OfficeCancelation
          key={12}
          officeId={id}
          policies={policies}
          bookedStatus = { bookedStatus }
          bookableEvent= { bookableEvent }
          trans={this.props.trans}
      />);
    }

    return sections;
  }

  getTitle (title, bookedStatus) {
    if (bookedStatus) {
      return <div className="location-booked-title items-style1">{this.props.trans.location.yourBookingAt} { title } <span className="success-message">{this.props.trans.location.isConfirmed}</span>. {this.props.trans.location.cantWait}</div>
    } else {
      return <h3 className="items-style1">{title}</h3>
    }
  }

  getDescription (description, bookedStatus) {
    if (!bookedStatus) {
      return <span className="office-details-paragraph">{description}</span>
    }
  }

  render () {

    if(this.state.loaded === null)
    {
      return (
          <div className="officeDetailsLoader">
          </div>
      );
    }

    let {name, description} = this.props.details;

    const id = this.props.details.id;
    const bookedStatus = this.props.details['booked-status-location' + id];

    if(typeof id === "undefined" || this.state.loaded === null)
    {
      return (
          <div className="officeDetailsLoader">
          </div>
      );
    }
    else
    {
      return (
          <div className="office-details">
            <Link to="/booking" className="office-details-back-link">{this.props.trans.location.back}</Link>
            {this.getTitle(name, bookedStatus)}
            {this.getDescription(description, bookedStatus)}
            {this.renderSections()}
            <br />
          </div>
      );
    }
  }
}

OfficeDetails.propTypes = {
  details: PropTypes.object.isRequired
};

export default OfficeDetails;
