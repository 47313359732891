export const getWorkDays = (weekDays, weekDaysMap, checkedOutWeekday = null, checkedOutTranslation = null, shortenDays = false) => {
  if (typeof weekDays !== 'undefined') {
    const weekDaysLabel = [];
    const currentWeekday = new Date().getDay();
    weekDays.map(weekday => {
      if (checkedOutWeekday === weekday && weekday === currentWeekday) {
        weekDaysLabel.push(`${shortenDays ? weekDaysMap[weekday].substring(0, 3) : weekDaysMap[weekday]} ${checkedOutTranslation}`);
      } else {
        weekDaysLabel.push(shortenDays ? weekDaysMap[weekday].substring(0, 3) : weekDaysMap[weekday]);
      }
      return null;
    });
    return weekDaysLabel.join(', ');
  }
  return null;
};
