import React, { Component } from 'react';
import Truncate from 'react-truncate';
import _ from 'lodash';
import noDocumentIcon from '../../../../assets/svg/community/uploads/community-no-uploads-document.svg';
import noImageIcon from '../../../../assets/svg/community/uploads/community-no-uploads-image.svg';
import noVideoIcon from '../../../../assets/svg/community/uploads/community-no-uploads-video.svg';
import uploadDocument from '../../../../assets/svg/community/uploads/community-uploads-document.svg';
import uploadImage from '../../../../assets/svg/community/uploads/community-uploads-image.svg';
import uploadVideo from '../../../../assets/svg/community/uploads/community-uploads-video.svg';
import downloadIcon from '../../../../assets/svg/community/download-attachment.svg';
import downloadIconGrey from '../../../../assets/svg/community/download-attachment-grey.svg';
import removeIcon from '../../../../assets/svg/community/close-modal-red.svg';
import { filesizeFormatter } from '../../utils/filesize-formatter';
import CommunityModal from '../../common/modals';
import InfoToast from '../../common/info-toast/InfoToast';

const getCardImage = cardType => {
  if (cardType === 'document') {
    return uploadDocument;
  }
  if (cardType === 'image') {
    return uploadImage;
  }
  return uploadVideo;
};

class Uploads extends Component {
  constructor(props) {
    super(props);
    this.seeAllUploads = this.seeAllUploads.bind(this);
    this.buildCard = this.buildCard.bind(this);
    this.handleRemoveAttachmentModal = this.handleRemoveAttachmentModal.bind(this);

    this.state = {
      allUploadsButton: true,
      showRemoveAttachmentModal: false,
      attachmentId: null,
      showAttachmentRemovedToast: false,
      message: null,
    };
  }

  seeAllUploads() {
    this.setState({ allUploadsButton: false });
  }

  handleRemoveAttachmentModal() {
    const { showRemoveAttachmentModal } = this.state;
    this.setState({ showRemoveAttachmentModal: !showRemoveAttachmentModal });
  }

  buildCard(cardData, index) {
    const { isAdmin } = this.props;
    const cardType = _.get(cardData, 'attachmentType');
    const cardName = _.get(cardData, 'originalName');
    const cardLink = _.get(cardData, 'attachmentUrl');
    const cardSize = filesizeFormatter(_.get(cardData, 'attachmentSize'));
    const cardImage = getCardImage(cardType);
    const cardId = _.get(cardData, 'attachmentId');

    return (
      <div key={index} className="upload-card">
        <div className="upload-card__container">
          <img src={cardImage} className="upload-card__img" alt={cardName} />
          <div className="upload-card__details">
            <div className="card-details__title">
              <Truncate lines={1} ellipsis={<>... </>}>
                {cardName}
              </Truncate>
            </div>
            <div className="card-details__size">{cardSize}</div>
          </div>
          {isAdmin && (
            <div
              className="upload-card__remove-btn"
              onClick={() => this.setState({ showRemoveAttachmentModal: true, attachmentId: cardId })}
            >
              <img src={removeIcon} alt={cardName} />
            </div>
          )}

          <a href={cardLink} download={cardName} target="_blank" rel="noopener noreferrer">
            <div className="upload-card__download-btn">
              <img src={downloadIcon} alt={cardName} />
            </div>
          </a>
        </div>
      </div>
    );
  }

  resetToastData() {
    setTimeout(
      function() {
        this.setState({
          showAttachmentRemovedToast: false,
          message: null,
        });
      }.bind(this),
      5000
    );
  }

  renderRemoveAttachmentModal(attachmentId) {
    const { translations, removeCommunityUpload } = this.props;
    const formTitle = _.get(translations, 'removeAttachmentModal.title', 'removeAttachmentModal.title');

    return (
      <CommunityModal
        title={formTitle}
        closeModal={this.handleRemoveAttachmentModal}
        wrapperClasses="community-modals community-confirmation-modal__wrapper"
        body={
          <div className="community-confirmation-modal__container">
            <div className="community-confirmation-modal__confirmation-message">
              {_.get(translations, 'removeAttachmentModal.confirmationMessage', 'removeAttachmentModal.confirmationMessage')}
            </div>

            <div className="community-confirmation-modal__actions">
              <button
                type="button"
                onClick={this.handleRemoveAttachmentModal}
                id="community-confirmation-modal__cancel-button"
                className="community-confirmation-modal__cancel-button"
              >
                {_.get(translations, 'deleteCommunityModal.cancelButton', 'deleteCommunityModal.cancelButton')}
              </button>

              <button
                id="community-confirmation-modal__save-button"
                type="button"
                className="button-yellow community-confirmation-modal__save-button community-confirmation-modal__save-button--more-padding"
                onClick={() => {
                  this.setState({ showRemoveAttachmentModal: false, attachmentId: null });
                  removeCommunityUpload(attachmentId)
                    .then(() => {
                      this.setState({
                        showAttachmentRemovedToast: true,
                        message: _.get(translations, 'toast.attachmentRemoved', 'toast.attachmentRemoved'),
                      });
                      this.resetToastData();
                    })
                    .catch(message => {
                      this.setState({ showAttachmentRemovedToast: true, message });
                      this.resetToastData();
                    });
                }}
              >
                {_.get(translations, 'removeAttachmentModal.delete', 'removeAttachmentModal.delete')}
              </button>
            </div>
          </div>
        }
      />
    );
  }

  render() {
    const { translations, uploads, displayModalAction } = this.props;
    const trans = _.get(translations, 'uploads', []);

    const { allUploadsButton, showRemoveAttachmentModal, attachmentId, showAttachmentRemovedToast, message } = this.state;
    const showAllUploadsButton = uploads.length > 3;

    const hasUploads = uploads.length;

    const removeAttachmentModal = showRemoveAttachmentModal && attachmentId ? this.renderRemoveAttachmentModal(attachmentId) : null;

    return (
      <div className="community-uploads__container">
        {showAttachmentRemovedToast && <InfoToast message={message} />}
        {removeAttachmentModal}
        <div className="community-uploads__header">{trans.title}</div>
        {hasUploads ? (
          <>
            {allUploadsButton ? (
              <>
                <div className="community-uploads__cards">{_.take(uploads, 3).map(this.buildCard)}</div>
                {showAllUploadsButton ? (
                  <button type="button" className="entity-button" id="see-all-uploads" onClick={() => this.seeAllUploads()}>
                    {trans.seeAll}
                  </button>
                ) : null}
              </>
            ) : (
              <div className="community-uploads__cards">{uploads.map(this.buildCard)}</div>
            )}
          </>
        ) : (
          <>
            <div className="community-no-uploads">
              <img src={downloadIconGrey} alt="Download" className="no-uploads-img" />
              <p className="no-uploads-title">{trans.noUploads}</p>
              <p className="no-uploads-subtitle">{trans.beTheFirst}</p>
              <div className="no-uploads-images">
                <div className="no-uploads-image" onClick={() => displayModalAction()}>
                  <img src={noImageIcon} alt="Camera Icon" />
                </div>
                <div className="no-uploads-image" onClick={() => displayModalAction()}>
                  <img src={noVideoIcon} alt="Video Icon" />
                </div>
                <div className="no-uploads-image" onClick={() => displayModalAction()}>
                  <img src={noDocumentIcon} alt="Document Icon" />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
}

export default Uploads;
