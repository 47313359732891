import Immutable, { fromJS } from 'immutable/dist/immutable';
import { SET_UPCOMING_BOOKINGS, SET_UPCOMING_BOOKINGS_IS_LOADING } from '../../actions/dashboard/upcoming-bookings';

const initialState = Immutable.Map({
  bookings: fromJS([]),
  isLoading: false,
});

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_UPCOMING_BOOKINGS:
      return state.setIn(['bookings'], fromJS(action.bookings));
    case SET_UPCOMING_BOOKINGS_IS_LOADING:
      return state.setIn(['isLoading'], action.isLoading);
    default:
      return state;
  }
};
