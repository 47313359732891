import { connect } from 'react-redux';

import toJS from '../../utils/to-js';
import { saveComment } from '../../../../actions/community/edit-comment';
import { isLoadingSelector, formErrorsSelector } from '../../../../selectors/community/edit-comment';

import EditCommentForm from './EditCommentForm';
import { showCommunitiesPageToast } from '../../../../actions/community/community-toasts';

const mapDispatchToProps = dispatch => ({
  saveComment: data => dispatch(saveComment(data)),
  showCommunitiesPageToast: message => dispatch(showCommunitiesPageToast(message)),
});
const mapStateToProps = state => ({
  isLoading: isLoadingSelector(state),
  errors: formErrorsSelector(state),
  heroProfile: state.global.get('heroProfile'),
});
export default connect(mapStateToProps, mapDispatchToProps)(toJS(EditCommentForm));
