import { connect } from 'react-redux';
import OfficeTypePicker from './OfficeTypePicker';
import { fetchMapProducts, getFirstAvailable } from '../../../../../actions/whiteLabel/products';
import {
  setNoParkingsAvailable,
  setSelectedDate,
  setSelectedProductId,
  setSelectedProductType,
} from '../../../../../actions/whiteLabel/booking';
import { floorPlansSelector, selectedFeaturesSelector, selectedFloorIndexSelector } from '../../../../../selectors/whiteLabel/filter';
import {
  selectedDateSelector,
  selectedProductIdSelector,
  selectedProductTypeSelector,
  noParkingsAvailableSelector,
} from '../../../../../selectors/whiteLabel/booking';
import { fetchHeroesBookings } from '../../../../../actions/whiteLabel/heroes-booking-list';
import { setSelectedFeatures, setSelectedFloorIndex } from '../../../../../actions/whiteLabel/filter';

const mapStateToProps = state => {
  return {
    translations: state.translations,
    officeId: state.location.get('details').get('id'),
    floorPlan: floorPlansSelector(state),
    selectedFloorIndex: selectedFloorIndexSelector(state),
    selectDateRange: selectedDateSelector(state),
    selectedProductId: selectedProductIdSelector(state),
    selectedProductTypeId: selectedProductTypeSelector(state),
    selectedFeatures: selectedFeaturesSelector(state),
    officeDetails: state.location.get('details'),
    noParkingsAvailable: noParkingsAvailableSelector(state),
  };
};

const mapDispatchProps = dispatch => {
  return {
    fetchMapProducts: (officeId, floorId, productType, startDate, endDate, features) =>
      dispatch(fetchMapProducts(officeId, floorId, productType, startDate, endDate, features)),
    getFirstAvailable: (officeId, floorId, productType, startDate, endDate, features) =>
      dispatch(getFirstAvailable(officeId, floorId, productType, startDate, endDate, features)),
    setSelectedProductId: productId => dispatch(setSelectedProductId(productId)),
    setSelectedProductType: productType => dispatch(setSelectedProductType(productType)),
    setSelectedDate: (startDate, endDate) => dispatch(setSelectedDate(startDate, endDate)),
    fetchHeroesBookings: (officeId, startDate, endDate, type) => dispatch(fetchHeroesBookings(officeId, startDate, endDate, type)),
    setSelectedFeatures: feature => dispatch(setSelectedFeatures(feature)),
    setNoParkingsAvailable: flag => dispatch(setNoParkingsAvailable(flag)),
    setSelectedFloorIndex: selectedFloorIndex => dispatch(setSelectedFloorIndex(selectedFloorIndex)),
  };
};

export default connect(mapStateToProps, mapDispatchProps)(OfficeTypePicker);
