import Immutable, { fromJS } from 'immutable';
import { SET_MEDIA_COMPANY, SET_MEDIA_COMPANY_LOGO, SET_MEDIA_COMPANY_IS_LOADING } from '../../actions/dashboard/media-company-profile';

const initialState = Immutable.Map({
  company: fromJS({}),
  companyLogo: null,
  isLoading: false,
});

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_MEDIA_COMPANY:
      return state.setIn(['company'], fromJS(action.company));
    case SET_MEDIA_COMPANY_LOGO:
      return state.setIn(['companyLogo'], fromJS(action.companyLogo));
    case SET_MEDIA_COMPANY_IS_LOADING:
      return state.setIn(['isLoading'], action.isLoading);
    default:
      return state;
  }
};
