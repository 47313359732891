import api from '../../components/openAuth/utils/api';

export const forgot = data => dispatch => {
  return new Promise((resolve, reject) => {
    api
      .post(`/alternative/password/forgot`, data)
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const reset = data => dispatch => {
  return new Promise((resolve, reject) => {
    api
      .post(`/alternative/password/reset`, data)
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        reject(error);
      });
  });
};
