import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Truncate from 'react-truncate';
import classnames from 'classnames';
import getLang from '../../../../../utils/lang';

const lang = getLang() !== null ? getLang() : 'en';
const buildImageVideoContent = news => {
  const imgSrc = _.get(news, 'image', null);
  const videoSrc = _.get(news, 'video', null);

  if (videoSrc) {
    return (
      <div className="news-card-right-section">
        <iframe
          className="news-card__youtube-iframe"
          title="video"
          src={videoSrc}
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; "
          allowFullScreen
        />
      </div>
    );
  }
  if (imgSrc) {
    const title = _.get(news, 'category', null);
    return <img className="news-card-right-section news-card__image" src={imgSrc} alt={title} />;
  }
  return null;
};

const goToLink = (link, openInSameTab) => {
  if (link) {
    if (openInSameTab) {
      window.location = link;
    } else {
      window.open(link, '_blank');
    }
  }
};

const Card = props => {
  const { news, translations } = props;
  const category = _.get(news, 'category', null);
  const content = _.get(news, 'content', null);
  const title = _.get(news, 'title', null);
  const tagList = _.get(news, 'tags', null);
  const imgSrc = _.get(news, 'image', null);
  const videoSrc = _.get(news, 'video', null);
  const link = _.get(news, 'link', null);
  const publisher = _.get(news, 'publisher', null);
  const date = _.get(news, 'date', null);
  const translatedDate = new Date(date).toLocaleString(lang, { day: 'numeric', month: 'long', year: 'numeric' });
  const openInSameTab = _.get(news, 'openSameTab', false);
  const translatedCategory = _.get(translations, category, category);
  const id = _.get(news, 'id', null);

  return (
    <div id={`news-${id}`} className="card__container card__container--light news-card" onClick={goToLink.bind(null, link, openInSameTab)}>
      <div className={classnames('news-card-left-section', { 'news-card-left-section-full': !imgSrc && !videoSrc })}>
        <div className="news-card__header-wrapper">
          <span className="news-card__header">
            {publisher ? (
              <span className="news-card__type">{publisher}</span>
            ) : (
              <span className="news-card__type">{translatedCategory}</span>
            )}
            {tagList ? (
              <>
                <span className="dot_between">•</span>
                <span className="news-card__tags">{tagList}</span>{' '}
              </>
            ) : null}
          </span>
        </div>
        <div className="news-card__title">
          <Truncate lines={1} ellipsis={<>...</>}>
            {title}
          </Truncate>
        </div>
        <div className="news-card__content">
          <Truncate lines={2} ellipsis={<>...</>}>
            {content}
          </Truncate>
        </div>
        {publisher ? (
          <div className="news-card__footer">
            <div className="card-footer__container">
              {/* {publisher} <span className="dot-between__footer">•</span> */}
              {translatedDate}
            </div>
          </div>
        ) : null}
      </div>
      {buildImageVideoContent(news)}
    </div>
  );
};

Card.propTypes = {
  news: PropTypes.shape({}).isRequired,
};

export default Card;
