import React, { Component } from 'react';
import classnames from 'classnames';
import _ from 'lodash';

class MediaFeedback extends Component {
  constructor(props) {
    super(props);
    this.saveHeroMediaVote = this.saveHeroMediaVote.bind(this);
  }

  saveHeroMediaVote(vote) {
    const { mediaId, saveHeroMediaFeedback } = this.props;
    saveHeroMediaFeedback({
      vote,
      mediaId,
    });
  }

  render() {
    const { vote, translations } = this.props;

    return (
      <div className="media-footer__wrapper">
        <span className="footer__recommend-text">
          {_.get(translations, 'usefulRecommendation', 'Did you find this recommendation useful?')}
        </span>

        <span
          className={classnames({ 'footer__recommend-selected': vote === 1 }, { disabled: vote !== null }, 'footer__recommend-yes')}
          onClick={() => {
            if (vote === null) {
              this.saveHeroMediaVote(1);
            }
          }}
        >
         {_.get(translations, 'yes', 'Yes')}
        </span>
        <span
          className={classnames({ 'footer__recommend-selected': vote === 0 }, { disabled: vote !== null }, 'footer__recommend-no')}
          onClick={() => {
            if (vote === null) {
              this.saveHeroMediaVote(0);
            }
          }}
        >
          {_.get(translations, 'no', 'No')}
        </span>
      </div>
    );
  }
}

export default MediaFeedback;
