import React from 'react';
import {injectStripe} from 'react-stripe-elements';
import classnames from 'classnames';
import _ from 'lodash';

import CardSection from './CardSection';

class CardForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      cardErrors: null
    };
  }

  handleSubmit = (ev) => {
    // We don't want to let default form submission happen here, which would refresh the page.
    ev.preventDefault();
    // Within the context of `Elements`, this call to createToken knows which Element to
    // tokenize, since there's only one in this group.
    this.props.stripe.createToken({name: this.props.customerName}).then((response) => {
      if(response.token === undefined)
      {
        const stripeCardError = _.get(response, 'error.message', null);
         this.setState({
           cardErrors: stripeCardError
         });
         this.props.updateValidating(false);
      }
      else
      {
          this.props.updateStripeToken(response.token);
      }
    });
  }

  onCardLoaded = (elementRef) => {
    elementRef.addEventListener('change', function(event) {
      let displayError = null;
      if (event.error) {
        displayError = event.error.message;
      } else {
        displayError = null;
      }
      this.setState({
        cardErrors: displayError
      });
    }.bind(this));
  }

  render() {
    const { errors } = this.props;
    const { cardErrors } = this.state;
    const hasErrors = errors || cardErrors;
    return (
      <div>
        <div id="cardTestDev"></div>
      <form onSubmit={this.handleSubmit}>
        <CardSection
        onCardLoaded={this.onCardLoaded}
         />
        {hasErrors ? (
          <div className="stripe-card-error">{errors || cardErrors}</div>
        ) : null
        }
        <div>
          <button
          onClick={() => { if(hasErrors === null) {this.props.updateValidating();} else {this.props.updateValidating(false);} }}
          className={classnames({'disabled': hasErrors === null ? false : true}, 'add-card-button')}>{this.props.buttonText ? this.props.buttonText : 'ADD CARD'}</button>
        </div>
      </form>
      </div>
    );
  }
}

export default injectStripe(CardForm);
