import Immutable from 'immutable';
import uuid from 'uuid';
import _ from 'lodash';

import {
  ADD_IMAGE,
  ADD_MESSAGE,
  ADD_SOCKET_MESSAGE,
  CLEAR_MODAL_DATA,
  DELETE_CONVERSATION_CONFIRMATION,
  DELETE_CONVERSATION,
  DIRECT_CHAT_TICK,
  FETCH_CHAT_CONNECTIONS,
  FETCH_CONVERSATION_MESSAGES,
  FETCH_DIRECT_CHAT_CONVERSATIONS,
  GET_DIRECT_CHAT_CONNECTIONS,
  GET_DIRECT_CHAT_CONVERSATIONS,
  MARK_CONVERSATION_AS_READ,
  MUTE_CONVERSATION_CONFIRMATION,
  MUTE_CONVERSATION,
  OPEN_CONVERSATION,
  OPEN_CONVERSATION_AND_FETCH,
  REMOVE_IMAGE,
  REPORT_CONVERSATION_CONFIRMATION,
  REPORT_CONVERSATION,
  SET_CONVERSATION_ONLINE_STATUS_LIST,
  SET_HERO_ONLINE_STATUS,
  STORE_SCROLL_HEIGHT,
  STORE_SCROLL_HEIGHT_CONTACTS,
  TOGGLE_CONVERSATION_OPTIONS,
  TOGGLE_CONVERSATIONS,
  UPDATE_UNREAD_CONVERSATIONS_COUNT,
  CLOSE_ACTIVE_CONVERSATION,
} from './../actions/directChat';

const defaultState = Immutable.fromJS({
  online: false,
  connections: Immutable.List(),
  conversations: Immutable.List(),
  connectionsInitialLoaded: false,
  conversationsInitialLoaded: false,
  messagesLoaded: false,
  lastFetchedEpoch: 0,
  scrollHeight: 0,
  scrollHeightContacts: 0,
  storedConnections: Immutable.List(),
  storedConversations: Immutable.List(),
  onlineHeroIds: Immutable.List(),
  onlineStatusSynced: false,
  meta: Immutable.Map({
    contactFilterTerm: '',
    filterTerm: '',
    messageImage: null,
    modalShowing: false,
    modalData: Immutable.Map(),
    optionsOpened: false,
    showConversations: true
  }),
  now: new Date(),
  refreshChat: false,
  unreadConversations: 0
});

const filterConversations = (conversationState, term) => {
  if (!term) {
    return conversationState;
  }

  return conversationState.reduce((conversations, conversation) => {
    let matchesTerm = false;

    if (!_.isString(conversation.getIn(['lastMessage', 'text']))) {
      return conversations;
    }

    if (
      conversation.getIn(['lastMessage', 'text']).toLowerCase().indexOf(term) !== -1 ||
      conversation.getIn(['hero', 'firstName']).toLowerCase().indexOf(term) !== -1 ||
      conversation.getIn(['hero', 'lastName']).toLowerCase().indexOf(term) !== -1
    ) {
      matchesTerm = true;
    } else if (conversation.has('thread')) {
      conversation.get('thread').forEach(message => {
        if (message.get('text').toLowerCase().indexOf(term) !== -1) {
          matchesTerm = true;
        }
      })
    }

    if (matchesTerm) {
      return conversations.push(conversation);
    }

    return conversations;
  }, Immutable.List());
};

const directChatState = (state = defaultState, action) => {

  // console.log('%c ' + action.type, 'color: orange');
  let onlineHeroIds       = state.get('onlineHeroIds');
  switch (action.type) {

    case SET_CONVERSATION_ONLINE_STATUS_LIST:
      return state.set('onlineHeroIds', Immutable.fromJS(action.list));

    case ADD_IMAGE:
      return state.setIn(['meta', 'messageImage'], action.image);

    case ADD_MESSAGE: {
      let message = _.trim(action.message);
      const newState = state.set('storedConversations', state.get('conversations').reduce((conversations, conversation) => {
        if (conversation.get('id') === action.id) {
          let cnv = conversation;
          return conversations.push(conversation
            .set('isRead', true)
            .set('lastMessage', Immutable.fromJS({
              text: message,
              heroId: action.heroId,
              timestamp: Math.floor(+new Date()/1000),
              read: true
            })).set('thread', cnv.get('thread', Immutable.List()).push(Immutable.fromJS({
              clientId: 0,
              heroId: action.heroId,
              text: message,
              timestamp: Math.floor(+new Date()/1000)
            })))
          );
        }
        return conversations.push(conversation);
      }, Immutable.List()));

      return newState
        .set('conversations', filterConversations(newState.get('storedConversations'), newState.getIn(['meta', 'filterTerm'])))
        .setIn(['meta', 'messageImage'], null).set('now', new Date());
    }

    case ADD_SOCKET_MESSAGE:

      const newState = state.set('storedConversations', state.get('conversations').reduce((conversations, conversation) => {
        
        if (conversation.getIn(['hero', 'id']) === action.args.sourceHeroId) {
          let cnv = conversation;
          return conversations.push(conversation
            .set('lastMessage', Immutable.fromJS({
              text: action.args.text,
              heroId: action.args.targetHeroId,
              timestamp: Math.floor(+new Date()/1000)
            })).set('thread', cnv.get('thread', Immutable.List()).push(Immutable.fromJS({
              heroId: action.args.sourceHeroId,
              clientId: action.args.sourceHeroId,
              text: action.args.text,
              read: true
            })))
          );
        }
        return conversations.push(conversation);
      }, Immutable.List()));

      return newState
        .set('conversations', filterConversations(newState.get('storedConversations'), newState.getIn(['meta', 'filterTerm'])))
      .setIn(['meta', 'messageImage'], null);

    case CLEAR_MODAL_DATA:
      return state.set('meta', state.get('meta').merge({
        modalShowing: false,
        modalData: Immutable.Map()
      }));

    case STORE_SCROLL_HEIGHT:
      return state.set('scrollHeight', action.height);

    case STORE_SCROLL_HEIGHT_CONTACTS:
      return state.set('scrollHeightContacts', action.height);

    case DELETE_CONVERSATION:
      return state;
      // let deletingActive = false;
      // let newState = state.set('storedConversations', state.get('storedConversations').reduce((conversations, conversation) => {
      //   if (conversation.get('id') !== action.id) {
      //     return conversations.push(conversation);
      //   }
      //
      //   if (conversation.get('active') === true) {
      //     deletingActive = true;
      //   }
      //
      //   return conversations;
      // }, Immutable.List()));
      //
      // newState = newState.set('conversations', filterConversations(newState.get('storedConversations'), newState.getIn(['meta', 'filterTerm'])));
      //
      // if (deletingActive && newState.get('conversations').count() > 0) {
      //   const conversationId = newState.getIn(['conversations', 0, 'id']);
      //   newState = newState.set('storedConversations', newState.get('storedConversations').map(conversation =>
      //     conversation.get('id') === conversationId ? conversation.merge({
      //       active: true,
      //       isRead: true
      //     }) : conversation));
      // } else if (deletingActive && newState.get('storedConversations').count() > 0) {
      //   newState = newState.setIn(['storedConversations', 0], newState.getIn(['storedConversations', 0]).merge({
      //     active: true,
      //     isRead: true
      //   }));
      // }
      //
      // return newState.set('conversations', filterConversations(newState.get('storedConversations'), newState.getIn(['meta', 'filterTerm'])));;

    case DELETE_CONVERSATION_CONFIRMATION:
      return state.setIn(['meta', 'modalShowing'], true)
        .setIn(['meta', 'modalData'], Immutable.Map({
          id: action.id,
          type: 'Delete',
          conversation: state.get('storedConversations').find(conversation => conversation.get('id') === action.id)
        }));

    case DIRECT_CHAT_TICK:
      return state.set('now', new Date());

    case UPDATE_UNREAD_CONVERSATIONS_COUNT:
      return state.set('unreadConversations', action.count);

    case FETCH_CHAT_CONNECTIONS:
      return state.set('connections', Immutable.fromJS(action.data)).set('storedConnections', Immutable.fromJS(action.data)).set('connectionsInitialLoaded', true);

    case FETCH_DIRECT_CHAT_CONVERSATIONS:

      let currentConversations = state.get('storedConversations');
      let newConversation = null;
      currentConversations.map((cConv) => {
        if(cConv.get('newConv') === true)
        {
          newConversation = cConv;
        }
        return null;
      });  

      let fetchedConversations = action.data;
      let activeConversation = null;
      currentConversations.map((conv) => {
        if(conv.get('active') === true)
        {
          activeConversation = conv.toJS();
          fetchedConversations.map((fetchedConversation) => {
            if(fetchedConversation.hero.id === activeConversation.hero.id)
            {
              activeConversation.id = fetchedConversation.id;
            }
            return null;
          });
        }
        return null;
      });

      fetchedConversations.map((conversation, index) => {
        
        if(activeConversation !== null && activeConversation.hero.id === conversation.hero.id)
        {
          fetchedConversations[index] = activeConversation;
          fetchedConversations[index].isRead = true;
        }
        if(onlineHeroIds !== undefined && onlineHeroIds.size > 0)
        { 
          onlineHeroIds.map((id) => {
              if(conversation.hero.id === id)
              {
                fetchedConversations[index].isOnline = true;
              }
              return null;
          });
        }
        currentConversations.map((currConv, index) => {
          if(conversation.hero.id === currConv.getIn(['hero', 'id']))
          {
            if(currConv.isOnline !== undefined)
            {
              fetchedConversations[index].isOnline = true;
            }
          }
          return null; 
        });
        return null;
      });
      
      fetchedConversations.map((conversation, index) => {
        if(conversation.lastMessage !== null && conversation.lastMessage.read === true) 
        {
          fetchedConversations[index].isRead = conversation.lastMessage.read;
        }
        return null;
      });

      if(newConversation !== null)
      {

        let existsInFetched = false;
        fetchedConversations.map((fc) => {
          if(fc.hero.id === newConversation.getIn(['hero', 'id']))
          {
            existsInFetched = true; 
          }
          return null;
        });

        if(existsInFetched === false)
        {
          fetchedConversations.splice(0, 0, newConversation);
        }
      }
      
      return state.merge({
        conversations: Immutable.fromJS(fetchedConversations),
        storedConversations: Immutable.fromJS(fetchedConversations)
      }).set('conversationsInitialLoaded', true);

    case SET_HERO_ONLINE_STATUS:

    let processedConversations       = state.get('conversations').toJS();
    let processedStoredConversations = state.get('storedConversations').toJS();

    processedConversations.map((conv, index) => {

      if(action.heroId === conv.hero.id)
      {
        processedConversations[index].isOnline = action.online;
      }
      return null;
    });

    processedStoredConversations.map((conv, index) => {

      if(action.heroId === conv.hero.id)
      {
        processedStoredConversations[index].isOnline = action.online;
      }
      return null;
    });

    return state.set('conversations', Immutable.fromJS(processedConversations)).set('storedConversations', Immutable.fromJS(processedStoredConversations));

    case FETCH_CONVERSATION_MESSAGES:
      let conversations = state.get('conversations').toJS();
      conversations.map((conversation, index) => {
        if (conversation.id === action.conversationId && _.isArray(action.data)) {
          let savedMessages = conversations[index].thread !== undefined ? conversations[index].thread : [];
          delete conversations[index].newConv;
          conversations[index].thread = action.data;
          conversations[index].thread = conversations[index].thread.concat(savedMessages);
        }
        return null;
      });

      if(action.startEpoch !== undefined)
      {
        return state.set('conversations', Immutable.fromJS(conversations)).set('lastFetchedEpoch', action.startEpoch);
      }

      return state.set('conversations', Immutable.fromJS(conversations));

    case GET_DIRECT_CHAT_CONNECTIONS: {
      if (!action.term) {
        return state.set('connections', state.get('storedConnections')).setIn(['meta', 'contactFilterTerm'], action.term || '');
      }

      return state.set('connections', state.get('storedConnections').reduce((connections, connection) => {
        if (
          connection.get('firstName').toLowerCase().indexOf(action.term) !== -1 ||
          connection.get('lastName').toLowerCase().indexOf(action.term) !== -1
        ) {
          return connections.push(connection);
        }

        return connections;
      }, Immutable.List())).setIn(['meta', 'contactFilterTerm'], action.term || '');
    }

    case GET_DIRECT_CHAT_CONVERSATIONS: {

      let conversations = [];
      let storedConversations = state.get('storedConversations');
      let nonActiveConversations = [];
      storedConversations.map((conversation) => {
        nonActiveConversations.push(conversation.set('active', false));
        return null;
      });
      state.set('storedConversations', nonActiveConversations);
      storedConversations.map((conversation) => {

        let matchesTerm = false;

        if (!_.isString(conversation.getIn(['lastMessage', 'text']))) {
          return null;
        }

        if (
          conversation.getIn(['lastMessage', 'text']).toLowerCase().indexOf(action.term) !== -1 ||
          conversation.getIn(['hero', 'firstName']).toLowerCase().indexOf(action.term) !== -1 ||
          conversation.getIn(['hero', 'lastName']).toLowerCase().indexOf(action.term) !== -1
        ) {
          matchesTerm = true;
        } else if (conversation.has('thread')) {
          conversation.get('thread').forEach(message => {
            //if (message.get('message').toLowerCase().indexOf(action.term) !== -1) {
            //  matchesTerm = true;
            //}
          })
        }

        if (matchesTerm) {
          conversations.push(conversation.set('active', false));
        }

        return null;
      });

      return state.set('conversations', Immutable.fromJS(conversations))
        .setIn(['meta', 'filterTerm'], action.term || '').set('now', new Date());
    }

    case MARK_CONVERSATION_AS_READ: {
      const newState = state.set('storedConversations', state.get('storedConversations').reduce((conversations, conversation) => {
        if (conversation.get('id') === action.id) {
          return conversations.push(conversation.set('isRead', true));
        }

        return conversations.push(conversation);
      }, Immutable.List()));

      return newState.set('conversations', filterConversations(newState.get('storedConversations'), newState.getIn(['meta', 'filterTerm'])));
    }

    case MUTE_CONVERSATION: {
      const newState = state.set('storedConversations', state.get('storedConversations').reduce((conversations, conversation) => {
        if (conversation.get('id') === action.id) {
          return conversations.push(conversation.set('muted', action.muted));
        }

        return conversations.push(conversation);
      }, Immutable.List()));

      return newState
        .set('conversations', filterConversations(newState.get('storedConversations'), newState.getIn(['meta', 'filterTerm'])));
    }

    case MUTE_CONVERSATION_CONFIRMATION:
      return state.setIn(['meta', 'modalShowing'], true)
        .setIn(['meta', 'modalData'], Immutable.Map({
          id: action.id,
          type: 'Mute',
          conversation: state.get('storedConversations').find(conversation => conversation.get('id') === action.id)
        }));

    case OPEN_CONVERSATION_AND_FETCH:
      {
        let conversationId = state.get('storedConversations').reduce((id, conversation) => {
          if (conversation.getIn(['hero', 'id']) === action.posterId) {
            return conversation.get('id');
          }

          return id;
        }, '');

        let newState = state.set('storedConversations', state.get('storedConversations').reduce((conversations, conversation) => {
          if (conversation.get('id') === conversationId) {
            return conversations.push(conversation.merge({
              active: true,
              isRead: true
            }));
          }

          return conversations.push(conversation.set('active', false).set('newConv', true ));
        }, Immutable.List()));

        let conversations = newState.get('storedConversations').toJS();

        conversations.map((conversation, index) => {
          if (conversation.id === action.conversationId) {
            conversations[index].thread = action.data;
          }
          return null;
        });

        if(action.startEpoch !== undefined)
        {
          newState = newState.set('storedConversations', Immutable.fromJS(conversations)).set('lastFetchedEpoch', action.startEpoch);
        }
        else
        {
          newState = newState.set('storedConversations', Immutable.fromJS(conversations)).set('lastFetchedEpoch', 0);
        }

        return newState
          .set('meta', newState.get('meta').merge({
            contactFilterTerm: '',
            filterTerm: '',
            showConversations: true
          }))
          .set('conversations', filterConversations(newState.get('storedConversations'), newState.getIn(['meta', 'filterTerm'])))
          .set('connections', newState.get('storedConnections')).set('now', new Date());
      }


    case OPEN_CONVERSATION: {
      let conversationId = state.get('storedConversations').reduce((id, conversation) => {
        //lastMessage check is added for empty conversation which should be treated as new convesation (fix for #6295)
        if (conversation.getIn(['hero', 'id']) === action.posterId && conversation.get('lastMessage')) {
          return conversation.get('id');
        }

        return id;
      }, '');


      let newState = state;
      let newConversation = true;

      newState = newState.set('storedConversations', newState.get('storedConversations').reduce((conversations, conversation) => {
        if (conversation.get('id') === conversationId) {
          newConversation = false;
          return conversations.push(conversation.merge({
            active: true,
            isRead: true,
            newConv: false,
          }));
        }

        return conversations.push(conversation.set('active', false).set('newConv', false ));
      }, Immutable.List()));

      if(newConversation === true)
      {        
        let storedConversations = newState.get('storedConversations').toJS();

        const hero = {};
        if (_.isFunction(_.get(action.poster, 'get', null))) {
          hero.id = action.poster.get('id');
          hero.firstName = action.poster.get('firstName');
          hero.lastName = action.poster.get('lastName');
          hero.profilePictureUrl = action.poster.get('profilePictureUrl');
          hero.connectionsCount = action.poster.get('connectionsCount');
          hero.heroInitials = action.poster.get('heroInitials');
        } else {
          hero.id = _.get(action.poster, 'id', '');
          hero.firstName = _.get(action.poster, 'firstName', '');
          hero.lastName = _.get(action.poster, 'lastName', '');
          hero.profilePictureUrl = _.get(action.poster, 'profilePictureUrl', '');
          hero.connectionsCount = _.get(action.poster, 'connectionsCount', '');
          hero.heroInitials = _.get(action.poster, 'heroInitials', '');
        }

        storedConversations.unshift({
          id: uuid(),
          newConv: true,
          active: true,
          isMuted: false,
          isOnline: true,
          isRead: true,
          lastMessage: null,
          thread: [],
          hero,
        });
        newState = newState.set('storedConversations', Immutable.fromJS(storedConversations));
      }

      return newState
        .set('meta', newState.get('meta').merge({
          contactFilterTerm: '',
          filterTerm: '',
          showConversations: true
        }))
        .set('conversations', filterConversations(newState.get('storedConversations'), newState.getIn(['meta', 'filterTerm'])))
        .set('connections', newState.get('storedConnections')).set('now', new Date());
    }

    case REMOVE_IMAGE:
      return state.setIn(['meta', 'messageImage'], null);

    case REPORT_CONVERSATION:
      return state;

    case REPORT_CONVERSATION_CONFIRMATION:
      return state.setIn(['meta', 'modalShowing'], true)
        .setIn(['meta', 'modalData'], Immutable.Map({
          id: action.id,
          type: 'Report',
          conversation: state.get('storedConversations').find(conversation => conversation.get('id') === action.id)
        }));

    case TOGGLE_CONVERSATION_OPTIONS:
      return state.setIn(['meta', 'optionsOpened'], action.showing);

    case CLOSE_ACTIVE_CONVERSATION:
        let currConvs = state.get('storedConversations').toJS();
        currConvs.map((currConv, index) => {
          if(currConv.active === true)
          { 
            currConvs[index].active = false;
          }
          return null;
        });
        
      return state.set('conversations', Immutable.fromJS(currConvs))
      .set('storedConversations', Immutable.fromJS(currConvs));

    case TOGGLE_CONVERSATIONS:
      return state.set('meta', state.get('meta').merge({
        contactFilterTerm: '',
        filterTerm: '',
        showConversations: !state.getIn(['meta', 'showConversations'])
      }))
        .set('conversations', state.get('storedConversations'))
        .set('connections', state.get('storedConnections'));

    default:
      return state;
  }
};

export default directChatState;
