import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Lightbox from 'react-images';

class OfficeGalleryLightbox extends Component {
  constructor(){
    super();

    this.state = {
      galleryOpened: false,
      galleryCurrentImage: 0
    };

    this.goToPreviousGalleryImage = this.goToPreviousGalleryImage.bind(this);
    this.goToNextGalleryImage     = this.goToNextGalleryImage.bind(this);
    this.onClickThumbnail         = this.onClickThumbnail.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if(nextProps.currentImage !== this.state.galleryCurrentImage)
    {
      this.setState({galleryCurrentImage: nextProps.currentImage});
    }
  }

  goToPreviousGalleryImage() {
    this.props.updateCurrentImage(this.state.galleryCurrentImage - 1); 
    this.setState({
      galleryCurrentImage: this.state.galleryCurrentImage - 1
    });
  }

  goToNextGalleryImage() {
    this.props.updateCurrentImage(this.state.galleryCurrentImage + 1);
    this.setState({
      galleryCurrentImage: this.state.galleryCurrentImage + 1
    });
  }

  onClickThumbnail(e) {
    this.setState({
      galleryCurrentImage: e
    });
  }

  render() {

    if(this.props.images[this.state.galleryCurrentImage] === undefined) return null;

    return (
       <Lightbox
         images={this.props.images}
         isOpen={this.props.isOpen}
         onClickPrev={this.goToPreviousGalleryImage}
         onClickNext={this.goToNextGalleryImage}
         onClose={this.props.onCloseGallery}
         showThumbnails={true}
         onClickThumbnail={this.onClickThumbnail}
         currentImage={this.state.galleryCurrentImage}
         width={1280}
       />
    );
  }
}


OfficeGalleryLightbox.propTypes = {
  images: PropTypes.array.isRequired,
};

export default OfficeGalleryLightbox;
