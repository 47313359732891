import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import MyBookingsPageMenu from './MyBookingsPageMenu';

import './css/index.scss';
import _ from 'lodash';
import Masonry from '../../utils/Masonry';
import CreateBookingTile from '../myMeetings/tile/CreateBookingTile';
import { getDistance as geolibGetDistance } from 'geolib';
import Tile from './tile';

const masonryBreakPoints = [870, 1300, 1700, 1900];

class MyBookingsPage extends React.Component {
  componentDidMount() {
    this.props.fetchMyBookings();
  }

  getDistance(location) {
    const { translations } = this.props;
    if (this.props.isGeolocationEnabled && this.props.coords !== null) {
      const dist = geolibGetDistance(
        { latitude: _.get(this.props, 'coords.latitude', 0), longitude: _.get(this.props, 'coords.longitude', 0) },
        { latitude: _.get(location, 'latitude', 0), longitude: _.get(location, 'longitude', 0) }
      );

      const distance = dist / 1000;

      return (
        <>
          {distance > 10
            ? distance.toFixed(0)
            : distance
                .toFixed(2)
                .toString()
                .replace('.', window.__env.decimalSeparator)}{' '}
          {_.get(translations, 'myBookings.kmAway', 'myBookings.kmAway')}
        </>
      );
    }
    return null;
  }

  buildTile(office) {
    const distanceLabel = this.getDistance(office.location);
    return <Tile office={office} distanceLabel={distanceLabel} numberOfBookings={3} />;
  }

  render() {
    const { translations, myBookings, isLoading, hero } = this.props;
    let bookingsTiles = [];
    if (myBookings.toJS()) {
      bookingsTiles = Object.values(myBookings.toJS());
      bookingsTiles.push({ newTile: true });
    }
    if (translations === null || isLoading) return <div className="Loader" />;
    return (
      <div style={{ height: '100%' }} className="my-meetings">
        <Scrollbars className="styleScroll">
          <a
            onClick={e => {
              e.preventDefault();
              this.props.history.push('/booking');
            }}
            className="myBookings-navigation"
          >
            {_.get(translations, 'connection.back', '')}
          </a>
          <div className="meetings-container">
            <div className="my-meetings-title">{_.get(translations, 'myBookings.title', '')}</div>
            <MyBookingsPageMenu translations={translations} hero={hero.toJS()} />
            <div className="masonry-container">
              {!_.isEmpty(myBookings.toJS()) ? (
                <Masonry brakePoints={masonryBreakPoints} break>
                  {bookingsTiles.map(office => this.buildTile(office))}
                </Masonry>
              ) : (
                <CreateBookingTile
                  title={_.get(translations, 'myBookings.startNewBooking', '')}
                  subtitle={_.get(translations, 'myBookings.chooseYourLocation', '')}
                  buttonText={_.get(translations, 'myBookings.startNewBooking', 'START  new booking')}
                />
              )}
            </div>
          </div>
        </Scrollbars>
      </div>
    );
  }
}

export default MyBookingsPage;
