import React from 'react';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import CommunityCard from '../../common/community-card/CommunityCard';
/**
 * Hero Communities Component
 */
class HeroCommunities extends React.Component {
  constructor(props) {
    super(props);
    this.buildCommunityCard = this.buildCommunityCard.bind(this);
  }

  componentDidMount() {
    const { fetchHeroCommunities } = this.props;
    fetchHeroCommunities();
  }

  handleClick(link) {
    const { history } = this.props;
    history.push(`/community/${link}`);
  }

  buildCommunityCard(item) {
    const { translations } = this.props;
    return (
      <CommunityCard
        img={item.image}
        title={item.name}
        members={item.members}
        buttonText={_.get(translations, 'view', '')}
        action={this.handleClick.bind(this, item.urlSlug)}
        type={item.audienceType}
        verticals={item.verticals}
        key={item.id}
      />
    );
  }

  render() {
    const { translations, heroCommunities } = this.props;
    const myCommunities = _.concat(
      _.orderBy(_.filter(heroCommunities.isAdmin, ['audienceType', 'closed']), 'members', 'desc'),
      _.orderBy(_.filter(heroCommunities.isMember, ['audienceType', 'closed']), 'members', 'desc'),
      _.orderBy(_.reject(heroCommunities.isAdmin, ['audienceType', 'closed']), 'members', 'desc'),
      _.orderBy(_.reject(heroCommunities.isMember, ['audienceType', 'closed']), 'members', 'desc')
    );
    const communitiesYouAreIn = _.get(translations, 'communitiesYouAreIn', 'communitiesYouAreIn');

    if (!myCommunities || myCommunities.length === 0) {
      const noCommunities = _.get(translations, 'noCommunitiesYet', 'noCommunitiesYet');
      return (
        <div>
          <h4 className="card__title">{communitiesYouAreIn}</h4>
          <div className="no-communities-label">{noCommunities}</div>
        </div>
      );
    }

    return (
      <div>
        <h4 className="card__title">{translations.communitiesYouAreIn}</h4>
        {myCommunities.map(this.buildCommunityCard)}
      </div>
    );
  }
}
export default withRouter(HeroCommunities);
