import React from 'react';
import { withRouter } from 'react-router-dom';
import greyPin from '../../../../../../assets/svg/meeting/grey-pin.svg';

const CreateBookingTile = props => {
  return (
    <div className="start-new-meeting-tile">
      <img className="start-new-meeting-img" src={greyPin} alt="Grey Pin" />
      <div className="start-new-meeting-title">{props.title}</div>
      <div className="start-new-meeting-subtitle">{props.subtitle}</div>
      <button
        className="start-new-meeting-btn"
        type="button"
        onClick={e => {
          e.preventDefault();
          props.history.push('/booking');
        }}
      >
        {props.buttonText}
      </button>
    </div>
  );
};
export default withRouter(CreateBookingTile);
