import React from 'react';
import classnames from 'classnames';
import Checkbox from './checkbox';
import ReactTooltip from 'react-tooltip';
import _ from 'lodash';

const prepareDataForRender = (props) => {
    let nonBookable = [];
    let bookable = [];
    props.officeBookingOptions.amenities.forEach(function(entity){
        entity.unit = (!entity.price || entity.price === 0) ? '' : entity.priceType + ': ';
        entity.mention = (!entity.price || entity.price === 0) ? props.trans.booking.options.complementary : entity.price.toFixed(2).toString().replace(".", window.__env.decimalSeparator) + '€';
        entity.areComplementary = (!entity.price || entity.price === 0) ? true : false;
        entity.id += "amenity";
        nonBookable.push(entity);
    });
    props.officeBookingOptions.services.forEach(function(entity){
        entity.unit = (!entity.price || entity.price === 0) ? '' : entity.priceType + ': ';
        entity.mention = (!entity.price || entity.price === 0) ? props.trans.booking.options.complementary : entity.price.toFixed(2).toString().replace(".", window.__env.decimalSeparator) + '€';
        entity.areComplementary = (!entity.price || entity.price === 0) ? true : false;
        if(entity.bookable && entity.price > 0){
            entity.id += "service";
            bookable.push(entity);
        } else {
            entity.id += "service";
            nonBookable.push(entity);
        }
    });

    props.officeBookingOptions.equipments.forEach(function(entity){
        entity.unit = (!entity.price || entity.price === 0) ? '' : entity.priceType + ': ';
        entity.mention = (!entity.price || entity.price === 0) ? props.trans.booking.options.complementary : entity.price.toFixed(2).toString().replace(".", window.__env.decimalSeparator) + '€';
        entity.areComplementary = (!entity.price || entity.price === 0) ? true : false;
        if(entity.bookable && entity.price > 0){
            entity.id += "equipment";
            bookable.push(entity);
        } else {
            entity.id += "equipment";
            nonBookable.push(entity);
        }
    });

    return {
        nonBookable: nonBookable,
        bookable: bookable,
    };
}

class AddBookingAmenitiesAndServices extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            nonBookable: [],
            bookable: [],
        }
    }

    static getDerivedStateFromProps(props, state) {
        return prepareDataForRender(props);
    }

    componentDidMount() {
        this.setState(prepareDataForRender(this.props));
    }

    toggleServices = (serviceId) => {
        const {selectedServices} = this.props;
        selectedServices.includes(serviceId) ? selectedServices.splice(selectedServices.indexOf(serviceId),1) : selectedServices.push(serviceId);
        this.props.serviceToggle(selectedServices);
    };

    getInfoPrice = (entity) => {
        if(!entity.areComplementary) {
            return (<div className="info-tooltip"><span>
                <a data-tip data-for={entity.name}> <span className={'info-button'} ></span> </a>
             <ReactTooltip id={entity.name} type="dark" effect='solid'>
                 <span className="tooltip-content">
                     <div key={"spriceX" + entity.id} className="tooltip-item">
                          <span className={"price-info-title"}>{_.capitalize(entity.unit)}</span> <span className="price-info-value">{entity.mention}</span>
                      </div>
                 </span>
             </ReactTooltip>
        </span>
            </div>);
        }
    }

    render() {
        return (
            <div className="amenities-services">
              {(this.state.nonBookable.length > 0 || this.state.bookable.length > 0) ?
              <div className="booking-options-title">{this.props.trans.booking.options.amenitiesAndServices}</div> : ''}
              <div className="booking-options-list">
                {this.state.nonBookable.map(entity => (
                  <div key={entity.id}>
                    <div key={entity.id}
                         className={classnames('free', 'booking-options-amenity')}>
                      <div className="mention entity">
                        {entity.name}
                        {this.getInfoPrice(entity)}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="booking-options-list">
                {this.state.bookable.map(entity => (
                  <div key={entity.id}>
                    <div key={entity.id}
                         className={classnames({'free': entity.available}, 'booking-options-service')}>
                      <div className="mention entity">
                        {entity.name}
                        {this.getInfoPrice(entity)}
                      </div>
                      <Checkbox 
                        className="align-left"
                        checked={this.props.selectedServices.includes(entity.id)}
                        onChange={() => this.toggleServices(entity.id)}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
        );
    }
}

export default AddBookingAmenitiesAndServices;
