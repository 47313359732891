/* eslint-disable no-underscore-dangle */
import axios from 'axios';

let baseApiUrl = '';

if (window.__env && window.__env.apiUrl) {
  baseApiUrl = window.__env.apiUrl;
}
const baseURL = `${baseApiUrl}/`;

export default axios.create({
  responseType: 'json',
  baseURL,
  headers: {
    'Content-Type': 'application/json',
    'access-key': localStorage.getItem('access-key'),
  },
});
