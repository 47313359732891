import Truncate from 'react-truncate';
import React, { Component } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import classnames from 'classnames';
import ContextMenu from '../../../common/context-menu';
import contactMemberYellow from '../../../../../assets/svg/community/contact-member-icon-yellow.svg';
import contactMemberHover from '../../../../../assets/svg/community/contact-member-icon--hover.svg';

class MemberCard extends Component {
  constructor(props) {
    super(props);
    this.goToDirectChat.bind(this);
    this.activeMembersActions.bind(this);
    this.blockedMembersActions.bind(this);
    this.invitedMembersActions.bind(this);
  }

  goToDirectChat(member) {
    const { history, openConversation } = this.props;
    const { id } = member;
    openConversation(id, member);
    history.push('/direct-chat');
  }

  activeMembersActions(member) {
    const { authId, translations, makeHeroAdmin, removeHeroFromCommunity, blockHeroFromCommunity, isLast, displayActions } = this.props;
    const { id, admin } = member;

    const items = [
      {
        text: _.get(translations, 'manageCommunityModal.makeAdmin', 'manageCommunityModal.makeAdmin'),
        action: () => makeHeroAdmin(id),
      },
      {
        text: _.get(translations, 'manageCommunityModal.removeFromCommunity', 'manageCommunityModal.removeFromCommunity'),
        action: () => removeHeroFromCommunity(id),
      },
      {
        text: _.get(translations, 'manageCommunityModal.blockFromCommunity', 'manageCommunityModal.blockFromCommunity'),
        action: () => blockHeroFromCommunity(id),
      },
    ];

    return (
      <div className={classnames('manage-community__actions', { 'manage-community__actions--hidden': !displayActions })}>
        {!admin ? (
          <div className={classnames('manage-community__context-menu', { 'manage-community__context-menu--top': isLast })}>
            <ContextMenu items={items} />
          </div>
        ) : null}
        {!authId || authId !== id ? (
          <div className="manage-community__contact-member">
            <button
              type="button"
              className="manage-community__message-button"
              onClick={event => {
                event.stopPropagation();
                this.goToDirectChat(member);
              }}
            >
              <img className="manage-community__message-icon" src={contactMemberYellow} alt="" />
              <img className="manage-community__message-icon--hover" src={contactMemberHover} alt="" />
            </button>
          </div>
        ) : null}
      </div>
    );
  }

  blockedMembersActions(member) {
    const { translations, unblockMember } = this.props;
    const heroId = _.get(member, 'id', null);

    return (
      <div className="manage-community__actions">
        <div className="manage-community__unblock-member">
          <button
            type="button"
            className="entity-button entity-button--border manage-community__unblock-button"
            onClick={() => unblockMember(heroId)}
          >
            {_.get(translations, 'manageCommunityModal.unblock', 'manageCommunityModal.unblock')}
          </button>
        </div>
      </div>
    );
  }

  invitedMembersActions(member) {
    const { translations, resendInvitation, cancelInvitation } = this.props;
    const invitationId = _.get(member, 'invitationId', null);
    const isSent = _.get(member, 'sent', false);
    const isSending = _.get(member, 'sending', false);
    return (
      <div className="manage-community__actions">
        <div className="manage-community__cancel-invitation">
          <button type="button" className="manage-community__cancel-invitation-button" onClick={() => cancelInvitation(invitationId)}>
            {_.get(translations, 'form.cancelButton', 'form.cancelButton')}
          </button>
        </div>
        <div className="manage-community__reinvite-member">
          {!isSent ? (
            <button
              type="button"
              className="entity-button entity-button--border manage-community__reinvite-button"
              onClick={() => resendInvitation(invitationId)}
              disabled={isSending === 1}
            >
              {_.get(translations, 'manageCommunityModal.resendInvite', 'manageCommunityModal.resendInvite')}
            </button>
          ) : (
            <button type="button" className="manage-community__just-sent-button">
              {_.get(translations, 'manageCommunityModal.justSent', 'manageCommunityModal.justSent')}
            </button>
          )}
        </div>
      </div>
    );
  }

  render() {
    const { member, memberType, history, authId } = this.props;
    const { id, profilePictureUrl, name, headline } = member;
    const isGuest = !id;
    const heroInitials = _.get(member, 'heroInitials', 'N/A');
    const { admin } = member;

    return (
      <div
        className={classnames(
          'manage-community__hero-info',
          { 'manage-community__hero-info--guest': isGuest },
          { 'manage-community__hero-info--member': !admin }
        )}
      >
        <div
          className="manage-community__profile-picture-container"
          onClick={() => {
            if (!isGuest) {
              history.push(authId === id ? '/account/profile' : `/connection/${id}`);
            }
          }}
        >
          {profilePictureUrl ? (
            <img className="manage-community__profile-picture" src={profilePictureUrl} alt={name} />
          ) : heroInitials !== 'N/A' ? (
            <div className="hero-initials community-members-modal-hero-initials">{heroInitials}</div>
          ) : (
            <div className="community-members-modal__default-picture" />
          )}
        </div>
        <div
          className="manage-community__details"
          onClick={() => {
            if (!isGuest) {
              history.push(authId === id ? '/account/profile' : `/connection/${id}`);
            }
          }}
        >
          <div className="manage-community__name">{name}</div>
          <div className="manage-community__headline" title={headline}>
            <Truncate lines={2} ellipsis={<>...</>}>
              {headline}
            </Truncate>
          </div>
        </div>
        {memberType === 'active' ? this.activeMembersActions(member) : null}
        {memberType === 'blocked' ? this.blockedMembersActions(member) : null}
        {memberType === 'invited' ? this.invitedMembersActions(member) : null}
      </div>
    );
  }
}

MemberCard.propTypes = {
  translations: PropTypes.shape({}).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  member: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    headline: PropTypes.string,
    profilePictureUrl: PropTypes.string,
  }).isRequired,
  memberType: PropTypes.string.isRequired,
  authId: PropTypes.number,
  makeHeroAdmin: PropTypes.func,
  removeHeroFromCommunity: PropTypes.func,
  blockHeroFromCommunity: PropTypes.func,
  unblockMember: PropTypes.func,
  resendInvitation: PropTypes.func,
  cancelInvitation: PropTypes.func,
};

MemberCard.defaultProps = {
  authId: null,
  makeHeroAdmin: () => {},
  removeHeroFromCommunity: () => {},
  blockHeroFromCommunity: () => {},
  unblockMember: () => {},
  resendInvitation: () => {},
  cancelInvitation: () => {},
};

export default withRouter(MemberCard);
