import Immutable from 'immutable';
import moment from 'moment-timezone';
import {
  SET_SELECTED_PRODUCT_ID,
  SET_SELECTED_DATE,
  SET_SELECTED_PRODUCT_TYPE,
  SET_SELECTED_PIN,
  SET_WHITELABEL_CANCEL_BOOKING_DATA,
  SET_CANCEL_LOADING,
  SET_PARKING_VALIDATION,
  SET_COMPLETE_BOOKING_LOADING,
} from '../../actions/whiteLabel/booking';
import { FETCH_OFFICE_DETAILS } from '../../actions/locationActions';
import { SET_GO_TO_PARKING_FLOOR } from '../../actions/whiteLabel/my-parkings';

const initialState = Immutable.Map({
  selectedProductId: null,
  selectedProductType: null,
  selectedDate: {
    from: null,
    to: null,
  },
  selectedPin: null,
  cancelBookingData: null,
  cancelLoader: false,
  goToParkingFloor: false,
  noParkingsAvailable: false,
  completeBookingLoader: false,
});

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTED_PRODUCT_ID:
      const newState = state.setIn(['selectedPin'], null);
      return newState.setIn(['selectedProductId'], action.productId);
    case SET_SELECTED_PRODUCT_TYPE:
      return state.setIn(['selectedProductType'], action.productType);
    case SET_SELECTED_DATE:
      return state.setIn(['selectedDate'], {
        from: action.from,
        to: action.to,
      });
    case SET_SELECTED_PIN:
      return state.setIn(['selectedPin'], action.productId);
    case SET_WHITELABEL_CANCEL_BOOKING_DATA:
      return state.set('cancelBookingData', action.data);
    case SET_CANCEL_LOADING:
      return state.set('cancelLoader', action.isLoading);
    case SET_COMPLETE_BOOKING_LOADING:
      return state.set('completeBookingLoader', action.isLoading);
    case SET_GO_TO_PARKING_FLOOR:
      return state.set('goToParkingFloor', action.goToParkingFloor);
    case FETCH_OFFICE_DETAILS:
      // redirect from colleagues bookings with selected pin, no need to set date
      // redirect from book parking spot button
      if (state.get('selectedPin') || state.get('goToParkingFloor')) {
        return state;
      }
      return state.setIn(['selectedDate'], {
        from: moment.tz(action.data.timezone).format('MMM D, YYYY'),
        to: moment.tz(action.data.timezone).format('MMM D, YYYY'),
      });
    case SET_PARKING_VALIDATION:
      return state.set('noParkingsAvailable', action.validationFlag);
    default:
      return state;
  }
};
