import { connect } from 'react-redux';

import _ from 'lodash';
import toJS from '../../utils/to-js';
import { isLoadingSelector } from '../../../../selectors/community/comments';
import { fetchComments, deleteCommunityComment } from '../../../../actions/community/comments';
import { showCommunityPageToast } from '../../../../actions/community/community-toasts';
import {
  heroIsCommunityAdminSelector,
  communitySelector,
  heroIsCommunityMemberSelector,
} from '../../../../selectors/community/community-page';

import CommentsCardContainer from './CommentsCardContainer';
import { translations } from '../../../../selectors/translations';
import { report } from '../../../../actions/community/report';

const mapDispatchToProps = dispatch => ({
  fetchComments: data => dispatch(fetchComments(data)),
  deleteCommunityComment: (commentId, postId) => dispatch(deleteCommunityComment(commentId, postId)),
  showCommunityPageToast: data => dispatch(showCommunityPageToast(data)),
  report: data => dispatch(report(data)),
});

const mapStateToProps = state => ({
  translations: _.get(translations(state), 'community', {}),
  isLoading: isLoadingSelector(state),
  heroIsCommunityAdmin: heroIsCommunityAdminSelector(state),
  heroProfile: state.global.get('heroProfile'),
  community: communitySelector(state),
  heroIsCommunityMember: heroIsCommunityMemberSelector(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(toJS(CommentsCardContainer));
