import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import Truncate from 'react-truncate';
import _ from 'lodash';
import accordionSvg from '../../../../../../assets/svg/accordion/accordion-arrow-down.svg';
import greaterSign from '../../../../../../assets/svg/meeting/greater.svg';

class Accordion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
    this.togglePanel = this.togglePanel.bind(this);
    this.buildContent = this.buildContent.bind(this);
  }

  togglePanel(e) {
    this.setState({ open: !this.state.open });
  }

  buildContent() {
    const {
      bookingHours,
      bookingStatus,
      bookingDays,
      translations,
      alreadyStarted,
    } = this.props;

    return (
      <div className="accordion__content">
        <div className="accordion_meeting-hours with-margin-bottom">
          <span>{_.get(translations, 'myBookings.workingDays', 'myBookings.workingDays')}</span>
          {bookingHours}
        </div>
        <div className="accordion_meeting-hours with-margin-bottom">
          <span>{_.get(translations, 'meetings.chosenDays', 'meetings.chosenDays')}</span>
          <Truncate title={bookingDays} lines={1} ellipsis={<>...</>} width={150} className='working-days'>
            {bookingDays}
          </Truncate>
        </div>
      </div>);
  }

  render() {
    const { title, officeLink, officeName, bookingStatus } = this.props;
    const { open } = this.state;

    return (
      <div className="accordion__section">
        <div onClick={e => this.togglePanel(e)} className="accordion">
          <p className={classnames('accordion__title', { 'accordion__title--cancelled': bookingStatus === 'canceled' })}>{title}</p>
          <img src={accordionSvg} className={!open ? 'accordion__icon' : 'accordion__icon rotate'} alt="Accordion Arrow" />
        </div>
        <div className="accordion_office">
          <Link to={officeLink} className="accordion_office-name">
            {officeName} <img src={greaterSign} className="accordion_office-greater" alt="Greater" />
          </Link>
        </div>
        {open ? <>{this.buildContent()}</> : null}
      </div>
    );
  }
}

export default Accordion;
