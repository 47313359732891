import api from '../../components/dashboard/utils/api';
/*
 * Constants
 */
export const SET_BOOKING_RECOMMENDATION = 'SET_BOOKING_RECOMMENDATION';
export const SET_BOOKING_RECOMMENDATION_IS_LOADING = 'SET_BOOKING_RECOMMENDATION_IS_LOADING';

/*
 * Sync Actions
 */
export const setBookingRecommendation = bookingRecommendation => ({
  type: SET_BOOKING_RECOMMENDATION,
  bookingRecommendation,
});

export const setBookingRecommendationIsLoading = isLoading => ({
  type: SET_BOOKING_RECOMMENDATION_IS_LOADING,
  isLoading,
});

/*
 * Async Actions
 */
export const fetchBookingRecommendation = () => dispatch => {
  dispatch(setBookingRecommendationIsLoading(true));
  api
    .get('recommendation/booking')
    .then(({ data = {} }) => {
      if (data.data) {
        dispatch(setBookingRecommendation(data.data));
      }
    })
    .catch(() => {})
    .finally(() => {
      dispatch(setBookingRecommendationIsLoading(false));
    });
};
