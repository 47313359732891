import api from '../../components/openAuth/utils/api';

export const validateInvitation = data => dispatch => {
  return new Promise((resolve, reject) => {
    api
      .post(`alternative/validateInvitation`, data)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
};

export default validateInvitation;
