import React from 'react';
import PropTypes from 'prop-types';
import './../css/requestDelete.scss';

class RequestDelete extends React.Component {

  onRequest = () => {
    this.props.onAction();
  }

  onCancelRequest = () => {
    this.props.onCancelAction(false);
  }

  render () {
    return (
      <div className="requestDelete">
        <div onClick={ this.onCancelRequest } className="modal-close-button"></div>
        <div className="requestDelete-title items-style1">{this.props.trans.modals.requestDelete.title}</div>
        <div className="requestDelete-warning">{this.props.trans.modals.requestDelete.warning}</div>
        <div className="requestDelete-explanation" dangerouslySetInnerHTML={{__html: this.props.trans.modals.requestDelete.explanation}}></div>
        <div className="requestDelete-actions">
          <button
          style={{marginLeft: '0'}}
            className="requestDelete-blockButton secondary-button"
            onClick={ this.onRequest }>{this.props.trans.modals.requestDelete.requestButtonLabel}</button>
          <button
            style={{marginLeft: '0'}}
            className="requestDelete-cancelButton main-button"
            onClick={ this.onCancelRequest }>{this.props.trans.modals.requestDelete.changedMyMindButtonLabel}</button>
        </div>
      </div>
    );
  }

}

RequestDelete.propTypes = {
  onAction: PropTypes.func
};

export default RequestDelete;
