import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';

const DeleteCommunityModal = props => {
  const { translations, community, deleteCommunity, hideDeleteCommunityModal, isLoading, history } = props;
  const communityId = _.get(community, 'id', null);
  return (
    <div className="community-confirmation-modal__container community-form">
      <div className="community-confirmation-modal__confirmation-message">
        {_.get(translations, 'deleteCommunityModal.confirmationMessage', 'deleteCommunityModal.confirmationMessage')}
      </div>

      <div className="community-confirmation-modal__actions">
        <button
          type="button"
          onClick={() => hideDeleteCommunityModal()}
          id="community-confirmation-modal__cancel-button"
          className="community__button-cancel community-confirmation-modal__cancel-button"
        >
          {_.get(translations, 'deleteCommunityModal.cancelButton', 'deleteCommunityModal.cancelButton')}
        </button>

        {!isLoading && (
          <button
            id="community-confirmation-modal__save-button"
            type="button"
            className="button-yellow community-confirmation-modal__save-button"
            onClick={() =>
              deleteCommunity(communityId).then(() => {
                history.push({
                  pathname: '/communities',
                  state: { showCommunityDeletedToast: true },
                });
              })
            }
          >
            {_.get(translations, 'deleteCommunityModal.deleteButton', 'deleteCommunityModal.deleteButton')}
          </button>
        )}
        {isLoading && <span className="community__loader community-confirmation-modal__loader" />}
      </div>
    </div>
  );
};

DeleteCommunityModal.propTypes = {
  deleteCommunity: PropTypes.func.isRequired,
  hideDeleteCommunityModal: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  errors: PropTypes.shape({}),
  translations: PropTypes.shape({}),
  community: PropTypes.shape({}).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

DeleteCommunityModal.defaultProps = {
  errors: {},
  translations: {},
};

export default withRouter(DeleteCommunityModal);
