import React from 'react';
import _ from 'lodash';

const AuthButtons = props => {
  const { translations, history } = props;
  const loginLabel = _.get(translations, 'header.login', 'Log in');
  const signupLabel = _.get(translations, 'header.signup', 'Sign up');
  return (
    <div className="header__auth-container">
      <button type="button" className="dashboard-button header__auth-button" onClick={() => history.push('/login')}>
        {loginLabel}
      </button>
      <button
        type="button"
        className="dashboard-button dashboard-button--border header__auth-button"
        onClick={() => history.push('/sign-up')}
      >
        {signupLabel}
      </button>
    </div>
  );
};

export default AuthButtons;
