import _ from 'lodash';

const loginRedirects = (response, fromUrl, state, setIsWhiteLabel, history) => {
  const communityJoinUrl = localStorage.getItem('community-join-url');
  localStorage.removeItem('meeting-attendee-key');
  if (communityJoinUrl) {
    localStorage.removeItem('community-join-url');
    history.push(communityJoinUrl);
  } else {
    const isWhiteLabel = _.get(response, 'data.heroData.isWhiteLabel', null);
    const hasProfileCompleted = _.get(response, 'data.heroData.profileCompleted', null);
    if (isWhiteLabel && !hasProfileCompleted) {
      setIsWhiteLabel(true);
      history.push({
        pathname: '/profile',
        fromUrl,
        state,
      });
    } else {
      history.push({
        pathname: fromUrl,
        fromUrl,
        state,
      });
    }
  }
};

export default loginRedirects;
