import React, { Component } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import CONST from '../../../../utils/const';

class CommunitySearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayEmailSuggestion: null,
    };
    this.searchFieldRef = React.createRef();
    this.handleEmailAddress = this.handleEmailAddress.bind(this);
    this.renderEmailSuggestionBox = this.renderEmailSuggestionBox.bind(this);
  }

  componentDidUpdate() {
    const { searchedHeroes, heroProfile } = this.props;
    const { displayEmailSuggestion } = this.state;
    const isInternal = _.get(heroProfile, 'isInternal', 0);
    const email = this.searchFieldRef.current.value;

    if (_.isEmpty(displayEmailSuggestion) && !_.isEmpty(email) && !isInternal && _.isEmpty(searchedHeroes)) {
      this.setState({ displayEmailSuggestion: email });
    }

    if (!_.isEmpty(displayEmailSuggestion) && !_.isEmpty(searchedHeroes)) {
      this.setState({ displayEmailSuggestion: null });
    }
  }

  handleEmailAddress(ev) {
    const { searchCallback, searchedHeroes, heroProfile } = this.props;
    const { displayEmailSuggestion } = this.state;
    const isInternal = _.get(heroProfile, 'isInternal', 0);
    if (!isInternal && ev.target.value !== displayEmailSuggestion && _.isEmpty(searchedHeroes)) {
      this.setState({ displayEmailSuggestion: ev.target.value });
    } else {
      this.setState({ displayEmailSuggestion: null });
    }

    if (!_.isEmpty(displayEmailSuggestion) && ev.keyCode === CONST.keyCodes.ENTER) {
      this.selectGuestEmail(ev.target.value);
    }
    searchCallback(ev);
  }

  selectGuestEmail(guestEmail) {
    const { handleEmailSelection } = this.props;
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,8}$/i.test(guestEmail)) {
      return false;
    }
    this.setState({ displayEmailSuggestion: null });
    this.searchFieldRef.current.value = '';
    handleEmailSelection(guestEmail);
  }

  renderEmailSuggestionBox() {
    const { displayEmailSuggestion } = this.state;
    return (
      <div className="community-form__email-suggestion-box" onClick={() => this.selectGuestEmail(displayEmailSuggestion)}>
        {displayEmailSuggestion}
      </div>
    );
  }

  render() {
    const { translations, hasEmailSuggestionBox } = this.props;
    const { displayEmailSuggestion } = this.state;
    const suggestionBox = displayEmailSuggestion && hasEmailSuggestionBox ? this.renderEmailSuggestionBox() : null;
    return (
      <div className="community-form__search-wrapper">
        <input
          ref={this.searchFieldRef}
          className="community-form__search-input"
          type="text"
          placeholder={_.get(translations, 'searchInputPlaceholder', 'searchInputPlaceholder')}
          onKeyUp={this.handleEmailAddress}
        />
        {suggestionBox}
      </div>
    );
  }
}

CommunitySearch.propTypes = {
  translations: PropTypes.shape({}).isRequired,
  searchCallback: PropTypes.func.isRequired,
  handleEmailSelection: PropTypes.func.isRequired,
  heroProfile: PropTypes.shape({}).isRequired,
  hasEmailSuggestionBox: PropTypes.bool,
  searchedHeroes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      headline: PropTypes.string,
      profilePictureUrl: PropTypes.string,
    })
  ).isRequired,
};

CommunitySearch.defaultProps = {
  hasEmailSuggestionBox: false,
};
export default CommunitySearch;
