import { connect } from 'react-redux';

import toJS from '../../utils/to-js';
import PostCard from './PostCard';

import {
  communityPostIsLoadingSelector,
  communityPostSelector,
  communityPostsHasMoreSelector,
} from '../../../../selectors/community/community-posts';
import {
  communitySelector,
  heroIsCommunityMemberSelector,
  heroIsCommunityAdminSelector,
} from '../../../../selectors/community/community-page';
import {
  fetchCommunityPost,
  fetchMoreCommunityPost,
  likeCommunityPost,
  deleteCommunityPost,
  notifyPost,
} from '../../../../actions/community/community-posts';
import { report } from '../../../../actions/community/report';
import { showCommunityPageToast } from '../../../../actions/community/community-toasts';

const mapStateToProps = state => ({
  communityPosts: communityPostSelector(state),
  isLoading: communityPostIsLoadingSelector(state),
  heroProfile: state.global.get('heroProfile'),
  heroIsCommunityAdmin: heroIsCommunityAdminSelector(state),
  heroIsCommunityMember: heroIsCommunityMemberSelector(state),
  hasMorePosts: communityPostsHasMoreSelector(state),
  community: communitySelector(state),
});

const mapDispatchToProps = dispatch => ({
  likeCommunityPost: (heroId, postId) => dispatch(likeCommunityPost(heroId, postId)),
  fetchCommunityPost: () => dispatch(fetchCommunityPost()),
  fetchMoreCommunityPost: () => dispatch(fetchMoreCommunityPost()),
  deleteCommunityPost: postId => dispatch(deleteCommunityPost(postId)),
  report: data => dispatch(report(data)),
  showCommunityPageToast: message => dispatch(showCommunityPageToast(message)),
  notifyPost: (communityId, postId) => dispatch(notifyPost(communityId, postId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(toJS(PostCard));
