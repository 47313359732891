import { connect } from 'react-redux';
import toJS from '../../../utils/to-js';
import MemberCard from './MemberCard';
import { openConversation } from '../../../../../actions/directChat';

const mapDispatchToProps = dispatch => ({
  openConversation: (id, hero) => dispatch(openConversation(id, hero)),
});
const mapStateToProps = () => ({});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(toJS(MemberCard));
