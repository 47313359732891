import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import moment from 'moment';
import { Scrollbars } from 'react-custom-scrollbars';
import AddBookingAmenitiesAndServices from '../addBookingSidebar/addBookingAmenitiesAndServices';
import AddBookingCheckboxesGroup from './addBookingCheckboxesGroup/addBookingCheckboxesGroup';
import _ from 'lodash';
import {
    setSelectedRoom,
    setSelectedProduct,
    setSelectedOptions,
    setSelectedServices,
    setMeetingRoomTitle,
    setMeetingRoomComment
} from './../../actions/locationActions';
import DatePicker from '../datePicker/datePicker';
import 'react-datepicker/dist/react-datepicker.css';
import Dropdown from './dropdown';
import Immutable from 'immutable';
import CheckAvailability from './checkAvailability';
import SidePopup from './../sidePopup/sidePopup';
import {Link, withRouter} from 'react-router-dom';
import blockIncompleteProfileAction from "../../utils/blockIncompleteProfileAction";


const weekDaysNormalOrder = [1,2,3,4,5,6,7];
const weekDaysOrder = [1,2,3,4,5,6,7];
const ERRORS = {
    'fromDate':           'From Date',
    'toDate':             'To Date',
    'hours.from.hour':    'From Hour',
    'hours.from.minute':  'From Minute',
    'hours.to.hour':      'To Hour',
    'hours.to.minute':    'To Minute',
    'weekDays':           'Week Days',
    'officeTypeId':       'Office Type',
}

const DEFAULT_SELECTED_PRODUCT = {
    type: null,
    id: null
};

class AddBookingOptions extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ajaxLoading: false,
            working: false,
            checkAvailabilityClosed: true,
            heroPopupErrorPhone: '',
            heroPopupErrorWhen: '',
            heroPopupErrorHour: '',
            sidePopupPage: 'initial',
            sidePopupErrorText: null,
        };
        this.toggleCheckAvailability = this.toggleCheckAvailability.bind(this);
        this.openCheckAvailability = this.openCheckAvailability.bind(this);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {

        if(this.props.bookingRequestResponse === null && nextProps.bookingRequestResponse !== null)
        {
            if(this.state.sidePopupPage === 'initial')
            {
                this.setState({sidePopupPage: 'confirmation'});
            }
        }
    }

    componentDidUpdate(prevProps) {

        if(this.props.bookingOptions !== null)
        {
            let errors = [];
            this.props.bookingOptions.toJS().products.map((product) => {
                if(product.error !== "")
                {
                    errors.push(product.error);
                }
                return 0;
            });

            if(errors.length > 0 && errors.length === this.props.bookingOptions.toJS().products.length
                && prevProps.heroPopupErrorOpened === false
                && this.props.heroPopupErrorOpened === false
                && this.props.datePickerExpanded === false
                && this.props.selectedRoom.id === 2
            )
            {
                this.debounceShowSideError();
            }
        }

        let {selectedOptions} = this.props;

        if (
            prevProps.selectedProduct !== null &&
            prevProps.selectedProduct.id !== null &&
            this.props.selectedProduct !== null &&
            this.props.selectedProduct.id !== null &&
            prevProps.selectedProduct.id !== this.props.selectedProduct.id &&
            prevProps.selectedProduct.type === this.props.selectedProduct.type
        ){
            this._fetchBookinOptions();
        }

        if(this.props.bookingOptions !== null)
        {
            let preselect = false;
            if(this.props.selectedProduct.type === null || (this.props.selectedRoom !== null && this.props.selectedRoom.type !== this.props.selectedProduct.type))
            {
                preselect = true;
            }

            // check if the current selected product is among the current list
            if(this.props.selectedProduct.type !== null)
            {
                let foundProduct = false;
                this.props.bookingOptions.toJS().products.map((product) => {
                    if(product.id === this.props.selectedProduct.id)
                    {
                        foundProduct = true;
                    } return null;
                });
                if(foundProduct === false)
                {
                    preselect = true;
                }
            }

            if(preselect === true)
            {
                this.props.bookingOptions.toJS().products.map((product) => {
                    if(product.selected === true)
                    {
                        this.clickProduct(product.id);
                    } return null;
                });
            }
        }

        // week days
        if(this.props.bookingOptions !== null && this.props.selectedOptions.selectedWeekDays === undefined)
        {
            selectedOptions.selectedWeekDays = [];

            weekDaysOrder.map((weekDay) => {
                if(this.props.bookingOptions.toJS().weekDays[weekDay].available === true)
                    selectedOptions.selectedWeekDays.push(weekDay);
                return null;
            });

            this.props.setSelectedOptions(selectedOptions);
            this._fetchBookinOptions();
        }
    }

    clickProduct = (productId) => {
        let { selectedProduct, selectedRoom } = this.props;
        if(selectedRoom !== null)
        {
            selectedProduct.type  = selectedRoom.type;
            selectedProduct.id    = productId;
            this.props.setSelectedProduct(selectedProduct);
            this._fetchBookinOptions();
        }
    };

    ServiceToggle = (selectedServices) => {
        this.props.setSelectedServices(selectedServices);
        this._fetchBookinOptions();
    }

    renderRoomTypes = () => {
        let officeSpace = this.getOfficeSpace(),
            {selectedRoom} = this.props,
            selectedType = selectedRoom ? selectedRoom.type : '';

        return officeSpace.map(room => (
            <div
                key={room.id}
                className={classnames({'selected': room.type === selectedType}, `icon-${room.type}`)}
                onClick={() => this.changeSelectedType(room)}>{room.typeName}</div>
        ));
    }

    changeSelectedType = (room) => {

        this.props.setSelectedRoom(room);
        this.props.setSelectedOptions({});
        this.props.setSelectedProduct(DEFAULT_SELECTED_PRODUCT);
        this.props.setSelectedServices([]);

        this.setState({
            ajaxLoading: true,
        }, () => {
            this._fetchBookinOptions();
            this.props.redeemVoucher(null);
        });
    }

    _fetchBookinOptions() {
        this.props._fetchBookinOptions();
        this._fetchBookingValidate();
        this.debounceHideLoader();
        this.debounceWorking();
    }

    _fetchBookingValidate() {
        const { selectedOptions } = this.props;
        if (!selectedOptions.selectedWeekDays ||
            !selectedOptions.startDate ||
            !selectedOptions.hoursFrom ||
            !selectedOptions.hoursUntil
        ) {
            return;
        }
            if(this.props.selectedRoom !== null && this.props.selectedRoom.type === this.props.selectedProduct.type)
        {
            let services    = [];
            let equipments  = [];

            if(this.props.selectedServices.length > 0) {
                this.props.selectedServices.map((selectedService) => {
                    if(selectedService.includes('service'))
                    {
                        let [serviceId] = selectedService.split('service');
                        services.push(parseInt(serviceId, 10));
                    }

                    if(selectedService.includes('equipment'))
                    {
                        let [equipmentId] = selectedService.split('equipment');
                        equipments.push(parseInt(equipmentId, 10));
                    } return null;
                });
            }
            const useSpendingLimit = _.get(this.props, 'selectedRoom.id', null) === 4 ? false : this.props.useSpendingLimit;

            let args = {
                officeId: this.props.officeLocation.id,
                officeTypeId: this.props.selectedRoom !== null ? this.props.selectedRoom.id : null,
                productId: this.props.selectedRoom !== null ? this.props.selectedProduct.id : null,
                fromDate: this.props.selectedOptions.startDate,
                toDate: this.props.selectedOptions.endDate,
                weekDays: this.props.selectedOptions.selectedWeekDays,
                hoursFrom: this.props.selectedOptions.hoursFrom,
                hoursUntil: this.props.selectedOptions.hoursUntil,
                servicesIds: services,
                equipmentIds: equipments,
                useSpendingLimit: useSpendingLimit
            };
            this.props.fetchBookingValidate(args);
        }
    }

    debounceHideLoader = _.debounce(function(){
        this.setState({
            ajaxLoading: false
        });
    }, 300);

    debounceWorking = _.debounce(function(){
        this.setState({
            working: false
        });
    }, 300);

    debounceShowSideError = _.debounce(function(){
        this.props.openHeroSidePopup();
    }, 600);

    getOfficeSpace = function () {
        let officeSpace = [];
        //prepare available office space:
        if (typeof this.props.officeLocation.products !== "undefined") {
            if (typeof this.props.officeLocation.products.desks !== "undefined") {
                if (this.props.officeLocation.products.desks.length) {
                    officeSpace.push({
                        id: 1,
                        type: "desk",
                        typeName: this.props.trans.booking.options.officeTypes.desk,
                    })
                }
            }
            if (typeof this.props.officeLocation.products.privateOffices !== "undefined") {
                if (this.props.officeLocation.products.privateOffices.length) {
                    officeSpace.push({
                        id: 2,
                        type: "private",
                        typeName: this.props.trans.booking.options.officeTypes.private,
                    })
                }
            }
            if (typeof this.props.officeLocation.products.meetingRooms !== "undefined") {
                if (this.props.officeLocation.products.meetingRooms.length) {
                    officeSpace.push({
                        id: 3,
                        type: "meeting",
                        typeName: this.props.trans.booking.options.officeTypes.meeting,
                    })
                }
            }
            if (typeof this.props.officeLocation.products.eventRooms !== "undefined") {
                if (this.props.officeLocation.products.eventRooms.length) {
                    officeSpace.push({
                        id: 4,
                        type: "event",
                        typeName: this.props.trans.booking.options.officeTypes.event,
                    })
                }
            }
        }
        return officeSpace;
    }

    renderworkingCalendar = (selectable) => {

        let startDate = null;
        let endDate   = null;
        if(this.props.selectedOptions.startDate !== undefined)
            startDate = this.props.selectedOptions.startDate;
        if(this.props.selectedOptions.endDate !== undefined)
            endDate = this.props.selectedOptions.endDate;

        let excludedDates = [];
        let disabledDays = [];

        for(let weekD = 1; weekD <=7; weekD++)
        {
            if(this.props.bookingOptions.toJS().weekDays[weekD].available === false)
            {
                disabledDays.push(weekD);
            }
        }

        this.props.bookingOptions.toJS().closedDays.map((closedInterval) => {
            // was removed UTC because on client was disabled (-3 hours) 25.07.2018: 00:00:00 -> 24.07.2018: 21:00:00
            let startDate = moment(closedInterval.fromDate, 'YYYY-MM-DD  H:i:s').set('hour', 0).set('minute', 0);
            let endDate   = moment(closedInterval.toDate, 'YYYY-MM-DD  H:i:s').set('hour', 0).set('minute', 0);
            if(startDate.format('YYYY-MM-DD') !== endDate.format('YYYY-MM-DD'))
            {
                //we have an interval of >1 day
                let noDays = endDate.diff(startDate, 'days');
                for(let d = 0; d <= noDays; d++) {
                    excludedDates.push(moment.utc(startDate.format('YYYY-MM-DD')));
                    startDate.add(1, 'days');
                }
            }
            else
            {
                excludedDates.push(startDate);
            }
            return null;
        });

        if(! selectable) return "";

        return (
            <div onClick={this.openCheckAvailability}>
                <div className="booking-options-title mt30" onClick={(e) => e.stopPropagation()}>{this.props.trans.booking.options.dateLabel}</div>
                <DatePicker
                    toggleDateExpanded={this.toggleDateExpanded}
                    placeholder={this.props.trans.booking.options.selectRentingDate}
                    startDate={startDate}
                    endDate={endDate}
                    excludeDates={excludedDates}
                    disabledDays={disabledDays}
                    onChange={this.onUpdateDate}
                    trans={this.props.trans}
                />
            </div>
        )
    }

    toggleDateExpanded = (expanded) => {
        this.props.toggleDateExpanded(expanded);
    }

    onUpdateDate = (startDate, endDate) => {
        let {selectedOptions} = this.props;
        selectedOptions = Object.assign({}, selectedOptions, {startDate, endDate});
        this.props.setSelectedOptions(selectedOptions);
        this.setState({
            working: true,
        }, () => {
            this._fetchBookinOptions();
            this.props.redeemVoucher(null);
        });
    }

    onUpdateHours = (type, value) => {
        let {selectedOptions} = this.props;
        selectedOptions[type] = value;
        this.props.setSelectedOptions(selectedOptions);
        this.setState({
            working: true,
        }, () => {
            this._fetchBookinOptions();
        });
    }

    renderWorkingWeekDays = (selectable) => {

        let { weekDays } = this.props.bookingOptions.toJS();
        if(!selectable) {
            let weekDaysLabel = [];
            weekDaysNormalOrder.map((weekDayIndex) => {
                let isDisabled = weekDays[weekDayIndex].count === 0 || weekDays[weekDayIndex].available === false;
                if(this.props.selectedOptions.startDate !== undefined)
                {
                    let count = weekDays[weekDayIndex].count > 1 ? ' (' + weekDays[weekDayIndex].count + ')' : '';
                    if(isDisabled === false)
                    {
                        weekDaysLabel.push(this.props.trans.location.weekDaysMap[weekDayIndex] + count);
                    }
                }
                else
                {
                    if(!isDisabled)
                    {
                        weekDaysLabel.push(this.props.trans.location.weekDaysMap[weekDayIndex]);
                    }
                }
                return null;
            });

            return (
                <div className="week-days-not-selectable">
                    <div className="booking-options-title">{this.props.trans.booking.options.workingWeek}</div>
                    {weekDaysLabel.join(', ')}
                </div>
            );
        }

        let checkboxes          = [];
        let selectedCheckboxes  = [];
        selectedCheckboxes = this.props.selectedOptions.selectedWeekDays === undefined ? [] : this.props.selectedOptions.selectedWeekDays;
        weekDaysOrder.map((weekDayIndex) => {
            if(this.props.selectedOptions.startDate !== undefined)
            {
                let isDisabled = weekDays[weekDayIndex].count === 0 || weekDays[weekDayIndex].available === false;
                checkboxes.push({
                    id: weekDayIndex,
                    name: 'day[' + weekDayIndex + ']',
                    label: this.props.trans.location.weekDaysMap[weekDayIndex] + (weekDays[weekDayIndex].count > 1 ? ' (' + weekDays[weekDayIndex].count + ')' : ''),
                    disabled: isDisabled
                });
            }
            else
            {
                let isDisabled = weekDays[weekDayIndex].available === false;
                checkboxes.push({
                    id: weekDayIndex,
                    name: 'day[' + weekDayIndex + ']',
                    label: this.props.trans.location.weekDaysMap[weekDayIndex] + (weekDays[weekDayIndex].count > 1 ? ' (' + weekDays[weekDayIndex].count + ')' : ''),
                    disabled: isDisabled
                });
            } return null;
        });

        return (
            <div>
                <AddBookingCheckboxesGroup
                    titleClassName={'booking-options-title'}
                    groupName={'amenities'}
                    groupLabel={this.props.trans.booking.options.desiredWorkingWeek}
                    selectedItems={selectedCheckboxes}
                    items={Immutable.fromJS(checkboxes)}
                    onChange={this.onChangeWeekDays}
                />
                <div style={{ clear: 'both' }}></div>
            </div>
        );
    }

    onChangeWeekDays = (weekday) => {

        let {selectedOptions} = this.props;
        let selectedWeekDays = [];
        if(selectedOptions.selectedWeekDays !== undefined) {
            selectedWeekDays = selectedOptions.selectedWeekDays;
        }

        if(!selectedWeekDays.includes(weekday)) {
            selectedWeekDays.push(weekday);
        } else {
            const index = selectedWeekDays.indexOf(weekday);
            selectedWeekDays.splice(index, 1);
        }

        selectedOptions.selectedWeekDays = selectedWeekDays;
        this.props.setSelectedOptions(selectedOptions);
        this._fetchBookinOptions();
    }

    buildOptionFormProduct(product, selectedProduct, selectedRoom) {
        if (product.parent_id) {
            return null;
        }
        return this.buildOptionSingleProduct(product, selectedProduct, selectedRoom);
    }

    buildOptionSingleProduct(product, selectedProduct, selectedRoom) {
        const productSecondLine = (product.bookable && this.props.officeLocation.bookableEvent === false && selectedProduct.type !== 'event')
            ? (product.price > 0
                    ? product.price.toFixed(2).toString().replace(".", window.__env.decimalSeparator)+'€/'+product.priceType
                    : ''
            )
            : product.error;
        const children = product.children && product.children.length
            ? (
                <div className="product-children">
                    {product.children.map((child) => this.buildOptionSingleProduct(child, selectedProduct, selectedRoom))}
                </div>
            )
            : null;
        return (
            <div className="office-options-product" key={product.id}>
                <input
                    id={'product_' + product.id}
                    name='product_selected'
                    type='radio'
                    checked={selectedProduct.type === selectedRoom.type && selectedProduct.id === product.id && product.bookable}
                    disabled={product.bookable ? false : true}
                    onChange={() => this.props.selectProduct(product.id, selectedRoom.type)}
                />
                <label htmlFor={'product_' + product.id}>
                    {product.name}
                    <div className="product-second-line">{productSecondLine}</div>
                </label>
                {children}
            </div>
        );
    }

    renderInputFormsProducts = () => {
        let {products} = this.props.bookingOptions.toJS();
        let {selectedProduct, selectedRoom, trans} = this.props;
        if (typeof products !== "undefined") {
            return (
                <div>
                    <div className="booking-options-title">
                        {trans.formatString(_.get(trans, 'whiteLabel.options.productOverview', '{0} '), selectedRoom.typeName)}
                    </div>
                    {products.map((product) => this.buildOptionFormProduct(product, selectedProduct, selectedRoom))}
                </div>
            );
        }
    }

    renderWorkingHours = (selectable) => {

        const { bookingOptions, selectedOptions, bookingOptionsErrors } = this.props;
        const bookingOptionsHash = bookingOptions.toJS();
        const fromHour = _.get(bookingOptionsHash, 'hours.from.hour', '00');
        const fromMinute = _.get(bookingOptionsHash, 'hours.from.minute', '00');
        const untilHour = _.get(bookingOptionsHash, 'hours.to.hour', '00');
        const untilMinute = _.get(bookingOptionsHash, 'hours.to.minute', '00');

        let from = moment().set('hour', bookingOptionsHash.hours.from.hour).set('minute', bookingOptionsHash.hours.from.minute);
        let to = moment().set('hour', bookingOptionsHash.hours.to.hour).set('minute', bookingOptionsHash.hours.to.minute);

        if(!selectable) {
            selectedOptions.hoursFrom = from.format('HH:mm');
            selectedOptions.hoursUntil = to.format('HH:mm');
            this.props.setSelectedOptions(selectedOptions);
            return (
                <div className="hours-not-selectable">
                    <div className="booking-options-title desiredHours">{this.props.trans.booking.options.workingHours}</div>
                    {from.format('HH:mm')} - {to.format('HH:mm')}
                </div>
            );
        }

        const fromHours = [];
        const fromNextHour = from;

        if (fromMinute !== '00' && fromMinute !== '30') {
            // find first nearest half hour if start hours is not :00 or :30
            const remainder = 30 - (from.minute() % 30);
            fromNextHour.add(remainder, 'minutes');
        }

        while (parseInt(fromNextHour.format('HHmm'), 10) <= parseInt(untilHour + untilMinute, 10)) {
            fromHours.push(fromNextHour.format('HH:mm'));
            fromNextHour.add(30, 'minutes');
        }
        const errors = bookingOptionsErrors ? bookingOptionsErrors.map((error, index) => {
            if(index !== 'hours.from.hour') return null;
            return (
                <div className="product-error">
                    {ERRORS[index]}: {error.toJS()[0]}
                </div>
            );
        }) : null;


        return (
            <div>
                <div className="booking-options-title desiredHours">{this.props.trans.booking.options.desiredWorkingHours}</div>
                <div className="booking-options-hours">
                    <Dropdown
                        placeHolder={_.get(this.props.trans, 'booking.from', 'From')}
                        onChange={(value) => this.onUpdateHours('hoursFrom', value)}
                        options={fromHours}
                        selected={_.get(selectedOptions, 'hoursFrom', '')}
                    />
                    <Dropdown
                        placeHolder={_.get(this.props.trans, 'booking.until', 'To')}
                        onChange={(value) => this.onUpdateHours('hoursUntil', value)}
                        options={fromHours}
                        selected={_.get(selectedOptions, 'hoursUntil', '')}
                    />
                </div>
                {errors && <div className="error-box">{errors.values().next().value}</div>}
            </div>
        );
    }

    renderCapacityAndPricing = (officeId, isBookableEvent) => {
        if (isBookableEvent) {
            try {
                if(window.__env.InterOfficesId.split(',').map(item => parseInt(item, 10)).indexOf(officeId) > -1)
                {
                    return (
                        <div className="pdf-link__container">
                            <a target="_blank" rel="noopener noreferrer" href="https://s3.eu-central-1.amazonaws.com/workero-public-assets/media/uploaded-files/arts-56-meeting-room-capacity-pricing.pdf" className="pdf-link__link">PDF with capacity and pricing</a>
                        </div>
                    );
                }
            } catch(e) {}
        }
        return null;
    }

    renderMeetingRoomFields = () => {
        const {selectedProduct, setMeetingRoomTitle, setMeetingRoomComment, trans} = this.props;

        if(selectedProduct.type === 'meeting')
        return (
            <>
                <div className="sidebar__field">
                    <input
                        className="sidebar__field--input"
                        type="text"
                        maxLength="100"
                        onChange={e => {
                            setMeetingRoomTitle(e.target.value)
                        }}
                        placeholder={_.get(trans, 'booking.sidebar.form.meetingTitle', 'Meeting title (optional)')}
                    />
                </div>

                <div className="sidebar__field">
                      <textarea
                          className="sidebar__field--textarea"
                          maxLength="500"
                          onChange={e => {
                              setMeetingRoomComment(e.target.value)
                          }}
                          placeholder={_.get(trans, 'booking.sidebar.form.comment', 'Additional comment (optional)')}
                      />
                </div>
            </>
        );
    }

    renderRoomDetails = () => {

        const {selectedRoom, bookingOptions} = this.props;
        if(selectedRoom === null || bookingOptions === null)
        {
            return null;
        }

        let workingCalendar     =  this.renderworkingCalendar(true);
        let workingWeek         =  this.renderWorkingWeekDays(this.props.bookingOptions.toJS().weekDays.selectable);
        let inputFormsProducts  =  this.props.bookingOptionsErrors === null ? this.renderInputFormsProducts() : null;
        let workingHours        =  this.renderWorkingHours(this.props.bookingOptions.toJS().hours.selectable);
        let capacityAndPricing  =  this.renderCapacityAndPricing(_.get(this.props.officeLocation, 'id', 0), _.get(this.props.bookingOptions.toJS(), 'bookable_event', false));
        let meetingRoomFields   =  this.renderMeetingRoomFields();

        let bookingAmenitiesAndServices = null;
        if(this.props.bookingOptions !== null && this.props.bookingOptionsErrors === null) {
            bookingAmenitiesAndServices = <AddBookingAmenitiesAndServices
                officeBookingOptions={this.props.bookingOptions.toJS()}
                serviceToggle={this.ServiceToggle}
                selectedServices={this.props.selectedServices}
                trans={this.props.trans}
            />
        }

        let renderProductErrorsCount = this.renderProductErrorsCount();
        let productErrors   = renderProductErrorsCount > 0 ? this.renderProductErrors() : null;

        let errors = (productErrors !== null && productErrors.values().next().value) ? (<div className="error-box">{productErrors.values().next().value}</div>) : null;

        return (
            <div>
                {errors}
                {workingCalendar}
                {workingWeek}
                {workingHours}
                {capacityAndPricing}
                {inputFormsProducts}
                {bookingAmenitiesAndServices}
                {meetingRoomFields}
            </div>
        )
    }

    renderProductErrorsCount() {
        let renderProductErrorsCnt = 0;
        if(this.props.bookingOptionsErrors !== null)
        {
            this.props.bookingOptionsErrors.map((error, index) => {
                if(index !== 'officeTypeId') {
                    renderProductErrorsCnt++;
                }
                return null;
            });
        }
        return renderProductErrorsCnt;
    }

    renderProductErrors() {

        if(this.props.bookingOptionsErrors !== null)
        {
            return this.props.bookingOptionsErrors.map((error, index) => {
                if (index !== 'officeTypeId' && index !== 'hours.from.hour')
                    return (
                        <div className="product-error">
                            {ERRORS[index]}: {error.toJS()[0]}
                        </div>
                    );
            });
        }

        return null;
    }

    renderFooter = () => {

        let totalPrice              = 0;
        let disableContinueButton   = false;

        if(this.props.bookingValidateErrors === null && this.props.bookingValidate !== null)
        {
            totalPrice = this.props.bookingValidate.toJS().price.subtotal;
        }

        if((this.props.bookingValidate !== null && this.props.bookingValidate.toJS().error.length > 0) ||
            !this.props.selectedRoom ||
            this.props.selectedProduct.type === null ||
            this.props.bookingOptionsErrors !== null ||
            this.props.bookingValidateErrors !== null ||
            this.props.bookingValidate == null
        )
        {
            disableContinueButton   = true;
        }

        const showTotalPrice        = false; // added to remove the price - it is confusing for the customer

        let loader = this.state.ajaxLoading === true ? (<div className="LoaderInverse"></div>) : null;

        let continueButton = (this.props.selectedProduct !== null && this.props.selectedProduct.type !== null && this.state.ajaxLoading === false && this.props.selectedRoom !== null) ? (
            <div
                className={classnames({'disabled': disableContinueButton ? true : false}, 'booking-options-finish')}
                onClick={() => {
                    if(!disableContinueButton)
                        blockIncompleteProfileAction().then(() => {
                            this.props.history.push({
                                pathname:'/account/profile/incomplete',
                                fromUrl: this.props.location.pathname,
                                state: { fromBooking: true }
                            })
                        }).catch(() => {
                            this.props.onContinue();
                        });

                }}>{this.props.trans.booking.options.continueLabel}
            </div>
        ) : null;

        return (
            <div className="booking-options-footer">
                <div className="booking-options-footer-price" style={{display: showTotalPrice ? 'flex' : 'none' }}>
                    <span className="footer-price-left">{'Total price'}</span>
                    <span className="footer-price-right">{totalPrice.toFixed(2).toString().replace(".", window.__env.decimalSeparator)}€</span>
                </div>
                {continueButton}
                {loader}
            </div>
        );
    }

    toggleCheckAvailability() {
        this.setState((state) => ({ checkAvailabilityClosed: !state.checkAvailabilityClosed }));
    }

    openCheckAvailability() {
        this.setState({ checkAvailabilityClosed: false });
    }

    buildCheckAvailabilityLabel() {
        return (
            <div className="check-availability-label__container">
                <p className="check-availability-label__text" onClick={this.toggleCheckAvailability}>Check Availability</p>
            </div>
        )
    }

    onCallMeBackClicked = () => {
        let req_call_time = '';
        switch(this.state.heroPopupErrorWhen)
        {
            case 'Today':
                req_call_time = 'today';
                break;
            case 'Tomorrow':
                req_call_time = 'tomorrow';
                break;
            case 'Later this week':
                req_call_time = 'later_this_week';
                break;
            default:
                req_call_time = '';
                break;
        }
        const {officeLocation, selectedRoom, selectedProduct} = this.props;
        let args = {
            type: 'book_difficulty',
            phone: this.state.heroPopupErrorPhone,
            request_call_time: req_call_time,
            hour: this.state.heroPopupErrorHour,
            office_id: _.get(officeLocation, 'id', null),
            product_id: _.get(selectedProduct, 'id', null),
            product_type: _.get(selectedRoom, 'id', null),
            product_name: _.get(officeLocation, 'name', null),
        };
        this.props.bookingRequest(args);
    }


    returnHeroPopupContent = () => {
        const { trans } = this.props;
        let dropdownHours = [];
        let dropdownWhen = [
            'Today', 'Tomorrow', 'Later this week'
        ];
        let dropdownHour = moment().set('hour', '09').set('minute', '00');
        while(parseInt(dropdownHour.format('HHmm'), 10) <= 1800)
        {
            dropdownHours.push(dropdownHour.format('HH:mm'));
            dropdownHour.add(30, "minutes");
        }

        let errors = [];
        if(this.props.bookingOptions !== null)
        {
            this.props.bookingOptions.toJS().products.map((product) => {
                if(product.error !== "")
                {
                    errors.push(product.error);
                }
                return 0;
            });
        }

        switch(this.state.sidePopupPage)
        {
            case 'confirmation':
                return (
                    <div>
                        <h1>{localStorage.getItem('first-name')}, {_.get(trans, 'booking.sidebar.thankYou')}</h1>
                        <p dangerouslySetInnerHTML={{__html: _.get(trans, 'booking.sidebar.contactYou')}}></p>
                        <div className="vsp20"></div>
                        <div className="vsp20"></div>
                        <div className="vsp20"></div>
                        <div className="popupBox">
                            <p>{_.get(trans, 'booking.sidebar.connectedAlready', '')}</p>
                            <Link onClick={this.props.profileMenuClick}
                                  to="/connections">
                                <button className="secondary-button">{_.get(trans, 'booking.sidebar.growNetwork', '')}</button>
                            </Link>
                        </div>
                    </div>
                );
            case 'initial':
            default:
                return (
                    <div>
                        <div className="errorWithBg">{errors[0]}</div>
                        <h1>{localStorage.getItem('first-name')}, {_.get(this.trans, 'booking.sidebar.letsTalk', '')}</h1>
                        <p dangerouslySetInnerHTML={{__html: _.get(trans, 'booking.sidebar.descriptionText', '')}}>
                        </p>
                        <div className="vsp20"></div>
                        <div className="popupLabel">{_.get(trans, 'booking.sidebar.yourPhone', '')}</div>
                        <input onChange={(event) => { this.setState({heroPopupErrorPhone: event.target.value}); }} type="text"
                               placeholder={_.get(trans, 'booking.sidebar.phone', '')} value={this.state.heroPopupErrorPhone} className="popupTextInput"  />
                        {this.props.bookingRequestResponseErrors !== null && this.props.bookingRequestResponseErrors.phone !== undefined ? (<div className="popupError">{this.props.bookingRequestResponseErrors.phone[0]}</div>) : null}
                        <div className="vsp20"></div>
                        <div className="popupLabel">{_.get(trans, 'booking.sidebar.when', '')}</div>
                        <Dropdown
                            placeHolder={_.get(trans, 'booking.sidebar.select', '')}
                            onChange={(value) => { this.setState({heroPopupErrorWhen: value}); }}
                            options={dropdownWhen}
                            selected={this.state.heroPopupErrorWhen}
                        />
                        {this.props.bookingRequestResponseErrors !== null && this.props.bookingRequestResponseErrors.request_call_time !== undefined ? (<div className="popupError">{this.props.bookingRequestResponseErrors.request_call_time[0]}</div>) : null}
                        <div className="vsp20"></div>
                        <div className="popupLabel">{_.get(trans, 'booking.sidebar.time', '')}</div>
                        <Dropdown
                            placeHolder={_.get(trans, 'booking.sidebar.select', '')}
                            onChange={(value) => { this.setState({heroPopupErrorHour: value}); }}
                            options={dropdownHours}
                            selected={this.state.heroPopupErrorHour}
                        />
                        {this.props.bookingRequestResponseErrors !== null && this.props.bookingRequestResponseErrors.hour !== undefined ? (<div className="popupError">{this.props.bookingRequestResponseErrors.hour[0]}</div>) : null}
                        <div className="vsp20"></div>
                        <div className="vsp20"></div>
                        <div className="vsp20"></div>
                        <button className="main-button" onClick={this.onCallMeBackClicked}>{_.get(trans, 'booking.sidebar.callBack', '')}</button>
                    </div>
                );
        }
    }

    closeHeroSidePopup = () => {
        this.setState({
            sidePopupErrorText: null,
            sidePopupPage: 'initial',
        }, () => {
            let {selectedOptions} = this.props;
            let startDate = null;
            let endDate = null;
            selectedOptions = Object.assign({}, selectedOptions, {startDate, endDate});
            delete selectedOptions.startDate;
            delete selectedOptions.endDate;
            this.props.setSelectedOptions(selectedOptions);
            this.setState({
                working: true,
            }, () => {
                this.props.closeHeroSidePopup();
                this._fetchBookinOptions();
            });
        });
    }

    renderAvailabilityLoader() {
        const { fetchLoading } = this.props;
        if(fetchLoading) {
            return <div className='loader'/>;
        }
        return null;
    }

    render() {
        const { bookingOptions, officeLocation } = this.props;
        const { checkAvailabilityClosed } = this.state;
        let checkAvailabilityHidden = true;
        if(bookingOptions) {
            const decodedBookingOptions = bookingOptions.toJS();
            if ( officeLocation
                && officeLocation.bookableEvent
                && decodedBookingOptions
                && decodedBookingOptions.officeTypeId === 4
            ) {
                checkAvailabilityHidden = false;
            }
        }

        let errors = [];
        if(this.props.bookingOptions !== null)
        {
            this.props.bookingOptions.toJS().products.map((product) => {
                if(product.error !== "")
                {
                    errors.push(product.error);
                }
                return 0;
            });
        }

        return (
            <div className="booking-options">
                <SidePopup
                    opened={this.props.heroPopupErrorOpened && errors.length > 0}
                    onClose={this.closeHeroSidePopup}
                >
                    {this.returnHeroPopupContent()}
                </SidePopup>
                <div className="booking-options-content">
                    <Scrollbars className="styleScrollDark">
                        <div className="booking-options-scrollable">
                            <div className="booking-options-title">{this.props.trans.booking.options.chooseOfficeType}</div>
                            <div className="booking-options-icons">
                                {this.renderRoomTypes()}
                            </div>
                            <div className="booking-options-details">
                                {this.renderAvailabilityLoader()}
                                {!checkAvailabilityHidden ? this.buildCheckAvailabilityLabel() : null}
                                {this.renderRoomDetails()}
                            </div>
                        </div>
                        {this.renderFooter()}
                    </Scrollbars>
                </div>
                {
                    !checkAvailabilityHidden ?
                        <CheckAvailability
                            hidden={checkAvailabilityHidden}
                            closed={checkAvailabilityClosed}
                            toggle={this.toggleCheckAvailability}
                            officeId={officeLocation.id}
                            trans={this.props.trans}
                        /> : null
                }
            </div>
        );
    }
}

AddBookingOptions.propTypes = {
    officeLocation: PropTypes.object.isRequired
};

const mapStateToProps = state => {
    return {
        selectedRoom: state.addBooking.get('selectedRoom'),
        selectedProduct: state.addBooking.get('selectedProduct'),
        selectedOptions: state.addBooking.get('selectedOptions'),
        selectedServices: state.addBooking.get('selectedServices'),
        bookingOptions: state.addBooking.get('bookingOptions'),
        bookingOptionsErrors: state.addBooking.get('bookingOptionsErrors'),
        bookingValidateErrors: state.addBooking.get('bookingValidateErrors'),
        useSpendingLimit: state.addBooking.get('useSpendingLimit'),
        meetingRoomTitle: state.addBooking.get('meetingRoomTitle'),
        meetingRoomComment: state.addBooking.get('meetingRoomComment')
    };
};

const mapDispatchProps = dispatch => {
    return {
        setSelectedServices: (selectedServices) => {
            dispatch(setSelectedServices(selectedServices));
        },
        setSelectedOptions: (selectedOptions) => {
            dispatch(setSelectedOptions(selectedOptions));
        },
        setSelectedRoom: (room) => {
            dispatch(setSelectedRoom(room));
        },
        setSelectedProduct: (product) => {
            dispatch(setSelectedProduct(product));
        },
        setMeetingRoomTitle: (title) => {
            dispatch(setMeetingRoomTitle(title));
        },
        setMeetingRoomComment: (comment) => {
            dispatch(setMeetingRoomComment(comment));
        }
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchProps)(AddBookingOptions));