import { connect } from 'react-redux';
import _ from 'lodash';
import { translations } from '../../../selectors/translations';
import PaymentProfilesOptions from './PaymentProfilesOptions';

const mapStateToProps = state => ({
  heroProfile: state.global.get('heroProfile'),
  translations: _.get(translations(state), 'paymentProfile', {}),
  paymentProfile: state.addBooking.get('paymentProfile'),
  paymentProfileErrors: state.addBooking.get('paymentProfileErrors'),
  paymentProfileUpdateInProgress: state.addBooking.get('paymentProfileUpdateInProgress'),
  paymentProfiles: state.addBooking.get('paymentProfiles'),
});

export default connect(mapStateToProps)(PaymentProfilesOptions);
