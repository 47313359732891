import Immutable from 'immutable';
import {
  SET_INVITED_HEROES,
  SET_MEETINGS,
  SET_IS_LOADING_INVITED_HEROES,
  ADD_INVITED_HERO,
  DELETE_INVITED_HERO,
  SET_MEETING_ATTENDEES_TOAST_MESSAGE,
  CHANGE_MEETING_STATUS,
  SET_CHANGE_MEETING_STATUS_IS_LOADING,
} from '../actions/meetingAttendeesActions';

const initialState = Immutable.Map({
  meetingAttendeesInvitedHeroes: [],
  meetings: {},
  isLoadingInvitedMember: true,
  isLoadingBookingIds: {},
  meetingAttendeesToastMessage: null,
});

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_INVITED_HEROES:
      return state.setIn(['meetingAttendeesInvitedHeroes'], action.invitedHeroes);
    case SET_IS_LOADING_INVITED_HEROES:
      return state.setIn(['isLoadingInvitedMember'], action.isLoading);
    case ADD_INVITED_HERO:
      return state.updateIn(['meetingAttendeesInvitedHeroes'], heroInvited => {
        return heroInvited.concat(action.hero);
      });
    case DELETE_INVITED_HERO:
      const heroIndex = state.get('meetingAttendeesInvitedHeroes').findIndex(hero => hero.attendeeId === action.attendeeId);
      return state.deleteIn(['meetingAttendeesInvitedHeroes', heroIndex]);
    case SET_MEETING_ATTENDEES_TOAST_MESSAGE:
      return state.setIn(['meetingAttendeesToastMessage'], action.meetingAttendeesToastMessage);
    case SET_MEETINGS:
      return state.setIn(['meetings'], action.meetings);
    case CHANGE_MEETING_STATUS:
      const meetings = state.get('meetings'); // .bookings').findIndex(booking => booking.id === action.bookingId);
      const officeIndex = meetings.findIndex(office => office.id === action.officeId);
      const bookings = state.getIn(['meetings', officeIndex, 'bookings']);
      const bookingIdIndex = bookings.findIndex(booking => booking.id === action.bookingId);
      const meetingAttendeIndex = state
        .getIn(['meetings', officeIndex, 'bookings', bookingIdIndex, 'meetingAttendees'])
        .findIndex(attendee => attendee.id === action.attendeeId);
      return state.updateIn(
        ['meetings', officeIndex, 'bookings', bookingIdIndex, 'meetingAttendees', meetingAttendeIndex, 'status'],
        status => action.meetingStatus
      );
    case SET_CHANGE_MEETING_STATUS_IS_LOADING:
      if (state.getIn(['isLoadingBookingIds', action.isLoadingBookingId])) {
        return state.deleteIn(['isLoadingBookingIds', action.isLoadingBookingId]);
      }
      return state.setIn(['isLoadingBookingIds', action.isLoadingBookingId], true);
    default:
      return state;
  }
};
