import getLang from '../utils/lang';

export const FETCH_OFFICE_DETAILS = 'FETCH_OFFICE_DETAILS';
export const ON_FINISH_BOOKING = 'ON_FINISH_BOOKING';
export const FETCH_BOOKING_OPTIONS = 'FETCH_BOOKING_OPTIONS';
export const FETCH_BOOKING_VALIDATE = 'FETCH_BOOKING_VALIDATE';
export const BEFORE_FETCH_OFFICE_DETAILS = 'BEFORE_FETCH_OFFICE_DETAILS';
export const FETCH_PAYMENT_PROFILES = 'FETCH_PAYMENT_PROFILES';
export const FETCH_PAYMENT_PROFILE = 'FETCH_PAYMENT_PROFILE';
export const BEFORE_FETCH_PAYMENT_PROFILE = 'BEFORE_FETCH_PAYMENT_PROFILE';
export const CREATE_HERO_PAYMENT_PROFILE = 'CREATE_HERO_PAYMENT_PROFILE';
export const BEFORE_UPDATE_HERO_PAYMENT_PROFILE = 'BEFORE_UPDATE_HERO_PAYMENT_PROFILE';
export const UPDATE_HERO_PAYMENT_PROFILE = 'UPDATE_HERO_PAYMENT_PROFILE';
export const DELETE_HERO_PAYMENT_PROFILE = 'DELETE_HERO_PAYMENT_PROFILE';
export const REDEEM_VOUCHER = 'REDEEM_VOUCHER';
export const CLEAR_VOUCHER = 'CLEAR_VOUCHER';
export const OPEN_BOOKING_OPTIONS = 'OPEN_BOOKING_OPTIONS';
export const CLOSE_BOOKING_OPTIONS = 'CLOSE_BOOKING_OPTIONS';
export const ON_CHANGE_SECTION = 'ON_CHANGE_SECTION';
export const SET_SELECTED_ROOM = 'SET_SELECTED_ROOM';
export const SET_SELECTED_PRODUCT = 'SET_SELECTED_PRODUCT';
export const SET_SELECTED_OPTIONS = 'SET_SELECTED_OPTIONS';
export const SET_SELECTED_SERVICES = 'SET_SELECTED_SERVICES';
export const RESET_BOOKING = 'RESET_BOOKING';
export const RESET_VOUCHER_INFO = 'RESET_VOUCHER_INFO';
export const CLEAR_BOOKING_ERRORS = 'CLEAR_BOOKING_ERRORS';
export const SUBSCRIPTION_UPDATE_IN_PROGRESS = 'SUBSCRIPTION_UPDATE_IN_PROGRESS';
export const SET_EVENT_REQUEST_DETAILS = 'SET_EVENT_REQUEST_DETAILS';
export const FETCH_CHECK_AVAILABILITY = 'FETCH_CHECK_AVAILABILITY';
export const BOOKING_REQUEST = 'BOOKING_REQUEST';
export const OPEN_SIDE_POPUP = 'OPEN_SIDE_POPUP';
export const CLOSE_SIDE_POPUP = 'CLOSE_SIDE_POPUP';
export const OPEN_HERO_SIDE_POPUP = 'OPEN_HERO_SIDE_POPUP';
export const CLOSE_HERO_SIDE_POPUP = 'CLOSE_HERO_SIDE_POPUP';
export const TOGGLE_DATE_EXPANDED = 'TOGGLE_DATE_EXPANDED';
export const FETCH_PAYMENT_METHODS = 'FETCH_PAYMENT_METHODS';
export const SET_SELECTED_PAYMENT_METHOD = 'SET_SELECTED_PAYMENT_METHOD';
export const SET_DEFAULT_PAYMENT_METHOD = 'SET_DEFAULT_PAYMENT_METHOD';
export const DELETE_PAYMENT_METHOD = 'DELETE_PAYMENT_METHOD';
export const CREATE_PAYMENT_METHOD = 'CREATE_PAYMENT_METHOD';
export const CLEAR_DELETE_ERRORS = 'CLEAR_DELETE_METHOD_ERRORS';
export const SET_USE_SPENDING_LIMIT = 'SET_USE_SPENDING_LIMIT';
export const LOADER_DELETE = 'LOADER_DELETE';
export const SET_MEETING_ROOM_TITLE = 'SET_MEETING_ROOM_TITLE';
export const SET_MEETING_ROOM_COMMENT = 'SET_MEETING_ROOM_COMMENT';

const lang = getLang() !== null ? getLang() : 'en';

export const setSelectedRoom = room => {
  return {
    type: SET_SELECTED_ROOM,
    room,
  };
};
export const setSelectedPaymentMethod = paymentMethod => {
  return {
    type: SET_SELECTED_PAYMENT_METHOD,
    paymentMethod,
  };
};
export const setSelectedProduct = product => {
  return {
    type: SET_SELECTED_PRODUCT,
    product,
  };
};

export const fetchOfficeDetails = id => {
  const officeUrl = localStorage.getItem('access-key')
    ? `/offices/details/${id}?language=${lang}`
    : `/public/offices/details/${id}?language=${lang}`;
  return {
    type: FETCH_OFFICE_DETAILS,
    url: window.__env.apiUrl + officeUrl,
  };
};

export const openBookingOptions = () => {
  return {
    type: OPEN_BOOKING_OPTIONS,
  };
};

export const closeBookingOptions = () => {
  return {
    type: CLOSE_BOOKING_OPTIONS,
  };
};
export const setMeetingRoomTitle = title => {
  return {
    type: SET_MEETING_ROOM_TITLE,
    title,
  };
};
export const setMeetingRoomComment = comment => {
  return {
    type: SET_MEETING_ROOM_COMMENT,
    comment,
  };
};
export const beforeFetchOfficeDetails = () => {
  return {
    type: BEFORE_FETCH_OFFICE_DETAILS,
  };
};

export const onFinishBooking = args => {
  return {
    type: ON_FINISH_BOOKING,
    url: `${window.__env.apiUrl}/booking/book`,
    args,
  };
};

export const fetchBookingOptions = (officeId, officeTypeId, productId, time_date) => {
  const apiPrefix = localStorage.getItem('access-key') ? '' : '/public';
  return {
    type: FETCH_BOOKING_OPTIONS,
    url: `${window.__env.apiUrl + apiPrefix}/booking/options?language=${lang}`,
    officeId,
    officeTypeId,
    productId,
    time_date,
  };
};

export const fetchBookingValidate = args => {
  const apiPrefix = localStorage.getItem('access-key') ? '' : '/public';
  return {
    type: FETCH_BOOKING_VALIDATE,
    url: `${window.__env.apiUrl + apiPrefix}/booking/validate?language=${lang}`,
    args,
  };
};

export const fetchPaymentProfiles = () => {
  return {
    type: FETCH_PAYMENT_PROFILES,
    url: `${window.__env.apiUrl}/hero/paymentProfiles`,
  };
};
export const fetchPaymentMethods = () => {
  return {
    type: FETCH_PAYMENT_METHODS,
    url: `${window.__env.apiUrl}/hero/paymentMethods`,
  };
};

export const fetchPaymentProfile = (id = null) => {
  return {
    type: FETCH_PAYMENT_PROFILE,
    url: `${window.__env.apiUrl}/hero/paymentProfiles/${id}`,
  };
};

export const beforeFetchPaymentProfile = () => {
  return {
    type: BEFORE_FETCH_PAYMENT_PROFILE,
  };
};

export const createHeroPaymentProfile = (data, cb) => {
  return {
    type: CREATE_HERO_PAYMENT_PROFILE,
    url: `${window.__env.apiUrl}/hero/paymentProfiles`,
    cb,
    data,
  };
};
export const createPaymentMethod = (data, cb) => {
  return {
    type: CREATE_PAYMENT_METHOD,
    url: `${window.__env.apiUrl}/hero/paymentMethods`,
    cb,
    data,
  };
};
export const beforeUpdateHeroPaymentProfile = () => {
  return {
    type: BEFORE_UPDATE_HERO_PAYMENT_PROFILE,
  };
};

export const updateHeroPaymentProfile = data => {
  return {
    type: UPDATE_HERO_PAYMENT_PROFILE,
    url: `${window.__env.apiUrl}/hero/paymentProfiles`,
    data,
  };
};

export const deleteHeroPaymentProfile = (id, cb) => {
  return {
    type: DELETE_HERO_PAYMENT_PROFILE,
    url: `${window.__env.apiUrl}/hero/paymentProfiles/${id}`,
    cb,
    id,
  };
};

export const redeemVoucher = (code, args = null) => {
  return {
    type: REDEEM_VOUCHER,
    url: `${window.__env.apiUrl}/booking/validateVoucher`,
    code,
    args,
  };
};

export const clearVoucher = () => {
  return {
    type: CLEAR_VOUCHER,
  };
};

export const onChangeSection = bookingSection => {
  return {
    type: ON_CHANGE_SECTION,
    bookingSection,
  };
};

export const setSelectedOptions = selectedOptions => {
  return {
    type: SET_SELECTED_OPTIONS,
    selectedOptions,
  };
};

export const setSelectedServices = selectedServices => {
  return {
    type: SET_SELECTED_SERVICES,
    selectedServices,
  };
};

export const resetBooking = () => {
  return {
    type: RESET_BOOKING,
  };
};

export const resetVoucherInfo = () => {
  return {
    type: RESET_VOUCHER_INFO,
  };
};

export const clearBookingErrors = () => {
  return {
    type: CLEAR_BOOKING_ERRORS,
  };
};

export const subscriptionUpdateInProgress = () => {
  return {
    type: SUBSCRIPTION_UPDATE_IN_PROGRESS,
  };
};

export const setEventRequestDetails = details => {
  return {
    type: SET_EVENT_REQUEST_DETAILS,
    details,
  };
};

export const fetchCheckAvailability = (officeId, date, officeType) => {
  const apiPrefix = localStorage.getItem('access-key') ? '' : '/public';
  return {
    type: FETCH_CHECK_AVAILABILITY,
    url: `${window.__env.apiUrl + apiPrefix}/offices/availability`,
    officeId,
    date,
    officeType,
  };
};

export const bookingRequest = args => {
  return {
    type: BOOKING_REQUEST,
    args,
    url: `${window.__env.apiUrl}/booking/bookingRequest`,
  };
};

export const openSidePopup = () => {
  return {
    type: OPEN_SIDE_POPUP,
  };
};

export const closeSidePopup = () => {
  return {
    type: CLOSE_SIDE_POPUP,
  };
};

export const openHeroSidePopup = () => {
  return {
    type: OPEN_HERO_SIDE_POPUP,
  };
};

export const closeHeroSidePopup = () => {
  return {
    type: CLOSE_HERO_SIDE_POPUP,
  };
};

export const toggleDateExpanded = expanded => {
  return {
    type: TOGGLE_DATE_EXPANDED,
    expanded,
  };
};

export const setDefaultPaymentMethod = id => {
  return {
    type: SET_DEFAULT_PAYMENT_METHOD,
    url: `${window.__env.apiUrl}/hero/paymentMethods/default/${id}`,
    id,
  };
};

export const deletePaymentMethod = (id, callback) => {
  return {
    type: DELETE_PAYMENT_METHOD,
    url: `${window.__env.apiUrl}/hero/paymentMethods/${id}`,
    callback,
    id,
  };
};

export const clearDeleteErrors = () => {
  return {
    type: CLEAR_DELETE_ERRORS,
  };
};
export const loaderDelete = data => {
  return {
    type: LOADER_DELETE,
    data,
  };
};

export const setUseSpendingLimit = value => {
  return {
    type: SET_USE_SPENDING_LIMIT,
    value,
  };
};
