import Immutable from 'immutable';
import {
  SET_COMMUNITY_ADMINS,
  SET_COMMUNITY_ACTIVE_MEMBERS,
  SET_COMMUNITY_BLOCKED_MEMBERS,
  SET_COMMUNITY_INVITATIONS,
  SET_ALL_MEMBERS_IS_LOADING,
} from '../../actions/community/all-members';

const initialState = Immutable.Map({
  admins: [],
  activeMembers: [],
  blockedMembers: [],
  communityInvitations: [],
  isLoading: false,
});

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_COMMUNITY_ADMINS:
      return state.setIn(['admins'], action.members);
    case SET_COMMUNITY_ACTIVE_MEMBERS:
      return state.setIn(['activeMembers'], action.members);
    case SET_COMMUNITY_BLOCKED_MEMBERS:
      return state.setIn(['blockedMembers'], action.members);
    case SET_COMMUNITY_INVITATIONS:
      return state.setIn(['communityInvitations'], action.communityInvitations);
    case SET_ALL_MEMBERS_IS_LOADING:
      return state.setIn(['isLoading'], action.isLoading);
    default:
      return state;
  }
};
