import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';

import './css/index.scss';

class SidePopup extends React.Component {

    render() {
        if(this.props.opened !== true) return null;
        
        return (
            <div className="sidePopup">
                <div className="overlay"></div>
                <div className="popup">
                    <div className="closeButton" onClick={this.props.onClose}><span></span></div>
                    <Scrollbars>
                    <div className="p30">
                        {this.props.children}
                    </div>
                    </Scrollbars>
                </div>
            </div>
        );
    }
}

export default SidePopup;