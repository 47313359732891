import Immutable from 'immutable';
import { SET_JOIN_COMMUNITY_ERRORS, CLEAR_JOIN_COMMUNITY_ERRORS } from '../../actions/community/join-community';

const initialState = Immutable.Map({
  joinCommunityErrors: undefined,
});

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_JOIN_COMMUNITY_ERRORS:
      return state.setIn(['joinCommunityErrors'], action.joinCommunityErrors);
    case CLEAR_JOIN_COMMUNITY_ERRORS:
      return state.setIn(['joinCommunityErrors'], undefined);
    default:
      return state;
  }
};
