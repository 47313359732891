import React from 'react';
import ModalDialog from './../modalDialog/modalDialog';
import RequestExport from "../modalDialog/modals/requestExport";
import RequestDelete from "../modalDialog/modals/requestDelete";

import './css/index.scss';

class AccountGeneralSettings extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      rendering: true,
      requestExportModalOpened: false,
      requestDeleteModalOpened: false,
    };
  }

  componentDidMount() {
      this.props.fetchProfile();
      this.setState({rendering: false});
  }

  componentDidUpdate = () => {
  }

  onRequestExportModalClose = () => {
    this.setState({requestExportModalOpened: false});
  }

  onRequestDeleteModalClose = () => {
    this.setState({requestDeleteModalOpened: false});
  }

  requestExport = () => {
    this.setState({requestExportModalOpened: false}, () => {
        this.props.requestExportData();
    });
  }

  requestDelete = () => {
    this.setState({requestDeleteModalOpened: false}, () => {
        this.props.requestDeleteData();
    });
  }

  render() {

    if(this.state.rendering === true || this.props.translations === null)
    {
      return (
        <div className="officeDetailsLoader"></div>
      );
    }

    return (
      <div className="account-general-settings">
        <div className="account-general-settings__wrapper">
          <section className="account-general-settings__content">
              <button className="gdpr-button-export" onClick={() => { this.setState({requestExportModalOpened: true}); }} style={{marginBottom: '20px'}}>{this.props.translations.generalSettings.requestExport}</button>
              <div className="spacer"></div>
              <button className="gdpr-button-delete" onClick={() => { this.setState({requestDeleteModalOpened: true}); }}>{this.props.translations.generalSettings.requestDelete}</button>
          </section>
        </div>

        <ModalDialog
          modalOpened={this.state.requestExportModalOpened}  
          className="--paymentAccount"
          onModalClose={this.onRequestExportModalClose}
          label="requestExport">
          <RequestExport
            onAction={this.requestExport}
            onCancelAction={this.onRequestExportModalClose}
            trans={this.props.translations}
          />
        </ModalDialog>

        <ModalDialog
          modalOpened={this.state.requestDeleteModalOpened}
          className="--paymentAccount"
          onModalClose={this.onRequestDeleteModalClose}
          label="requestExport">
          <RequestDelete
            onAction={this.requestDelete}
            onCancelAction={this.onRequestDeleteModalClose}
            trans={this.props.translations}
          />
        </ModalDialog>

      </div>
    );
  }
}

export default AccountGeneralSettings;
