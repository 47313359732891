import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import fr from 'moment/locale/fr';
import nl from 'moment/locale/nl-be';
import getLang from './../../utils/lang';
let languageList = {
  fr: fr, 
  nl: nl
};
let lang = (getLang() !== null && languageList.hasOwnProperty(getLang()) )? getLang() : 'en';

const OfficeSchedule = (props) => {

  let workingHours = props.workingHours,
      closedDays = props.closedDays;
  let weekDays = [];

  let hoursStart  = workingHours[0].fromHour + ':' + workingHours[0].fromMinute;
  let hoursEnd    = workingHours[0].toHour + ':' + workingHours[0].toMinute;

  workingHours.map((day) => (weekDays.push(props.trans.location.weekDaysMap[day.weekDay])));

  let closedIntervalContainer = null;
  let closedIntervalItems     = null;

  if(closedDays.length > 0) {

    closedIntervalItems = closedDays.map((closedDay) => {
      return (
        <div key={closedDay.fromDate + closedDay.toDate}>{moment.unix(closedDay.fromDate).locale(lang).format('MMM DD, YYYY')} - {moment.unix(closedDay.toDate).locale(lang).format('MMM DD, YYYY')}</div>
    );});

    closedIntervalContainer = (
      <div className="office-schedule-closedDays">
        <div className="office-schedule-subTitle">{props.trans.location.closedDays}</div>
        {closedIntervalItems}
        <div className="office-schedule-fieldDescription"></div>
      </div>
    );
  }

  return (
    <div className="office-schedule">
      <div className="office-schedule-title">{props.trans.location.businessHours}</div>
      <div className="office-schedule-openHours">
        <div className="office-schedule-subTitle">{props.trans.location.openHours}</div>
        <div>{weekDays.join(', ')}</div>
        <div className="office-schedule-fieldDescription">{hoursStart} - {hoursEnd}</div>
      </div>
      {closedIntervalContainer}
    </div>
  )
};

OfficeSchedule.propTypes = {
  workingHours: PropTypes.array.isRequired,
  closedDays: PropTypes.array.isRequired,
};

export default OfficeSchedule;
