import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import _ from 'lodash';

const AccountPaymentSettingsMenu = props => {
  const pathName = props.history.location.pathname;
  const { translations } = props;

  return (
    <div className="my-bookings-menu">
      <ul className="my-bookings-menu-list">
        <li className={`my-bookings-menu__link ${pathName === '/account/paymentSettings' ? 'my-bookings-menu__link-active' : ''}`}>
          <Link to="/account/paymentSettings">
            {_.get(translations, 'paymentSettings.paymentProfile', '')}
            <span className="my-bookings-menu-border" />
          </Link>
        </li>
        <li className={`my-bookings-menu__link ${pathName === '/account/voucher-history' ? 'my-bookings-menu__link-active' : ''}`}>
          <Link to="/account/voucher-history">
            {_.get(translations, 'paymentSettings.voucherHistory', '')}
            <span className="my-bookings-menu-border" />
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default withRouter(AccountPaymentSettingsMenu);
