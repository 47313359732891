import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import blockIncompleteProfileAction from '../../../utils/blockIncompleteProfileAction';

const FirstCommunityContainer = props => {
  const { translations, action, history, location } = props;

  return (
    <div className="card__container card__container--border start_first_community__card">
      <div className="first_commnunity__column--left ">
        <p className="first_commnunity">{_.get(translations, 'startFirstCommunityTitle', '')}</p>
        <span className="first_community_subtitle">{_.get(translations, 'startFirstCommunitySubtitle', '')}</span>
      </div>
      <div className="first_commnunity__column--right">
        <button
          type="button"
          className="entity-button entity-button--border start-now__button"
          onClick={() =>
            blockIncompleteProfileAction()
              .then(() => {
                history.push({ pathname: '/account/profile/incomplete', fromUrl: location.pathname });
              })
              .catch(() => {
                action();
              })
          }
        >
          {_.get(translations, 'startFirstCommunityButton', '')}
        </button>
      </div>
    </div>
  );
};

FirstCommunityContainer.propTypes = {
  translations: PropTypes.shape({
    startFirstCommunityTitle: PropTypes.string,
    startFirstCommunitySubtitle: PropTypes.string,
    startFirstCommunityButton: PropTypes.string,
  }),
  action: PropTypes.func.isRequired,
};

FirstCommunityContainer.defaultProps = {
  translations: {},
};

export default withRouter(FirstCommunityContainer);
