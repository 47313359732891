import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';
import _ from 'lodash';
import classnames from 'classnames';
// import utils
import { isMobile } from 'react-device-detect';
import getLang from '../../utils/lang';
import ChatService from '../../services/chat.service';
import { getCurrentHostname } from '../../utils/currentCompanyHostname';
import { iubendaImport, iubendaConfig } from './Iubenda';
// import components
import Header from '../../views/headerView/headerView';
import HomeView from '../../views/homeView/homeView';
import LocationView from '../../views/locationView/locationView';
import ProfileView from '../../views/profileView';
import { ProfileWhitelabelView, AccountProfileWhitelabelView } from '../whiteLabel/components/profile';
import MyBookings from '../../views/myBookingsView/myBookingsView';
import Navigation from '../navigation/navigation';
import DashboardView from '../../views/dashboard-view';
import DashboardMediaView from '../../views/dashboard-media-view';
import CommunitiesView from '../../views/communities-view';
import CommunityView from '../../views/community-view';
import CommunitySinglePostPage from '../../views/community-single-post-view';
import HealthMeasure from '../../views/healthMeasureView/healthMeasure';
import BookingView from '../../views/bookingView/bookingView';
import ConnectionsView from '../../views/ConnectionsView/connectionsView';
import DirectChatView from '../../views/directChatView/directChatView';
import ConnectionView from '../../views/ConnectionView/connectionView';
import AccountProfileView from '../../views/accountProfileView/accountProfileView';
import GeneralSettingsView from '../../views/generalSettingsView/generalSettingsView';
import AccountPaymentSettingsView from '../../views/accountPaymentSettingsView/accountPaymentSettingsView';
import VoucherHistoryView from '../accountPaymentSettings';
import Invitation from '../invitation';
import OpenLogin from '../../views/loginView';
import OpenSignUp from '../../views/signUpView';
import OpenForgotPassword from '../../views/forgotPasswordView';
import OpenResetPassword from '../../views/resetPasswordView';
import ActivateServices from './ActivateServices';
import LinkedinLogin from '../openAuth/linkendinLogin';
import CookieBanner from './CookieBanner';
import IsMobile from '../isMobile';
import BookingPaymentView from '../../views/bookingPayment/bookingPaymentView';
import PaymentProfile from '../../views/paymentProfile';
import MyMeetings from '../../views/myMeetingsView';
import MyMeeting from '../../views/myMeetingView';
import goToMeeting from '../../utils/goToMeeting';
import WhiteLabelLocation from '../whiteLabel';
import WhiteLabelHomepage from '../whiteLabel/home';
import HeroDetails from '../whiteLabel/components/HeroDetails';
import WhitelabelBooking from '../whiteLabel/components/bookingConfirmation';
import { currentRouteRestriction } from '../../utils/currentRouteGroup';
import CONST from '../../utils/const';
import { CustomSignup, CustomLogin } from '../openAuth';
import MyParkingBookings from '../whiteLabel/components/MyBookings/myParkings';
import WmsHomescreen from '../wmsHomescreen';

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props =>
        rest.restricted ? (
          <Component {...props} sendChatMessage={ChatService.sendMessage} />
        ) : (
          <Redirect
            to={{
              pathname: rest.goTo ? rest.goTo : '/login',
              state: {
                from: props.location.pathname,
                queryParams: props.location.search,
                fromBooking: _.get(props.location, 'state.fromBooking', false),
              },
              fromUrl: _.get(props.location, 'fromUrl', ''),
            }}
          />
        )
      }
    />
  );
};

const blockedLoggedRoutes = [
  <Route exact path="/login" component={OpenLogin} key="login" />,
  <Route exact path="/sign-up" component={OpenSignUp} key="sign-up" />,
  <Route exact path="/linkedin-login" component={LinkedinLogin} key="linkedin-login" />,
  <Route exact path="/forgot-password" component={OpenForgotPassword} key="forgot-password" />,
  <Route path="/alternative/password/reset" component={OpenResetPassword} key="alternative-password-reset" />,
];
const customSignupLoginRoutes = [
  <Route exact path="/login" component={CustomLogin} key="login" />,
  <Route exact path="/sign-up" component={CustomSignup} key="sign-up" />,
  <Route exact path="/forgot-password" component={OpenForgotPassword} key="forgot-password" />,
  <Route path="/alternative/password/reset" component={OpenResetPassword} key="alternative-password-reset" />,
];
const wmsHomescreenRoute = <Route exact path="/" component={WmsHomescreen} key="wms-homescreen" />;

function Main(props) {
  const {
    getTranslations,
    setHeroAuthentication,
    isAuthenticated,
    isWhiteLabel,
    restrictions,
    handleSubdomain,
    validSubdomain,
    fetchAppTimezone,
  } = props;
  const lang = getLang();
  const { isWmsApp } = window.__env;
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    handleSubdomain(getCurrentHostname());
    getTranslations(lang);
    setHeroAuthentication(!!localStorage.getItem('access-key'));
    fetchAppTimezone();

    // add iubenda cookie and privacy policy
    document.body.appendChild(iubendaImport);
    document.body.appendChild(iubendaConfig);
  }, []);
  useEffect(() => {
    if (isAuthenticated && restrictions && isWhiteLabel !== null && validSubdomain !== null) {
      setIsLoading(false);
    }
    if (!localStorage.getItem('access-key') && validSubdomain !== null) {
      setIsLoading(false);
    }
  }, [props.restrictions, props.isWhiteLabel, props.validSubdomain]);
  if (isMobile) {
    return (
      <div style={{ height: 'inherit' }}>
        <IsMobile />
      </div>
    );
  }
  const whiteLabelRoutes = [
    <Route exact path="/booking" component={WhiteLabelHomepage} />,
    <Route exact path="/:city/:country/:location/:id/:officeSpaceSuffix" component={WhiteLabelLocation} />,
    <Route exact path="/location/:id" component={WhiteLabelLocation} />,
    <PrivateRoute restricted={props.isAuthenticated} exact path="/colleague/details/:id" component={HeroDetails} />,
    <PrivateRoute restricted={props.isAuthenticated} exact path="/booking/payment" component={BookingPaymentView} />,
    <PrivateRoute restricted={props.isAuthenticated} exact path="/booking/:id/:confirmed" component={WhitelabelBooking} />,
    <PrivateRoute restricted={props.isAuthenticated} exact path="/booking/:id" component={WhitelabelBooking} />,
    <PrivateRoute restricted={props.isAuthenticated} exact path="/meeting/:id" component={MyMeeting} />,
  ];
  const routes = [
    <Route exact path="/booking" component={HomeView} />,
    <Route exact path="/:city/:country/:location/:id/:officeSpaceSuffix" component={LocationView} />,
    <Route exact path="/location/:id" component={LocationView} />,
    <PrivateRoute restricted={props.isAuthenticated} exact path="/colleague/details/:id" component={HeroDetails} />,
    <PrivateRoute restricted={props.isAuthenticated} exact path="/booking/payment" component={BookingPaymentView} />,
    <PrivateRoute restricted={props.isAuthenticated} exact path="/booking/:id/:confirmed" component={BookingView} />,
    <PrivateRoute restricted={props.isAuthenticated} exact path="/booking/:id" component={BookingView} />,
    <PrivateRoute restricted={props.isAuthenticated} exact path="/meeting/:id" component={MyMeeting} />,
  ];

  const customRoutes = isWhiteLabel ? whiteLabelRoutes : routes;
  const whitelabelRedirectRoute = [<Route path="/booking" component={WhiteLabelHomepage} render={() => <Redirect to="/booking" />} />];
  const communitiesRoutes = !_.find(restrictions.toJS(), x => x.restriction_code === currentRouteRestriction(CONST.navigation.community))
    ? [
        <Route exact path="/communities/all" component={CommunitiesView} />,
        <Route exact path="/communities" restrictions={restrictions} component={CommunitiesView} />,
        <PrivateRoute restricted={props.isAuthenticated} exact path="/community/:slug" component={CommunityView} goTo="/login" />,
        <PrivateRoute restricted={props.isAuthenticated} exact path="/community/:slug/:postId" component={CommunitySinglePostPage} />,
      ]
    : whitelabelRedirectRoute;
  const dashoardRoutes = !_.find(restrictions.toJS(), x => x.restriction_code === currentRouteRestriction(CONST.navigation.dashboard))
    ? [<Route exact path="/dashboard" component={goToMeeting(DashboardView)} />]
    : whitelabelRedirectRoute;
  const healthMeasuresRoutes = !_.find(
    restrictions.toJS(),
    x => x.restriction_code === currentRouteRestriction(CONST.navigation.healthMeasure)
  )
    ? [<Route exact path="/health-safety-certificate" component={HealthMeasure} />]
    : whitelabelRedirectRoute;
  const connectionRoutes = !_.find(restrictions.toJS(), x => x.restriction_code === currentRouteRestriction(CONST.navigation.connections))
    ? [
        <PrivateRoute restricted={props.isAuthenticated} exact path="/connection/:id" component={ConnectionView} />,
        <Route exact path="/connections/" component={ConnectionsView} />,
      ]
    : whitelabelRedirectRoute;
  const directChatRoutes = !_.find(restrictions.toJS(), x => x.restriction_code === currentRouteRestriction(CONST.navigation.directChat))
    ? [<PrivateRoute restricted={props.isAuthenticated} exact path="/direct-chat" component={DirectChatView} />]
    : whitelabelRedirectRoute;
  const impersonatingHero = localStorage.getItem('impersonatingHero');
  const isWmsGuest = !isAuthenticated && isWmsApp;
  const isRoot = window.location.pathname === '/';
  const guestRedirect = isWmsApp ? <Redirect to="/" /> : <Redirect to="/login" />;

  return (
    <Router>
      <LastLocationProvider>
        <div style={{ height: 'inherit' }}>
          <div
            className={classnames({
              impersonating: impersonatingHero,
              wmsHomescreen: isWmsGuest && isRoot,
            })}
            style={{ height: 'inherit' }}
          >
            <Header />
            <Navigation />
            <div className="main-content">
              <ActivateServices loading={isLoading} />
              {!isLoading && (
                <>
                  <Switch>
                    {!isAuthenticated && !isWmsApp && blockedLoggedRoutes}
                    {!isAuthenticated && isWmsApp && customSignupLoginRoutes}
                    {isWmsGuest && wmsHomescreenRoute}
                    {!isWmsGuest && customRoutes}
                    {!isWmsGuest && dashoardRoutes}
                    {!isWmsGuest && healthMeasuresRoutes}
                    {!isWmsGuest && communitiesRoutes}
                    <Route exact path="/impersonate" component={validSubdomain ? CustomLogin : OpenLogin} />,
                    <PrivateRoute
                      restricted={props.isAuthenticated}
                      exact
                      path="/account/profile"
                      component={isWhiteLabel ? AccountProfileWhitelabelView : AccountProfileView}
                    />
                    ,
                    <PrivateRoute
                      restricted={props.isAuthenticated}
                      exact
                      path="/account/profile/incomplete"
                      component={isWhiteLabel ? AccountProfileWhitelabelView : AccountProfileView}
                    />
                    ,
                    <PrivateRoute
                      restricted={props.isAuthenticated}
                      exact
                      path="/account/generalSettings"
                      component={GeneralSettingsView}
                    />
                    ,
                    <PrivateRoute restricted={props.isAuthenticated} exact path="/account/paymentProfile/:id?" component={PaymentProfile} />
                    ,
                    <PrivateRoute
                      restricted={props.isAuthenticated}
                      exact
                      path="/account/paymentSettings"
                      component={AccountPaymentSettingsView}
                    />
                    ,
                    <PrivateRoute
                      restricted={props.isAuthenticated}
                      exact
                      path="/profile"
                      component={isWhiteLabel ? ProfileWhitelabelView : ProfileView}
                    />
                    , ,
                    <PrivateRoute restricted={props.isAuthenticated} exact path="/my-bookings/" component={MyBookings} />,
                    <PrivateRoute restricted={props.isAuthenticated} exact path="/my-meetings/" component={MyMeetings} />,
                    <PrivateRoute restricted={props.isAuthenticated} exact path="/my-parking-bookings/" component={MyParkingBookings} />,
                    {!isWmsGuest && <Route exact path="/dashboard/media/:id" component={DashboardMediaView} />},
                    {!isWmsGuest && connectionRoutes},{!isWmsGuest && directChatRoutes},
                    <PrivateRoute restricted={props.isAuthenticated} path="/invitation" component={Invitation} goTo="/sign-up" />,
                    <PrivateRoute
                      restricted={props.isAuthenticated}
                      path="/account/voucher-history"
                      component={VoucherHistoryView}
                      goTo="/login"
                    />
                    ,{isAuthenticated ? <Redirect to="/booking" /> : guestRedirect}
                  </Switch>
                </>
              )}
            </div>
          </div>
        </div>
      </LastLocationProvider>
    </Router>
  );
}
export default Main;
