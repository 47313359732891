import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
import newMessage from '../../../../assets/svg/dashboard/new-message-icon.svg';
import newMessageHover from '../../../../assets/svg/dashboard/new-message-icon--hover.svg';
import noProfilePicture from '../../../../assets/images/defaultAvatar.png';
import blockIncompleteProfileAction from '../../../../utils/blockIncompleteProfileAction';

const goToConnections = () => {
  window.location.href = `/connections`;
};

class MostActiveHeroes extends Component {
  constructor(props) {
    super(props);
    this.getConnectionUrl = this.getConnectionUrl.bind(this);
    this.buildHeroWrapper = this.buildHeroWrapper.bind(this);
    this.goToDirectChat = this.goToDirectChat.bind(this);
  }

  componentDidMount() {
    const { fetchMostActiveHeroes } = this.props;
    fetchMostActiveHeroes();
  }

  getConnectionUrl(hero) {
    const { history } = this.props;
    history.push(`/connection/${_.get(hero, 'id')}`);
  }

  goToDirectChat(hero) {
    const { openConversation, history } = this.props;
    openConversation(_.get(hero, 'id', null), hero);
    history.push('/direct-chat');
  }

  buildHeroWrapper(hero) {
    const { firstName, lastName, job, profilePictureUrl, id, history, heroInitials } = hero;
    const avatar = profilePictureUrl || noProfilePicture;
    return (
      <div key={id} className="active-hero__announcement" onClick={this.getConnectionUrl.bind(this, hero)}>
        {!profilePictureUrl ? (
          <div className="hero-initials most-active-heroes-initials">{heroInitials}</div>
        ) : (
          <img src={avatar} alt="Profile" className="active-hero__image" />
        )}
        <div className="active-hero__details-wrapper">
          <div className="active-hero__name">{`${firstName} ${lastName}`}</div>
          <div className="active-hero__job">{job}</div>
        </div>
        <div className="active-hero__button-container">
          <button
            type="button"
            className="active-hero__message-button"
            onClick={event => {
              event.stopPropagation();
              blockIncompleteProfileAction()
                .then(() => {
                  history.push({ pathname: '/account/profile/incomplete' });
                })
                .catch(() => {
                  this.goToDirectChat(hero);
                });
            }}
          >
            <img className="active-hero__message-icon" src={newMessage} alt="" />
            <img className="active-hero__message-icon--hover" src={newMessageHover} alt="" />
          </button>
        </div>
      </div>
    );
  }

  render() {
    const { activeHeroes, translations } = this.props;
    return _.isEmpty(activeHeroes) || !_.isArray(activeHeroes) ? null : (
      <div className="dashboard-grid__row dashboard-grid__row--margin-bottom">
        <div className="card__container card__container--border card__most-active-heroes">
          <h4 className="card__title">{_.get(translations, 'mostActiveHeroes', 'Most Active Heroes')}</h4>
          <div className="active-hero__announcements-wrapper">{activeHeroes.map(this.buildHeroWrapper)}</div>
          <button type="button" className="dashboard-button dashboard-button--border active-heroes__button" onClick={goToConnections}>
            {_.get(translations, 'growYourNetwork', 'Grow your network')}
          </button>
        </div>
      </div>
    );
  }
}

MostActiveHeroes.propTypes = {
  activeHeroes: PropTypes.arrayOf(
    PropTypes.shape({
      params: PropTypes.string,
    })
  ),
  fetchMostActiveHeroes: PropTypes.func.isRequired,
  openConversation: PropTypes.func.isRequired,
  history: PropTypes.shape({}),
};

MostActiveHeroes.defaultProps = {
  activeHeroes: [],
  history: {},
};

export default withRouter(MostActiveHeroes);
