import moment from 'moment-timezone';

const computeTimeTo = (startDate, startHour, timezone = 'Europe/Brussels') => {
  let timeTo = '';
  if (startDate && startHour) {
    const now = moment().tz(timezone);
    const startBooking = moment.tz(`${startDate}T${startHour}`, 'YYYY-MM-DD hh:mm:ss', timezone);
    const duration = moment.duration(startBooking.diff(now));
    const yearsTo = Math.floor(duration.years());
    const monthsTo = Math.floor(duration.months());
    const weeksTo = Math.floor(duration.weeks());
    const daysTo = Math.floor(duration.days());
    const hours = Math.floor(duration.hours()) < 10 ? `0${Math.floor(duration.hours())}` : Math.floor(duration.hours());
    const minutes = Math.floor(duration.minutes()) < 10 ? `0${Math.floor(duration.minutes())}` : Math.floor(duration.minutes());

    timeTo += yearsTo !== 0 ? `${yearsTo} year${yearsTo > 1 ? 's ' : ' '}` : '';
    timeTo += monthsTo !== 0 ? `${monthsTo} month${monthsTo > 1 ? 's ' : ' '}` : '';
    timeTo += weeksTo !== 0 ? `${weeksTo} week${weeksTo > 1 ? 's ' : ' '}` : '';
    timeTo += daysTo !== 0 ? `${daysTo} day${daysTo > 1 ? 's ' : ' '}` : '';
    if (timeTo) {
      return timeTo;
    }
    return `${hours}h ${minutes}m`;
  }
  return '';
};
export default computeTimeTo;
