import React from 'react';
import { MapContainer, ImageOverlay, Popup, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import _ from 'lodash';
import L from 'leaflet';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Truncate from 'react-truncate';
import firstAid from '../../../../assets/images/whitelabel/first-aid-training.svg';
import maintenance from '../../../../assets/images/whitelabel/maintenance.svg';
import evacuation from '../../../../assets/images/whitelabel/evacuation-emergency.svg';
import parking from '../../../../assets/images/whitelabel/fleet-parking-management.svg';
import ExtendMarker from './extendMarkerIndex';
import { DATE_FORMAT } from '../../../../constants';
import { getWorkDays } from '../../../../utils/weekDaysUtils';
import getLang from '../../../../utils/lang';

const lang = getLang() !== null ? getLang() : 'en';

/**
 * Load floor plan map and add markers
 */
function FloorPlanImage(props) {
  const map = useMap();
  const { whiteLabel, selectedFloorIndex } = props;
  const floorPlanImage = _.get(whiteLabel, `floor_plans.${selectedFloorIndex}.image_url`, null);
  const planHeight = _.get(whiteLabel, `floor_plans.${selectedFloorIndex}.height`, []);
  const planWidth = _.get(whiteLabel, `floor_plans.${selectedFloorIndex}.width`, []);

  const southWest = map.unproject([0, planHeight], 0);
  const northEast = map.unproject([planWidth, 0], 0);
  const bounds = new L.LatLngBounds(southWest, northEast);
  map.setMaxBounds(bounds);

  return <ImageOverlay url={floorPlanImage} bounds={bounds} />;
}

const getSkillIcon = skills => {
  let icon = null;
  let alt = null;
  const iconedSkills = [2, 3, 4, 6];
  const skill = _.get(
    skills.filter(skill => iconedSkills.includes(skill)),
    '0',
    null
  );
  switch (skill) {
    case 2:
      icon = firstAid;
      alt = 'first aid';
      break;
    case 3:
      icon = evacuation;
      alt = 'evacuation & emergency';
      break;
    case 4:
      icon = maintenance;
      alt = 'maintenance';
      break;
    case 6:
      icon = parking;
      alt = 'fleet & parking management';
      break;
    default:
      icon = null;
  }

  return icon && <img alt={alt} src={icon} />;
};

const buildDeskBooking = (booking, weekDaysMap) => {
  const heroName = `${_.get(booking, 'first_name', '')} ${_.get(booking, 'last_name', '')}`;
  const heroHeadline = _.get(booking, 'headline', '');
  const heroId = _.get(booking, 'hero_id', '');
  const from = _.replace(_.get(booking, 'from_date', ''), /-/g, '/');
  const to = _.replace(_.get(booking, 'to_date', ''), /-/g, '/');
  const bookingInterval =
    from === to
      ? _.upperFirst(
          moment(_.get(booking, 'from_date', ''))
            .locale(lang)
            .format(DATE_FORMAT)
        )
      : `${_.upperFirst(
          moment(_.get(booking, 'from_date', ''))
            .locale(lang)
            .format(DATE_FORMAT)
        )} - ${_.upperFirst(
          moment(_.get(booking, 'to_date', ''))
            .locale(lang)
            .format(DATE_FORMAT)
        )}`;
  const skills = _.get(booking, 'skills', null);
  const weekDays = _.get(booking, 'weekDays', '');
  const bookingDays = getWorkDays(weekDays, weekDaysMap);

  return (
    <div className="white-label__office-booked">
      <div className="white-label__office-booked--left">
        <span className="hero-headline">{bookingInterval}</span>
        <div className="hero-headline">
          <Truncate title={bookingDays} lines={1} ellipsis={<span className="hero-headline">...</span>} width={0}>
            {bookingDays}
          </Truncate>
        </div>
        <Link
          to={{
            pathname: `/colleague/details/${heroId}`,
            state: { stopFetch: true },
          }}
          replace
        >
          <div className="white-label__office-booked--right-desk">
            <span className="hero-name">{heroName}</span>
            {skills ? getSkillIcon(skills) : null}
          </div>
        </Link>
        <span className="hero-headline">{heroHeadline}</span>
      </div>
    </div>
  );
};

const buildMeetingRoomBooking = (interval, translations, calendarProvider) => {
  const booking = _.get(interval, 'booking', null);
  if (booking) {
    const heroName = `${_.get(booking, 'first_name', '')} ${_.get(booking, 'last_name', '')}`;
    const heroHeadline = _.get(booking, 'headline', '');
    const heroId = _.get(booking, 'hero_id', '');
    const from = _.get(booking, 'from_hour', '').substr(0, 5);
    const to = _.get(booking, 'to_hour', '').substr(0, 5);
    const bookingInterval = from === to ? from : `${from} - ${to}`;
    const skills = _.get(booking, 'skills', null);

    return (
      <div className="white-label__office-booked">
        <div className="white-label__office-booked--left">
          <span className="hero-headline">{bookingInterval}</span>
          <Link
            to={{
              pathname: `/colleague/details/${heroId}`,
              state: { stopFetch: true },
            }}
            replace
          >
            <span className="hero-name">{heroName}</span>
          </Link>
          <span className="hero-headline">{heroHeadline}</span>
        </div>
        <div className="white-label__office-booked--right">{skills ? getSkillIcon(skills) : null}</div>
      </div>
    );
  }
  const from = _.get(interval, 'from', '').substr(0, 5);
  const to = _.get(interval, 'to', '').substr(0, 5);
  const isFree = _.get(interval, 'free', null);
  const freeInterval = from === to ? from : `${from} - ${to}`;

  return (
    <div className="white-label__office-booked">
      <div className="white-label__office-booked--left">
        <span className="hero-headline">{freeInterval}</span>
        <span className="hero-name">
          {isFree
            ? _.get(translations, 'location.checkAvailability.table.available', 'location.checkAvailability.table.available')
            : _.get(translations, `location.checkAvailability.table.bookedIn${calendarProvider}`, 'location.checkAvailability.table.bookedInOutlook')}
        </span>
      </div>
    </div>
  );
};

class FloorPlan extends React.Component {
  constructor() {
    super();
    this.state = {
      floorPlanImage: null,
      products: [],
    };
    this.buildMarker = this.buildMarker.bind(this);
    this.getMeetingRoomIntervals = this.getMeetingRoomIntervals.bind(this);
  }

  buildMarker(marker) {
    const { setSelectedProductId, selectedProductId, bookings, selectedPin, selectedProductType } = this.props;
    let productType = '';
    switch (marker.productableType) {
      case 1:
        productType = 'desk';
        break;
      case 3:
        productType = 'meeting-room';
        break;
      case 5:
        productType = 'parking';
        break;
      default:
        break;
    }
    const markerId = _.get(marker, 'id', null);
    const coords = JSON.parse(marker.position);
    const filteredBookings = bookings.filter(booking => _.get(booking, 'product.id', null) === marker.id);
    const calendarIntervals = _.get(marker, 'calendar_busy_intervals', []);
    const intervals = productType === 'meeting-room' ? this.getMeetingRoomIntervals(filteredBookings, calendarIntervals) : [];
    const status = _.get(marker, 'status', null);
    const isDisabled = status === 'inactive';
    const isSelected = selectedProductId === markerId;
    const isPinSelected = selectedPin === markerId;
    const markerSizes = productType === 'meeting-room' ? [70, 30] : [21, 22];
    const customFeatures = _.get(marker, 'customFeature', '');

    let markerClass = '';
    if (isSelected && !isDisabled) {
      markerClass = 'selected-product';
    } else if (!isDisabled && productType === 'meeting-room' && intervals.filter(interval => _.get(interval, 'free', false)).length === 0) {
      markerClass = `booked-product`;
    } else {
      markerClass = `${status}-product`;
    }
    const icon = new L.divIcon({
      html: `<div class=" marker-icon-${productType} ${markerClass}" id="product-${markerId}"
        style=" -webkit-transform: rotate(${marker.orientation}deg);
        -moz-transform: rotate(${marker.orientation});
        -ms-transform: rotate(${marker.orientation}deg);
        -o-transform: rotate(${marker.orientation}deg);
        transform: rotate(${marker.orientation}deg);"/>`,
      iconSize: markerSizes,
    });
    return (
      <ExtendMarker
        isSelected={isPinSelected || isSelected}
        marker={marker.id}
        key={marker.id}
        icon={icon}
        position={coords}
        eventHandlers={{
          click: () => {
            if (!isDisabled) setSelectedProductId(markerId);
          },
        }}
      >
        {this.renderPopup(productType, filteredBookings, intervals, customFeatures, status)}
      </ExtendMarker>
    );
  }

  getMeetingRoomIntervals(bookings, calendarIntervals) {
    const { openingHour, openingMinute, closingHour, closingMinute } = this.props;
    const openingTime = `${openingHour}:${openingMinute}:00`;
    const closingTime = `${closingHour}:${closingMinute}:00`;
    let temp = openingTime;
    const intervals = [];
    // generate an array with all half hours available in a day
    const busyHalves = new Array(48).fill(false);
    // set the value which corresponds to a specific half to true if it is busy/booked in Outlook/Google
    calendarIntervals.forEach(calendarInterval => {
      const firstIndex = calendarInterval.fromHour * 2 + (calendarInterval.fromMinute % 29);
      const lastIndex = calendarInterval.toHour * 2 + (calendarInterval.toMinute % 29);
      for (let i = firstIndex; i < lastIndex; i++) {
        busyHalves[i] = true;
      }
    });

    bookings.forEach(function(booking) {
      if (temp !== _.get(booking, `from_hour`, '').toString()) {
        intervals.push({
          from: temp,
          to: _.get(booking, `from_hour`, '').toString(),
          free: true,
        });
      }
      intervals.push({
        booking,
        free: false,
      });
      temp = _.get(booking, `to_hour`, '').toString();
    });
    if (temp !== closingTime) {
      intervals.push({
        from: temp,
        to: closingTime,
        free: true,
      });
    }

    let toReturn = [...intervals];
    // the index where the temporary interval will be pushed
    let indexForIntervals = 0;
    let newInterval = null;
    // iterate through all intervals
    intervals.forEach((interval, key) => {
      const { from, to, free } = interval;
      // ..and modify only the free ones (the bookings made in Workero platform should not interfere)
      if (free) {
        const fromArray = from.split(':');
        const fromIndex = fromArray[0] * 2 + (fromArray[1] % 29);
        const toArray = to.split(':');
        const toIndex = toArray[0] * 2 + (toArray[1] % 29);
        // if there are any busy halfs between the start and the end of current free interval
        if (_.filter(busyHalves.slice(fromIndex, toIndex)).length) {
          // remove the current interval from array in order to add smaller ones
          toReturn = _.pull(toReturn, interval);
          // initialize a new interval
          newInterval = {
            from,
            free: !busyHalves[fromIndex],
          };
          let toHour;
          let toMinute;
          let toTime = null;
          for (let i = fromIndex + 1; i <= toIndex; i++) {
            toHour = Math.floor(i / 2) < 10 ? `0${Math.floor(i / 2)}` : Math.floor(i / 2);
            toMinute = i % 2 ? '30' : '00';
            toTime = `${toHour}:${toMinute}:00`;
            // when the temporary interval meets his end (i.e when a busy interval ends or starts)
            if (busyHalves[i] === newInterval.free) {
              // push the interval in array and
              toReturn.splice(indexForIntervals, 0, { ...newInterval, to: toTime });
              indexForIntervals += 1;
              // start with a fresh interval having an opposite status (free/busy)
              newInterval = {
                from: toTime,
                to: toTime,
                free: !busyHalves[i],
              };
            }
            // each iteration increases the interval with 30 minutes
            newInterval = { ...newInterval, to: toTime };
            if (i === toIndex && newInterval.from !== newInterval.to) {
              toReturn.splice(indexForIntervals, 0, newInterval);
            }
          }
        }
        indexForIntervals += 1;
      } else {
        indexForIntervals += 1;
      }
    });

    return toReturn;
  }

  getContentForDesk(filteredBookings, customFeatures, status, productType) {
    const { translations } = this.props;
    const deskLabel = _.get(translations, `booking.options.officeTypes.${productType}`, `booking.options.officeTypes.${productType}`);
    const content =
      filteredBookings.length && status === 'booked' ? (
        <div>
          {filteredBookings.map(booking => {
            return buildDeskBooking(booking, _.get(translations, 'location.weekDaysMap', []));
          })}
          {this.getCustomFeatures(customFeatures, translations)}
        </div>
      ) : (
        <>
          {this.renderProductAvailability(status === 'inactive', translations, deskLabel)}
          {this.getCustomFeatures(customFeatures, translations)}
        </>
      );

    return content;
  }

  renderProductAvailability(isDisabled, translations, label) {
    return (
      <div className="white-label__office-booked">
        <div className="white-label__office-booked--left">
          <span className="fully-booked">
            {!isDisabled
              ? _.get(translations, 'whiteLabel.productAvailable', '').replace('#product#', label)
              : _.get(translations, 'whiteLabel.productNotAvailable', '').replace('#product#', label)}
          </span>
        </div>
      </div>
    );
  }

  getContentForMeetingRoom(filteredBookings, customFeatures, intervals, status) {
    const { translations, ssoProvider } = this.props;
    const meetingRoomLabel = _.get(translations, 'booking.options.officeTypes.meeting', '');
    const isBooked = intervals.filter(interval => !interval.free).length > 0;
    const isDisabled = status === 'inactive';
    const calendarProvider = ssoProvider === 'Google' ? 'Google' : 'Outlook';

    const content = !isBooked ? (
      <>
        <div className="white-label__office-booked">
          <div className="white-label__office-booked--left">
            <span className="fully-booked">
              {!isDisabled
                ? _.get(translations, 'whiteLabel.productAvailable', '').replace('#product#', meetingRoomLabel)
                : _.get(translations, 'whiteLabel.productNotAvailable', '').replace('#product#', meetingRoomLabel)}
            </span>
          </div>
        </div>
      </>
    ) : (
      intervals.map(x => {
        return buildMeetingRoomBooking(x, translations, calendarProvider);
      })
    );

    return (
      <>
        {content}
        {this.getCustomFeatures(customFeatures, translations)}
      </>
    );
  }

  getCustomFeatures(customFeatures, translations) {
    return customFeatures ? (
      <>
        <div className="white-label__office-booked">
          <div className="white-label__office-booked--left">
            <span className="hero-name">{_.get(translations, 'whiteLabel.features', 'Features')}: </span>
            <div className="hero-headline">
              <Truncate title={customFeatures} lines={1} ellipsis={<span className="hero-headline">...</span>} width={0}>
                {customFeatures}
              </Truncate>
            </div>
          </div>
        </div>
      </>
    ) : null;
  }

  renderPopup(productType, filteredBookings, intervals, customFeatures, status) {
    const { selectedProductType } = this.props;
    let selectedProductTypeLabel = '';
    let content = '';

    switch (selectedProductType) {
      case 1:
        selectedProductTypeLabel = 'desk';
        content = this.getContentForDesk(filteredBookings, customFeatures, status, productType);
        break;
      case 5:
        selectedProductTypeLabel = 'parking';
        content = this.getContentForDesk(filteredBookings, customFeatures, status, productType);
        break;
      case 3:
        selectedProductTypeLabel = 'meeting-room';
        content = this.getContentForMeetingRoom(filteredBookings, customFeatures, intervals, status);
        break;
    }
    if (selectedProductTypeLabel !== productType) {
      return null;
    }

    return (
      <Popup autoClose closeButton={false}>
        {content}
      </Popup>
    );
  }

  render() {
    const { whiteLabel, selectedFloorIndex, mapProducts } = this.props;

    return (
      <div className="white-label__floor-plan">
        <MapContainer
          center={[0, 0]}
          zoom={0}
          minZoom={-2}
          maxZoom={2}
          scrollWheelZoom={false}
          attributionControl={false}
          doubleClickZoom={false}
          crs={L.CRS.Simple}
        >
          {mapProducts.map(this.buildMarker)}
          <FloorPlanImage whiteLabel={whiteLabel} selectedFloorIndex={selectedFloorIndex} />
        </MapContainer>
      </div>
    );
  }
}

export default FloorPlan;
