import _ from 'lodash';
import api from '../../components/dashboard/utils/api';
import { mediaArticleSelector } from '../../selectors/dashboard/media-article';
import { setMediaArticle } from './media-article';

export const saveHeroMediaFeedback = feedback => (dispatch, getState) => {
    api
        .post(`/media/feedback`, feedback)
        .then(() => {
            const mediaArticle = mediaArticleSelector(getState()).toJS();
            _.set(mediaArticle, 'vote', _.get(feedback, 'vote'));
            dispatch(setMediaArticle(mediaArticle));
        })
        .catch(() => {})
        .finally(() => {});
};
