import { connect } from 'react-redux';
import { heroProfileSelector, heroProfileIsLoadingSelector, heroProfileErrorsSelector } from '../../../selectors/hero-profile';
import { fetchHeroProfile, saveHeroProfile, setHeroProfile, setHeroProfileErrors } from '../../../actions/heroProfile';
import toJS from '../../dashboard/utils/to-js';
import addLoader from '../utils/add-loader';
import ProfileForm from './ProfileForm';
import { fetchProfile } from '../../../actions/accountActions';

const mapStateToProps = state => ({
  heroProfile: heroProfileSelector(state),
  isLoading: heroProfileIsLoadingSelector(state),
  errors: heroProfileErrorsSelector(state),
  translations: state.translations,
});

const mapDispatchToProps = dispatch => ({
  fetchHeroProfile: () => dispatch(fetchHeroProfile()),
  saveHeroProfile: args => dispatch(saveHeroProfile(args)),
  setHeroProfile: heroProfile => dispatch(setHeroProfile(heroProfile)),
  setHeroProfileErrors: errors => dispatch(setHeroProfileErrors(errors)),
  fetchProfile: () => dispatch(fetchProfile()),
});

export default connect(mapStateToProps, mapDispatchToProps)(addLoader(toJS(ProfileForm), true));
