import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

const OfficeHealthMeasures = props => {
  const { measures } = props;
  const measuresList = measures.map(measure => (
    <div key={measure.id + measure.name} className="rule-available">
      {_.capitalize(measure.name)}
    </div>
  ));

  if (measuresList.length > 0) {
    return (
      <div className="office-rules">
        <div className="office-rules-title">{_.get(props, 'trans.location.healthMeasure', 'healthMeasure')}</div>
        <div className="office-rules-list">{measuresList}</div>
      </div>
    );
  }
  return <div />;
};

OfficeHealthMeasures.propTypes = {
  measures: PropTypes.array.isRequired,
};

export default OfficeHealthMeasures;
