import _ from 'lodash';
import api from '../../components/dashboard/utils/api';
import parseMediaArticle from '../../components/dashboard/utils/parse-media-article';
import { mediaArticleSelector } from '../../selectors/dashboard/media-article';
import { buildTags, findValueInJson } from '../../components/dashboard/utils/map-news-article';
import ImageResizer from '../../utils/imageHelper';
import { verticalSelector } from '../../selectors/vertical-selector';
import { setMediaArticleIsLoading } from './media-article';
/*
 * Constants
 */
export const SET_SIMILAR_MEDIA = 'SET_SIMILAR_MEDIA';
export const SET_ADDITIONAL_MEDIA = 'SET_ADDITIONAL_MEDIA';
export const SET_SIMILAR_MEDIA_IS_LOADING = 'SET_SIMILAR_MEDIA_IS_LOADING';

/*
 * Sync Actions
 */
export const setSimilarMedia = similarMedia => ({
  type: SET_SIMILAR_MEDIA,
  similarMedia,
});

export const setAdditionalMedia = additionalMedia => ({
  type: SET_ADDITIONAL_MEDIA,
  additionalMedia,
});

export const setSimilarMediaIsLoading = isLoading => ({
  type: SET_SIMILAR_MEDIA_IS_LOADING,
  isLoading,
});

/*
 * Async Actions
 */
export const fetchSimilarMediaItems = (article, mediaTypeId) => (dispatch, getState) => {
  dispatch(setSimilarMediaIsLoading(true));
  const verticals = getState().global.get('globalVerticals');
  let selectedVerticalId = verticalSelector(getState());
  const articleVerticals = JSON.parse(_.get(article, 'verticals', null));
  if (_.includes(articleVerticals, selectedVerticalId) === false) {
    selectedVerticalId = _.get(articleVerticals, '0');
  }

  const selectedVertical = verticals.find(obj => {
    return obj.id === selectedVerticalId;
  });
  api
    .get(`/media/similar/id/${article.id}/vertical/${selectedVerticalId}/type/${mediaTypeId}`)
    .then(({ data = {} }) => {
      if (data.data) {
        dispatch(
          setSimilarMedia(
            data.data.map(item => {
              const params = JSON.parse(item.params);
              const title = findValueInJson(params, 'question.code', 'title');
              const tags = findValueInJson(params, 'question.code', 'tags');
              const logo = findValueInJson(params, 'question.code', 'logo');
              const image = findValueInJson(params, 'question.code', 'image');
              const verticalName = _.get(selectedVertical, 'name', '');
              return {
                id: item.id,
                title,
                tags: tags.map(buildTags).join(', '),
                image: ImageResizer(logo || image, 60, 60, item.id),
                verticalName,
              };
            })
          )
        );
      }
    })
    .catch(() => {})
    .finally(() => {
      dispatch(setSimilarMediaIsLoading(false));
    });
};

export const fetchAdditionalMediaItems = (article, mediaTypeId) => (dispatch, getState) => {
  dispatch(setSimilarMediaIsLoading(true));
  const verticals = getState().global.get('globalVerticals');
  let selectedVerticalId = verticalSelector(getState());
  const articleVerticals = JSON.parse(_.get(article, 'verticals', null));
  if (_.includes(articleVerticals, selectedVerticalId) === false) {
    selectedVerticalId = _.get(articleVerticals, '0');
  }
  const selectedVertical = verticals.find(obj => {
    return obj.id === selectedVerticalId;
  });
  api
    .get(`/media/similar/id/${article.id}/vertical/${selectedVerticalId}/type/${mediaTypeId}`)
    .then(({ data = {} }) => {
      if (data.data) {
        dispatch(
          setAdditionalMedia(
            data.data.map(item => {
              const params = JSON.parse(item.params);
              const title = findValueInJson(params, 'question.code', 'title');
              const tags = findValueInJson(params, 'question.code', 'tags');
              const logo = findValueInJson(params, 'question.code', 'logo');
              const image = findValueInJson(params, 'question.code', 'image');
              const verticalName = _.get(selectedVertical, 'name', '');
              return {
                id: item.id,
                title,
                tags: tags.map(buildTags).join(', '),
                image: ImageResizer(logo || image, 60, 60, item.id),
                verticalName,
              };
            })
          )
        );
      }
    })
    .catch(() => {})
    .finally(() => {
      dispatch(setSimilarMediaIsLoading(false));
    });
};
