import { connect } from 'react-redux';
import _ from 'lodash';
import { upcomingBookingsSelector, upcomingBookingsIsLoadingSelector } from '../../../../selectors/dashboard/upcoming-bookings';
import { fetchUpcomingBookings } from '../../../../actions/dashboard/upcoming-bookings';
import toJS from '../../utils/to-js';
import { translations } from '../../../../selectors/translations';
import addLoader from '../../utils/add-loader';
import UpcomingBookings from './UpcomingBookings';

const mapStateToProps = state => ({
  bookings: upcomingBookingsSelector(state),
  isLoading: upcomingBookingsIsLoadingSelector(state),
  translations: _.get(translations(state), 'dashboard', {})
});

const mapDispatchToProps = dispatch => ({
  fetchUpcomingBookings: () => dispatch(fetchUpcomingBookings()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(addLoader(toJS(UpcomingBookings), true));
