import { connect } from 'react-redux';
import _ from 'lodash';
import LanguageSelector from './languageSelector';
import { saveHeroProfile, setHeroProfile } from '../../../actions/heroProfile';
import { translations } from '../../../selectors/translations';

const mapStateToProps = state => ({
  heroProfile: state.global.get('heroProfile'),
  isAuthenticated: state.auth.get('isAuthenticated'),
  isWhiteLabel: state.global.get('isWhiteLabel'),
  translations: _.get(translations(state), 'languages', {}),
});
const mapDispatchToProps = dispatch => ({
  setHeroProfile: heroProfile => dispatch(setHeroProfile(heroProfile)),
  saveHeroProfile: args => dispatch(saveHeroProfile(args)),
});
export default connect(mapStateToProps, mapDispatchToProps)(LanguageSelector);
