import { connect } from 'react-redux';
import _ from 'lodash';
import toJS from '../../utils/to-js';

import Uploads from './Uploads';
import { uploadsSelector } from '../../../../selectors/community/community-uploads';
import { removeCommunityUpload } from '../../../../actions/community/community-uploads';
import { heroIsCommunityAdminSelector } from '../../../../selectors/community/community-page';

const mapStateToProps = state => ({
  uploads: uploadsSelector(state),
  isAdmin: heroIsCommunityAdminSelector(state),
});
const mapDispatchToProps = dispatch => ({
  removeCommunityUpload: id => dispatch(removeCommunityUpload(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(toJS(Uploads));
