import api from '../../components/community/utils/api';
import { fetchCommunityUploads } from './community-uploads';
import { fetchCommunityPost, fetchSinglePostPage } from './community-posts';

/*
 * Constants
 */
export const SET_COMMUNITY = 'SET_COMMUNITY';
export const SET_COMMUNITY_IS_LOADING = 'SET_COMMUNITY_IS_LOADING';
export const SET_HERO_IS_COMMUNITY_ADMIN = 'SET_HERO_IS_COMMUNITY_ADMIN';
export const SET_HERO_IS_COMMUNITY_MEMBER = 'SET_HERO_IS_COMMUNITY_MEMBER';

/*
 * Sync Actions
 */
export const setCommunity = community => ({
  type: SET_COMMUNITY,
  community,
});

export const setCommunityIsIsLoading = isLoading => ({
  type: SET_COMMUNITY_IS_LOADING,
  isLoading,
});
export const setHeroIsCommunityAdmin = isCommunityAdmin => ({
  type: SET_HERO_IS_COMMUNITY_ADMIN,
  isCommunityAdmin,
});
export const setHeroIsCommunityMember = isCommunityMember => ({
  type: SET_HERO_IS_COMMUNITY_MEMBER,
  isCommunityMember,
});

const afterFetchCommunity = () => dispatch => {
  dispatch(fetchCommunityUploads());
  dispatch(fetchCommunityPost());
};

/*
 * Async Actions
 */
export const fetchCommunity = (urlSlug, postId = null) => dispatch => {
  dispatch(setCommunityIsIsLoading(true));
  return new Promise((resolve, reject) => {
    api
      .get(`/get/${urlSlug}`)
      .then(({ data = {} }) => {
        if (data.data) {
          dispatch(setCommunity(data.data));
          dispatch(setHeroIsCommunityAdmin(data.data.isAdmin));
          dispatch(setHeroIsCommunityMember(data.data.isCommunityMember));
          postId
            ? dispatch(fetchSinglePostPage(urlSlug, postId))
                .then()
                .catch(response => {
                  reject(response);
                })
            : dispatch(afterFetchCommunity());
        }
      })
      .catch(({ response }) => {
        reject(response.data.error);
      })
      .finally(() => {
        dispatch(setCommunityIsIsLoading(false));
      });
  });
};
