import Immutable, { fromJS } from 'immutable';
import { SET_MEDIA_FILTERS, SET_MEDIA_FILTERS_IS_LOADING, SET_MEDIA_FILTER } from '../../actions/dashboard/media-filters';

const initialState = Immutable.Map({
  mediaFilters: fromJS([]),
  mediaFilter: 'all',
  isLoading: false,
});

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_MEDIA_FILTERS:
      return state.setIn(['mediaFilters'], fromJS(action.mediaFilters));
    case SET_MEDIA_FILTER:
      return state.setIn(['mediaFilter'], action.mediaFilter);
    case SET_MEDIA_FILTERS_IS_LOADING:
      return state.setIn(['isLoading'], action.isLoading);
    default:
      return state;
  }
};
