import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import PropTypes from 'prop-types';
import DefaultPaymentProfile from './DefaultPaymentProfile';

class PaymentProfile extends Component {
  constructor(props) {
    super(props);
    this.renderSpendingLimit = this.renderSpendingLimit.bind(this);
    this.renderBuildProfileButton = this.renderBuildProfileButton.bind(this);
    this.renderDiscountVoucher = this.renderDiscountVoucher.bind(this);
  }

  renderSpendingLimit() {
    const {
      trans,
      formatString,
      useSpendingLimit,
      handleUseSpendingLimitChange,
      spendingLimit,
      bookingPrice,
      paymentMethod,
      voucherInfo,
    } = this.props;
    const vat = _.get(window.__env, 'vat', 1.21);
    const paymentMethodType = _.get(paymentMethod, 'type', null);
    if (paymentMethodType === 'voucher') {
      return null;
    }
    const amount = _.get(spendingLimit, 'amount', 0);
    const voucherValue = _.get(voucherInfo, 'discount.value', 0);
    const voucherValueVatExcluded = _.ceil(voucherValue / vat, 2);
    const payableByCompany = bookingPrice > voucherValueVatExcluded ? _.ceil(bookingPrice - voucherValueVatExcluded, 2) : 0;
    const insufficientSpendingLimit = payableByCompany > amount;
    const paidAmount = insufficientSpendingLimit ? amount : payableByCompany;
    const insufficientLabel =
      useSpendingLimit && insufficientSpendingLimit ? (
        <div className="payment-section__spending-limit--insufficient">
          {_.get(
            trans,
            'payment.insufficientSpendingLimit',
            `Your spending limit does not cover the total cost of the booking. We'll charge the rest of the amount from your chosen payment method`
          )}
        </div>
      ) : null;
    const spendingLabel = formatString(
      _.get(trans, 'payment.useSpendingLimitFromAvailable', `Use {0}€ from your {1}€ available`),
      paidAmount,
      amount
    );

    return amount ? (
      <div className="payment-section__spending-limit">
        <div className="payment-section__subtitle">{_.get(trans, 'payment.spendingLimit', 'Spending limit')}</div>

        <div className="payment-section__spending-checkbox">
          <label className="card-checkbox--label">
            <input type="checkbox" name="default" value="default" checked={useSpendingLimit} onChange={handleUseSpendingLimitChange} />
            <span className="card-checkbox--span">{spendingLabel}</span>
          </label>
        </div>
        {insufficientLabel}
      </div>
    ) : null;
  }

  renderBuildProfileButton() {
    const { trans, history, location } = this.props;

    return (
      <div className="payment-section__build-profile">
        <div
          className="booking-options-finish"
          onClick={() => {
            history.push({ pathname: '/booking/payment', state: { from: location.pathname } });
          }}
        >
          {_.get(trans, 'payment.buildYourPaymentProfile', 'Build your payment profile')}
        </div>
      </div>
    );
  }

  renderDiscountVoucher() {
    const {
      voucherInfo,
      voucherCode,
      voucherApplied,
      showVoucherLoader,
      bookingValidate,
      trans,
      handleVoucherCodeChange,
      redeemVoucher,
      removeVoucher,
      formatString,
      paymentInProgress
    } = this.props;
    let voucherError = null;
    let loader = null;
    if (voucherInfo !== null) {
      if (!_.get(voucherInfo, 'valid') && !voucherApplied) {
        const voucherErrors = voucherInfo
          ? _.get(voucherInfo, 'errors', {}).map(error => {
              return (
                <div key={error} className="error-item">
                  {error}
                </div>
              );
            })
          : null;
        voucherError = <div className="voucher-errors-container">{voucherErrors}</div>;
      }
    }

    if (showVoucherLoader === true) {
      loader = <div className="LoaderInverseSmall" />;
      voucherError = null;
    } else {
      loader = null;
    }

    if (_.get(bookingValidate, 'price.paidAmountVoucher', 0) !== 0 && voucherInfo) {
      const { discount, code } = voucherInfo;
      const { value } = discount;
      const lastFourChars = code.substr(code.length - 4);
      return (
        <div className="payment-section__voucher">
          <div className="payment-section__voucher-subtitle">{_.get(trans, 'payment.voucherBalance', 'Voucher balance')}</div>
          <div className="payment-section__voucher-title">
            {formatString(_.get(trans, 'payment.useYourDiscountVoucher', `Use your {0}€ discount voucher`), value)}
          </div>
          <div className="booking-footer-item">
            <div className="payment-section__voucher-subtitle">
              {`${_.get(trans, 'payment.voucher', 'Voucher')}: ${_.get(trans, 'payment.hiddenCode', 'xxx-xxxxxxx-')}${lastFourChars}`}
            </div>
            <span onClick={removeVoucher} className="payment-section__voucher-remove">
              {_.get(trans, 'payment.deleteVoucher', 'Remove voucher')}
            </span>
          </div>
        </div>
      );
    }

    if (_.get(bookingValidate, 'price.totalPriceVatIncluded', 0) === 0 && _.get(bookingValidate, 'price.subscriptionApplied', 0) === true) {
      return null;
    }
    return (
      <div>
        <div style={loader !== null ? { display: 'none' } : {}}>
          <div className="payment-section__subtitle">
            {_.get(trans, 'payment.doYouHaveADiscountVoucherCode', 'Do you have a discount voucher code?')}
          </div>
          <div className="booking-footer-item">
            <span className="item-label">
              <input
                className="voucher-input"
                value={voucherCode}
                type="text"
                disabled={paymentInProgress}
                placeholder={_.get(trans, 'payment.enterVoucherCodeHere', 'Enter voucher code here')}
                onChange={event => handleVoucherCodeChange(event.target.value)}
              />
            </span>
            <span className="item-value">
              <button
                disabled={voucherCode === ''  || paymentInProgress ? 'disabled' : ''}
                className="voucher-redeem-button secondary-button"
                onClick={redeemVoucher}
              >
                {_.get(trans, 'payment.redeem')}
              </button>
            </span>
          </div>
        </div>
        {voucherError}
        {loader}
      </div>
    );
  }

  render() {
    const {
      trans,
      paymentMethod,
      heroProfile,
      modifyPaymentMethod,
      formatString,
      spendingLimit,
      useSpendingLimit,
      bookingPrice,
      voucherInfo,
    } = this.props;
    const paymentMethodType = _.get(paymentMethod, 'type', null);
    const showDiscountVoucher = paymentMethodType !== 'voucher';
    const spendingLimitAmount = _.get(spendingLimit, 'amount', 0);
    const voucherAmount = _.get(voucherInfo, 'discount.value', 0);
    const vat = _.get(window.__env, 'vat', 1.21);
    const voucherAmountVatExcluded = _.ceil(voucherAmount / vat, 2);
    const showPaymentProfile = !useSpendingLimit || spendingLimitAmount + voucherAmountVatExcluded < bookingPrice || !showDiscountVoucher;

    return (
      <div className="payment-section__container">
        <div className="payment-section__title">{_.get(trans, 'payment.paymentProfile.paymentOptions')}</div>
        {this.renderSpendingLimit()}
        {!_.isEmpty(paymentMethod, true) ? (
          showPaymentProfile ? (
            <DefaultPaymentProfile
              trans={trans}
              paymentMethod={paymentMethod}
              heroProfile={heroProfile}
              modifyPaymentMethod={modifyPaymentMethod}
              formatString={formatString}
            />
          ) : null
        ) : (
          this.renderBuildProfileButton()
        )}
        {showDiscountVoucher ? this.renderDiscountVoucher() : null}
      </div>
    );
  }
}

PaymentProfile.propTypes = {
  trans: PropTypes.shape({}),
  formatString: PropTypes.func,
  paymentMethod: PropTypes.shape({}),
  voucherInfo: PropTypes.shape({ discount: PropTypes.shape({ value: PropTypes.number }), code: PropTypes.string }),
  voucherCode: PropTypes.string,
  voucherApplied: PropTypes.bool,
  showVoucherLoader: PropTypes.bool,
  bookingValidate: PropTypes.shape({}),
  bookingPrice: PropTypes.string,
  heroProfile: PropTypes.shape({}),
  useSpendingLimit: PropTypes.bool,
  spendingLimit: PropTypes.shape({}),
  handleUseSpendingLimitChange: PropTypes.func,
  handleVoucherCodeChange: PropTypes.func,
  redeemVoucher: PropTypes.func,
  removeVoucher: PropTypes.func,
  modifyPaymentMethod: PropTypes.func,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

PaymentProfile.defaultProps = {
  trans: {},
  paymentMethod: {},
  voucherInfo: {},
  voucherCode: null,
  voucherApplied: false,
  showVoucherLoader: false,
  bookingValidate: {},
  bookingPrice: '0',
  heroProfile: {},
  useSpendingLimit: true,
  spendingLimit: {},
  handleUseSpendingLimitChange: () => {},
  handleVoucherCodeChange: () => {},
  redeemVoucher: () => {},
  removeVoucher: () => {},
  modifyPaymentMethod: () => {},
  formatString: () => {},
};

export default withRouter(PaymentProfile);
