import React from 'react';
import PropTypes from 'prop-types';
import StarRating from '../starRating/starRating';

class OfficeReviews extends React.Component {

  constructor() {
    super();

    this.state ={
      expanded: false
    };
  }

  onToggleClick = () => {
    this.setState({ expanded: !this.state.expanded});
  }

  renderToggleButton = () => {
    let toggleClass = 'collapsed',
        toggleLabel = 'Expand';

    if(this.state.expanded) {
      toggleClass = 'expanded';
      toggleLabel = 'Collapse';
    }

    if(this.props.reviews.length > 3)
    return (
      <div
        className={`office-reviews-${toggleClass}`}
        onClick={this.onToggleClick}>{toggleLabel}</div>
    );
  }

  renderReview = (review) => {
    return (
      <div>
        <div className="office-reviews-header">
          <div className="office-reviews-user">
            <span className="thumb" style={{ backgroundImage: `url(${review.hero.imageUrl})`, backgroundRepeat: 'no-repeat'}}></span>
            <div className="details">
              <div className="details-name">{review.hero.firstName} {review.hero.lastName}</div>
              <div className="details-company">{review.userCompany}</div>
            </div>
          </div>
          <div className="office-reviews-info">
            <span className="label">Share comment on</span>
            <span className="share-facebook"></span>
            <span className="share-twitter"></span>
            <span className="share-linkedin"></span>
            <span className="rating">
              <StarRating initialRate={review.rating} theme={'theme-yellow-small'} readonly={true} />
            </span>
            <span className="office-reviews-reply">Reply</span>
          </div>
        </div>
        <div className="office-reviews-content">
          <span>{review.text}</span>
        </div>
      </div>
    )
  }

  renderHiddenReviews = (reviews) => {
    if (this.state.expanded === false) return;

    return reviews.map(review => this.renderReview(review));
  }

  render () {
    let [review, ...otherReviews] = this.props.reviews,
        reviewsTotal = this.props.reviews.length;

    return (
      <div className="office-reviews">
        <div className="office-reviews-title">Reviews ({reviewsTotal})</div>
        {this.renderReview(review)}
        {this.renderHiddenReviews(otherReviews)}
        {this.renderToggleButton()}
      </div>
    )
  }
}

OfficeReviews.propTypes = {
  reviews: PropTypes.array.isRequired,
};

export default OfficeReviews;
