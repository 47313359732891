import { connect } from 'react-redux';
import PopularCommunities from './PopularCommunities';
import toJS from '../../utils/to-js';
import addLoader from '../../../dashboard/utils/add-loader';

import { fetchPopularCommunities } from '../../../../actions/community/popular-communities';

import {
  popularCommunitiesSelector,
  popularCommunitiesIsLoadingSelector,
  loadMoreCommunitiesOffsetSelector,
  hasMoreCommunitiesSelector,
  popularCommunitiesCountSelector,
  popularCommunitiesIsLoadingMoreSelector,
} from '../../../../selectors/community/popular-communities';

import { joinCommunityErrorsSelector } from '../../../../selectors/community/join-community';

const mapStateToProps = state => ({
  popularCommunities: popularCommunitiesSelector(state),
  popularCommunitiesCount: popularCommunitiesCountSelector(state),
  loadMoreCommunitiesOffset: loadMoreCommunitiesOffsetSelector(state),
  isLoadingMore: popularCommunitiesIsLoadingMoreSelector(state),
  hasMoreCommunities: hasMoreCommunitiesSelector(state),
  joinCommunityErrors: joinCommunityErrorsSelector(state),
  isLoading: popularCommunitiesIsLoadingSelector(state),
});

const mapDispatchToProps = dispatch => ({
  fetchPopularCommunities: () => dispatch(fetchPopularCommunities()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(toJS(addLoader(PopularCommunities)));
