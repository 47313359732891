import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';

const ProfileButtons = props => {
  const { isTop, profileCompleted, addPositionSection, saveHeroProfileData, history, location, translations, bookingValidate } = props;
  // check if redirect from booking
  let firstButton = _.get(translations, 'profile.addExperience', 'ADD MORE EXPERIENCE');
  let secondButton = profileCompleted ? _.get(translations, 'profile.save', '') : _.get(translations, 'profile.continue', '');
  const fromUrlForSignup = _.get(location, 'state.from', _.get(location, 'fromUrl', '/'));
  const newAccount = _.get(location, 'state.newAccount', false);
  const redirectFromBooking = _.get(location, 'state.fromBooking', false);
  let fromSignup = false;
  let fromBooking = false;
  let redirectTo = null;
  if (location.fromUrl && redirectFromBooking) {
    firstButton = _.get(translations, 'paymentProfile.continueToPayment', '');
    secondButton = _.get(translations, 'paymentProfile.bookLater', '');
    fromBooking = true;
    redirectTo = '/booking/payment';
    // treat corner case with event room
    if (bookingValidate.size && bookingValidate.toJS()) {
      const officeTypeId = _.get(bookingValidate.toJS(), 'officeTypeId', 0);
      if (officeTypeId === 4) {
        firstButton = _.get(translations, 'paymentProfile.continueToBooking', '');
        redirectTo = location.fromUrl;
      }
    }
  } else if (newAccount || (fromUrlForSignup && fromUrlForSignup.split('/')[1] === 'sign-up')) {
    firstButton = _.get(translations, 'profile.continue', '');
    secondButton = _.get(translations, 'profile.updateLater', '');
    fromSignup = true;
    redirectTo = location.fromUrl !== '/sign-up' ? location.fromUrl : '/';
  }

  return (
    <div className={classnames('profile-form__row', { 'profile-form__top-buttons': isTop })}>
      <button
        className="add-experience__button profile-form__button"
        type="submit"
        onClick={event => {
          event.preventDefault();
          event.stopPropagation();
          if (fromBooking || fromSignup) {
            saveHeroProfileData(redirectTo);
            return;
          }
          addPositionSection();
        }}
      >
        {firstButton}
      </button>
      <button
        className="save-information__button profile-form__button"
        type="submit"
        onClick={event => {
          event.preventDefault();
          event.stopPropagation();
          if (fromBooking || fromSignup) {
            history.replace(location.fromUrl);
            return;
          }
          saveHeroProfileData(location.fromUrl);
        }}
      >
        {secondButton}
      </button>
    </div>
  );
};

ProfileButtons.propTypes = {
  isTop: PropTypes.bool,
  profileCompleted: PropTypes.bool,
  addPositionSection: PropTypes.func,
  saveHeroProfileData: PropTypes.func,
};

ProfileButtons.defaultProps = {
  isTop: false,
  profileCompleted: false,
  addPositionSection: () => null,
  saveHeroProfileData: () => null,
};

export default withRouter(ProfileButtons);
