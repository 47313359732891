import React from 'react';
import _ from 'lodash';

const Footer = props => {
  const year = new Date().getFullYear();
  const { translations } = props;
  return (
    <div className="open-login__footer">
      <div className="intro">
        <span className="service">{_.get(translations, 'footer.serviceProvidedBy', 'footer.serviceProvidedBy')}</span>
        <span className="copyright active">{`© Workero ${year}`}</span>
      </div>
      <div className="list-inline-dot">
        <a href={`${window.__env.public_site}/privacy`} target="_blank" rel="noopener noreferrer">
          {_.get(translations, 'footer.privacy', 'footer.privacy')}
        </a>
        <a className="bulletDot" />
        <a
          href="https://www.iubenda.com/terms-and-conditions/82572254"
          target="_blank"
          rel="noopener noreferrer"
        >
          {_.get(translations, 'footer.terms', 'footer.terms')}
        </a>
        <a className="bulletDot" />
        <a href={`${window.__env.public_site}/cookies`} target="_blank" rel="noopener noreferrer">
          {_.get(translations, 'footer.cookie', 'footer.cookie')}
        </a>
      </div>
    </div>
  );
};

export default Footer;
