import { connect } from 'react-redux';
import _ from 'lodash';
import PaymentProfile from './PaymentProfile';
import './index.scss';

const mapStateToProps = state => ({
  trans: _.get(state, 'translations.booking'),
  formatString: state.translations ? state.translations.formatString : () => {},
  heroProfile: state.global.get('heroProfile'),
  spendingLimit: state.addBooking.get('spendingLimit'),
});

const mapDispatchToProps = dispatch => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentProfile);
