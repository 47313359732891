import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import Truncate from 'react-truncate';
import PropTypes from 'prop-types';
import _ from 'lodash';
import contactMember from '../../../../assets/svg/community/contact-member-icon.svg';
import contactMemberHover from '../../../../assets/svg/community/contact-member-icon--hover.svg';
import CommunityModal from '../../common/modals';
import InviteMembersModal from '../invite-members-modal';
import SeeAllMembersModal from '../see-all-members-modal';

const breakPoints = {
  'full-resolution': {
    maxResolution: null,
    minResolution: 1900,
    nrIcons: 10,
  },
  'medium-resolution': {
    maxResolution: 1900,
    minResolution: 1500,
    nrIcons: 9,
  },
  'small-resolution': {
    maxResolution: 1500,
    minResolution: 1350,
    nrIcons: 7,
  },
  'micro-resolution': {
    maxResolution: 1350,
    minResolution: null,
    nrIcons: 6,
  },
};

const getBreakPointKey = () => {
  const width = window.innerWidth;
  for (const x in breakPoints) {
    const breakPoint = breakPoints[x];

    if (!breakPoint.maxResolution && width > breakPoint.minResolution) {
      return x;
    }

    if (!breakPoint.minResolution && width <= breakPoint.maxResolution) {
      return x;
    }

    if (breakPoint.minResolution < width && breakPoint.maxResolution >= width) {
      return x;
    }
  }
  return null;
};

const buildMembersNameList = members => {
  if (!members) {
    return null;
  }
  const nameList = members
    .slice(0, 2)
    .reduce(
      (acumulator, currentValue, index) =>
        `${acumulator + (index !== 0 ? ', ' : '') + _.get(currentValue, 'firstName', '')} ${_.get(currentValue, 'lastName', '')}`,
      ''
    );

  return nameList;
};

class CommunityInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      descriptionSeeAll: false,
      breakPointKey: null,
      showInviteMembersModal: false,
      showAllMembersModal: false,
      showLeaveCommunityModal: false,
    };

    this.toggleDescriptionSeeAll = this.toggleDescriptionSeeAll.bind(this);
    this.renderMemberIcon = this.renderMemberIcon.bind(this);
    this.renderAdminMember = this.renderAdminMember.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.renderMembersCounter = this.renderMembersCounter.bind(this);
    this.renderInviteMembersModal = this.renderInviteMembersModal.bind(this);
    this.renderSeeAllMembersModal = this.renderSeeAllMembersModal.bind(this);
    this.setShowAllMembersModal = this.setShowAllMembersModal.bind(this);
    this.handleLeaveCommunityModal = this.handleLeaveCommunityModal.bind(this);
  }

  componentDidMount() {
    const breakPointNewKey = getBreakPointKey();
    this.setState({ breakPointKey: breakPointNewKey });
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize() {
    const { breakPointKey } = this.state;
    const breakPointNewKey = getBreakPointKey();

    if (breakPointNewKey === breakPointKey) {
      return;
    }
    this.setState({ breakPointKey: breakPointNewKey });
  }

  renderMemberIcon(member) {
    const { heroProfile, history } = this.props;
    const currentHeroId = _.get(heroProfile, 'id', null);
    const avatar = _.get(member, 'profilePictureUrl', null);
    const firstName = _.get(member, 'firstName', '');
    const lastName = _.get(member, 'lastName', '');
    const memberId = _.get(member, 'id', null);
    const heroInitials = _.get(member, 'heroInitials', null);
    return (
      <div
        key={memberId}
        onClick={() => {
          history.push(memberId === currentHeroId ? '/account/profile' : `/connection/${memberId}`);
        }}
      >
        {avatar ? (
          <span className="community-info-member-icon" title={`${firstName} ${lastName}`} style={{ backgroundImage: `url("${avatar}")` }} />
        ) : (
          <span className="hero-initials members-hero-initials">{heroInitials}</span>
        )}
      </div>
    );
  }

  renderMembersCounter(members) {
    const { breakPointKey } = this.state;
    const numberOfIconMembers = _.get(breakPoints, `${breakPointKey}.nrIcons`, 10);

    const memberCount = members.length - numberOfIconMembers;
    if (members.length <= numberOfIconMembers) {
      return null;
    }

    return <div className="community-info-member-counter">+{memberCount}</div>;
  }

  renderAdminMember(admin) {
    const { translations, heroProfile, history } = this.props;
    const avatar = _.get(admin, 'profilePictureUrl', null);
    const firstName = _.get(admin, 'firstName', '');
    const lastName = _.get(admin, 'lastName', '');
    const headline = _.get(admin, 'headline', '');
    const adminLabel = _.get(translations, 'admin', 'admin');
    const adminId = _.get(admin, 'id', null);
    const currentHeroId = _.get(heroProfile, 'id', null);
    const displayChatButton = currentHeroId !== adminId;
    const heroInitials = _.get(admin, 'heroInitials', null);
    return (
      <div
        key={adminId}
        className="community-info-admin__container"
        onClick={() => {
          history.push(adminId === currentHeroId ? '/account/profile' : `/connection/${adminId}`);
        }}
      >
        <div className="community-info-admin__info">
          <div className="community-info-admin__avatar-container">
            {avatar ? (
              <img className="community-info-admin__avatar" src={avatar} alt={`${firstName} ${lastName}`} />
            ) : (
              <div className="hero-initials community-info-admin-hero-initials">{heroInitials}</div>
            )}
          </div>
          <div className="community-info-admin__details">
            <div className="community-info-admin__name">
              {`${firstName} ${lastName}`} <span className="community-info-admin__label">{adminLabel}</span>
            </div>
            <div className="community-info-admin__headline" title={headline}>
              <Truncate lines={2} ellipsis={<>...</>}>
                {headline}
              </Truncate>
            </div>
          </div>
        </div>
        {displayChatButton && (
          <button
            type="button"
            className="community-info__message-button"
            onClick={e => {
              this.goToDirectChat(admin);
              e.stopPropagation();
            }}
          >
            <img className="community-info__message-icon" src={contactMember} alt="" />
            <img className="community-info__message-icon--hover" src={contactMemberHover} alt="" />
          </button>
        )}
      </div>
    );
  }

  goToDirectChat(hero) {
    const { openConversation, history } = this.props;
    openConversation(_.get(hero, 'id', null), hero);
    history.push('/direct-chat');
  }

  setShowAllMembersModal(value) {
    this.setState({ showAllMembersModal: value });
  }

  renderSeeAllMembersModal() {
    const { translations, community, heroProfile } = this.props;
    const members = _.get(community, 'members', []);
    const membersCount = members.length;
    const modalTitle =
      membersCount === 1
        ? `1 ${_.upperFirst(_.get(translations, 'member', 'member'))}`
        : `${membersCount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} ${_.upperFirst(_.get(translations, 'members', 'members'))}`;
    return (
      <CommunityModal
        title={modalTitle}
        wrapperClasses="community-modal__sectioned"
        translations={translations}
        body={
          <SeeAllMembersModal
            translations={translations}
            closeForm={() => {
              this.setShowAllMembersModal(false);
            }}
            members={members}
            heroProfile={heroProfile}
          />
        }
        closeModal={() => {
          this.setShowAllMembersModal(false);
        }}
      />
    );
  }

  renderInviteMembersModal() {
    const { translations } = this.props;
    const formTitle = _.get(translations, 'inviteMembersModal.title', 'inviteMembersModal.title');
    return (
      <CommunityModal
        title={formTitle}
        wrapperClasses="community-modal__sectioned"
        body={
          <InviteMembersModal
            translations={translations}
            closeForm={() => {
              this.setState({ showInviteMembersModal: false });
            }}
          />
        }
        closeModal={() => {
          this.setState({ showInviteMembersModal: false });
        }}
      />
    );
  }

  renderInvitationSection() {
    const { community, translations } = this.props;
    const inviteMembers = _.get(translations, 'inviteMembers', 'inviteMembers');
    const audienceType = _.get(community, 'audienceType', null);
    const isAdmin = _.get(community, 'isAdmin', false);
    if (audienceType !== 'closed' || !isAdmin) {
      return null;
    }
    return (
      <div className="community-info__invites">
        <button
          className="button-yellow community-info__invite-button"
          type="button"
          onClick={() => this.setState({ showInviteMembersModal: true })}
        >
          {inviteMembers}
        </button>
      </div>
    );
  }

  renderJoinSection() {
    const { community, translations, joinCommunity } = this.props;

    return (
      <div className="community-info__invites">
        <button className="button-yellow community-info__invite-button" type="button" onClick={() => joinCommunity(community.urlSlug)}>
          {_.get(translations, 'joinComunity', 'Join community')}
        </button>
      </div>
    );
  }

  renderCommunityAudience() {
    const { community, translations } = this.props;
    const audienceType = _.get(community, 'audienceType', null);
    const verticals = _.get(community, 'verticals', []);

    if (!audienceType) {
      return null;
    }

    switch (audienceType) {
      case 'public':
        return _.get(translations, 'audienceTypePublic', 'audienceTypePublic');
      case 'specific_verticals':
        return (
          <>
            <div>{_.get(translations, 'audienceTypeSpecificVerticals', 'audienceTypeSpecificVerticals')}</div>
            <div className="community-info-audience--verticals">{verticals.join(', ')}</div>
          </>
        );
      default:
        return _.get(translations, 'audienceTypeSpecificHeroes', 'audienceTypeSpecificHeroes');
    }
  }

  toggleDescriptionSeeAll() {
    const { descriptionSeeAll } = this.state;
    this.setState({ descriptionSeeAll: !descriptionSeeAll });
  }

  renderCommunityDescription() {
    const { descriptionSeeAll } = this.state;
    const { community, translations } = this.props;
    const communityDescription = _.get(community, 'description', '');
    const readMore = _.get(translations, 'readMore', 'readMore');
    const showLess = _.get(translations, 'showLess', 'showLess');

    let descriptionContainer = null;
    if (communityDescription) {
      if (!descriptionSeeAll) {
        descriptionContainer = (
          <>
            <Truncate
              lines={2}
              ellipsis={
                <>
                  ...{' '}
                  <button onClick={this.toggleDescriptionSeeAll} className="button__text-link expand-button__text-link" type="button">
                    {readMore}
                  </button>
                </>
              }
            >
              {communityDescription}
            </Truncate>
          </>
        );
      } else {
        descriptionContainer = (
          <>
            {communityDescription}
            <button onClick={this.toggleDescriptionSeeAll} className="button__text-link expand-button__text-link" type="button">
              {showLess}
            </button>
          </>
        );
      }
    }

    return <div className="description">{descriptionContainer}</div>;
  }

  handleLeaveCommunityModal() {
    const { showLeaveCommunityModal } = this.state;
    this.setState({ showLeaveCommunityModal: !showLeaveCommunityModal });
  }

  renderLeaveCommunityModal() {
    const { translations, community, leaveCommunity, history, clearPopularCommunities } = this.props;
    const formTitle = _.get(translations, 'leaveCommunityModal.title', 'leaveCommunityModal.title');
    const communityId = _.get(community, 'id', null);
    return (
      <CommunityModal
        title={formTitle}
        closeModal={this.handleLeaveCommunityModal}
        wrapperClasses="community-modals community-confirmation-modal__wrapper"
        body={
          <div className="community-confirmation-modal__container">
            <div className="community-confirmation-modal__confirmation-message">
              {_.get(translations, 'leaveCommunityModal.confirmationMessage', 'leaveCommunityModal.confirmationMessage')}
            </div>

            <div className="community-confirmation-modal__actions">
              <button
                type="button"
                onClick={this.handleLeaveCommunityModal}
                id="community-confirmation-modal__cancel-button"
                className="community-confirmation-modal__cancel-button"
              >
                {_.get(translations, 'deleteCommunityModal.cancelButton', 'deleteCommunityModal.cancelButton')}
              </button>

              <button
                id="community-confirmation-modal__save-button"
                type="button"
                className="button-yellow community-confirmation-modal__save-button community-confirmation-modal__save-button--more-padding"
                onClick={() =>
                  leaveCommunity(communityId).then(status => {
                    if (status) {
                      clearPopularCommunities();
                      history.push({
                        pathname: '/communities',
                        state: { showCommunityLeftToast: true },
                      });
                    }
                  })
                }
              >
                {_.get(translations, 'leaveCommunityModal.leave', 'leaveCommunityModal.leave')}
              </button>
            </div>
          </div>
        }
      />
    );
  }

  renderLeaveCommunityAdminModal() {
    const {
      translations,
      showCommunityEditModal,
      setShowEditCommunityModal,
      showCommunityManageModal,
      setShowManageCommunityModal,
    } = this.props;
    const formTitle = _.get(translations, 'leaveCommunityModal.warning', 'leaveCommunityModal.warning');
    return (
      <CommunityModal
        title={formTitle}
        closeModal={this.handleLeaveCommunityModal}
        wrapperClasses="community-modals community-confirmation-modal__wrapper"
        body={
          <div className="community-confirmation-modal__container">
            <div className="community-confirmation-modal__confirmation-message community-confirmation-modal__confirmation-message--large">
              {_.get(translations, 'leaveCommunityModal.adminMessage', 'leaveCommunityModal.adminMessage')}
              <br />
              {_.get(translations, 'leaveCommunityModal.actionsMessage', 'leaveCommunityModal.actionsMessage')}
            </div>

            <div className="community-confirmation-modal__actions">
              <button
                type="button"
                onClick={() => {
                  this.handleLeaveCommunityModal();
                  setShowEditCommunityModal(!showCommunityEditModal);
                }}
                id="community-confirmation-modal__delete-button"
                className="community-confirmation-modal__delete-button"
              >
                {_.get(translations, 'editForm.deleteButton', 'editForm.deleteButton')}
              </button>
              <button
                type="button"
                onClick={this.handleLeaveCommunityModal}
                id="leave-community__cancel-button"
                className="community-confirmation-modal__cancel-button"
              >
                {_.get(translations, 'deleteCommunityModal.cancelButton', 'deleteCommunityModal.cancelButton')}
              </button>

              <button
                id="leave-community__save-button"
                type="button"
                className="button-yellow community-confirmation-modal__save-button"
                onClick={() => {
                  this.handleLeaveCommunityModal();
                  setShowManageCommunityModal(!showCommunityManageModal);
                }}
              >
                {_.get(translations, 'leaveCommunityModal.transferAdminRights', 'leaveCommunityModal.transferAdminRights')}
              </button>
            </div>
          </div>
        }
      />
    );
  }

  unblockedAdminsCount(admins) {
    if (admins) {
      const counter = (acc, current) => acc + (_.get(current, 'blocked', 1) === 0 ? 1 : 0);
      return admins.reduce(counter, 0);
    }
    return 0;
  }

  render() {
    const { community, translations, heroIsCommunityMember } = this.props;
    const { breakPointKey, showInviteMembersModal, showAllMembersModal, showLeaveCommunityModal } = this.state;

    const members = _.get(community, 'members', []);
    const admins = _.get(community, 'admins', []);
    const isAdmin = _.get(community, 'isAdmin', false);
    const seeAllMembers = _.get(translations, 'seeAll', 'seeAll');

    const aboutCommunity = _.get(translations, 'aboutCommunity', 'aboutCommunity');
    const communityAudience = _.get(translations, 'communityAudience', 'communityAudience');
    const communityAdmins = _.get(translations, 'communityAdmins', 'communityAdmins');
    const leaveCommunity = _.get(translations, 'leaveCommunity', 'leaveCommunity');
    const remainingConnections = members.length - 2;
    const connectionsTranslation =
      remainingConnections === 1
        ? _.get(translations, 'connectionTranslation', 'connectionTranslation')
        : _.get(translations, 'connectionsTranslation', 'connectionsTranslation');

    const numberOfIconMembers = _.get(breakPoints, `${breakPointKey}.nrIcons`, 10);
    const nameSubstractionLabel = members.length <= 2 ? null : `+${remainingConnections} ${connectionsTranslation}`;
    const unblockedAdminsCount = this.unblockedAdminsCount(admins);

    const inviteMembersModal = showInviteMembersModal ? this.renderInviteMembersModal() : null;
    const seeAllMembersModal = showAllMembersModal ? this.renderSeeAllMembersModal() : null;

    const leaveCommunityModal = showLeaveCommunityModal
      ? !isAdmin || unblockedAdminsCount > 1
        ? this.renderLeaveCommunityModal()
        : this.renderLeaveCommunityAdminModal()
      : null;

    return (
      <div className="community-info card__container">
        {inviteMembersModal}
        {seeAllMembersModal}
        {leaveCommunityModal}
        <div className="community-info__members">
          <div className="community-info-header">
            <div className="community-info-title">{`${_.upperFirst(_.get(translations, 'members', 'members'))} (${members.length})`}</div>
            <button onClick={() => this.setShowAllMembersModal(true)} className="button__text-link" type="button">
              {seeAllMembers}
            </button>
          </div>
          <div className="community-info-member__icons">
            {members.slice(0, numberOfIconMembers).map(this.renderMemberIcon)}
            {this.renderMembersCounter(members)}
          </div>
          <div className="community-info-member-names">
            <Truncate lines={1} ellipsis={<>...</>}>
              {buildMembersNameList(members)} {nameSubstractionLabel}
            </Truncate>
          </div>
        </div>
        {this.renderInvitationSection()}
        {!heroIsCommunityMember && this.renderJoinSection()}
        <div className="community-info__fields">
          <div className="community-info-title">{aboutCommunity}</div>
          {this.renderCommunityDescription()}
        </div>
        <div className="community-info__fields">
          <div className="community-info-title">{communityAudience}</div>
          <div className="community-info-audience">{this.renderCommunityAudience()}</div>
        </div>
        <div className="community-info__admins">
          <div className="community-info-title">{communityAdmins}</div>
          {admins.map(this.renderAdminMember)}
        </div>
        {heroIsCommunityMember && (
          <div className="community-info__leave-button-container">
            <button onClick={this.handleLeaveCommunityModal} className="community-info-leave-button" type="button">
              {leaveCommunity}
            </button>
          </div>
        )}
      </div>
    );
  }
}

CommunityInfo.propTypes = {
  translations: PropTypes.shape({}).isRequired,
  community: PropTypes.shape({}).isRequired,
  heroProfile: PropTypes.shape({}),
  isSinglePostPage: PropTypes.bool,
  showCommunityEditModal: PropTypes.bool.isRequired,
  setShowEditCommunityModal: PropTypes.func.isRequired,
  showCommunityManageModal: PropTypes.bool.isRequired,
  setShowManageCommunityModal: PropTypes.func.isRequired,
  leaveCommunity: PropTypes.func.isRequired,
  clearPopularCommunities: PropTypes.func.isRequired,
  openConversation: PropTypes.func.isRequired,

  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

CommunityInfo.defaultProps = {
  isSinglePostPage: false,
  heroIsCommunityMember: false,
  heroProfile: null,
};

export default withRouter(CommunityInfo);
