import { combineReducers } from 'redux';
import booking from './whiteLabel/booking';
import filter from './whiteLabel/filter';
import products from './whiteLabel/products';
import staff from './whiteLabel/staff';
import heroesBookingList from './whiteLabel/heroesBookingList';
import heroDetails from './whiteLabel/heroDetails';
import parkingBookings from './whiteLabel/parkingBookings';

const whiteLabel = combineReducers({
  booking,
  filter,
  products,
  staff,
  heroesBookingList,
  heroDetails,
  parkingBookings,
});

export default whiteLabel;
