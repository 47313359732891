import {FEEDBACK_SEND, ON_FEEDBACK_CLICK} from "../actions/feedbackActions";
import Immutable from "immutable";

const feedback = (state = Immutable.fromJS({ feedbackData: [], }), action) => {
  let newState;
  newState = state.set('feedbackData', Immutable.fromJS([]));
  switch (action.type) {
    case ON_FEEDBACK_CLICK:
      newState = newState.set('feedbackClicked', !state.get('feedbackClicked'));
      break;

    case FEEDBACK_SEND:
      if(action.data.feedback !== undefined) {
        newState = newState.set('feedbackError', true);
        newState = newState.set('feedbackClicked', true);
        newState = newState.set('sendFeedbackLoader', false);

      }
      if(action.data.response !== undefined) {
        newState = newState.set('feedbackError', false);
        newState = newState.set('feedbackContent', '');
        newState = newState.set('feedbackClicked', false);
      }
      break;
    default:
      newState = state;
      newState = newState.set('feedbackError', false);
  }
  return newState;
};

export default feedback;