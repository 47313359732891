import Immutable from 'immutable';
import { FETCH_BOOKING_DETAILS, BEFORE_FETCH_BOOKING_DETAILS, FETCH_BOOKING_SUMMARY, CANCEL_BOOKING } from '../actions/bookingActions';

const defaultState = Immutable.fromJS({
  details: {},
  bookingSummary: [],
  cancelBookingData: {}
});

const booking = (state = defaultState, action) => {
  let newState;

  switch (action.type) {
    case BEFORE_FETCH_BOOKING_DETAILS:
      newState = state.set('details', Immutable.fromJS({})).set('cancelBookingData', Immutable.fromJS({}));
      break;
    case FETCH_BOOKING_DETAILS:
      newState = state.set('details', Immutable.fromJS(action.data));
      break;
    case FETCH_BOOKING_SUMMARY:
      newState = state.set('bookingSummary', Immutable.fromJS(action.data));
      break;
    case CANCEL_BOOKING:
      newState = state.set('cancelBookingData', Immutable.fromJS(action.data));
      break;
    default:
      newState = state;
  }

  return newState;
};

export default booking;
