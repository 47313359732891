import React, { useEffect, useRef } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import classnames from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';
import RemoveYellowImage from '../../../../assets/images/remove-yellow.png';
import CommunitySearch from '../../common/community-search';
import noProfilePicture from '../../../../assets/images/defaultAvatar.png';
import useHashMap from '../../../../utils/useHashMap';

export default function InviteMembersModal(props) {
  const [getHero, setHero, deleteHero, selectedHeroesCount, selectedHeroes] = useHashMap();

  const searchInputValueRef = useRef('');
  const searchInputInTimeoutRef = useRef(undefined);

  const {
    translations,
    closeForm,
    isLoading,
    searchedHeroes,
    hasMore,
    community,
    fetchMoreSearchedInviteHeroes,
    fetchSearchedInviteHeroes,
    inviteHeroes,
    showCommunityPageToast,
    isSubmitted,
    loadMoreIsLoading,
  } = props;

  useEffect(() => {
    fetchSearchedInviteHeroes(_.get(community, 'id', null), searchInputValueRef.current, 0);
  }, []);

  const toggleHero = hero => {
    if (!getHero(hero.id)) {
      setHero(hero.id, hero);
    } else {
      deleteHero(hero.id);
    }
  };

  const buildSuggestedHeroCard = (hero, index) => {
    const { headline } = hero;
    const avatar = hero.profilePictureUrl;
    const name = `${_.get(hero, 'firstName', '')} ${_.get(hero, 'lastName', '')}`;
    const id = _.get(hero, 'id', '');
    const isChecked = getHero(id) || false;
    const heroInitials = _.get(hero, 'heroInitials', null);
    return (
      <div
        key={`hero-${index}`}
        onClick={() => {
          toggleHero(hero);
        }}
        className={classnames('invite-members__suggested-members-card', { 'invite-members__suggested-members-card--checked': isChecked })}
      >
        <div className="search-radio-group invite-members__radio-button-wrapper">
          <label htmlFor="isChecked" className="invite-members__radio-button-label">
            <input type="checkbox" name="isChecked" checked={isChecked} className="radio-button" />
            <span className="invite-members__radio-button-span" />
          </label>
        </div>
        {avatar ? (
          <img src={avatar} className="invite-members__selected-members-picture" width="60px" height="60px" alt="Avatar" />
        ) : (
          <div className="hero-initials community-invite-hero-initials">{heroInitials}</div>
        )}
        <div className="invite-members__suggested-members-details-wrapper">
          <div className="invite-members__suggested-members-name">{name}</div>
          <div className="invite-members__suggested-members-headline">{headline}</div>
        </div>
      </div>
    );
  };

  const buildSelectedHeroItem = hero => {
    const name = `${_.get(hero, 'firstName', '')} ${_.get(hero, 'lastName', '')}`;
    const email = `${_.get(hero, 'email', '')}`;
    const displayName = email || name;
    const id = _.get(hero, 'id', null);
    return (
      <div className="invite-members__selected-members-item">
        <span key={id} className="invite-members__selected-members-name">
          {displayName}
        </span>
        <img
          src={RemoveYellowImage}
          alt="x"
          className="invite-members__selected-members-remove"
          onClick={() => {
            deleteHero(id);
          }}
        />
      </div>
    );
  };

  const handleSearchCallback = event => {
    searchInputValueRef.current = event.target.value;
    if (searchInputInTimeoutRef.current) {
      return;
    }
    searchInputInTimeoutRef.current = setTimeout(() => {
      fetchSearchedInviteHeroes(_.get(community, 'id', null), searchInputValueRef.current, 0);
      searchInputInTimeoutRef.current = undefined;
    }, 1000);
  };

  const handleGuestInvitation = guestEmail => {
    const guest = {
      id: guestEmail,
      email: guestEmail,
      isGuest: true,
    };
    setHero(guest.id, guest);
    fetchSearchedInviteHeroes(_.get(community, 'id', null), '', 0);
  };
  return (
    <div className="community-form">
      <div className="invite-members__header">
        <CommunitySearch
          handleEmailSelection={handleGuestInvitation}
          hasEmailSuggestionBox
          searchCallback={event => {
            handleSearchCallback(event);
          }}
        />
      </div>

      <div className="invite-members__content">
        <div className="invite-members__suggested-members-wrapper">
          <Scrollbars className="styleScroll invite-members__suggested-members-scroll">
            <div className="invite-members__column-title">
              {_.get(translations, 'inviteMembersModal.suggestedMembers', 'inviteMembersModal.suggestedMembers')}
            </div>
            {isLoading ? (
              <div className="invite-members__search-message">
                {_.get(translations, 'inviteMembersModal.loading', 'inviteMembersModal.loading')}
              </div>
            ) : (
              <>
                {!_.isEmpty(searchedHeroes) ? (
                  <div className="invite-members__selected-members">{searchedHeroes.map(buildSuggestedHeroCard)}</div>
                ) : (
                  <div className="invite-members__search-message">
                    {_.get(translations, 'inviteMembersModal.noResult', 'inviteMembersModal.noResult')}
                  </div>
                )}
                {loadMoreIsLoading ? (
                  <div className="invite-members__search-message">
                    {_.get(translations, 'inviteMembersModal.loading', 'inviteMembersModal.loading')}
                  </div>
                ) : (
                  <>
                    {hasMore ? (
                      <button
                        type="button"
                        className="invite-members__load-button"
                        onClick={() => {
                          fetchMoreSearchedInviteHeroes(_.get(community, 'id', null), searchInputValueRef.current, searchedHeroes.length);
                        }}
                      >
                        {_.get(translations, 'inviteMembersModal.loadMore', 'inviteMembersModal.loadMore')}
                      </button>
                    ) : null}
                  </>
                )}
              </>
            )}
          </Scrollbars>
        </div>

        <div className="invite-members__selected-members-wrapper">
          <Scrollbars className="styleScroll">
            <div className="invite-members__column-title">{`${selectedHeroesCount} ${_.get(
              translations,
              'inviteMembersModal.selected',
              'inviteMembersModal.selected'
            )}`}</div>
            <div className="invite-members__selected-members">{selectedHeroes.map(buildSelectedHeroItem)}</div>
          </Scrollbars>
        </div>
      </div>
      <div className="invite-members__footer">
        <div className="community-form__actions">
          <button
            type="button"
            onClick={() => closeForm()}
            id="edit-community__cancel-button"
            className="community-confirmation-modal__cancel-button"
          >
            {_.get(translations, 'form.cancelButton', 'form.cancelButton')}
          </button>
          {!isSubmitted && (
            <button
              id="edit-community__save-button"
              type="button"
              className="button-yellow community__button-submit"
              onClick={() => {
                const guests = selectedHeroes.filter(function(hero) {
                  return hero.isGuest === true;
                });
                const heroes = selectedHeroes.filter(function(hero) {
                  return hero.isGuest !== true;
                });
                inviteHeroes(
                  _.get(community, 'id', null),
                  heroes.map(hero => hero.id),
                  guests.map(guest => guest.email)
                )
                  .then(() => {
                    showCommunityPageToast('toast.invitesSent');
                    closeForm();
                  })
                  .catch(() => {
                    showCommunityPageToast('toast.generalFailure');
                    closeForm();
                  });
              }}
              disabled={false}
            >
              {_.get(translations, 'inviteMembersModal.inviteButton', 'inviteMembersModal.inviteButton')}
            </button>
          )}
          {isSubmitted && <span className="community__loader community-invite-members__loader" />}
        </div>
      </div>
    </div>
  );
}

InviteMembersModal.propTypes = {
  translations: PropTypes.shape({}).isRequired,
  community: PropTypes.shape({}).isRequired,
  hasMore: PropTypes.bool,
  isLoading: PropTypes.bool,
  isSubmitted: PropTypes.bool,
  loadMoreIsLoading: PropTypes.bool,
  searchedHeroes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      headline: PropTypes.string,
      profilePictureUrl: PropTypes.string,
    })
  ),
  fetchMoreSearchedInviteHeroes: PropTypes.func.isRequired,
  fetchSearchedInviteHeroes: PropTypes.func.isRequired,
  inviteHeroes: PropTypes.func.isRequired,
  showCommunityPageToast: PropTypes.func.isRequired,
  closeForm: PropTypes.func.isRequired,
};

InviteMembersModal.defaultProps = {
  hasMore: false,
  isLoading: false,
  isSubmitted: false,
  loadMoreIsLoading: false,
  searchedHeroes: [],
};
