import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import addLoader from '../../../utils/add-loader';

const LoadMore = ({ fetchNews, translations }) => (
  <div className="news__load_more">
    <button id="media-load-more" type="button" className="dashboard-button dashboard-button--border" onClick={fetchNews}>
      {_.get(translations, 'loadMore', 'Load More')}
    </button>
  </div>
);

LoadMore.propTypes = {
  fetchNews: PropTypes.func.isRequired,
};

export default addLoader(LoadMore);
