import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';

const OfficeRules = props => {
  const { rules } = props;
  const rulesList = rules.map(rule => (
    <div
      key={rule.id + rule.fileUrl}
      className={classNames({ attachments: rule.fileUrl, 'rule-available': !rule.fileUrl, 'rule-unavailable': rule.fileUrl })}
    >
      {_.capitalize(rule.name)}
      {rule.fileUrl ? (
        <div className="">
          <a
            target="_blank"
            className="office-rules attachments"
            data-label={_.get(props.trans, 'chat.view', 'chat.view')}
            href={rule.fileUrl}
            rel="noreferrer"
          >
            {' '}
          </a>
        </div>
      ) : (
        ''
      )}
    </div>
  ));

  if (rulesList.length > 0) {
    return (
      <div className="office-rules">
        <div className="office-rules-title">{props.trans.location.houseRules}</div>
        <div className="office-rules-list">{rulesList}</div>
      </div>
    );
  }
  return <div />;
};

OfficeRules.propTypes = {
  rules: PropTypes.array.isRequired,
};

export default OfficeRules;
