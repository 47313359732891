import React from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import moment from 'moment';

const DATE_FORMAT = 'MMM D, YYYY';

class OfficeTypePicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      noAvailableProduct: false,
      hasProductsOfType: false,
      isFetching: false,
      officeSpace: [],
    };
    this.chooseRandomProduct = this.chooseRandomProduct.bind(this);
  }

  componentDidMount() {
    const { officeDetails, noParkingsAvailable } = this.props;
    const officeDetailsHash = officeDetails.toJS();
    const listOfficeTypes = [];
    _.map(officeDetailsHash.products, (data, key) => {
      if (data.length) {
        switch (key) {
          case 'desks':
            listOfficeTypes.push({
              id: 1,
              type: 'desk',
              typeName: 'Desk',
            });
            break;
          case 'meetingRooms':
            listOfficeTypes.push({
              id: 3,
              type: 'meeting',
              typeName: 'Meeting Room',
            });
            break;
          case 'parking':
            listOfficeTypes.push({
              id: 5,
              type: 'parking',
              typeName: 'Parking',
            });
            break;
          default:
            break;
        }
      }
    });
    this.setState({ officeSpace: listOfficeTypes });
    if (noParkingsAvailable) {
      this.setState({ noAvailableProduct: true, hasProductsOfType: true });
    }
  }

  componentDidUpdate(prevProps) {
    const { selectDateRange, noParkingsAvailable } = this.props;
    const { selectDateRange: oldSelectDateRange, noParkingsAvailable: oldNoParkings } = prevProps;
    if (oldSelectDateRange.from !== selectDateRange.from || oldSelectDateRange.to !== selectDateRange.to) {
      this.setState({ noAvailableProduct: false });
    }
    if (!oldNoParkings && noParkingsAvailable) {
      this.setState({ noAvailableProduct: true, hasProductsOfType: true });
    }
  }

  componentWillUnmount() {
    const { setNoParkingsAvailable } = this.props;
    setNoParkingsAvailable(false);
  }

  chooseRandomProduct(officeType) {
    const {
      fetchMapProducts,
      setSelectedProductId,
      officeId,
      floorPlan,
      selectedFloorIndex,
      selectDateRange,
      setSelectedProductType,
      fetchHeroesBookings,
      setSelectedDate,
      selectedFeatures,
      setSelectedFeatures,
      setSelectedFloorIndex,
      getFirstAvailable,
    } = this.props;
    setSelectedProductType(_.get(officeType, 'id', 1));
    const fromDate = moment.utc(selectDateRange.from, DATE_FORMAT).unix();
    const toDate = moment.utc(selectDateRange.to, DATE_FORMAT).unix();
    if (_.get(officeType, 'id', 1) === 3) {
      setSelectedDate(selectDateRange.from, selectDateRange.from);
      setSelectedFeatures([0]);
    }
    const floorId = _.get(floorPlan, `${selectedFloorIndex}.label`, '');
    const productTypeId = _.get(officeType, 'id', 1);
    const features = productTypeId === 1 ? selectedFeatures : null;
    this.setState({ isFetching: true });
    getFirstAvailable(floorId, officeId, officeType.id, fromDate, toDate, features).then(response => {
      let selectedProductId = _.get(response, 'selectedProduct', null);
      const newFloorId = _.get(response, 'floorPlan', null);
      const floorIndex = floorPlan.findIndex(fp => fp.id === newFloorId);
      setSelectedFloorIndex(floorIndex);
      const newFloorNumber = _.get(floorPlan, `${floorIndex}.label`, '');
      fetchMapProducts(officeId, newFloorNumber, productTypeId, fromDate, toDate, features).then(data => {
        if (!selectedProductId) {
          // choose random
          const selectedProduct = _.first(
            _.filter(data, function(index) {
              if (index.status && index.status === 'available' && index.productableType === officeType.id) {
                return index;
              }
            })
          );
          selectedProductId = _.get(selectedProduct, 'id', null);
          if (!selectedProductId) {
            this.setState({ noAvailableProduct: true, hasProductsOfType: false, isFetching: false });
          }
        }
        setSelectedProductId(selectedProductId);
        fetchHeroesBookings(officeId, fromDate, toDate, productTypeId);
      });
    });
  }

  renderProductTypes() {
    const { selectedProductTypeId, selectedProductId, translations } = this.props;
    const { officeSpace } = this.state;
    return officeSpace.map(room => (
      <div
        key={room.id}
        className={classnames({ selected: room.id === selectedProductTypeId && selectedProductId }, `icon-${room.type} office-type-icon`)}
        onClick={() => this.chooseRandomProduct(room)}
      >
        {_.get(translations, `booking.options.officeTypes.${room.type}`, `booking.options.officeTypes.${room.type}`)}
      </div>
    ));
  }

  render() {
    const { translations, selectedProductId, selectedProductTypeId } = this.props;
    const { noAvailableProduct, hasProductsOfType, isFetching, officeSpace } = this.state;
    const productType = _.find(officeSpace, x => x.id === selectedProductTypeId);
    const chooseOfficeTypeLabel = _.get(translations, 'booking.options.chooseOfficeType', '');
    return (
      <div className="booking-options">
        <div className="white-label__booking-options-title">{chooseOfficeTypeLabel}</div>
        <div className="booking-options-icons booking-options-icons__whitelabel">{this.renderProductTypes()}</div>
        {noAvailableProduct && !selectedProductId && selectedProductTypeId && !isFetching && (
          <div className={classnames('white-label__book-max-days', 'white-label__book-max-days--error')}>
            {hasProductsOfType
              ? _.get(translations, 'whiteLabel.noAvailableProduct', 'whiteLabel.noAvailableProduct').replace(
                  '#product#',
                  _.trimEnd(_.lowerCase(_.get(productType, 'typeName', '')))
                )
              : _.get(translations, 'whiteLabel.noOfficeOnCurrentFloor', 'whiteLabel.noOfficeOnCurrentFloor').replace(
                  '#product#',
                  _.trimEnd(_.lowerCase(_.get(productType, 'typeName', '')))
                )}
          </div>
        )}
      </div>
    );
  }
}

export default OfficeTypePicker;
