import Immutable from 'immutable';

import { MARKER_CLICK, MARKER_CLOSE, ON_RADIUS_CHANGE, ON_BOUNDS_CHANGED, ON_ZOOM_CHANGED, ON_LOCATION_CHANGE, SET_ZOOM_TO_FIT_APPLIED } from './../actions/map';
import officeAvailable from './../assets/svg/map/available.svg';
import officeUnavailable from './../assets/svg/map/partially.svg';
import parkingAvailable from './../assets/svg/map/parking.svg';
import officeAvailableSelected from './../assets/svg/map/available-selected.svg';
import officeUnavailableSelected from './../assets/svg/map/partially-selected.svg';
import parkingAvailableSelected from './../assets/svg/map/parking-selected.svg';

const iconsMap = {
  available: {
    normal: officeAvailable,
    selected: officeAvailableSelected
  },
  unavailable: {
    normal: officeUnavailable,
    selected: officeUnavailableSelected
  },
  parking: {
    normal: parkingAvailable,
    selected: parkingAvailableSelected
  }
};

const DEFAULT_LAT = 50.8346086; // Brussels Lat
const DEFAULT_LNG = 4.3463326;  // Brussels Lng
const DEFAULT_ZOOM = 12;

const getIcon = (item) => {
  const available = item.getIn(['locationData', 'available']);
  const parking = item.getIn(['locationData', 'parking']);
  const selected = item.get('showInfo') ? 'selected' : 'normal';
  let icon;

  if (available) {
    if (parking) {
      icon = iconsMap.parking;
    } else {
      icon = iconsMap.available;
    }
  } else {
    icon = iconsMap.unavailable;
  }

  return icon[selected];
};

const rad = (x) => {
    return x * Math.PI / 180;
};

const getDistance = (p1, p2) => {
    let R = 6378137; // Earth’s mean radius in meter
    let dLat = rad(p2.lat - p1.lat);
    let dLong = rad(p2.lng - p1.lng);
    let a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(rad(p1.lat)) * Math.cos(rad(p2.lat)) *
      Math.sin(dLong / 2) * Math.sin(dLong / 2);
    let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    let d = R * c;
    return d/1000; // returns the distance in km
};

const setIcons = (initialMapData) => {
  return initialMapData.set('markers', initialMapData.get('markers').map(_item => {
    return _item.set('icon', getIcon(_item));
  }));
};

// Will remove this when I will make sure nothing depends on it
const initialMapData = {
  defaultZoom: 10,
  zoom: DEFAULT_ZOOM,
  center: { lat: DEFAULT_LAT, lng: DEFAULT_LNG },
  activeMarkerID: null,
  markers: [],
  initialized : false,
  zoomToFitApplied: false,
};

const initialData = setIcons(Immutable.fromJS(initialMapData));

const map = (state = initialData, action) => {
  let newState = state;
  // console.log('MAP STATE', state.toJS());

  switch (action.type) {
    case SET_ZOOM_TO_FIT_APPLIED:
      newState = newState.set('zoomToFitApplied', action.applied);
      break;

    case MARKER_CLICK:
      newState = newState.set('activeMarkerID', action.markerID);
      break;

    case MARKER_CLOSE:
      newState = newState.set('activeMarkerID', null);
      break;

    case ON_BOUNDS_CHANGED:
      let radius    = null;
      let newCenter = {
        lat: state.toJS().center !== null ? state.toJS().center.lat : DEFAULT_LAT,
        lng: state.toJS().center !== null ? state.toJS().center.lng : DEFAULT_LNG
      };
      let newZoom   = state.get('zoom');

      if(action.newMap !== null)
      {
        let p1Lat = state.toJS().center !== null ? state.toJS().center.lat : DEFAULT_LAT;
        let p1Lng = state.toJS().center !== null ? state.toJS().center.lng : DEFAULT_LNG;
        if(action.newMap.getCenter() !== undefined && action.newMap.getCenter() !== null)
        {
          p1Lat = action.newMap.getCenter().lat();
          p1Lng = action.newMap.getCenter().lng();

          let p1 = {
            lat: p1Lat,
            lng: p1Lng,
          };
          let p2 = {
            lat: action.newMap.getCenter().lat(),
            lng: action.newMap.getBounds().getSouthWest().lng()
          };
          radius  = getDistance(p1, p2);
          newZoom   = action.newMap.getZoom();
          newZoom   = newZoom < 3 ? 3 : newZoom;
          newZoom   = newZoom > 14 ? 14 : newZoom;
          newCenter = {lat: action.newMap.getCenter().lat(), lng: action.newMap.getCenter().lng() };
        }
      }
      newState = newState.set('center', Immutable.fromJS(newCenter)).set('zoom', newZoom).set('radius', radius).set('initialized', true);
    break;

    case ON_LOCATION_CHANGE:
      newState = newState.set('center', Immutable.fromJS(action.value)).set('zoom', 11).set('radius', 0);
    break;

    case ON_ZOOM_CHANGED:
      newState = newState.set('zoom', action.zoom);
    break;

    case ON_RADIUS_CHANGE:
    break;

    default:
      newState = state;
  }
  return newState;
};

export default map;
