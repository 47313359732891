import Immutable, { fromJS } from 'immutable';
import _ from 'lodash';
import { SET_BOOKING_RECOMMENDATION, SET_BOOKING_RECOMMENDATION_IS_LOADING } from '../../actions/dashboard/booking-recommendation';

const initialState = Immutable.Map({
  bookingRecommendation: fromJS({}),
  images: fromJS([]),
  isLoading: false,
});

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_BOOKING_RECOMMENDATION:
      const images = _.get(action, 'bookingRecommendation.images', []).map(x => ({ ...x, original: x.url }));
      return state.setIn(['bookingRecommendation'], fromJS(action.bookingRecommendation)).setIn(['images'], fromJS(images));
    case SET_BOOKING_RECOMMENDATION_IS_LOADING:
      return state.setIn(['isLoading'], action.isLoading);
    default:
      return state;
  }
};
