import _ from 'lodash';
import api from '../components/dashboard/utils/api';

export const FETCH_NOTIFICATIONS = 'FETCH_NOTIFICATIONS';
export const GET_UNREAD_NOTIFICATIONS_COUNT = 'GET_UNREAD_NOTIFICATIONS_COUNT';
export const MARK_AS_READ = 'MARK_AS_READ';
export const MARK_ONE_AS_READ = 'MARK_ONE_AS_READ';
export const ON_MODAL_CLOSE = 'ON_MODAL_CLOSE';
export const ON_NOTIFICATION_CLICK = 'ON_NOTIFICATION_CLICK';
export const TRIGGER_REFRESH_NOTIFICATIONS = 'TRIGGER_REFRESH_NOTIFICATIONS';
export const HIDE_NOTIFICATIONS = 'HIDE_NOTIFICATIONS';

export const onNotificationClick = () => {
  return {
    type: ON_NOTIFICATION_CLICK,
  };
};

export const triggerRefreshNotifications = () => {
  return {
    type: TRIGGER_REFRESH_NOTIFICATIONS,
  };
};


export const setNotifications = data => ({
  type: FETCH_NOTIFICATIONS,
  data,
});
export const setUnreadNotificationsCount = unreadNotificationsCount => ({
  type: GET_UNREAD_NOTIFICATIONS_COUNT,
  unreadNotificationsCount,
});

export const setOneAsRead = notificationId => ({
  type: MARK_ONE_AS_READ,
  notificationId,
});
export const setAllAsRead = () => ({
  type: MARK_AS_READ,
});

export const hideNotifications = () => ({
  type: HIDE_NOTIFICATIONS,
});

export const fetchNotifications = (pageNumber = 1, pageSize = 10) => dispatch => {
  return new Promise(() => {
    api
      .get(`${window.__env.apiUrl}/hero/notifications/pageNumber/${pageNumber}/pageSize/${pageSize}`)
      .then(({ data = {} }) => {
        dispatch(setNotifications(data));
      })
      .catch(({ response }) => {});
  });
};

export const getUnreadNotificationsCount = () => dispatch => {
  return new Promise(() => {
    api
      .get(`${window.__env.apiUrl}/hero/notifications/unread`)
      .then(({ data = {} }) => {
        dispatch(setUnreadNotificationsCount(_.get(data, 'unreadNotificationsCount', 0)));
      })
      .catch(({ response }) => {});
  });
};

export const markAsRead = () => dispatch => {
  return new Promise(() => {
    api
      .post(`${window.__env.apiUrl}/hero/notifications/clear`)
      .then(() => {
        dispatch(setAllAsRead());
      })
      .catch(({ response }) => {});
  });
};

export const markOneAsRead = notificationId => dispatch => {
  return new Promise(() => {
    api
      .post(`${window.__env.apiUrl}/hero/notifications/clear/${notificationId}`)
      .then(({ data = {} }) => {
        dispatch(setOneAsRead(_.get(data, 'id', null)));
      })
      .catch(({ response }) => {});
  });
};
