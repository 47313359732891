import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import moment from 'moment';
import getLang from './../../utils/lang';
let lang = getLang() !== null ? getLang() : 'en';

class EventBox extends React.Component {

  /**
   * Decides whether or not si required and renders connections info
   *
   * @method getConnections
   * @param {Immutable.Map} item
   * @returns {ReactElement}
   */
  getConnections (item) {
    // const connections = item.get('connections');
    const attendees = item.get('noAttendees', 0);
    return attendees === 1 ? this.props.trans.events.onePersonJoining : attendees + ' ' + this.props.trans.events.peopleJoining;
    // if (!connections || !connections.size) {
    //   return attendees === 1 ? this.props.trans.events.onePersonJoining : attendees + ' ' + this.props.trans.events.peopleJoining;
    // }
    
    // const firstName = (typeof connections.getIn([0, 'user', 'first_name']) !== 'undefined') ? connections.getIn([0, 'user', 'first_name']) : '';
    // const lastName = (typeof connections.getIn([0, 'user', 'last_name']) !== 'undefined') ? connections.getIn([0, 'user', 'last_name']) : '';
    // const firstConnection = firstName + ' ' + lastName;
    // const secondConnection = (connections.size > 1 && connections.getIn([1, 'user', 'first_name']) !== undefined && connections.getIn([1, 'user', 'last_name']) !== undefined) ? connections.getIn([1, 'user', 'first_name']) + ' ' + connections.getIn([1, 'user', 'last_name']) : null;
    // const connectionsTotal = secondConnection !== null ? connections.size - 2 : connections.size - 1;
    // return (
    //   <ConnectionsData
    //     first={ firstConnection }
    //     second={ secondConnection }
    //     total={ connectionsTotal }
    //     trans={this.props.trans}
    //     />
    // );
  }

  render () {
    const trans = this.props.trans;
    let evStartDate = this.props.item.getIn(['date', 'start']);
    // let evEndDate = this.props.item.getIn(['date', 'end']); // to be use maybe later?

    let eventDate =  moment(evStartDate, 'YYYY-MM-DD HH:mm').locale(lang).format('dddd, D MMMM'); 

    const boxActions = (this.props.content === 'events' || this.props.content === 'upcoming-events') ? '': <div className="bookedOffice-actions">
        <button className="bookedOffice-directions secondary-button-leftSide">
          <span className="bookedOffice-directionsIcon"></span>
          {trans.planDrive}
        </button>
        <div className="officeBox-infoSmall">
          {trans.startsIn}
          <span className="officeBox-infoHighlighted">{ this.props.item.get('startTime') }</span>
        </div>
      </div>;

    const eventLink = (this.props.content === 'upcoming-events') ? <Link to={ `/event/${this.props.item.get('id')}` } className="officeBox-location" /> : '';

    const content = (this.props.content === 'active-events') ?
          <div>
            <div className="bookedOffice">
                <div className="bookedOffice-details">
                  <div className="officeBox-title items-style1">{ this.props.item.get('title') }</div>
                  { eventLink }
                </div>
                { boxActions }
            </div>
            <div className="officeBox-infoSmall">{ eventDate }&nbsp;&nbsp;•&nbsp;&nbsp;{ this.props.item.get('organizer') }<br/>
            <div style={{paddingTop: '5px'}}>
              { this.getConnections(this.props.item) }
            </div>
            </div>
            
          </div>
        : <div className="bookedOffice">
            <div className="bookedOffice-details">
              <div className="officeBox-title items-style1">{ this.props.item.get('title') }</div>
              <div className="officeBox-infoSmall">{ eventDate }{ this.props.item.get('venueName') ? '  •  ' + this.props.item.get('venueName') : '' }
              <div className="officeBox-infoSmall__count">
              { this.getConnections(this.props.item) }
              </div>
              </div>
              { eventLink }
            </div>
            { boxActions }
          </div>;
    return (
        <div>{ content }</div>
    );
  }
}

EventBox.propTypes = {
  /**
   * Immutable map containing an office description
   * @param item
   * @type Immutable.Map
   */
  item: PropTypes.object.isRequired
};

export default EventBox;
