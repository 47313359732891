import { connect } from 'react-redux';
import _ from 'lodash';
import HeroesBookedList from './heroesBookedList';
import { fetchHeroesBookings, fetchDepartments } from '../../../../actions/whiteLabel/heroes-booking-list';
import {
  heroesBookingListSelector,
  heroesBookingListLoadingSelector,
  officeDepartmentsSelector,
} from '../../../../selectors/whiteLabel/heroes-booking-list';
import { selectedDateSelector, selectedProductTypeSelector } from '../../../../selectors/whiteLabel/booking';
import { setSelectedPin, setSelectedProductId } from '../../../../actions/whiteLabel/booking';
import { floorPlansSelector, selectedFloorIndexSelector } from '../../../../selectors/whiteLabel/filter';

const mapStateToProps = state => {
  return {
    translations: state.translations,
    bookings: heroesBookingListSelector(state),
    departments: officeDepartmentsSelector(state),
    isLoading: heroesBookingListLoadingSelector(state),
    officeId: state.location.get('details').get('id'),
    selectDateRange: selectedDateSelector(state),
    selectProductTypeId: selectedProductTypeSelector(state),
    floorPlans: floorPlansSelector(state),
    selectedFloorIndex: selectedFloorIndexSelector(state),
    currentUserId: _.get(state.account.toJS().profile, 'id', null),
  };
};

const mapDispatchProps = dispatch => {
  return {
    fetchHeroesBookings: (officeId, startDate, endDate, type, departmentId) =>
      dispatch(fetchHeroesBookings(officeId, startDate, endDate, type, departmentId)),
    fetchDepartments: officeId => dispatch(fetchDepartments(officeId)),
    setSelectedPin: id => {
      dispatch(setSelectedPin(id));
    },
    setSelectedProductId: id => {
      dispatch(setSelectedProductId(id));
    },
  };
};

export default connect(mapStateToProps, mapDispatchProps)(HeroesBookedList);
