import React from 'react';
import _ from 'lodash';
import cardIcon from '../../../assets/svg/paymentSettings/card.svg';

const PaymentProfileInfo = props => {
  const { address, city, postalCode, country, companyName, firstName, lastName, vatNumber, translations } = props;
  return (
    <div className="payment-profile__content">
      <span className="profile-details__title">{_.get(translations, 'booking.payment.yourPaymentProfile', '')}</span>
      <div className="profile-details__headline">
        <div className="profile-details__image-container">
          <img className="profile-details__image" src={cardIcon} alt="" />
        </div>
        {companyName ? (
          <div className="payment-profile-details">
            <span className="account-details__hero-name">{companyName}</span>
            <span className="profile-details__row-description">{vatNumber}</span>
          </div>
        ) : (
          <span className="account-details__hero-name">{`${firstName} ${lastName}`}</span>
        )}
      </div>
      <div className="payment-profile-details">
        <span className="profile-details__row-title">{_.get(translations, 'paymentSettings.address', '')}</span>
        <span className="profile-details__row-description">{address}</span>
        <span className="profile-details__row-description">{`${city}, ${postalCode}`}</span>
        <span className="profile-details__row-description">{country}</span>
      </div>
    </div>
  );
};

export default PaymentProfileInfo;
