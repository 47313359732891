import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Search from './search';
import VerticalSelector from './verticalSelector';
import Feedback from './feedback';
import Notifications from './notifications';
import ModalDialog from '../modalDialog/modalDialog';
import { withRouter } from 'react-router-dom';
import './css/index.scss';
import _ from 'lodash';
import AuthButtons from "./AuthButtons";
import {currentRouteGroup} from "../../utils/currentRouteGroup";
import LanguageSelector from "./languageSelector/index";
import LogoutHero from "../../utils/LogoutHero";
import LoadingGif from "../../assets/images/loader-inverse.gif";
const logoutOnBrowserClose = (props) =>
{
  LogoutHero(props)
  return true;
}

class Header extends Component {
  props;
  context;

  constructor() {
    super();
    this.state = {
      showWelcomePopUp: false,
    };
  }

  // componentDidMount () {
  //   window.addEventListener("beforeunload", (ev) => { logoutOnBrowserClose(this.props) });
  // }
  //
  // componentWillUnmount() {
  //   window.removeEventListener("beforeunload", logoutOnBrowserClose(this.props));
  // }

  onProceedGoToProfile = () => {
    const { history, location } = this.props;

    history.push({pathname:'/profile', fromUrl: history.location.fromUrl, state:{fromBooking: _.get(location, 'state.fromBooking', false)} });
  };

  onProceedGoToPaymentProfiles = () => {
    window.location = `/account/paymentSettings`;
  };

  onProceedGoToUseApp = () => {
    localStorage.removeItem('show-welcome-pop-up');
    this.setState({ showWelcomePopUp: false });
  };

  handleClick = resultId => {
    const {isWhiteLabel} = this.props;
    if (isWhiteLabel) {
      window.location = '/colleague/details/' + resultId;
      return;
    }
    window.location = '/connection/' + resultId;
  }

  renderImpersonationBanner() {
    const { translations } = this.props;
    const impersonatingHero = localStorage.getItem('impersonatingHero');
    const impersonationWarning = _.get(translations, 'header.impersonationBanner', null);
    if(impersonatingHero &&  !impersonationWarning) {
      return (<div className='impersonation-banner'><img src={LoadingGif} alt="Loading" /></div>);
    }
    if (!impersonatingHero || !impersonationWarning) {
      return null;
    }

    const impersonationLogoutText = _.get(translations, 'header.impersonationLogoutText', '')
    return (<div className='impersonation-banner'>{impersonationWarning.replace('{0}', impersonatingHero)} <span onClick={()=>this.logout()}>{impersonationLogoutText}</span></div>);
  }

  logout() {
    LogoutHero(this.props)
  }

  onLogoutConfirmation = () => {
    const { setMustLogout } = this.props;
    setMustLogout(false);
    this.logout();
  };
  onModalClose = () => {
    const { setMustLogout } = this.props;
    setMustLogout(false);
  };

  onRedirectNewWmsApp = () => {
    window.location.href = window.__env.wmsUrl;
  };

  render() {
    let showWaitingListDialog = false;
    let showRejectedDialog = false;
    let showCompleteProfileDialog = false;
    let showCompleteProfileDialogWhitelabel = false;
    let showWelcomePopUp = false;
    let showRedirectToPayment = false;
    const baseUrl = window.location.origin;
    const isWmsApp = window.__env.isWmsApp;
    const { translations, isAuthenticated, history, location, isWhiteLabel, mustLogout, ssoProvider, formatString } = this.props;
    if (this.props.heroProfile !== null) {
      switch (this.props.heroProfile.status) {
        case 'in_review':
          if (
            this.props.heroProfile.redirect_to_payment === true &&
            this.props.currentPage !== 'heroPaymentSettings' &&
            this.props.currentPage !== 'heroAddEditPaymentProfile'
          ) {
            showRedirectToPayment = true;
          } else {
            if (this.props.heroProfile.profileCompleted !== true) {
              if (
                currentRouteGroup(location.pathname) === 'heroProfileIncomplete') {
                if (isWhiteLabel) {
                  showCompleteProfileDialogWhitelabel = true;
                } else {
                  showCompleteProfileDialog = true;
                }
              }
            } else {
              showWaitingListDialog = true;
            }
          }
          break;
        case 'rejected':
          showRejectedDialog = true;
          break;
        case 'approved':
        default:
          if (
            this.props.heroProfile.redirect_to_payment === true &&
            this.props.currentPage !== 'heroPaymentSettings' &&
            this.props.currentPage !== 'heroAddEditPaymentProfile'
          ) {
            showRedirectToPayment = true;
          } else {
            if (this.props.heroProfile.profileCompleted !== true) {
              if (currentRouteGroup(location.pathname) === 'heroProfileIncomplete') {
                if (isWhiteLabel) {
                  showCompleteProfileDialogWhitelabel = true;
                } else {
                  showCompleteProfileDialog = true;
                }
              }
            } else {
              if (this.state.showWelcomePopUp === true) {
                showWelcomePopUp = true;
              }
            }
          }
          break;
      }
    }
    let headerClassName =  "header-logo-empty";
    if (_.isBoolean(isWhiteLabel) || !isAuthenticated) {
      headerClassName = isWmsApp ? "header-logo-whitelabel" : "header-logo";
    }
    const headerLink = (isWmsApp && !isAuthenticated) ? baseUrl : (baseUrl + '/dashboard');

    const ssoProviderLabel = ssoProvider ?? 'Azure';

    return (
    <>
      {this.renderImpersonationBanner()}
      <header className="header-container">
        <a href={headerLink} className={headerClassName}>
          <span className="logo"></span>
        </a>
        <div className="header">
          {
            (!isWmsApp || isAuthenticated) && (
                <div className="header__vertical-search">
                  <VerticalSelector />
                  <Search
                      onLocationChange={this.props.onLocationChange}
                      userName={this.props.userName}
                      globalSearch={this.props.globalSearch}
                      globalSearchTrigger={this.props.globalSearchTrigger}
                      globalSearchResults={this.props.globalSearchResults}
                      globalSearching={this.props.globalSearching}
                      handleClick={this.handleClick}
                      trans={translations}
                      isAuth={isAuthenticated}
                  />
                </div>
            )
          }
          <div className="header__feedback-notifications">
            <LanguageSelector/>
            {isAuthenticated ?
                <>
                  <Feedback
                      onFeedbackClick={this.props.onFeedbackClick}
                      feedbackSend={this.props.feedbackSend}
                      feedbackClicked={this.props.feedbackClicked}
                      sendFeedbackLoader={this.props.sendFeedbackLoader}
                      feedbackError={this.props.feedbackError}
                      feedbackContent={this.props.feedbackContent}
                      trans={translations}
                  />
                  <Notifications
                      notificationClicked={this.props.notificationClicked}
                      notificationsData={this.props.notificationsData}
                      notificationsCount={this.props.notificationsCount}
                      onNotificationClick={this.props.onNotificationClick}
                      modalOpened={this.props.modalOpened}
                      markAsRead={this.props.markAsRead}
                      markedAsRead={this.props.markedAsRead}
                      markOneAsRead={this.props.markOneAsRead}
                      fetchNotifications={this.props.fetchNotifications}
                      getUnreadNotificationsCount={this.props.getUnreadNotificationsCount}
                      refreshNotifications={this.props.refreshNotifications}
                      triggerRefreshNotifications={this.props.triggerRefreshNotifications}
                      hideNotifications={this.props.hideNotifications}
                      trans={translations}
                  />
                </> : null }

          </div>
          {isAuthenticated ? null : <AuthButtons translations={translations} history={history} />}

        </div>

        <ModalDialog
          label="Thank you for joining the Workero network."
          title={'Thank you for joining the Workero network.'}
          type="welcome-popup"
          modalOpened={showWaitingListDialog}
          showCancel={false}
          showOk={false}
          proceedText="OK"
          className="welcome-modal"
        >
          <div>
            Your account is on a waiting list and you cannot use the system yet. Please keep an eye on your email inbox for updates.
          </div>
        </ModalDialog>

        <ModalDialog
          label="Thank you for joining the Workero network."
          title={'Thank you for joining the Workero network.'}
          type="welcome-popup"
          modalOpened={showRejectedDialog}
          showCancel={false}
          showOk={false}
          proceedText="OK"
          className="welcome-modal"
        >
          <div>Your account has been rejected. Please contact our customer service.</div>
        </ModalDialog>

        <ModalDialog
          label={_.get(
            translations,
            'modals.incompleteProfile.title',
            'We made it. Workero has launched its Technology Knowledge Ecosystem.'
          )}
          title={_.get(
            translations,
            'modals.incompleteProfile.title',
            'We made it. Workero has launched its Technology Knowledge Ecosystem.'
          )}
          type="welcome-popup"
          modalOpened={showCompleteProfileDialog}
          onProceed={this.onProceedGoToProfile}
          showCancel={false}
          proceedText="OK, LET'S DO THIS"
          className="welcome-modal incomplete-profile-modal"
        >
          <div>
            <br />
            {_.get(
              translations,
              'modals.incompleteProfile.subtitle',
              'We are excited to present you the new Workero platform. ' +
                'We worked hard to extend the ecosystem focusing on office space and a knowledge driven environment.'
            )}
            <br />
            <br />
            {_.get(
              translations,
              'modals.incompleteProfile.firstDescription',
              '• If you were already a member, please review your profile and complete the missing information. It will only take you 15 seconds and you are back up and running.'
            )}
            <br />
            {_.get(
              translations,
              'modals.incompleteProfile.secondDescription',
              '• If you are a new Hero, please provide your details in order to access Workero Eco-system.'
            )}
          </div>
        </ModalDialog>

        <ModalDialog
            label={_.get(
                translations,
                'modals.incompleteProfileWhitelabel.title',
                'We made it. Workero has launched its Technology Knowledge Ecosystem.'
            )}
            title={_.get(
                translations,
                'modals.incompleteProfileWhitelabel.title',
                'We made it. Workero has launched its Technology Knowledge Ecosystem.'
            )}
            type="welcome-popup"
            modalOpened={showCompleteProfileDialogWhitelabel}
            onProceed={this.onProceedGoToProfile}
            showCancel={false}
            proceedText={_.get(
                translations,
                'modals.incompleteProfileWhitelabel.okButton',
                "OK, LET'S DO THIS"
            )}
            className="welcome-modal incomplete-profile-modal"
        >
          <div>
            <br />
            {_.get(
                translations,
                'modals.incompleteProfileWhitelabel.subtitle',
                'Please complete your profile by checking the skills and trainings that you have or by selecting No specific skills or trainings.'
            )}
            <br />
          </div>
        </ModalDialog>

        <ModalDialog
          label="Thank you for joining the Workero network."
          title={'Thank you for joining the Workero network.'}
          type="welcome-popup"
          modalOpened={showRedirectToPayment}
          onProceed={this.onProceedGoToPaymentProfiles}
          showCancel={false}
          proceedText="OK, LET'S DO THIS"
          className="welcome-modal"
        >
          <div>The next step in order to subscribe is to set up a payment profile and then click on Activate Subscription.</div>
        </ModalDialog>

        <ModalDialog
          label="Thank you for joining the Workero network."
          title={'Thank you for joining the Workero network.'}
          type="welcome-popup"
          modalOpened={showWelcomePopUp}
          onProceed={this.onProceedGoToUseApp}
          showCancel={false}
          proceedText="OK, LET'S DO THIS"
          className="welcome-modal"
        >
          <div>
            You are in our BETA version so your feedback is very important to us.
            <br />
            Please use the feedback form as often you need to. Enjoy!
          </div>
        </ModalDialog>

        <ModalDialog
            label={_.get(
                translations,
                'modals.mustLogout.title',
                'modals.mustLogout.title'
            )}
            title={_.get(
                translations,
                'modals.mustLogout.title',
                'modals.mustLogout.title'
            )}
            type="welcome-popup"
            modalOpened={mustLogout}
            onProceed={this.onLogoutConfirmation}
            proceedText={formatString(
                _.get(translations,
                'modals.mustLogout.okButton',
                "modals.mustLogout.okButton"
            ), ssoProviderLabel)}
            showCancel={true}
            onCancel={this.onModalClose}
            cancelText={_.get(
                translations,
                'modals.mustLogout.cancelButton',
                "modals.mustLogout.cancelButton"
            )}
            trans={translations}
            className="welcome-modal incomplete-profile-modal"
        >
          <div>
            <br />
            {formatString(
                _.get(translations,
                'modals.mustLogout.subtitle',
                'modals.mustLogout.subtitle'
            ), ssoProviderLabel)}
            <br />
          </div>
        </ModalDialog>

        <ModalDialog
            label={_.get(
                translations,
                'modals.redirectToWmsApp.title',
                'modals.redirectToWmsApp.title'
            )}
            title={_.get(
                translations,
                'modals.redirectToWmsApp.title',
                'modals.redirectToWmsApp.title'
            )}
            type="welcome-popup"
            modalOpened={isWhiteLabel && !isWmsApp}
            onProceed={this.onRedirectNewWmsApp}
            proceedText={_.get(
                translations,
                'modals.redirectToWmsApp.okButton',
                "modals.redirectToWmsApp.okButton"
            )}
            showCancel={false}
            trans={translations}
            className="welcome-modal incomplete-profile-modal"
        >
          <div>
            <br />
            {formatString(_.get(
                translations,
                'modals.redirectToWmsApp.subtitle',
                'modals.redirectToWmsApp.subtitle'
            ), window.__env.wmsUrl)}
            <br />
          </div>
        </ModalDialog>
      </header>
    </>
    );
  }
}

Header.propTypes = {
  notificationClicked: PropTypes.bool,
  onNotificationClick: PropTypes.func,
  onFeedbackClick: PropTypes.func,
  feedbackSend: PropTypes.func,
  modalOpened: PropTypes.bool,
  refreshNotifications: PropTypes.bool,
};

Header.defaultProps = {
  notificationClicked: false,
  modalOpened: false,
};
export default withRouter(Header);
