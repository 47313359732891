import { connect } from 'react-redux';
import MyMeetings from './MyMeetings';
import './index.scss';
import { fetchMeetings, acceptMeetingInvitation, declineMeetingInvitation } from '../../actions/meetingAttendeesActions';

const mapStateToProps = state => ({
  translations: state.translations,
  heroProfile: state.global.get('heroProfile'),
  meetings: state.meetingAttendees.get('meetings'),
  isLoading: state.meetingAttendees.get('isLoadingInvitedMember'),
});

const mapDispatchProps = dispatch => ({
  fetchMeetings: () => dispatch(fetchMeetings()),
  acceptMeetingInvitation: (attendeeId, status, bookingId, officeId) => dispatch(acceptMeetingInvitation(attendeeId, status, bookingId, officeId)),
  declineMeetingInvitation: (attendeeId, status, bookingId, officeId) => dispatch(declineMeetingInvitation(attendeeId, status, bookingId, officeId)),
});

export default connect(mapStateToProps, mapDispatchProps)(MyMeetings);
