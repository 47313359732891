import _ from 'lodash';

const routes = [
  {
    path: '/communities',
    group: 'community',
    restrictionCode: 'community',
  },
  {
    path: '/health-safety-certificate',
    group: 'healthMeasure',
    restrictionCode: 'health_measures',
  },
  {
    path: '/community',
    group: 'community',
    restrictionCode: 'community',
  },
  {
    path: '/my-bookings',
    group: 'booking',
    restrictionCode: 'booking',
  },
  {
    path: '/my-meetings',
    group: 'booking',
    restrictionCode: 'booking',
  },
  {
    path: '/booking',
    group: 'booking',
    restrictionCode: 'booking',
  },
  {
    path: '/location',
    group: 'booking',
    restrictionCode: 'booking',
  },
  {
    path: '/dashboard',
    group: 'dashboard',
    restrictionCode: 'dashboard',
  },
  {
    path: '/connections',
    group: 'connections',
    restrictionCode: 'connections',
  },
  {
    path: '/direct-chat',
    group: 'directChat',
    restrictionCode: 'direct_chat',
  },
  {
    path: '/account/profile/incomplete',
    group: 'heroProfileIncomplete',
  },
];
export const currentRouteGroup = currentRoute => {
  const route = routes.find(x => currentRoute.startsWith(x.path));

  return _.get(route, 'group', undefined);
};

export const currentRouteRestriction = currentRoute => {
  const route = routes.find(x => currentRoute.startsWith(x.group));

  return _.get(route, 'restrictionCode', undefined);
};
