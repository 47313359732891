import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Scrollbars } from 'react-custom-scrollbars';
import onClickOutside from 'react-onclickoutside';

import meetingAccepted from '../../../assets/svg/meeting/meeting-accepted.svg';
import meetingAcceptedBlack from '../../../assets/svg/meeting/meeting-accepted-black.svg';
import meetingDeclined from '../../../assets/svg/meeting/meeting-declined.svg';
import LoadingGif from "../../../assets/images/loader.gif";
import _ from "lodash";

class Dropdown extends Component {
    constructor(props) {
        super(props);

        this.state = {
            expanded: false,
        };

        this.toggleExpand = this.toggleExpand.bind(this);
        this.onSelect = this.onSelect.bind(this);
    }

    handleClickOutside = (e) => {
        this.setState({
            expanded: false
        });
    }

    onSelect(id, value) {
        const { onChange } = this.props;
        this.setState({
            expanded: false,
        });

        onChange(id);
    }

    toggleExpand() {
        const { expanded } = this.state;
        this.setState({
            expanded: !expanded,
        });
    }


    render() {
        const { selectedValue, bookingId, isLoadingBookingIds, translations } = this.props;
        const { expanded } = this.state;

        const isLoading =  _.get(isLoadingBookingIds, `${bookingId}`, false);

        if (selectedValue === 'sent') return (

            <div className="meeting-attendees_buttons">
                {
                    isLoading ? (
                        <img className="meeting-attendees_dropdown__loader meeting-attendees_dropdown__loader--sent" src={LoadingGif} alt="Loading" />
                    ) : (
                        <>
                            <button className="accept" onClick={this.onSelect.bind(null, 'accepted', 'accepted')} type="button">
                                <img src={meetingAcceptedBlack} alt="Accept" className="accept-img" />{_.get(translations, 'meetings.accept', 'meetings.accept')}
                            </button>
                            <button className="decline" onClick={this.onSelect.bind(null, 'declined', 'declined')} type="button">
                                <img src={meetingDeclined} alt="Decline" className="decline-img" />{_.get(translations, 'meetings.decline', 'meetings.decline')}
                            </button>
                        </>
                    )
                }
            </div>
        );

        return (
            <div className={classnames({ expanded }, 'dropdown__selector', 'meeting-attendees_dropdown')}>
                <div className={classnames({'completed':  selectedValue }, 'dropdown-input')} onClick={this.toggleExpand}>
                    {
                        isLoading ? (
                            <img className="meeting-attendees_dropdown__loader" src={LoadingGif} alt="Loading" />
                        ) : (
                            <>
                                {selectedValue === 'accepted' ? (
                                    <>
                                        <img src={meetingAccepted} alt="Meeting Accepted" />{_.get(translations, 'meetings.accept', 'meetings.accept')}
                                    </>
                                ) : (
                                    <>
                                        <img src={meetingDeclined} alt="Meeting Declined" />{_.get(translations, 'meetings.decline', 'meetings.decline')}
                                    </>
                                )}
                            </>
                        )
                    }

                </div>
                <div className="container">
                    <Scrollbars className="styleScroll">
                        <div key="accepted" className="option" onClick={this.onSelect.bind(null, 'accepted', '')}>
                            <img src={meetingAccepted} alt="Meeting Accepted" className="accepted" />{_.get(translations, 'meetings.accept', 'meetings.accept')}
                        </div>
                        <div key="declined" className="option" onClick={this.onSelect.bind(null, 'declined', 'declined')}>
                            <img src={meetingDeclined} alt="Meeting Declined" className="declined" />{_.get(translations, 'meetings.decline', 'meetings.decline')}
                        </div>
                    </Scrollbars>
                </div>
            </div>
        );
    }
}

Dropdown.propTypes = {
    onChange: PropTypes.func.isRequired,
    selectedId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Dropdown.defaultProps = {
    selectedId: null,
};

export default onClickOutside(Dropdown);
