import api from '../../components/community/utils/api';
import { fetchSinglePostPage } from './community-posts';

/*
 * Constants
 */
export const SET_COMMUNITY_COMMENTS_IS_LOADING = 'SET_COMMUNITY_COMMENTS_IS_LOADING';
export const SET_COMMUNITY_POST_COMMENTS = 'SET_COMMUNITY_POST_COMMENTS';
export const DELETE_COMMUNITY_COMMENT = 'DELETE_COMMUNITY_COMMENT';

/*
 * Sync Actions
 */
export const setCommunityPostComments = (comments, postId) => ({
  type: SET_COMMUNITY_POST_COMMENTS,
  comments,
  postId,
});

export const setCommentsIsLoading = comments => ({
  type: SET_COMMUNITY_COMMENTS_IS_LOADING,
  comments,
});
export const deleteComment = deleteComment => ({
  type: DELETE_COMMUNITY_COMMENT,
  deleteComment,
});

/*
 * Async Actions
 */
export const fetchComments = postId => dispatch => {
  dispatch(setCommentsIsLoading(true));
  return new Promise((resolve, reject) => {
    api
      .get(`comments/list/${postId}`)
      .then(response => {
        dispatch(setCommentsIsLoading(false));
        dispatch(setCommunityPostComments(response.data.data, postId));
        resolve(response.data.data);
      })
      .catch(({ response }) => {
        if (response.data) {
          dispatch(setCommentsIsLoading(false));
          reject(response.data);
        }
      });
  });
};
export const deleteCommunityComment = (commentId, postId) => dispatch => {
  return new Promise((resolve, reject) => {
    api
      .delete(`comments/delete/${commentId}`)
      .then(response => {
        dispatch(deleteComment({ commentId, postId }));
        dispatch(fetchComments(postId));
        resolve(true);
      })
      .catch(({ response }) => {
        reject();
      });
  });
};
