
let ws;

export default {
  subscribers: [],
  pendingReconnect: false,
  pingIntervalId: undefined,
  reconnectTimeoutId: undefined,
  initAndLogin() {

    this.clearPingAndReconnect();
    if(!window.ws) {
      console.log('connection does not exists')
      ws = new WebSocket(window.__env.webSocketUrl);
      window.ws = ws
    }
    else {
      console.log('connection alredy exists')
      ws = window.ws
    }

    ws.onopen = () => {

      this.clearPingAndReconnect();
      this.pingIntervalId = setInterval(() => {
        if (ws.readyState === 1) {
          ws.send(JSON.stringify({ action: 'ping' }));
        }
      }, 30000);
      this.login();
    }

    ws.onmessage = (e) => {
      const message = JSON.parse(e.data);
      if (message.action)
        this.dispatch(message.action, message);
    };

    ws.onerror = (e) => {
      // an error occurred
      // console.log('An error occured and your message was not sent.', e);
     this.scheduleReconnect();
    };

    ws.onclose = (e) => {
      // connection closed
      // console.log('The websocket has been closed', e);
      this.scheduleReconnect();
    };
  },
  clearPingAndReconnect() {
    if (this.pingIntervalId) {
      clearInterval(this.pingIntervalId);
    }
    if (this.reconnectTimeoutId) {
      clearTimeout(this.reconnectTimeoutId);
    }
  },
  reconnect() {
    // console.log('[CHAT]Reconnecting...');
    this.pendingReconnect = false;
    this.initAndLogin();
  },
  scheduleReconnect() {
    if (!this.pendingReconnect) {
      // console.log('[CHAT]Scheduling reconnect...');
      this.pendingReconnect = true;
      this.reconnectTimeoutId = setTimeout(this.reconnect.bind(this), 10000);
    }
  },
  on(action, handler) {
    this.subscribers.push({ action, handler });
  },
  off(action, handler) {
    this.subscribers = this.subscribers.filter(x => x.handler !== handler);
  },
  offAll(action, handler) {
    this.subscribers = [];
  },
  dispatch(action, data) {
    this.subscribers.filter(x => x.action === action).forEach(x => x.handler(data));
  },
  login() {
    ws.send(JSON.stringify({
      action: 'login',
      login: {
        "accessToken": localStorage.getItem('access-key')
      }
    }));
  },
  sendMessage(text, targetHeroId, clientMessageId) {
    ws.send(JSON.stringify({
      'action': 'send-message',
      'message': {
        "targetHeroId": targetHeroId,
        "text": text,
        "imageFileName": '',
        "clientMessageId": clientMessageId,
      }
    }));
  },
  close() {
    ws.close();
  }
}
