import React from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import defaultAvatar from '../../../assets/images/defaultAvatar.png';
import Dropdown from '../../myMeetings/dropdown';
import LoadingGif from '../../../assets/images/loader.gif';

const buildAttendeeItem = (hero, organizerLabel = '') => (
  <div key={`attendee-${_.get(hero, 'id', '')}`} className="meeting-details__attendee">
    <div className="booking-invite-members__image-section">
      {_.get(hero, 'profilePictureUrl', null) || _.get(hero, 'heroInitials', 'N/A') === 'N/A' ? (
        <img
          className="meeting-details__attendee-picture"
          alt=""
          style={{ height: '36px', width: '36px', marginRight: '10px' }}
          src={_.get(hero, 'profilePictureUrl', null) !== null ? _.get(hero, 'profilePictureUrl', null) : defaultAvatar}
        />
      ) : (
        <div className="hero-initials search-hero-initials">{_.get(hero, 'heroInitials', null)}</div>
      )}
      <span className="meeting-details__attendee-name">
        {_.get(hero, 'firstName', null)} {_.get(hero, 'lastName', null)}
        {organizerLabel}
      </span>
    </div>
  </div>
);
const buildMeetingDetailItem = (detail, translations) => (
  <div key={`detail-${_.get(detail, '0', '')}`} className="meeting-details__detail-section">
    <div className="meeting-details__category">{_.get(translations, `meetingAttendees.email.${_.get(detail, '0')}`)}</div>
    <div className="meeting-details__value">{_.get(detail, '1')}</div>
  </div>
);

class MeetingDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: 'sent',
      showAll: false,
      updating: false,
    };
    this.changeResponse = this.changeResponse.bind(this);
  }

  componentDidMount() {
    const { meeting, heroProfile } = this.props;
    const { attendees } = meeting;
    const attendee = attendees.filter(attendee => _.get(attendee, 'heroId', null) === _.get(heroProfile, 'id', null));
    const status = _.get(attendee, '0.status', 'sent');
    this.setState({ status });
  }

  changeResponse(action) {
    const { declineMeetingInvitation, acceptMeetingInvitation, meeting, heroProfile } = this.props;
    const { attendees } = meeting;
    const attendee = attendees.filter(attendee => _.get(attendee, 'heroId', null) === _.get(heroProfile, 'id', null));
    const meetingAttendeeId = _.get(attendee, '0.id');
    this.setState({ updating: true });
    if (action === 'declined') {
      declineMeetingInvitation(meetingAttendeeId)
        .then(() => this.setState({ status: 'declined', updating: false }))
        .catch(() => this.setState({ updating: false }));
    }
    if (action === 'accepted') {
      acceptMeetingInvitation(meetingAttendeeId)
        .then(() => this.setState({ status: 'accepted', updating: false }))
        .catch(() => this.setState({ updating: false }));
    }
  }

  getCalendarLink = (bookingStatus) => {
    const { meeting, translations } = this.props;
    const joinUrlText = meeting.calendar_provider === 'google' ? 'go_to_meet' : 'go_to_teams'
    const joinUrlClass = meeting.calendar_provider === 'google' ? 'gotoMeet' : 'gotoTeams'
    const isCanceled = (bookingStatus === 'canceled');
    if (!isCanceled && meeting.hasOwnProperty('join_url')) {
      return (
        <div className={`office-booked-details ${joinUrlClass}__child-position-absolute`}>
          <div className={`office-booked-title ${joinUrlClass}__office-booked-title`}>{translations.booking.sidebar[joinUrlText]}</div>
            <a href={meeting.join_url} target="_blank" rel="noopener noreferrer" className='meeting-details__value'>
              {meeting.join_url.substr(0, 19)} ...
            </a>
        </div>
      )
    }
  }

  render() {
    const { translations, meeting } = this.props;
    const { status, showAll, updating } = this.state;
    const { organizer, attendees, bookingDetails, id, officeDetails } = meeting;
    const organizerName = `${_.get(organizer, 'firstName')} ${_.get(organizer, 'lastName')} `;
    const title = translations.formatString(
      _.get(translations, 'meetings.invitedYouToAMeeting', '{0} invited you to a meeting.'),
      organizerName
    );
    const organizerLabel = ` ${_.get(translations, 'meetingAttendees.email.organizer', '(organizer)')}`;
    const confirmedAttendees = attendees.filter(attendee => _.get(attendee, 'status', null) === 'accepted');
    const hiddenCount = confirmedAttendees.length > 3 ? confirmedAttendees.length - 3 : 0;
    const attendeesToDisplay = showAll ? confirmedAttendees : confirmedAttendees.slice(0, 3);
    const bookingStatus = _.get(meeting, 'bookingStatus');

    const dayFrom = _.get(bookingDetails, 'when');
    const hourFrom = _.get(bookingDetails, 'atWhatTime').substring(0, 5);
    const timeZone = _.get(officeDetails, 'timezone');
    const now = moment.tz(timeZone);
    const startBooking = moment.tz(`${dayFrom} ${hourFrom}`, 'mmm, dd yyyy HH:mm', timeZone);
    const alreadyStarted = now.diff(startBooking) < 0;
    const dropdownIsDisabled = alreadyStarted || bookingStatus === 'canceled';

    return (
      <div className="meeting-details__container">
        <div className="meeting-details__column meeting-details__left-side">
          <div className="meeting-details__title">{title}</div>
          <div className="meeting-details__attendees">
            <span className="meeting-details__category">{_.get(translations, 'meetingAttendees.email.who', 'Who')}</span>
            {buildAttendeeItem(organizer, organizerLabel)}
            {attendeesToDisplay.map(function(attendee) {
              return buildAttendeeItem(attendee, '');
            })}
            {showAll ? (
              <span
                className="meeting-details__more-button"
                onClick={() => {
                  this.setState({ showAll: false });
                }}
              >
                {_.get(translations, 'meetings.lessAttendeesLabel')}
              </span>
            ) : hiddenCount ? (
              <span
                className="meeting-details__more-button"
                onClick={() => {
                  this.setState({ showAll: true });
                }}
              >
                {translations.formatString(_.get(translations, 'meetings.moreAttendeesLabel', '+ {0} more'), hiddenCount)}
              </span>
            ) : null}
          </div>
        </div>
        <div className="meeting-details__column meeting-details__middle-side">
          {Object.entries(bookingDetails).map(function(bookingDetail) {
            return buildMeetingDetailItem(bookingDetail, translations);
          })}
        </div>
        <div
          className={classnames('meeting-details__column meeting-details__right-side gotoTeams__parent-position-relative', {
            'meeting-details__right-side--disabled': dropdownIsDisabled,
          })}
        >
          {this.getCalendarLink(bookingStatus)}
          {status === 'sent' ? (
            <div className="meeting-details__title">{_.get(translations, 'meetings.doYouAccept', 'Do you accept the invitation?')}</div>
          ) : (
            <div className="meeting-details__title">{_.get(translations, `meetings.you_${status}`, `You ${status} the invitation`)}</div>
          )}
          {updating ? (
            <div className="meeting-loader__container">
              <img className="meeting-loader__image" src={LoadingGif} alt="Loading" />
            </div>
          ) : (
            <Dropdown onChange={this.changeResponse} selectedValue={status} bookingId={id} />
          )}
        </div>
      </div>
    );
  }
}

MeetingDetails.propTypes = {
  meeting: PropTypes.shape({
    organizer: PropTypes.shape({}),
    attendees: PropTypes.arrayOf(PropTypes.shape({})),
    bookingDetails: PropTypes.shape({}),
    id: PropTypes.string,
  }).isRequired,
  heroProfile: PropTypes.shape({}),
  history: PropTypes.shape({}),
  translations: PropTypes.shape({
    formatString: PropTypes.func,
  }),
  declineMeetingInvitation: PropTypes.func,
  acceptMeetingInvitation: PropTypes.func,
};

MeetingDetails.defaultProps = {
  heroProfile: {},
  history: {},
  translations: {},
  declineMeetingInvitation: () => {},
  acceptMeetingInvitation: () => {},
};

export default MeetingDetails;
