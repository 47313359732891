import api from '../../components/community/utils/api';
import { loadMoreCommunitiesOffsetSelector, popularCommunitiesCountSelector } from '../../selectors/community/popular-communities';
/*
 * Constants
 */
export const SET_POPULAR_COMMUNITIES = 'SET_POPULAR_COMMUNITIES';
export const SET_POPULAR_COMMUNITIES_IS_LOADING = 'SET_POPULAR_COMMUNITIES_IS_LOADING';
export const SET_NO_COMMUNITIES = 'SET_NO_COMMUNITIES';
export const SET_POPULAR_COMMUNITIES_IS_LOADING_MORE = 'SET_POPULAR_COMMUNITIES_IS_LOADING_MORE';
export const SET_LOAD_MORE_COMMUNITIES_OFFSET = 'SET_LOAD_MORE_COMMUNITIES_OFFSET';
export const INCREMENT_LOAD_MORE_COMMUNITIES_OFFSET = 'INCREMENT_LOAD_MORE_COMMUNITIES_OFFSET';
export const ADD_POPULAR_COMMUNITIES = 'ADD_POPULAR_COMMUNITIES';
export const SET_HAS_MORE_COMMUNITIES = 'SET_HAS_MORE_COMMUNITIES';

/*
 * Sync Actions
 */
export const setPopularCommunities = popularCommunities => ({
  type: SET_POPULAR_COMMUNITIES,
  popularCommunities,
});

export const setNoCommunities = noCommunities => ({
  type: SET_NO_COMMUNITIES,
  noCommunities,
});

export const addPopularCommunities = popularCommunities => ({
  type: ADD_POPULAR_COMMUNITIES,
  popularCommunities,
});

export const setLoadMoreCommunitiesOffset = loadMoreCommunitiesOffset => ({
  type: SET_LOAD_MORE_COMMUNITIES_OFFSET,
  loadMoreCommunitiesOffset,
});

export const incrementLoadMoreCommunitiesOffset = () => ({
  type: INCREMENT_LOAD_MORE_COMMUNITIES_OFFSET,
});

export const setPopularCommunitiesIsLoadingMore = isLoadingMore => ({
  type: SET_POPULAR_COMMUNITIES_IS_LOADING_MORE,
  isLoadingMore,
});

export const setPopularCommunitiesIsLoading = isLoading => ({
  type: SET_POPULAR_COMMUNITIES_IS_LOADING,
  isLoading,
});

export const setHasMoreCommunitites = hasMoreCommunities => ({
  type: SET_HAS_MORE_COMMUNITIES,
  hasMoreCommunities,
});

export const clearPopularCommunities = () => dispatch => {
  dispatch(setPopularCommunities([]));
  dispatch(setLoadMoreCommunitiesOffset(0));
};
/*
 * Async Actions
 */
export const fetchPopularCommunities = () => (dispatch, getState) => {
  const loadMoreOffset = loadMoreCommunitiesOffsetSelector(getState());
  const popularCommunitiesCount = popularCommunitiesCountSelector(getState());
  if (!popularCommunitiesCount) {
    dispatch(setPopularCommunitiesIsLoading(true));
  } else {
    dispatch(setPopularCommunitiesIsLoadingMore(true));
  }

  api
    .get(`/all/offset/${loadMoreOffset}`)
    .then(({ data = {} }) => {
      if (data.data.length !== 0) {
        if (!popularCommunitiesCount) {
          dispatch(setPopularCommunities(data.data));
        } else {
          dispatch(addPopularCommunities(data.data));
        }

        dispatch(setNoCommunities(false));
        dispatch(incrementLoadMoreCommunitiesOffset());
        dispatch(setHasMoreCommunitites(data.hasMore));
      } else if (!popularCommunitiesCount) {
        dispatch(setNoCommunities(true));
      }
    })
    .catch(() => {})
    .finally(() => {
      dispatch(setPopularCommunitiesIsLoading(false));
      dispatch(setPopularCommunitiesIsLoadingMore(false));
    });
};
