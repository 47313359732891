import { connect } from 'react-redux';
import _ from 'lodash';
import toJS from '../../utils/to-js';
import CommunityPageHeader from './CommunityPageHeader';
import { communityEditModalSelector, communityManageModalSelector } from '../../../../selectors/community/community-modals';
import { setShowEditCommunityModal, setShowManageCommunityModal } from '../../../../actions/community/community-modals';
import { translations } from '../../../../selectors/translations';
import { communitySelector } from '../../../../selectors/community/community-page';
import { fetchCommunity } from '../../../../actions/community/community-page';

const mapDispatchToProps = dispatch => ({
  setShowEditCommunityModal: data => dispatch(setShowEditCommunityModal(data)),
  setShowManageCommunityModal: data => dispatch(setShowManageCommunityModal(data)),
  fetchCommunity: (urlSlug, postId) => dispatch(fetchCommunity(urlSlug, postId)),
});
const mapStateToProps = state => ({
  showEditCommunity: communityEditModalSelector(state),
  showManageCommunity: communityManageModalSelector(state),
  translations: _.get(translations(state), 'community', {}),
  community: communitySelector(state),
});
export default connect(mapStateToProps, mapDispatchToProps)(toJS(CommunityPageHeader));
