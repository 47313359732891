import _ from 'lodash';
import api from '../../components/community/utils/api';
import { communitySelector } from '../../selectors/community/community-page';
import { fetchCommunityPost } from './community-posts';

/*
 * Constants
 */
export const SET_COMMUNITY_UPLOADS = 'SET_COMMUNITY_UPLOADS';

/*
 * Sync Actions
 */
export const setCommunityUploads = uploads => ({
  type: SET_COMMUNITY_UPLOADS,
  uploads,
});

/*
 * Async Actions
 */
export const fetchCommunityUploads = () => (dispatch, getState) => {
  const community = communitySelector(getState());
  const communityId = _.get(community, 'id', null);
  return new Promise(resolve => {
    api
      .get(`/get/uploads/${communityId}`)
      .then(({ data = {} }) => {
        if (data.uploads) {
          dispatch(setCommunityUploads(data.uploads));
          resolve();
        }
      })
      .catch(() => {})
      .finally(() => {});
  });
};

export const removeCommunityUpload = id => dispatch => {
  return new Promise((resolve, reject) => {
    api
      .delete(`removeAttachment/${id}`)
      .then(() => {
        resolve();
      })
      .catch(({ response }) => {
        const errorMessage = _.get(response, 'data.data');
        if (errorMessage) {
          reject(errorMessage);
        }
      })
      .finally(() => {
        dispatch(fetchCommunityUploads());
        dispatch(fetchCommunityPost());
      });
  });
};
