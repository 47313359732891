import React, { Component } from 'react';
import onClickOutside from 'react-onclickoutside';
import { withRouter } from 'react-router-dom';
import './css/search.scss';
import { Scrollbars } from 'react-custom-scrollbars';
import _ from 'lodash';
import defaultAvatar from './../../assets/images/defaultAvatar.png';
import defaultPlace from './../../assets/images/defaultPlace.png';
import sizedImage from './../../utils/imageHelper';
import PropTypes from 'prop-types';
import searchLoader from './../../assets/svg/search-loader.svg';
import OutsideClickHandler from 'react-outside-click-handler';
import buildLocationUrl from "../../utils/buildLocationUrl";

const getCoordinates = async (query, callback) => {
  let brussels = new window.google.maps.LatLng(50.8346086, 4.3463326);
  let map = new window.google.maps.Map(document.getElementById('searchMap'), {
    center: brussels,
    zoom: 8,
    scrollwheel: false,
  });
  let request = {
    placeId: query
  };
  let service = new window.google.maps.places.PlacesService(map);
  service.getDetails(request, function(place, status) {
    if (status === window.google.maps.places.PlacesServiceStatus.OK) {
      callback(place);
    }
  });
};

class Search extends Component {
  context;

  static contextTypes = {
      router: PropTypes.object,
  };

  constructor(props){
    super(props);

    this.state = {
      typing: false,
      results: null,
      query: '',
      selectedResultIndex: 0,
      globalSearchSelectedLi: null
    }

    this.goToPlace = this.goToPlace.bind(this);
  }

  getCities = async (query) => {
    if (!query.trim())
      return [];
  }

  handleChange =  (e) => {
    let text = e.target.value;
    this.setState({
     query: text
    }, this.delaySearch());
  }

  handleGlobalSearchLiSelectionChange = (index) => {
      let elementKey = "liElement" + this.state.selectedResultIndex;
      let selectedLi = this.refs[elementKey];
      let oneElementHeight = 0;
      if(selectedLi !== undefined)
      {
          oneElementHeight = selectedLi.offsetHeight;
      }
      if(this.refs[elementKey] != null){
          this.setState({globalSearchSelectedLi: selectedLi});
      }
      if(typeof selectedLi !== "undefined"){

          let elementStartsAtPosition = oneElementHeight * index;
          let scrollbarValues = this.refs.scrollbar.getValues();
          if(elementStartsAtPosition + oneElementHeight  > scrollbarValues.scrollTop + this.refs.scrollbar.getClientHeight()){
              let scrollingValue = oneElementHeight - ((scrollbarValues.scrollTop + this.refs.scrollbar.getClientHeight()) % oneElementHeight);
                if(scrollingValue === 0){
                    scrollingValue = oneElementHeight;
                }
              this.refs.scrollbar.scrollTop(scrollingValue + scrollbarValues.scrollTop);
          }

          if(elementStartsAtPosition < scrollbarValues.scrollTop){
              let scrollingValue = 0;
              if(elementStartsAtPosition !== 0){
                  let scrollingDifference = scrollbarValues.scrollTop % oneElementHeight;
                  if(scrollingDifference === 0){
                      scrollingDifference = oneElementHeight;
                  }
                  scrollingValue = scrollbarValues.scrollTop - scrollingDifference;
              }
              this.refs.scrollbar.scrollTop(scrollingValue);
          }
      }
  }

  handleResultMouseOver = (index) => {
    this.setState({selectedResultIndex: index});
    this.handleGlobalSearchLiSelectionChange(index);
  }


   handleEnterOnSelectedOptionForSearch = () => {
      if(this.state.globalSearchSelectedLi != null){
          this.state.globalSearchSelectedLi.click();
      }
   }

  handleKeyUp =  (e) => {
    if(e.nativeEvent.keyCode === 13){//ENTER
        this.handleEnterOnSelectedOptionForSearch();
    }
    if(e.nativeEvent.keyCode === 38 || e.nativeEvent.keyCode === 40)
    {
      if(this.props.globalSearchResults !== null)
      {
          let maxResultIndex = -1;
          if(this.props.globalSearchResults !== null)
          {
              if(this.props.globalSearchResults.q !== undefined)
              {
                maxResultIndex = -1;
              }

              if(this.props.globalSearchResults.offices !== undefined && this.props.globalSearchResults.offices.length > 0)
              {
                maxResultIndex += this.props.globalSearchResults.offices.length;
              }

              if(this.props.globalSearchResults.addresses !== undefined && this.props.globalSearchResults.addresses.length > 0)
              {
                maxResultIndex += this.props.globalSearchResults.addresses.length;
              }

              if(this.props.globalSearchResults.heroes !== undefined && this.props.globalSearchResults.heroes.length > 0)
              {
                maxResultIndex += this.props.globalSearchResults.heroes.length;
              }
          }

          let nextIndex = this.state.selectedResultIndex;
          if(this.state.selectedResultIndex > 0 && e.nativeEvent.keyCode === 38) // UP
          {
              nextIndex = nextIndex - 1;
          }
          if(this.state.selectedResultIndex < maxResultIndex && e.nativeEvent.keyCode === 40) // DOWN
          {
              nextIndex = nextIndex + 1;
          }
          this.setState({selectedResultIndex : nextIndex}, () => {
              this.handleGlobalSearchLiSelectionChange(nextIndex);
          });
      }
    }

    let state = ((this.state.query.length !== 0 && e.nativeEvent.keyCode !== 27 ) ? true : false);
    if(state !== this.state.typing)
    {
      this.setState({
        typing: state
      });
    }
  }

  delaySearch = _.debounce(function(e){
    this.props.globalSearchTrigger();
    this.props.globalSearch(this.state.query);
      if (this.props.handleNonExistingMembers) {
          if(this.state.query.length) {
              this.props.handleNonExistingMembers(this.state.query);
          } else {
              this.props.handleNonExistingMembers(null);
          }
      }
  }, 300);

  componentDidUpdate = () => {
    if(this.state.globalSearchSelectedLi === null && this.props.globalSearchResults !== null)
    {
      this.handleGlobalSearchLiSelectionChange(0);
    }
  }

  handleClickOutside = evt => {
        this.setState({
            typing: false,
            query: ''
        });
    }

  handleFocus = e => {
    e.preventDefault();
    let state = ((this.state.query !== 0) ? true : false);
    this.setState({
      typing: state
    });
  }

  handleClick = result => {
      const { isAuth, history } = this.props;
      if (!isAuth) {
          history.replace('/login');
      }
      const resultId = _.get(result, 'id', null);
      const resultName = _.get(result, 'firstName', null) + ' ' + _.get(result, 'lastName', null);
      this.setState({
          typing: false,
          results: null,
          query: '',
          selectedResultIndex: 0,
          globalSearchSelectedLi: null
      });
      this.props.handleClick(resultId, resultName);
      if (this.props.handleNonExistingMembers) {
          this.props.handleNonExistingMembers(null);
      }
  }

  async goToPlace (resultId) {
    let search = this;
    await getCoordinates(resultId, function(result){
        search.props.onLocationChange(result.geometry.location); //, result.name); // ie Antwerp
        search.setState({
          typing: false,
          query: '',
          results: null
        });
    }
  )}


    renderResults(noResults) {

    let results = [];

    if(this.props.globalSearching === true)
    {
      results.push(<li style={{display: 'flex', padding: '8px 19px 9px 19px'}} key="searching">{this.props.trans.header.searching} <img style={{height: '28px', marginLeft: '30px'}} alt="" src={searchLoader} /></li>);
    }
    else
    {
      if(noResults === 0)
      {
         results.push(<li key="no-results">No results</li>);
      }
      if(this.props.globalSearchResults !== null)
      {
          if(this.props.globalSearchResults.q !== undefined)
          {
            return null;
          }
          if(this.props.globalSearchResults.heroes !== undefined && this.props.globalSearchResults.heroes.length > 0)
          {
              this.props.globalSearchResults.heroes.map((result) => {
                  let currentIndex = results.length;
                  results.push(
                      <li
                          ref={"liElement" + currentIndex} onMouseOver={() => {this.handleResultMouseOver(currentIndex);}}
                          className={this.state.selectedResultIndex === results.length ? 'selectedResult heroResult' : 'heroResult'}
                          key={result.id+'h'}
                          onClick={() => {this.handleClick(result)}}
                          style={{lineHeight: '40px', cursor: 'pointer', display: 'flex' }}
                      >
                          {result.imageUrl ? (
                              <img alt="" style={{height: '40px', width: '40px', marginRight: '10px'}} src={result.imageUrl !== null ? result.imageUrl : defaultAvatar} />
                          ) : (
                              <div className='hero-initials search-hero-initials'>{result.heroInitials}</div>
                          )}
                          {result.firstName} {result.lastName}
                      </li>
                  );
                  return null;
              });
          }

          if(this.props.globalSearchResults.offices !== undefined && this.props.globalSearchResults.offices.length > 0)
          {

             this.props.globalSearchResults.offices.map((result) => {
               let currentIndex = results.length;
               results.push(<li ref={"liElement" + currentIndex} onMouseOver={() => {this.handleResultMouseOver(currentIndex);}} className={this.state.selectedResultIndex === results.length ? 'selectedResult officeResult' : 'officeResult'} key={result.id+'o'} onClick={() => { window.location = buildLocationUrl(result) }} style={{lineHeight: '40px', cursor: 'pointer', display: 'flex' }} ><img alt="" style={{height: '40px', width: '40px', marginRight: '10px'}} src={result.iamgeUrl !== null ? sizedImage(result.imageUrl, 40, 40) : null} />{result.name}</li>);
               return null;
             });
          }

          if(this.props.globalSearchResults.addresses !== undefined && this.props.globalSearchResults.addresses.length > 0)
          {
             this.props.globalSearchResults.addresses.map((result) => {
               let currentIndex = results.length;
               results.push(<li ref={"liElement" + currentIndex} onMouseOver={() => {this.handleResultMouseOver(currentIndex);}} className={this.state.selectedResultIndex === results.length ? 'selectedResult addressResult' : 'addressResult'} key={result.placeId+'a'} onClick={() => {  this.props.history.push('/booking'); this.goToPlace(result.placeId);}} style={{lineHeight: '40px', cursor: 'pointer', display: 'flex' }} ><img alt="" style={{height: '40px', width: '40px', marginRight: '10px'}} src={defaultPlace} /> {result.name}</li>);
               return null;
             });
          }

      }
    }

    return (results);
  }

  render () {
    const typed = (this.state.typing ? ' search-typed' : '');
    const userName = this.props.userName;
    let noResults = 0;
    if(this.props.globalSearchResults !== null)
    {
        if(this.props.globalSearchResults.offices !== undefined && this.props.globalSearchResults.offices.length > 0)
        {
           this.props.globalSearchResults.offices.map((result) => {
             noResults++;
             return null;
           });
        }

        if(this.props.globalSearchResults.addresses !== undefined && this.props.globalSearchResults.addresses.length > 0)
        {
           this.props.globalSearchResults.addresses.map((result) => {
             noResults++;
             return null;
           });
        }

        if(this.props.globalSearchResults.heroes !== undefined && this.props.globalSearchResults.heroes.length > 0)
        {
           this.props.globalSearchResults.heroes.map((result) => {
             noResults++;
             return null;
           });
        }
    }

    let searchBarResultsHeight = 0;
    if(this.props.globalSearching === true)
    {
      searchBarResultsHeight = '46px';
    }
    else
    {
      if(noResults >= 3)
      {
        searchBarResultsHeight = '186px';
      }
      else
      {
        if(noResults !== 0)
        {
           searchBarResultsHeight = 'calc(' + noResults * 63 + 'px)';
        }
      }
    }

    let placeHolder = '';
    if(!_.get(this.props, 'placeholder', null)) {
        let trans = this.props.trans;

        if(trans !== null)
        {
            placeHolder = trans.formatString(trans.header.searchPlaceholder, [userName]);
        }
    } else {
        placeHolder = this.props.placeholder;
    }

    return (
        <OutsideClickHandler onOutsideClick={() => {
            this.handleClickOutside();
        }}>
          <div className={`search ${typed} --disabled`}>
            <input
                disabled={this.props.disabled}
                onClick={() => { this.setState({typing: true}, ()=>{this.nameInput.focus();}); this.props.globalSearch('')}}
                className="search-input"
                type="text"
                placeholder={placeHolder}
                onChange={this.handleChange}
                onKeyUp={this.handleKeyUp}
                value={this.state.query}
                onFocus={()=>{  }}
                onBlur={() => { if(this.state.typing === true){ this.nameInput.focus(); }}}
                ref={(input) => { this.nameInput = input; }}
            />
            <div className="search-results" style={{ height: searchBarResultsHeight }}>
              <Scrollbars
                  ref={"scrollbar"}
              >
                <ul>
                  { this.renderResults(noResults) }
                </ul>
              </Scrollbars>
            </div>
          </div>
        </OutsideClickHandler>
    );
  }
};

export default withRouter(Search);
