import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import Truncate from 'react-truncate';
import _ from 'lodash';
import accordionSvg from '../../../assets/svg/accordion/accordion-arrow-down.svg';
import meetingAccepted from '../../../assets/svg/meeting/meeting-accepted.svg';
import meetingDeclined from '../../../assets/svg/meeting/meeting-declined.svg';
import Dropdown from '../dropdown';
import greaterSign from '../../../assets/svg/meeting/greater.svg';

class Accordion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
    this.togglePanel = this.togglePanel.bind(this);
    this.buildOrganiserSection = this.buildOrganiserSection.bind(this);
    this.buildAttendeeResponse = this.buildAttendeeResponse.bind(this);
    this.buildContent = this.buildContent.bind(this);
    this.changeResponse = this.changeResponse.bind(this);
    this.renderBookingFee = this.renderBookingFee.bind(this);
  }

  togglePanel(e) {
    this.setState({ open: !this.state.open });
  }

  changeResponse(action) {
    const { meetingAttendeeId, declineMeetingInvitation, acceptMeetingInvitation, bookingId, officeId } = this.props;
    if (action === 'declined') {
      declineMeetingInvitation(meetingAttendeeId, action, bookingId, officeId);
    }
    if (action === 'accepted') {
      acceptMeetingInvitation(meetingAttendeeId, action, bookingId, officeId);
    }
  }

  buildOrganiserSection(noOfInvited) {
    const { translations } = this.props;
    if (noOfInvited)
      return (
        <div className="accordion_meeting-default-response">
          <div className="no-of-invited">
            {noOfInvited} {_.get(translations, 'meetings.invited', '')}
          </div>
        </div>
      );

    return <div className="accordion_meeting-default-response"> {_.get(translations, 'meetings.noInviteSent', '')}</div>;
  }

  buildAttendeeResponse(meetingStatus) {
    const { translations } = this.props;
    if (meetingStatus === 'accepted')
      return (
        <div className="accordion_meeting-response">
          <img src={meetingAccepted} className="response_caret" alt="Meeting Accepted" />
          {_.get(translations, 'meetings.accepted', '')}
        </div>
      );
    if (meetingStatus === 'declined')
      return (
        <div className="accordion_meeting-response">
          <img src={meetingDeclined} className="response_caret" alt="Meeting Declined" />
          {_.get(translations, 'meetings.declined', '')}
        </div>
      );
    return <div className="accordion_meeting-default-response"> {_.get(translations, 'meetings.rspv', '')}</div>;
  }

  renderBookingFee() {
    const { bookingFee, translations } = this.props;
    if (bookingFee === 0) {
      return null;
    }

    return (
      <div className="accordion_meeting-hours">
        <span>{_.get(translations, 'meetings.bookingFee', '')} </span>
        {bookingFee} &euro;
      </div>
    );
  }

  buildContent() {
    const {
      invitedBy,
      invitedByProfile,
      meetingHours,
      isOrganiser,
      meetingStatus,
      noOfInvited,
      noOfAccepted,
      bookingId,
      bookingStatus,
      bookingDays,
      translations,
      alreadyStarted,
    } = this.props;
    
    const dropdownIsDisabled = alreadyStarted || bookingStatus === 'closed' || bookingStatus === 'canceled';
    const bookingFeeInfo = this.renderBookingFee();

    if (isOrganiser)
      return (
        <div className="accordion__content">
          <div className="accordion_attendees">
            <div className="attendees-text">{_.get(translations, 'meetings.attendees', '')}</div>
            <div className="attendees-no">
              {noOfAccepted} {_.get(translations, 'meetings.accepted', '')} <span>/{noOfInvited}</span>
            </div>
          </div>
          <div className="accordion_meeting-hours with-margin-bottom">
            <span>{_.get(translations, 'meetings.meetingHours', '')}</span>
            {meetingHours}
          </div>
          <div className="accordion_meeting-hours with-margin-bottom">
            <span>{_.get(translations, 'meetings.chosenDays', '')}</span>
            <Truncate title={bookingDays} lines={1} ellipsis={<>...</>} width={150} className="working-days">
              {bookingDays}
            </Truncate>
          </div>
          {bookingFeeInfo}
        </div>
      );

    return (
      <div className="accordion__content">
        <div className="accordion_invited-by">
          <span>{_.get(translations, 'meetings.invitedBy', '')}</span>
          <Link to={invitedByProfile} className="accordion_hero-profile">
            {invitedBy}
          </Link>
        </div>
        <div className="accordion_meeting-hours with-margin-bottom">
          <span>{_.get(translations, 'meetings.meetingHours', '')}</span>
          {meetingHours}
        </div>
        <div className="accordion_meeting-hours">
          <span>{_.get(translations, 'meetings.chosenDays', '')}</span>
          <Truncate title={bookingDays} lines={1} ellipsis={<>...</>} width={150} className="working-days">
            {bookingDays}
          </Truncate>
        </div>
        <div className={classnames({ 'accordion__dropdown--disabled': dropdownIsDisabled })}>
          <Dropdown onChange={this.changeResponse} selectedValue={meetingStatus} bookingId={bookingId} />
        </div>
      </div>
    );
  }

  render() {
    const { title, officeLink, officeName, isOrganiser, meetingStatus, noOfInvited, bookingStatus } = this.props;
    const { open } = this.state;

    return (
      <div className="accordion__section">
        <div onClick={e => this.togglePanel(e)} className="accordion">
          <p className={classnames('accordion__title', { 'accordion__title--cancelled': bookingStatus === 'canceled' })}>{title}</p>
          <img src={accordionSvg} className={!open ? 'accordion__icon' : 'accordion__icon rotate'} alt="Accordion Arrow" />
        </div>
        <div className="accordion_office">
          <>{isOrganiser ? this.buildOrganiserSection(noOfInvited) : this.buildAttendeeResponse(meetingStatus)}</>
          <span className="accordion_office-comma">{', '}</span>
          <Link to={officeLink} className="accordion_office-name">
            {officeName} <img src={greaterSign} className="accordion_office-greater" alt="Greater" />
          </Link>
        </div>
        {open ? <>{this.buildContent()}</> : null}
      </div>
    );
  }
}

export default Accordion;
