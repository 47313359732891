import { connect } from 'react-redux';
import DateTimePicker from './DateTimePicker';
import { selectedDateSelector, selectedProductIdSelector, selectedProductTypeSelector } from '../../../../../selectors/whiteLabel/booking';
import { bookingValidate } from '../../../../../actions/whiteLabel/booking';
import { mapProductsSelector } from '../../../../../selectors/whiteLabel/products';
import _ from "lodash";

const mapStateToProps = state => {
  const data = state.location.get('details').toJS();
  return {
    whiteLabel: _.get(data, 'white_label', []),
    translations: state.translations,
    officeOpenDaysAndHours: state.location.get('details').get('openingHours'),
    selectDateRange: selectedDateSelector(state),
    products: mapProductsSelector(state),
    selectedProductId: selectedProductIdSelector(state),
    selectedProductType: selectedProductTypeSelector(state),
    officeTimezone: state.location.get('details').get('timezone'),
  };
};

const mapDispatchProps = dispatch => {
  return {
    bookingValidate: data => dispatch(bookingValidate(data)),
  };
};

export default connect(mapStateToProps, mapDispatchProps)(DateTimePicker);
