import React, { Component } from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import FacebookLogin from 'react-facebook-login';
import GoogleLogin from 'react-google-login';
import classnames from 'classnames';
import LinkedinButton from '../../linkedinButton/linkedin';
import InfoToast from '../../community/common/info-toast/InfoToast';
import FacebookIcon from '../../../assets/images/badges/badgeFacebook.png';
import EmailIcon from '../../../assets/svg/mail.svg';
import loginRedirects from '../utils/loginRedirects';
import {getCurrentHostname} from "../../../utils/currentCompanyHostname";

const { facebookAppId } = window.__env;
const { googleAppId } = window.__env;

class ThirdPartyAuth extends Component {
  constructor(props) {
    super(props);
    this.responseAuth = this.responseAuth.bind(this);
    this.checkPreviousAuthentication = this.checkPreviousAuthentication.bind(this);
    this.state = {
      hasPreviousAuth: false,
      clickedButton: null,
      isLoading: false,
      showToast: false,
      toastMessage: null,
    };
    this.showInfoToast = this.showInfoToast.bind(this);
  }

  componentDidMount() {
    const { history, location } = this.props;
    const errorHash = location.hash || null;
    const errorCode = errorHash && errorHash.includes('#error=') ? `${errorHash.replace('#error=', '')}` : null;
    if (errorCode) {
      this.showInfoToast(errorCode);
      history.push('/login');
    }
  }

  showInfoToast(errorCode) {
    this.setState({ showToast: true, toastMessage: errorCode });
    return setTimeout(() => {
      this.setState({ showToast: false });
    }, 7000);
  }

  responseAuth(response) {
    const { auth, history, location, updateRegisteredAttendee, setIsWhiteLabel, joinPrivateCommunity } = this.props;
    const { isLoading } = this.state;
    let token = '';
    let source = '';
    if (!isLoading) {
      this.setState({ isLoading: true });
    }

    if (_.get(response, 'tokenId', '')) {
      token = _.get(response, 'tokenId', '');
      source = 'google';
    } else if (_.get(response, 'accessToken', '')) {
      token = _.get(response, 'accessToken', '');
      source = 'facebook';
    }
    if (!_.get(response, 'email', null) && source === 'facebook') {
      this.showInfoToast('emailSharingRequired');
      history.push('/login');
    }
    const data = {
      token,
      source,
    };
    if (token) {
      auth(data)
        .then(resp => {
          localStorage.removeItem('invitation-key');
          localStorage.removeItem('invitation-email');
          localStorage.removeItem('invitation-whitelabel');
          localStorage.setItem('source', source);
          const accountType = _.get(resp, 'data.heroData.account', null);
          const fromUrl = _.get(location, 'fromUrl', location.pathname);
          const historyState = { fromBooking: _.get(location, 'state.fromBooking', false) };
          if (accountType === 'existing') {
            loginRedirects(resp, fromUrl, historyState, setIsWhiteLabel, history);
          } else {
            const attendeeKey = localStorage.getItem('meeting-attendee-key');
            if (attendeeKey) {
              const meetingId = localStorage.getItem('meeting-id');
              updateRegisteredAttendee({ attendeeKey, meetingId });
            }
            const communityUrl = localStorage.getItem('community-join-url');
            if (communityUrl) {
              joinPrivateCommunity(communityUrl);
            }
            history.push({
              pathname: '/profile',
              fromUrl,
              state: {
                from: location.pathname,
                newAccount: true,
                fromBooking: _.get(location, 'state.fromBooking', false),
              },
            });
          }
        })
        .catch(error => {
          const errorCode = _.get(error, 'response.data.error', 'generalError');
          const { isWmsApp } = window.__env;
          if (errorCode === 'wms' && !isWmsApp) {
            const subdomain = _.get(error, 'response.data.subdomain', null);
            if (subdomain) {
              window.location.href = `${window.__env.baseUrl.replace(getCurrentHostname(), subdomain)}/login`;
            } else {
              setIsWhiteLabel(true);
            }
          } else {
            this.showInfoToast(errorCode);
            history.push('/login');
          }
        })
        .finally(() => this.setState({ isLoading: false }));
    } else {
      this.setState({ isLoading: false });
    }
  }

  shouldDisplayPreviousModal(clickedButton) {
    const { hasPreviousAuth } = this.state;
    this.setState({ hasPreviousAuth: !hasPreviousAuth, clickedButton });
  }

  checkPreviousAuthentication(evt) {
    const previousAuthentication = localStorage.getItem('source', null);
    if (previousAuthentication && previousAuthentication !== evt.currentTarget.className) {
      this.shouldDisplayPreviousModal(evt.currentTarget.className);
      evt.stopPropagation();
    }
  }

  handleLoginChoice(data) {
    const { translations, handleEmailButtonClick } = this.props;
    const actionWithEmail = _.get(translations, 'emailAction', 'Log in with email');
    let actionMessage = _.get(translations, '3rdPartyAction', 'Log in with');
    if (this.state.clickedButton === data) {
      actionMessage = _.get(translations, 'continueWith', 'Continue with');
    }
    let labelButtonClicked = '';
    let action = '';
    switch (data) {
      case 'google':
        labelButtonClicked = `${actionMessage} Google`;
        action = (
          <GoogleLogin
            clientId={googleAppId}
            onSuccess={response => this.responseAuth(response)}
            onFailure={response => this.responseAuth(response)}
            cookiePolicy="single_host_origin"
            buttonText={labelButtonClicked}
            className="login-form__third-party-button google-login-button"
            icon
          />
        );
        break;
      case 'facebook':
        labelButtonClicked = `${actionMessage} Facebook`;
        action = (
          <FacebookLogin
            appId={facebookAppId}
            fields="name,email,picture"
            callback={this.responseAuth}
            textButton={labelButtonClicked}
            cssClass="login-form__third-party-button facebook-login-button"
            icon={<img src={FacebookIcon} alt="facebook" className="facebook-login-icon" />}
          />
        );
        break;
      case 'linkedin':
        action = <LinkedinButton clickedButton={this.state.clickedButton === data} />;
        break;
      default:
        action = (
          <button
            type="button"
            className="dashboard-button dashboard-button--border auth-button"
            onClick={() => {
              handleEmailButtonClick();
              this.shouldDisplayPreviousModal(null);
            }}
          >
            <img src={EmailIcon} alt="email" className="email-login-icon" />
            <p className="auth-button-text">{actionWithEmail}</p>
          </button>
        );
        break;
    }
    return action;
  }

  renderPreviousAuthentication() {
    const { translations } = this.props;
    const previousAuthentication = localStorage.getItem('source', null);
    return (
      <div className={classnames('base-modal', 'previous-auth-check')}>
        <div className="base-modal__modal-overlay" />
        <div className="base-modal__modal-content">
          <div className="base-modal__left-area">
            <span className="icon icon-booking base-modal__left-area-icon" />
          </div>
          <div className="base-modal__right-area">
            <div className="base-modal__modal-header">
              <div className="base-modal__modal-header-title">
                {_.get(translations, 'alreadyHaveAccount', 'It looks like you already have an account.')}
              </div>
              <span className="base-modal__close" onClick={this.checkPreviousAuthentication.bind(this)}>
                &times;
              </span>
            </div>
            <div className="base-modal__modal-body">
              <div className="base-modal__confirmation-modal">
                <div className="base-modal__confirmation-message">
                  {_.get(
                    translations,
                    'previousConnection',
                    'We detected that you previously used #previousConnection# method to register into this platform\n'
                  ).replace('#previousConnection#', `${previousAuthentication[0].toUpperCase()}${previousAuthentication.slice(1)}`)}
                </div>
                <div className="base-modal__confirmation-actions">
                  {this.handleLoginChoice(localStorage.getItem('source', null))}
                  <span className="base-modal__conjunction">{_.get(translations, 'or', 'or')}</span>
                  {this.handleLoginChoice(this.state.clickedButton)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { translations, handleEmailButtonClick, openAuthTranslations } = this.props;
    const { isLoading, showToast, toastMessage } = this.state;
    const actionMessage = _.get(translations, '3rdPartyAction', 'Log in with');
    const actionWithEmail = _.get(translations, 'emailAction', 'Log in with email');
    const GoogleAction = `${actionMessage} Google`;
    const FacebookAction = `${actionMessage} Facebook`;

    if (isLoading) return <div className="Loader" />;

    return (
      <div className="login-form__third-party-container">
        {showToast && <InfoToast message={_.get(openAuthTranslations, toastMessage, 'generalError')} />}
        <div className="login-form__row">
          <div className="login-form__columns">
            {this.state.hasPreviousAuth ? this.renderPreviousAuthentication() : null}
            <div className="linkedin" onClick={this.checkPreviousAuthentication.bind(this)}>
              <span
                className={classnames('wrapper', {
                  'deactivate-events': localStorage.getItem('source', null) && localStorage.getItem('source', null) !== 'linkedin',
                })}
              >
                <LinkedinButton translations={translations} />
              </span>
            </div>
            <div className="facebook__disabled">
              <span
                className={classnames('wrapper', {
                  'deactivate-events': localStorage.getItem('source', null) && localStorage.getItem('source', null) !== 'facebook',
                })}
              >
                <FacebookLogin
                  // appId={facebookAppId}
                  // fields="name,email,picture"
                  // callback={this.responseAuth}
                  textButton={FacebookAction}
                  cssClass="login-form__third-party-button facebook-login-button facebook__disabled"
                  icon={<img src={FacebookIcon} alt="facebook" className="facebook-login-icon facebook__disabled" />}
                />
              </span>
            </div>
          </div>
        </div>
        <div className="login-form__row">
          <div className="login-form__columns">
            <div className="google" onClick={this.checkPreviousAuthentication}>
              <span
                className={classnames('wrapper', {
                  'deactivate-events': localStorage.getItem('source', null) && localStorage.getItem('source', null) !== 'google',
                })}
              >
                <GoogleLogin
                  clientId={googleAppId}
                  onSuccess={response => this.responseAuth(response)}
                  onFailure={response => this.responseAuth(response)}
                  cookiePolicy="single_host_origin"
                  buttonText={GoogleAction}
                  className="login-form__third-party-button google-login-button"
                  icon
                />
              </span>
            </div>
            <div className="email" onClick={this.checkPreviousAuthentication}>
              <span
                className={classnames('wrapper', {
                  'deactivate-events': localStorage.getItem('source', null) && localStorage.getItem('source', null) !== 'email',
                })}
              >
                <button
                  type="button"
                  className="dashboard-button dashboard-button--border auth-button"
                  onClick={() => handleEmailButtonClick()}
                >
                  <img src={EmailIcon} alt="email" className="email-login-icon" />
                  <p className="auth-button-text">{actionWithEmail}</p>
                </button>
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ThirdPartyAuth);
