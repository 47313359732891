import React, { Component } from 'react';
import PropTypes from 'prop-types';
import onClickOutside from 'react-onclickoutside';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import InfiniteCalendar, {
  Calendar,
  withRange,
} from 'react-infinite-calendar';
import 'react-infinite-calendar/styles.css'; // only needs to be imported once
import _ from 'lodash';

import 'react-datepicker/dist/react-datepicker.css';
import './css/index.scss';

import {DATE_FORMAT, DEFAULT_TIMEZONE} from '../../constants';
import classnames from "classnames";
import getLang from '../../utils/lang';

const lang = getLang() !== null ? getLang() : 'en';
const localeFR = require('react-infinite-calendar/node_modules/date-fns/locale/fr');
const localeNL = require('react-infinite-calendar/node_modules/date-fns/locale/nl');
const localeEN = require('react-infinite-calendar/node_modules/date-fns/locale/en');
const localeES = require('react-infinite-calendar/node_modules/date-fns/locale/es');
const localeIT = require('react-infinite-calendar/node_modules/date-fns/locale/it');
// const DATE_FORMAT = 'MMM D, YYYY';
const CalendarWithRange = withRange(Calendar);

class DatePicker extends Component {
  constructor(props) {
    super(props);

    this.state = {
      inputValue: '',
      startDate: null,
      endDate: null,
      previousStartDate: null,
      expanded: false
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let iV = '';
    if(nextProps.startDate !== null && nextProps.endDate !== null)
    {
      iV = this.getInputValue(moment(nextProps.startDate, DATE_FORMAT).locale(lang), moment(nextProps.endDate, DATE_FORMAT).locale(lang));
    } else if(nextProps.startDate !== null && nextProps.single) {
      iV = this.getInputValue(moment(nextProps.startDate, DATE_FORMAT).locale(lang));
    }
    if(this.state.inputValue !== iV)
    {
      this.setState({
        inputValue: iV
      });
    }

    if(this.state.previousStartDate == null)
    {
      this.setState({previousStartDate: this.state.startDate });
    }
  }

  componentDidMount() {
    const { startDate, endDate, single } = this.props;
    if(startDate !== null && endDate !== null)
    {
      this.setState({
        startDate: moment(startDate, DATE_FORMAT),
        endDate: moment(endDate, DATE_FORMAT),
        inputValue: this.getInputValue(moment(startDate, DATE_FORMAT), moment(endDate, DATE_FORMAT))
      });
    }
    if (startDate !== null && single) {
      this.setState({
        startDate: moment(startDate, DATE_FORMAT),
        inputValue: this.getInputValue(moment(startDate, DATE_FORMAT))
      });
    }
  }

  handleClickOutside = (e) => {
    const {startDate, expanded} = this.state
    // if you select start date and then click outside, keep the selection and
    // check for dispo products
    if(startDate && expanded) {
      this.props.onChange(moment(startDate).format(DATE_FORMAT), moment(startDate).format(DATE_FORMAT));
      this.setState({
        expanded: false,
        inputValue: this.getInputValue(moment(startDate), moment(startDate))
      }, () => {
        if(this.props.toggleDateExpanded !== undefined) this.props.toggleDateExpanded(false);
      });
    } else {
      this.setState({
        expanded: false,
        startDate: null,
        endDate: null,
      }, () => {
        if(this.props.toggleDateExpanded !== undefined) this.props.toggleDateExpanded(false);
      });
    }
  }

  getInput = () => {
    return (
      <input
        readOnly
        type="text"
        value={this.state.inputValue}
        className="datepicker-input"
        placeholder={this.props.placeholder === undefined ? 'Select date range' : this.props.placeholder}/>
    )
  }

  getInputValue = (startDate, endDate) => {
    let inputValue;
    if (!startDate ||  !startDate instanceof moment) {
      return '';
    }
    if (startDate) {
      inputValue = startDate.format(DATE_FORMAT);
    }
    if (endDate && endDate instanceof moment && !startDate.isSame(endDate, 'day')) {
      inputValue = `${inputValue} - ${endDate.format(DATE_FORMAT)}`;
    }
    return inputValue;
  }

  handleChangeRange(date) {
    const { single, onChange } = this.props;
    if (single) {
      this.setState({ startDate: moment(date, DATE_FORMAT), inputValue: this.getInputValue(moment(date.start)) });
      setTimeout(() => this.setState({ expanded: false, startDate: null, endDate: null }, () => { if(this.props.toggleDateExpanded !== undefined)  this.props.toggleDateExpanded(false); }));
      return onChange(date);
    }
    switch(date.eventType)
    {
      case 1:
        this.setState({
          startDate: moment(date.start),
          endDate: moment(date.end),
          inputValue: this.getInputValue(moment(date.start).locale(lang), moment(date.end).locale(lang))
        });
        break;

      case 3:
        this.props.onChange(moment(date.start).format(DATE_FORMAT), moment(date.end).format(DATE_FORMAT));
        this.setState({
          startDate: moment(date.start),
          endDate: moment(date.end),
          inputValue: this.getInputValue(moment(date.start), moment(date.end))
        }, function(){
          this.setState({
            expanded: false
          }, () => {
            if(this.props.toggleDateExpanded !== undefined) this.props.toggleDateExpanded(false);
          });
        });
        break;
      default:
        break;
    }
  }

  getContainer = () => {
    const { single, maxDate, startDate, officeTimezone } = this.props;

    let disabledDates = [];
    if(this.props.excludeDates !== undefined)
    {
      this.props.excludeDates.map((date) => {
        disabledDates.push(date.toDate());
        return null;
      });
    }
    let width = this.props.width !== undefined ? this.props.width : '100%';
    let disabledDays = (this.props.disabledDays !== undefined && this.props.disabledDays.length > 0) ? this.props.disabledDays : null;
    let now = new Date();
    let maxRenderDate = new Date();
    maxRenderDate.setYear(now.getFullYear() + 5);
    let locale = this.props.trans.booking.options.calendarLocale;
    locale.weekStartsOn = parseInt(locale.weekStartsOn, 10);
    switch(lang)
    {
      case 'fr':
        locale.locale = localeFR;
        break;
      case 'nl':
        locale.locale = localeNL;
        break;
      case 'es':
        locale.locale = localeES;
        break;
      case 'it':
        locale.locale = localeIT;
        break;
      case 'en':
      default:
        locale.locale = localeEN;
        break;
    }
    const dynamicProps = {};
    const minDate = moment().tz(officeTimezone || DEFAULT_TIMEZONE);
    let sD = this.state.startDate !== null ? this.state.startDate.toDate() : minDate;
    let eD = this.state.endDate !== null ? this.state.endDate.toDate() : minDate;

    if (single) {
      if (!this.state.startDate && startDate) {
        const newDate = moment(startDate, DATE_FORMAT);
        if (newDate.isValid()) {
          sD = newDate.toDate();
        }
      }
      dynamicProps.selected = sD;
    } else {
      dynamicProps.selected = {
        start: sD,
        end: eD,
      }
      dynamicProps.Component = CalendarWithRange;
    }
    if (maxDate) {
      dynamicProps.maxDate = maxDate;
    }

    return(
      <div className="infinite-calendar">
        <InfiniteCalendar
          {...dynamicProps}
          disabledDays={disabledDays}
          disabledDates={disabledDates}
          width={width}
          rowHeight={32}
          min={minDate}
          max={maxRenderDate}
          minDate={minDate}
          onSelect={(date) => {this.handleChangeRange(date)}}
          displayOptions={{
            showHeader: false,
            showTodayHelper: false
          }}
          locale={locale}
        />
      </div>
    );
  }

  expandDatePicker = () => {
    this.setState({expanded: true}, () => {
      if(this.props.toggleDateExpanded !== undefined) this.props.toggleDateExpanded(true);
    })
  }

  render () {
    let { expanded, inputValue } = this.state;
    const { isDatepickerOnFilterMap, className, placeHolder, customText } = this.props;
    if (isDatepickerOnFilterMap) {
      return (
        <div className={classnames({ expanded }, className, 'datepicker')}>
          <div className= {classnames("dropdown-input-field", {'hasDateSelected': inputValue})}  onClick={this.expandDatePicker}>
            <span className="dropdown-field-title">{customText}</span>
            <span className={classnames("dropdown-field-value", {'hasDateSelected': inputValue})} >{ inputValue || placeHolder } </span>
          </div>
          {expanded && this.getContainer()}
        </div>
      );
    }
    return (
      <div className="datepicker" onClick={this.expandDatePicker}>
        {this.getInput()}
        {expanded && this.getContainer()}
      </div>
    );
  }
}

DatePicker.propTypes = {
  expanded: PropTypes.bool,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  onChange: PropTypes.func,
  single: PropTypes.bool,
};

DatePicker.defaultProps = {
  expanded: false,
  startDate: null,
  endDate: null,
  single: false,
};


const mapStateToProps = state => ({
  trans: state.translations,
});

export default connect(mapStateToProps)(onClickOutside(DatePicker));
