import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

/*
 * Component creates a brick effect on the elements used by it
 * Receives a list of breakpoint
 * The number of breakpoints is the number of initial displayed columns
 * The value of the breakpoints is used for checking the masonry column width
 * */

class Masonry extends React.Component {
  constructor(props) {
    super(props);
    this.state = { columns: 1 };
    this.masonry = React.createRef();
    this.onResize = this.onResize.bind(this);
  }

  componentDidMount() {
    this.onResize();
    window.addEventListener('resize', this.onResize);
  }

  onResize() {
    const mansonryColumns = this.getColumns(_.get(this.masonry, 'current.offsetWidth'));
    const { columns } = this.state;
    if (mansonryColumns !== columns) {
      this.setState({ columns: mansonryColumns });
    }
  }

  getColumns(w) {
    const { brakePoints } = this.props;
    return (
      brakePoints.reduceRight((p, c, i) => {
        return c < w ? p : i;
      }, brakePoints.length) + 1
    );
  }

  buildChildren() {
    const col = [];
    const { columns } = this.state;
    const { children } = this.props;
    for (let i = 0; i < columns; i += 1) {
      col[i] = [];
    }
    return children
      .reduce((p, c, i) => {
        p[i % columns].push(c);
        return p;
      }, col)
      .map((column, indexColumn) => {
        return (
          // eslint-disable-next-line react/no-array-index-key
          <div className="masonry__column" key={`column-${indexColumn}`}>
            {column.map((child, indexChild) => {
              // eslint-disable-next-line react/no-array-index-key
              return <div key={`child-${indexChild}`}>{child}</div>;
            })}
          </div>
        );
      });
  }

  render() {
    return (
      <div className="masonry" ref={this.masonry}>
        {this.buildChildren()}
      </div>
    );
  }
}

Masonry.propTypes = {
  brakePoints: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number])),
  children: PropTypes.arrayOf(PropTypes.shape({})),
};

Masonry.defaultProps = {
  brakePoints: [],
  children: {},
};

export default Masonry;
