import React from 'react';
import { Provider } from 'react-redux';
import store from './store';
import Main from './components/main/index';
import 'bootstrap/scss/bootstrap.scss';

const App = () => (
  <div style={{ height: 'inherit' }}>
    <Provider store={store}>
      <Main />
    </Provider>
  </div>
);

export default App;
