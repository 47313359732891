import React, { Component } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import commRightArrow from '../../../../../assets/svg/community/community-right-arrow.svg';

class ReportModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reason: null,
      comments: '',
    };
    this.setReason = this.setReason.bind(this);
    this.handleReportText = this.handleReportText.bind(this);
    this.submitData = this.submitData.bind(this);
  }

  setReason(reason) {
    const { toggleNextTitle, translations } = this.props;
    this.setState({ reason }, () => {
      toggleNextTitle(_.get(translations, 'report.tellUsMore', 'report.tellUsMore'));
    });
  }

  handleReportText(event) {
    const comments = event.target.value.length > 0 ? event.target.value : null;

    this.setState({ comments });
  }

  submitData() {
    const { communityId, postId, heroId, reporterName, communityName, commentText, onReportSuccess, reportType } = this.props;

    const { comments, reason } = this.state;

    const formData = {
      reporterName,
      communityName,
      communityId,
      postId,
      commentText,
      heroId,
      reason,
      comments,
      reportType,
    };

    onReportSuccess(formData);
  }

  render() {
    const { reason } = this.state;
    const { translations } = this.props;
    const reasonsList = [
      { text: _.get(translations, 'report.reasonInappropriate', 'report.reasonInappropriate'), id: 'reason-inappropriate' },
      { text: _.get(translations, 'report.reasonSpam', 'report.reasonSpam'), id: 'reason-spam' },
      { text: _.get(translations, 'report.reasonElse', 'report.reasonElse'), id: 'reason-something-else' },
    ];

    return (
      <div className="report-modal-container">
        {!reason ? (
          reasonsList.map(item => (
            <div className="report-reason-group" key={item.id}>
              <div className="report-reason-item" onClick={() => this.setReason(item.text)} id={item.id}>
                <span className="report-reason-text">{item.text}</span>
                <img className="report-reason-arrow" src={commRightArrow} alt="Right Arrow" />
              </div>
            </div>
          ))
        ) : (
          <>
            <div className="report-reason-textarea">
              <textarea
                className="reason-textarea"
                placeholder={_.get(translations, 'report.reasonPlaceholder', 'report.reasonPlaceholder')}
                onChange={this.handleReportText}
              />
            </div>
            <div className="report-buttons-area">
              <button type="button" className="report-button-back" onClick={() => this.setState({ reason: null, comments: '' })}>
                {_.get(translations, 'report.back', 'report.back')}
              </button>
              <button type="button" className="report-button-submit button-yellow" onClick={() => this.submitData()}>
                {_.get(translations, 'report.submit', 'report.submit')}
              </button>
            </div>
          </>
        )}
      </div>
    );
  }
}

ReportModal.propTypes = {
  translations: PropTypes.shape({}).isRequired,
  communityId: PropTypes.number.isRequired,
  postId: PropTypes.number.isRequired,
  commentText: PropTypes.number,
  heroId: PropTypes.number.isRequired,
  reporterName: PropTypes.string.isRequired,
  communityName: PropTypes.string.isRequired,
  onReportSuccess: PropTypes.func.isRequired,
  reportType: PropTypes.string.isRequired,
  toggleNextTitle: PropTypes.func.isRequired,
};

ReportModal.defaultProps = {
  commentText: null,
};

export default ReportModal;
