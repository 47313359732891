import {connect} from 'react-redux';
import AccountProfile from './../../components/accountProfile/accountProfile';

import {fetchProfile, saveProfile, saveOriginalInformation} from './../../actions/accountActions';
import {fetchSkills, fetchIndustries, fetchCountryCodes} from './../../actions/generalDataActions';

const mapStateToProps = state => {

    return {
        profileDetails: state.account.get('profile'),
        skills: state.account.get('skills'),
        industries: state.account.get('industries'),
        countryCodes: state.account.get('countryCodes'),
        originalInformation: state.account.get('originalInformation'),
        translations: state.translations,
    };
};

const mapDispatchProps = dispatch => {
    return {
        fetchProfile: () => {
           dispatch(fetchProfile());
        },
        fetchSkills: () => {
           dispatch(fetchSkills());
        },
        fetchIndustries: () => {
           dispatch(fetchIndustries());
        },
        saveProfile: (args) => {
           dispatch(saveProfile(args));
        },
        fetchCountryCodes: () => {
           dispatch(fetchCountryCodes());
        },
        saveOriginalInformation: (information) => {
           dispatch(saveOriginalInformation(information));
        },
    }
};

const AccountProfileView = connect(mapStateToProps, mapDispatchProps)(AccountProfile);

export default AccountProfileView;
