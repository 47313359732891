import { connect } from 'react-redux';
import _ from 'lodash';
import { translations } from '../../../selectors/translations';
import PaymentDetails from './PaymentDetails';
import { onChangeSection, openBookingOptions } from '../../../actions/locationActions';

const mapStateToProps = state => ({
  translations: _.get(translations(state), 'paymentProfile', {}),
});

const mapDispatchToProps = dispatch => ({
  onChangeSection: bookingSection => dispatch(onChangeSection(bookingSection)),
  openBookingOptions: () => dispatch(openBookingOptions()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentDetails);
