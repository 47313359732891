import Immutable from 'immutable';
import _ from 'lodash';
import moment from 'moment-timezone';
import { SET_MEETING_IS_LOADING, SET_MEETING } from '../actions/meetingActions';
import getLang from '../utils/lang';

const initialState = Immutable.Map({
  meeting: {},
  isLoading: true,
});

const lang = getLang() !== null ? getLang() : 'en';

/**
 * Ex: date = meeting.bookingDetails.when
 *     date input: 2022-02-18|2022-03-19
 *     date output:  févr. 18, 2022 - mars. 19, 2022
 * @param meeting
 * @returns {*}
 */
const updateDateFormat = meeting => {
  const when = _.split(_.get(meeting, 'bookingDetails.when'), '|');
  const dates = when
    .map(date =>
      moment(date, 'YYYY-MM-DD')
        .locale(lang)
        .format('MMM DD, YYYY')
        .toString()
    )
    .join(' - ');

  _.set(meeting, 'bookingDetails.when', dates);

  return meeting;
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_MEETING_IS_LOADING:
      return state.setIn(['isLoading'], action.isLoading);
    case SET_MEETING:
      return state.setIn(['meeting'], updateDateFormat(action.meeting));
    default:
      return state;
  }
};
