import React from 'react';
import { Iterable } from 'immutable';

const toJS = WrappedComponent => wrappedComponentProps => {
  const KEY = 0;
  const VALUE = 1;

  const propsJS = Object.entries(wrappedComponentProps).reduce((newProps, wrappedComponentProp) => {
    // eslint-disable-next-line no-param-reassign
    newProps[wrappedComponentProp[KEY]] = Iterable.isIterable(wrappedComponentProp[VALUE])
      ? wrappedComponentProp[VALUE].toJS()
      : wrappedComponentProp[VALUE];
    return newProps;
  }, {});

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <WrappedComponent {...propsJS} />;
};

export default toJS;
