import React from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import InputField from '../inputField';
import loginRedirects from '../utils/loginRedirects';
import { getCurrentHostname } from '../../../utils/currentCompanyHostname';

class LoginForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      errors: {},
      isLoading: false,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  handleInputChange(key, value) {
    switch (key) {
      case 'email':
        this.setState({ email: value });
        break;
      case 'password':
        this.setState({ password: value });
        break;
      default:
    }
  }

  handleFormSubmit() {
    const { email, password } = this.state;
    const { history, login, location, setHeroDataAfterLogin, setIsWhiteLabel } = this.props;
    const invitationKey = localStorage.getItem('invitation-key');
    const credentials = {
      email,
      password,
      invitationKey,
    };
    this.setState({ isLoading: true });
    login(credentials)
      .then(response => {
        const accessKey = _.get(response, 'data.access-key', null);
        if (accessKey) {
          localStorage.setItem('access-key', accessKey);
          localStorage.setItem('first-name', _.get(response, 'data.heroData.firstName', null));
          localStorage.setItem('last-name', _.get(response, 'data.heroData.lastName', null));
          localStorage.setItem('source', 'email');
          localStorage.removeItem('invitation-key');
          localStorage.removeItem('invitation-email');
          localStorage.removeItem('invitation-whitelabel');
          setHeroDataAfterLogin(!!accessKey);
          const fromUrl = _.get(location, 'fromUrl', location.pathname);
          const historyState = { fromBooking: _.get(location, 'state.fromBooking', false) };
          loginRedirects(response, fromUrl, historyState, setIsWhiteLabel, history);
        }
      })
      .catch(error => {
        this.setState({ isLoading: false });
        const errorType = _.get(error, 'response.data.type', '');
        if (errorType === 'email') {
          this.setState({ errors: { email: _.get(error, 'response.data.message', null) } });
        } else if (errorType === 'password') {
          this.setState({ errors: { password: _.get(error, 'response.data.message', null) } });
        } else if (errorType === 'wms') {
          const { isWmsApp } = window.__env;
          const subdomain = _.get(error, 'response.data.subdomain', '');
          if (subdomain) {
            window.location.href = `${window.__env.baseUrl.replace(getCurrentHostname(), subdomain)}/login`;
          } else if (!isWmsApp) {
            setIsWhiteLabel(true);
          }
        } else {
          history.push('/');
        }
      });
  }

  render() {
    const { translations } = this.props;
    const { email, password, errors, isLoading } = this.state;
    const loginTranslations = _.get(translations, 'login', []);
    const requiredLabel = _.get(translations, 'required', '(required)');
    const emailLabel = `${_.get(translations, 'email', 'Email')} ${requiredLabel}`;
    const passwordLabel = `${_.get(translations, 'password', 'Password')} ${requiredLabel}`;
    const forgotPasswordLabel = _.get(loginTranslations, 'forgotPassword', 'Forgot your password?');
    const submitMessage = _.get(loginTranslations, 'action', 'Log in & continue');
    const emailError = _.get(errors, 'email', null) ? _.get(loginTranslations, errors.email, true) : false;
    const passwordError = _.get(errors, 'password', null) ? _.get(loginTranslations, errors.password, true) : false;

    return (
      <form
        className="login-form__container"
        onSubmit={event => {
          event.preventDefault();
          event.stopPropagation();
          this.handleFormSubmit();
        }}
      >
        <div className="login-form__row">
          <InputField
            label={emailLabel}
            name="email"
            value={email}
            type="email"
            handleInputChange={this.handleInputChange}
            error={emailError}
            className="login__email-input"
          />
        </div>
        <div className="login-form__row">
          <InputField
            label={passwordLabel}
            name="password"
            value={password}
            type="password"
            handleInputChange={this.handleInputChange}
            error={passwordError}
            className="login__password-input"
          />
        </div>
        <a href="forgot-password" className="login-form__forgot-password">
          {forgotPasswordLabel}
        </a>
        <div className="login-form__row">
          <button id="open-login" className="login-form__submit-button login__button" type="submit" disabled={isLoading}>
            {!isLoading ? submitMessage : <div className="open-login__loader" />}
          </button>
        </div>
      </form>
    );
  }
}

export default withRouter(LoginForm);
