import { connect } from 'react-redux';

import toJS from '../../utils/to-js';
import addLoader from '../../utils/add-loader';
import _ from 'lodash';

import { heroRecommendationSelector, heroRecommendationIsLoadingSelector } from '../../../../selectors/dashboard/hero-recommendation';
import { fetchHeroRecommendation } from '../../../../actions/dashboard/hero-recommendation';
import { translations } from '../../../../selectors/translations';

import HeroRecommendation from './HeroRecommendation';
import { openConversation } from '../../../../actions/directChat';

const mapStateToProps = state => ({
  heroRecommendation: heroRecommendationSelector(state),
  isLoading: heroRecommendationIsLoadingSelector(state),
  translations: _.get(translations(state), 'dashboard', {}),
});

const mapDispatchToProps = dispatch => ({
  fetchHeroRecommendation: () => dispatch(fetchHeroRecommendation()),
  openConversation: (id, hero) => dispatch(openConversation(id, hero)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(toJS(addLoader(HeroRecommendation)));
