import _ from 'lodash';
import { connect } from 'react-redux';
import ResetPasswordForm from './ResetPasswordForm';
import { translations } from '../../../selectors/translations';
import { reset } from '../../../actions/openAuth/forgot';

const mapStateToProps = state => ({
  translations: _.get(translations(state), 'openAuth', {}),
});

const mapDispatchProps = dispatch => ({
  reset: data => dispatch(reset(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchProps
)(ResetPasswordForm);
