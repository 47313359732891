import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

import parseTweetContent from '../../../utils/parse-tweet-content';
import twitterImage from '../../../../../assets/svg/dashboard/hero-dashboard-twitter-icon.png';
import getLang from '../../../../../utils/lang';

const lang = getLang() !== null ? getLang() : 'en';

const TwitterCard = props => {
  const { news, translations } = props;
  const category = _.get(news, 'category', null);
  const content = _.get(news, 'content', null);
  const date = _.get(news, 'date', null);
  const translatedDate = new Date(date).toLocaleString(lang, { day: 'numeric', month: 'long', year: 'numeric' });
  const tweetFavs = _.get(news, 'tweetFavs', null);
  const tweetCounts = _.get(news, 'tweetCounts', null);
  const link = _.get(news, 'link', null);

  const parsedContent = parseTweetContent(content);

  return (
    <a className="card__container-link news-card" href={link} target="_blank" rel="noopener noreferrer">
      <div className="card__container card__container--light news-card news-twitter-card">
        <div className="news-card-left-section">
          <div className="news-card__header-wrapper twitter-card">
            <span className="news-card__header">
              <span className="news-card__type">{category}</span>
            </span>
            <span className="news-card__date">{translatedDate}</span>
          </div>
          <div className="news-card__title">
            <span dangerouslySetInnerHTML={{ __html: parsedContent }} />
          </div>
          <div className="news-card__footer">
            <div className="card-footer__container">
              {tweetCounts} {_.get(translations, 'retweets', 'retweets')} <span className="dot-between__footer">•</span> {tweetFavs}{' '}
              {_.get(translations, 'likes', 'likes')}
            </div>
          </div>
        </div>
        <span className="twitter-card__icon">
          <img className="twitter-card__icon-img" src={twitterImage} alt="" />
        </span>
      </div>
    </a>
  );
};

TwitterCard.propTypes = {
  news: PropTypes.shape([]).isRequired,
};

export default TwitterCard;
