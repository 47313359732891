import Immutable from 'immutable';
import {
  SET_SUBMISSION_FORM_IS_LOADING,
  SET_SUBMISSION_FORM_IS_SUBMITED,
  SET_SUBMISSION_FORM_ERRORS,
} from '../../actions/dashboard/submission-form';

const initialState = Immutable.Map({
  isLoading: false,
  isSubmited: false,
  errors: {},
});

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_SUBMISSION_FORM_IS_LOADING:
      return state.setIn(['isLoading'], action.isLoading);
    case SET_SUBMISSION_FORM_IS_SUBMITED:
      return state.setIn(['isSubmited'], action.isSubmited);
    case SET_SUBMISSION_FORM_ERRORS:
      return state.setIn(['errors'], action.errors);
    default:
      return state;
  }
};
