import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import CommunityCard from '../../common/community-card/CommunityCard';
import InfoToast from '../../common/info-toast/InfoToast';
import LoadMore from './LoadMore';
import { CARDS_PER_PAGE } from '../../constants';

class PopularCommunities extends Component {
  constructor(props) {
    super(props);
    this.state = {
      joinCommunityErrorsShow: false,
    };
    this.buildCommunityCard = this.buildCommunityCard.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.discoverAllCommunities = this.discoverAllCommunities.bind(this);
    this.buildCardsMessage = this.buildCardsMessage.bind(this);
  }

  componentDidMount() {
    const { fetchPopularCommunities, popularCommunities } = this.props;
    if (!popularCommunities.length) {
      fetchPopularCommunities();
    }
  }

  discoverAllCommunities() {
    this.props.history.push('/communities/all');
  }

  buildCommunityCard(comm) {
    const { isAllCommunities, translations } = this.props;
    return (
      <CommunityCard
        img={comm.image}
        title={comm.name}
        members={comm.members}
        buttonText={_.get(translations, "popular.join", "popular.join")}
        action={() => this.handleClick(comm.urlSlug)}
        type={comm.audienceType}
        verticals={comm.verticals}
        key={comm.id}
        isAllCommunities={isAllCommunities}
        translations={translations}
      />
    );
  }

  handleClick(communitySlug) {
    const { history } = this.props;
    history.push(`/community/${communitySlug}`);
  }

  buildCardsMessage(popularCommunitiesCount, trans) {
    if (popularCommunitiesCount < CARDS_PER_PAGE) {
      return null;
    }
    return <div className="no-more-communities">{trans}</div>;
  }

  render() {
    const {
      popularCommunities,
      translations,
      isAllCommunities,
      hasMoreCommunities,
      popularCommunitiesCount,
      fetchPopularCommunities,
      history,
    } = this.props;
    const { joinCommunityErrorsShow } = this.state;
    const trans = _.get(translations, 'popular', []);

    if (isAllCommunities) {
      return (
        <div className="all-communities popular-communities__container">
          <button type="button" onClick={history.goBack} className="navigation__back-to-button community-page__back-to-button">
            {translations.backToCommunities}
          </button>
          <p className="all-communities__title">{trans.allCommunitiesText}</p>
          {joinCommunityErrorsShow && <InfoToast message={trans.joinErrorMessage} />}
          <div className="popular-communities__cards">{popularCommunities.map(this.buildCommunityCard)}</div>
          {hasMoreCommunities ? (
            <LoadMore fetchPopularCommunities={fetchPopularCommunities} trans={trans} />
          ) : (
            this.buildCardsMessage(popularCommunitiesCount, trans.noMoreCommunities)
          )}
        </div>
      );
    }

    return (
      <div className="popular-communities__container">
        {joinCommunityErrorsShow && <InfoToast message={trans.joinErrorMessage} />}
        <h2 className="popular-communities__header">{trans.title}</h2>
        <div className="popular-communities__cards">{popularCommunities.slice(0, CARDS_PER_PAGE).map(this.buildCommunityCard)}</div>
        <div className="popular-communities__display-all">
          <button type="button" className="entity-button" onClick={this.discoverAllCommunities}>
            {trans.discover}
          </button>
        </div>
      </div>
    );
  }
}

PopularCommunities.propTypes = {
  fetchPopularCommunities: PropTypes.func.isRequired,
  popularCommunities: PropTypes.arrayOf(PropTypes.shape([])),
  joinCommunityErrors: PropTypes.string,
  isAllCommunities: PropTypes.bool,
  hasMoreCommunities: PropTypes.bool,
  popularCommunitiesCount: PropTypes.number,
};

PopularCommunities.defaultProps = {
  popularCommunities: [],
  joinCommunityErrors: undefined,
  isAllCommunities: false,
  hasMoreCommunities: false,
  popularCommunitiesCount: null,
};

export default withRouter(PopularCommunities);
