import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import {getCurrentHostname} from "../../../utils/currentCompanyHostname";

const queryString = require('query-string');

class LinkedinLogin extends Component {
  componentDidMount() {
    const { auth, history, updateRegisteredAttendee, setIsWhiteLabel, joinPrivateCommunity } = this.props;
    const params = queryString.parse(window.location.search);
    const { invitation_key, state } = params;
    const token = params.code;
    const source = 'linkedin';

    const data = {
      token,
      source,
      invitation_key,
    };

    auth(data)
      .then(resp => {
        localStorage.setItem('invitation_key', _.get(invitation_key, '', null));
        localStorage.setItem('source', source);
        const accountType = _.get(resp, 'data.heroData.account', null);
        const fromUrl = _.get(JSON.parse(state), 'fromUrl', '');

        if (accountType === 'existing') {
          //it doesn't work with loginRedirects to redirect hero with incomplete profile to /profile
          const communityJoinUrl = localStorage.getItem('community-join-url');
          localStorage.removeItem('meeting-attendee-key');
          if (communityJoinUrl) {
            localStorage.removeItem('community-join-url');
            history.push(communityJoinUrl);
          } else {
            const isWhiteLabel = _.get(resp, 'data.heroData.isWhiteLabel', null);
            const hasProfileCompleted = _.get(resp, 'data.heroData.profileCompleted', null);
            if (isWhiteLabel && !hasProfileCompleted) {
              setIsWhiteLabel(true);
              history.push({
                pathname: '/profile',
                fromUrl,
                state,
              });
            } else {
              history.push({
                pathname: fromUrl,
                fromUrl,
                state,
              });
            }
          }
        } else {
          const attendeeKey = localStorage.getItem('meeting-attendee-key');
          if (attendeeKey) {
            const meetingId = localStorage.getItem('meeting-id');
            updateRegisteredAttendee({ attendeeKey, meetingId });
          }
          const communityUrl = localStorage.getItem('community-join-url');
          if (communityUrl) {
            joinPrivateCommunity(communityUrl);
          }
          history.push({
            pathname: '/profile',
            fromUrl,
            state: {
              from: fromUrl,
              newAccount: true,
              fromBooking: _.get(JSON.parse(state), 'state.fromBooking', false),
            },
          });
        }
      })
      .catch(error => {
        const errorCode = _.get(error, 'response.data.error', 'generalError');
        const { isWmsApp } = window.__env;
        if (errorCode === 'wms' && !isWmsApp) {
          const subdomain = _.get(error, 'response.data.subdomain', '');
          if (subdomain) {
            window.location.href = `${window.__env.baseUrl.replace(getCurrentHostname(), subdomain)}/login`;
          } else {
            setIsWhiteLabel(true);
          }
          setIsWhiteLabel(true);
        } else {
          history.push(`/login#error=${errorCode}`);
        }
      });
  }

  render() {
    return <div className="Loader" />;
  }
}

export default withRouter(LinkedinLogin);
