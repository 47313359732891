import React  from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import sizedImage from '../../../../utils/imageHelper';
import verticalIcon from '../../../../assets/svg/community/community-verticals.svg';
import lockIcon from '../../../../assets/svg/community/community-lock.svg';
import globalIcon from '../../../../assets/svg/community/community-global.svg';
import ContextMenu from '../../common/context-menu';
import textIcon from '../../../../assets/svg/community/community-text.svg';
import photoIcon from '../../../../assets/svg/community/community-photo.svg';
import videoIcon from '../../../../assets/svg/community/community-video.svg';
import fileIcon from '../../../../assets/svg/community/community-file.svg';
import ManageCommunityModal from '../manage-community-modal';
import CommunityModal from '../../common/modals';
import EditCommunityForm from '../edit-community-form';
import DeleteCommunityModal from '../delete-community-modal';

const communityTypeImage = type => {
  switch (type) {
    case 'specific_verticals':
      return verticalIcon;
    case 'closed':
      return lockIcon;
    default:
      return globalIcon;
  }
};

const audienceText = (type, verticals) => {
  const noOfVerticals = verticals.length;
  if (noOfVerticals > 0) return verticals.join(', ');
  return type === 'public' ? 'Public' : '';
};
class CommunityPageHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showDeleteCommunityModal: false,
    };
    this.hideDeleteCommunityModal = this.hideDeleteCommunityModal.bind(this);
  }

  hideDeleteCommunityModal() {
    const { showDeleteCommunityModal } = this.state;
    this.setState({ showDeleteCommunityModal: !showDeleteCommunityModal });
  }

  renderEditCommunityModal() {
    const { translations, community, showEditCommunity, setShowEditCommunityModal } = this.props;
    const formTitle = _.get(translations, 'editForm.editCommunityFormTitle', 'editCommunityFormTitle');
    const formDescription = _.get(translations, 'editForm.editCommunityFormDescription', 'editCommunityFormDescription');
    return (
      <CommunityModal
        title={formTitle}
        description={formDescription}
        wrapperClasses="community-modals"
        body={
          <EditCommunityForm
            translations={translations}
            closeForm={() => {
              setShowEditCommunityModal(!showEditCommunity);
            }}
            community={community}
            callbackDeleteCommunity={() => {
              setShowEditCommunityModal(!showEditCommunity);
              this.hideDeleteCommunityModal();
            }}
          />
        }
        closeModal={() => {
          setShowEditCommunityModal(!showEditCommunity);
        }}
      />
    );
  }

  renderDeleteCommunityModal() {
    const { translations } = this.props;
    const formTitle = _.get(translations, 'deleteCommunityModal.deleteCommunityModalTitle', 'deleteCommunityModalTitle');
    return (
      <CommunityModal
        title={formTitle}
        wrapperClasses="community-modals community-confirmation-modal__wrapper"
        body={<DeleteCommunityModal hideDeleteCommunityModal={this.hideDeleteCommunityModal} />}
        closeModal={this.hideDeleteCommunityModal}
      />
    );
  }

  render() {
    const {
      community,
      translations,
      action,
      isSinglePostPage,
      showManageCommunity,
      showEditCommunity,
      setShowManageCommunityModal,
      setShowEditCommunityModal,
      fetchCommunity,
      match,
    } = this.props;
    const { showDeleteCommunityModal } = this.state;
    const verticals = _.get(community, 'verticals', []);
    const members = _.get(community, 'members', []);
    const img = _.get(community, 'image', []);
    const membersCountLabel = members.length > 1 ? _.get(translations, 'members', 'members') : _.get(translations, 'member', 'member');
    const isAdmin = _.get(community, 'isAdmin', false);
    const communtiyTranslation = _.get(translations, 'communityPage', []);
    const urlSlug = _.get(match.params, 'slug', '');

    const items = [
      {
        text: _.get(translations, 'communityPage.header.editCommunity', 'communityPage.header.editCommunity'),
        action: () => {
          setShowEditCommunityModal(!showEditCommunity);
        },
      },
      {
        text: _.get(translations, 'communityPage.header.manageCommunity', 'communityPage.header.manageCommunity'),
        action: () => {
          setShowManageCommunityModal(!showManageCommunity);
        },
      },
    ];
    const editCommunity = showEditCommunity ? this.renderEditCommunityModal() : null;
    const deleteCommunity = showDeleteCommunityModal ? this.renderDeleteCommunityModal() : null;

    return (
      <>
        {editCommunity}
        {deleteCommunity}
        {showManageCommunity ? (
          <CommunityModal
            title={_.get(translations, 'communityPage.header.manageCommunity', 'communityPage.header.manageCommunity')}
            wrapperClasses="community-modal__sectioned"
            translations={translations}
            body={
              <ManageCommunityModal
                translations={translations}
                closeForm={() => {
                  fetchCommunity(urlSlug);
                  setShowManageCommunityModal(!showManageCommunity);
                }}
                community={community}
                callbackDeleteCommunity={() => {}}
                callbackSuccessfullyEdited={() => {}}
              />
            }
            closeModal={() => {
              fetchCommunity(urlSlug);
              setShowManageCommunityModal(!showManageCommunity);
            }}
          />
        ) : null}

        <div className="community-header">
          <img className="community-header__image" src={sizedImage(img, 120, 0)} alt="" />
          <div className="community-header__details">
            <div>
              <h1 className="community-header__title">{community.name}</h1>
              <div className="community_header-description">
                <img src={communityTypeImage(community.audienceType)} alt={community.name} className="community-card__svg" />
                <span>{audienceText(community.audienceType, verticals)}</span>
                <span className="dot-between__footer">•</span>
                <span className="community-members">
                  {members.length} {membersCountLabel}
                </span>
              </div>
            </div>
            {isAdmin ? (
              <div>
                <ContextMenu items={items} />
              </div>
            ) : null}
          </div>
        </div>
        {!isSinglePostPage && (
          <div className="community-start-conversation">
            <div className="community-start-conversation__text" onClick={() => action()}>
              <img src={textIcon} alt="" className="community-card__svg" />
              <span>{communtiyTranslation.startConversation}</span>
            </div>
            <div className="community-start-conversation__photo" onClick={() => action()}>
              <img src={photoIcon} className="community-card__svg" alt=""/>
            </div>
            <div className="community-start-conversation__video" onClick={() => action()}>
              <img src={videoIcon} className="community-card__svg" alt="" />
            </div>
            <div className="community-start-conversation__file" onClick={() => action()}>
              <img src={fileIcon} className="community-card__svg" alt="" />
            </div>
          </div>
        )}
      </>
    );
  }
}
CommunityPageHeader.propTypes = {
  community: PropTypes.shape({}).isRequired,
  translations: PropTypes.shape(),
  isSinglePostPage: PropTypes.bool,
};

CommunityPageHeader.defaultProps = {
  translations: {},
  isSinglePostPage: false,
};
export default withRouter(CommunityPageHeader);
