import { connect } from 'react-redux';

import toJS from '../../utils/to-js';
import { isLoadingSelector, formErrorsSelector } from '../../../../selectors/community/update-community';
import { updateCommunity, clearErrors } from '../../../../actions/community/update-community';

import EditCommunityForm from './EditCommunityForm';
import { showCommunityPageToast } from '../../../../actions/community/community-toasts';
import { setShowEditCommunityModal } from '../../../../actions/community/community-modals';
import { communityEditModalSelector } from '../../../../selectors/community/community-modals';

const mapDispatchToProps = dispatch => ({
  updateCommunity: (communityId, data) => dispatch(updateCommunity(communityId, data)),
  clearErrors: () => dispatch(clearErrors()),
  showCommunityPageToast: data => dispatch(showCommunityPageToast(data)),
  setShowEditCommunityModal: data => dispatch(setShowEditCommunityModal(data)),
});

const mapStateToProps = state => ({
  isLoading: isLoadingSelector(state),
  errors: formErrorsSelector(state),
  showCommunityEditModal: communityEditModalSelector(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(toJS(EditCommunityForm));
