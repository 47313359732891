import { connect } from 'react-redux';

import toJS from '../../../utils/to-js';
import _ from 'lodash';
import { mediaFiltersSelector, mediaFiltersIsLoadingSelector, mediaFilterSelector } from '../../../../../selectors/dashboard/media-filters';
import {translations} from '../../../../../selectors/translations';
import { fetchMediaFilters, changeMediaFilter } from '../../../../../actions/dashboard/media-filters';
import { fetchNews } from '../../../../../actions/dashboard/news';

import Filter from './Filter';

const mapStateToProps = state => ({
  mediaFilters: mediaFiltersSelector(state),
  mediaFilter: mediaFilterSelector(state),
  isLoading: mediaFiltersIsLoadingSelector(state),
  translations: _.get(translations(state), 'dashboard', {}),
});

const mapDispatchToProps = dispatch => ({
  fetchMediaFilters: () => dispatch(fetchMediaFilters()),
  fetchNews: () => dispatch(fetchNews()),
  changeMediaFilter: mediaFilter => dispatch(changeMediaFilter(mediaFilter)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(toJS(Filter));
