import { connect } from 'react-redux';
import AccountPaymentSettings from '../../components/accountPaymentSettings/accountPaymentSettings';
import { fetchProfile } from '../../actions/accountActions';
import {
  fetchPaymentProfiles,
  deleteHeroPaymentProfile,
  updateHeroPaymentProfile,
  subscriptionUpdateInProgress,
  setDefaultPaymentMethod,
  deletePaymentMethod,
  clearDeleteErrors, loaderDelete,
} from '../../actions/locationActions';
import { fetchCountries } from '../../actions/generalDataActions';

const mapStateToProps = state => {
  return {
    profileDetails: state.account.get('profile'),
    paymentProfiles: state.addBooking.get('paymentProfiles'),
    deletePaymentProfileErrors: state.account.get('deletePaymentProfileErrors'),
    subscrUpdateInProgress: state.addBooking.get('subscrUpdateInProgress'),
    translations: state.translations,
    deletePaymentErrors: state.account.get('deletePaymentErrors'),
    loaderDelete: state.account.get('loaderDelete'),
  };
};

const mapDispatchProps = dispatch => {
  return {
    fetchProfile: () => {
      dispatch(fetchProfile());
    },
    fetchPaymentProfiles: () => {
      dispatch(fetchPaymentProfiles());
    },
    updateHeroPaymentProfile: data => {
      dispatch(updateHeroPaymentProfile(data));
    },
    deleteHeroPaymentProfile: (id, cb) => {
      dispatch(deleteHeroPaymentProfile(id, cb));
    },
    subscriptionUpdateInProgress: () => {
      dispatch(subscriptionUpdateInProgress());
    },
    fetchCountries: () => {
      dispatch(fetchCountries());
    },
    setDefaultPaymentMethod: id => {
      dispatch(setDefaultPaymentMethod(id));
    },
    deletePaymentMethod: (id, cb) => {
      dispatch(deletePaymentMethod(id, cb));
    },
    clearDeleteErrors: () => {
      dispatch(clearDeleteErrors());
    },
    loaderForDelete : (data) => {
      dispatch(loaderDelete(data));
    }
  };
};

const AccountPaymentSettingsView = connect(mapStateToProps, mapDispatchProps)(AccountPaymentSettings);

export default AccountPaymentSettingsView;
