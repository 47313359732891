import api from '../../components/dashboard/utils/api';

import { fetchMediaArticle } from './media-article';

/*
 * Constants
 */
export const SET_SUBMISSION_FORM_IS_LOADING = 'SET_SUBMISSION_FORM_IS_LOADING';
export const SET_SUBMISSION_FORM_IS_SUBMITED = 'SET_SUBMISSION_FORM_IS_SUBMITED';
export const SET_SUBMISSION_FORM_ERRORS = 'SET_SUBMISSION_FORM_ERRORS';
/*
 * Sync Actions
 */

export const setSubmissionFormIsLoading = isLoading => ({
  type: SET_SUBMISSION_FORM_IS_LOADING,
  isLoading,
});

export const setIsSubmited = isSubmited => ({
  type: SET_SUBMISSION_FORM_IS_SUBMITED,
  isSubmited,
});

export const setFormError = errors => ({
  type: SET_SUBMISSION_FORM_ERRORS,
  errors,
});
/*
 * Async Actions
 */
export const saveSubmission = data => dispatch => {
  dispatch(setSubmissionFormIsLoading(true));
  const formData = new FormData();
  const endpoint = data.type.toLowerCase() === 'jobs' ? 'job' : 'project';
  for (const key in data) {
    if (data[key] === null || key === 'type') {
      continue;
    }
    formData.append(key, data[key]);
  }
  api
    .post(`media/submission/${endpoint}`, formData)
    .then(() => {
      dispatch(setSubmissionFormIsLoading(false));
      dispatch(setIsSubmited(true));
      dispatch(fetchMediaArticle(data.id));
    })
    .catch(({ response }) => {
      if (response.data.error) {
        dispatch(setFormError(response.data.error));
        dispatch(setSubmissionFormIsLoading(false));
      }
    });
};
