import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import PageHeader from './../pageHeader/pageHeader';
import ModalDialog from './../modalDialog/modalDialog';
import DeletePaymentAccount from '../modalDialog/modals/deletePaymentAccount';
import MakeDefaultMethod from '../modalDialog/modals/makeDefaultMethod';
import DeletePaymentMethod from '../modalDialog/modals/deletePaymentMethod';
import moment from 'moment';
import Immutable from 'immutable';
import { Link } from 'react-router-dom';
import './css/index.scss';
import _ from 'lodash';
import AccountPaymentSettingsMenu from './AccountPaymentSettingsMenu';
import ContextMenu from '../../components/community/common/context-menu';
import creditCardImage from '../../../src/assets/svg/paymentSettings/credit-cards.svg';
import maestroImage from '../../../src/assets/svg/paymentSettings/maestro.svg';
import giftCardImage from '../../../src/assets/svg/paymentSettings/gift-card.svg';
import visaIcon from '../addBookingSidebar/icons/visa.svg';
import masterCardIcon from '../addBookingSidebar/icons/mastercard.svg';
import banContactIcon from '../addBookingSidebar/icons/bancontact.svg';
import amexIcon from '../addBookingSidebar/icons/amex.svg';
import CreateFirstPaymentProfile from "../paymentProfile/CreateFirstPaymentProfile";
import InfoToast from "../community/common/info-toast/InfoToast";

const getPaymentCardIcon = (cardBrand) => {
  let tileLogo = '';
  switch (cardBrand) {
    case 'Visa' : tileLogo = visaIcon; break;
    case 'MasterCard' : tileLogo = masterCardIcon; break;
    case 'Bancontact' : tileLogo = banContactIcon; break;
    case 'American Express' : tileLogo = amexIcon; break;
    case 'Voucher' : tileLogo = giftCardImage; break;
    default : break;
  }

  return tileLogo;
};

class AccountPaymentSettings extends React.Component {
  context;

  constructor(props) {
    super(props);

    this.state = {
      rendering: true,
      createNewProfile: false,
      showDeleteErrors: false,
    };

    this.buildPaymentItem = this.buildPaymentItem.bind(this);
    this.buildPersonalPaymentSection = this.buildPersonalPaymentSection.bind(this);
    this.buildBusinessPaymentSection = this.buildBusinessPaymentSection.bind(this);
    this.buildPaymentMethodTile = this.buildPaymentMethodTile.bind(this);
    this.deleteMethod = this.deleteMethod.bind(this);
    this.makePaymentMethodDefault = this.makePaymentMethodDefault.bind(this);
    this.buildAddNewPaymentMethods = this.buildAddNewPaymentMethods.bind(this);
    this.buildSpendingLimitSection = this.buildSpendingLimitSection.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.setState({
      rendering: true,
      overlayRendering: false,
      deleteModalOpened: false,
      makeAccountDefaultModalOpened: false,
      makeMethodDefaultModalOpened: false,
      deletePaymentMethodModalOpened: false,
      accountToDelete: null,
      accountToMakeDefault: null,
      paymentId: null,
      toUpdateProfiles: false,
      accountToChangeSubscription: null,
      activateSubscriptionModalOpened: false,
      activateLightweightSubscriptionModalOpened: false,
      localSubscrUpdateInProgress: false,
      updatingSubscriptionId: null,
      cancelSubscriptionModalOpened: false,
      lightweightSelectedOffice: Immutable.fromJS([
        {
          label: _.get(window, '__env.lightweightSubscriptionOfficeList.offices[0].name', 'Workero'),
          value: _.get(window, '__env.lightweightSubscriptionOfficeList.offices[0].id', 0),
        },
      ]),
    });
  }

  componentDidMount() {
    this.props.fetchProfile();
    this.props.fetchPaymentProfiles();
    this.props.fetchCountries();
    this.setState({ rendering: false });
  }

  componentDidUpdate = (prevProps) => {
    if (this.state.toUpdateProfiles === true) {
      setTimeout(() => {
        this.setState({ toUpdateProfiles: false }, () => {
          this.props.fetchPaymentProfiles();
          this.props.loaderForDelete(false);
        });
      }, 2000);
    }

    const {paymentProfiles} = this.props;
    const {paymentProfiles: oldProfiles} = prevProps;

    if (paymentProfiles && paymentProfiles.toJS().payment_profiles && oldProfiles && !oldProfiles.toJS().payment_profiles) {
      this.setState({ createNewProfile : false });
    }

    if (this.state.localSubscrUpdateInProgress === true && this.props.subscrUpdateInProgress === false) {
      this.setState({ localSubscrUpdateInProgress: false }, () => {
        this.props.fetchPaymentProfiles();

      });
    }
  };

  buildPersonalPaymentSection(personalPaymentProfiles) {
    const { translations } = this.props;
    return (
        <div className="personal_section">
          <p className="payment-section-title personal-title">{_.get(translations, 'paymentSettings.personalProfileTitle', 'Personal profile')}</p>
          {personalPaymentProfiles.map(this.buildPaymentItem)}
          <div className="horizontal-line" />
        </div>
    );
  }

  buildBusinessPaymentSection(businessPaymentProfiles) {
    const { translations } = this.props;
    return (
        <div className="business_section personal_section">
          <p className="payment-section-title">{_.get(translations, 'paymentSettings.businessProfileTitle', 'Business profiles')}</p>
          {this.buildSpendingLimitSection()}
          {businessPaymentProfiles.map(this.buildPaymentItem)}
          <div className="horizontal-line" />
        </div>
    );
  }

  buildAddNewPaymentMethods() {
    const { translations } = this.props;

    return (
        <div className="add-new-section">
          <p className="payment-section-title add-new-method-title">{_.get(translations, 'paymentSettings.addNewPaymentMethod', 'Add a new payment method')}</p>
          <div className="add-new-section__card">
            <div className="add-new-section__left">
              <div className="add-new-section__title">{_.get(translations, 'paymentSettings.cc', 'Credit Card')}</div>
              <div className="add-new-section__desc">{_.get(translations, 'paymentSettings.ccAccepted', 'Workero accepts credit cards & debit cards')}</div>
              <Link className="secondary-button add-new-payment-method" to={{
                pathname:`/account/paymentProfile`,
                search: `?type=cc`
              }}>
                {_.get(translations, 'paymentSettings.addCC', 'AddCC')}
              </Link>
            </div>
            <div className="add-new-section__right">
              <img src={creditCardImage} alt="Credit Card" />
            </div>
          </div>
          <div className="horizontal-line horizontal-line--thin" />
          <div className="add-new-section__card add-new-section__maestro">
            <div className="add-new-section__left">
              <div className="add-new-section__title">{_.get(translations, 'paymentSettings.maestro', 'Maestro')}</div>
              <div className="add-new-section__desc">{_.get(translations, 'paymentSettings.maestroAccepted', 'Workero accepts credit cards & debit cards')}</div>
              <Link className="secondary-button add-new-payment-method" to={{
                pathname:`/account/paymentProfile`,
                search: `?type=maestro`
              }}>
                {_.get(translations, 'paymentSettings.AddMaestro', 'AddMaestro')}
              </Link>
            </div>
            <div className="add-new-section__right">
              <img src={maestroImage} alt="Maestro" />
            </div>
          </div>
          <div className="horizontal-line horizontal-line--thin" />
          <div className="add-new-section__card add-new-section__gift-card">
            <div className="add-new-section__left">
              <div className="add-new-section__title">{_.get(translations, 'paymentSettings.voucher', 'voucher')}</div>
              <div className="add-new-section__desc">{_.get(translations, 'paymentSettings.voucherAccepted', 'Workero accepts credit cards & debit cards')}</div>
              <Link className="secondary-button add-new-payment-method" to={{
                pathname:`/account/paymentProfile`,
                search: `?type=voucher`
              }}>
                {_.get(translations, 'paymentSettings.AddVoucher', 'AddVoucher')}
              </Link>
            </div>
            <div className="add-new-section__right">
              <img src={giftCardImage} alt="Gift Card" />
            </div>
          </div>
        </div>
    );
  }

  buildSpendingLimitSection() {
    const { paymentProfiles, translations } = this.props;
    const spending_limit = _.get(paymentProfiles.toJS(), 'spending_limit', []);

    if (_.get(spending_limit, 'company_details[0]', null)) {
      const amount = _.get(spending_limit, 'amount', 0);
      const companyName = _.get(spending_limit, 'company_details[0].name', '');
      const vatNumber = _.get(spending_limit, 'company_details[0].vat_number', '');
      const address = _.get(spending_limit, 'company_details[0].address', '');
      const postCode = _.get(spending_limit, 'company_details[0].postal_code', '');
      const city = _.get(spending_limit, 'company_details[0].city', '');
      const country = _.get(spending_limit, 'company_details[0].country', '');

      return (
        <div className="payment-method">
          <div className="payment-method__wrapper">
            <div className="payment-method__specs">
              <div className="payment-method__title">
                {companyName} <span>{_.get(translations, 'paymentSettings.paysForYourBooking', 'pays for your booking')}</span>
              </div>
              <div className="payment-method__details">
                <span className="vat_number">{vatNumber}</span> <span className="dot-between">•</span>{address}, {city} <br /> {postCode}, {country}
              </div>
            </div>
            <div className="payment-method__tiles">
              <div className="payment-method__card">
                <div className="payment-method__card-title">
                  {_.get(translations, 'paymentSettings.spendingLimit', 'spending limit')}
                </div>
                <div className="payment-method__card-text">
                  {translations.formatString(_.get(translations, "paymentSettings.amountAvailable", "Amount"), amount )}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return null;
  }

  buildPaymentMethodTile(data) {
    const { translations } = this.props;

    const methodId = _.get(data, 'id', '');
    const paymentType = _.get(data, 'type', '');
    const isDefault = _.get(data, 'is_default', 0);
    const lastDigits = _.get(data, 'last_digits', null);
    const expireDate = _.get(data, 'expire_date', null);
    const cardBrand = _.get(data, 'card_brand', null);

    const items = [
      {
        text: _.get(translations, 'paymentSettings.deleteMethod', 'deleteMethod'),
        action: () => {
          this.onDeletePaymentMethodModalOpen(methodId, paymentType)
        },
      }
    ];

    if (!isDefault && !(paymentType === 'voucher' && _.isEmpty(data.voucher))) {
      const makeDefault = {
        text: _.get(translations, 'paymentSettings.makeDefault', 'makeDefault'),
        action: () => { this.onMakeMethodDefaultModalOpen(methodId, paymentType) }
      };
      items.push(makeDefault);
    }

    let giftCardData = '';
    let title = _.get(translations, 'paymentSettings.cc', 'cc') + (isDefault ? _.get(translations, 'paymentSettings.isDefault', 'isDefault') : '');

    if (paymentType === 'bancontact') {
      title = _.get(translations, 'paymentSettings.maestro', 'maestro') + (isDefault ? _.get(translations, 'paymentSettings.isDefault', 'isDefault') : '');
    } else if (paymentType === 'voucher') {
      title = _.get(translations, 'paymentSettings.voucher', 'voucher') + (isDefault ? _.get(translations, 'paymentSettings.isDefault', 'isDefault') : '');
      title = _.isEmpty(data.voucher) ? `${title} (inactive)` : title;
      const giftCardAvailable = _.get(data, 'voucher.balance', 0);
      const giftCardTotal = _.get(data, 'voucher.amount', 0);
      giftCardData = translations.formatString(_.get(translations, "paymentSettings.voucherAmountAvailable", "Amount"), giftCardAvailable, giftCardTotal );
    }

    const cardEnding = (lastDigits ? _.get(translations, 'paymentSettings.endingIn', 'endingIn')  + lastDigits + ', ' : '');
    const expirationDate = (expireDate ? _.get(translations, 'paymentSettings.expiresIn', 'expiresIn')  + moment.utc(expireDate).format('MM/YY') : '');

    return (
        <div className="payment-method__card">
          <div className="payment-method__card-title">
            {title}
          </div>
          <div className="payment-method__card-text">
            {cardEnding} {expirationDate} {giftCardData}
          </div>
          <ContextMenu items={items} />
          <img className="payment-method__logo" src={getPaymentCardIcon(cardBrand)} alt={cardBrand} />
        </div>
    );
  }

  buildPaymentItem(paymentData) {
    const heroData = this.props.profileDetails.toJS();
    const firstName = _.get(heroData, 'firstName', '');
    const lastName = _.get(heroData, 'lastName', '');

    const paymentId = _.get(paymentData, 'id', null);
    const paymentProfileType = _.get(paymentData, 'payment_profile_type');

    const companyName = _.get(paymentData, 'company_name', '');
    const vatNumber = _.get(paymentData, 'company_vat_number', '');

    const address = _.get(paymentData, 'company_address', '');
    const postCode = _.get(paymentData, 'postal_code', '');
    const city = _.get(paymentData, 'city', '');
    const country = _.get(paymentData, 'country', '');

    const paymentMethods = _.get(paymentData, 'payment_methods', []);

    return (
        <div className="payment-method">
          <div className="payment-method__wrapper">
            <div className="payment-method__specs">
              <div className="payment-method__title">
                {companyName ? (
                    companyName
                ) : (
                    firstName + ' ' + lastName
                )}
              </div>
              <div className="payment-method__details">
                {paymentProfileType === 'company' ? (
                    <>
                      <span className="vat_number">{vatNumber}</span> <span className="dot-between">•</span>{address}, {city} <br /> {postCode}, {country}
                    </>
                ) : (
                    <>
                      {address} <br /> {postCode}, {city}, {country}
                    </>
                )}
              </div>
              <span onClick={() => {this.onDeleteAccountModalOpen(paymentId)}} className="delete-payment-profile" >
                {this.props.translations.paymentSettings.deleteAccount}
              </span>
            </div>
            <div className="payment-method__tiles">
              {paymentMethods.map(this.buildPaymentMethodTile)}
            </div>
          </div>
        </div>
    );
  }

  _renderPaymentProfiles = () => {
    const { paymentProfiles } = this.props;
    const personalPaymentProfiles = [];
    const businessPaymentProfiles = [];

    const payment_profiles = _.get(paymentProfiles.toJS(), 'payment_profiles', []);
    const spending_limit = _.get(paymentProfiles.toJS(), 'spending_limit', []);

    if (payment_profiles) {
      for (let i = 0; i < payment_profiles.length; i++) {
        if (payment_profiles[i]['payment_profile_type'] === 'company') businessPaymentProfiles.push(payment_profiles[i]);
        if (payment_profiles[i]['payment_profile_type'] === 'personal') personalPaymentProfiles.push(payment_profiles[i]);
      }
    }

    if (payment_profiles && payment_profiles.length || _.get(spending_limit, 'company_details', null)) {
      return (
        <div className="payment-section-wrapper">
          {personalPaymentProfiles.length ? this.buildPersonalPaymentSection(personalPaymentProfiles) : null}
          {businessPaymentProfiles.length || _.get(spending_limit, 'company_details', null) ? this.buildBusinessPaymentSection(businessPaymentProfiles) : null}
          {this.buildAddNewPaymentMethods(businessPaymentProfiles)}
        </div>
      );
    }

    return (
      <div>
        {!this.state.createNewProfile &&
        <div className="paymentProfile-no-profiles-container" onClick={() => {this.onCreateFirstPaymentProfile()}}>
            <div className="paymentProfile-plus-icon" />
            <br />
            <span className="paymentProfile-no-profiles-header">{this.props.translations.paymentSettings.noProfilesHeader}</span>
            <br />
            <span className="paymentProfile-no-profiles-sub-header">{this.props.translations.paymentSettings.noProfilesSubHeader}</span>
        </div>
        }
      </div>
    );
  };

  onCreateFirstPaymentProfile = () => {
    this.setState({
      createNewProfile: true
    })
  }

  onDeleteAccountModalOpen = accountToDelete => {
    this.setState({
      deleteModalOpened: true,
      accountToDelete,
    });
  };

  onDeleteAccountModalClose = () => {
    this.setState({
      deleteModalOpened: false,
      accountToDelete: null,
    });
  };

  onMakeMethodDefaultModalOpen = (methodId, paymentType) => {
    this.setState({
      makeMethodDefaultModalOpened: true,
      methodId,
      paymentType,
    });
  };

  onMakeMethodDefaultModalClose = () => {
    this.setState({
      makeMethodDefaultModalOpened: false,
      methodId: null,
      paymentType: null,
    });
  };

  onDeletePaymentMethodModalOpen = (methodId, paymentType) => {
    this.setState({
      deletePaymentMethodModalOpened: true,
      methodId,
      paymentType
    });
  };

  onDeletePaymentMethodModalClose = () => {
    this.setState({
      deletePaymentMethodModalOpened: false,
      methodId: null,
      paymentType: null,
    });
  };

  makePaymentMethodDefault = () => {
    this.setState(
        { makeMethodDefaultModalOpened: false },
        () => {
          this.props.setDefaultPaymentMethod(this.state.methodId);
          this.setState({ methodId: null, toUpdateProfiles: true });
        }
    );
  };

  deleteAccount = () => {
    const { clearDeleteErrors, loaderForDelete, deleteHeroPaymentProfile } = this.props;
    this.setState(
      {
        deleteModalOpened: false,
      },
      () => {
        loaderForDelete(true);
        deleteHeroPaymentProfile(this.state.accountToDelete, () => {
          loaderForDelete(false);
          this.setState({ showDeleteErrors: true });
          setTimeout(() => {
            this.setState({ showDeleteErrors: false });
            clearDeleteErrors();
          }, 3000);
        });
        this.setState({ accountToDelete: null, toUpdateProfiles: true });
      }
    );
  };

  deleteMethod = () => {
    const { clearDeleteErrors, loaderForDelete, deletePaymentMethod } = this.props;
    this.setState(
        {
          deletePaymentMethodModalOpened: false,
        },
        () => {
          loaderForDelete(true);
          deletePaymentMethod(this.state.methodId, () => {
            this.setState({ showDeleteErrors: true });
            loaderForDelete(false);
            setTimeout(() => {
              this.setState({ showDeleteErrors: false });
              clearDeleteErrors();
            }, 3000);
          });
          this.setState({ methodId: null, toUpdateProfiles: true });
        }
    );
  };

  render() {
    if (
      this.state.rendering === true ||
      this.props.paymentProfiles === null ||
      this.props.profileDetails === null ||
      this.props.translations === null || this.props.loaderDelete
    ) {
      return <div className="Loader" />;
    }
    const { createNewProfile, showDeleteErrors } = this.state;
    const { deletePaymentErrors } = this.props;
    const toastMessage = deletePaymentErrors ? _.get(deletePaymentErrors.toJS(), 'error', null) : null;

    return (
      <div className="accountPaymentSettings">
        {(toastMessage && showDeleteErrors) && <InfoToast message={toastMessage} />}
        <div className="payment-settings">
          <section className="payment-settings-content">
            <Scrollbars autoHide={true} className="styleScroll">
              <PageHeader
                className="--defaultPadding"
                pageTitle={this.props.translations.paymentSettings.title}
                icon="payment-settings"
                actions={[]}
              />
              <AccountPaymentSettingsMenu translations={this.props.translations}/>
              { createNewProfile ? <CreateFirstPaymentProfile heroData={this.props.profileDetails.toJS()} translations={this.props.translations}/> : null}
              {this._renderPaymentProfiles()}
            </Scrollbars>
          </section>
        </div>
        <ModalDialog
          modalOpened={this.state.deleteModalOpened}
          className="--paymentAccount"
          onModalClose={this.onDeleteAccountModalClose}
          label="deleteAccount"
        >
          <DeletePaymentAccount
            onAction={this.deleteAccount}
            onCancelAction={this.onDeleteAccountModalClose}
            trans={this.props.translations}
          />
        </ModalDialog>
        <ModalDialog
            modalOpened={this.state.deletePaymentMethodModalOpened}
            className="--paymentAccount"
            onModalClose={this.onDeletePaymentMethodModalOpen}
            label="deleteAccount"
        >
          <DeletePaymentMethod
              onAction={this.deleteMethod}
              onCancelAction={this.onDeletePaymentMethodModalClose}
              trans={this.props.translations}
              paymentType={this.state.paymentType}
          />
        </ModalDialog>
        <ModalDialog
            modalOpened={this.state.makeMethodDefaultModalOpened}
            className="--paymentAccount"
            onModalClose={this.onMakeMethodDefaultModalClose}
            label="makeAccountDefault"
        >
          <MakeDefaultMethod
              onAction={this.makePaymentMethodDefault}
              onCancelAction={this.onMakeMethodDefaultModalClose}
              trans={this.props.translations}
              paymentType={this.state.paymentType}
          />
        </ModalDialog>
        {this.state.overlayRendering === true ? (
          <div className="profile-overlay">
            <div className="officeDetailsLoader" />
          </div>
        ) : null}
      </div>
    );
  }
}

export default AccountPaymentSettings;
