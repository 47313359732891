import React, { Component } from 'react';
import Truncate from 'react-truncate';
import PropTypes from 'prop-types';
import _ from 'lodash';
import classnames from 'classnames';

const goToProject = id => {
  window.location.href = `/dashboard/media/${id}`;
};
const buildCoCreationProject = (project, translations) => {
  const description = _.get(project, 'description', '');
  const thumb = _.get(project, 'thumb', '');
  const duration = _.get(project, 'duration', null);
  const remuneration = _.get(project, 'remuneration', null);
  const id = _.get(project, 'id', null);
  return (
    <a href={`/dashboard/media/${id}`}>
      <div className="co-creation-project__container">
        <div className="co-creation-project__header">
          <div
            className={classnames('co-creation-project__thumb-container', {
              'co-creation-project__thumb-container-no-thumb': _.isEmpty(thumb),
            })}
          >
            {!_.isEmpty(thumb) ? (
              <img className="co-creation-project__thumb" src={thumb} alt="Project" />
            ) : (
              <div className="co-creation-project__no-thumb">{_.get(translations, 'noPhoto', 'No photo')}</div>
            )}
          </div>
          <div className="co-creation-project__description">
            <Truncate lines={3} ellipsis={<>...</>}>
              {description}
            </Truncate>
          </div>
        </div>
        <button
          type="button"
          className="dashboard-button dashboard-button--border co-creation-project__button"
          onClick={goToProject.bind(null, [id])}
        >
          {_.get(translations, 'moreDetails', 'More details')}
        </button>
        <div className="co-creation-project__body">
          <div className="co-creation-project__duration">
            <div className="co-creation-project__body-item co-creation-project__label">
              {_.get(translations, 'projectDuration', 'Project duration')}
            </div>
            <div className="co-creation-project__body-item co-creation-project__value">{duration}</div>
          </div>
          <div className="co-creation-project__remuneration">
            <div className="co-creation-project__body-item co-creation-project__label">
              {_.get(translations, 'remuneration', 'Remuneration')}
            </div>
            <div className="co-creation-project__body-item co-creation-project__value">{remuneration}</div>
          </div>
        </div>
      </div>
    </a>
  );
};

class CoCreationProjects extends Component {
  constructor(props) {
    super(props);
    this.buildBullet = this.buildBullet.bind(this);
  }

  componentDidMount() {
    const { fetchCoCreationProjects } = this.props;
    fetchCoCreationProjects();
  }

  handleOnClick(activeId) {
    const { setActiveId } = this.props;
    setActiveId(activeId);
  }

  buildBullet(id) {
    const { activeId } = this.props;
    return (
      <div
        id={id}
        key={id}
        className={classnames('co-creation-projects__bullet', {
          'co-creation-projects__bullet-active': _.toString(activeId) === _.toString(id),
        })}
        onClick={this.handleOnClick.bind(this, id)}
      />
    );
  }

  render() {
    const { coCreationProjects, activeId, translations } = this.props;
    const activeCoCreationProject = _.get(coCreationProjects, activeId);
    if (!_.isEmpty(coCreationProjects)) {
      return (
        <div className="dashboard-grid__row dashboard-grid__row--margin-bottom">
          <div className="card__container card__container--border card__co-creation-projects">
            <h4 className="card__title">{_.get(translations, 'coCreationProjects', 'Co-creation Projects')}</h4>
            <div className="co-creation-projects_wrapper">{buildCoCreationProject(activeCoCreationProject, translations)}</div>
            <div className="co-creation-projects_footer">{_.keys(coCreationProjects).map(this.buildBullet)}</div>
          </div>
        </div>
      );
    }
    return null;
  }
}
CoCreationProjects.propTypes = {
  coCreationProjects: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
      thumb: PropTypes.string,
      duration: PropTypes.string,
      remuneration: PropTypes.string,
      url: PropTypes.string,
    })
  ),
  activeId: PropTypes.number,
  fetchCoCreationProjects: PropTypes.func.isRequired,
  setActiveId: PropTypes.func.isRequired,
};

CoCreationProjects.defaultProps = {
  coCreationProjects: [],
  activeId: 0,
};

export default CoCreationProjects;
