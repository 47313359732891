import _ from 'lodash';
import api from '../../components/community/utils/api';
import { clearPopularCommunities } from './popular-communities';
import { setHeroIsCommunityMember } from './community-page';
import { showCommunityPageToast } from './community-toasts';

export const SET_JOIN_COMMUNITY_ERRORS = 'SET_JOIN_COMMUNITY_ERRORS';
export const CLEAR_JOIN_COMMUNITY_ERRORS = 'CLEAR_JOIN_COMMUNITY_ERRORS';
export const ADD_HERO_TO_COMMUNITY = 'ADD_HERO_TO_COMMUNITY';

export const setJoinCommunityErrors = joinCommunityErrors => ({
  type: SET_JOIN_COMMUNITY_ERRORS,
  joinCommunityErrors,
});

export const clearJoinCommunityErrors = () => ({
  type: CLEAR_JOIN_COMMUNITY_ERRORS,
});
export const addHeroIntoCommunity = hero => ({
  type: ADD_HERO_TO_COMMUNITY,
  hero,
});
export const joinCommunity = communitySlug => (dispatch, getState) => {
  const heroProfile = getState().global.get('heroProfile');
  const hero = {
    id: heroProfile.id,
    firstname: heroProfile.firstname,
    lastname: heroProfile.lastname,
    headline: heroProfile.headline,
    profilePictureUrl: heroProfile.profilePictureUrl,
    blocked: 0,
  };
  return new Promise(resolve => {
    api
      .get(`/join/${communitySlug}`)
      .then(() => {
        dispatch(clearPopularCommunities());
        dispatch(setHeroIsCommunityMember(true));
        dispatch(showCommunityPageToast('toast.joinCommunity'));
        dispatch(addHeroIntoCommunity(hero));
        resolve(true);
      })
      .catch(({ response }) => {
        dispatch(setJoinCommunityErrors(_.get(response, 'data.error')));
        resolve(false);
      });
  });
};

export const joinPrivateCommunity = communityUrl => (dispatch, getState) => {
  const communityJoinKey = communityUrl.substring(communityUrl.indexOf('=') + 1);
  if (communityJoinKey) {
    const firstIndex = communityUrl.split('/', 2).join('/').length + 1;
    const secondIndex = communityUrl.indexOf('?');
    const communitySlug = communityUrl.substring(firstIndex, secondIndex);
    return new Promise(resolve => {
      api
        .get(`/private/join/${communitySlug}/${communityJoinKey}`)
        .then(() => {
          resolve(true);
        })
        .catch(({ response }) => {
          resolve(false);
        })
        .finally(() => {
          localStorage.removeItem('community-join-url');
        });
    });
  }
  return false;
};
