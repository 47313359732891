import React from 'react';
import PropTypes from 'prop-types';
import Sidebar from '../../../rightSidebar/rightSidebar';
import { Scrollbars } from 'react-custom-scrollbars';
import OfficeBooked from './OfficeBooked';
import ModalDialog from '../../../modalDialog/modalDialog';
import CancelBooking from '../../../modalDialog/modals/cancelBooking';
import OfficeGallery from '../../../booking/officeGallery';
import OfficeContactInfo from '../../../booking/officeContactInfo';

import '../../../booking/css/index.scss';
import moment from "moment/moment";
import circleLoader from '../../../../assets/images/circle-loader-64px.gif';
import MeetingAttendeesBox from '../../../meetingAttendees';
import _ from 'lodash';
import { withRouter } from "react-router-dom";
import CheckingPopup from "./CheckinPopup";
import ExtendBookingPopup from "./ExtendBookingPopup";
import InfoToast from "../../../community/common/info-toast/InfoToast";
import buildLocationUrl from '../../../../utils/buildLocationUrl';
import {DATE_FORMAT} from "../../../../constants";


class Booking extends React.Component {
  context;

  static contextTypes = {
    router: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    const { location } = props;
    this.state = {
      officeDetailsFetched: false,
      showBackButton: true,
      cancelBookingSpinner: false,
      cancelBookingError: '',
      showCancelBookingButton: true,
      showCancelBookingModal: _.get(location, 'state.showConfirmationPopup') === 'cancel',
      showCheckinModal: _.get(location, 'state.showConfirmationPopup') === 'checkin',
      showCheckoutModal: _.get(location, 'state.showConfirmationPopup') === 'checkout',
      showExtendModal: _.get(location, 'state.showConfirmationPopup') === 'extend',
      showToastMessage: '',
      checkinComponentHidden: null,
      checkoutComponentHidden: null
    };
    this.componentToRemove = this.componentToRemove.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {

    if (nextProps.details.toJS().office !== undefined && nextProps.details.toJS().office.id !== undefined && !this.state.officeDetailsFetched) {
      this.props.beforeFetchOfficeDetails();
      this.props.fetchOfficeDetails(nextProps.details.toJS().office.id);
      this.setState({ officeDetailsFetched: true });
    }

    if (this.state.cancelBookingSpinner === true) {
      this.setState({ cancelBookingSpinner: false }, () => {
        this.props.beforeFetchBookingDetails();
        this.props.fetchBookingDetails(this.props.match.params.id);
      });
    }

  }

  componentDidMount() {
    const { beforeFetchBookingDetails, fetchBookingDetails, history } = this.props;
    beforeFetchBookingDetails();
    fetchBookingDetails(this.props.match.params.id);
    this.historyListener = history.listen((location, action) => {
      const bookingUrl = location.pathname.match(/\/booking\/(\d+)*/i);
      const bookingId = _.nth(bookingUrl, 1);
      if (bookingId) {
        beforeFetchBookingDetails();
        fetchBookingDetails(bookingId);
      }
      this.setState({ officeDetailsFetched: false }, () => {
        this.setState({
          showCancelBookingModal: _.get(location, 'state.showConfirmationPopup') === 'cancel',
          showCheckinModal: _.get(location, 'state.showConfirmationPopup') === 'checkin',
          showCheckoutModal: _.get(location, 'state.showConfirmationPopup') === 'checkout',
          showExtendModal: _.get(location, 'state.showConfirmationPopup') === 'extend',
        });
      });
    });
  }

  componentWillUnmount() {
    this.props.fetchBookingSummary();
    this.historyListener();
  }

  componentToRemove(action){
    if(action == 'check-in'){
      this.setState({ checkinComponentHidden: false, checkoutComponentHidden: true });
    } else {
      this.setState({ checkinComponentHidden: false, checkoutComponentHidden: false});
    }

  }

  renderCheckinCheckout() {
    const { history, details, translations } = this.props;
    const { checkinComponentHidden,checkoutComponentHidden } = this.state;
    const items = details.toJS();
    const bookingId = _.get(items, 'id', null);
    const canCheckout = _.get(items, 'canCheckOut', null);
    const canCheckedIn = _.get(items, 'canCheckIn', null);
    const canExtendBooking = _.get(items, 'canExtendHour', null);
    const checkinStatus = checkinComponentHidden ? checkinComponentHidden : canCheckedIn
    const checkoutStatus = (checkoutComponentHidden != null && (checkoutComponentHidden || canCheckout)) ? checkoutComponentHidden : canCheckout;
    if (checkoutStatus) {
      return (
          <div className="bookedOffice-checkout">
            <a
                className="myBookings-link secondary-button check-btn"
                onClick={() => history.push({ pathname: `/booking/${bookingId}`, state: { showConfirmationPopup: 'checkout' } })}
            >
              {_.get(translations, 'notifications.booking.checkout_action', '')}
            </a>
            {canExtendBooking ? (
                <a
                    className="myBookings-link secondary-button extended-btn"
                    onClick={() => history.push({ pathname: `/booking/${bookingId}`, state: { showConfirmationPopup: 'extend' } })}
                >
                  {_.get(translations, 'notifications.booking.extend_action', '')}
                </a>
            ) : null}
          </div>
      );
    }
    if (checkinStatus)
      return (
          <div className="bookedOffice-checkin">
            <a
                className="myBookings-link secondary-button check-btn"
                onClick={() => history.push({ pathname: `/booking/${bookingId}`, state: { showConfirmationPopup: 'checkin' } })}
            >
              {_.get(translations, 'notifications.booking.checkin_action', '')}
            </a>
          </div>
      );
    return null;
  }

  renderBookParkingButton() {
    const { history, details, translations, officeDetails, openBookingOptions, setGoToParkingFloor, setParkingBookingDates, appTimezone } = this.props;
    const bookingDetails = details.toJS();
    const canBookParking = _.get(bookingDetails, 'canBookParking', null);
    if(!canBookParking) {
      return null;
    }
    const officeLink = buildLocationUrl(officeDetails.toJS());

    return (
        <div className="bookedOffice-checkout">
          <a
              className="myBookings-link secondary-button book-parking-button"
              onClick={() => {
                history.push({ pathname: officeLink})
                openBookingOptions();
                setGoToParkingFloor(true);
                const fromDate = _.get(bookingDetails, 'fromDate');
                const toDate = _.get(bookingDetails, 'toDate');

                const parkingBookingStartDate = moment(fromDate * 1000).tz(appTimezone).utc(true).set('hour', 0).set('minute', 0).unix();
                const parkingBookingEndDate = moment(toDate * 1000).tz(appTimezone).utc(true).set('hour', 0).set('minute', 0).unix();

                setParkingBookingDates({fromDate: parkingBookingStartDate, toDate: parkingBookingEndDate});
              }}
          >
            {_.get(translations, 'bookingPage.bookParkingSpot', 'Book-a-parking-spot')}
          </a>
        </div>
    );
  }

  cancelBooking = () => {
    this.setState({
      showCancelBookingButton: false,
      cancelBookingSpinner: true,
      showCancelBookingModal: false,
    });
    this.props.cancelBooking(this.props.details.get('id'))
      .then(response => {
        this.setToastMessage(response.message);
      }).catch(data => {
        this.setToastMessage(data.message);
      });
    this.props.history.replace(this.props.location.pathname, null);
  };

  onModalOpen = () => {
    this.setState({
      showCancelBookingModal: true
    });
  }

  onModalClose = () => {
    this.setState({
      showCancelBookingModal: false,
      showCheckinModal: false,
      showCheckoutModal: false,
      showExtendModal: false
    });
    this.props.history.replace(this.props.location.pathname, null);
  }
  setToastMessage = (message) => {
    this.setState({showToastMessage : message}, () => {
      setTimeout(() => {
        this.setState({ showToastMessage: '' });

      }, 2000);

    });
  }


  renderExtraOfficeDetails() {
    const {details} = this.props;
    const bookingDetails = details.toJS();
    const customText = _.get(bookingDetails, 'office.customText', null);
    const buttonText = _.get(bookingDetails, 'office.buttonText', null);
    const buttonLink = _.get(bookingDetails, 'office.buttonLink', null);

    const customTextarea = customText ? <div className="office-booked-details-description bookedOffice__extra-details-text">{customText}</div> : null;
    const customButton = buttonText && buttonLink ? (
        <div className="bookedOffice-checkout">
          <a
              className="myBookings-link secondary-button bookedOffice__extra-details-button"
              href={buttonLink}
              target="_blank"
          >
            {buttonText}
          </a>
        </div>
    ) : null;
    const showSection = customTextarea || customButton;

    return showSection &&
        <div className="bookedOffice__extra-details-container">
          {customTextarea}
          {customButton}
        </div>;
  }

  render() {
    let getSidebar = null;
    let isMeetingRoom = false;
    const { details, officeDetails, translations, cancelBookingData, cancelLoader, formatString, isWhiteLabel, officeTimezone } = this.props;
    if ((details && !details.size) || (officeDetails && !officeDetails.size) ) {
      return <div className="Loader"></div>;
    }
    if (details.toJS().meetingRoom !== undefined) {
      isMeetingRoom = true;
    }

      const startDate = moment(details.toJS().fromDate * 1000).tz(this.props.appTimezone).set('hour', details.getIn(['hours', 'from', 'hour'])).set('minute', details.getIn(['hours', 'from', 'minute']));
      const startingBookingString = startDate.format('YYYY-MM-DD HH:mm:ss');
      const nowDate = moment.tz(officeTimezone);
      const startBooking = moment.tz(startingBookingString, 'YYYY-MM-DD HH:mm:ss', officeTimezone);

      let diffInMinutes = Math.floor(startBooking.diff(nowDate, 'minutes'));
      const cancelButtonElement = <a className="myBookings-link secondary-button" onClick={this.onModalOpen}>{translations.bookingPage.cancelBooking}</a>
      const joinUrlText = details.toJS().calendar_provider === 'google' ? 'go_to_meet' : 'go_to_teams'
      const joinUrlClass = details.toJS().calendar_provider === 'google' ? 'gotoMeetButton' : 'gotoTeamsButton'
      let cancelButton = (details.toJS().status !== 'canceled') ? (
        <div className="cancelActionContainer">
          {(cancelLoader) ?
            <div><img alt="" style={{ height: 58, width: 58 }} src={circleLoader} /></div> : ''}
          {(this.state.showCancelBookingButton) ?
            cancelButtonElement
            : ''}
        </div>
      ) : null;

      // Should be only visible for white label if the link to Teams/Google is available
      // and the booking is not canceled
      let goToCalendarButton = (details.toJS().hasOwnProperty('join_url') && cancelButton) ? (
        <div className={joinUrlClass}>
          <a className="myBookings-link secondary-button" href={details.toJS().join_url} target="_blank" rel="noopener noreferrer">
            {translations.booking.sidebar[joinUrlText]}
          </a>
        </div>
      ) : null;


      getSidebar = <Sidebar>
        <div className="not-booked-location">
          <table style={{ width: "100%" }}>
            <tbody>
            <tr>
              <td>
                <table style={{ width: "100%", margin: "0px" }}>
                  <tbody>
                  <tr>
                    <td>
                      { isWhiteLabel ? this.renderCheckinCheckout() : null }
                      { isWhiteLabel ? goToCalendarButton : null }
                      { isWhiteLabel ? this.renderBookParkingButton() : null }
                      { cancelButton }
                      { this.renderExtraOfficeDetails()}
                    </td>
                  </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </Sidebar>;
      let images = officeDetails.toJS().images;
      let map = {
        defaultZoom: 10,
        zoom: 15,
        center: { lat: officeDetails.toJS().location.latitude, lng: officeDetails.toJS().location.longitude },
        markers: [
          {
            id: 1,
            location: {
              latitude: officeDetails.toJS().location.latitude,
              longitude: officeDetails.toJS().location.longitude
            }
          }
        ]
      };
      const phoneNumberCode = _.get(officeDetails.toJS(),'phoneNumber.countryCode', null);
      const phoneNumberValue = _.get(officeDetails.toJS(),'phoneNumber.number', null);
      let contactInfo = {
        map: map,
        address: officeDetails.toJS().address,
        name: officeDetails.toJS().contactPerson.name,
        phone: (!phoneNumberCode || !phoneNumberValue) ? null : phoneNumberCode + ' ' + phoneNumberValue,
        emailPerson: officeDetails.toJS().contactPerson.email,
        emailOffice: officeDetails.toJS().email, // email
        additional: details.toJS().additionalInfo
      };

      let backButton =  <a onClick={this.props.history.goBack} className="myBooking-navigation">{this.props.translations.connection.back}</a> ;

      let statusLabel = <span className="success-message"> {this.props.translations.bookingPage.isConfirmed}</span>;
      switch (this.props.details.toJS().status) {
        case 'canceled':
          statusLabel = <span className="error-message"> {this.props.translations.bookingPage.isCanceled}</span>;
          break;
        case 'active':
          statusLabel = <span className="active-message"> {this.props.translations.bookingPage.isActive}</span>;
          break;
        case 'closed':
          statusLabel = <span className="closed-message"> {this.props.translations.bookingPage.isClosed}</span>;
          break;
        case 'pending':
          statusLabel = <span className="pending-message"> {this.props.translations.bookingPage.isPending}</span>;
          break;
        default:
          break;
      }
      const cantWait = _.get(translations, 'location.cantWait', "We can't wait to meet you!");

      return (<>
        {this.state.showToastMessage ? <InfoToast message={this.state.showToastMessage}/> :  null}
          <div className="location-container">
           <section className="location-content">
             <Scrollbars className="styleScroll">
               <div className="location-holder">
                {backButton}
                <div className="location-booked-title items-style1">{this.props.translations.bookingPage.yourBookingAt} {details.toJS().office.name}
                  {statusLabel}{this.props.details.toJS().status === 'active' ? '. ' + cantWait : '.'}</div>
                { isMeetingRoom &&  <MeetingAttendeesBox capacity={this.props.details.toJS().meetingRoom.seats} bookingId={this.props.details.get('id')} isInternalEnv diffInMinutes={diffInMinutes}  bookingStatus={this.props.details.toJS().status}/> }
                <OfficeBooked details={this.props.details} trans={this.props.translations} appTimezone={this.props.appTimezone} />
                <OfficeGallery images={images} trans={this.props.translations} />
                <OfficeContactInfo contactInfo={contactInfo} title={this.props.translations.bookingPage.locationName} trans={this.props.translations} />
              </div>
            </Scrollbars>
          </section>
          {getSidebar}
          <ModalDialog
            modalOpened={this.state.showCancelBookingModal}
            className="--cancel-booking-modal"
            onModalClose={this.onModalClose}
            label="cancelBooking">
            <CancelBooking
              onAction={this.cancelBooking}
              onCancelAction={this.onModalClose}
              trans={this.props.translations}
              customMessage = {_.get(translations,'bookingPage.confirmBookingCancellation', 'bookingPage.confirmBookingCancellation')}
              booking={this.props.details}
            />
          </ModalDialog>
          <ModalDialog
            modalOpened={this.state.showCheckinModal}
            className="--cancel-booking-modal"
            onModalClose={this.onModalClose}
            label="cancelBooking">
            <CheckingPopup
              title={_.get(translations, 'notifications.booking.checkin_action', 'notifications.booking.checkin_action')}
              onAction={this.props.checkin}
              onCancelAction={this.onModalClose}
              trans={translations}
              booking={details.toJS()}
              images={images}
              cancelButtonText={_.get(translations, 'whiteLabel.refuseCheckin', 'whiteLabel.refuseCheckin')}
              submitButtonText= {_.get(translations,'whiteLabel.checkMeIn', 'whiteLabel.checkMeIn')}
              formatString={formatString}
              setToastMessage={this.setToastMessage}
              componentToRemove={this.componentToRemove}
              actionType = 'check-in'
              customMessage = {_.get(translations,'whiteLabel.checkinCustomMessage', 'whiteLabel.checkinCustomMessage')}
            />
          </ModalDialog>
          <ModalDialog
            modalOpened={this.state.showCheckoutModal}
            className="--cancel-booking-modal"
            onModalClose={this.onModalClose}
            label="cancelBooking">
            <CheckingPopup
              title={_.get(translations, 'notifications.booking.checkout_action', 'notifications.booking.checkout_action')}
              onAction={this.props.checkout}
              onCancelAction={this.onModalClose}
              trans={translations}
              customMessage = {_.get(translations,'whiteLabel.checkoutCustomMessage', 'whiteLabel.checkoutCustomMessage')}
              booking={details.toJS()}
              images={images}
              cancelButtonText={_.get(translations,'whiteLabel.refuseCheckOut', 'whiteLabel.refuseCheckOut')}
              submitButtonText={_.get(translations,'whiteLabel.checkMeOut', 'whiteLabel.checkMeOut')}
              componentToRemove={this.componentToRemove}
              formatString={formatString}
              setToastMessage={this.setToastMessage}
              actionType = 'check-out'
            />
          </ModalDialog>
          <ModalDialog
            modalOpened={this.state.showExtendModal}
            className="--cancel-booking-modal"
            onModalClose={this.onModalClose}
            label="cancelBooking">
            <ExtendBookingPopup
              onAction={this.props.extendBooking}
              onCancelAction={this.onModalClose}
              trans={translations}
              booking={details.toJS()}
              formatString={formatString}
              setToastMessage={this.setToastMessage}
              officeDetails={officeDetails.toJS()}
            />
          </ModalDialog>
        </div>
        </>
      );
    }
}

export default withRouter(Booking);
