import React from 'react';
import _ from 'lodash';
import sizedImage from '../../../../utils/imageHelper';

const CheckingPopup = props => {
  const { booking, formatString, title, customMessage, componentToRemove, actionType } = props;
  const id = _.get(booking, 'id', null);
  const location = _.get(booking, 'office.name', null);
  const officeType = _.get(booking, 'product.officeTypeId', 0) === 1 ? 'desk' : 'meeting room';
  const startHours = `${_.get(booking, 'hours.from.hour')}:${_.get(booking, 'hours.from.minute')}`;
  const endHours = `${_.get(booking, 'hours.to.hour')}:${_.get(booking, 'hours.to.minute')}`;
  return (
    <div className="cancelBooking">
      <div onClick={props.onCancelAction} className="modal-close-button" />
      <div className="bookedOffice-popup-title">{title}</div>
      <div className="bookedOffice-popup-body" style={{ display: 'flex' }}>
        <img src={sizedImage(props.images[0].url, 80, 60)} />
        <span className="bookedOffice-text">{formatString(customMessage, officeType, startHours, location, endHours)}</span>
      </div>
      <div className="cancelBooking-actions">
        <button style={{ marginLeft: '0' }} className="cancelBooking-blockButton secondary-button" onClick={props.onCancelAction}>
          {props.cancelButtonText}
        </button>
        <button
          style={{ marginLeft: '0' }}
          className="cancelBooking-cancelButton bookedOffice-submitButton main-button"
          onClick={() => {
            props
              .onAction(id)
              .then(response => {
                props.setToastMessage(response.message);
              })
              .catch((response = {}) => {
                props.setToastMessage(response);
              })
              .finally(() => {
                componentToRemove(actionType);
              });
            props.onCancelAction();
          }}
        >
          {props.submitButtonText}
        </button>
      </div>
    </div>
  );
};
export default CheckingPopup;
