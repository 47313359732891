import { useEffect } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import {
  addSocketMessage,
  fetchDirectChatConversations,
  setConversationOnlineStatusList,
  setHeroStatus,
  setOnlineStatus,
} from '../../actions/directChat';
import ChatService from '../../services/chat.service';
import intercomLoader from '../../intercomLoader';
import { fetchNotifications, getUnreadNotificationsCount } from '../../actions/notificationsActions';
import { fetchProfile, fetchRestrictions } from '../../actions/accountActions';
import { fetchMyBookings } from '../../actions/officesActions';
import { fetchBookingSummary, fetchGuestBookingSummary } from '../../actions/bookingActions';
import { clearPopularCommunities } from '../../actions/community/popular-communities';
import { getVerticals } from '../../actions/global';
import { fetchHeroRecommendation } from '../../actions/dashboard/hero-recommendation';
import { getCurrentHostname } from '../../utils/currentCompanyHostname';

function ActivateServices(props) {
  const pushLastMessage = message => {
    props.addSocketMessage(message);
    if (message.clientMessageId === undefined) {
      props.fetchDirectChatConversations();
    }
  };
  const init = () => {
    ChatService.on('login-response', response => {
      if (response.success === true) {
        props.setOnlineStatus(true);
      } else {
        props.setOnlineStatus(false);
      }
    });
    ChatService.on('receive-message', response => {
      pushLastMessage(response.message, true);
    });
    ChatService.on('login-response', response => {
      if (response.success === true) {
        setConversationOnlineStatusList(response.onlineHeroIds);
      }
    });
    ChatService.on('hero-connected', response => {
      props.setHeroStatus(response.heroId, true);
    });
    ChatService.on('hero-disconnected', response => {
      props.setHeroStatus(response.heroId, false);
    });
    ChatService.initAndLogin();
    intercomLoader();
  };

  useEffect(() => {
    if (props.isAuthenticated) {
      props.fetchProfile();
      props.fetchRestrictions();
      props.fetchNotifications();
      props.getUnreadNotificationsCount();
      props.fetchMyBookings();
      props.fetchBookingSummary();
      props.clearPopularCommunities();
      props.getVerticals();
      props.fetchHeroRecommendation();
      init();
    } else {
      if (!props.loading && !props.validSubdomain) {
        props.fetchGuestBookingSummary();
      }
    }
  }, [props.isAuthenticated, props.loading]);

  useEffect(() => {
    if (props.isAuthenticated) {
      if (_.get(props.heroProfile, 'isWhiteLabel', false)) {
        const lang = localStorage.getItem('lang');
        const heroLang = _.get(props.heroProfile, 'language', 'en');

        if (lang !== heroLang) {
          localStorage.setItem('lang', heroLang);
          window.location.reload();
        }
      }
      const companySubdomain = _.get(props.heroProfile, 'companySubdomain', null);
      if (getCurrentHostname() !== companySubdomain && companySubdomain !== null) {
        const redirectTo = window.__env.baseUrl.replace(getCurrentHostname(), companySubdomain);
        localStorage.removeItem('access-key');
        window.location.href = `${redirectTo}/login`;
      }
    }
  }, [props.heroProfile, props.isAuthenticated]);
  return null;
}
const mapStateToProps = state => ({
  isAuthenticated: state.auth.get('isAuthenticated'),
  heroProfile: state.global.get('heroProfile'),
  online: state.directChat.get('online'),
  conversationsInitialLoaded: state.directChat.get('conversationsInitialLoaded'),
  connectionsInitialLoaded: state.directChat.get('connectionsInitialLoaded'),
  conversations: state.directChat.get('conversations'),
  validSubdomain: state.global.get('existingSubdomain'),
});
const mapDispatchProps = dispatch => ({
  setOnlineStatus: status => {
    dispatch(setOnlineStatus(status));
  },
  addSocketMessage: args => {
    dispatch(addSocketMessage(args));
  },
  fetchDirectChatConversations: () => {
    dispatch(fetchDirectChatConversations());
  },
  setConversationOnlineStatusList: list => {
    dispatch(setConversationOnlineStatusList(list));
  },
  setHeroStatus: (heroId, online) => {
    dispatch(setHeroStatus(heroId, online));
  },
  fetchNotifications: (pageNumber, pageSize) => {
    dispatch(fetchNotifications(pageNumber, pageSize));
  },
  getUnreadNotificationsCount: () => {
    dispatch(getUnreadNotificationsCount());
  },
  fetchProfile: () => {
    dispatch(fetchProfile());
  },
  fetchRestrictions: () => {
    dispatch(fetchRestrictions());
  },
  fetchMyBookings: () => {
    dispatch(fetchMyBookings());
  },
  fetchBookingSummary: () => {
    dispatch(fetchBookingSummary());
  },
  fetchGuestBookingSummary: () => {
    dispatch(fetchGuestBookingSummary());
  },
  clearPopularCommunities: () => {
    dispatch(clearPopularCommunities());
  },
  getVerticals: () => {
    dispatch(getVerticals());
  },
  fetchHeroRecommendation: () => {
    dispatch(fetchHeroRecommendation());
  },
});
export default connect(mapStateToProps, mapDispatchProps)(ActivateServices);
