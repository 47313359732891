import { connect } from 'react-redux';
import staffListing from './StaffListing';
import { fetchStaffMembers } from '../../../../../actions/whiteLabel/staff';
import { staffMembersSelector, staffMembersLoadingSelector } from '../../../../../selectors/whiteLabel/staff';
import { selectedDateSelector } from '../../../../../selectors/whiteLabel/booking';

const mapStateToProps = state => {
  return {
    translations: state.translations.booking,
    staff: staffMembersSelector(state),
    isLoadingStaff: staffMembersLoadingSelector(state),
    officeId: state.location.get('details').get('id'),
    selectDateRange: selectedDateSelector(state),
  };
};

const mapDispatchProps = dispatch => {
  return {
    fetchStaffMembers: (officeId, date) => dispatch(fetchStaffMembers(officeId, date)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchProps
)(staffListing);
