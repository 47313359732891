import React, { Component } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import _ from 'lodash';
import computeTimeTo from '../../utils/compute-time-to';
import getLang from '../../../../utils/lang';

const lang = getLang() !== null ? getLang() : 'en';
const getBookingInterval = (fromDate, toDate) => {
  if (fromDate && toDate) {
    const startDate = new Date(fromDate).toLocaleDateString(lang, { month: 'short', year: 'numeric', day: 'numeric', timeZone: 'UTC' });
    if (fromDate !== toDate) {
      const endDate = new Date(toDate).toLocaleDateString(lang, { month: 'short', year: 'numeric', day: 'numeric', timeZone: 'UTC' });
      return `${startDate} - ${endDate}`;
    }
    return `${startDate}`;
  }
  return '';
};

const goToBooking = id => {
  window.location.href = `/booking/${id}`;
};

const buildUpcomingBooking = (booking, translations) => {
  const bookingId = _.get(booking, 'id', null);
  const fromDate = _.get(booking, 'from_date', null);
  const toDate = _.get(booking, 'to_date', null);
  const fromHour = _.get(booking, 'from_hour', null);
  const officeName = _.get(booking, 'office_name', null);
  const timezone = _.get(booking, 'timezone', null);

  return (
    <div key={bookingId} className="upcoming-booking__container" onClick={goToBooking.bind(null, [bookingId])}>
      <div className="upcoming-booking__info">
        <div
          className={classnames('upcoming-booking__img-container', {
            'upcoming-booking__img-container-no-logo': _.isEmpty(booking.image),
          })}
        >
          {!_.isEmpty(booking.image) ? (
            <img className="upcoming-booking__img" src={booking.image} alt="Booking" />
          ) : (
            <div className="upcoming-booking__no-img">{_.get(translations, 'noPhoto', 'No photo')}</div>
          )}
        </div>
        <div className="upcoming-booking__description">
          <div className="upcoming-booking__title">{officeName}</div>
          <div className="upcoming-booking__date">{getBookingInterval(fromDate, toDate)}</div>
          <div className="upcoming-booking__start">
            {_.get(translations, 'startsIn', 'Starts in')}
            <span>{computeTimeTo(fromDate, fromHour, timezone)}</span>
          </div>
        </div>
      </div>
      <button type="button" className="dashboard-button dashboard-button--secondary dashboard-button__view-booking">
        {_.get(translations, 'view', 'View')}
      </button>
    </div>
  );
};

const handleOnClick = () => {
  window.location.href = `/my-bookings`;
};

class UpcomingBookings extends Component {
  componentDidMount() {
    const { fetchUpcomingBookings } = this.props;
    fetchUpcomingBookings();
  }

  render() {
    const { bookings, translations } = this.props;

    if (!_.isEmpty(bookings)) {
      return (
        <div className="dashboard-grid__row dashboard-grid__row--margin-bottom">
          <div className="card__container card__container--border card__upcoming-bookings">
            <h4 className="card__title">{_.get(translations, 'upcomingBookings', 'Upcoming Bookings')}</h4>
            <div className="upcoming-booking_wrapper">
              {bookings.map(booking => {
                return buildUpcomingBooking(booking, translations);
              })}
            </div>
            <button type="button" className="dashboard-button dashboard-button--border upcoming-bookings__button" onClick={handleOnClick}>
              {_.get(translations, 'seeAllBookings', 'See all bookings')}
            </button>
          </div>
        </div>
      );
    }
    return '';
  }
}

UpcomingBookings.propTypes = {
  bookings: PropTypes.arrayOf(
    PropTypes.shape({
      booking_id: PropTypes.number,
      from_date: PropTypes.date,
      to_date: PropTypes.date,
      from_hour: PropTypes.date,
      office_id: PropTypes.number,
      office_name: PropTypes.string,
      image: PropTypes.string,
    })
  ),
  fetchUpcomingBookings: PropTypes.func.isRequired,
};
UpcomingBookings.defaultProps = {
  bookings: [],
};
export default UpcomingBookings;
