/*
 * Constants
 */
export const SET_COMMUNITY_PAGE_TOAST_MESSAGE = 'SET_COMMUNITY_PAGE_TOAST_MESSAGE';
export const SET_COMMUNITIES_PAGE_TOAST_MESSAGE = 'SET_COMMUNITIES_PAGE_TOAST_MESSAGE';

/*
 * Sync Actions
 */
export const setCommunityPageToastMessage = communityPageToastMessage => ({
  type: SET_COMMUNITY_PAGE_TOAST_MESSAGE,
  communityPageToastMessage,
});

export const setCommunitiesPageToastMessage = communitiesPageToastMessage => ({
  type: SET_COMMUNITIES_PAGE_TOAST_MESSAGE,
  communitiesPageToastMessage,
});

/*
 * Async Actions
 */
export const showCommunityPageToast = (communityPageToastMessage, timer = 3000) => dispatch => {
  dispatch(setCommunityPageToastMessage(communityPageToastMessage));
  setTimeout(() => {
    dispatch(setCommunityPageToastMessage(null));
  }, timer);
};

export const showCommunitiesPageToast = (communitiesPageToastMessage, timer = 5000) => dispatch => {
  dispatch(setCommunitiesPageToastMessage(communitiesPageToastMessage));
  setTimeout(() => {
    dispatch(setCommunitiesPageToastMessage(null));
  }, timer);
};
