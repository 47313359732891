import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import _ from 'lodash';
import ProfileForm from './profile-form';

const HeroProfile = props => (
  <Scrollbars className="styleScroll">
    <div className="hero-profile__container">
      <div className="hero-profile__welcome-message">{_.get(props.translations, 'title', '')}</div>
      <div className="hero-profile__profile-message">{_.get(props.translations, 'subtitle', '')}</div>
      <div className="hero-profile__profile-message">{_.get(props.translations, 'allFieldsRequired', '')}</div>
      <ProfileForm />
    </div>
  </Scrollbars>
);

export default HeroProfile;
