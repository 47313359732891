import { connect } from 'react-redux';
import _ from 'lodash';
import BookingPayment from './BookingPayment';
import {
  beforeFetchOfficeDetails,
  fetchOfficeDetails, fetchPaymentMethods, fetchPaymentProfiles,
  onChangeSection,
  resetBooking,
  resetVoucherInfo,
} from '../../actions/locationActions';
import { translations } from '../../selectors/translations';
import './index.scss';
import { fetchCountries } from '../../actions/generalDataActions';

const mapStateToProps = state => ({
  heroProfile: state.global.get('heroProfile'),
  bookingValidate: state.addBooking.get('bookingValidate'),
  locationDetails: state.location.get('details'),
  translations: _.get(translations(state), 'payment', {}),
});

const mapDispatchToProps = dispatch => ({
  onChangeSection: bookingSection => dispatch(onChangeSection(bookingSection)),
  resetBooking: () => dispatch(resetBooking()),
  fetchOfficeDetails: id => dispatch(fetchOfficeDetails(id)),
  beforeFetchOfficeDetails: id => dispatch(beforeFetchOfficeDetails(id)),
  fetchCountries: () => dispatch(fetchCountries()),
  resetVoucherInfo: () => dispatch(resetVoucherInfo()),
  fetchPaymentMethods: () => dispatch(fetchPaymentMethods()),
  fetchPaymentProfiles: () => dispatch(fetchPaymentProfiles()),
});

export default connect(mapStateToProps, mapDispatchToProps)(BookingPayment);
