import { connect } from 'react-redux';
import _ from 'lodash';
import PaymentMethods from './PaymentMethods';
import {
  fetchBookingValidate,
  onChangeSection,
  openBookingOptions,
  redeemVoucher,
  createHeroPaymentProfile,
  resetBooking,
  fetchPaymentProfiles,
  createPaymentMethod,
} from '../../../actions/locationActions';

import { validateVoucher } from '../../../actions/accountPaymentSettings/vouchers';
import { translations } from '../../../selectors/translations';

const mapStateToProps = state => ({
  formatString: _.get(translations(state), 'formatString', () => {}),
  translations: _.get(translations(state), 'paymentProfile', {}),
  bookingValidate: state.addBooking.get('bookingValidate'),
  selectedRoom: state.addBooking.get('selectedRoom'),
  selectedProduct: state.addBooking.get('selectedProduct'),
  selectedOptions: state.addBooking.get('selectedOptions'),
  selectedServices: state.addBooking.get('selectedServices'),
  voucherInfo: state.addBooking.get('voucherInfo'),
  locationDetails: state.location.get('details'),
});

const mapDispatchToProps = dispatch => ({
  onChangeSection: bookingSection => dispatch(onChangeSection(bookingSection)),
  openBookingOptions: () => dispatch(openBookingOptions()),
  createHeroPaymentProfile: (data, cb) => dispatch(createHeroPaymentProfile(data, cb)),
  redeemVoucher: (code, args) => dispatch(redeemVoucher(code, args)),
  fetchBookingValidate: args => dispatch(fetchBookingValidate(args)),
  resetBooking: () => dispatch(resetBooking()),
  fetchPaymentProfiles: () => dispatch(fetchPaymentProfiles()),
  validateVoucher: code => {
    return dispatch(validateVoucher(code));
  },
  createPaymentMethod: (data, cb) => dispatch(createPaymentMethod(data, cb)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentMethods);
