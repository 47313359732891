import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import addLoader from '../../../dashboard/utils/add-loader';

const LoadMore = ({ fetchNextPosts, trans }) => (
  <div className="all-communities__load_more">
    <button id="all-communities-load-more" type="button" className="dashboard-button dashboard-button--border" onClick={fetchNextPosts}>
      {_.get(trans, 'popular.loadMore', 'Load More')}
    </button>
  </div>
);

LoadMore.propTypes = {
  fetchNextPosts: PropTypes.func.isRequired,
};

export default addLoader(LoadMore);
