/**
 * Renders a location-type maps infoWindow
 *
 * @class LocationMarker
 * @module MapContainer
 */
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import StarRating from '../../starRating/starRating';
import sizedImage from '../../../utils/imageHelper';
import workeroLogYellow from '../../../assets/svg/header/logo-yellow.svg';

class LocationMarker extends React.Component {
  constructor() {
    super();
    this.state = {
      distance: null,
    };
    this.callback = this.callback.bind(this);
    this.getTimeSpentBetweenDistance = this.getTimeSpentBetweenDistance.bind(this);
  }

  componentDidMount() {
    this.getTimeSpentBetweenDistance();
  }

  getRating() {
    if (this.props.markerInfo.getIn(['rating', 'average']) > 0) {
      return (
        <div className="officeBox-infoSmall">
          {this.props.markerInfo.getIn(['rating', 'votes'])}{' '}
          <StarRating initialRate={this.props.markerInfo.getIn(['rating', 'average'])} theme="theme-yellow-small" readonly />
        </div>
      );
    }
    return null;
  }

  getTimeSpentBetweenDistance() {
    const service = new window.google.maps.DistanceMatrixService();
    service.getDistanceMatrix(
      {
        origins: [{ lat: _.get(this.props, 'geoLocationCoords.latitude', ''), lng: _.get(this.props, 'geoLocationCoords.longitude', '') }],
        destinations: [
          {
            lat: _.get(this.props.markerInfo.toJS(), 'location.latitude', ''),
            lng: _.get(this.props.markerInfo.toJS(), 'location.longitude', ''),
          },
        ],
        travelMode: 'DRIVING',
        unitSystem: window.google.maps.UnitSystem.METRIC,
        avoidHighways: false,
        avoidTolls: false,
      },
      this.callback
    );
  }

  callback(response, status) {
    if (status === 'OK') {
      this.setState({ distance: response.rows[0].elements[0].duration.text });
    }
  }

  getMarkerImage() {
    const { markerInfo } = this.props;
    const markerInfoData = markerInfo.toJS();
    if (_.get(markerInfoData, 'isCompanyPin', false)) {
      if (_.get(markerInfoData, 'imageUrl', null)) {
        return (
          <div
            className="markerInfo-image markerInfo-image-review"
            style={{
              backgroundImage: `url('${sizedImage(this.props.markerInfo.get('imageUrl'), 100, 0)}')`,
              backgroundSize: '100%',
              backgroundRepeat: 'no-repeat',
            }}
          />
        );
      }
      return null;
    }
    const healthMeasureIcon = _.get(markerInfoData, 'healthMeasuresStamp', false) ? <span className="health-measure-icon" /> : null;
    const temperatureScannerIcon = _.get(markerInfoData, 'temperatureControlStamp', false) ? (
      <span className="temperature-scanner-icon" />
    ) : null;
    const premiumPartnerIcon = _.get(markerInfoData, 'premiumSupplier', false) ? <span className="premium-partner-icon" /> : null;

    // handle office images
    if (_.get(markerInfoData, 'imageUrl', '')) {
      return (
        <div
          className="markerInfo-image"
          style={{
            backgroundImage: `url('${sizedImage(this.props.markerInfo.get('imageUrl'), 300, 0)}')`,
            width: '300px',
            height: '100px',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
          }}
        >
          {premiumPartnerIcon}
          {healthMeasureIcon}
          {temperatureScannerIcon}
        </div>
      );
    }
    // yellow workero logo
    return (
      <div
        className="markerInfo-image markerInfo-default-image"
        style={{
          backgroundImage: `url('${workeroLogYellow}')`,
          width: '300px',
          height: '100px',
          backgroundSize: '45%',
          backgroundRepeat: 'no-repeat',
        }}
      >
        {premiumPartnerIcon}
        {healthMeasureIcon}
        {temperatureScannerIcon}
      </div>
    );
  }

  getMarkerProducts() {
    const { markerInfo } = this.props;
    const markerInfoData = markerInfo.toJS();
    const desksCount = _.get(markerInfoData, 'productsCount.desks', 0);
    const privateOfficesCount = _.get(markerInfoData, 'productsCount.privateOffices', 0);
    const meetingRoomsCount = _.get(markerInfoData, 'productsCount.meetingRooms', 0);
    const eventRoomsCount = _.get(markerInfoData, 'productsCount.eventRooms', 0);

    return (
      <div className="markerInfo-products__container">
        {desksCount ? (
          <div className="markerInfo-products__item">
            <span className="markerInfo-products__product-icon desk-icon" />
            <span className="markerInfo-products__product-count">{desksCount}</span>
          </div>
        ) : null}
        {meetingRoomsCount ? (
          <div className="markerInfo-products__item">
            <span className="markerInfo-products__product-icon meetingRoom-icon" />
            <span className="markerInfo-products__product-count">{meetingRoomsCount}</span>
          </div>
        ) : null}
        {privateOfficesCount ? (
          <div className="markerInfo-products__item">
            <span className="markerInfo-products__product-icon privateOffice-icon" />
            <span className="markerInfo-products__product-count">{privateOfficesCount}</span>
          </div>
        ) : null}
        {eventRoomsCount ? (
          <div className="markerInfo-products__item">
            <span className="markerInfo-products__product-icon eventRoom-icon" />
            <span className="markerInfo-products__product-count">{eventRoomsCount}</span>
          </div>
        ) : null}
      </div>
    );
  }

  render() {
    const { distance } = this.state;
    const { markerInfo, trans } = this.props;
    const markerInfoData = markerInfo.toJS();
    const isCompanyPin = _.get(markerInfoData, 'isCompanyPin', false);
    const companyClassModifier = isCompanyPin ? 'company-info-pin' : '';
    const companyStatus = _.get(markerInfoData, 'status', false);
    const statusClassModifier = companyStatus !== 'live' ? 'markerInfo--coming-soon' : '';
    const premiumPartnerIcon = _.get(markerInfoData, 'premiumSupplier', true) ? <span className="premium-partner-icon" /> : null;
    const awayLabel = distance ? _.get(trans, 'away', 'away') : '';

    return (
      <div className={`markerInfo ${(companyClassModifier, statusClassModifier)}`}>
        {this.props.markerInfo.get('status') === 'live' ? (
          <div
            onMouseDown={e => {
              if (this.props.markerInfo.get('status') === 'live') {
                this.props.onBookItem();
              }
            }}
          >
            <div className="markerInfo-image-container">{this.getMarkerImage()}</div>
            <div className="markerInfo-data">
              <div className="markerInfo-title">{this.props.markerInfo.get('name')}</div>
              <div className="markerInfo-info">
                {distance} {awayLabel}
              </div>
              {this.getMarkerProducts()}
              {this.getRating()}
            </div>
          </div>
        ) : (
          <div>
            <div className="markerInfo__coming-soon">
              <div>{_.get(trans, 'comingSoon', 'Coming Soon')}</div>
            </div>
            {_.get(markerInfoData, 'imageUrl', '') ? <div className="markerInfo-image-container">{this.getMarkerImage()}</div> : null}
            <div className="markerInfo-data">
              <div className="markerInfo-title">
                {premiumPartnerIcon}
                {this.props.markerInfo.get('name')}
              </div>
              <div className="markerInfo-info">
                {distance} {awayLabel}
              </div>
            </div>
          </div>
        )}

        <span
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            this.props.onCloseClick();
          }}
          className="markerInfo-close"
        >
          ×
        </span>
      </div>
    );
  }
}

export default LocationMarker;

LocationMarker.propTypes = {
  markerInfo: PropTypes.object.isRequired,
  onCloseClick: PropTypes.func,
  onBookItem: PropTypes.func,
};
