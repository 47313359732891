/**
 * Renders the connection visual
 * @module DirectChat
 * @class Connection
 */
import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';

const Contact = props => {
  const avatarStyle =
    props.item.get('profilePictureUrl') !== null
      ? {
          backgroundSize: 'cover',
          backgroundImage: `url('${props.item.get('profilePictureUrl')}')`,
        }
      : {};
  return (
    <div
      className="directChat-contacts-connection"
      onClick={e => {
        props.onConnectionSelection();
        props.actions.openConversation(props.item.get('id'), props.item);
      }}
    >
      {props.item.get('profilePictureUrl') ? (
        <div
          className={classnames('directChat-contacts-conversationAvatar', 'directChat-contacts-conversationImage', {
            defaultAvatar2: props.item.get('profilePictureUrl') === null,
          })}
          style={avatarStyle}
        />
      ) : (
        <div className="hero-initials messages-hero-initials">{props.item.get('heroInitials')}</div>
      )}

      <div className="directChat-contacts-conversationDetails">
        <div className="directChat-contacts-conversationPoster">
          <div className="directChat-contacts-conversationPosterName">
            {props.item.get('firstName')}
            <span className="directChat-contacts-conversationPosterLastName">{` ${props.item.get('lastName')}`}</span>
          </div>
        </div>
        <div className="directChat-contacts-conversationPreview">
          <p className="directChat-contacts-conversationPreviewText notMuted">{props.item.getIn(['position', 'title'])}</p>
        </div>
      </div>
    </div>
  );
};

Contact.propTypes = {
  /**
   * Contains all available dispatched actions for this component
   * @type {Object}
   */
  actions: PropTypes.object.isRequired,
  /**
   * Contains latest conversation data for the respective contact
   * @type {Immutable.List<Conversation>}
   */
  item: PropTypes.object.isRequired,
  /**
   * Handler to lift up the action to the parent component so scrollbar offset
   * can be reset
   * @type {Function}
   */
  onConnectionSelection: PropTypes.func.isRequired,
};

export default Contact;
