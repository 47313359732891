import { connect } from 'react-redux';
import { acceptInvitation } from '../../actions/openAuth/acceptInvitation';
import Invitation from './invitation';
import { clearPopularCommunities } from '../../actions/community/popular-communities';
import { fetchMyBookings } from '../../actions/officesActions';
import { fetchBookingSummary } from '../../actions/bookingActions';
import { fetchProfile } from '../../actions/accountActions';

const mapStateToProps = () => ({});

const mapDispatchProps = dispatch => ({
  acceptInvitation: data => dispatch(acceptInvitation(data)),
  fetchProfile: () => dispatch(fetchProfile()),
  clearPopularCommunities: () => dispatch(clearPopularCommunities()),
  fetchMyBookings: () => dispatch(fetchMyBookings()),
  fetchBookingSummary: () => dispatch(fetchBookingSummary()),
});

export default connect(
  mapStateToProps,
  mapDispatchProps
)(Invitation);
