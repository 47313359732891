/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import LoadingGif from '../../../assets/images/loader.gif';

const buildLoader = () => (
    <div className={classnames('profile-loader__container')}>
        <img className="profile-loader__image" src={LoadingGif} alt="Loading" />
    </div>
);

const addLoader = (WrappedComponent) => wrappedComponentProps => {
    const isLoading = _.get(wrappedComponentProps, 'isLoading', false);
    return (
        <>
            {isLoading ? buildLoader() : null}
            <span className={classnames('profile-loader-content', { 'profile-loader-content--hidden': isLoading })}>
        <WrappedComponent {...wrappedComponentProps} />
      </span>
        </>
    );
};

export default addLoader;
