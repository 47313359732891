import classnames from 'classnames';
import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';

const VerticalItem = props => {
  const { id, name, value, onVerticalClick } = props;
  return (
    <div className={classnames('profile-form__tag', { 'profile-form__tag-checked': value })} onClick={() => onVerticalClick(id)}>
      {name}
    </div>
  );
};

const VerticalPicker = props => {
  const { question, allVerticals, checkedVerticals, onVerticalClick, error } = props;
  const hmapChecked = {};
  for (let i = 0; i < checkedVerticals.length; i++) {
    hmapChecked[_.get(checkedVerticals[i], 'id')] = checkedVerticals[i];
  }

  return (
    <div className="profile-form__picker">
      <div className={classnames('profile-form__label', { 'profile-form__label-error': error })}>{question}</div>
      <div className="profile-form__tags">
        {allVerticals.map(vertical => (
          <VerticalItem
            id={vertical.id}
            key={vertical.id}
            name={vertical.name}
            value={!!hmapChecked[vertical.id]}
            onVerticalClick={onVerticalClick}
          />
        ))}
      </div>
    </div>
  );
};

VerticalPicker.propTypes = {
  allVerticals: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ),
  checkedVerticals: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ),
  onVerticalClick: PropTypes.func.isRequired,
};
VerticalPicker.defaultProps = {
  allVerticals: [],
  checkedVerticals: [],
};

VerticalItem.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  value: PropTypes.bool,
  onVerticalClick: PropTypes.func.isRequired,
};
VerticalItem.defaultProps = {
  id: null,
  name: null,
  value: null,
};

export default VerticalPicker;
