import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import _ from 'lodash';
import InfoToast from '../community/common/info-toast/InfoToast';

import BookingRecommendation from './components/booking-recommendation';
import HeroRecommendation from './components/hero-recommendation';
import { NewCompanyProfile } from './components/new-company-profile';
import UpcomingBookings from './components/upcoming-bookings';
import CoCreationProjects from './components/co-creation-projects';
import MostActiveHeroes from './components/most-active-heroes';
import News from './components/news';

const Dashboard = props => (
  <Scrollbars className="styleScroll">
    {props.toastMessage && <InfoToast message={_.get(props.translations, props.toastMessage)}/> }
    <div className="dashboard__container">
      <div className="dashboard-grid">
        <div className="dashboard-grid__column dashboard-grid__column--left">
          <div className="dashboard-grid__row dashboard-grid__row--margin-bottom">
            <div className="card__container card__container--dark card__container--margin-right card__container--fixed-height">
              <h4 className="card__title"> {_.get(props.translations, 'bookingRecommendation', 'Booking Recommendation')}</h4>
              <BookingRecommendation />
            </div>
            <div className="card__container card__container--dark card__container--fixed-height">
              <h4 className="card__title">{_.get(props.translations, 'heroRecommendationOfTheDay', 'Hero Recommendation of the Day')}</h4>
              <HeroRecommendation />
            </div>
          </div>
          <div className="dashboard-grid__row">
            <News />
          </div>
        </div>
        <div className="dashboard-grid__column dashboard-grid__column--right">
          <NewCompanyProfile />
          {props.isAuthenticated && <UpcomingBookings />}
          <CoCreationProjects />
          <MostActiveHeroes />
        </div>
      </div>
    </div>
  </Scrollbars>
);

export default Dashboard;
