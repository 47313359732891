import api from '../components/myMeetings/utils/api';
import { setDashboardToastMessage } from './dashboard/toasts';

export const SET_MEETING_IS_LOADING = 'SET_MEETING_IS_LOADING';
export const SET_MEETING = 'SET_MEETING';

export const setMeetingIsLoading = isLoading => ({
  type: SET_MEETING_IS_LOADING,
  isLoading,
});
export const setMeeting = meeting => ({
  type: SET_MEETING,
  meeting,
});

export const fetchMeeting = id => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(setMeetingIsLoading(true));
    api
      .get(`/hero/meetings/${id}`)
      .then(({ data }) => {
        if (data.data) {
          dispatch(setMeeting(data.data));
          resolve();
        }
      })
      .catch(() => {
        dispatch(setDashboardToastMessage('theMeetingHasBeenCancelled'));
        reject();
      })
      .finally(() => {
        dispatch(setMeetingIsLoading(false));
      });
  });

export const acceptMeetingInvitation = attendeeId => () => {
  return new Promise((resolve, reject) => {
    api
      .post(`${window.__env.apiUrl}/hero/meetingAttendees/accept`, { attendee_id: attendeeId })
      .then(() => {
        resolve();
      })
      .catch(() => {
        reject();
      })
      .finally(() => {});
  });
};

export const declineMeetingInvitation = attendeeId => () => {
  return new Promise((resolve, reject) => {
    api
      .post(`${window.__env.apiUrl}/hero/meetingAttendees/decline`, { attendee_id: attendeeId })
      .then(() => {
        resolve();
      })
      .catch(() => {
        reject();
      })
      .finally(() => {});
  });
};
