import React from 'react';
import _ from 'lodash';
import classnames from 'classnames';

import noPicture from '../../../assets/svg/dashboard/profile-picture__no_picture.svg';

const buildUserProfilePicture = (picture, action, customClass) => (
  <div
    className={classnames('dashboard__profile-picture-container', { [customClass]: customClass })}
    onClick={event => {
      if (_.isFunction(action)) {
        action(event);
      }
    }}
  >
    <img className="user-profile-picture" src={picture || noPicture} alt="User Profile" />
  </div>
);

export default buildUserProfilePicture;
