import React from 'react';
import _ from 'lodash';
import Config from '../../../utils/const';
import iconEn from '../../../assets/images/lang/EN.png';

class LanguageSelector extends React.Component {
  constructor() {
    super();
    this.state = {
      showDropdown: false,
    };
    this.showDropdownLanguages = this.showDropdownLanguages.bind(this);
    this.changeLanguage = this.changeLanguage.bind(this);
  }

  showDropdownLanguages() {
    const { showDropdown } = this.state;
    this.setState({ showDropdown: !showDropdown });
  }

  changeLanguage(language) {
    const { heroProfile, isAuthenticated, saveHeroProfile, isWhiteLabel } = this.props;
    localStorage.setItem('lang', language);
    if (isAuthenticated && isWhiteLabel) {
      const heroProfileHash = heroProfile;
      heroProfileHash.language = language;
      saveHeroProfile(heroProfileHash).then(() => {
        window.location.reload();
      });
    } else {
      window.location.reload();
    }
  }

  renderLanguages() {
    const { translations } = this.props;
    return (
      <div className="language-list">
        <ul>
          {Config.locales.map(locale => {
            return (
              <li>
                <img className="language-icon" src={locale.image} alt="" />
                <span
                  onClick={() => {
                    this.changeLanguage(locale.id);
                  }}
                >
                  {_.get(translations, `${locale.value}`, locale.value)}
                </span>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }

  render() {
    const { showDropdown } = this.state;
    const languageSelector = showDropdown ? this.renderLanguages() : null;
    const selectedLang = Config.locales.filter(locale => locale.id === localStorage.getItem('lang'));

    return (
      <>
        <div className="feedback notifications" onClick={this.showDropdownLanguages}>
          <img src={_.get(selectedLang, '0.image', iconEn)} alt="Language selector icon " className="language-icon" />
          {languageSelector}
        </div>
      </>
    );
  }
}
export default LanguageSelector;
