import Immutable from 'immutable';
import { FETCH_EVENTS_ALL, FETCH_EVENTS_NEARBY, FETCH_EVENTS_ACTIVE, FETCH_EVENT, CLEAR_EVENT } from './../actions/eventsActions';

const defaultState = Immutable.fromJS({
  eventsAll: [],
  eventsNearby: [],
  eventsActive: [],
  openedEvent: null
});

const eventsState = (state = defaultState, action) => {
  let newState;

  switch (action.type) {
    case FETCH_EVENTS_ALL:
      newState = state.set('eventsAll', Immutable.fromJS(action.data));
      break;

    case FETCH_EVENTS_NEARBY:
      newState = state.set('eventsNearby', Immutable.fromJS(action.data));
      break;
    
    case FETCH_EVENTS_ACTIVE:
      newState = state.set('eventsActive', Immutable.fromJS(action.data));
      break;

    case FETCH_EVENT:
      newState = state.set('openedEvent', Immutable.fromJS(action.data));
      break;
    
    case CLEAR_EVENT:
      newState = state.set('openedEvent', null);
      break;

    default:
      newState = state;
  }

  return newState;
};

export default eventsState;
