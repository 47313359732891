import { Scrollbars } from 'react-custom-scrollbars';
import React from 'react';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import PropTypes from 'prop-types';
import MeetingDetails from './meetingDetails';
import BookingDetails from './bookingDetails';
import OfficeGallery from '../booking/officeGallery';
import OfficeContactInfo from '../booking/officeContactInfo';
import LoadingGif from '../../assets/images/loader.gif';

class MyMeeting extends React.Component {
  componentDidMount() {
    const { fetchMeeting, location, history } = this.props;
    const meetingId = location.pathname.split('/')[2];
    if (meetingId) {
      fetchMeeting(meetingId).catch(() => {
        history.push('/dashboard');
      });
    }
    this.historyListener = history.listen((location, action) => {
      const historyMeetingId = location.pathname.split('/')[2];
      if (historyMeetingId) {
        fetchMeeting(historyMeetingId).catch(() => {
          history.push('/dashboard');
        });
      }
    });
  }

  componentWillUnmount() {
    this.historyListener();
  }

  render() {
    const { history, translations, meeting, isLoading } = this.props;
    const images = _.get(meeting, 'officeImages', []);
    const officeDetails = _.get(meeting, 'officeDetails', []);
    const officeLatitude = _.get(officeDetails, 'lat', null);
    const officeLongitude = _.get(officeDetails, 'lng', null);
    const bookingStatus = _.get(meeting, 'bookingStatus');

    if (isLoading) {
      return (
        <div className="meeting-loader__container">
          <img className="meeting-loader__image meeting-loader__image--margin" src={LoadingGif} alt="Loading" />
        </div>
      );
    }

    const map = {
      defaultZoom: 10,
      zoom: 15,
      center: { lat: officeLatitude, lng: officeLongitude },
      markers: [
        {
          id: 1,
          location: {
            latitude: officeLatitude,
            longitude: officeLongitude,
          },
        },
      ],
    };
    const contactInfo = {
      map,
      address: {
        street: _.get(officeDetails, 'street', null),
        postCode: _.get(officeDetails, 'postCode', null),
        city: _.get(officeDetails, 'city', null),
      },
      name: _.get(officeDetails, 'contactPerson', null),
      phone: _.get(officeDetails, 'phoneNumber', null),
      emailPerson: _.get(officeDetails, 'emailAddress', null),
      emailOffice: _.get(officeDetails, 'officeEmail', null),
      additional: _.get(meeting, 'additionalInfo', []),
    };

    return (
      <div className="location-container">
        <section className="location-content">
          <Scrollbars className="styleScroll">
            <div className="location-holder">
              <a onClick={history.goBack} className="myBooking-navigation">
                {_.get(translations, 'meetings.back', 'Back')}
              </a>
              <div className="location-booked-title items-style1">
                {_.get(translations, 'meetings.youHaveANewInvite', 'You have a new invite!')}{' '}
                {bookingStatus === 'canceled' && (
                  <span className="error-message">
                    {_.get(translations, 'meetings.thisBookingIsCancelled', 'This booking is cancelled!')}
                  </span>
                )}
              </div>
            </div>
            <MeetingDetails meeting={meeting} />
            <BookingDetails meeting={meeting} />
            <div className="meeting-details__section-wrapper">
              <OfficeGallery images={images} trans={translations} />
            </div>
            <div className="meeting-details__section-wrapper">
              <OfficeContactInfo hasPaddingBottom contactInfo={contactInfo} trans={translations} />
            </div>
          </Scrollbars>
        </section>
      </div>
    );
  }
}

MyMeeting.propTypes = {
  meeting: PropTypes.shape({}).isRequired,
  isLoading: PropTypes.bool,
  match: PropTypes.shape({}),
  history: PropTypes.shape({
    goBack: PropTypes.func,
  }),
  translations: PropTypes.shape({}),
  fetchMeeting: PropTypes.func,
};

MyMeeting.defaultProps = {
  isLoading: false,
  match: {},
  history: {},
  translations: {},
  fetchMeeting: () => {},
};

export default withRouter(MyMeeting);
