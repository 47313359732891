import { connect } from 'react-redux';

import Connections from '../../components/connections/connections';
import { markerClick, markerClose } from '../../actions/map';
import { showMessageModal } from '../../actions/modals';
import { fetchProfile } from '../../actions/accountActions';
import {
  fetchConnections,
  onSuggestionsChange,
  changeSuggestionFilters,
  deselectAll,
  fetchConnection,
} from '../../actions/connections';

const mapStateToProps = state => {
  return {
    // existing: state.connections.get('existing').filter(_item => _item.get('connected')),
    connection: state.connections.get('currentConnection'),
    hasMore: state.connections.get('hasMore'),
    existing: state.connections.get('existing'),
    loaded: state.connections.get('loaded'),
    total: state.connections.get('total'),
    page: state.connections.get('page'),
    nearby: state.connections.get('nearby'),
    recommended: state.connections.get('recommended'),
    loadMoreSpinner: state.connections.get('loadMoreSpinner'),
    newConnections: state.connections.get('newConnections'),
    selectedConnections: state.connections.get('selectedConnections'),
    selectedFilters: state.connections.get('selectedFilters'),
    add_multiple_connections: state.connections.get('add_multiple_connections'),
    hasMoreToLoad: state.connections.get('hasMoreToLoad'),
    heroProfile: state.global.get('heroProfile'),
    mapData: state.map,
    lastRecommendedConnections: state.connections.get('lastRecommendedConnections'),
    translations: state.translations,
  };
};

const mapDispatchProps = dispatch => {
  return {
    fetchConnections: page => {
      dispatch(fetchConnections(page));
    },

    fetchConnection: connectionId => {
      dispatch(fetchConnection(connectionId));
    },

    fetchProfile: () => {
      dispatch(fetchProfile());
    },

    markerClick: markerID => {
      dispatch(markerClick(markerID));
    },

    markerClose: markerID => {
      dispatch(markerClose(markerID));
    },

    showMessageModal: userData => {
      dispatch(showMessageModal(userData));
    },
    onSuggestionsChange: (value, checked) => {
      dispatch(onSuggestionsChange(value, checked));
    },
    deselectAll: () => {
      dispatch(deselectAll());
    },
    changeSuggestionFilters: value => {
      dispatch(changeSuggestionFilters(value));
    },
  };
};

const ConnectionsView = connect(
  mapStateToProps,
  mapDispatchProps
)(Connections);

export default ConnectionsView;
