import React from 'react';
import PropTypes from 'prop-types';
import './../css/requestExport.scss';

class RequestExport extends React.Component {

  onRequest = () => {
    this.props.onAction();
  }

  onCancelRequest = () => {
    this.props.onCancelAction(false);
  }

  render () {
    return (
      <div className="requestExport">
        <div onClick={ this.onCancelRequest } className="modal-close-button"></div>
        <div className="requestExport-title items-style1">{this.props.trans.modals.requestExport.title}</div>
        <div className="requestExport-warning">{this.props.trans.modals.requestExport.warning}</div>
        <div className="requestExport-actions">
          <button
          style={{marginLeft: '0'}}
            className="requestExport-blockButton secondary-button"
            onClick={ this.onRequest }>{this.props.trans.modals.requestExport.requestButtonLabel}</button>
          <button
            style={{marginLeft: '0'}}
            className="requestExport-cancelButton main-button"
            onClick={ this.onCancelRequest }>{this.props.trans.modals.requestExport.changedMyMindButtonLabel}</button>
        </div>
      </div>
    );
  }

}

RequestExport.propTypes = {
  onAction: PropTypes.func
};

export default RequestExport;
