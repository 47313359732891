import _ from 'lodash';
import moment from 'moment';
import ImageResizer from '../../../utils/imageHelper';
/**
 * Function used to get value from JSON
 *
 * Returns the matched element, else null
 *
 * @param meta
 * @param path
 * @param search
 */
export const findValueInJson = (meta, path, search) => _.get(_.find(meta, o => _.get(o, path, '') === search), 'answer', null);

export const buildTags = tag => {
  return `${tag.label}`;
};

const mapNewsArticle = item => {
  const article = {
    id: _.get(item, 'id', null),
    type: _.get(item, 'type', null),
    category: _.get(item, 'category', null),
    title: _.get(item, 'title', null),
    date: _.get(item, 'date', null) ? moment(_.get(item, 'date', null)).format('DD MMMM YYYY') : '',
    link: _.get(item, 'link', null),
    image: _.get(item, 'image', null),
    video: _.get(item, 'video', null),
    content: _.get(item, 'content', null),
    tags: _.get(item, 'tags', null),
  };

  if (item.rawData) {
    const meta = JSON.parse(item.rawData);
    if (item.type === 'twitter') {
      article.tweetCounts = _.get(_.find(meta, o => _.get(o, 'retweet_count', '')), 'retweet_count', '0');
      article.tweetFavs = _.get(_.find(meta, o => _.get(o, 'favourite_count', '')), 'favourite_count', '0');
      const twitterAccountRaw = _.get(item, 'category', null);
      if (twitterAccountRaw) {
        const accountParts = twitterAccountRaw
          .replace('@', '')
          .split('/')
          .filter(function(el) {
            return el !== null && el.length !== 0;
          });
        if (accountParts.length === 1) {
          article.category = `@${accountParts[0]}`;
        } else {
          article.category = `@${accountParts.pop()}`;
        }
      }
    } else if (item.type === 'blog') {
      article.tags = meta.categories;
    } else if (item.type === 'news') {
      article.publisher = meta.publisher;
    } else {
      article.content = findValueInJson(meta, 'question.code', 'description');
      article.image = ImageResizer(findValueInJson(meta, 'question.type', 'image'), 180, 120, article.id);
      article.video = findValueInJson(meta, 'question.type', 'video');
      article.title = findValueInJson(meta, 'question.code', 'title');
      article.link = `/dashboard/media/${article.id}`;
      article.openSameTab = true;

      const tags = findValueInJson(meta, 'question.code', 'tags') || [];
      article.tags = tags.map(buildTags).join(', ');
    }
  }

  return article;
};

export default mapNewsArticle;
