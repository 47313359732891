import React, { Component } from 'react';
import _ from 'lodash';
import { Scrollbars } from 'react-custom-scrollbars';
import PropTypes from 'prop-types';
import SimilarMedia from './components/media/SimilarMedia';
import InfoCard from './components/media/InfoCard';
import { MediaCompanyProfile } from './components/new-company-profile';
import MediaFeedback from './components/media/media-feedback';
import { buildArticleBlock } from './utils/create-article-blocks';
import addLoader from './utils/add-loader';

const MediaMainSection = addLoader(({ mediaArticle }) => (
  <>
    <div className="dashboard-media__background">
      <div className="dashboard-media__wrapper">
        <div className="media__content">
          { mediaArticle.blocks.map(buildArticleBlock) }
          <div className="media__footer">
              <MediaFeedback mediaId={_.get(mediaArticle, 'id')} vote={_.get(mediaArticle, 'vote')} />
          </div>
        </div>
      </div>
    </div>
  </>
));

class Media extends Component {
  componentDidMount() {
    const { fetchMediaArticle, fetchSimilarMediaItems, fetchMediaCompanyProfile, fetchAdditionalMediaItems, match } = this.props;
    const id = _.get(match.params, 'id', 0);
    fetchMediaArticle(id).then(article => {
      const mediaTypeId = _.get(article, 'media_type_id');

      if (mediaTypeId === 1) {
        fetchAdditionalMediaItems(article, 3);
      } else if (mediaTypeId === 3) {
        fetchAdditionalMediaItems(article, 1);
      }
      fetchMediaCompanyProfile(_.get(article, 'company_id'));
      fetchSimilarMediaItems(article, _.get(article, 'media_type_id'));
    });
  }

  buildSimilarItems(similarMediaItems, articleType, selectedVertical) {
    if (similarMediaItems.length > 0) {
      return (
        <div className="media-dashboard__card">
          <SimilarMedia items={similarMediaItems} articleType={articleType} verticalName={selectedVertical} showAdditional={false} />
        </div>
      );
    }
    return null;
  }

  buildAdditionalItems(additionalMediaItems, articleType, selectedVertical, articleTypeId) {
    if (additionalMediaItems.length > 0) {
      if (articleTypeId === 1) {
        return (
          <div className="media-dashboard__card">
            <SimilarMedia items={additionalMediaItems} articleType="Co-creation Projects" verticalName={selectedVertical} showAdditional />
          </div>
        );
      }
      if (articleTypeId === 3) {
        return (
          <div className="media-dashboard__card">
            <SimilarMedia items={additionalMediaItems} articleType="Jobs" verticalName={selectedVertical} showAdditional />
          </div>
        );
      }
    }
    return null;
  }

  render() {
    const { mediaArticle, similarMediaItems, additionalMediaItems, selectedVertical } = this.props;
    const articleType = _.get(mediaArticle, 'type.name', '');
    const articleTypeId = _.get(mediaArticle, 'type.id', '');
    return (
      <Scrollbars className="styleScroll">
        <div className="dashboard-media__container">
          <div className="dashboard-media__main">
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
              {mediaArticle && mediaArticle.blocks ? <MediaMainSection {...this.props} /> : null}
          </div>
          <div className="dashboard-media__sidebar">
            <div className="dashboard-media__sidebar-wrapper">
              <div className="media-dashboard__card apply-card">
                {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                <InfoCard {...this.props} />
              </div>
              <MediaCompanyProfile media companyId={_.get(mediaArticle, 'company_id')} />
              {this.buildSimilarItems(similarMediaItems, articleType, selectedVertical)}
              {this.buildAdditionalItems(additionalMediaItems, articleType, selectedVertical, articleTypeId)}
            </div>
          </div>
        </div>
      </Scrollbars>
    );
  }
}

Media.propTypes = {
  fetchMediaArticle: PropTypes.func.isRequired,
  mediaArticle: PropTypes.shape({}).isRequired,
  similarMediaItems: PropTypes.arrayOf(PropTypes.shape({})),
  additionalMediaItems: PropTypes.arrayOf(PropTypes.shape({})),
  selectedVertical: PropTypes.string,
  fetchSimilarMediaItems: PropTypes.func.isRequired,
  fetchAdditionalMediaItems: PropTypes.func.isRequired,
  fetchMediaCompanyProfile: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({}).isRequired,
  }).isRequired,
};

Media.defaultProps = {
  similarMediaItems: [],
  additionalMediaItems: [],
  selectedVertical: null,
};

export default Media;
