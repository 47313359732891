import iconEn from '../assets/images/lang/EN.png';
import iconEs from '../assets/images/lang/ES.png';
import iconNl from '../assets/images/lang/NL.png';
import iconFr from '../assets/images/lang/FR.png';
import iconIt from '../assets/images/lang/IT.png';

export default {
  navigation: {
    advancedSearch: 'advancedSearch',
    app: 'app',
    awesomeButton: 'awesomeButton',
    booking: 'booking',
    buttons: 'buttons',
    community: 'community',
    healthMeasure: 'healthMeasure',
    connections: 'connections',
    directChat: 'directChat',
    ecoStats: 'ecoState',
    events: 'events',
    heroAddEditPaymentProfile: 'heroAddEditPaymentProfile',
    heroGeneralSettings: 'heroGeneralSettings',
    heroPaymentSettings: 'heroPaymentSettings',
    heroProfile: 'heroProfile',
    typography: 'typography',
    isMobile: 'isMobile',
    maintenance: 'maintenance',
    dashboard: 'dashboard',
    profile: 'profile',
    heroProfileIncomplete: 'heroProfileIncomplete',
    openLogin: 'openLogin',
    openSignup: 'openSignup',
    openForgotPassword: 'openForgotPassword',
  },
  keyCodes: {
    ESC: 27,
    ENTER: 13,
  },
  weekdays: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
  supportId: 'support-team',
  format: {
    numberSeparator: ',',
  },
  isAuthenticated: !!localStorage.getItem('access-key'),
  locales: [
    { id: 'en', value: 'English', image: iconEn },
    { id: 'nl', value: 'Dutch', image: iconNl },
    { id: 'fr', value: 'French', image: iconFr },
    { id: 'es', value: 'Spanish', image: iconEs },
    { id: 'it', value: 'Italian', image: iconIt },
  ],
};
