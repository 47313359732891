import React from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import InputField from '../inputField';
import { getCurrentHostname } from '../../../utils/currentCompanyHostname';

class SignUpForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: localStorage.getItem('first-name'),
      lastName: localStorage.getItem('last-name'),
      email: '',
      password: '',
      passwordConfirmation: '',
      errors: {},
      isLoading: false,
      isEmailDisabled: false,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  componentDidMount() {
    const invitationWhitelabel = localStorage.getItem('invitation-whitelabel');
    if (invitationWhitelabel === '1') {
      const invitationEmail = localStorage.getItem('invitation-email');
      this.setState({ email: invitationEmail, isEmailDisabled: true });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { isEmailDisabled: oldDisabled } = prevState;
    const invitationWhitelabel = localStorage.getItem('invitation-whitelabel');
    if (!oldDisabled && invitationWhitelabel === '1') {
      const invitationEmail = localStorage.getItem('invitation-email');
      this.setState({ email: invitationEmail, isEmailDisabled: true });
    }
  }

  handleInputChange(key, value) {
    switch (key) {
      case 'firstName':
        this.setState({ firstName: value });
        break;
      case 'lastName':
        this.setState({ lastName: value });
        break;
      case 'email':
        this.setState({ email: value });
        break;
      case 'password':
        this.setState({ password: value });
        break;
      case 'passwordConfirmation':
        this.setState({ passwordConfirmation: value });
        break;
      default:
    }
  }

  handleFormSubmit() {
    const { firstName, lastName, email, password, passwordConfirmation } = this.state;
    const {
      history,
      signup,
      location,
      setHeroDataAfterLogin,
      updateRegisteredAttendee,
      joinPrivateCommunity,
      setIsWhiteLabel,
    } = this.props;
    const fromUrl = _.get(location, 'fromUrl', location.pathname);
    const invitationKey = localStorage.getItem('invitation-key');
    const credentials = {
      firstName,
      lastName,
      email,
      password,
      password_confirmation: passwordConfirmation,
      invitationKey,
    };
    this.setState({ isLoading: true });
    signup(credentials)
      .then(response => {
        const accessKey = _.get(response, 'data.access-key', null);
        if (accessKey) {
          localStorage.setItem('access-key', accessKey);
          localStorage.setItem('first-name', _.get(response, 'data.heroData.firstName', null));
          localStorage.setItem('last-name', _.get(response, 'data.heroData.lastName', null));
          localStorage.setItem('show-welcome-pop-up', true);
          localStorage.setItem('source', 'email');
          localStorage.removeItem('invitation-key');
          localStorage.removeItem('invitation-email');
          localStorage.removeItem('invitation-whitelabel');
          setHeroDataAfterLogin(!!accessKey);
          const attendeeKey = localStorage.getItem('meeting-attendee-key');
          if (attendeeKey) {
            const meetingId = localStorage.getItem('meeting-id');
            updateRegisteredAttendee({ attendeeKey, meetingId });
          }
          const communityUrl = localStorage.getItem('community-join-url');
          if (communityUrl) {
            joinPrivateCommunity(communityUrl);
          }
          history.replace({
            pathname: '/profile',
            fromUrl,
            state: {
              from: location.pathname,
              fromBooking: _.get(location, 'state.fromBooking', false),
            },
          });
        }
      })
      .catch(error => {
        this.setState({ isLoading: false });
        const errors = _.get(error, 'response.data.messages', null);
        if (errors) {
          this.setState({ errors });
        } else if (_.get(error, 'response.data.type') === 'wms') {
          const subdomain = _.get(error, 'response.data.subdomain', '');
          const { isWmsApp } = window.__env;
          if (subdomain) {
            window.location.href = `${window.__env.baseUrl.replace(getCurrentHostname(), subdomain)}/login`;
          } else if (!isWmsApp) {
            setIsWhiteLabel(true);
          }
        }
      });
  }

  render() {
    const { translations, history } = this.props;
    const { firstName, lastName, email, password, passwordConfirmation, errors, isLoading, isEmailDisabled } = this.state;
    const signupTranslations = _.get(translations, 'signup', []);
    const requiredLabel = _.get(translations, 'required', '(required)');
    const firstNameLabel = `${_.get(translations, 'firstName', 'First name')} ${requiredLabel}`;
    const surnameLabel = `${_.get(translations, 'surname', 'Surname')} ${requiredLabel}`;
    const emailLabel = `${_.get(translations, 'email', 'Email')} ${requiredLabel}`;
    const passwordLabel = `${_.get(translations, 'password', 'Password')} ${requiredLabel}`;
    const passwordConfirmationLabel = `${_.get(translations, 'retypePassword', 'Re-type password')} ${requiredLabel}`;
    const submitMessage = _.get(signupTranslations, 'action', 'Create account & continue');
    const firstNameError = _.get(errors, 'firstName.0', null) ? _.get(signupTranslations, errors.firstName[0], true) : false;
    const surnameError = _.get(errors, 'surname.0', null) ? _.get(signupTranslations, errors.surname[0], true) : false;
    const emailError = _.get(errors, 'email.0', null) ? _.get(signupTranslations, errors.email[0], true) : false;
    const passwordError = _.get(errors, 'password.0', null) ? _.get(signupTranslations, errors.password[0], true) : false;
    const passwordConfirmationError = _.get(errors, 'passwordConfirmation.0', null)
      ? _.get(signupTranslations, errors.passwordConfirmation[0], true)
      : false;
    const laterMessage = _.get(signupTranslations, 'doThisLater', "I'll do this later");

    return (
      <>
        <form
          className="login-form__container"
          onSubmit={event => {
            event.preventDefault();
            event.stopPropagation();
            this.handleFormSubmit();
          }}
        >
          <div className="login-form__row">
            <div className="login-form__columns">
              <InputField
                label={firstNameLabel}
                name="firstName"
                value={firstName}
                type="text"
                handleInputChange={this.handleInputChange}
                error={firstNameError}
                className="signup__first-name-input"
              />
              <InputField
                label={surnameLabel}
                name="lastName"
                value={lastName}
                type="text"
                handleInputChange={this.handleInputChange}
                error={surnameError}
                className="signup__last-name-input"
              />
            </div>
          </div>
          <div className="login-form__row">
            <InputField
              label={emailLabel}
              name="email"
              value={email}
              type="email"
              handleInputChange={this.handleInputChange}
              error={emailError}
              className="signup__email-input"
              isDisabled={isEmailDisabled}
            />
          </div>
          <div className="login-form__row">
            <div className="login-form__columns">
              <InputField
                label={passwordLabel}
                name="password"
                value={password}
                type="password"
                handleInputChange={this.handleInputChange}
                error={passwordError}
                className="signup__password-input"
              />
              <InputField
                label={passwordConfirmationLabel}
                name="passwordConfirmation"
                value={passwordConfirmation}
                type="password"
                handleInputChange={this.handleInputChange}
                error={passwordConfirmationError}
                className="signup__password-confirmation-input"
              />
            </div>
          </div>
          <div className="login-form__row">
            <button id="open-signup" className="login-form__submit-button signup__button" type="submit" disabled={isLoading}>
              {!isLoading ? submitMessage : <div className="open-login__loader" />}
            </button>
          </div>
        </form>
        <div className="open-login__later-message" onClick={() => history.push('/dashboard')}>
          {laterMessage}
        </div>
      </>
    );
  }
}

export default withRouter(SignUpForm);
