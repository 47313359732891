import React, { Component } from 'react';
import './css/notifications.scss';
import classnames from 'classnames';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import sizedImage from '../../utils/imageHelper';
import Timer from '../community/utils/timer';
import getLang from '../../utils/lang';

const lang = getLang() !== null ? getLang() : 'en';

class NotificationItem extends Component {
  constructor(props) {
    super(props);
    this.goToActionUrl = this.goToActionUrl.bind(this);
    this.getImageUrl = this.getImageUrl.bind(this);
  }

  getImageUrl() {
    const { content } = this.props;
    const jsonContent = JSON.parse(content);
    return _.get(jsonContent, 'image_url', null);
  }

  getNotificationText(type) {
    const { content, trans } = this.props;
    const jsonContent = JSON.parse(content);
    switch (type) {
      case 'office_live':
        const officeName = _.get(jsonContent, 'office_name', null);
        const OfficeLiveMessage = trans.formatString(
          _.get(trans, 'notifications.officeLive.message', '{0} went live!'),
          `<span>${officeName}</span>`
        );
        return <p dangerouslySetInnerHTML={{ __html: OfficeLiveMessage }} />;
      case 'community_post':
        const communityName = _.get(jsonContent, 'community_name', null);
        const authorName = _.get(jsonContent, 'author_name', null);
        const CommunityPostMessage = trans.formatString(
          _.get(trans, 'notifications.communityPost.message', '{0} community has a new post from {1}'),
          `<span>${communityName}</span>`,
          `<span>${authorName}</span>`
        );
        return <p dangerouslySetInnerHTML={{ __html: CommunityPostMessage }} />;
      case 'meeting_invitation':
        const heroName = _.get(jsonContent, 'hero_name', null);
        const meetingLocation = _.get(jsonContent, 'office_name', null);
        const meetingDate = _.get(jsonContent, 'when', null);
        const meetingInvitation = trans.formatString(
          _.get(trans, 'notifications.meeting.meeting_invitation', '{0} invited you to meet on {1} at {2}'),
          `<span>${heroName}</span>`,
          `<span>${moment(meetingDate)
            .locale(lang)
            .format('dddd, MMM D')}</span>`,
          `<span>${meetingLocation}</span>`
        );
        return <p dangerouslySetInnerHTML={{ __html: meetingInvitation }} />;
      case 'meeting_response':
        const invitatedHero = _.get(jsonContent, 'hero_name', null);
        const invitationStatus = _.get(jsonContent, 'invitation_status', '');
        const meetingResponse = trans.formatString(
          _.get(trans, 'notifications.meeting.meeting_response', ''),
          `<span>${invitatedHero}</span>`,
          `${invitationStatus}`
        );
        return <p dangerouslySetInnerHTML={{ __html: meetingResponse }} />;
      case 'meeting_reminder':
        const meetingOfficeLocation = _.get(jsonContent, 'office_name', null);
        const meetingReminder = trans.formatString(
          _.get(trans, 'notifications.meeting.meeting_reminder', 'Your meeting at {0} is coming up in 1 day.'),
          `<span>${meetingOfficeLocation}</span>`
        );
        return <p dangerouslySetInnerHTML={{ __html: meetingReminder }} />;
      case 'booking_checkin_reminder':
        const bookingCheckinReminder = _.get(
          trans,
          'notifications.booking.checkin_reminder',
          "Your booking is about to start. Please Check-in or Cancel if you can't make it"
        );
        return <p dangerouslySetInnerHTML={{ __html: bookingCheckinReminder }} />;
      case 'booking_checkin':
        const bookingCheckin = _.get(
          trans,
          'notifications.booking.checkin',
          "Your booking has started. Please Check-in or Cancel if you can't make it"
        );
        return <p dangerouslySetInnerHTML={{ __html: bookingCheckin }} />;
      case 'booking_checkout':
        const bookingCheckout = _.get(
          trans,
          'notifications.booking.checkout',
          'Your booking is about to end, please dont forget to Check - out or Extend it if you need more time.'
        );
        return <p dangerouslySetInnerHTML={{ __html: bookingCheckout }} />;
      case 'booking_checkout_reminder':
        const bookingCheckoutReminder = _.get(
          trans,
          'notifications.booking.checkout_reminder',
          'Your booking has ended, please dont forget to Check - out or Extend it if you need more time.'
        );
        return <p dangerouslySetInnerHTML={{ __html: bookingCheckoutReminder }} />;
      default:
        return null;
    }
  }

  getPrimaryButton() {
    const { trans, content } = this.props;
    const jsonContent = JSON.parse(content);
    const oldActionText = _.get(jsonContent, 'action_text', null);
    const oldActionUrl = _.get(jsonContent, 'action_url', null);

    const actionTextKey = oldActionText || _.get(jsonContent, 'actions.0.text', null);
    const actionUrl = oldActionUrl || _.get(jsonContent, 'actions.0.url', null);
    const buttonStyle = _.get(jsonContent, 'actions.0.button_style', 'secondary-button-leftSide');
    const confirmationPopup = _.get(jsonContent, 'actions.0.showConfirmationPopup', null);
    const newState = confirmationPopup ? { showConfirmationPopup: confirmationPopup, stopFetch: true } : null;
    if (!actionUrl) {
      return null;
    }
    return (
      <span className={buttonStyle} onClick={() => this.goToActionUrl(actionUrl, newState)}>
        {_.get(trans, actionTextKey, null)}
      </span>
    );
  }

  getSecondaryButton() {
    const { trans, content } = this.props;
    const jsonContent = JSON.parse(content);
    const actionTextKey = _.get(jsonContent, 'actions.1.text', null);
    const actionUrl = _.get(jsonContent, 'actions.1.url', null);
    const buttonStyle = _.get(jsonContent, 'actions.1.button_style', 'secondary-button-leftSide');
    const confirmationPopup = _.get(jsonContent, 'actions.1.showConfirmationPopup', null);
    const newState = confirmationPopup ? { showConfirmationPopup: confirmationPopup, stopFetch: true } : null;

    if (!actionUrl) {
      return null;
    }
    return (
      <span className={classnames('secondary-action', buttonStyle)} onClick={() => this.goToActionUrl(actionUrl, newState)}>
        {_.get(trans, actionTextKey, null)}
      </span>
    );
  }

  goToActionUrl(actionUrl, newState) {
    const { notificationId, markOneAsRead, history, seen, hideNotifications } = this.props;
    if (!seen) {
      markOneAsRead(notificationId);
    }
    hideNotifications();

    const params = {
      pathname: actionUrl,
    };

    if (newState) {
      params.state = newState;
    }
    history.push(params);
  }

  render() {
    const { type, seen, notificationDate } = this.props;
    const itemClass = !seen ? 'notification-unseen' : '';
    const imageUrl = this.getImageUrl();

    return (
      <li className={itemClass}>
        <div
          className={classnames('notifications-result-image', { defaultAvatar: !imageUrl })}
          style={{
            backgroundSize: 'cover',
            backgroundImage: `url('${sizedImage(imageUrl, 45, 45)}')`,
          }}
        />
        <div className="notifications-result-details">
          {this.getNotificationText(type)}
          <span className="notifications-result-time">{Timer(notificationDate)}</span>
          <div style={{ marginTop: '15px' }}>
            {this.getPrimaryButton()}
            {this.getSecondaryButton()}
          </div>
        </div>
      </li>
    );
  }
}

export default withRouter(NotificationItem);
