import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { Link, withRouter } from 'react-router-dom';
import _ from 'lodash';
import classnames from 'classnames';
import Footer from './Footer';
import SignUpForm from './signUpForm';
import setCommunityUrlInLocalStorage from './utils/setCommunityUrlInLocalStorage';
import AzureLogo from '../../assets/svg/azure-ad-logo.png';
import GoogleLogo from '../../assets/svg/google-logo.svg';

const queryString = require('query-string');

class CustomSignup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
    };
    this.handleSSOButtonClick = this.handleSSOButtonClick.bind(this);
  }

  componentDidMount() {
    const { history, validateInvitation, setIsWhiteLabel } = this.props;
    const queryParams = _.get(history, 'location.state.queryParams', null);
    if (queryParams) {
      const params = queryString.parse(queryParams);
      const invitationKey = _.get(params, 'invitation_key', null);
      if (invitationKey) {
        localStorage.setItem('invitation-key', invitationKey);
        validateInvitation({ invitationKey })
          .then(data => {
            const email = _.get(data, 'email', null);
            if (email) {
              localStorage.setItem('invitation-email', email);
              localStorage.setItem('first-name', _.get(data, 'firstName', null));
              localStorage.setItem('last-name', _.get(data, 'lastName', null));
              const isWhitelabel = _.get(data, 'isWhitelabel', 0);
              localStorage.setItem('invitation-whitelabel', isWhitelabel);
              if (isWhitelabel) {
                setIsWhiteLabel(true);
              }

              this.setState({ isLoading: false });
            }
          })
          .catch(() => {});
      }
      const meetingKey = _.get(params, 'meeting_attendee_key', null);
      if (meetingKey) {
        localStorage.setItem('meeting-attendee-key', meetingKey);
        const meetingId = _.get(history, 'location.state.from', null).split('/')[2];
        localStorage.setItem('meeting-id', meetingId);
        this.setState({ isLoading: false });
      }
    } else {
      this.setState({ isLoading: false });
    }

    const historyState = _.get(history, 'location.state', null);
    if (_.startsWith(_.get(historyState, 'from', null), '/community')) {
      setCommunityUrlInLocalStorage(historyState);
    }
  }

  handleSSOButtonClick() {
    const { loginSSO } = this.props;
    loginSSO()
      .then(response => {
        if (response.loginLink) {
          window.location.href = response.loginLink;
        }
      })
      .catch(response => {
        console.log('catch');
      });
  }

  render() {
    const { translations, history, location, ssoProvider } = this.props;
    const { isLoading } = this.state;
    const signupTranslations = _.get(translations, 'signup', null);
    if (isLoading) {
      return <div className="Loader" />;
    }

    return (
      <Scrollbars className="styleScroll">
        <div className="open-login__container--full">
          <button type="button" onClick={() => history.goBack()} className="navigation__back-to-button">
            {_.get(translations, 'backToPreviousPage', 'Back to previous page')}
          </button>
          <div className="open-login__title">{_.get(signupTranslations, 'title', 'Create an account')}</div>
          <div className="open-login__subtitle">
            {_.get(
              signupTranslations,
              'subtitle',
              'You are seconds away to join our amazing community, book your first office and explore new opportunities.'
            )}
          </div>
          <div className={classnames('open-login__container--small')}>
            <div className="sso">
              <span className={classnames('wrapper')}>
                {ssoProvider && ssoProvider === 'Azure' && (
                  <button type="button" className="login-form__third-party-button azure" onClick={() => this.handleSSOButtonClick()}>
                    <img src={AzureLogo} alt="azure logo" className="azure-login-icon" />
                    <p className="auth-button-text">Log in with Azure AD</p>
                  </button>
                )}
                {ssoProvider && ssoProvider === 'Google' && (
                  <button type="button" className="login-form__third-party-button google" onClick={() => this.handleSSOButtonClick()}>
                    <img src={GoogleLogo} alt="google logo" className="google-login-icon" />
                    <p className="auth-button-text">Log in with Google</p>
                  </button>
                )}
              </span>
            </div>
            <SignUpForm />
            <div className="open-login__account-message">
              <div className="open-login__info-message">
                {_.get(signupTranslations, 'footerMessage', 'Do you already have an user account?')}
              </div>
              <Link
                to={{
                  pathname: '/login',
                  fromUrl: _.get(location, 'fromUrl', location.pathname),
                }}
                className="open-login__action-message"
              >
                {_.get(signupTranslations, 'footerAction', 'Log in')}
              </Link>
            </div>
          </div>
        </div>
        <Footer translations={translations} />
      </Scrollbars>
    );
  }
}

export default withRouter(CustomSignup);
