import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
import AddBookingOptions from './addBookingOptions';
import AddBookingPayment from './addBookingPayment';
import PaymentOptions from "./paymentOptions";
import { setSelectedPaymentMethod, createHeroPaymentProfile, updateHeroPaymentProfile, fetchBookingValidate,
 onChangeSection, resetBooking, resetVoucherInfo, fetchPaymentMethods } from './../../actions/locationActions';
import './css/index.scss';

class AddBokingSidebar extends React.Component {

  onChangeSection = (bookingSection) => {
    this.props.onChangeSection(bookingSection);
    if(bookingSection === 'payment' && this.props.selectedPaymentMethod === null)
    {
      this.props.fetchPaymentMethods();
    }
    if (bookingSection === 'options') {
        this.props.resetVoucherInfo();
        this.props.onVoucherDelete();
    }
  }

  componentDidUpdate(prevProps) {
      const voucherInfo = this.props.voucherInfo;
      if (voucherInfo) {
          const voucherInfoJS = voucherInfo.toJS();
          const voucherBalance = _.get(voucherInfoJS, 'balance', null);
          if (voucherBalance) {
              const voucherValue = _.get(voucherInfoJS, 'discount.value', 0);
              this.showModal(voucherBalance - voucherValue);
          }
      }

      const { selectedPaymentMethod : oldSelected } = prevProps;
      const { selectedPaymentMethod } = this.props;
      if (_.get(oldSelected, 'type', null) !== _.get(selectedPaymentMethod, 'type', null)) {
          this._fetchBookingValidate();
      }
  }

  componentWillUnmount() {
    const { history } = this.props;
    if (history.location.pathname.split('/')[2] !== 'payment' && history.location.pathname.split('/')[3] !== 'incomplete') {
      this.resetBooking();
    }
  }

  resetBooking() {
    this.props.resetBooking();
    this.props.onChangeSection('options');
  }

  showModal(voucherBalance) {
      this.props.showVoucherBalance(voucherBalance);
  }

    _fetchBookingValidate() {
        if (this.props.selectedRoom !== null && this.props.selectedRoom.type === this.props.selectedProduct.type) {
            let services = [];
            let equipments = [];
            let voucherCode = null;

            if (this.props.voucherInfo !== null && this.props.voucherInfo.toJS().valid === true) {
                voucherCode = this.props.voucherInfo.toJS().code;
            }

            if (this.props.selectedServices.length > 0) {
                this.props.selectedServices.map((selectedService) => {
                    if (selectedService.includes('service')) {
                        let [serviceId] = selectedService.split('service');
                        services.push(parseInt(serviceId, 10));
                    }
                    if (selectedService.includes('equipment')) {
                        let [equipmentId] = selectedService.split('equipment');
                        equipments.push(parseInt(equipmentId, 10));
                    } return null;
                });
            }
            const useSpendingLimit = _.get(this.props, 'selectedRoom.id', null) === 4 ? false : this.props.useSpendingLimit;

            let args = {
                officeId: this.props.officeLocation.id,
                officeTypeId: this.props.selectedRoom !== null ? this.props.selectedRoom.id : null,
                productId: this.props.selectedRoom !== null ? this.props.selectedProduct.id : null,
                fromDate: this.props.selectedOptions.startDate,
                toDate: this.props.selectedOptions.endDate,
                weekDays: this.props.selectedOptions.selectedWeekDays,
                hoursFrom: this.props.selectedOptions.hoursFrom,
                hoursUntil: this.props.selectedOptions.hoursUntil,
                servicesIds: services,
                equipmentIds: equipments,
                voucherCode: voucherCode,
                paymentProfileId: _.get(this.props, 'selectedPaymentMethod.payment_profile_id', null),
                useSpendingLimit: useSpendingLimit,
            };
            this.props.fetchBookingValidate(args);
        }
    }
  
  render () {
    const {officeLocation, bookingSection, history} = this.props;

    return (
      <div className="booking-sidebar">
        {bookingSection === 'options' &&
          <AddBookingOptions
            officeLocation={officeLocation}
            bookingRequest={this.props.bookingRequest}
            heroPopupErrorOpened={this.props.heroPopupErrorOpened}
            openHeroSidePopup={this.props.openHeroSidePopup}
            toggleDateExpanded={this.props.toggleDateExpanded}
            datePickerExpanded={this.props.datePickerExpanded}
            closeHeroSidePopup={this.props.closeHeroSidePopup}
            bookingRequestResponse={this.props.bookingRequestResponse}
            bookingRequestResponseErrors={this.props.bookingRequestResponseErrors}
            onContinue={() => this.onChangeSection('payment')}
            fetchBookingValidate={this.props.fetchBookingValidate}
            bookingValidate={this.props.bookingValidate}
            redeemVoucher={this.props.redeemVoucher}
            clearVoucher={this.props.clearVoucher}
            selectProduct={this.props.selectProduct}
            _fetchBookinOptions={this.props._fetchBookinOptions}
            trans={this.props.trans}
            fetchLoading={this.props.fetchLoading}
          />
        }
        {bookingSection === 'payment' &&
          <AddBookingPayment
            {...this.props}
            bookingRequest={this.props.bookingRequest}
            bookingRequestResponse={this.props.bookingRequestResponse}
            bookingRequestResponseErrors={this.props.bookingRequestResponseErrors}
            sidePopupOpened={this.props.sidePopupOpened}
            openSidePopup={this.props.openSidePopup}
            closeSidePopup={this.props.closeSidePopup}
            bookingValidate={this.props.bookingValidate}
            bookingErrors={this.props.bookingErrors}
            fetchBookingValidate={this.props.fetchBookingValidate}
            paymentMethods={this.props.paymentMethods}
            selectedPaymentMethod={this.props.selectedPaymentMethod}
            voucherInfo={this.props.voucherInfo}
            paymentProfileErrors={this.props.paymentProfileErrors}
            createHeroPaymentProfile={this.props.createHeroPaymentProfile}
            updateHeroPaymentProfile={this.props.updateHeroPaymentProfile}
            redeemVoucher={this.props.redeemVoucher}
            clearVoucher={this.props.clearVoucher}
            onVoucherDelete={this.props.onVoucherDelete}
            officeLocation={officeLocation}
            fetchPaymentMethods={this.props.fetchPaymentMethods}
            onBack={(data) => this.onChangeSection('options')}
            onFinish={() => this.onChangeSection('confirmation')}
            onFinishBooking={this.props.onFinishBooking}
            bookingResponse={this.props.bookingResponse}
            paymentConfirmationRequiresAction={this.props.paymentConfirmationRequiresAction}
            onModifyMethod={() => this.onChangeSection('paymentOptions')}
            trans={this.props.trans}
          />
        }
        {bookingSection === 'paymentOptions' &&
        <PaymentOptions
          onBack={() => this.onChangeSection('payment')}
          paymentMethods={this.props.paymentMethods}
          heroProfile={this.props.heroProfile}
          setSelectedPaymentMethod={this.props.setSelectedPaymentMethod}
          selectedPaymentMethod={this.props.selectedPaymentMethod}
          trans={_.get(this.props.trans, 'booking.payment', {})}
          formatString={this.props.formatString}
          fetchBookingValidate={this.props.fetchBookingValidate}
        />
        }
      </div>
    );
  }
}

AddBokingSidebar.propTypes = {
    officeLocation: PropTypes.object.isRequired
};

const mapStateToProps = state => {
    return {
        bookingErrors: state.addBooking.get('bookingErrors'),
        bookingResponse: state.addBooking.get('bookingResponse'),
        paymentConfirmationRequiresAction: state.addBooking.get('paymentConfirmationRequiresAction'),
        bookingSection: state.addBooking.get('bookingSection'),
        bookingValidate: state.addBooking.get('bookingValidate'),
        paymentProfileErrors: state.addBooking.get('paymentProfileErrors'),
        paymentMethods: state.addBooking.get('paymentMethods'),
        selectedPaymentMethod: state.addBooking.get('selectedPaymentMethod'),
        voucherInfo: state.addBooking.get('voucherInfo'),
        externalPaymentIntent: state.addBooking.get('externalPaymentIntent'),
        heroProfile: state.global.get('heroProfile'),
        formatString: state.translations ? state.translations.formatString : () => {},
        selectedRoom: state.addBooking.get('selectedRoom'),
        selectedProduct: state.addBooking.get('selectedProduct'),
        selectedOptions: state.addBooking.get('selectedOptions'),
        selectedServices: state.addBooking.get('selectedServices'),
        useSpendingLimit: state.addBooking.get('useSpendingLimit'),
        fetchLoading: state.demo.get('fetchAvailabilityProgress'),
    };
};

const mapDispatchProps = dispatch => {
    return {
        fetchPaymentMethods: () => {
          dispatch(fetchPaymentMethods());
        },
        createHeroPaymentProfile: (data) => {
          dispatch(createHeroPaymentProfile(data));
        },
        updateHeroPaymentProfile: (data) => {
          dispatch(updateHeroPaymentProfile(data));
        },
        fetchBookingValidate: (args) => {
            dispatch(fetchBookingValidate(args));
        },
        onChangeSection: (bookingSection) => {
            dispatch(onChangeSection(bookingSection));
        },
        resetBooking: () => {
            dispatch(resetBooking());
        },
        resetVoucherInfo: () => {
            dispatch(resetVoucherInfo());
        },
        setSelectedPaymentMethod: (paymentMethod) => {
          dispatch(setSelectedPaymentMethod(paymentMethod));
        },
    }
};
const ConnectAddBookingSideBar = connect(mapStateToProps, mapDispatchProps)(AddBokingSidebar);

export default withRouter(ConnectAddBookingSideBar);
