import { connect } from 'react-redux';

import Booking from '../../components/booking/booking';

import { fetchOfficeDetails, beforeFetchOfficeDetails } from '../../actions/locationActions';
import { fetchBookingDetails, beforeFetchBookingDetails, cancelBooking, fetchBookingSummary } from '../../actions/bookingActions';

const mapStateToProps = state => {
  return {
    details: state.booking.get('details'),
    officeDetails: state.location.get('details'),
    cancelBookingData: state.booking.get('cancelBookingData'),
    translations: state.translations,
    isWhiteLabel: state.global.get('isWhiteLabel'),
  };
};

const mapDispatchProps = dispatch => {
  return {
    beforeFetchBookingDetails: () => {
      dispatch(beforeFetchBookingDetails());
    },
    fetchBookingDetails: id => {
      dispatch(fetchBookingDetails(id));
    },
    beforeFetchOfficeDetails: id => {
      dispatch(beforeFetchOfficeDetails(id));
    },
    fetchOfficeDetails: id => {
      dispatch(fetchOfficeDetails(id));
    },
    cancelBooking: id => {
      dispatch(cancelBooking(id));
    },
    fetchBookingSummary: () => {
      dispatch(fetchBookingSummary());
    },
  };
};

const BookingView = connect(mapStateToProps, mapDispatchProps)(Booking);

export default BookingView;
