/**
 * Renders the details inside a Info Window for the current selected marker
 * @class MarkerInfo
 * @module MapContainer
 */

import React from 'react';
import PropTypes from 'prop-types';
import LocationMarker from './markers/locationMarker';
import ConnectionMarker from './markers/connectionMarker';

class MarkerInfo extends React.Component {

  /**
   * Handles click on the
   * @param {SyntheticEvent} event
   */
  onCloseClick = (event) => {
    this.props.onMarkerClose();
  };

  /**
   * Handles book location click
   * @method onBookItem
   * @private
   */
  onBookItem = () => {
    this.props.onBookItem(this.props.markerID);
  };

  _getMarkerInfo () {
    if (this.props.marker.get('type') === 'connection') {
      return (
        <ConnectionMarker
          onCloseClick={ this.onCloseClick }
          connection={ this.props.marker }
          onBookItem={ this.onBookItem }
          showMessageModal={ this.props.showMessageModal } />
      );
    }

    return (
      <LocationMarker
        id={ this.props.marker.get('id') }
        onCloseClick={ this.onCloseClick }
        markerInfo={ this.props.marker }
        geoLocationCoords={this.props.geoLocationCoords}
        officeTypesData={this.props.officeTypesData}
        onBookItem={ this.onBookItem }
        trans={this.props.trans}
      />
    );
  }

  render () {
    return this._getMarkerInfo();
  }

}

MarkerInfo.propTypes = {
  /**
   * Info data for the currently selected mark
   * @propery markerInfo
   * @type {Immutable.Map}
   * @required
   */
  marker: PropTypes.object.isRequired,

  /**
   * Callback function to handle infoWindow close action
   * @property onMarkerClose
   * @type {function}
   */
  onMarkerClose: PropTypes.func,

  /**
   * Contains the unique ID of the currently rendered infoWindow
   * @property markerID
   * @type {string}
   * @required
   */
  markerID: PropTypes.number.isRequired,

  /**
   * Handles book icon click
   * @property onBookItem
   * @type {function}
   * @required
   */
  onBookItem: PropTypes.func.isRequired,

  /**
   * Provides message modal action where context is not present
   * @property showMessageModal
   * @type {function}
   */
  showMessageModal: PropTypes.func
};

export default MarkerInfo;
