import { connect } from 'react-redux';
import Accordion from './Accordion';

const mapStateToProps = state => ({
  translations: state.translations,
  heroProfile: state.global.get('heroProfile'),
  isLoadingBookingId: state.meetingAttendees.get('isLoadingBookingId'),
});

const mapDispatchProps = dispatch => ({
});

export default connect(
  mapStateToProps,
  mapDispatchProps
)(Accordion);
