import api from '../../components/dashboard/utils/api';
import parseMediaArticle from '../../components/dashboard/utils/parse-media-article';
import { setSimilarMediaIsLoading } from './similar-media';
import { setMediaCompanyIsLoading } from './media-company-profile';

/*
 * Constants
 */
export const SET_MEDIA_ARTICLE = 'SET_MEDIA_ARTICLE';
export const SET_MEDIA_ARTICLE_IS_LOADING = 'SET_MEDIA_ARTICLE_IS_LOADING';

/*
 * Sync Actions
 */
export const setMediaArticle = mediaArticle => ({
  type: SET_MEDIA_ARTICLE,
  mediaArticle,
});

export const setMediaArticleIsLoading = isLoading => ({
  type: SET_MEDIA_ARTICLE_IS_LOADING,
  isLoading,
});

/*
 * Async Actions
 */
export const fetchMediaArticle = id => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(setMediaArticleIsLoading(true));
    dispatch(setSimilarMediaIsLoading(true));
    dispatch(setMediaCompanyIsLoading(true));
    api
      .get(`/media/${id}`)
      .then(({ data }) => {
        if (data.data) {
          dispatch(setMediaArticle(parseMediaArticle(data.data)));
        }
        return resolve(data.data);
      })
      .catch(() => {
          window.location.href ="/login";
      })
      .finally(() => {
        dispatch(setMediaArticleIsLoading(false));
        dispatch(setSimilarMediaIsLoading(false));
        dispatch(setMediaCompanyIsLoading(false));
      });
  });
