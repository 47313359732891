import Immutable from 'immutable';

import { SHOW_MESSAGE_MODAL, SHOW_SHARE_MODAL, CLOSE_MODAL } from './../actions/modals';

const defaultState = Immutable.fromJS({
  messageModal: {
    userId: null,
    userName: '',
    visible: false
  },
  shareModal: {
    currentUser: Immutable.Map(),
    post: Immutable.Map(),
    visible: false
  }
});

const modals = (state = defaultState, action) => {
  let newState;

  switch (action.type) {
    case SHOW_MESSAGE_MODAL:
      newState = state.set('messageModal', Immutable.fromJS({
        userId: action.userData.id,
        userName: action.userData.firstName,
        visible: true
      }));
      break;

    case SHOW_SHARE_MODAL:
      newState = state.set('shareModal', Immutable.fromJS({
        currentUser: action.currentUser,
        post: action.post,
        visible: true
      }));
      break;

    case CLOSE_MODAL:
      newState = state.setIn([action.modalType, 'visible'], false);
      break;

    default:
      newState = state;
  }

  return newState;
};

export default modals;