import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import backIcon from '../../../assets/svg/back.svg';
import PaymentAccountType from '../payment-account-type';
import PaymentMethods from '../payment-methods';

class PaymentDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accountTypeActive: true,
      paymentMethodsActive: false,
      accountData: '',
    };
    this.proceedToPaymentMethods = this.proceedToPaymentMethods.bind(this);
    this.backToPaymentDetails = this.backToPaymentDetails.bind(this);
    this.redirectToBooking = this.redirectToBooking.bind(this);
  }

  proceedToPaymentMethods(accountData) {
    this.setState({
      paymentMethodsActive: true,
      accountTypeActive: false,
      accountData,
    });
  }

  backToPaymentDetails(accountData) {
    this.setState({
      paymentMethodsActive: false,
      accountTypeActive: true,
      accountData,
    });
  }

  redirectToBooking() {
    const redirectTo = this.props.location.state ? this.props.location.state.from : '/';
    this.props.openBookingOptions();
    this.props.onChangeSection('payment');
    this.props.history.push(redirectTo);

    return true;
  }

  render() {
    const { accountTypeActive, paymentMethodsActive, accountData } = this.state;
    const { translations } = this.props;

    return (
      <div className="booking-payment-container">
        <div
          className="booking-payment_back"
          onClick={() => {
            if (paymentMethodsActive) {
              this.backToPaymentDetails(accountData);
              return;
            }
            this.props.handleContinueClick();
            this.redirectToBooking();
          }}
        >
          <img src={backIcon} alt="back" />
          {_.get(translations, 'backToPreviousPage', 'Back to previous page')}
        </div>
        <div className="booking-payment_title">{_.get(translations, 'paymentDetails', 'Payment details')}</div>
        <div className="booking-payment_subtitle">
          {_.get(translations, 'completeToFinish', 'Please complete your payment details to finish booking your location')}
        </div>
        {accountTypeActive && <PaymentAccountType accountData={accountData} proceedToPaymentMethods={this.proceedToPaymentMethods} />}
        {paymentMethodsActive && <PaymentMethods accountData={accountData} handleContinueClick={this.props.handleContinueClick} />}
      </div>
    );
  }
}

export default withRouter(PaymentDetails);
