import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import ForgotPasswordForm from './forgotPasswordForm';
import Footer from './Footer';

class OpenForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSent: false,
      mainEmail: '',
    };
    this.handleEmailChange = this.handleEmailChange.bind(this);
  }

  handleEmailChange(email) {
    this.setState({ isSent: true, mainEmail: email });
  }

  render() {
    const { translations, history } = this.props;
    const { isSent, mainEmail } = this.state;
    const forgotPasswordTranslations = _.get(translations, 'forgotPassword', null);
    return !isSent ? (
      <Scrollbars className="styleScroll">
        <div className="open-login__container--full">
          <button type="button" onClick={() => history.goBack()} className="navigation__back-to-button">
            {_.get(translations, 'backToPreviousPage', 'Back to previous page')}
          </button>
          <div className="open-login__title">{_.get(forgotPasswordTranslations, 'title', 'Have you forgotten your password?')}</div>
          <div className="open-login__subtitle">
            {_.get(forgotPasswordTranslations, 'subtitle', 'Please enter your e-mail in the field below and follow the instructions.')}
          </div>
          <div className="open-login__container--small">
            <ForgotPasswordForm handleEmailChange={this.handleEmailChange} />
          </div>
        </div>
        <Footer translations={translations} />
      </Scrollbars>
    ) : (
      <Scrollbars className="styleScroll">
        <div className="open-login__container--full">
          <button type="button" onClick={() => history.replace('/login')} className="navigation__back-to-button">
            {_.get(translations, 'backToLogin', 'Back to login')}
          </button>
          <div className="reset-confirmation__container">
            <div className="open-login__title">
              {_.get(forgotPasswordTranslations, 'confirmationTitleFirst', 'We sent you an email to ')}
              <div className="open-login__title--yellow">{mainEmail}</div>
              {_.get(forgotPasswordTranslations, 'confirmationTitleLast', ' with password reset link.')}
            </div>
            <div className="open-login__subtitle">
              {_.get(forgotPasswordTranslations, 'confirmationSubtitle', 'Follow the steps and we will get that password for you.')}
            </div>
          </div>
        </div>
        <Footer translations={translations} />
      </Scrollbars>
    );
  }
}

export default withRouter(OpenForgotPassword);
