/**
 * Renders the contact information sidebar
 * @module DirectChat
 * @class AboutContact
 */
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';

import CONST from '../../../utils/const';

import { numberFormat } from '../../../utils/formatUtils';

const AboutContact = props => {
  const { actions, conversation, conversationHeroDetails } = props;

  const avatarStyle =
    conversation.getIn(['hero', 'profilePictureUrl']) !== null
      ? {
          backgroundSize: 'cover',
          backgroundImage: `url('${conversation.getIn(['hero', 'profilePictureUrl'])}')`,
        }
      : {};

  const heroInitials = conversation.getIn(['hero', 'heroInitials']);

  return (
    <div className="directChat-aboutContent-contentWrapper">
      {!conversation.isEmpty() ? (
        <div>
          <div className="directChat-aboutContent-pageTitle">
            {`${props.trans.chat.about} ${conversation.getIn(['hero', 'firstName'])}`}
          </div>
          <div className="directChat-aboutContent-connection">
            {conversation.getIn(['hero', 'profilePictureUrl']) ? (
              <div
                className={classnames('directChat-aboutContent-avatar', 'directChat-contacts-conversationImage', {
                  defaultAvatar: conversation.getIn(['hero', 'profilePictureUrl']) === null,
                })}
                style={avatarStyle}
              />
            ) : (
              <div className="hero-initials about-contact-hero-initials">{heroInitials}</div>
            )}

            <div className="directChat-aboutContent-userDetails">
              <Link to={`/connection/${conversation.getIn(['hero', 'id'])}`}>
                <div className="directChat-aboutContent-userName">
                  {conversation.getIn(['hero', 'firstName'])}
                  <span className="directChat-aboutContent-userLastName">{` ${conversation.getIn(['hero', 'lastName'])}`}</span>
                </div>
              </Link>
              <div className="directChat-aboutContent-jobTitle">{conversationHeroDetails.getIn(['position', 'title'])}</div>
            </div>
          </div>
          <div>
            <div className="directChat-aboutContent-connectionData" style={{ display: 'none' }}>
              <div className="directChat-aboutContent-connectionDataTitle">{`${conversation.getIn(['hero', 'firstName'])}'s Bookings`}</div>
              <div className="directChat-aboutContent-connectionDataInfo">
                <div className="directChat-aboutContent-connectionDataValue green">
                  {`${numberFormat(conversation.getIn(['hero', 'activeBookings'], 0))} active`}
                </div>
                <Link className="directChat-aboutContent-connectionDataLink" to="/booking">
                  View
                </Link>
              </div>
            </div>
          </div>
        </div>
      ) : (
        undefined
      )}
      <span
        style={{ display: 'none' }}
        className="directChat-aboutContent-slider icon icon-slider"
        onClick={() => {
          actions.openConversation(CONST.supportId);
        }}
      />
    </div>
  );
};

AboutContact.propTypes = {
  /**
   * Contains all available dispatched actions for this component
   * @type {Object}
   */
  actions: PropTypes.object.isRequired,
  /**
   * Contains latest conversation data for specified contact
   * @type {Immutable.Map<Conversation>}
   */
  conversation: PropTypes.object.isRequired,
};

export default AboutContact;
