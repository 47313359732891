import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import _ from 'lodash';
import PaymentAccountType from '../bookingPayment/payment-account-type';
import PaymentMethods from '../bookingPayment/payment-methods';
import PaymentProfileInfo from './paymentProfileInfo/PaymentProfileInfo';

class CreateFirstPaymentProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accountTypeActive: true,
      paymentMethodsActive: false,
      accountData: '',
    };
    this.proceedToPaymentMethods = this.proceedToPaymentMethods.bind(this);
    this.backToPaymentDetails = this.backToPaymentDetails.bind(this);
  }

  proceedToPaymentMethods(accountData) {
    this.setState({
      paymentMethodsActive: true,
      accountTypeActive: false,
      accountData,
    });
  }

  backToPaymentDetails(accountData) {
    this.setState({
      paymentMethodsActive: false,
      accountTypeActive: true,
      accountData,
    });
  }

  render() {
    const { translations, heroData } = this.props;
    const firstName = _.get(heroData, 'firstName', '');
    const lastName = _.get(heroData, 'lastName', '');

    const { accountTypeActive, paymentMethodsActive, accountData } = this.state;
    return (
      <Scrollbars>
        <div className="payment-container">
          <div className="payment-content">
            <div className="payment-content__left-area">
              <div className="booking-payment-container">
                {!paymentMethodsActive && (
                  <>
                    <div className="booking-payment_title">{_.get(translations, 'paymentSettings.createFirstProfile', '')}</div>
                    <div className="booking-payment_subtitle">{_.get(translations, 'paymentSettings.completeFirstProfile', '')}</div>
                  </>
                )}
                {accountTypeActive && (
                  <PaymentAccountType
                    accountData={accountData}
                    proceedToPaymentMethods={this.proceedToPaymentMethods}
                    firstPaymentProfile
                  />
                )}
                {paymentMethodsActive && <PaymentMethods accountData={accountData} firstPaymentProfile />}
              </div>
            </div>
            {paymentMethodsActive && (
              <div className="payment-content__right-area">
                <PaymentProfileInfo
                  translations={translations}
                  address={accountData.address}
                  city={accountData.city}
                  country={accountData.country}
                  lastName={lastName}
                  firstName={firstName}
                  companyName={_.get(accountData, 'company.name', null)}
                  postalCode={accountData.postal_code}
                  vatNumber={_.get(accountData, 'company.vatNumber', null)}
                />
              </div>
            )}
          </div>
        </div>
      </Scrollbars>
    );
  }
}
export default CreateFirstPaymentProfile;
