import React from 'react';
import Truncate from 'react-truncate';
import _ from 'lodash';
import PropTypes from 'prop-types';

const goTo = id => {
  window.location.href = `/dashboard/media/${id}`;
};

const buildTagList = tags => {
  if (!tags) {
    return null;
  }
  return tags.reduce((accumulator, currentValue, index) => accumulator + (index !== 0 ? ', ' : '') + _.get(currentValue, 'label', ''), '');
};

const JobAnnouncement = props => {
  const { job, translations } = props;
  const params = JSON.parse(job.params);
  const data = {};
  for (let i = 0; i < params.length; i += 1) {
    if (params[i].question && params[i].question.code) {
      data[params[i].question.code] = params[i].answer;
    }
  }
  const title = _.get(data, 'title', '');
  const tags = _.get(data, 'tags', '');
  const tagList = buildTagList(tags);

  return (
    <a href={`/dashboard/media/${job.id}`}>
      <div className="job-announcement__container">
        <div className="job__info">
          <div className="job__tags">{tagList}</div>
          <div className="job__title">
            <Truncate lines={3} ellipsis={<>... </>}>
              {title}
            </Truncate>
          </div>
        </div>
        <button
          type="button"
          className="dashboard-button dashboard-button--secondary dashboard-button__apply-job"
          onClick={() => goTo(job.id)}
        >
          {_.get(translations, 'apply', 'Apply')}
        </button>
      </div>
    </a>
  );
};

JobAnnouncement.propTypes = {
  job: PropTypes.shape({
    params: PropTypes.string,
  }),
};

JobAnnouncement.defaultProps = {
  job: {},
};

export default JobAnnouncement;
