import './index.scss';
import { connect } from 'react-redux';
import _ from 'lodash';
import HeroProfile from './HeroProfile';
import { translations } from '../../selectors/translations';

const mapStateToProps = state => ({
  translations: _.get(translations(state), 'profile', {}),
});

export default connect(mapStateToProps)(HeroProfile);
