import React, { Component } from 'react';
import StarRating from '../starRating/starRating';
import './css/modalDialogCenter.scss';

/**
 * @todo: Maybe this was supposed to be general since it is a pattern?
 */

class ModalDialogCenter extends Component {
  props;

  render () {
    return (
        <div className="location-review">
          <div className="location-review-header">
            <div className="location-image" style={{ backgroundImage: `url("${ this.props.image }")` }}></div>
            <h3 className="items-style1">{this.props.title} review</h3>
          </div>
          <p>Rating</p>
          <StarRating initialRate={this.props.rating} theme={'theme-yellow'} />
          <p>Share your experience</p>
          <textarea placeholder={'How was your experience at ' + this.props.title + '...'}></textarea>
          <div className="location-review-action">
            <span className="tertiary-button" onClick={this.props.onCancel}>CANCEL</span>
            <span className="secondary-button" onClick={this.props.onCancel}>SEND</span>
          </div>
        </div>
    );
  }
};

export default ModalDialogCenter;
