import _ from 'lodash';
import api from '../components/dashboard/utils/api';

export const SET_INVITED_HEROES = 'SET_INVITED_HEROES';
export const SET_IS_LOADING_INVITED_HEROES = 'SET_IS_LOADING_INVITED_HEROES';
export const SET_MEETINGS = 'SET_MEETINGS';
export const ADD_INVITED_HERO = 'ADD_INVITED_HERO';
export const DELETE_INVITED_HERO = 'DELETE_INVITED_HERO';
export const SET_MEETING_ATTENDEES_TOAST_MESSAGE = 'SET_MEETING_ATTENDEES_TOAST_MESSAGE';
export const CHANGE_MEETING_STATUS = 'CHANGE_MEETING_STATUS';
export const SET_CHANGE_MEETING_STATUS_IS_LOADING = 'SET_CHANGE_MEETING_STATUS_IS_LOADING';

/*
 * Sync Actions
 */

export const setInvitedHeoresToMeeting = invitedHeroes => ({
  type: SET_INVITED_HEROES,
  invitedHeroes,
});

export const setMeetings = meetings => ({
  type: SET_MEETINGS,
  meetings,
});

export const setIsLoading = isLoading => ({
  type: SET_IS_LOADING_INVITED_HEROES,
  isLoading,
});

export const addInvitedHeroToList = hero => ({
  type: ADD_INVITED_HERO,
  hero,
});
/**
 * remove from store only
 * @param hero
 * @returns {{hero: *, type: string}}
 */
export const deleteInvitedHero = attendeeId => ({
  type: DELETE_INVITED_HERO,
  attendeeId,
});
export const setChangeMeetingStatusIsLoading = isLoadingBookingId => ({
  type: SET_CHANGE_MEETING_STATUS_IS_LOADING,
  isLoadingBookingId,
});

export const setMeetingAttendeeToastMessage = meetingAttendeesToastMessage => ({
  type: SET_MEETING_ATTENDEES_TOAST_MESSAGE,
  meetingAttendeesToastMessage,
});

export const changeMeetingStatus = (attendeeId, meetingStatus, bookingId, officeId) => ({
  type: CHANGE_MEETING_STATUS,
  attendeeId,
  meetingStatus,
  bookingId,
  officeId,
});

/*
 * Async Actions
 */
export const showMeetingAttendeesToast = (meetingAttendeesToastMessage, timer = 3000) => dispatch => {
  dispatch(setMeetingAttendeeToastMessage(meetingAttendeesToastMessage));
  setTimeout(() => {
    dispatch(setMeetingAttendeeToastMessage(null));
  }, timer);
};

/**
 *  fetch Invitated heroes with statuses
 * @param bookingId
 * @returns {function(*=): Promise<unknown>}
 */
export const fetchHeroInvitations = bookingId => dispatch => {
  dispatch(setIsLoading(true));
  return new Promise((resolve, reject) => {
    api
      .get(`${window.__env.apiUrl}/hero/meetingAttendees/get/${bookingId}/attendees`)
      .then(response => {
        if (response.data) {
          dispatch(setInvitedHeoresToMeeting(response.data.meetingAttendees));
        }
        resolve();
      })
      .catch(({ response }) => {
        reject();
      })
      .finally(() => {
        dispatch(setIsLoading(false));
      });
  });
};
/**
 * send invitation to hero
 * @param data
 * @returns {Promise<unknown>}
 */
export const sendInviteToMeeting = data => dispatch => {
  return new Promise((resolve, reject) => {
    api
      .post(`${window.__env.apiUrl}/hero/meetingAttendees/store`, data)
      .then(() => {
        dispatch(setIsLoading(true));
        dispatch(fetchHeroInvitations(data.booking_id));
        dispatch(showMeetingAttendeesToast('meetingAttendees.invitation.sendSuccess'));
        resolve();
      })
      .catch(({ response }) => {
        const calendarSyncRequired = _.get(response, 'data.reason') === 'outlook' || _.get(response, 'data.reason') === 'google';
        const message = calendarSyncRequired ? 'meetingAttendees.invitation.sendFailedCalendar' : 'meetingAttendees.invitation.sendFailed';
        dispatch(showMeetingAttendeesToast(message));
        reject();
      })
      .finally(() => {
        dispatch(setIsLoading(false));
      });
  });
};
/**
 *  remove from db already invited hero -> hero did response to invitation
 * @param attendee
 * @returns {function(...[*]=)}
 */
export const deleteInvitedAttendee = attendeeId => dispatch => {
  return new Promise((resolve, reject) => {
    api
      .post(`${window.__env.apiUrl}/hero/meetingAttendees/delete`, { attendee_id: attendeeId })
      .then(response => {
        dispatch(deleteInvitedHero(attendeeId));
        dispatch(showMeetingAttendeesToast('meetingAttendees.invitation.deleteSuccess'));
      })
      .catch(({ response }) => {
        dispatch(showMeetingAttendeesToast('meetingAttendees.invitation.deleteFailed'));
        reject();
      })
      .finally(() => {
        dispatch(setIsLoading(false));
      });
  });
};
/**
 * Resend invitation to hero
 * @param attendeeId
 * @returns {function(*=): Promise<unknown>}
 */
export const resendInvitation = attendeeId => dispatch => {
  return new Promise((resolve, reject) => {
    api
      .post(`${window.__env.apiUrl}/hero/meetingAttendees/resend`, { attendee_id: attendeeId })
      .then(response => {
        dispatch(showMeetingAttendeesToast('meetingAttendees.invitation.sendSuccess'));
      })
      .catch(({ response }) => {
        dispatch(showMeetingAttendeesToast('meetingAttendees.invitation.sendFailed'));
        reject();
      })
      .finally(() => {
        dispatch(setIsLoading(false));
      });
  });
};

export const fetchMeetings = () => dispatch => {
  dispatch(setIsLoading(true));
  return new Promise((resolve, reject) => {
    api
      .get(`${window.__env.apiUrl}/hero/meetings`)
      .then(response => {
        if (response.data) {
          dispatch(setMeetings(response.data));
        }
        resolve();
      })
      .catch(({ response }) => {
        reject();
      })
      .finally(() => {
        dispatch(setIsLoading(false));
      });
  });
};

export const acceptMeetingInvitation = (attendeeId, newStatus, bookingId, officeId) => dispatch => {
  dispatch(setChangeMeetingStatusIsLoading(bookingId));
  return new Promise((resolve, reject) => {
    api
      .post(`${window.__env.apiUrl}/hero/meetingAttendees/accept`, { attendee_id: attendeeId })
      .then(() => {
        dispatch(changeMeetingStatus(attendeeId, newStatus, bookingId, officeId));
      })
      .catch(({ response }) => {
        reject();
      })
      .finally(() => {
        dispatch(setChangeMeetingStatusIsLoading(bookingId));
      });
  });
};

export const declineMeetingInvitation = (attendeeId, newStatus, bookingId, officeId) => dispatch => {
  dispatch(setChangeMeetingStatusIsLoading(bookingId));
  return new Promise((resolve, reject) => {
    api
      .post(`${window.__env.apiUrl}/hero/meetingAttendees/decline`, { attendee_id: attendeeId })
      .then(() => {
        dispatch(changeMeetingStatus(attendeeId, newStatus, bookingId, officeId));
      })
      .catch(({ response }) => {
        reject();
      })
      .finally(() => {
        dispatch(setChangeMeetingStatusIsLoading(bookingId));
      });
  });
};

export const updateRegisteredAttendee = ({ attendeeKey, meetingId }) => dispatch => {
  return new Promise((resolve, reject) => {
    api
      .post(`${window.__env.apiUrl}/hero/meetingAttendees/update`, { attendee_key: attendeeKey, meeting_id: meetingId })
      .then(() => {
        resolve();
      })
      .catch(() => {
        reject();
      })
      .finally(() => {
        localStorage.removeItem('meeting-attendee-key');
      });
  });
};
