import { connect } from 'react-redux';

import _ from 'lodash';
import toJS from '../../utils/to-js';
import { isLoadingSelector, formErrorsSelector } from '../../../../selectors/community/create-community';
import { saveCommunity, clearErrors } from '../../../../actions/community/create-community';

import CommunityForm from './CommunityForm';
import { showCommunityPageToast } from '../../../../actions/community/community-toasts';
import { translations } from '../../../../selectors/translations';

const mapDispatchToProps = dispatch => ({
  saveCommunity: data => dispatch(saveCommunity(data)),
  clearErrors: () => dispatch(clearErrors()),
  showCommunityPageToast: data => dispatch(showCommunityPageToast(data)),
});
const mapStateToProps = state => ({
  translations: _.get(translations(state), 'community', {}),
  isLoading: isLoadingSelector(state),
  errors: formErrorsSelector(state),
});
export default connect(mapStateToProps, mapDispatchToProps)(toJS(CommunityForm));
