/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const CommunityModal = ({ closeModal, title, description, body, wrapperClasses }) => {
  return (
    <div className={classnames('base-modal', `${wrapperClasses}`)}>
      <div className="base-modal__modal-overlay" />
      <div className="base-modal__modal-content">
        <div className="base-modal__left-area">
          <span className="icon icon-community base-modal__left-area-icon" />
        </div>
        <div className="base-modal__right-area">
          <div className="base-modal__modal-header">
            <div className="base-modal__modal-header-title">{title}</div>
            <span className="base-modal__close" onClick={closeModal}>
              &times;
            </span>
          </div>
          {description ? <div className="base-modal__modal-description">{description}</div> : null}
          <div className="base-modal__modal-body">{body}</div>
        </div>
      </div>
    </div>
  );
};

CommunityModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  body: PropTypes.object.isRequired,
  wrapperClasses: PropTypes.string,
};

CommunityModal.defaultProps = {
  description: '',
  wrapperClasses: '',
};

export default CommunityModal;
