import { connect } from 'react-redux';
import Search from './search';
import { buildingHeroesSelector } from '../../../../selectors/whiteLabel/filter';

const mapStateToProps = state => {
  return {
    translations: state.translations,
    isAuthenticated: state.auth.get('isAuthenticated'),
    buildingHeroes: buildingHeroesSelector(state),
  };
};

const mapDispatchProps = dispatch => {
  return {};
};
export default connect(mapStateToProps, mapDispatchProps)(Search);
