import Immutable from 'immutable';
import { SET_STAFF_MEMBERS, SET_STAFF_MEMBERS_LOADING } from '../../actions/whiteLabel/staff';

const initialState = Immutable.Map({
  list: [],
  isLoading: false,
});

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_STAFF_MEMBERS:
      return state.setIn(['list'], action.members);
    case SET_STAFF_MEMBERS_LOADING:
      return state.setIn(['isLoading'], action.isLoading);
    default:
      return state;
  }
};
