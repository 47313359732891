import React from 'react';
import PageHeader from '../pageHeader/pageHeader';
import NotificationsGeneralSettings from '../notificationsGeneralSettings/notificationsGeneralSettings';
import AccountGeneralSettingsView from '../../views/accountGeneralSettingsView/accountGeneralSettingsView';
import TipsAndTricks from '../tipsAndTricks/tipsAndTricks';

import './css/index.scss';

import _ from 'lodash';

class GeneralSettings extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: 'notifications',
      tabs: [
        'notifications',
        'accountSettings',
        // 'blockedPeople'
      ],
    };
    this.buildTab = this.buildTab.bind(this);
    this.buildComponent = this.buildComponent.bind(this);
    this.handleEmailCheckboxClick = this.handleEmailCheckboxClick.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    this.props.fetchProfile();
    this.props.fetchPermissions();
  }

  handleClick(tab) {
    this.setState({ activeTab: tab });
  }

  buildTab(tab) {
    return (
      <div
        key={tab}
        onClick={this.handleClick.bind(null, tab)}
        className={`general-settings__tab-title ${this.state.activeTab === tab ? 'active' : ''}`}
      >
        {_.get(this.props, `translations.generalSettings.${tab}`)}
      </div>
    );
  }

  handleEmailCheckboxClick(item) {
    this.props.savePermission(item);
  }

  buildComponent() {
    switch (this.state.activeTab) {
      case 'notifications':
        let permissions = [];
        if (this.props.permissions && typeof this.props.permissions.toJS === 'function') {
          permissions = this.props.permissions.toJS();
        }
        return (
          <NotificationsGeneralSettings
            translations={this.props.translations}
            permissions={permissions}
            handleEmailCheckboxClick={this.handleEmailCheckboxClick}
          />
        );
      case 'accountSettings':
        return <AccountGeneralSettingsView />;
      case 'blockedPeople':
        return null;
        break;
      default:
        return null;
        break;
    }
  }

  render() {
    if (!this.props.translations || !this.props.permissions) {
      return <div className="officeDetailsLoader" />;
    }
    return (
      <div className="general-settings">
        <div className="general-settings__wrapper">
          <section className="general-settings__content">
            <PageHeader
              className="--defaultPadding"
              pageTitle={_.get(this.props, 'translations.generalSettings.title', 'General Settings')}
              icon="general-settings"
              actions={[]}
            />
            {this.state.tabs.map(this.buildTab)}
            {this.buildComponent()}
          </section>
          <TipsAndTricks translations={this.props.translations} />
        </div>
      </div>
    );
  }
}

export default GeneralSettings;
