import api from '../../components/community/utils/api';
/*
 * Constants
 */
export const SET_DELETE_COMMUNITY_FORM_IS_LOADING = 'SET_DELETE_COMMUNITY_FORM_IS_LOADING';
export const SET_DELETE_COMMUNITY_FORM_ERRORS = 'SET_DELETE_COMMUNITY_FORM_ERRORS';
/*
 * Sync Actions
 */

export const setDeleteCommunityFormIsLoading = isLoading => ({
  type: SET_DELETE_COMMUNITY_FORM_IS_LOADING,
  isLoading,
});

export const setFormError = errors => ({
  type: SET_DELETE_COMMUNITY_FORM_ERRORS,
  errors,
});

/*
 * Async Actions
 */

export const deleteCommunity = communityId => dispatch => {
  dispatch(setDeleteCommunityFormIsLoading(true));

  return new Promise(resolve => {
    api
      .delete(`/delete/${communityId}`)
      .then(() => {
        dispatch(setDeleteCommunityFormIsLoading(false));
        resolve();
      })
      .catch(({ response }) => {
        if (response.data.error) {
          dispatch(setFormError(response.data.error));
          dispatch(setDeleteCommunityFormIsLoading(false));
        }
      });
  });
};
