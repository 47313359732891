import React, { Component } from 'react';
import './css/modalDialogCenter.scss';

/**
 * @todo: Maybe this was supposed to be general since it is a pattern?
 */

class ModalDialogCenterConfirmation extends Component {
  props;

  render () {

    let proceedText =  (this.props.proceedText !== undefined && this.props.proceedText !== '') ? this.props.proceedText : this.props.trans.modals.businessAccount.proceed;
        
    let cancelButton = (this.props.showCancel !== undefined && this.props.showCancel === false) ? null :
        <span className="tertiary-button" onClick={this.props.onCancel}>
            {this.props.cancelText ? this.props.cancelText : this.props.trans.modals.businessAccount.cancel}
        </span>;

    let okButton = (this.props.showOk !== undefined && this.props.showOk === false) ? null : <span className="secondary-button" onClick={this.props.onProceed}>{proceedText}</span>;

    return (
        <div className="dialog-center-confirmation">
          <div className="dialog-center-confirmation-header">
            <h3>{this.props.title}</h3>
          </div>
          <div className="dialog-center-confirmation-body">{this.props.body}</div>
          <div className="dialog-center-confirmation-action">
            {cancelButton}
            {okButton}
          </div>
        </div>
    );
  }
};

export default ModalDialogCenterConfirmation;
