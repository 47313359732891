import _ from 'lodash';
import api from '../../components/community/utils/api';
import { communitySelector } from '../../selectors/community/community-page';
import {
  activeMembersSelector,
  adminsSelector,
  blockedMembersSelector,
  communityInvitationsSelector,
} from '../../selectors/community/all-members';
/*
 * Constants
 */
export const SET_COMMUNITY_ADMINS = 'SET_COMMUNITY_ADMINS';
export const SET_COMMUNITY_ACTIVE_MEMBERS = 'SET_COMMUNITY_ACTIVE_MEMBERS';
export const SET_COMMUNITY_BLOCKED_MEMBERS = 'SET_COMMUNITY_BLOCKED_MEMBERS';
export const SET_COMMUNITY_INVITATIONS = 'SET_COMMUNITY_INVITATIONS';
export const SET_ALL_MEMBERS_IS_LOADING = 'SET_ALL_MEMBERS_IS_LOADING';

/*
 * Sync Actions
 */
export const setAdmins = members => ({
  type: SET_COMMUNITY_ADMINS,
  members,
});
export const setActiveMembers = members => ({
  type: SET_COMMUNITY_ACTIVE_MEMBERS,
  members,
});
export const setBlockedMembers = members => ({
  type: SET_COMMUNITY_BLOCKED_MEMBERS,
  members,
});

export const setCommunityInvitations = communityInvitations => ({
  type: SET_COMMUNITY_INVITATIONS,
  communityInvitations,
});

export const setAllMembersIsLoading = isLoading => ({
  type: SET_ALL_MEMBERS_IS_LOADING,
  isLoading,
});

/*
 * Async Actions
 */
export const fetchCommunityMembers = () => (dispatch, getState) => {
  const community = communitySelector(getState());
  dispatch(setAllMembersIsLoading(true));
  return new Promise(resolve => {
    api
      .get(`members/communityId/${community.id}`)
      .then(response => {
        const allMembers = response.data.members;
        const admins = allMembers.filter(member => {
          return _.get(member, 'admin', 0) === 1;
        });
        const activeMembers = allMembers.filter(member => {
          return _.get(member, 'admin', 0) !== 1 && _.get(member, 'blocked', 0) !== 1;
        });
        const blockedMembers = allMembers.filter(member => {
          return _.get(member, 'blocked', 0) === 1;
        });
        dispatch(setAdmins(admins));
        dispatch(setActiveMembers(activeMembers));
        dispatch(setBlockedMembers(blockedMembers));
      })
      .catch(() => {})
      .finally(() => {
        dispatch(setAllMembersIsLoading(false));
      });
  });
};

export const fetchCommunityInvitations = () => (dispatch, getState) => {
  const community = communitySelector(getState());
  return new Promise(resolve => {
    api
      .get(`invitations/communityId/${community.id}`)
      .then(response => {
        dispatch(setCommunityInvitations(response.data.invitations));
      })
      .catch(() => {})
      .finally(() => {});
  });
};

export const makeHeroAdmin = heroId => (dispatch, getState) => {
  const community = communitySelector(getState());
  const communityId = _.get(community, 'id', null);
  const admins = adminsSelector(getState());
  const activeMembers = activeMembersSelector(getState());

  const hero = activeMembers.filter(member => member.id === heroId);
  _.set(hero, '0.admin', 1);
  const newActiveMembers = activeMembers.filter(member => member.id !== heroId);
  const newAdmins = admins.concat(hero);
  return new Promise(resolve => {
    api
      .post(`members/makeAdmin`, { communityId, heroId })
      .then(response => {
        dispatch(setAdmins(newAdmins));
        dispatch(setActiveMembers(newActiveMembers));
      })
      .catch(() => {})
      .finally(() => {});
  });
};

export const removeHeroFromCommunity = heroId => (dispatch, getState) => {
  const community = communitySelector(getState());
  const communityId = _.get(community, 'id', null);
  const activeMembers = activeMembersSelector(getState());
  const newActiveMembers = activeMembers.filter(member => member.id !== heroId);
  return new Promise(resolve => {
    api
      .post(`members/remove`, { communityId, heroId })
      .then(response => {
        dispatch(setActiveMembers(newActiveMembers));
      })
      .catch(() => {})
      .finally(() => {});
  });
};

export const blockHeroFromCommunity = heroId => (dispatch, getState) => {
  const community = communitySelector(getState());
  const communityId = _.get(community, 'id', null);
  const activeMembers = activeMembersSelector(getState());
  const blockedMembers = blockedMembersSelector(getState());
  const hero = activeMembers.filter(member => member.id === heroId);
  _.set(hero, '0.blocked', 1);
  const newActiveMembers = activeMembers.filter(member => member.id !== heroId);
  const newBlockedMembers = blockedMembers.concat(hero);
  return new Promise(resolve => {
    api
      .post(`members/block`, { communityId, heroId })
      .then(response => {
        dispatch(setActiveMembers(newActiveMembers));
        dispatch(setBlockedMembers(newBlockedMembers));
      })
      .catch(() => {})
      .finally(() => {});
  });
};

export const unblockMember = heroId => (dispatch, getState) => {
  const community = communitySelector(getState());
  const communityId = _.get(community, 'id', null);
  const activeMembers = activeMembersSelector(getState());
  const blockedMembers = blockedMembersSelector(getState());
  const hero = blockedMembers.filter(member => member.id === heroId);
  _.set(hero, '0.blocked', 0);
  const newActiveMembers = activeMembers.concat(hero);
  const newBlockedMembers = blockedMembers.filter(member => member.id !== heroId);
  return new Promise(resolve => {
    api
      .post(`members/unblock`, { communityId, heroId })
      .then(response => {
        dispatch(setActiveMembers(newActiveMembers));
        dispatch(setBlockedMembers(newBlockedMembers));
      })
      .catch(() => {})
      .finally(() => {});
  });
};

export const resendInvitation = invitationId => (dispatch, getState) => {
  const invitations = communityInvitationsSelector(getState()).map(member => (member.invitationId === invitationId ? { ...member, sending: 1 } : member));
  dispatch(setCommunityInvitations(invitations));
  const newInvitations = invitations.map(member => (member.invitationId === invitationId ? { ...member, sent: 1 } : member));

  return new Promise(resolve => {
    api
      .post(`invitations/resend`, { invitationId })
      .then(response => {
        dispatch(setCommunityInvitations(newInvitations));
      })
      .catch(() => {
        dispatch(setCommunityInvitations(invitations.map(member => (member.invitationId === invitationId ? { ...member, sending: 0 } : member))));
      })
      .finally(() => {});
  });
};

export const cancelInvitation = invitationId => (dispatch, getState) => {
  const invitations = communityInvitationsSelector(getState());
  const newInvitations = invitations.filter(member => member.invitationId !== invitationId);
  return new Promise(resolve => {
    api
      .post(`invitations/cancel`, { invitationId })
      .then(response => {
        dispatch(setCommunityInvitations(newInvitations));
      })
      .catch(() => {})
      .finally(() => {});
  });
};
