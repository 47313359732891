import React from 'react';
import {Scrollbars} from 'react-custom-scrollbars';
import PropTypes from 'prop-types';
import _ from 'lodash';

import Sidebar from '../rightSidebar/rightSidebar';
import ProfileBox from './../profileBox/profileBox';
import ExperienceBox from './../experienceBox/experienceBox';
import ModalDialog from './../modalDialog/modalDialog';
import InvitationSent from './../modalDialog/modals/invitationSent';

import './css/index.scss';
import moment from "moment/moment";

class Connection extends React.Component {
    props;
    context;
    
    static contextTypes = {
        router: PropTypes.object.isRequired
    };

    constructor() {
        super();
        this.state = {
            invitationSent: false,
            rendering: true,
        }
    }

    UNSAFE_componentWillMount() {
        this.setState({
            rendering: true
        });
    }

    componentDidMount() {
        this.props.beforeFetchConnection();
        this.props.fetchConnection(this.props.match.params.id);
        this.setState({rendering: false});
        this.props.fetchProfile();
    }

    componentWillUnmount() {
        this.props.fetchChatConnections();
        this.props.fetchDirectChatConversations();
    }

    UNSAFE_componentWillReceiveProps() {
        let {connection} = this.props;
        let {heroProfile} = this.props;
        if(heroProfile !== null && connection !== null) {
            if(heroProfile.id === connection.get('id')) {
                window.location = `/account/profile`;
            }
        }
    }

    _getProfileBox() {
        const {connection} = this.props;
        if (connection && connection.get('id')) {
            return (
                <ProfileBox
                    id={connection.get('id')}
                    noLink={true}
                    className={'--wide'}
                    grade={connection.get('grade')}
                    firstName={connection.get('firstName')}
                    distance={connection.get('distance')}
                    headline={ connection.get('headline')}
                    lastName={connection.get('lastName')}
                    options={{distance: true, location: true}}
                    avatar={connection.get('profilePictureUrl')}
                    jobName={connection.getIn(['positions', 0, 'title'])}
                    currentLocation={connection.getIn(['positions', 0, 'company'])}
                    matchValue={this.props.heroProfile !== null && connection.get('id') !== this.props.heroProfile.id ? connection.get('matchingPercentage') : null}
                    totalScore={this.props.heroProfile !== null && connection.get('id') !== this.props.heroProfile.id ? connection.get('totalScore') : null}
                    connected={connection.getIn(['connection', 'isConnected'])}
                    showGrade={true}
                    currentUser={this.props.heroProfile}
                    openConversation={this.props.openConversation}
                    openConversationAndFetch={this.props.openConversationAndFetch}
                    fetchDirectChatConversations={this.props.fetchDirectChatConversations}
                    conversations={this.props.conversations}
                    trans={this.props.translations}
                    heroInitials={connection.get('heroInitials')}
                />
            );
        }

        return null;
    }

    _getExperience(items, type = "positions") {
        if (!items) {
            return null;
        }
        
        
        return items.map((item, index) => {

            let startMonth = item.get('startMonth');
            let startYear = item.get('startYear');
            let endMonth = item.get('endYear');
            let endYear = item.get('endYear');
            let positionStartDate = false;
            let positionEndDate = false;
            if(startMonth === null && startYear !== null) {
                positionStartDate = moment(`${item.get('startYear')}`, "YYYY");
            }
            if(startMonth !== null && startYear !== null) {
                positionStartDate = moment(`${item.get('startMonth')}-${item.get('startYear')}`, "MM-YYYY");
            }
            if(endMonth === null && endYear !== null) {
                positionEndDate = moment(`${item.get('endYear')}`, "YYYY");
            }
            if(endMonth !== null && endYear !== null) {
                positionEndDate = moment(`${item.get('endMonth')}-${item.get('endYear')}`, "MM-YYYY");
            }

          return (
              <ExperienceBox
                  className="connection-experienceBox"
                  key={ `${type} - ${index}`}
                  type={ type }
                  title={ item.get('title') }
                  icon={ item.get('imageUrl') }
                  startMonth={ item.get('startMonth') }
                  startDate={ item.get('startYear') }
                  endMonth={ item.get('endMonth') }
                  endDate={ item.get('endYear') }
                  diffInYears={ (positionStartDate) ?  Math.floor(moment(positionEndDate).diff(positionStartDate, 'years')) : false }
                  diffInMonths={ (positionStartDate && startMonth !== null) ? Math.floor(moment(positionEndDate).diff(positionStartDate, 'months') % 12) : false }
                  company={ item.get('company') }
                  summary={ item.get('summary') }
                  description={ item.get('companyDescription') }
                  trans={this.props.translations}
              />
          );
        });
    }

    _getSection(items, type) {
        if (!items || !items.size) {
            return null;
        }

        return (
            <div className="connection-experience">
                <div className="connection-sectionTitle">{type.label}</div>
                {this._getExperience(items, type.name)}
            </div>
        );
    }

    _getExperienceSections() {
        const {connection} = this.props;
        return (
            <div className="connection-pagePadding">
                {this._getSection(connection.get('positions'), {name: 'experience', label: this.props.translations.connection.experience})}
                {this._getSection(connection.get('educations'), { name: 'education', label: this.props.translations.connection.education})}
            </div>
        );
    }

    _getInfo(sectionTitle, data, type=null) {
        let infoString = data;
        let arr = [];
        if (!data || data.size === 0 || data === undefined) {
            return null;
        }
        if (typeof data !== 'string') {
            if (data.get('name') === undefined && sectionTitle !== this.props.translations.connection.industryInterests && sectionTitle !== this.props.translations.connection.interestedIndustrySkills) {
                arr = [];
                data.forEach((item, key) => {
                    arr.push(item.get('name'));
                });
                infoString = arr.join(', ');
            }
            else
            {
                infoString = data.get('name');
            }
        }
        if (sectionTitle === this.props.translations.connection.industryInterests) {
            arr = [];
            data.forEach((item, key) => {
                if (item.get('industry') !== undefined)
                    arr.push(item.getIn(['industry', 'name']));
            });
            infoString = arr.join(', ');
        }

        if (sectionTitle === this.props.translations.connection.interestedIndustrySkills) {
            arr = [];
            data.forEach((item, key) => {
                if (item.get('skills') !== undefined) {
                    item.get('skills').forEach((val, key) => {
                        arr.push(val.get('name'));
                    });
                }
            });
            infoString = arr.join(', ');
        }

        const infoTextId = type ? "sidebarInfoText-" + type : null;
        if (!_.trim(infoString))
            return null;
        return (
            <div className="connection-sidebarInfo">
                <div className="connection-sectionTitle --smallMargin">{sectionTitle}</div>
                <div id={infoTextId} className="connection-sidebarInfoText">{infoString}</div>
            </div>
        );
    }

    onModalClose = () => {
        this.setState({invitationSent: false});
    }

    render() {
        let trans = this.props.translations;
        if (this.state.rendering === true || trans === null) {
            return <div className="officeDetailsLoader"></div>;
        }
        const {connection} = this.props;
        return (
            <div className="connection">
                <section className="connection-content">
                    <Scrollbars autoHide className="styleScroll">
                        <button type="button" onClick={this.props.history.goBack} className="navigation__back-to-button navigation__back-to-button--margin-left">
                            {trans.connection.back}
                        </button>
                        {this._getProfileBox()}
                        {this._getExperienceSections()}

                    </Scrollbars>
                </section>

                <Sidebar className="--full-height">
                    <Scrollbars autoHide className="styleScroll">
                        <div className="connection-sidebarContent">
                            { this._getInfo(_.get(this.props, 'translations.connection.allVerticals', 'News preference'), connection.get('heroVerticals'), 'heroVerticals') }
                            { this._getInfo(_.get(this.props, 'translations.connection.defaultVertical', 'Interest technology vertical'), connection.get('defaultVertical'), 'defaultVertical') }
                            { this._getInfo(_.get(this.props, 'translations.connection.allGoals', 'Goals in Workero network'), connection.get('heroGoals'), 'heroGoals') }
                        </div>
                    </Scrollbars>
                </Sidebar>
                <ModalDialog
                    modalOpened={this.state.invitationSent}
                    className="--connections"
                    onModalClose={this.onModalClose}
                    label="removeConnection">
                    <InvitationSent 
                        onCancelAction={this.onModalClose}
                        firstName={this.props.connection.get('firstName')}
                        lastName={this.props.connection.get('lastName')}
                        trans={trans}
                    />
                </ModalDialog>
            </div>
        );
    }

}

export default Connection;
