import Immutable from 'immutable';
import { SET_HERO_DETAILS, SET_HERO_DETAILS_LOADING } from '../../actions/whiteLabel/hero-details';

const initialState = Immutable.Map({
  list: [],
  isLoading: false,
});

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_HERO_DETAILS:
      return state.setIn(['list'], action.heroDetails);
    case SET_HERO_DETAILS_LOADING:
      return state.setIn(['isLoading'], action.isLoading);
    default:
      return state;
  }
};
