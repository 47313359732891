/**
 * Renders the contact list
 * @module DirectChat
 * @class ContactList
 */

import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';

import CONST from './../../../utils/const';
import Connection from './connection';
import Contact from './contact';

class ContactList extends Component{

  componentDidMount(){
    if(this.conversationsFilter && this.props.meta.get('filterTerm'))
      this.conversationsFilter.focus();
  }

  moveCaretAtEnd(e) {
    var temp_value = e.target.value
    e.target.value = ''
    e.target.value = temp_value
  }

  handleScrollStop = () => {
    this.props.storeScrollHeightContacts(this.scrollbars.getValues().scrollTop);
  }

  componentDidUpdate() {
      if (this.scrollbars) {
            this.scrollbars.scrollTop(this.props.scrollHeightContacts);
      }
  }

  setScroll = () => {
    
  }

  render(){
    let props = this.props;
    
    return (
    <div className="directChat-contacts-contactsWrapper">
      <div className="directChat-contacts-headerWrapper">
        <h1 className="directChat-contacts-pageTitle icon icon-directChat ">
          {this.props.trans.chat.title}
        </h1>

        <button
          className={classnames('directChat-contacts-newConversation icon', {
            'icon-newConversation' : props.meta.get('showConversations') === true,
            'icon-directChat' : props.meta.get('showConversations') !== true
          })}
          onClick={e => {
            if (this.connectionsFilter) {
              this.connectionsFilter.value = '';
            }
            if (this.conversationsFilter) {
              this.conversationsFilter.value = '';
            }
            if (this.scrollbars) {
              this.scrollbars.scrollTop(0);
            }
            props.actions.toggleConversations();
          }}
        />
      </div>
      <div className="directChat-contacts-filter">
        {
          props.meta.get('showConversations') === true
            ? (
              <input
                className="directChat-contacts-filterContacts"
                id="filter-contacts"
                placeholder={this.props.trans.chat.searchMessages} 
                ref={input => {
                  this.conversationsFilter = input;
                }}
                onFocus={this.moveCaretAtEnd}
                value={this.props.meta.get('filterTerm')}
                type="text"
                onKeyUp={e => {
                  if (e.keyCode === CONST.keyCodes.ESC) {
                    e.target.value = '';
                    props.actions.getDirectChatConversations();
                  }
                }}
                onChange={e => {
                  const term = e.target.value.trim().toLowerCase();
                  props.actions.getDirectChatConversations(term);
                }}
              />
            )
            : (
              <input
                className="directChat-contacts-filterConnections"
                id="filter-connections"
                placeholder={this.props.trans.chat.searchConnections}
                ref={input => {
                  this.connectionsFilter = input;
                }}
                value={this.props.meta.get('contactFilterTerm')}
                type="text"
                onKeyUp={e => {
                  if (e.keyCode === CONST.keyCodes.ESC) {
                    e.target.value = '';
                    props.actions.getDirectChatConnections('');
                  }
                }}
                onChange={e => {
                  const term = e.target.value.trim().toLowerCase();
                  if(term === '')
                  {
                    props.actions.getDirectChatConnections('');
                  }
                  else
                  {
                      props.actions.getDirectChatConnections(term);
                  }
                }}
              />
            )
        }
        <span className="directChat-contacts-filterIcon icon icon-search" />
      </div>
      <div className="directChat-contacts-conversationsWrapper">
        <Scrollbars
          universal={true}
          ref={scrollbars => {
            this.scrollbars = scrollbars;
          }}
          autoHide={true}
          onScrollStop={this.handleScrollStop}
        >
          {
            props.meta.get('showConversations') === true
              ? (
                <div className="directChat-contacts-conversations">
                  {
                    props.conversations.count() > 0
                      ? props.conversations.map((conversation, index) => (
                          <div key={`csacsa` + index +new Date()}>
                            <Contact
                              actions={props.actions}
                              item={conversation}
                              setScroll={this.setScroll}
                              now={props.now}
                              heroProfile={props.heroProfile}
                              trans={this.props.trans}
                            />
                          </div>
                        ))
                      : (
                        <div className="directChat-contacts-informationWrapper">
                          {
                            props.meta.get('filterTerm') !== ''
                              ? (
                                <span className="directChat-contacts-information">
                                  {this.props.trans.chat.noConversations}
                                </span>
                              )
                              : (
                                <span className="directChat-contacts-information">
                                  {this.props.trans.chat.noActiveConversations}
                                </span>
                              )
                          }
                        </div>
                    )
                  }
                </div>
              )
              : (
                <div className="directChat-contacts-connections">
                  {
                    props.connections.count() > 0
                      ? props.connections.map((connections) => (
                          <div key={`${connections.get('id')}`}>
                            <Connection
                              actions={props.actions}
                              item={connections}
                              onConnectionSelection={() => {
                                  this.setScroll();
                              }}
                              trans={this.props.trans}
                            />
                          </div>
                      ))
                      : (
                        <div className="directChat-contacts-informationWrapper">
                          {
                            props.meta.get('connectionFilterTerm') !== ''
                              ? (
                                <span className="directChat-contacts-information">
                                   {this.props.trans.chat.noConnectionsFound}
                                </span>
                              )
                              : (
                                <span className="directChat-contacts-information">
                                {this.props.trans.chat.noConnections}
                                </span>
                              )
                          }
                        </div>
                      )
                  }
                </div>
              )
          }
        </Scrollbars>
      </div>
    </div>
  );
  }
}

ContactList.propTypes = {
  /**
   * Contains all available dispatched actions for this component
   * @type {Object}
   */
  actions: PropTypes.object.isRequired,
  /**
   * Contains available connections data
   * @type {Immutable.List<Connection>}
   */
  connections: PropTypes.object.isRequired,
  /**
   * Contains latest conversation data
   * @type {Immutable.List<Conversation>}
   */
  conversations: PropTypes.object.isRequired,
  /**
   * Contains information about the component interactions and state
   * @type {Immutable.Map<Meta>}
   */
  meta: PropTypes.object.isRequired,
  /**
   * Representative date object of NOW
   * @type {Object}
   */
  now: PropTypes.object.isRequired
};

export default ContactList;
