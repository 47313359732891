import React from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import Checkbox from '../../../../addBookingSidebar/checkbox';

class AmenitiesAndServices extends React.Component {
  render() {
    const {
      translations,
      amenities,
      toggleServices,
      selectedAmenities,
      selectedProductId,
      products,
      officeMeetingRooms,
      selectedServices,
      toggleAmenities,
      inactiveAmenities,
      selectedProductType,
    } = this.props;
    const title = _.get(translations, 'options.amenitiesAndServices', 'Amenities&Servicess');
    const findingMeetingRoomProduct = _.find(products, { id: selectedProductId, productableType: 3 });
    const meetingProductableId = _.get(findingMeetingRoomProduct, 'productableId', null);
    const meetingRoom = _.filter(officeMeetingRooms.toJS(), data => data.id === meetingProductableId);
    const equipments = _.get(meetingRoom, '0.equipments', []);
    const isMeetingRoom = _.includes(_.toLower(_.get(findingMeetingRoomProduct, 'type')), 'meeting');
    const hasAvailableAmenities = _.find(amenities.toJS(), ['isAvailable', true]);
    if ((_.isEmpty(equipments) && !hasAvailableAmenities) || selectedProductType === 5) {
      return null;
    }
    return (
      <>
        <div className="white-label__booking-options-title mt-40 mb-15">{title}</div>
        <div className="white-label__amenities-services-list">
          {isMeetingRoom &&
            equipments.map(entity => (
              <div key={entity.id}>
                <div key={entity.id} className={classnames({ free: entity.available }, 'white-label__amenities-services-item')}>
                  <div className="white-label__amenities-services-title">{entity.name}</div>
                  <Checkbox checked={selectedServices.includes(entity.id)} onChange={() => toggleServices(entity.id)} />
                </div>
              </div>
            ))}
          {amenities.toJS().map(
            entity =>
              entity.isAvailable && (
                <div key={entity.id}>
                  <div key={entity.id} className={classnames('white-label__amenities-services-item')}>
                    <div className="white-label__amenities-services-title">{entity.name}</div>
                    <Checkbox
                      checked={selectedAmenities.includes(entity.id)}
                      onChange={() => {
                        toggleAmenities(entity.id);
                      }}
                    />
                  </div>
                  {inactiveAmenities.includes(entity.id) ? (
                    <div className={classnames('no-free-entity', 'white-label__book-max-days', 'white-label__book-max-days--error')}>
                      There is no free {entity.name}
                    </div>
                  ) : null}
                </div>
              )
          )}
        </div>
      </>
    );
  }
}

export default AmenitiesAndServices;
