import _ from 'lodash';
import { connect } from 'react-redux';
import HealthMeasures from '../../components/healthMeasures/HealthMeasures';
import { translations } from '../../selectors/translations';
import './index.scss';

const mapStateToProps = state => ({
  translations: _.get(translations(state), 'healthMeasure', {}),
});

export default connect(mapStateToProps)(HealthMeasures);
