import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import '../../../header/css/search.scss';
import { Scrollbars } from 'react-custom-scrollbars';
import _ from 'lodash';
import PropTypes from 'prop-types';
import OutsideClickHandler from 'react-outside-click-handler';
import classnames from "classnames";

class Search extends Component {
  context;

  static contextTypes = {
    router: PropTypes.object,
  };

  constructor(props){
    super(props);

    this.state = {
      typing: false,
      query: '',
      selectedResultIndex: 0,
      globalSearchSelectedLi: null
    }
  }

  handleChange =  (e) => {
    let text = e.target.value;
    this.setState({
      query: text
    });
  };

  handleClickOutside = evt => {
    this.setState({
      typing: false,
      query: ''
    });
    this.props.expandSearch();
  };


  handleClick = result => {
    this.setState({
      typing: false,
      query: '',
      selectedResultIndex: 0,
      globalSearchSelectedLi: null
    });
    this.props.history.push(`/colleague/details/${result.id}`);
  };

  renderResults(searchedHeroes, query) {
    const { translations } = this.props;
    let results = [];
    if (searchedHeroes.length) {
      searchedHeroes.map((result) => {
        let heroName = `${result.firstName} ${result.lastName}`;
        let regExp = new RegExp(query, "i");
        heroName = heroName.replace(regExp, `<span class='stringMatch'>${query}</span>`);
        let currentIndex = results.length
        results.push(
            <li
                ref={"liElement" + currentIndex} onMouseOver={() => {this.handleResultMouseOver(currentIndex);}}
                className={this.state.selectedResultIndex === results.length ? 'heroResult selected' : 'heroResult'}
                key={result.id + 'h'}
                onClick={() => {
                  this.handleClick(result)
                }}
            >
              <span className="hero-results__name" dangerouslySetInnerHTML={{__html: heroName}}></span>
            </li>
        );
        return null;
      });
      return results;
    }
    results.push(
        <li
            className='heroResult'
            key='noresults'
        >
          <span className="hero-results__no-result">{_.get(translations, 'whiteLabel.noResults', 'whiteLabel.noResults')}</span>
        </li>
    );
    return results;
  }

  handleGlobalSearchLiSelectionChange = (index) => {
    let elementKey = "liElement" + this.state.selectedResultIndex;
    let selectedLi = this.refs[elementKey];
    let oneElementHeight = 0;
    if(selectedLi !== undefined)
    {
      oneElementHeight = selectedLi.offsetHeight;
    }
    if(this.refs[elementKey] != null){
      this.setState({globalSearchSelectedLi: selectedLi});
    }
    if(typeof selectedLi !== "undefined"){

      let elementStartsAtPosition = oneElementHeight * index;
      let scrollbarValues = this.refs.scrollbar.getValues();
      if(elementStartsAtPosition + oneElementHeight  > scrollbarValues.scrollTop + this.refs.scrollbar.getClientHeight()){
        let scrollingValue = oneElementHeight - ((scrollbarValues.scrollTop + this.refs.scrollbar.getClientHeight()) % oneElementHeight);
        if(scrollingValue === 0){
          scrollingValue = oneElementHeight;
        }
        this.refs.scrollbar.scrollTop(scrollingValue + scrollbarValues.scrollTop);
      }

      if(elementStartsAtPosition < scrollbarValues.scrollTop){
        let scrollingValue = 0;
        if(elementStartsAtPosition !== 0){
          let scrollingDifference = scrollbarValues.scrollTop % oneElementHeight;
          if(scrollingDifference === 0){
            scrollingDifference = oneElementHeight;
          }
          scrollingValue = scrollbarValues.scrollTop - scrollingDifference;
        }
        this.refs.scrollbar.scrollTop(scrollingValue);
      }
    }
  };

  handleResultMouseOver = (index) => {
    this.setState({selectedResultIndex: index});
    this.handleGlobalSearchLiSelectionChange(index);
  }


  handleEnterOnSelectedOptionForSearch = () => {
    if(this.state.globalSearchSelectedLi != null){
      this.state.globalSearchSelectedLi.click();
    }
  }

  handleKeyUp =  (e) => {
    const { buildingHeroes } = this.props;
    const { query } = this.state;
    const lowerQuery = _.toLower(query);
    const searchedHeroes = buildingHeroes
        ? buildingHeroes.filter(searchHero => _.startsWith(_.toLower(`${searchHero.firstName} ${searchHero.lastName}`), lowerQuery))
        : [];
    if(e.nativeEvent.keyCode === 13){//ENTER
      this.handleEnterOnSelectedOptionForSearch();
    }
    if(e.nativeEvent.keyCode === 38 || e.nativeEvent.keyCode === 40) //up || down
    {
      if(this.props.globalSearchResults !== null)
      {
        const maxResultIndex = searchedHeroes.length - 1;

        let nextIndex = this.state.selectedResultIndex;
        if(this.state.selectedResultIndex > 0 && e.nativeEvent.keyCode === 38) // UP
        {
          nextIndex = nextIndex - 1;
        }
        if(this.state.selectedResultIndex < maxResultIndex && e.nativeEvent.keyCode === 40) // DOWN
        {
          nextIndex = nextIndex + 1;
        }
        this.setState({selectedResultIndex : nextIndex}, () => {
          this.handleGlobalSearchLiSelectionChange(nextIndex);
        });
      }
    }

    const state = query.length !== 0 && e.nativeEvent.keyCode !== 27;
    if (state !== this.state.typing)
    {
      this.setState({
        typing: state
      });
    }
  }

  componentDidUpdate = () => {
    if(this.state.globalSearchSelectedLi === null && this.props.globalSearchResults !== null)
    {
      this.handleGlobalSearchLiSelectionChange(0);
    }
  }

  handleFocus = e => {
    e.preventDefault();
    let state = ((this.state.query !== 0) ? true : false);
    this.setState({
      typing: state
    });
  }

  render () {
    const { query, typing } = this.state;
    const { buildingHeroes, translations } = this.props;
    const typed = (typing ? ' search-typed' : '');
    const lowerQuery = _.toLower(query);
    const searchedHeroes = buildingHeroes
        ? buildingHeroes.filter(searchHero =>
            _.startsWith(_.toLower(`${searchHero.firstName} ${searchHero.lastName}`), lowerQuery) ||
            _.startsWith(_.toLower(`${searchHero.lastName} ${searchHero.firstName}`), lowerQuery))
        : [];
    let placeHolder = _.get(this.props, 'placeholder', null);
    const nbResults = searchedHeroes.length;
    let searchBarResultsHeight = 0;
    if (nbResults >= 3) {
      searchBarResultsHeight = '186px';
    }
    else if (nbResults) {
      searchBarResultsHeight = 'calc(' + nbResults * 63 + 'px)';
    } else {
      searchBarResultsHeight = '46px';
    }


    return (
        <OutsideClickHandler onOutsideClick={() => {
          this.handleClickOutside();
        }}>
          <div className={classnames(this.props.className, typed)}>
            <span className="search-title">{_.get(translations, 'whiteLabel.searchForColleagues', '')}</span>
            <input
                disabled={this.props.disabled}
                onClick={() => { this.setState({typing: true}, ()=>{this.nameInput.focus();}); }}
                className="search-input"
                type="text"
                placeholder={placeHolder}
                onChange={this.handleChange}
                onKeyUp={this.handleKeyUp}
                value={query}
                onFocus={()=>{  }}
                onBlur={() => { if(this.state.typing === true){ this.nameInput.focus(); }}}
                ref={(input) => { this.nameInput = input; }}
            />
            <span className={'close-search'} onClick={() => {this.setState({query: ''})}} >x</span>
            <div className={classnames("search-results", (query.length ? 'hasSearch' : ''))}  style={{ height: searchBarResultsHeight }}>
              <Scrollbars
                  ref={"scrollbar"}
              >
                <ul>
                  { this.renderResults(searchedHeroes, query) }
                </ul>
              </Scrollbars>
            </div>
          </div>
        </OutsideClickHandler>
    );
  }
}

export default withRouter(Search);
