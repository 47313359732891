import React from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import ContextMenu from '../../common/context-menu';
import CommentForm from '../create-comment';
import { dateTimeFormatter } from '../../utils/datetime-formatter';
import { urlify } from '../../utils/url-parser';
import CommunityModal from '../../common/modals';
import ReportModal from '../../common/modals/report-modal';
import EditCommentForm from '../edit-comment';

class CommentCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      replyForm: false,
      showDeleteCommentModal: false,
      showReportModal: false,
      isReportDisabled: false,
      commentId: null,
      postId: null,
      title: _.get(props.translations, 'report.whyReport', 'report.whyReport'),
      editForm: false,
    };
    this.ref = React.createRef();

    this.handleReplyCommentForm = this.handleReplyCommentForm.bind(this);
    this.handleDeleteCommentModal = this.handleDeleteCommentModal.bind(this);
    this.handleEditComment = this.handleEditComment.bind(this);
    this.handleReportCommentModal = this.handleReportCommentModal.bind(this);
    this.setReportModalVisibility = this.setReportModalVisibility.bind(this);
    this.onReportSuccess = this.onReportSuccess.bind(this);
  }

  componentDidMount() {
    const { location, comment } = this.props;
    const commentId = _.get(comment, 'commentId', '');
    const anchor = _.replace(location.hash, '#', '');

    if (anchor && commentId == anchor) {
        this.ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }

  handleReplyCommentForm(status) {
    const { heroIsCommunityMember, showCommunityPageToast } = this.props;
    if (!heroIsCommunityMember) {
      return showCommunityPageToast('toast.onlyMemberCanPost');
    }
    this.setState({ replyForm: status });
  }

  handleDeleteCommentModal(idComment) {
    const { showDeleteCommentModal, commentId } = this.state;
    this.setState({
      showDeleteCommentModal: !showDeleteCommentModal,
      commentId: commentId !== idComment ? idComment : null,
    });
  }

  handleReportCommentModal(comment, postId) {
    const { heroIsCommunityMember, showCommunityPageToast } = this.props;
    if (!heroIsCommunityMember) {
      return showCommunityPageToast('toast.onlyMemberCanReport');
    }
    this.setState({ showReportModal: true, comment, postId });
  }

  setReportModalVisibility() {
    const { showReportModal } = this.state;
    this.setState({ showReportModal: !showReportModal });
  }

  onReportSuccess(data) {
    const { report, showCommunityPageToast } = this.props;
    report(data)
      .then(() => {
        showCommunityPageToast('report.success');
        this.setState({ isReportDisabled: true });
      })
      .catch(() => {
        showCommunityPageToast('report.fail');
      });
    this.setState({ showReportModal: false });
  }

  renderReportModal() {
    const { translations, heroProfile, community } = this.props;
    const { comment, title, postId } = this.state;
    const reporterFirstName = _.get(heroProfile, 'firstName', '');
    const reporterLastName = _.get(heroProfile, 'lastName', '');
    const reporterName = `${reporterFirstName} ${reporterLastName}`;
    const communityName = _.get(community, 'name', '');
    const communityId = _.get(community, 'id', null);
    const heroId = _.get(heroProfile, 'id', null);
    const commentText = _.get(comment, 'content', null);
    return (
      <CommunityModal
        translations={translations}
        title={title}
        wrapperClasses="community-modals report-modal"
        body={
          <ReportModal
            translations={translations}
            reporterName={reporterName}
            communityName={communityName}
            communityId={communityId}
            postId={postId}
            commentText={commentText}
            heroId={heroId}
            reportType="comment"
            toggleNextTitle={nextTitle => {
              this.setState({ title: nextTitle });
            }}
            onReportSuccess={this.onReportSuccess}
          />
        }
        closeModal={this.setReportModalVisibility}
      />
    );
  }

  handleEditComment(status) {
    this.setState({ editForm: status });
  }

  handleContextMenuItems() {
    const { comment, heroProfile, heroIsCommunityAdmin, translations, post } = this.props;
    const { isReportDisabled } = this.state;
    const currentHeroId = _.get(heroProfile, 'id', null);
    const authorId = _.get(comment, 'authorId', null);
    const commentId = _.get(comment, 'commentId', null);
    const editCommentTranslation = _.get(translations, 'communityComments.editComment', 'communityComments.editComment');
    const deleteCommentTranslation = _.get(translations, 'communityComments.deleteComment', 'communityComments.deleteComment');
    const reportCommentTranslation = _.get(translations, 'communityComments.reportComment', 'communityComments.reportComment');
    const postId = _.get(post, 'id', null);
    const deleteAction = {
      text: deleteCommentTranslation,
      action: () => this.handleDeleteCommentModal(commentId),
    };
    const editAction = {
      text: editCommentTranslation,
      action: () => this.handleEditComment(true),
    };
    const reportAction = {
      text: reportCommentTranslation,
      action: () => this.handleReportCommentModal(comment, postId),
      isDisabled: isReportDisabled,
    };

    const items = [];
    if (heroIsCommunityAdmin) {
      if (currentHeroId === authorId) {
        items.push(editAction);
      }
      items.push(deleteAction);
    } else if (currentHeroId === authorId) {
      items.push(editAction, deleteAction);
    } else {
      items.push(reportAction);
    }

    return items;
  }

  deleteCommentAction(commentId) {
    const { deleteCommunityComment, post, showCommunityPageToast } = this.props;
    deleteCommunityComment(commentId, post.id)
      .then(() => {
        showCommunityPageToast('toast.deleteCommentWithSucces');
      })
      .catch(() => {
        showCommunityPageToast('toast.generalFailure');
      });
    this.setState({ showDeleteCommentModal: false });
  }

  renderDeleteCommentModal() {
    const { translations } = this.props;
    const { commentId } = this.state;
    return (
      <CommunityModal
        title={_.get(translations, 'communityComments.deleteCommentModalTitle', 'communityComments.deleteCommentModalTitle')}
        closeModal={this.handleDeleteCommentModal.bind(this)}
        wrapperClasses="community-modals community-confirmation-modal__wrapper"
        body={
          <div className="community-confirmation-modal__container">
            <div className="community-confirmation-modal__confirmation-message">
              {_.get(translations, 'communityComments.deleteCommentMessage', 'communityComments.deleteCommentMessage')}
            </div>
            <div className="community-confirmation-modal__actions">
              <button className="community-confirmation-modal__cancel-button" onClick={this.handleDeleteCommentModal.bind(this)}>
                {_.get(translations, 'deleteCommunityModal.cancelButton', 'deleteCommunityModal.cancelButton')}
              </button>
              <button
                className="button-yellow community-confirmation-modal__save-button"
                onClick={() => this.deleteCommentAction(commentId)}
              >
                {_.get(translations, 'deleteCommunityModal.deleteButton', 'deleteCommunityModal.deleteButton')}
              </button>
            </div>
          </div>
        }
      />
    );
  }

  render() {
    const { comment, post, history, heroProfile, translations, uniqueCommentIdentifier } = this.props;
    const { replyForm, editForm, showDeleteCommentModal, showReportModal } = this.state;
    const authorFullname = _.get(comment, 'author.fullName', false);
    const authorAvatar = _.get(comment, 'author.avatar', false);
    const heroInitials = _.get(comment, 'author.heroInitials', null);
    const commentContent = _.get(comment, 'content', false);
    const authorId = _.get(comment, 'authorId', false);
    const commentId = _.get(comment, 'commentId', false);
    const isReplyForm = _.get(comment, 'isReply', false);
    const menuitems = this.handleContextMenuItems();
    const currentHeroId = _.get(heroProfile, 'id', null);
    const parentCommentId = _.get(comment, 'parentCommentId', false) || commentId;
    const commentCreationDate = _.get(comment, 'created', null);
    const formattedCreationDate = dateTimeFormatter(commentCreationDate);
    const replyTranslation = _.get(translations, 'communityComments.reply', 'communityComments.reply');
    const openDeleteCommentModal = showDeleteCommentModal ? this.renderDeleteCommentModal() : '';
    const createReportModal = showReportModal ? this.renderReportModal() : null;
    if (editForm) {
      return (
        <EditCommentForm
          comment={comment}
          translations={translations}
          handleEditFormCallback={() => {
            this.handleEditComment(false);
          }}
        />
      );
    }

    return (
      <>
        {createReportModal}
        {openDeleteCommentModal}
        <div
          ref={this.ref}
          className={classnames('community__comment-container', {
            'community__comment-container--isReplyForm': isReplyForm,
          })}
        >
          {authorAvatar ? (
            <div className="community-comment__avatar">
              <img
                className="community-comment__hero-picture"
                onClick={() => {
                  history.push(authorId === currentHeroId ? '/account/profile' : `/connection/${authorId}`);
                }}
                src={authorAvatar}
                alt=""
              />
            </div>
          ) : (
            <div className="hero-initials post-card-hero-initials">{heroInitials}</div>
          )}
          <div className="community-comment__element">
            <div className="community-comment__container">
              <span
                className="community-comment__author"
                onClick={() => {
                  history.push(authorId === currentHeroId ? '/account/profile' : `/connection/${authorId}`);
                }}
              >
                {authorFullname}
              </span>{' '}
              <span className="community-comment__content" dangerouslySetInnerHTML={{ __html: urlify(commentContent) }} />
              <ContextMenu items={menuitems} />
            </div>
            <div className="community-comment__controls">
              <button className="community-comment-reply-button" type="button" onClick={e => this.handleReplyCommentForm(true)}>
                {replyTranslation}
              </button>
              <span className="comments-separator">•</span>
              <span className="comments-timestamp">{formattedCreationDate}</span>
            </div>
          </div>
        </div>
        {replyForm && (
          <CommentForm
            uniqueIdentifier={`form-${uniqueCommentIdentifier}`}
            post={post}
            translations={translations}
            isReplyForm
            parentCommentId={parentCommentId}
            handleReplyForm={this.handleReplyCommentForm}
          />
        )}
      </>
    );
  }
}

CommentCard.propTypes = {
  community: PropTypes.shape({}).isRequired,
  comment: PropTypes.shape({}).isRequired,
  post: PropTypes.shape({}).isRequired,
  showCommunityPageToast: PropTypes.func.isRequired,
  deleteCommunityComment: PropTypes.func.isRequired,
  report: PropTypes.func.isRequired,
  heroIsCommunityAdmin: PropTypes.bool.isRequired,
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
  }).isRequired,
  heroProfile: PropTypes.shape({}).isRequired,
  translations: PropTypes.shape({}).isRequired,
  uniqueCommentIdentifier: PropTypes.string,
};

CommentCard.defaultProps = {
  uniqueCommentIdentifier: undefined,
};

export default withRouter(CommentCard);
