import React from 'react';
import Sidebar from '../rightSidebar/rightSidebar';

class TipsAndTricks extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
        };
    }

    componentDidMount() {
    }

    componentDidUpdate(){
    }


    buildTip(tip, index) {
        return <div key={index} className='general-settings__sidebar-tip'>{tip}</div>;
    }

    render() {

        return (
            <Sidebar className="--full-height">
                <div className="general-settings__sidebar-content">
                  <div className="general-settings__sidebar-title">{this.props.translations.generalSettings.tipsAndTricksTitle}</div>
                  {this.props.translations.generalSettings.tips.map(this.buildTip)}
                </div>
            </Sidebar>
        );
    }
}

export default TipsAndTricks;
