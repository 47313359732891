import React from 'react';
import '../../css/index.scss';
import moment from 'moment';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import Truncate from 'react-truncate';
import details_icon from '../../../../assets/svg/details_icon.svg';
import Dropdown from '../../../dashboard/common/dropdown/Dropdown';
import firstAid from '../../../../assets/images/whitelabel/first-aid-training.svg';
import maintenance from '../../../../assets/images/whitelabel/maintenance.svg';
import evacuation from '../../../../assets/images/whitelabel/evacuation-emergency.svg';
import parking from '../../../../assets/images/whitelabel/fleet-parking-management.svg';
import { getWorkDays } from '../../../../utils/weekDaysUtils';
import getLang from '../../../../utils/lang';

const lang = getLang() !== null ? getLang() : 'en';
const DATE_FORMAT = 'MMM D, YYYY';

/**
 *  Show bookings for all floors into the office details
 *
 */
class HeroesBookedList extends React.Component {
  constructor(props) {
    super(props);
    this.changeDepartment = this.changeDepartment.bind(this);
    this.renderBooking = this.renderBooking.bind(this);
    this.setPin = this.setPin.bind(this);
    this.state = { selectedDepartment: 0 };
  }

  componentDidMount() {
    const { fetchDepartments, officeId } = this.props;
    fetchDepartments(officeId);
  }

  changeDepartment(departmentId) {
    const { fetchHeroesBookings, officeId, selectDateRange, selectProductTypeId } = this.props;
    const startDate = moment.utc(selectDateRange.from, DATE_FORMAT).unix();
    const endDate = moment.utc(selectDateRange.to, DATE_FORMAT).unix();

    fetchHeroesBookings(officeId, startDate, endDate, selectProductTypeId, departmentId);
    this.setState({ selectedDepartment: departmentId });
  }

  setPin(product) {
    const { setSelectedPin, floorPlans, selectedFloorIndex, selectFloor, setSelectedProductId } = this.props;
    const { id, floor_plan_id } = product;
    setSelectedProductId(null);
    const currentFloorId = _.get(floorPlans, `${selectedFloorIndex}.id`);
    if (currentFloorId !== floor_plan_id) {
      selectFloor(floor_plan_id);
    }
    setSelectedPin(id);
  }

  getSkill(skill) {
    let icon = null;
    let alt = null;
    switch (skill) {
      case 2:
        icon = firstAid;
        alt = 'first aid';
        break;
      case 3:
        icon = evacuation;
        alt = 'evacuation & emergency';
        break;
      case 4:
        icon = maintenance;
        alt = 'maintenance';
        break;
      case 6:
        icon = parking;
        alt = 'fleet & parking management';
        break;
      default:
        icon = null;
    }

    return icon && <img alt={alt} src={icon} title={alt} />;
  }

  renderLoader() {
    return (
      <div className="heroes-booked-content">
        <div className="heroes-grid__row">
          <div className="heroes-card-container">
            <div className="hero-booking-list-loader" />
          </div>
        </div>
      </div>
    );
  }

  renderBooking(booking) {
    const { translations, currentUserId } = this.props;
    const startDate = moment(_.get(booking, 'from_date', null))
      .locale(lang)
      .format('MMM D, YYYY');
    const endDate = moment(_.get(booking, 'to_date', null))
      .locale(lang)
      .format('MMM D, YYYY');
    const startHour = _.get(booking, 'from_hour', null).slice(0, -3);
    const endHour = _.get(booking, 'to_hour', null).slice(0, -3);

    const product = _.get(booking, 'product', null);
    const heroId = _.get(booking, 'hero_id', null);
    const skills = _.get(booking, 'skills', null);
    const weekDaysMap = _.get(translations, 'location.weekDaysMap', []);

    const heroPageUrl = currentUserId === heroId ? `/my-bookings` : `/colleague/details/${heroId}`;
    const bookingDateInterval = startDate === endDate ? `${startDate}` : `${startDate} - ${endDate}`;
    const weekDays = _.get(booking, 'weekDays', '');
    const checkedOutWeekday = _.get(booking, 'checkout_weekday', null);
    const checkedOutString = _.get(translations, 'whiteLabel.checkedOut', '(checked out)');
    const floorName = _.get(booking, 'product.name', null);
    const floorLabel = floorName || `${_.get(translations, 'location.floor', '')} ${_.get(booking, 'product.floor_number', null)}`;

    return (
      <div className="heroes-active-row-wrapper">
        <div className="heroes-active-row">
          <div className="heroes-booked-avatar-mask">
            <span>{_.get(booking, 'initials', null)}</span>
          </div>

          <div className="heroes-details-wrapper">
            <div className="heroes-details-name">
              {_.get(booking, 'first_name', null)} {_.get(booking, 'last_name', null)}
              <span className="hero-details-skills">{skills.map(this.getSkill)}</span>
            </div>
            <div className="heroes-details-floor">
              {floorLabel}, {_.get(booking, 'productType', null)} - {_.get(booking, 'product.product_name', null)}
            </div>
          </div>

          <div className="heroes-details-wrapper">
            <div className="heroes-details-date">
              {bookingDateInterval}{' '}
              <span>
                {startHour}-{endHour}
              </span>
            </div>
            <div className="heroes-details-working-days">
              <Truncate title={getWorkDays(weekDays, weekDaysMap, checkedOutWeekday, checkedOutString, true)} lines={1} ellipsis={<span>...</span>} width={300}>
                {getWorkDays(weekDays, weekDaysMap, checkedOutWeekday, checkedOutString, true)}
              </Truncate>
            </div>
          </div>

          <div className="heroes-button-wrapper">
            <div
              className="heroes-details-button"
              onClick={() => {
                this.setPin(product);
              }}
            >
              <span className="heroes-details-button-sign">
                <img src={details_icon} />
              </span>
              <Link
                to={{
                  pathname: heroPageUrl,
                  state: { stopFetch: true },
                }}
              >
                <span className="heroes-details-button-name">{_.get(translations, 'whiteLabel.details', '')}</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { isLoading, bookings, departments, translations } = this.props;
    const allLabel = _.get(translations, 'dashboard.all', 'All');
    const formatedDepartments = departments.map(item => ({ id: item.id, value: item.name }));
    const departmentsArray = _.concat({ id: null, value: allLabel }, formatedDepartments);
    const { selectedDepartment } = this.state;

    if (isLoading) {
      return this.renderLoader();
    }

    return (
      <div className="heroes-booked-content">
        <div className="heroes-grid__row">
          <div className="heroes-card-container">
            <div className="heroes-filters-selector__container">
              <div className="heroes-filters-selector__picker-container">
                <Dropdown
                  selectedId={selectedDepartment}
                  placeHolder={allLabel}
                  className="dropdown__selector filter__dropdown"
                  options={departmentsArray}
                  onChange={this.changeDepartment}
                />
              </div>
            </div>
            {bookings.map(this.renderBooking)}
            {bookings.length == 0 ? (
              <div className="heroes-booked-content__no-records">{_.get(translations, 'whiteLabel.noBookings', '')}</div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

export default HeroesBookedList;
