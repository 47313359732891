import React from 'react';
import _ from 'lodash';

const ProductOverview = props => {
  const { translations, formatString, selectedProductId, products, officeMeetingRooms } = props;
  const selectedProduct = _.find(products, x => x.id === selectedProductId);
  let seats;
  switch (_.get(selectedProduct, 'productableType', null)) {
    case 1:
      selectedProduct.type = _.get(translations, 'booking.options.officeTypes.desk', 'booking.options.officeTypes.desk');
      break;
    case 5:
      selectedProduct.type = _.get(translations, 'booking.options.officeTypes.parking', 'booking.options.officeTypes.parking');
      break;
    case 3:
      selectedProduct.type = _.get(translations, 'booking.options.officeTypes.meeting', 'booking.options.officeTypes.meeting');
      const findingMeetingRoomProduct = _.find(products, ['id', selectedProductId]);
      const meetingRoom = _.filter(officeMeetingRooms.toJS(), data => data.id === findingMeetingRoomProduct.productableId);
      seats = `, ${_.get(meetingRoom, '0.seats', 0)} ${_.get(translations, 'location.roomLabels.seats', 'location.roomLabels.seats')}`;
      break;
    default:
      break;
  }

  const title = formatString(_.get(translations, 'whiteLabel.options.productOverview', '{0} '), _.get(selectedProduct, 'type', 'desk'));
  let customFeatures = _.get(selectedProduct, 'customFeature', ' ');
  if (customFeatures) {
    customFeatures = `${_.get(translations, 'whiteLabel.features', 'Features')}: ${customFeatures}`;
  }
  return (
    <div className="white-label__product-overview">
      <div className="white-label__booking-options-title mt-40">{title}</div>
      <div className="white-label__product-type">{_.get(selectedProduct, 'type')}</div>
      <div className="white-label__product-name">
        {_.get(selectedProduct, 'name')}
        {seats}
      </div>
      <div className="white-label__product-description">{_.get(selectedProduct, 'details')}</div>
      <div className="white-label__product-description">{customFeatures}</div>
    </div>
  );
};

export default ProductOverview;
