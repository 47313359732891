import { connect } from 'react-redux';
import { fetchProfile } from '../../../actions/accountActions';
import addLoader from '../utils/add-loader';
import ProfileButtons from './ProfileButtons';

const mapStateToProps = state => ({
  translations: state.translations,
  bookingValidate: state.addBooking.get('bookingValidate'),
});

const mapDispatchToProps = dispatch => ({
  fetchProfile: () => dispatch(fetchProfile()),
});

export default connect(mapStateToProps, mapDispatchToProps)(addLoader(ProfileButtons, true));
