import Immutable from 'immutable';
import {
  FETCH_OFFICE_DETAILS,
  ON_FINISH_BOOKING,
  BEFORE_FETCH_OFFICE_DETAILS,
  OPEN_BOOKING_OPTIONS,
  CLOSE_BOOKING_OPTIONS,
  BOOKING_REQUEST,
  OPEN_SIDE_POPUP,
  CLOSE_SIDE_POPUP,
  OPEN_HERO_SIDE_POPUP,
  CLOSE_HERO_SIDE_POPUP,
  TOGGLE_DATE_EXPANDED,
} from './../actions/locationActions';
import { FETCH_AMENITIES, FETCH_OFFICE_TYPES, FETCH_SERVICES, FETCH_HOUSE_RULES } from './../actions/generalDataActions';

const defaultState = Immutable.fromJS({
  amenities: {},
  bookingOptionsOpened: false,
  details: {},
  houseRules:{},
  officeTypesData:{},
  services:{},
  bookingRequestResponse: null,
  bookingRequestResponseErrors: null,
  sidePopupOpened: false,
  heroPopupErrorOpened: false,
  datePickerExpanded: false,
});

const location = (state = defaultState, action) => {
  let newState;

  switch (action.type) {
    case FETCH_HOUSE_RULES:
      newState = state.set('houseRules', Immutable.fromJS(action.data));
      break;
    case OPEN_BOOKING_OPTIONS:
      newState = state.set('bookingOptionsOpened', true);
      break;
    case CLOSE_BOOKING_OPTIONS:
      newState = state.set('bookingOptionsOpened', false);
      break;
    case FETCH_SERVICES:
      newState = state.set('services', Immutable.fromJS(action.data));
      break;
    case FETCH_AMENITIES:
      newState = state.set('amenities', Immutable.fromJS(action.data));
      break;
    case BEFORE_FETCH_OFFICE_DETAILS:
      newState = state.set('details', Immutable.fromJS({}));
      break;
    case FETCH_OFFICE_DETAILS:
      newState = state.set('details', Immutable.fromJS(action.data));
      break;
    case FETCH_OFFICE_TYPES:
      newState = state.set('officeTypesData', Immutable.fromJS(action.data));
      break;
    case ON_FINISH_BOOKING:
      const bookedStatus = 'booked-status-location' + action.id;
      newState = state.setIn(['details', 'selectedOptions'], action.selectedOptions)
                      .setIn(['details', 'selectedServices'], action.selectedServices)
                      .setIn(['details', 'selectedRoom'], action.selectedRoom)
                      .setIn(['details', bookedStatus], true);
      break;
    case BOOKING_REQUEST:
      newState = state.set('bookingRequestResponse', action.data).set('bookingRequestResponseErrors', action.errors);
      break;
    case OPEN_SIDE_POPUP:
        if(state.get('bookingOptionsOpened') === true)
        {
          newState = state.set('sidePopupOpened', true);
        }
      break;
    case CLOSE_SIDE_POPUP:
        newState = state.set('sidePopupOpened', false).set('bookingRequestResponseErrors', null).set('bookingRequestResponse', null);
      break;   
    case OPEN_HERO_SIDE_POPUP:
          newState = state.set('heroPopupErrorOpened', true);
        break;
    case CLOSE_HERO_SIDE_POPUP:
          newState = state.set('heroPopupErrorOpened', false).set('bookingRequestResponseErrors', null).set('bookingRequestResponse', null);
        break;     
    case TOGGLE_DATE_EXPANDED:
        newState = state.set('datePickerExpanded', action.expanded);
      break;
    default:
      newState = state;
  }
  return newState;
};
export default location;
