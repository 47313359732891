import React, { useState } from 'react';
import _ from 'lodash';
import RemoveYellowImage from '../../../../../../assets/images/remove-yellow.png';

const HeroesList = props => {
  const buildHeroItem = hero => (
    <span key={hero.id} className="community-audience__hero-item">
      {_.get(hero, 'name', '')}
      <img
        src={RemoveYellowImage}
        alt="x"
        className="community-audience__remove-hero"
        onClick={() => props.handleHeroRemoveClick(hero.id)}
      />
    </span>
  );

  const [showMore, setShowMore] = useState(true);
  const { heroes } = props;
  let heroesToDisplay = [...heroes];
  heroesToDisplay.reverse();
  const heroesCount = heroes.length;
  if (showMore && heroesCount > 2) {
    heroesToDisplay = heroesToDisplay.slice(0, 2);
  }
  const heroesMore = heroes.length - heroesToDisplay.length;

  return heroesToDisplay.length ? (
    <div className="community-audience__heroes-list">
      {heroesToDisplay.map(buildHeroItem)}
      {showMore ? (
        <div className="community-audience__heroes-more" onClick={() => setShowMore(false)}>
          {heroesMore ? ` +${heroesMore} more` : ''}{' '}
        </div>
      ) : (
        <div className="community-audience__heroes-more" onClick={() => setShowMore(true)}>
          Show less
        </div>
      )}
    </div>
  ) : (
    ''
  );
};

export default HeroesList;
