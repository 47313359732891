import React, { Component } from 'react';
import _ from 'lodash';
import { Scrollbars } from 'react-custom-scrollbars';
import { withRouter } from 'react-router-dom';
import CommunityModal from './common/modals';
import CommunityForm from './components/create';
import PopularCommunities from './components/popular-communities';
import PageHeader from '../pageHeader/pageHeader';
import FirstCommunityContainer from './components/FirstCommunityContainer';
import HeroCommunities from './components/hero-communities';
import NoCommunities from './components/NoCommunities';
import InfoToast from './common/info-toast/InfoToast';
import blockIncompleteProfileAction from '../../utils/blockIncompleteProfileAction';

class Communities extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showForm: false,
      showCommunityGeneralToast: false,
      showCommunityDeletedToast: false,
      showCommunityLeftToast: false,
    };
    this.displayCommunityForm = this.displayCommunityForm.bind(this);
    this.hideCommunityForm = this.hideCommunityForm.bind(this);
  }

  componentDidMount() {
    this.showCompanyDeletedToast();
    this.showCommunityLeftToast();
    this.showGeneralToast();
  }

  showCompanyDeletedToast() {
    const { location, history } = this.props;
    const showCommunityDeletedToast = _.get(location, 'state.showCommunityDeletedToast', false);
    if (!showCommunityDeletedToast) {
      return;
    }
    this.setState({ showCommunityDeletedToast: true }, () => {
      setTimeout(() => {
        this.setState({ showCommunityDeletedToast: false });
        history.replace({
          pathname: '/communities',
          state: { showCommunityDeletedToast: false },
        });
      }, 2000);
    });
  }

  showCommunityLeftToast() {
    const { location, history } = this.props;
    const showCommunityLeftToast = _.get(location, 'state.showCommunityLeftToast', false);
    if (!showCommunityLeftToast) {
      return;
    }
    this.setState({ showCommunityLeftToast: true }, () => {
      setTimeout(() => {
        this.setState({ showCommunityLeftToast: false });
        history.replace({
          pathname: '/communities',
          state: { showCommunityLeftToast: false },
        });
      }, 2000);
    });
  }

  showGeneralToast() {
    const { history, location, communitiesToastMessage } = this.props;
    const errorHash = location.hash || null;
    const messageFromUrl = errorHash && errorHash.includes('#error=') ? `toast.${errorHash.replace('#error=', '')}` : null;
    const generalError = messageFromUrl || communitiesToastMessage;
    if (!generalError) {
      return;
    }

    this.setState({ showCommunityGeneralToast: true }, () => {
      setTimeout(() => {
        this.setState({ showCommunityGeneralToast: false });
        history.replace({
          pathname: '/communities',
          state: { showCommunityGeneralToast: false },
        });
      }, 4000);
    });
  }

  displayCommunityForm() {
    this.setState({ showForm: true });
  }

  hideCommunityForm() {
    this.setState({ showForm: false });
  }

  renderCommunityForm() {
    const { translations } = this.props;
    const formTitle = _.get(translations, 'form.createCommunityFormTitle', 'createCommunityFormTitle');
    const formDescription = _.get(translations, 'form.createCommunityFormDescription', 'createCommunityFormDescription');
    return (
      <CommunityModal
        title={formTitle}
        description={formDescription}
        wrapperClasses="community-modals"
        body={<CommunityForm translations={translations} closeForm={this.hideCommunityForm} />}
        closeModal={this.hideCommunityForm}
      />
    );
  }

  render() {
    const { translations, history, heroHasOwnCommunity, noCommunities, communitiesToastMessage, location } = this.props;
    const { showForm, showCommunityDeletedToast, showCommunityLeftToast, showCommunityGeneralToast } = this.state;
    const form = showForm ? this.renderCommunityForm() : null;
    const isAllCommunities = _.get(history, 'location.pathname', null) === '/communities/all';
    const createCommunity = _.get(translations, 'createCommunity', 'createCommunity');
    const errorHash = location.hash || null;
    const messageFromUrl = errorHash && errorHash.includes('#error=') ? `toast.${errorHash.replace('#error=', '')}` : null;
    const generalError = messageFromUrl || communitiesToastMessage;
    return (
      <Scrollbars>
        {form}
        {showCommunityDeletedToast && <InfoToast message={_.get(translations, 'toast.communityDeleted', 'toast.communityDeleted')} />}
        {showCommunityLeftToast && <InfoToast message={_.get(translations, 'toast.communityLeft', 'toast.communityLeft')} />}

        {/* To use as a general toast */}
        {generalError && showCommunityGeneralToast && <InfoToast message={_.get(translations, generalError, 'loading ...')} />}

        <div className="community__container">
          <div className="community-grid">
            <div className="community-grid__column community-grid__column--left background-left">
              {noCommunities ? (
                <>
                  <PageHeader pageTitle={_.get(translations, 'titleLabel', '')} icon="community" actions={null} />
                  <NoCommunities translations={translations} action={this.displayCommunityForm} />
                </>
              ) : (
                <>
                  {!isAllCommunities && <PageHeader pageTitle={_.get(translations, 'titleLabel', '')} icon="community" actions={null} />}
                  {!isAllCommunities && !heroHasOwnCommunity && (
                    <FirstCommunityContainer translations={translations} action={this.displayCommunityForm} />
                  )}
                  <PopularCommunities isAllCommunities={isAllCommunities} translations={translations} />
                </>
              )}
            </div>
            <div className="community-grid__column community-grid__column--right">
              <div className="create_community_button">
                <button
                  type="button"
                  id="open-community-form"
                  className="entity-button entity-button--yellow"
                  onClick={() =>
                      blockIncompleteProfileAction().then(() => {
                        history.push({ pathname: '/account/profile/incomplete', fromUrl: location.pathname });
                      }).catch(() => {
                        this.displayCommunityForm();
                      })
                  }
                >
                  {createCommunity}
                </button>
              </div>
              <div className="card__container card__container--border">
                <HeroCommunities translations={translations} />
              </div>
            </div>
          </div>
        </div>
      </Scrollbars>
    );
  }
}

export default withRouter(Communities);
