import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import './css/index.scss';
import onClickOutside from 'react-onclickoutside';
import _ from 'lodash';
import LogoutHero from "../../utils/LogoutHero";

class ProfileMenu extends React.Component {
  logout() {
      LogoutHero(this.props);
  }

  handleClickOutside = evt => {
    if(this.props.profileMenuClicked)
        this.props.profileMenuClick(evt);
  };


  render() {
    return (
      <div className="profile-menu">
        <div className="profile-menu-item">
          <a onClick={this.props.profileMenuClick}
             href={this.props.isWhiteLabel ? "/profile" : "/account/profile"}>
            <div className="menu-icon profile-icon"></div>
              <div className="profile-menu-item-label">{_.get(this.props.trans, 'profile.profile', 'Profile')}</div>
          </a>
          <div className="clear"></div>
        </div>
        <div className="profile-menu-item">
          <a onClick={this.props.profileMenuClick}
          href="/account/generalSettings" >
            <div className="menu-icon general-settings-icon"></div>
              <div className="profile-menu-item-label">{_.get(this.props.trans, 'profile.generalSettings', 'General settings')}</div>
          </a>
          <div className="clear"></div>
        </div>
        {!this.props.isWhiteLabel && (
          <div className="profile-menu-item">
              <a onClick={this.props.profileMenuClick}
                 href="/account/paymentSettings">
                  <div className="menu-icon payment-settings-icon"></div>
                  <div className="profile-menu-item-label">{_.get(this.props.trans, 'profile.paymentSettings', 'Payment Settings')}</div>
              </a>
              <div className="clear"></div>
          </div>
        )}
        <div className="profile-menu-item">
          <a onClick={this.props.profileMenuClick}
                href="https://www.iubenda.com/terms-and-conditions/82572254"
                target="_blank" rel="noopener noreferrer">
              <div className="menu-icon terms-conditions-icon"></div>
              <div className="profile-menu-item-label">{_.get(this.props.trans,'profile.termsConditions', 'Terms ans Conditions')}</div>
          </a>
          <div className="clear"></div>
        </div>
        <div className="profile-menu-item">
          <a onClick={this.props.profileMenuClick}
                href="https://join.workero.com/gdpr-form"
                target="_blank" rel="noopener noreferrer">
              <div className="menu-icon terms-conditions-icon"></div>
              <div className="profile-menu-item-label">{_.get(this.props.trans, 'profile.gdpr', 'Exercise your GDPR rights')}</div>
          </a>
          <div className="clear"></div>
        </div>
          <div className="profile-menu-item">
              <a onClick={this.props.profileMenuClick} href={`${window.__env.public_site}/privacy`} target="_blank" rel="noopener noreferrer">
                  <div className="menu-icon terms-conditions-icon" />
                  <div className="profile-menu-item-label">{_.get(this.props.trans, 'profile.privacyPolicy', 'Privacy policy')}</div>
              </a>
              <div className="clear" />
          </div>
          <div className="profile-menu-item">
              <a onClick={this.props.profileMenuClick} href={`${window.__env.public_site}/cookies`} target="_blank" rel="noopener noreferrer">
                  <div className="menu-icon terms-conditions-icon" />
                  <div className="profile-menu-item-label">{_.get(this.props.trans, 'profile.cookiePolicy', 'Cookie policy')}</div>
              </a>
              <div className="clear" />
          </div>
        <div className="profile-menu-item">
          <Link onClick={() => { this.props.profileMenuClick();  this.logout();}}
          to="/">
            <div className="menu-icon logout-icon"></div>
              <div className="profile-menu-item-label">{_.get(this.props.trans, 'profile.logout', 'Logout')}</div>
          </Link>
          <div className="clear"></div>
        </div>
      </div>
    );
  }
}

export default withRouter(onClickOutside(ProfileMenu));
