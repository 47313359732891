import Immutable from 'immutable';
import {
  SET_FLOOR_PLANS,
  SET_SELECTED_FLOOR_INDEX,
  SET_BUILDING_HEROES,
  SET_FEATURES,
  SET_SELECTED_FEATURES,
} from '../../actions/whiteLabel/filter';

const initialState = Immutable.Map({
  floorPlans: [],
  selectedFloorIndex: 0,
  buildingHeroes: [],
  features: [],
  selectedFeatures: [0],
});

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_FLOOR_PLANS:
      return state.setIn(['floorPlans'], action.floorPlans);
    case SET_SELECTED_FLOOR_INDEX:
      return state.setIn(['selectedFloorIndex'], action.selectedFloorIndex);
    case SET_BUILDING_HEROES:
      return state.setIn(['buildingHeroes'], action.buildingHeroes);
    case SET_FEATURES:
      return state.setIn(['features'], action.features);
    case SET_SELECTED_FEATURES:
      return state.setIn(['selectedFeatures'], action.features);

    default:
      return state;
  }
};
