import { connect } from 'react-redux';
import HeroDetails from './HeroDetails';
import { fetchHeroDetails } from '../../../../actions/whiteLabel/hero-details';
import {setSelectedDate, setSelectedPin, setSelectedProductType} from '../../../../actions/whiteLabel/booking';
import { heroDetailsSelector, HeroDetailsLoadingSelector } from '../../../../selectors/whiteLabel/hero-details';

const mapStateToProps = state => {
  return {
    translations: state.translations,
    heroDetails: heroDetailsSelector(state),
    isLoading: HeroDetailsLoadingSelector(state),
  };
};

const mapDispatchProps = dispatch => {
  return {
    fetchHeroDetails: heroId => dispatch(fetchHeroDetails(heroId)),
    setSelectedPin: productId => dispatch(setSelectedPin(productId)),
    setSelectedDate: startDate => dispatch(setSelectedDate(startDate, startDate)),
    setSelectedProductType: productType => dispatch(setSelectedProductType(productType)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchProps
)(HeroDetails);
