import Immutable from 'immutable';
import {
  SET_COMMUNITY_POST_FORM_IS_LOADING,
  SET_COMMUNITY_POST_FORM_ERRORS,
  SET_COMMUNITY_UPLOAD_PROGRESS,
  SET_COMMUNITY_POST_ATTACHMENT_IS_LOADING
} from '../../actions/community/create-post';

const initialState = Immutable.Map({
  isLoading: false,
  isUploading: false,
  uploadedPercent: null,
  errors: {},
});

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_COMMUNITY_POST_FORM_IS_LOADING:
      return state.setIn(['isLoading'], action.isLoading);
    case SET_COMMUNITY_POST_ATTACHMENT_IS_LOADING:
      return state.setIn(['isUploading'], action.isUploading);
    case SET_COMMUNITY_UPLOAD_PROGRESS:
      return state.setIn(['uploadedPercent'], action.percent);
    case SET_COMMUNITY_POST_FORM_ERRORS:
      return state.setIn(['errors'], action.errors);
    default:
      return state;
  }
};
