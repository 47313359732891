/**
 * Handles async data loading
 * @class fetchMiddleware
 */

import moment from 'moment';
import {
  FETCH_PROFILE,
  SAVE_PROFILE,
  REQUEST_EXPORT_DATA,
  REQUEST_DELETE_DATA,
  FETCH_PERMISSIONS,
  SAVE_PERMISSION,
  FETCH_RESTRICTIONS
} from '../../actions/accountActions';
import { FETCH_OFFICES, fetchProgress, fetchAvailabilityProgress } from '../../actions/buttonActions';
import { ON_RESET_FILTERS } from '../../actions/filtersActions';
import { ON_LOCATION_CHANGE_SEARCH, ON_LOCATION_CHANGE } from '../../actions/map';
import { FEEDBACK_SEND } from '../../actions/feedbackActions';
import { GET_VERTICALS } from '../../actions/global';

import {
  FETCH_CONNECTIONS,
  FETCH_CONNECTION_CONNECTIONS,
  FETCH_NEARBY_CONNECTIONS,
  FETCH_RECOMMENDED_CONNECTIONS,
  FETCH_CONNECTION,
  REMOVE_CONNECTION,
  ADD_CONNECTION,
  TOTAL_AVAILABLE_CONNECTIONS,
} from '../../actions/connections';
import {
  FETCH_EVENTS_ALL,
  FETCH_EVENTS_NEARBY,
  FETCH_EVENTS_ACTIVE,
  FETCH_EVENT,
  UPDATE_HERO_EVENTS_ATTENDEES,
  ADD_HERO_TO_EVENT,
} from '../../actions/eventsActions';

import {
  FETCH_NEAREST_OFFICES,
  FETCH_RECENTLY_ADDED,
  FETCH_AVAILABLE_OFFICES,
  FETCH_COMPANIES,
  FETCH_FEATURED_OFFICES,
} from '../../actions/officesActions';
import {
  FETCH_OFFICE_DETAILS,
  FETCH_BOOKING_OPTIONS,
  FETCH_BOOKING_VALIDATE,
  ON_FINISH_BOOKING,
  FETCH_PAYMENT_PROFILES,
  CREATE_HERO_PAYMENT_PROFILE,
  UPDATE_HERO_PAYMENT_PROFILE,
  DELETE_HERO_PAYMENT_PROFILE,
  REDEEM_VOUCHER,
  FETCH_PAYMENT_PROFILE,
  FETCH_CHECK_AVAILABILITY,
  BOOKING_REQUEST,
  FETCH_PAYMENT_METHODS,
  SET_DEFAULT_PAYMENT_METHOD,
  DELETE_PAYMENT_METHOD,
  CREATE_PAYMENT_METHOD,
} from '../../actions/locationActions';

import { FETCH_BOOKING_DETAILS, FETCH_BOOKING_SUMMARY, CANCEL_BOOKING } from '../../actions/bookingActions';

import {
  FETCH_DIRECT_CHAT_CONVERSATIONS,
  FETCH_CONVERSATION_MESSAGES,
  FETCH_CHAT_CONNECTIONS,
  OPEN_CONVERSATION_AND_FETCH,
} from '../../actions/directChat';

import processResponse from '../utils/processResponse';

const DATE_FORMAT = 'MMM D, YYYY';

let messagesIsLoading = false;

const fetchMiddleware = store => next => action => {
  switch (action.type) {
    case FETCH_AVAILABLE_OFFICES:
    case FETCH_COMPANIES:
    case ON_LOCATION_CHANGE:
    case ON_RESET_FILTERS:
      if (action.filters !== undefined && (action.filters.filters !== undefined || action.filters.location !== undefined)) {
        const { url } = action;
        const postParams = {};
        const location = action.filters.location.toJS();

        if (ON_RESET_FILTERS !== action.type && action.filters.filters !== undefined) {
          const filters = action.filters.filters.toJS();

          // amenities
          if (filters.applied.amenities && filters.applied.amenities.length > 0) {
            postParams.amenities = filters.applied.amenities;
          }

          // services
          if (filters.applied.services && filters.applied.services.length > 0) {
            postParams.services = filters.applied.services;
          }

          // industries
          if (filters.applied.industries && filters.applied.industries.length > 0) {
            postParams.industries = filters.applied.industries;
          }

          // officeTypes - only the first one for now as we render a radio input group
          if (filters.applied.officeTypes && filters.applied.officeTypes.length > 0) {
            postParams.officeTypes = filters.applied.officeTypes;
          }
          // health measures
          if (filters.applied.healthMeasures && filters.applied.healthMeasures.length > 0) {
            postParams.healthMeasures = filters.applied.healthMeasures;
          }
          // premium role
          if (filters.applied.premiumSupplier) {
            postParams.premiumSupplier = filters.applied.premiumSupplier;
          }
          // radius
          if (typeof location.radius !== 'undefined' && location.radius > 0) {
            postParams.radius = location.radius;
          }

          // capacity
          if (typeof filters.applied.capacity !== 'undefined' && filters.applied.capacity > 0) {
            postParams.seats = filters.applied.capacity;
          }

          // rating
          if (typeof filters.applied.rating !== 'undefined' && filters.applied.rating > 0) {
            postParams.minimumRating = filters.applied.rating;
          }
          // date
          if (
            typeof filters.applied.date !== 'undefined' &&
            typeof filters.applied.date.start !== 'undefined' &&
            typeof filters.applied.date.end !== 'undefined'
          ) {
            postParams.fromDate = moment.utc(filters.applied.date.start, DATE_FORMAT).unix();
            postParams.toDate = moment.utc(filters.applied.date.end, DATE_FORMAT).unix() + 24 * 60 * 60 - 1;
          }
        }
        // radius
        if (typeof location.radius !== 'undefined' && location.radius > 0) {
          postParams.radius = location.radius;
        }
        // location
        if (
          typeof location.center !== 'undefined' &&
          typeof location.center.lat !== 'undefined' &&
          typeof location.center.lng !== undefined
        ) {
          postParams.latitude = location.center.lat;
          postParams.longitude = location.center.lng;
        }
        fetch(url, {
          headers: { 'access-key': localStorage.getItem('access-key'), 'Content-Type': 'application/json' },
          body: JSON.stringify(postParams),
          method: 'POST',
        }).then(response => {
          processResponse(response).then(data => {
            action.data = data;
            next(action);
          });
        });
        break;
      }

      store.dispatch(fetchProgress());
      fetch(action.url, { headers: { 'access-key': localStorage.getItem('access-key') } }).then(response => {
        processResponse(response).then(data => {
          action.data = data;
          next(action);
        });
      });
      break;

    case FETCH_OFFICES:
    case FETCH_CONNECTION_CONNECTIONS:
    case FETCH_CHAT_CONNECTIONS:
      fetch(action.url, {
        headers: { 'access-key': localStorage.getItem('access-key'), 'Content-Type': 'application/json' },
        method: 'GET',
      }).then(response => {
        processResponse(response).then(data => {
          if (response.status === 422) {
            action.errors = data;
            action.data = null;
          } else {
            action.errors = null;
            action.data = data;
          }
          next(action);
        });
      });
      break;
    case FETCH_CONNECTIONS:
      const action_connection_url = `${action.url}?page=${action.page}`;
      fetch(action_connection_url, {
        headers: { 'access-key': localStorage.getItem('access-key'), 'Content-Type': 'application/json' },
        method: 'GET',
      }).then(response => {
        processResponse(response).then(data => {
          if (response.status === 422) {
            action.errors = data;
            action.data = null;
          } else {
            action.errors = null;
            action.data = data;
          }
          next(action);
        });
      });
      break;
    case FETCH_CONNECTION:
      fetch(action.url, {
        headers: { 'access-key': localStorage.getItem('access-key'), 'Content-Type': 'application/json' },
        method: 'GET',
      }).then(response => {
        processResponse(response).then(data => {
          if (response.status === 404) {
            action.errors = data;
            action.data = null;
          } else {
            action.errors = null;
            action.data = data;
          }
          next(action);
        });
      });
      break;
    case FETCH_NEARBY_CONNECTIONS:
    case FETCH_RECOMMENDED_CONNECTIONS:
      let emptyFilters = false;
      let action_url = `${action.url}?pageNumber=${action.pageNumber}&pageSize=${action.pageSize}&page=${action.pageNumber}`;

      if (action.filters !== undefined && action.filters.toJS().length > 0) {
        action_url += `&filters=${action.filters
          .toJS()
          .sort()
          .join()}`;
      } else {
        emptyFilters = true;
      }

      fetch(action_url, {
        headers: { 'access-key': localStorage.getItem('access-key'), 'Content-Type': 'application/json' },
        method: 'GET',
      }).then(response => {
        processResponse(response).then(data => {
          if (response.status === 422) {
            action.errors = data;
            action.data = null;
          } else if (emptyFilters === true) {
            action.errors = null;
            action.data = [];
          } else {
            action.errors = null;
            action.data = data;
          }
          next(action);
        });
      });
      break;
    case REMOVE_CONNECTION:
      const removeConnectionArgs = {};
      removeConnectionArgs.heroId = action.connectionId;
      fetch(action.url, {
        headers: { 'access-key': localStorage.getItem('access-key'), 'Content-Type': 'application/json' },
        body: JSON.stringify(removeConnectionArgs),
        method: 'POST',
      }).then(response => {
        processResponse(response).then(data => {
          if (response.status === 422) {
            action.errors = data;
            action.data = null;
          } else {
            action.errors = null;
            action.data = data;
          }
          next(action);
        });
      });
      break;
    case ADD_CONNECTION:
      fetch(action.url, {
        headers: { 'access-key': localStorage.getItem('access-key'), 'Content-Type': 'application/json' },
        body: JSON.stringify(action.connectionId),
        method: 'POST',
      }).then(response => {
        processResponse(response).then(data => {
          if (response.status === 422) {
            action.errors = data;
            action.data = null;
          } else {
            action.errors = null;
            action.data = data;
          }
          next(action);
        });
      });
      break;
    // case ON_RESET_FILTERS:
    case ON_LOCATION_CHANGE_SEARCH:
      store.dispatch(fetchProgress());
      fetch(action.url, {
        headers: { 'access-key': localStorage.getItem('access-key') },
        method: 'POST',
      }).then(response => {
        processResponse(response).then(data => {
          action.data = data;
          next(action);
        });
      });
      break;

    case FETCH_BOOKING_OPTIONS:
      const postParams = {};

      postParams.officeId = action.officeId;
      postParams.officeTypeId = action.officeTypeId;
      postParams.productId = action.productId;

      if (action.time_date.fromDate !== undefined) {
        postParams.fromDate = moment.utc(action.time_date.fromDate, DATE_FORMAT).unix();
      }

      if (action.time_date.toDate !== undefined) {
        postParams.toDate = moment.utc(action.time_date.toDate, DATE_FORMAT).unix();
      }

      if (action.time_date.weekDays !== undefined) {
        postParams.weekDays = action.time_date.weekDays;
      }

      if (action.time_date.hoursFrom !== undefined && action.time_date.hoursUntil !== undefined) {
        const hFrom = moment(moment().format('YYYY-MM-DD ') + action.time_date.hoursFrom, 'YYYY-MM-DD hh:mm A');
        const hUntil = moment(moment().format('YYYY-MM-DD ') + action.time_date.hoursUntil, 'YYYY-MM-DD hh:mm A');

        postParams.hours = {
          from: {
            hour: parseInt(hFrom.format('H'), 10),
            minute: parseInt(hFrom.format('m'), 10),
          },
          to: {
            hour: parseInt(hUntil.format('H'), 10),
            minute: parseInt(hUntil.format('m'), 10),
          },
        };
      }

      store.dispatch(fetchAvailabilityProgress(true));

      fetch(action.url, {
        headers: { 'access-key': localStorage.getItem('access-key'), 'Content-Type': 'application/json' },
        body: JSON.stringify(postParams),
        method: 'POST',
      }).then(response => {
        processResponse(response).then(data => {
          if (response.status === 422) {
            action.errors = data;
            action.data = null;
          } else {
            action.errors = null;
            action.data = data;
          }
          next(action);
        });
        store.dispatch(fetchAvailabilityProgress(false));
      });
      break;

    case FETCH_BOOKING_VALIDATE:
      const params = {};
      params.officeId = action.args.officeId;
      params.officeTypeId = action.args.officeTypeId;
      params.productId = action.args.productId;
      params.voucherCode = action.args.voucherCode;
      if (action.args.fromDate !== undefined) {
        params.fromDate = moment.utc(action.args.fromDate, DATE_FORMAT).unix();
      }

      if (action.args.toDate !== undefined) {
        params.toDate = moment.utc(action.args.toDate, DATE_FORMAT).unix();
      }

      if (action.args.weekDays !== undefined) {
        params.weekDays = action.args.weekDays;
      }

      if (action.args.paymentProfileId !== undefined) {
        params.paymentProfileId = action.args.paymentProfileId;
      }

      if (action.args.hoursFrom !== undefined && action.args.hoursUntil !== undefined) {
        const hFrom = moment(moment().format('YYYY-MM-DD ') + action.args.hoursFrom, 'YYYY-MM-DD hh:mm A');
        const hUntil = moment(moment().format('YYYY-MM-DD ') + action.args.hoursUntil, 'YYYY-MM-DD hh:mm A');

        params.hours = {
          from: {
            hour: parseInt(hFrom.format('H'), 10),
            minute: parseInt(hFrom.format('m'), 10),
          },
          to: {
            hour: parseInt(hUntil.format('H'), 10),
            minute: parseInt(hUntil.format('m'), 10),
          },
        };
      }

      params.servicesIds = action.args.servicesIds;
      params.equipmentIds = action.args.equipmentIds;
      params.useSpendingLimit = action.args.useSpendingLimit;

      store.dispatch(fetchProgress());

      fetch(action.url, {
        headers: { 'access-key': localStorage.getItem('access-key'), 'Content-Type': 'application/json' },
        body: JSON.stringify(params),
        method: 'POST',
      }).then(response => {
        processResponse(response).then(data => {
          if (response.status === 422) {
            action.errors = data;
            action.data = null;
          } else {
            action.errors = null;
            action.data = data;
          }
          next(action);
        });
      });

      break;

    case FETCH_PAYMENT_PROFILES:
      store.dispatch(fetchProgress());
      fetch(action.url, {
        headers: { 'access-key': localStorage.getItem('access-key') },
        method: 'GET',
      }).then(response => {
        processResponse(response).then(data => {
          if (response.status === 422) {
            action.errors = data;
            action.data = null;
          } else {
            action.errors = null;
            action.data = data;
          }
          next(action);
        });
      });
      break;
    case FETCH_PAYMENT_METHODS:
      store.dispatch(fetchProgress());
      fetch(action.url, {
        headers: { 'access-key': localStorage.getItem('access-key') },
        method: 'GET',
      }).then(response => {
        processResponse(response).then(data => {
          if (response.status === 422) {
            action.errors = data;
            action.data = null;
          } else {
            action.errors = null;
            action.data = data;
          }
          next(action);
        });
      });
      break;
    case FETCH_PAYMENT_PROFILE:
      store.dispatch(fetchProgress());
      fetch(action.url, {
        headers: { 'access-key': localStorage.getItem('access-key') },
        method: 'GET',
      }).then(response => {
        processResponse(response).then(data => {
          if (response.status === 422) {
            action.errors = data;
            action.data = null;
          } else {
            action.errors = null;
            action.data = data;
          }
          next(action);
        });
      });
      break;
    case CREATE_HERO_PAYMENT_PROFILE:
      const heroPaymentProfileParams = {};
      heroPaymentProfileParams.stripeToken = action.data.token;
      heroPaymentProfileParams.address = action.data.address !== undefined ? action.data.address : '';
      heroPaymentProfileParams.company = action.data.company;
      heroPaymentProfileParams.postal_code = action.data.postal_code;
      heroPaymentProfileParams.country = action.data.country;
      heroPaymentProfileParams.city = action.data.city;
      heroPaymentProfileParams.payment_method = action.data.payment_method;
      const { cb } = action;

      fetch(action.url, {
        headers: { 'access-key': localStorage.getItem('access-key'), 'Content-Type': 'application/json' },
        body: JSON.stringify(heroPaymentProfileParams),
        method: 'POST',
      }).then(response => {
        processResponse(response).then(data => {
          if (response.status === 422) {
            action.errors = data;
            action.data = data;
          } else {
            action.errors = null;
            action.data = data;
          }
          if (typeof cb === 'function') {
            cb(action.data);
          }
          next(action);
        });
      });
      break;
    case CREATE_PAYMENT_METHOD:
      const callback = action.cb;
      fetch(action.url, {
        headers: { 'access-key': localStorage.getItem('access-key'), 'Content-Type': 'application/json' },
        body: JSON.stringify(action.data),
        method: 'POST',
      }).then(response => {
        processResponse(response).then(data => {
          if (response.status === 422) {
            action.errors = data;
            action.data = null;
          } else {
            action.errors = null;
            action.data = data;
          }
          if (typeof callback === 'function') {
            callback(data);
          }
          next(action);
        });
      });
      break;
    case UPDATE_HERO_PAYMENT_PROFILE:
      fetch(`${action.url}/${action.data.paymentProfileId}`, {
        headers: { 'access-key': localStorage.getItem('access-key'), 'Content-Type': 'application/json' },
        body: JSON.stringify(action.data.paymentProfile),
        method: 'POST',
      }).then(response => {
        processResponse(response).then(data => {
          if (response.status === 422) {
            action.errors = data;
            action.data = null;
          } else {
            action.errors = null;
            action.data = data;
          }
          next(action);
        });
      });
      break;
    case DELETE_HERO_PAYMENT_PROFILE:
      fetch(action.url, {
        headers: { 'access-key': localStorage.getItem('access-key'), 'Content-Type': 'application/json' },
        method: 'DELETE',
      }).then(response => {
        processResponse(response).then(data => {
          if (response.status === 422) {
            action.errors = data;
            action.data = null;
            const { cb } = action;
            if (typeof cb === 'function') {
              cb();
            }
          } else {
            action.errors = null;
            action.data = data;
          }
          next(action);
        });
      });
      break;

    case ON_FINISH_BOOKING:
      const bookingParams = {};

      bookingParams.officeTypeId = action.args.officeTypeId;
      bookingParams.productId = action.args.productId;
      bookingParams.paymentMethodId = action.args.paymentMethodId;
      bookingParams.servicesIds = action.args.servicesIds;
      bookingParams.equipmentIds = action.args.equipmentIds;
      bookingParams.voucherCode = action.args.voucherCode;
      bookingParams.useSpendingLimit = action.args.useSpendingLimit;
      bookingParams.title = action.args.meetingRoomTitle;
      bookingParams.additionalComment = action.args.meetingRoomComment;

      if (action.args.fromDate !== undefined) {
        bookingParams.fromDate = moment.utc(action.args.fromDate, DATE_FORMAT).unix();
      }

      if (action.args.toDate !== undefined) {
        bookingParams.toDate = moment.utc(action.args.toDate, DATE_FORMAT).unix();
      }

      bookingParams.weekDays = action.args.weekDays;

      if (action.args.hoursFrom !== undefined && action.args.hoursUntil !== undefined) {
        const hFrom = moment(moment().format('YYYY-MM-DD ') + action.args.hoursFrom, 'YYYY-MM-DD hh:mm A');
        const hUntil = moment(moment().format('YYYY-MM-DD ') + action.args.hoursUntil, 'YYYY-MM-DD hh:mm A');

        bookingParams.hours = {
          from: {
            hour: parseInt(hFrom.format('H'), 10),
            minute: parseInt(hFrom.format('m'), 10),
          },
          to: {
            hour: parseInt(hUntil.format('H'), 10),
            minute: parseInt(hUntil.format('m'), 10),
          },
        };
      }

      if (action.args.eventRequestDetails) {
        bookingParams.eventRequestDetails = action.args.eventRequestDetails;
      }

      store.dispatch(fetchProgress());

      fetch(action.url, {
        headers: { 'access-key': localStorage.getItem('access-key'), 'Content-Type': 'application/json' },
        body: JSON.stringify(bookingParams),
        method: 'POST',
      }).then(response => {
        processResponse(response).then(data => {
          if (response.status === 422) {
            action.errors = data;
            action.data = null;
          } else {
            action.errors = null;
            action.data = data;
          }
          next(action);
        });
      });

      break;

    case FETCH_OFFICE_DETAILS:
      fetch(action.url, { headers: { 'access-key': localStorage.getItem('access-key') } }).then(response => {
        processResponse(response).then(data => {
          if (response.status === 422) {
            // window.location = '/booking';
          } else {
            action.data = data;
          }
          next(action);
        });
      });
      break;

    case FETCH_BOOKING_DETAILS:
      fetch(action.url, { headers: { 'access-key': localStorage.getItem('access-key') } }).then(response => {
        processResponse(response).then(data => {
          action.data = data;
          next(action);
        });
      });
      break;

    case CANCEL_BOOKING:
      fetch(`${action.url}/${action.id}`, {
        headers: { 'access-key': localStorage.getItem('access-key'), 'Content-Type': 'application/json' },
        method: 'POST',
      }).then(response => {
        processResponse(response).then(data => {
          action.data = data;
          next(action);
        });
      });
      break;

    case REQUEST_EXPORT_DATA:
      fetch(action.url, {
        headers: { 'access-key': localStorage.getItem('access-key'), 'Content-Type': 'application/json' },
        method: 'POST',
      }).then(response => {
        processResponse(response).then(data => {
          action.data = data;
          next(action);
        });
      });
      break;

    case REQUEST_DELETE_DATA:
      fetch(action.url, {
        headers: { 'access-key': localStorage.getItem('access-key'), 'Content-Type': 'application/json' },
        method: 'POST',
      }).then(response => {
        processResponse(response).then(data => {
          action.data = data;
          next(action);
        });
      });
      break;

    case FETCH_BOOKING_SUMMARY:
      fetch(action.url, { headers: { 'access-key': localStorage.getItem('access-key') } }).then(response => {
        processResponse(response).then(data => {
          action.data = data;
          next(action);
        });
      });
      break;

    case FETCH_PROFILE:
      fetch(action.url, { headers: { 'access-key': localStorage.getItem('access-key') } }).then(response => {
        processResponse(response).then(data => {
          action.data = data;

          next(action);
        });
      });
      break;

    case FETCH_NEAREST_OFFICES:
      const filters = {};
      filters.latitude = action.filters.mapData.toJS().center.lat;
      filters.longitude = action.filters.mapData.toJS().center.lng;
      fetch(`${action.url}?latitude=${filters.latitude}&longitude=${filters.longitude}`, {
        headers: { 'access-key': localStorage.getItem('access-key') },
      }).then(response => {
        processResponse(response).then(data => {
          action.data = data;
          next(action);
        });
      });
      break;

    case FETCH_RECENTLY_ADDED:
      fetch(action.url, { headers: { 'access-key': localStorage.getItem('access-key') } }).then(response => {
        processResponse(response).then(data => {
          action.data = data;
          next(action);
        });
      });
      break;
    case FETCH_FEATURED_OFFICES:
      fetch(action.url, { headers: { 'access-key': localStorage.getItem('access-key') } }).then(response => {
        processResponse(response).then(data => {
          action.data = data;
          next(action);
        });
      });
      break;

    case SAVE_PROFILE:
      const heroProfileParams = {};
      heroProfileParams.phoneNumber = action.args.phoneNumber;
      heroProfileParams.industryId = action.args.industryId;
      heroProfileParams.skillIds = action.args.skillIds;
      heroProfileParams.interests = action.args.interests;
      heroProfileParams.interests.skillIds.map((skillId, index) => {
        if (skillId === 'other') {
          heroProfileParams.interests.skillIds.splice(index, 1);
        }
        return null;
      });
      heroProfileParams.expectations = action.args.expectations;
      heroProfileParams.desiredEnvironment = action.args.desiredEnvironment;
      heroProfileParams.otherSkill = action.args.otherSkill;
      heroProfileParams.profileQuestion = action.args.profileQuestion;
      heroProfileParams.communicationPersonalEmail = action.args.communicationPersonalEmail;
      heroProfileParams.communicationBusinessEmail = action.args.communicationBusinessEmail;
      heroProfileParams.businessEmail = action.args.businessEmail;
      fetch(action.url, {
        headers: { 'access-key': localStorage.getItem('access-key'), 'Content-Type': 'application/json' },
        body: JSON.stringify(heroProfileParams),
        method: 'POST',
      }).then(response => {
        processResponse(response).then(data => {
          action.data = data;
          next(action);
        });
      });
      break;

    case REDEEM_VOUCHER:
      if (action.code !== null && action.args !== null) {
        const reedemVoucherParams = {};
        reedemVoucherParams.officeTypeId = action.args.officeTypeId;
        reedemVoucherParams.productId = action.args.productId;
        reedemVoucherParams.totalPriceVatIncluded = action.args.totalPriceVatIncluded;

        const redeemVoucerUrl = `${action.url}/${action.code}`;
        fetch(redeemVoucerUrl, {
          headers: { 'access-key': localStorage.getItem('access-key'), 'Content-Type': 'application/json' },
          body: JSON.stringify(reedemVoucherParams),
          method: 'POST',
        }).then(response => {
          processResponse(response).then(data => {
            action.data = data;
            next(action);
          });
        });
        break;
      } else {
        action.data = null;
        next(action);
        break;
      }

    case FETCH_EVENTS_ALL:
    case FETCH_EVENTS_ACTIVE:
      fetch(action.url, {
        headers: { 'access-key': localStorage.getItem('access-key'), 'Content-Type': 'application/json' },
        method: 'GET',
      }).then(response => {
        processResponse(response).then(data => {
          if (response.status === 404) {
            action.errors = data;
            action.data = null;
          } else {
            action.errors = null;
            action.data = data;
          }
          next(action);
        });
      });
      break;

    case FETCH_EVENTS_NEARBY:
      fetch(`${action.url}?latitude=${action.coord.latitude}&longitude=${action.coord.longitude}`, {
        headers: { 'access-key': localStorage.getItem('access-key'), 'Content-Type': 'application/json' },
        method: 'GET',
      }).then(response => {
        processResponse(response).then(data => {
          action.data = data;
          next(action);
        });
      });
      break;

    case FETCH_EVENT:
      fetch(`${action.url}/${action.eventId}`, {
        headers: { 'access-key': localStorage.getItem('access-key'), 'Content-Type': 'application/json' },
        method: 'GET',
      }).then(response => {
        processResponse(response).then(data => {
          action.data = data;
          next(action);
        });
      });

      break;

    /**
     * ! Deprecated
     * We sync on eventbrite event directly from booking app
     *
     * See: #4405
     */
    case UPDATE_HERO_EVENTS_ATTENDEES:
      fetch(action.url, {
        headers: { 'access-key': localStorage.getItem('access-key'), 'Content-Type': 'application/json' },
        method: 'GET',
      }).then(response => {
        processResponse(response).then(data => {
          action.data = data;
          next(action);
        });
      });

      break;

    case FETCH_DIRECT_CHAT_CONVERSATIONS:
      fetch(action.url, {
        headers: { 'access-key': localStorage.getItem('access-key'), 'Content-Type': 'application/json' },
        method: 'GET',
      }).then(response => {
        processResponse(response).then(data => {
          action.data = data;
          next(action);
        });
      });
      break;

    case OPEN_CONVERSATION_AND_FETCH:
    case FETCH_CONVERSATION_MESSAGES:
      let fetchUrl = action.url;
      if (action.startEpoch !== undefined) {
        fetchUrl += `?startEpoch=${action.startEpoch}`;
      }
      if (messagesIsLoading) {
        return;
      }
      messagesIsLoading = true;
      fetch(fetchUrl, {
        headers: { 'access-key': localStorage.getItem('access-key'), 'Content-Type': 'application/json' },
        method: 'GET',
      }).then(response => {
        processResponse(response).then(data => {
          action.data = data;
          next(action);
          messagesIsLoading = false;
        });
      });
      break;

    case FEEDBACK_SEND:
      const saveFeedback = {};
      saveFeedback.feedback = action.feedbackContent !== undefined ? action.feedbackContent : '';
      fetch(action.url, {
        headers: { 'access-key': localStorage.getItem('access-key'), 'Content-Type': 'application/json' },
        body: JSON.stringify(saveFeedback),
        method: 'POST',
      }).then(response => {
        processResponse(response).then(data => {
          action.data = data;
          next(action);
        });
      });
      break;

    case FETCH_CHECK_AVAILABILITY:
      fetch(`${action.url}?officeId=${action.officeId}&date=${action.date}&officeType=${action.officeType}`, {
        headers: { 'access-key': localStorage.getItem('access-key'), 'Content-Type': 'application/json' },
        method: 'GET',
      }).then(response => {
        const resp = processResponse(response);
        if (resp) {
          resp.then(data => next({ ...action, data }));
        }
      });
      break;
    case ADD_HERO_TO_EVENT:
      fetch(action.url, {
        headers: { 'access-key': localStorage.getItem('access-key'), 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify({ eventId: action.eventId }),
      });
      break;
    case TOTAL_AVAILABLE_CONNECTIONS:
      fetch(action.url, {
        headers: { 'access-key': localStorage.getItem('access-key'), 'Content-Type': 'application/json' },
        method: 'GET',
      }).then(response => processResponse(response).then(data => next({ ...action, data })));
      break;
    case BOOKING_REQUEST:
      const bookingRequest = {};
      bookingRequest.request_type = action.args.type;
      switch (action.args.type) {
        case 'schedule_a_visit':
          bookingRequest.date = action.args.date;
          bookingRequest.hour = action.args.hour;
          bookingRequest.product_id = action.args.product_id;
          bookingRequest.product_type = action.args.product_type;
          break;
        case 'contact_us':
          bookingRequest.product_name = action.args.contactLocation;
          bookingRequest.booking_period_in_months = action.args.contactPeriod;
          bookingRequest.office_size = action.args.contactOfficeSize;
          bookingRequest.number_of_people = action.args.contactNoOfPeople;
          bookingRequest.services = action.args.contactParking === true ? [{ name: 'Parking' }] : [];
          bookingRequest.details = action.args.contactDetails;
          bookingRequest.product_id = action.args.product_id;
          bookingRequest.product_type = action.args.product_type;
          break;
        case 'book_difficulty':
          bookingRequest.phone = action.args.phone;
          bookingRequest.request_call_time = action.args.request_call_time;
          bookingRequest.hour = action.args.hour;
          bookingRequest.office_id = action.args.office_id;
          bookingRequest.product_id = action.args.product_id;
          bookingRequest.product_name = action.args.product_name;
          bookingRequest.product_type = action.args.product_type;
          break;
        default:
          break;
      }

      fetch(action.url, {
        headers: { 'access-key': localStorage.getItem('access-key'), 'Content-Type': 'application/json' },
        body: JSON.stringify(bookingRequest),
        method: 'POST',
      }).then(response => {
        processResponse(response).then(data => {
          if (response.status === 422) {
            action.errors = data;
            action.data = null;
          } else {
            action.errors = null;
            action.data = data;
          }
          next(action);
        });
      });
      break;
    case FETCH_PERMISSIONS:
      fetch(action.url, { headers: { 'access-key': localStorage.getItem('access-key') } }).then(response => {
        processResponse(response).then(data => {
          action.data = data;
          next(action);
        });
      });
      break;
    case FETCH_RESTRICTIONS:
      fetch(action.url, { headers: { 'access-key': localStorage.getItem('access-key') } }).then(response => {
        processResponse(response).then(data => {
          action.data = data;
          next(action);
        });
      });
      break;
    case SAVE_PERMISSION:
      fetch(action.url, {
        headers: { 'access-key': localStorage.getItem('access-key'), 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify({ permission: action.permission }),
      }).then(response => {
        processResponse(response).then(data => {
          if (response.status === 200) {
            action.errors = null;
            action.data = data;
          } else {
            action.errors = data;
            action.data = null;
          }
          next(action);
        });
      });
      break;
    case GET_VERTICALS:
      fetch(action.url, {
        headers: { 'access-key': localStorage.getItem('access-key'), 'Content-Type': 'application/json' },
        method: 'GET',
      }).then(response => {
        processResponse(response).then(data => {
          if (response.status === 200) {
            action.errors = null;
            action.data = data.data;
          } else {
            action.errors = data;
            action.data = null;
          }
          next(action);
        });
      });
      break;

    case SET_DEFAULT_PAYMENT_METHOD:
      fetch(action.url, {
        headers: { 'access-key': localStorage.getItem('access-key'), 'Content-Type': 'application/json' },
        method: 'POST',
      }).then(response => {
        processResponse(response).then(data => {
          if (response.status === 422) {
            action.errors = data;
            action.data = null;
          } else {
            action.errors = null;
            action.data = data;
          }
          next(action);
        });
      });
      break;

    case DELETE_PAYMENT_METHOD:
      fetch(action.url, {
        headers: { 'access-key': localStorage.getItem('access-key'), 'Content-Type': 'application/json' },
        method: 'DELETE',
      }).then(response => {
        processResponse(response).then(data => {
          if (response.status === 422) {
            action.errors = data;
            action.data = null;
            const { callback } = action;
            if (typeof callback === 'function') {
              callback();
            }
          } else {
            action.errors = null;
            action.data = data;
          }
          next(action);
        });
      });
      break;

    default:
      next(action);
  }
};

export default fetchMiddleware;
