/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import LoadingGif from '../../../assets/images/loader.gif';

const buildLoader = borderFlag => (
  <div className={classnames('dashboard-loader__container', { 'dashboard-loader__container--with-border': borderFlag })}>
    <img className="dashboard-loader__image" src={LoadingGif} alt="Loading" />
  </div>
);

const addLoader = (WrappedComponent, borderFlag = false) => wrappedComponentProps => {
  const isLoading = _.get(wrappedComponentProps, 'isLoading', false);
  return (
    <>
      {isLoading ? buildLoader(borderFlag) : null}
      <span className={classnames('dashboard-loader-content', { 'dashboard-loader-content--hidden': isLoading })}>
        <WrappedComponent {...wrappedComponentProps} />
      </span>
    </>
  );
};

export default addLoader;
