import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Scrollbars } from 'react-custom-scrollbars';
import onClickOutside from 'react-onclickoutside';

import './css/dropdown.scss';

class Dropdown extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      expanded: false,
      selected: this.props.selected
    }
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (this.state.selected !== props.selected) {
      this.setState({ selected: props.selected });
    }
  }

  handleClickOutside = (e) => {
    this.setState({
      expanded: false
    });
  }

  toggleExpand = () => {
    this.setState({
      expanded: !this.state.expanded
    });
  }

  onSelect = (selected) => {
    this.setState({
      selected,
      expanded: false
    });

    this.props.onChange(selected);
  }

  render() {
    const { placeHolder, className, options } = this.props;
    const { expanded, selected } = this.state;

    return (
      <div className={classnames({'expanded': expanded}, className, 'dropdown')}>
        <div className="input" onClick={this.toggleExpand}>{selected || placeHolder}</div>
        <div className="container">
          <Scrollbars className="styleScroll">
            {options.map((option, index) => (<div key={index} className="option" onClick={() => {this.onSelect(option)}}>{option}</div>))}
          </Scrollbars>
        </div>
      </div>
    );
  }
};

Dropdown.propTypes = {
  placeHolder: PropTypes.string,
  selected: PropTypes.string,
  options: PropTypes.array,
  onChange: PropTypes.func,
  className: PropTypes.string
};

Dropdown.defaultProps = {
  selected: null,
  options: []
}
export default onClickOutside(Dropdown);
