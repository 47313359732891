import { connect } from 'react-redux';
import _ from 'lodash';
import { heroProfileSelector, heroProfileIsLoadingSelector, heroProfileErrorsSelector } from '../../../../selectors/hero-profile';
import { fetchHeroProfile, setHeroProfile, setHeroProfileErrors } from '../../../../actions/heroProfile';
import { saveHeroProfile } from '../../../../actions/whiteLabel/profile';
import Profile from './Profile';
import { translations } from '../../../../selectors/translations';
import AccountProfile from './AccountProfile';

const mapStateToPropsForEdit = state => ({
  heroProfile: heroProfileSelector(state).toJS(),
  isLoading: heroProfileIsLoadingSelector(state),
  errors: heroProfileErrorsSelector(state),
  translations: translations(state),
});

const mapDispatchToPropsForEdit = dispatch => ({
  fetchHeroProfile: () => dispatch(fetchHeroProfile()),
  saveHeroProfile: args => dispatch(saveHeroProfile(args)),
  setHeroProfile: heroProfile => dispatch(setHeroProfile(heroProfile)),
  setHeroProfileErrors: errors => dispatch(setHeroProfileErrors(errors)),
});

const mapStateToPropsForView = state => ({
  profileDetails: state.account.get('profile'),
  translations: state.translations,
});

const mapDispatchPropsForView = dispatch => ({
  fetchProfile: () => {
    dispatch(fetchHeroProfile());
  },
});

const ProfileView = connect(mapStateToPropsForEdit, mapDispatchToPropsForEdit)(Profile);
const AccountProfileView = connect(mapStateToPropsForView, mapDispatchPropsForView)(AccountProfile);

export { ProfileView as ProfileWhitelabelView, AccountProfileView as AccountProfileWhitelabelView };
