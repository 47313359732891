import React, {Component} from 'react';
import {dateDifferenceToDhm} from './../../utils/dateUtils';
import {dateDifferenceToDay} from './../../utils/dateUtils';

class TimeAgo extends Component {

  render () {
    
    if(this.props.epoch === undefined || this.props.epoch === null)
      return null;

    if(this.props.method === 'dateDifferenceToDay')
    {
      return(
        <span>
          {`${dateDifferenceToDay(this.props.epoch * 1000, (+new Date()), this.props.trans)}`}
        </span>
      )
    }

    return(
      <span>
        {`${dateDifferenceToDhm(this.props.epoch * 1000, this.props.now.getTime(), this.props.trans)}`}
      </span>
    )
  };
}

export default TimeAgo;
