import classnames from 'classnames';
import React from 'react';

const InputField = props => {
  const { label, name, value, placeholder, type, handleInputChange, error, className, isDisabled } = props;

  return (
    <div className="profile-form__field">
      {label ? <div className="profile-form__label">{label}</div> : null}
      <input
        key={label}
        className={classnames(
          'profile-form__input profile-form__text',
          { 'profile-form__input-value': value },
          { 'profile-form__input-error': error },
          `${className || ''}`
        )}
        type={type}
        required
        name={name}
        defaultValue={value || ''}
        placeholder={placeholder}
        onChange={event => {
          event.preventDefault();
          handleInputChange(name, event.target.value);
        }}
        disabled={isDisabled}
      />
      {error ? <div className="auth-form__error-message">{error}</div> : null}
    </div>
  );
};

export default InputField;
