import api from '../../components/openAuth/utils/api';

export const signup = data => dispatch => {
  return new Promise((resolve, reject) => {
    api
      .post(`/alternative/register`, data)
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        reject(error);
      });
  });
};

export default signup;
