import { connect } from 'react-redux';

import Location from '../../components/location/location';

import {
  fetchOfficeDetails,
  onFinishBooking,
  beforeFetchOfficeDetails,
  redeemVoucher,
  clearVoucher,
  openBookingOptions,
  closeBookingOptions,
  setSelectedProduct,
  resetBooking,
  setSelectedRoom,
  fetchBookingOptions,
  bookingRequest,
  openSidePopup,
  closeSidePopup,
  openHeroSidePopup,
  closeHeroSidePopup,
  toggleDateExpanded,
  onChangeSection,

} from '../../actions/locationActions';
import { fetchAmenities, fetchServices, fetchHouseRules, fetchOfficeTypes } from '../../actions/generalDataActions';

const mapStateToProps = state => {
  return {
    amenities: state.location.get('amenities'),
    bookingOptionsOpened: state.location.get('bookingOptionsOpened'),
    bookingRequestResponse: state.location.get('bookingRequestResponse'),
    bookingRequestResponseErrors: state.location.get('bookingRequestResponseErrors'),
    bookingSection: state.addBooking.get('bookingSection'),
    datePickerExpanded: state.location.get('datePickerExpanded'),
    heroPopupErrorOpened: state.location.get('heroPopupErrorOpened'),
    houseRules: state.location.get('houseRules'),
    officeDetails: state.location.get('details'),
    officeTypesData: state.location.get('officeTypesData'),
    services: state.location.get('services'),
    selectedOptions: state.addBooking.get('selectedOptions'),
    bookingValidate: state.addBooking.get('bookingValidate'),
    selectedRoom: state.addBooking.get('selectedRoom'),
    selectedProduct: state.addBooking.get('selectedProduct'),
    sidePopupOpened: state.location.get('sidePopupOpened'),
    translations: state.translations,
  };
};

const mapDispatchProps = dispatch => {
  return {
    fetchBookingOptions: (officeId, officeTypeId, productId, time_date) => {
      dispatch(fetchBookingOptions(officeId, officeTypeId, productId, time_date));
    },
    resetBooking: () => {
      dispatch(resetBooking());
    },
    setSelectedProduct: product => {
      dispatch(setSelectedProduct(product));
    },
    setSelectedRoom: room => {
      dispatch(setSelectedRoom(room));
    },
    openBookingOptions: () => {
      dispatch(openBookingOptions());
    },
    closeBookingOptions: () => {
      dispatch(closeBookingOptions());
    },
    beforeFetchOfficeDetails: id => {
      dispatch(beforeFetchOfficeDetails(id));
    },
    fetchOfficeDetails: id => {
      dispatch(fetchOfficeDetails(id));
    },
    fetchAmenities: () => {
      dispatch(fetchAmenities());
    },
    fetchServices: () => {
      dispatch(fetchServices());
    },
    fetchOfficeTypes: () => {
      dispatch(fetchOfficeTypes());
    },
    fetchHouseRules: () => {
      dispatch(fetchHouseRules());
    },
    onFinishBooking: args => {
      dispatch(onFinishBooking(args));
    },
    redeemVoucher: (code, args) => {
      dispatch(redeemVoucher(code, args));
    },
    clearVoucher: () => {
      dispatch(clearVoucher());
    },
    bookingRequest: args => {
      dispatch(bookingRequest(args));
    },
    openSidePopup: () => {
      dispatch(openSidePopup());
    },
    closeSidePopup: () => {
      dispatch(closeSidePopup());
    },
    openHeroSidePopup: () => {
      dispatch(openHeroSidePopup());
    },
    closeHeroSidePopup: () => {
      dispatch(closeHeroSidePopup());
    },
    toggleDateExpanded: expanded => {
      dispatch(toggleDateExpanded(expanded));
    },
    onChangeSection: bookingSection => dispatch(onChangeSection(bookingSection)),
  };
};

const LocationView = connect(mapStateToProps, mapDispatchProps)(Location);

export default LocationView;
