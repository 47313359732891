import * as React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import _ from 'lodash';
import CommunityPageHeader from './components/communityPage-header';
import PostCard from './components/community-post';
import CommunityInfo from './components/info';
import InfoToast from './common/info-toast/InfoToast';
import LoadingGif from '../../assets/images/loader.gif';

class CommunitySinglePostPage extends React.Component {
  componentDidMount() {
    const { match, history } = this.props;
    const urlSlug = _.get(match.params, 'slug', '');
    const postId = _.get(match.params, 'postId', '');
    this.historyListener = history.listen((location, action) => {
      const urlSlug = location.pathname.split('/')[2];
      const postId = location.pathname.split('/')[3];
      if (urlSlug && postId) {
        this.fetchCommuntyByPost(urlSlug, postId);
      }
    });
    this.fetchCommuntyByPost(urlSlug, postId);
  }

  componentWillUnmount() {
    this.historyListener();
  }

  fetchCommuntyByPost(urlSlug, postId) {
    const { history, showCommunityPageToast, fetchCommunity, showCommunitiesPageToast } = this.props;
    fetchCommunity(urlSlug, postId)
      .then()
      .catch(response => {
        if (response === 'postNotFound') {
          showCommunityPageToast('toast.deletedPost');
          history.replace({
            pathname: `/community/${urlSlug}`,
          });
        } else if (response === 'communityNotFound') {
          showCommunitiesPageToast('toast.nonExistingCommunity');
          history.replace({
            pathname: '/communities',
          });
        } else if (response === 'nonExistingMember') {
          showCommunitiesPageToast('toast.notCommunityMember');
          history.replace({
            pathname: '/communities',
          });
        }
      });
  }

  render() {
    const { translations, history, match, communityToastMessage, isLoading } = this.props;
    const urlSlug = _.get(match.params, 'slug', '');
    if (isLoading) {
      return (
        <div className="community-loader__container">
          <div className="community-loader__text">{_.get(translations, 'communityPage.loadingCommunity', 'Loading your community...')}</div>
          <img className="community-loader__image" src={LoadingGif} alt="Loading" />
        </div>
      );
    }
    return (
      <Scrollbars>
        {communityToastMessage && <InfoToast message={communityToastMessage} />}
        <div className="community__container">
          <div className="community-grid">
            <div className="community-grid__column community-grid__column--left background-left">
              <button
                type="button"
                onClick={() => {
                  history.replace(`/community/${urlSlug}`);
                }}
                className="navigation__back-to-button community-page__back-to-button"
              >
                {translations.backToCommunity}
              </button>
              <div>
                <CommunityPageHeader isSinglePostPage />
                <PostCard translations={translations} isSinglePostPage />
              </div>
            </div>
            <div className="community-grid__column community-grid__column--right">
              <CommunityInfo translations={translations} />
            </div>
          </div>
        </div>
      </Scrollbars>
    );
  }
}

export default CommunitySinglePostPage;
