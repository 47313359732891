import _ from 'lodash';
import api from '../../components/community/utils/api';

export const SET_LEAVE_COMMUNITY_ERRORS = 'SET_LEAVE_COMMUNITY_ERRORS';

export const setLeaveCommunityErrors = leaveCommunityErrors => ({
  type: SET_LEAVE_COMMUNITY_ERRORS,
  leaveCommunityErrors,
});

export const leaveCommunity = communityId => dispatch => {
  return new Promise(resolve => {
    api
      .get(`/leave/${communityId}`)
      .then(() => {
        resolve(true);
      })
      .catch(({ response }) => {
        dispatch(setLeaveCommunityErrors(_.get(response, 'data.error')));
        resolve(false);
      });
  });
};
