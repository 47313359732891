/**
 * Renders the main Connections view
 * @module Connections
 * @class Connections
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { withRouter } from 'react-router-dom';
import {openConversationAndFetch, openConversation, fetchDirectChatConversations} from './../../actions/directChat.js';
import { withLastLocation } from 'react-router-last-location';

import {connect} from 'react-redux';
import {Scrollbars} from 'react-custom-scrollbars';
import PageHeader from './../pageHeader/pageHeader';
import ProfileBox from './../profileBox/profileBox';

import './css/index.scss';
import { fetchConnectionConnections, totalAvailableConnections } from "../../actions/connections";
import _ from 'lodash';
class Connections extends Component {
    props;
    context;
    static contextTypes = {
        router: PropTypes.object.isRequired
    };

    _onPageViewSwitch = (view) => {
        this.setState({view});
    }

    constructor() {
        super();
        // Bind the this context to the handler function
        this.handler = this.handler.bind(this);
        this.changePage = this.changePage.bind(this);
        this.scrollToTop = this.scrollToTop.bind(this);
        this.state = {
            view: 'grid',
            other_connections: true,
            showSuggestions: false,
            invitationSent: false,
            firstTime: true,
            firstName: '',
            lastName: '',
            page: 1,
            hasMore: true,
        }
    }

    onModalClose = () => {
      this.setState({invitationSent: false});
    }

    componentDidMount() {
        if(this.props.match.params.id !== undefined) {
            this.props.fetchConnectionConnections(this.props.match.params.id);
            this.props.fetchConnection(this.props.match.params.id);
        } else {
            const { page } = this.state;
            this.props.fetchConnections(page);
        }
        this.props.fetchProfile();
        this.props.totalAvailableConnections();
        this.setState({other_connections: false});
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({
          disabled: nextProps.disabled,
          selected: nextProps.selectedItems
        });
    }

    showMessageModal = (id, firstName) => {
        this.props.showMessageModal({
            id,
            firstName
        });
    };

    changePage() {
        const { page } = this.state;
        this.props.fetchConnections((page + 1));
        this.setState({page: page + 1})
    }

    scrollToTop() {
        this.refs.scrollbars.scrollToTop();
    }


    /**
     * Passes trough all connections and returns a list of connections to render
     * @method _getConnectionsGrid
     * @param {Immutable.List<Connection>} connections
     * @param {String} type
     * @param {Object} options
     * @returns {Array|*}
     * @private
     */
    _getConnectionsGrid(connections, type, options) {
        const itemClassName = classnames({
            'connections-item': true,
            '--vertical': options.vertical,
            '--force-empty': options.forceEmpty
        });

        if(connections.get('message') === undefined) {
          return connections.map(connection => {
            let curentLocation = null;
            // if(connection.get('error'))
            if (connection.getIn(['location', 'name']) !== undefined) {
              curentLocation = connection.getIn(['location', 'name']);
            } else if (connection.getIn(['position', 'company']) !== undefined) {
              curentLocation = connection.getIn(['position', 'company']);
            }
            let currentUserId = null;
            if (this.props.heroProfile !== undefined && this.props.heroProfile !== null) {
              currentUserId = this.props.heroProfile.id;
            }
            let hideContact = false;
            if(connection.get('id') !== undefined && connection.get('id') === currentUserId) {
              hideContact = true;
            }
            return (
              <ProfileBox
                avatar={connection.getIn(['profilePictureUrl'])}
                hideConnect={hideContact}
                profilePictureUrl={connection.getIn(['profilePictureUrl'])}
                className={itemClassName}
                connected={connection.get('isConnected')}
                currentLocation={curentLocation}
                invitationSent={connection.get('invitationSent')}
                distance={connection.get('distance')}
                firstName={connection.get('firstName')}
                grade={connection.get('grade')}
                id={connection.get('id')}
                headline={connection.get('headline')}
                jobName={connection.getIn(['position', 'title'])}
                key={`${type}-${connection.get('id')}`}
                lastName={connection.get('lastName')}
                matchValue={this.props.heroProfile !== null && connection.get('id') !== this.props.heroProfile.id ? connection.get('matchingPercentage') : null}
                totalScore={this.props.heroProfile !== null && connection.get('id') !== this.props.heroProfile.id ? connection.get('totalScore') : null}
                options={options}
                openConversation={this.props.openConversation}
                openConversationAndFetch={this.props.openConversationAndFetch}
                fetchDirectChatConversations={this.props.fetchDirectChatConversations}
                conversations={this.props.conversations}
                showGrade={options.showGrade}
                currentUser={currentUserId}
                trans={this.props.translations}
                heroInitials={ connection.get('heroInitials') }
              />
            );
          });
        }
    }

    /**
     * Handles view type switch
     * @method _onPageViewSwitch
     * @param {string} view
     * @private
     */
    _onPageViewSwitch = (view) => {
        this.setState({view});
    }

    _getConnectionsDisplay() {
        return this._getConnectionsGrid(this.props.existing, 'existing', {
            distance: false,
            location: true,
            forceEmpty: true,
            showGrade: false
        });
    }

    //  <ConnectionsFilterBar />

// This method will be sent to the child component
    handler() {
        this.setState({
            showSuggestions: false,
            firstTime: false
        });
    }

    render() {
        if(this.props.loaded === false)
        {
            return <div className="Loader"></div>
        }

        let trans = this.props.translations;
        if(trans === null) return <div className="Loader"></div>;


        let connectionFirstName = '';
        let {existing, connection, hasMore} = this.props;
        if(connection !== undefined && connection !== null) {
            connectionFirstName = connection.get('firstName') + '\'s';
        }
        let heroName = (this.props.match.params.id !== undefined) ? connectionFirstName : '';
        const loadMore = hasMore === true ? (
            <div className="pageHeader-itemsDisplay-loadMoreButton">
                <button id="connections-load-more" onClick = { this.changePage } className={classnames("dashboard-button dashboard-button--border")}>{_.get(trans, 'dashboard.loadMore', '')}</button>
            </div>
        ) : null;
        const loaderContainer = existing.size > 0 ? (
            <div className="pageHeader-itemsDisplay-bottomButtons">
                <div className="pageHeader-itemsDisplay-goTopButton">
                    <button onClick = { this.scrollToTop } className={classnames("connections-goTopButton")}><i className="icon icon-arrow"></i></button>
                </div>
                {loadMore}
            </div>
        ) : null;

        return (<div className="connections">
            <section className="connections-container">
                <Scrollbars ref="scrollbars" autoHide className="styleScroll">
                    <div className="connections-mainContent">
                        {this.props.match.params.id !== undefined && this.props.lastLocation !== null && this.props.lastLocation.pathname === '/direct-chat' ? (<a onClick={this.props.history.goBack} className="connections-navigation">{trans.connections.back}</a>) : null  }
                        <PageHeader
                            pageTitle={heroName + ' ' + trans.connections.title}
                            icon="connections"
                            active={this.state.view}
                            onActionClick={this._onPageViewSwitch}
                            actions={null}
                        />

                        <div className={`connections-items --${this.state.view}-view `}>
                            {this._getConnectionsDisplay()}
                        </div>
                        {loaderContainer}
                    </div>
                </Scrollbars>
            </section>
            </div>
        );
    }
}


const mapStateToProps = state => {
    return {
        showSuggestions: state.global.get('showSuggestions'),
        conversations: state.directChat.get('conversations'),
        totalAvailableConnectionsCount: state.connections.get('totalAvailableConnections'),
    };
};

const mapDispatchProps = dispatch => {
    return {
        openConversationAndFetch: (heroId, conversatioId) => {
            dispatch(openConversationAndFetch(heroId, conversatioId));
        },
        openConversation: (heroId, poster) => {
            dispatch(openConversation(heroId, poster));
        },
        fetchDirectChatConversations: () => {
            dispatch(fetchDirectChatConversations());
        },
        fetchConnectionConnections: (connectionId) => {
            dispatch(fetchConnectionConnections(connectionId));
        },
        totalAvailableConnections: () => dispatch(totalAvailableConnections()),
    }
};

export default connect(mapStateToProps, mapDispatchProps)(withLastLocation(withRouter(Connections)));

Connections.propTypes = {
    /**
     * Contains all user's connections
     * @property existing
     * @type {Immutable.List<Connection>}
     */
    existing: PropTypes.object.isRequired,
    /**
     * Contains all nearby user's connection
     * @property nearby
     * @type {Immutable.List<Connection>}
     */
    nearby: PropTypes.object.isRequired,
    /**
     * Contains recommended connections
     * @property recommended
     * @type {Immutable.List<Connection>}
     */
    recommended: PropTypes.object.isRequired,
    /**
     * Total number of available connections
     * @property total
     * @type {number}
     */
    total: PropTypes.number.isRequired,
    /**
     * Number of newly added connections
     * @property newConnections
     * @type {number}
     */
    newConnections: PropTypes.number,
    /**
     * Provides message modal action where context is not present
     * @property showMessageModal
     * @type {function}
     */
    showMessageModal: PropTypes.func,
    add_multiple_connections: PropTypes.bool
};
