import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Dropdown from '../../../common/dropdown/Dropdown';

class Filter extends Component {
  constructor(props) {
    super(props);
    this.buildMediaFilterOption = this.buildMediaFilterOption.bind(this);
    this.changeSelectedFilter = this.changeSelectedFilter.bind(this);
  }

  componentDidMount() {
    const { fetchMediaFilters } = this.props;
    fetchMediaFilters();
  }

  buildMediaFilterOption({ id, name, slug }) {
    const { mediaFilter } = this.props;
    const isSelected = id === mediaFilter;
    return (
      <option key={id} value={slug} selected={isSelected}>
        {name}
      </option>
    );
  }

  changeSelectedFilter(filterId) {
    const { changeMediaFilter } = this.props;
    if (!filterId) {
      return;
    }
    changeMediaFilter(filterId);
  }

  render() {
    const { mediaFilters, translations } = this.props;
    const mediaFiltersArray = mediaFilters.map(item => ({ id: item.slug, value:_.get(translations, item.name, item.name) }));
    return (
      <div className="media-filters-selector__container">
        <div className="media-filters-selector__picker-container">
          <Dropdown
            onChange={this.changeSelectedFilter}
            options={mediaFiltersArray}
            selectedId="all"
            placeHolder={_.get(translations, 'all', 'All')}
            className="dropdown__selector filter__dropdown"
            customText={_.get(translations, 'filter', 'Filter: ')}
          />
        </div>
      </div>
    );
  }
}

Filter.propTypes = {
  mediaFilters: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  mediaFilter: PropTypes.string,
  fetchMediaFilters: PropTypes.func.isRequired,
  changeMediaFilter: PropTypes.func.isRequired,
};

Filter.defaultProps = {
  mediaFilter: PropTypes.number,
};

export default Filter;
