import React from 'react';
import PropTypes from 'prop-types';
import './../css/blockConnection.scss';

class InvitationSent extends React.Component {

  onCancelBlock = () => {
    this.props.onCancelAction();
  }

  render () {
    return (
      <div className="blockConnection">
        <div className="blockConnection-title items-style1">{this.props.trans.modals.invitationSent.title}</div>
        <div className="blockConnection-warning">{this.props.trans.modals.invitationSent.warning} { `${this.props.firstName} ${this.props.lastName}` }</div>
        <div className="blockConnection-description">{this.props.trans.modals.invitationSent.description}</div>
        <div className="blockConnection-actions">
          <button
            style={{marginLeft: '0'}}
            className="blockConnection-cancelButton main-button"
            onClick={ this.onCancelBlock }>{this.props.trans.modals.invitationSent.ok}</button>
        </div>
      </div>
    );
  }
}

InvitationSent.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  onAction: PropTypes.func,
  trans: PropTypes.object,
};

export default InvitationSent;
