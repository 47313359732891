/**
 * Renders the info displayed on map's bottom
 * @class MapInfo
 * @module MapContainer
 */
import React from 'react';
import _ from 'lodash';

class MapInfo extends React.Component {
  /**
   * returns an item to render based on a provided object
   * @method infoRenderItem
   * @param {Object} item
   * @returns {ReactElement}
   */
  infoRenderItem(item) {
    return (
      <div className="mapInfo-item --normal" key={item.name}>
        <span className={`mapInfo-icon --${item.icon}`} />
        <span className="mapInfo-label">{item.label}</span>
      </div>
    );
  }

  render() {
    return (
      <div className="mapInfo">
        <div className="mapInfo-item --normal mapInfo-info--available" key="available-items">
          <span className="mapInfo-icon --available" />
          <span className="mapInfo-label">{_.get(this.props, 'trans.map.locationAvailable', 'Location Available')}</span>
        </div>

        <div className="mapInfo-item mapInfo-info--unavailable" key="other-items">
          <span className="mapInfo-icon --normalUnavailable" />
          <span className="mapInfo-label">{_.get(this.props, 'trans.map.locationUnavailable', 'Location Unavailable')}</span>
        </div>

        <div className="mapInfo-item mapInfo-item--coming-up" key="other-items-2">
          <span className="mapInfo-icon --comingUpUnavailable" />
          <span className="mapInfo-label">{_.get(this.props, 'trans.map.comingUpUnavailable', 'Coming up')}</span>
        </div>

        <div className="mapInfo-item mapInfo-item--health-measures" key="other-items-3">
          <span className="mapInfo-icon --healthyMeasures" />
          <span className="mapInfo-label">{_.get(this.props, 'trans.map.healthyMeasures', 'Health Safety Measures')}</span>
        </div>

        <div className="mapInfo-item mapInfo-item--temperature-scanner" key="other-items-4">
          <span className="mapInfo-icon --temperatureScanner" />
          <span className="mapInfo-label">{_.get(this.props, 'trans.map.temperatureScanner', 'Temperature Control in Place')}</span>
        </div>

        <div className="mapInfo-item mapInfo-item--premium-partner" key="other-items-5">
          <span className="mapInfo-icon --premiumPartner" />
          <span className="mapInfo-label">{_.get(this.props, 'trans.map.premiumPartner', 'Premium Partner')}</span>
        </div>
      </div>
    );
  }
}

export default MapInfo;
