import './index.scss';

import { connect } from 'react-redux';
import _ from 'lodash';
import Community from './Community';
import Communities from './Communities';
import CommunitySinglePostPage from './CommunitySinglePostPage';
import { translations } from '../../selectors/translations';
import { heroCommunitiesHasOwnCommunity } from '../../selectors/community/hero-communities';
import { noCommunitiesSelector } from '../../selectors/community/popular-communities';
import { communityIsLoadingSelector, communitySelector, heroIsCommunityMemberSelector } from '../../selectors/community/community-page';
import { fetchCommunity } from '../../actions/community/community-page';
import { fetchPopularCommunities } from '../../actions/community/popular-communities';
import { leaveCommunityErrorsSelector } from '../../selectors/community/leave-community';
import { communitiesPageToastMessageSelector, communityPageToastMessageSelector } from '../../selectors/community/community-toasts';
import {
  showCommunitiesPageToast,
  showCommunityPageToast
} from '../../actions/community/community-toasts';

const mapStateToProps = state => ({
  translations: _.get(translations(state), 'community', {}),
  heroHasOwnCommunity: heroCommunitiesHasOwnCommunity(state),
  noCommunities: noCommunitiesSelector(state),
  communitiesToastMessage: communitiesPageToastMessageSelector(state),
});

const mapStateToPropsCommunity = state => ({
  translations: _.get(translations(state), 'community', {}),
  community: communitySelector(state),
  isLoading: communityIsLoadingSelector(state),
  leaveCommunityErrors: leaveCommunityErrorsSelector(state),
  communityToastMessage: communityPageToastMessageSelector(state),
  heroIsCommunityMember: heroIsCommunityMemberSelector(state),
});

const mapDispatchToPropsCommunity = dispatch => ({
  fetchCommunity: (urlSlug, postId) => dispatch(fetchCommunity(urlSlug, postId)),
  fetchPopularCommunities: () => dispatch(fetchPopularCommunities()),
  showCommunityPageToast: data => dispatch(showCommunityPageToast(data)),
  showCommunitiesPageToast: message => dispatch(showCommunitiesPageToast(message)),
});

const SinglePostPage = connect(mapStateToPropsCommunity, mapDispatchToPropsCommunity)(CommunitySinglePostPage);
const CommunityPage = connect(mapStateToPropsCommunity, mapDispatchToPropsCommunity)(Community);
const HomepageCommunity = connect(mapStateToProps, mapDispatchToPropsCommunity)(Communities);
export { HomepageCommunity as Communities, CommunityPage as Community, SinglePostPage as CommunitySinglePostPage };
