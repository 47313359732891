import React from 'react';
import PropTypes from 'prop-types';
import './../css/deletePaymentAccount.scss';

class DeletePaymentAccount extends React.Component {

  onDelete = () => {
    this.props.onAction('delete', this.props.profileId);
  }

  onCancelDelete = () => {
    this.props.onCancelAction(false);
  }

  render () {
    return (
      <div className="deletePaymentAccount">
        <div onClick={ this.onCancelDelete } className="modal-close-button"></div>
        <div className="deletePaymentAccount-title items-style1">{this.props.trans.modals.deletePaymentAccount.title}</div>
        <div className="deletePaymentAccount-warning">{this.props.trans.modals.deletePaymentAccount.warning}</div>
        <div className="deletePaymentAccount-actions">
          <button
            className="deletePaymentAccount-blockButton secondary-button"
            onClick={ this.onDelete }>{this.props.trans.modals.deletePaymentAccount.deletePaymentAccountButtonLabel}</button>
          <button
            className="deletePaymentAccount-cancelButton main-button"
            onClick={ this.onCancelDelete }>{this.props.trans.modals.deletePaymentAccount.changedMyMindButtonLabel}</button>
        </div>
      </div>
    );
  }
}

DeletePaymentAccount.propTypes = {
  onAction: PropTypes.func
};

export default DeletePaymentAccount;
