export const FETCH_CONNECTION = 'FETCH_CONNECTION';
export const FETCH_CONNECTIONS = 'FETCH_CONNECTIONS';
export const FETCH_CONNECTION_CONNECTIONS = 'FETCH_CONNECTION_CONNECTIONS';
export const FETCH_NEARBY_CONNECTIONS = 'FETCH_NEARBY_CONNECTIONS';
export const FETCH_RECOMMENDED_CONNECTIONS = 'FETCH_RECOMMENDED_CONNECTIONS';
export const BEFORE_FETCH_RECOMMENDED_CONNECTIONS = 'BEFORE_FETCH_RECOMMENDED_CONNECTIONS';
export const REMOVE_CONNECTION = 'REMOVE_CONNECTION';
export const ADD_CONNECTION = 'ADD_CONNECTION';
export const ON_SUGGESTIONS_CHANGE = 'ON_SUGGESTIONS_CHANGE';
export const DESELECT_ALL = 'DESELECT_ALL';
export const BEFORE_FETCH_CONNECTION = 'BEFORE_FETCH_CONNECTION';
export const CHANGE_SUGGESTION_FILTERS = 'CHANGE_SUGGESTION_FILTERS';
export const TOTAL_AVAILABLE_CONNECTIONS = 'TOTAL_AVAILABLE_CONNECTIONS';

export const fetchConnections = page => {
  const apiPrefix = localStorage.getItem('access-key') ? '' : '/public';
  return {
    type: FETCH_CONNECTIONS,
    url: `${window.__env.apiUrl}${apiPrefix}/connections`,
    page,
  };
};

export const fetchConnectionConnections = connectionId => {
  return {
    type: FETCH_CONNECTION_CONNECTIONS,
    url: `${window.__env.apiUrl}/connections/${connectionId}/connection`,
  };
};

export const totalAvailableConnections = () => ({
  type: TOTAL_AVAILABLE_CONNECTIONS,
  url: `${window.__env.apiUrl}/connections/available`,
});

export const beforeFetchConnection = () => {
  return {
    type: BEFORE_FETCH_CONNECTION,
  };
};

export const fetchConnection = connectionId => {
  return {
    type: FETCH_CONNECTION,
    url: `${window.__env.apiUrl}/hero/profile/${connectionId}`,
    connectionId,
  };
};

export const onSuggestionsChange = (value, checked) => {
  return {
    type: ON_SUGGESTIONS_CHANGE,
    value,
    checked,
  };
};

export const deselectAll = () => {
  return {
    type: DESELECT_ALL,
  };
};

export const changeSuggestionFilters = value => {
  return {
    type: CHANGE_SUGGESTION_FILTERS,
    value,
  };
};

