import { connect } from 'react-redux';
import MyMeeting from './MyMeeting';
import './index.scss';
import { fetchMeeting } from '../../actions/meetingActions';
import { meetingSelector, meetingIsLoadingSelector } from '../../selectors/meeting';

const mapStateToProps = state => ({
  translations: state.translations,
  meeting: meetingSelector(state),
  isLoading: meetingIsLoadingSelector(state),
});

const mapDispatchProps = dispatch => ({
  fetchMeeting: id => dispatch(fetchMeeting(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchProps
)(MyMeeting);
