import React from 'react';
import moment from 'moment';
import Truncate from 'react-truncate';
import _ from 'lodash';
import PropTypes from 'prop-types';

const DATE_FORMAT = 'MMM D, YYYY';

class StaffListing extends React.Component {
  constructor() {
    super();
    this.state = {
      showAll: false,
    };

    this.buildStaffItem = this.buildStaffItem.bind(this);
    this.showStaffToggle = this.showStaffToggle.bind(this);
    this.renderStaffListing = this.renderStaffListing.bind(this);
  }

  componentDidMount() {
    const { fetchStaffMembers, officeId, selectDateRange } = this.props;
    const date = moment(selectDateRange.from, DATE_FORMAT).unix();
    fetchStaffMembers(officeId, date);
  }

  showStaffToggle() {
    const { showAll } = this.state;
    this.setState({ showAll: !showAll });
  }

  renderStaffListing() {
    const { staff } = this.props;
    const { showAll } = this.state;

    if (!staff) {
      return null;
    }
    if (!showAll) {
      return staff.slice(0, 1).map(this.buildStaffItem);
    }

    return staff.map(this.buildStaffItem);
  }

  buildStaffItem(item, index) {
    const { profile_picture_url, first_name, last_name, initials, skills } = item;
    const memberSkills = skills.join(', ');
    const { showAll } = this.state;
    const { translations, staff } = this.props;
    return (
      <div key={index} className="white-label__staff-item">
        <div className="white-label__staff-picture-container">
          {profile_picture_url ? (
            <img className="white-label__staff-picture" src={profile_picture_url} alt={`${first_name} ${last_name}`} />
          ) : (
            <div className="white-label__staff-initials">{initials}</div>
          )}
        </div>
        <div className="white-label__staff-details">
          <div className="white-label__staff-name">
            <span>{`${first_name} ${last_name}`}</span>
            {index === 0 && staff.length > 1 ? (
              <span onClick={this.showStaffToggle} className="button__show-toggle">
                {showAll ? _.get(translations, 'showLess', 'Show less') : _.get(translations, 'showMore', 'Show more')}
              </span>
            ) : null}
          </div>
          <div className="white-label__staff-department" title={memberSkills}>
            <Truncate lines={1} ellipsis={<>... </>}>
              {memberSkills}
            </Truncate>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { isLoadingStaff } = this.props;

    if (isLoadingStaff) {
      return <div className="white-label__staff-loader" />;
    }

    return <div className="white-label__staff-list">{this.renderStaffListing()}</div>;
  }
}

StaffListing.propTypes = {
  isLoadingStaff: PropTypes.bool.isRequired,
  fetchStaffMembers: PropTypes.func.isRequired,
  officeId: PropTypes.number.isRequired,
  selectDateRange: PropTypes.shape({}).isRequired,
};

export default StaffListing;
