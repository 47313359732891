import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import StarRating from '../starRating/starRating';

class BookedOfficeInfo extends React.Component {

  computeDates (item) {
    let startDate = moment(item.getIn(['bookedData', 'startDate'])).format('MMM D');
    let endDate = moment(item.getIn(['bookedData', 'endDate'])).format('MMM D, YYYY');

    return `${startDate} - ${endDate}`;
  }

  _getRating() {
    let votes = this.props.item.getIn(['rating', 'votes']);

    if(votes === 0) return null;

    return (
      <div className="officeBox-infoSmall">
      { votes } <StarRating
        initialRate={ this.props.item.getIn(['rating', 'average']) }
        theme={'theme-yellow-small'}
        readonly={ true } />
      </div>
    );
  }

  render () {
    const itemTypes = (typeof this.props.item.get('types') !== 'undefined') ? this.props.item.get('types').toJS().join(', ') : "";
    return (
      <div className={ `bookedOffice officeBox ${this.props.className}` }>
        <div className="bookedOffice-details">
          <div className="officeBox-title items-style1">{ this.props.item.get('title') }</div>
          { this._getRating() }
          <div className="officeBox-infoSmall">{this.props.item.getIn(['bookedData', 'string']) }</div>
          <div className="officeBox-infoSmall">{ itemTypes }</div>
        </div>
        <div className="bookedOffice-actions">
          { this.props.fullView ? null : (<button className="bookedOffice-directions secondary-button-leftSide">
            <span className="bookedOffice-directionsIcon"></span>
            Plan Drive
          </button>) }
          <div className="officeBox-infoSmall">
            Starts in
            <span className="officeBox-infoHighlighted">{ this.props.item.getIn(['bookedData', 'startsIn']) }</span>
          </div>
        </div>
      </div>
    );
  }
}

BookedOfficeInfo.propTypes = {
  /**
   * Immutable map containing an office description
   * @param item
   * @type Immutable.Map
   */
  item: PropTypes.object.isRequired,
  /**
   * Flag which determines if connections are displayed or not
   * @param showConnections
   * @type boolean
   */
  showConnections: PropTypes.bool
};

export default BookedOfficeInfo;
