import { connect } from 'react-redux';
import _ from 'lodash';
import FilterContainer from './filterContainer';

import { fetchOfficeDetails } from '../../../../actions/locationActions';
import { setSelectedProductId } from '../../../../actions/whiteLabel/booking';
import { selectedDateSelector, selectedProductTypeSelector, officeTimezoneSelector } from '../../../../selectors/whiteLabel/booking';
import { selectedFloorIndexSelector, selectedFeaturesSelector, featuresSelector } from '../../../../selectors/whiteLabel/filter';
import { mapProductsSelector } from '../../../../selectors/whiteLabel/products';

const mapStateToProps = state => {
  const data = state.location.get('details').toJS();
  return {
    whiteLabel: _.get(data, 'white_label', []),
    translations: state.translations,
    selectedDate: selectedDateSelector(state),
    selectedFloorIndex: selectedFloorIndexSelector(state),
    selectedProductType: selectedProductTypeSelector(state),
    officeOpenDaysAndHours: state.location.get('details').get('openingHours'),
    officeTimezone: officeTimezoneSelector(state),
    features: featuresSelector(state),
    officeDetails: state.location.get('details'),
    selectedFeatures: selectedFeaturesSelector(state),
    mapProducts: mapProductsSelector(state),
  };
};

const mapDispatchProps = dispatch => {
  return {
    fetchOfficeDetails: id => {
      dispatch(fetchOfficeDetails(id));
    },
    setSelectedProductId: id => {
      dispatch(setSelectedProductId(id));
    },
  };
};

export default connect(mapStateToProps, mapDispatchProps)(FilterContainer);
