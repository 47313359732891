import _ from 'lodash';
import { connect } from 'react-redux';
import { translations } from '../../../../../selectors/translations';
import ProductOverview from './ProductOverview';
import { mapProductsSelector } from '../../../../../selectors/whiteLabel/products';
import { selectedProductIdSelector } from '../../../../../selectors/whiteLabel/booking';

const mapStateToProps = state => {
  return {
    translations: state.translations,
    formatString: _.get(translations(state), 'formatString', () => {}),
    products: mapProductsSelector(state),
    selectedProductId: selectedProductIdSelector(state),
    officeMeetingRooms: state.location
      .get('details')
      .get('products')
      .get('meetingRooms'),
  };
};

const mapDispatchProps = dispatch => {
  return {};
};

export default connect(mapStateToProps, mapDispatchProps)(ProductOverview);
