import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import './css/index.scss';

class PageHeader extends React.Component {

  _onActionClick(action) {
    return () => this.props.onActionClick(action);
  }

  _getActions () {
    if (!this.props.actions) {
      return null;
    }
    return this.props.actions.map(action => {
      const className = classnames({
        'pageheader-item': true,
        [`pageHeader-${action.name}`]: true,
        '--active': this.props.active === action.name
      });
      return (
        <div
          key={ action.name }
          className={ className }
          onClick={ this._onActionClick(action.name) }>{ this.props.trans.pageHeader[action.name]  }</div>
      );
    });
  }

  _getPageIcon() {
    if (!this.props.icon) {
      return null;
    }

    return <span className={ `pageHeader-icon icon icon-${this.props.icon}` }></span>
  }

  render() {
    return (
      <div className={ `pageHeader ${this.props.className}` }>
        <h1 className={ `pageHeader-title items-style2 --${this.props.icon}` }>
          { this._getPageIcon() }
          <span className="pageHeader-titleText white">{ this.props.pageTitle }</span>
        </h1>
        <div className="pageHeader-itemsDisplay">
          { this._getActions() }
        </div>
      </div>
    );
  }
}

export default PageHeader;

PageHeader.propTypes = {
  onActionClick: PropTypes.func,
  actions: PropTypes.array,
  className: PropTypes.string,
  active: PropTypes.string,
  icon: PropTypes.string
};

PageHeader.defaultProps = {
  onActionClick: () => null,
  actions: [{
    name: 'grid',
    label: 'Grid'
  }, {
    name: 'map',
    label: 'Map'
  }],
  className: ''
};
