export const allowedImages = ['jpg', 'jpeg', 'png', 'jpe', 'gif', 'image/jpeg', 'image/png', 'image/gif'];
export const allowedVideos = ['mp4', 'mp4v', 'mpg4', 'mov', 'avi', 'qt', 'video/mp4', 'video/avi', 'video/quicktime'];
export const allowedDocuments = [
  'pdf',
  'doc',
  'dot',
  'docx',
  'csv',
  'xls',
  'xlsx',
  'xlm',
  'xla',
  'xlc',
  'xlt',
  'xlw',
  'ppt',
  'pps',
  'pot',
  'pptx',
  'application/pdf',
  'application/msword',
  'text/csv',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
];

export function getFileExtension(filename) {
  return filename.split('.').pop();
}

export function fileIsAllowed(filename, mimeType, type) {
  switch (type) {
    case 'video':
      if (mimeType) return allowedVideos.includes(mimeType);
      return allowedVideos.includes(getFileExtension(filename));
    case 'image':
      if (mimeType) return allowedImages.includes(mimeType);
      return allowedImages.includes(getFileExtension(filename));
    case 'document':
      if (mimeType) return allowedDocuments.includes(mimeType);
      return allowedDocuments.includes(getFileExtension(filename));
    default:
      return false;
  }
}