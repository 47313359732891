import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { getDistance as geolibGetDistance } from 'geolib';
import { Link } from 'react-router-dom';
import buildLocationUrl from '../../../utils/buildLocationUrl';

class FullBookingInfo extends React.Component {
  constructor() {
    super();
    this.state = {
      expandedID: null,
      wasExpanded: false,
      collapsedBookings: {},
    };
  }

  componentDidMount() {
    let firstActive = true;
    this.props.item.toJS().bookings.map(booking => {
      if (booking.status === 'active' && firstActive) {
        this.setState({ expandedID: booking.id });
        firstActive = false;
      }
      return null;
    });
  }

  expandToggle(bookingID) {
    if (this.state.expandedID === bookingID) {
      this.setState({ expandedID: null });
    } else {
      this.setState({ expandedID: bookingID });
    }
  }

  showAllBookings(officeID) {
    const { collapsedBookings } = this.state;
    collapsedBookings[officeID] = !collapsedBookings[officeID];
    this.setState({ collapsedBookings });
  }

  getActiveBookingNumber(bookings) {
    let numberOfBookings = 0;
    bookings.map(booking => {
      numberOfBookings++;

      return null;
    });
    return numberOfBookings;
  }

  render() {
    const count = <span className="count">{` (${this.getActiveBookingNumber(this.props.item.toJS().bookings)})`}</span>;

    let distanceLabel = null;
    if (this.props.geoLocationCoords !== null) {
      const dist = geolibGetDistance(
        { latitude: this.props.geoLocationCoords.latitude, longitude: this.props.geoLocationCoords.longitude },
        { latitude: this.props.item.toJS().location.latitude, longitude: this.props.item.toJS().location.longitude }
      );
      const distance = dist / 1000;
      distanceLabel = (
        <div className="officeBox-infoSmall">
          {this.props.item.get('distance')}{' '}
          {distance > 10
            ? distance.toFixed(0)
            : distance
                .toFixed(2)
                .toString()
                .replace('.', window.__env.decimalSeparator)}{' '}
          km away
        </div>
      );
    }

    let activeBookingPeriodsCount = 0;
    let pastBookingPeriodsCount = 0;
    let displayedBookingsCounter = 1;
    const officeID = this.props.item.toJS().id;

    const activeBookingDetails = this.props.item.toJS().bookings.map(booking => {
      const office = this.props.item.toJS();
      if (booking.status === 'active') {
        activeBookingPeriodsCount++;
        const startPeriod = moment.unix(booking.fromDate).utc();
        const endPeriod = moment.unix(booking.toDate).utc();

        let colapsed = false;
        if (this.state.expandedID !== null && this.state.expandedID === booking.id) {
          colapsed = true;
        }
        return (
          <div
            key={booking.id}
            className={
              displayedBookingsCounter++ <= 3 ||
              (this.state.collapsedBookings[officeID] !== undefined && this.state.collapsedBookings[officeID])
                ? 'expanded'
                : 'collapse'
            }
          >
            <div
              className={colapsed ? 'extended' : 'collapsed'}
              style={{ cursor: 'pointer' }}
              onClick={() => {
                this.expandToggle(booking.id);
              }}
            >
              {booking.product.name} <span />
            </div>
            <div className={booking.status === 'active' && colapsed ? 'extend' : 'collapse'}>
              <div className="officeBox-infoSection">
                <div className="officeBox-infoTitle">{this.props.trans.myBookings.activeBookingPeriod}</div>
                <div className="officeBox-infoData">
                  <div key={booking.id}>
                    <a href={`${window.__env.baseUrl}/booking/${booking.id}`}>
                      {startPeriod.format('MMMM DD YYYY')} - {moment(endPeriod).format('MMMM DD YYYY')}
                    </a>
                  </div>
                </div>
              </div>
              <div className="officeBox-infoSection">
                <div className="officeBox-infoTitle">{this.props.trans.myBookings.yourActiveChosenHours}</div>
                <div className="officeBox-infoData">
                  {`${booking.hours.from.hour}:${booking.hours.from.minute}-${booking.hours.to.hour}:${booking.hours.to.minute}`}
                </div>
              </div>
              {office.bookableEvent === false ? (
                <div className="officeBox-infoSection">
                  <div className="officeBox-infoTitle">
                    {this.props.trans.myBookings.activeBookingFee}
                    <div className="officeBox-infoData">
                      {booking.totalPricePayable
                        .toFixed(2)
                        .toString()
                        .replace('.', window.__env.decimalSeparator)}
                      €
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        );
      }
      return null;
    });

    const pendingBookingDetails = this.props.item.toJS().bookings.map(booking => {
      const startPeriod = moment.unix(booking.fromDate).utc();
      const endPeriod = moment.unix(booking.toDate).utc();
      const colapsed = this.state.expandedID === booking.id;
      if (booking.status === 'pending') {
        return (
          <div
            key={booking.id}
            className={displayedBookingsCounter++ <= 3 || this.state.collapsedBookings[officeID] ? 'expanded' : 'collapse'}
          >
            <div
              className={colapsed ? 'extended' : 'collapsed'}
              style={{ cursor: 'pointer' }}
              onClick={() => {
                this.expandToggle(booking.id);
              }}
            >
              {booking.product.name} <label style={{ color: '#f0b323' }}>(Pending)</label> <span />
            </div>
            <div className={colapsed ? 'extend' : 'collapse'}>
              <div className="officeBox-infoSection">
                <div className="officeBox-infoTitle">{this.props.trans.myBookings.activeBookingPeriod}</div>
                <div className="officeBox-infoData">
                  <div key={booking.id}>
                    <a href={`${window.__env.baseUrl}/booking/${booking.id}`}>
                      {startPeriod.format('MMMM DD YYYY')} - {moment(endPeriod).format('MMMM DD YYYY')}
                    </a>
                  </div>
                </div>
              </div>
              <div className="officeBox-infoSection">
                <div className="officeBox-infoTitle">{this.props.trans.myBookings.yourActiveChosenHours}</div>
                <div className="officeBox-infoData">
                  {`${booking.hours.from.hour}:${booking.hours.from.minute}-${booking.hours.to.hour}:${booking.hours.to.minute}`}
                </div>
              </div>
              <div className="officeBox-infoSection">
                <div className="officeBox-infoTitle">{this.props.trans.myBookings.activeBookingFee}</div>
                <div className="officeBox-infoData">
                  {booking.totalPricePayable
                    .toFixed(2)
                    .toString()
                    .replace('.', window.__env.decimalSeparator)}
                  €
                </div>
              </div>
            </div>
          </div>
        );
      }
      return null;
    });

    const pastBookingDetails = this.props.item.toJS().bookings.map(booking => {
      const startPeriod = moment.unix(booking.fromDate).utc();
      const endPeriod = moment.unix(booking.toDate).utc();
      const colapsed = this.state.expandedID === booking.id;
      if (booking.status === 'closed' || booking.status === 'canceled') {
        pastBookingPeriodsCount++;
        return (
          <div
            key={booking.id}
            className={displayedBookingsCounter++ <= 3 || this.state.collapsedBookings[officeID] ? 'expanded' : 'collapse'}
          >
            <div
              className={colapsed ? 'extended' : 'collapsed'}
              style={{ cursor: 'pointer', ...(booking.status === 'canceled' ? { color: '#ff4a4a' } : {}) }}
              onClick={() => {
                this.expandToggle(booking.id);
              }}
            >
              {booking.product.name} <span />
            </div>
            <div className={colapsed ? 'extend' : 'collapse'}>
              <div className="officeBox-infoSection">
                <div className="officeBox-infoTitle">{this.props.trans.myBookings.activeBookingPeriod}</div>
                <div className="officeBox-infoData">
                  <div key={booking.id}>
                    <a href={`${window.__env.baseUrl}/booking/${booking.id}`}>
                      {startPeriod.format('MMMM DD YYYY')} - {moment(endPeriod).format('MMMM DD YYYY')}
                    </a>
                  </div>
                </div>
              </div>
              <div className="officeBox-infoSection">
                <div className="officeBox-infoTitle">{this.props.trans.myBookings.yourActiveChosenHours}</div>
                <div className="officeBox-infoData">
                  {`${booking.hours.from.hour}:${booking.hours.from.minute}-${booking.hours.to.hour}:${booking.hours.to.minute}`}
                </div>
              </div>
              <div className="officeBox-infoSection">
                <div className="officeBox-infoTitle">{this.props.trans.myBookings.activeBookingFee}</div>
                <div className="officeBox-infoData">
                  {booking.totalPricePayable
                    .toFixed(2)
                    .toString()
                    .replace('.', window.__env.decimalSeparator)}
                  €
                </div>
              </div>
            </div>
          </div>
        );
      }
      return null;
    });

    return (
      <div className={`bookedOffice officeBox ${this.props.className}`}>
        {activeBookingPeriodsCount === 0 && pastBookingPeriodsCount > 0 ? (
          <Link to={buildLocationUrl(this.props.item.toJS())} className="officeBox-location" />
        ) : null}
        <div className="bookedOffice-details">
          <div className="officeBox-title items-style1">
            {this.props.item.get('name')}
            {count}
          </div>
          {distanceLabel}
          {pendingBookingDetails}
          {activeBookingDetails}
          {pastBookingDetails}
          {this.state.collapsedBookings[this.props.item.get('id')] === undefined && this.props.item.toJS().bookings.length > 3 ? (
            <span className="secondary-button" onClick={() => this.showAllBookings(this.props.item.get('id'))}>
              {this.props.trans.myBookings.viewAllBookings}
            </span>
          ) : (
            ''
          )}
        </div>
      </div>
    );
  }
}

FullBookingInfo.propTypes = {
  /**
   * Immutable map containing an office description
   * @param item
   * @type Immutable.Map
   */
  item: PropTypes.object.isRequired,
  /**
   * Flag which determines if connections are displayed or not
   * @param showConnections
   * @type boolean
   */
  showConnections: PropTypes.bool,
};

export default FullBookingInfo;
