import { connect } from 'react-redux';

import { geolocated } from 'react-geolocated';
import Homepage from './Homepage';

import { fetchAvailableOffices, fetchMyBookings } from '../../actions/officesActions';
import { markerClick, markerClose, onBoundsChanged, onRadiusChange, onZoomChanged, setZoomToFitApplied } from '../../actions/map';
import { setSelectedProductId, setSelectedProductType, setSelectedDate } from '../../actions/whiteLabel/booking';
import { setMapProducts } from '../../actions/whiteLabel/products';

const mapStateToProps = state => {
  return {
    availableOffices: state.offices.get('availableOffices'),
    myBookings: state.offices.get('myBookings'),
    mapData: state.map,
    homeData: state.home,
    history: state.history,
    bookingSummary: state.booking.get('bookingSummary'),
    translations: state.translations,
    isWhiteLabel: state.global.get('isWhiteLabel'),
  };
};

const mapDispatchProps = dispatch => {
  return {
    fetchAvailableOffices: (filters, resetMarker) => {
      dispatch(fetchAvailableOffices(filters, resetMarker));
    },
    onRadiusChange: value => {
      dispatch(onRadiusChange(value));
    },
    markerClick: (markerID, location, googleMap) => {
      dispatch(markerClick(markerID, location, googleMap));
    },
    markerClose: markerID => {
      dispatch(markerClose(markerID));
    },
    onBoundsChanged: newMap => {
      dispatch(onBoundsChanged(newMap));
    },
    onZoomChanged: zoom => {
      dispatch(onZoomChanged(zoom));
    },
    fetchMyBookings: () => {
      dispatch(fetchMyBookings());
    },
    setZoomToFitApplied: () => {
      dispatch(setZoomToFitApplied());
    },
    setSelectedProductId: id => {
      dispatch(setSelectedProductId(id));
    },
    setSelectedProductType: type => {
      dispatch(setSelectedProductType(type));
    },
    setSelectedDate: (from, to) => {
      dispatch(setSelectedDate(from, to));
    },
    setMapProducts: products => {
      dispatch(setMapProducts(products));
    },
  };
};

const HomeView = connect(mapStateToProps, mapDispatchProps)(Homepage);

export default geolocated({
  positionOptions: {
    enableHighAccuracy: false,
  },
  userDecisionTimeout: 0,
  geolocationProvider: navigator.geolocation,
})(HomeView);
