import api from '../../components/myMeetings/utils/api';
import { fetchStaffMembers } from './staff';
/*
 * Constants
 */
export const SET_HEROES_BOOKINGS = 'SET_HEROES_BOOKINGS';
export const SET_HEROES_BOOKINGS_LOADING = 'SET_HEROES_BOOKINGS_LOADING';
export const SET_DEPARTMENTS = 'SET_DEPARTMENTS';
/*
 * Sync Actions
 */
export const setHeroesBookings = bookings => ({
  type: SET_HEROES_BOOKINGS,
  bookings,
});

export const setDepartments = departments => ({
  type: SET_DEPARTMENTS,
  departments,
});

export const setHeroesBookingsLoading = isLoading => ({
  type: SET_HEROES_BOOKINGS_LOADING,
  isLoading,
});

/*
 * Async Actions
 */
export const fetchHeroesBookings = (officeId, startDate, endDate, productType, departmentId) => dispatch => {
  dispatch(setHeroesBookingsLoading(true));

  const url = departmentId
    ? `whitelabel/bookings/startdate/${startDate}/enddate/${endDate}/officeid/${officeId}/type/${productType}/departmentid/${departmentId}`
    : `whitelabel/bookings/startdate/${startDate}/enddate/${endDate}/officeid/${officeId}/type/${productType}`;

  api
    .get(url)
    .then(({ data = {} }) => {
      if (data) {
        dispatch(setHeroesBookings(data));
        dispatch(fetchStaffMembers(officeId, startDate));
      }
    })
    .catch(() => {})
    .finally(() => {
      dispatch(setHeroesBookingsLoading(false));
    });
};

export const fetchDepartments = officeId => dispatch => {
  api
    .get(`whitelabel/departments/officeid/${officeId}`)
    .then(({ data = {} }) => {
      if (data) {
        dispatch(setDepartments(data));
      }
    })
    .catch(() => {})
    .finally(() => {});
};
