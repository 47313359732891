export const CLICK = 'CLICK';
export const FETCH_PROGRESS = 'FETCH_PROGRESS';
export const FETCH_AVAILABILITY_PROGRESS = 'FETCH_AVAILABILITY_PROGRESS';
export const FETCH_OFFICES = 'FETCH_OFFICES';
export const RIGHT_CLICK = 'RIGHT_CLICK';

export const click = () => {
  return {
    type: CLICK,
    otherStuff: {},
  };
};

export const rightClick = () => {
  return {
    type: RIGHT_CLICK,
  };
};

export const fetchProgress = (status = true) => {
  return {
    type: FETCH_PROGRESS,
    status,
  };
};
export const fetchAvailabilityProgress = (status = true) => {
  return {
    type: FETCH_AVAILABILITY_PROGRESS,
    status,
  };
};
export const fetchButtonData = filters => {
  return {
    type: FETCH_OFFICES,
    filters,
  };
};
