export const ADD_IMAGE = 'ADD_IMAGE';
export const ADD_MESSAGE = 'ADD_MESSAGE';
export const ADD_SOCKET_MESSAGE = 'ADD_SOCKET_MESSAGE';
export const CLEAR_MODAL_DATA = 'CLEAR_MODAL_DATA';
export const DELETE_CONVERSATION = 'DELETE_CONVERSATION';
export const DELETE_CONVERSATION_CONFIRMATION = 'DELETE_CONVERSATION_CONFIRMATION';
export const DIRECT_CHAT_TICK = 'DIRECT_CHAT_TICK';
export const FETCH_CHAT_CONNECTIONS = 'FETCH_CHAT_CONNECTIONS';
export const FETCH_CONVERSATION_MESSAGES = 'FETCH_CONVERSATION_MESSAGES';
export const FETCH_DIRECT_CHAT_CONVERSATIONS = 'FETCH_DIRECT_CHAT_CONVERSATIONS';
export const GET_DIRECT_CHAT_CONNECTIONS = 'GET_DIRECT_CHAT_CONNECTIONS';
export const GET_DIRECT_CHAT_CONVERSATIONS = 'GET_DIRECT_CHAT_CONVERSATIONS';
export const MARK_CONVERSATION_AS_READ = 'MARK_CONVERSATION_AS_READ';
export const MUTE_CONVERSATION = 'MUTE_CONVERSATION';
export const MUTE_CONVERSATION_CONFIRMATION = 'MUTE_CONVERSATION_CONFIRMATION';
export const OPEN_CONVERSATION = 'OPEN_CONVERSATION';
export const REMOVE_IMAGE = 'REMOVE_IMAGE';
export const REPORT_CONVERSATION = 'REPORT_CONVERSATION';
export const REPORT_CONVERSATION_CONFIRMATION = 'REPORT_CONVERSATION_CONFIRMATION';
export const SET_CONVERSATION_ONLINE_STATUS_LIST = 'SET_CONVERSATION_ONLINE_STATUS_LIST';
export const SET_ONLINE_STATUS = 'SET_ONLINE_STATUS';
export const STORE_SCROLL_HEIGHT = 'STORE_SCROLL_HEIGHT';
export const STORE_SCROLL_HEIGHT_CONTACTS = 'STORE_SCROLL_HEIGHT_CONTACTS';
export const TOGGLE_CONVERSATION_OPTIONS = 'TOGGLE_CONVERSATION_OPTIONS';
export const TOGGLE_CONVERSATIONS = 'TOGGLE_CONVERSATIONS';
export const TOGGLE_LOADING_MORE_MESSAGES = 'TOGGLE_LOADING_MORE_MESSAGES';
export const SET_HERO_ONLINE_STATUS = 'SET_HERO_ONLINE_STATUS';
export const OPEN_CONVERSATION_AND_FETCH = 'OPEN_CONVERSATION_AND_FETCH';
export const UPDATE_UNREAD_CONVERSATIONS_COUNT = 'UPDATE_UNREAD_CONVERSATIONS_COUNT';
export const CLOSE_ACTIVE_CONVERSATION = 'CLOSE_ACTIVE_CONVERSATION';

export const updateUnreadConversationsCount = count => ({ type: UPDATE_UNREAD_CONVERSATIONS_COUNT, count });

export const addImage = image => ({ type: ADD_IMAGE, image });

export const addSocketMessage = (args) => ({ type: ADD_SOCKET_MESSAGE, args });

export const addMessage = (id, message, heroId) => ({ type: ADD_MESSAGE, id, message, heroId });

export const clearModalData = () => ({ type: CLEAR_MODAL_DATA });

export const deleteConversationConfirmation = id => ({ type: DELETE_CONVERSATION_CONFIRMATION, id });

export const deleteConversation = id => ({ type: DELETE_CONVERSATION, id });

export const directChatTick = () => ({ type: DIRECT_CHAT_TICK });

export const fetchDirectChatConversations = () => ({
  type: FETCH_DIRECT_CHAT_CONVERSATIONS,
  url: window.__env.apiUrl +`/chat/conversations`,
});

export const fetchConversationMessages = (conversationId, startEpoch) => ({
  type: FETCH_CONVERSATION_MESSAGES,
  url: window.__env.apiUrl + `/chat/conversations/`+conversationId + `/messages`,
  conversationId,
  startEpoch
});

export const setConversationOnlineStatusList = (list) => ({
  type: SET_CONVERSATION_ONLINE_STATUS_LIST,
  list
});

export const setHeroStatus = (heroId, online) => ({
  type: SET_HERO_ONLINE_STATUS,
  heroId,
  online
});

export const fetchChatConnections = () => {
  return {
    type: FETCH_CHAT_CONNECTIONS,
    url: window.__env.apiUrl + "/connections"
  }
};

export const toggleLoadingMoreMessages = () => ({
  type: TOGGLE_LOADING_MORE_MESSAGES
});

export const storeScrollHeight = (height) => ({
  type: STORE_SCROLL_HEIGHT,
  height
});

export const storeScrollHeightContacts = (height) => ({
  type: STORE_SCROLL_HEIGHT_CONTACTS,
  height
});

export const getDirectChatConnections = term => ({ type: GET_DIRECT_CHAT_CONNECTIONS, term });

export const getDirectChatConversations = term => ({ type: GET_DIRECT_CHAT_CONVERSATIONS, term });

export const markConversationAsRead = id => ({ type: MARK_CONVERSATION_AS_READ, id });

export const muteConversation = (id, muted) => ({ type: MUTE_CONVERSATION, id, muted });

export const muteConversationConfirmation = id => ({ type: MUTE_CONVERSATION_CONFIRMATION, id });

export const openConversation = (posterId, poster) => ({
  type: OPEN_CONVERSATION,
  posterId,
  poster
});

export const openConversationAndFetch = (posterId, conversationId) => ({
  type: OPEN_CONVERSATION_AND_FETCH,
  url: window.__env.apiUrl + `/chat/conversations/` + conversationId + `/messages`,
  posterId,
  conversationId
});

export const removeImage = () => ({ type: REMOVE_IMAGE });

export const reportConversation = id => ({ type: REPORT_CONVERSATION, id });

export const reportConversationConfirmation = id => ({ type: REPORT_CONVERSATION_CONFIRMATION, id });

export const toggleConversationOptions = (showing) => ({ type: TOGGLE_CONVERSATION_OPTIONS, showing });

export const toggleConversations = () => ({ type: TOGGLE_CONVERSATIONS});

export const setOnlineStatus = (status) => ({ type: SET_ONLINE_STATUS, status });

export const closeActiveConversation = () => ({ type: CLOSE_ACTIVE_CONVERSATION });
