import React, { Component } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import './css/vertical-selector.scss';
import { connect } from 'react-redux';
import { getVerticals, setVerticalAndFetch } from '../../actions/global';
import { verticalSelector } from '../../selectors/vertical-selector';
import Dropdown from '../dashboard/common/dropdown/Dropdown';
import toJS from '../dashboard/utils/to-js';
import { translations } from '../../selectors/translations';

class VerticalSelector extends Component {
  constructor(props) {
    super(props);
    this.selectSelectedVertical = this.selectSelectedVertical.bind(this);
    this.buildVerticalOption = this.buildVerticalOption.bind(this);
    this.getSelectedVerticalId = this.getSelectedVerticalId.bind(this);
  }

  componentDidMount() {
    const { getGlobalVerticals } = this.props;
    getGlobalVerticals();
  }

  selectSelectedVertical(verticalId) {
    const { setVertical } = this.props;
    if (!verticalId) {
      return;
    }
    localStorage.setItem('selected-vertical', verticalId);
    setVertical(verticalId);
  }

  buildVerticalOption({ id, name }) {
    const { selectedVertical } = this.props;
    const isSelected = id === selectedVertical;
    return (
      <option key={id} value={id} selected={isSelected}>
        {name}
      </option>
    );
  }

  getSelectedVerticalId() {
    const { verticals } = this.props;

    const selectedLocalStorage = parseInt(localStorage.getItem('selected-vertical'), 10);
    if (!!selectedLocalStorage && _.isNumber(selectedLocalStorage)) {
      return selectedLocalStorage;
    }

    const defaultVertical = verticals.find(vertical => vertical.defaultVertical === true);
    if (defaultVertical) {
      return _.get(defaultVertical, 'id', 1);
    }

    return 1;
  }

  render() {
    const { verticals, translations } = this.props;
    const verticalsArray = verticals.map(item => ({ id: item.id, value: item.name }));
    return (
      <div className="vertical-selector__container">
        <div className="vertical-selector__label-container">
          <label className="vertical-selector__label">{_.get(translations, 'selectVertical', 'SELECT VERTICAL')}</label>
        </div>
        <div className="vertical-selector__picker-container">
          <Dropdown
            onChange={this.selectSelectedVertical}
            options={verticalsArray}
            selectedId={this.getSelectedVerticalId()}
            placeHolder="Vertical"
            className="dropdown__selector vertical__selector"
            customText=""
          />
        </div>
      </div>
    );
  }
}

VerticalSelector.propTypes = {
  verticals: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selectedVertical: PropTypes.number,
  getGlobalVerticals: PropTypes.func.isRequired,
  setVertical: PropTypes.func.isRequired,
};

VerticalSelector.defaultProps = {
  selectedVertical: null,
};

export default connect(
  state => ({
    verticals: state.global.get('globalVerticals'),
    selectedVertical: state.global.get('selectedVertical'),
    translations: _.get(translations(state), 'header', {}),
  }),
  dispatch => ({
    getGlobalVerticals: () => dispatch(getVerticals()),
    setVertical: vertical => dispatch(setVerticalAndFetch(vertical)),
  })
)(toJS(VerticalSelector));
