import _ from 'lodash';

const buildLocationUrl = office => {
  const lang = localStorage.getItem('lang') !== null ? localStorage.getItem('lang') : 'en';
  const officeId = _.get(office, 'id', null);

  if (!officeId) {
    return '/dashboard';
  }

  return _.get(office, `officeLocation.${lang}.locationUrl`, `/location/${officeId}`);
};
export default buildLocationUrl;
