import React from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import classnames from 'classnames';
import OfficeBox from './../officeBox/officeBox';
import {withRouter} from "react-router-dom";
import moment from 'moment';
import './css/index.scss';

class OfficesGrid extends React.Component {

  /**
   * The width of a single slide item. Fixed for now.
   * @property ITEM_WIDTH
   * @type {number}
   */
  ITEM_WIDTH = 418;
  /**
   * The space between two adjacent slide items
   * @property ITEMS_SPACING
   * @type {number}
   */
  ITEMS_SPACING = 29;
  /**
   * Padding of the container (left + right) which is unused space
   * @property CONTAINER_PADDING
   * @type {number}
   */
  CONTAINER_PADDING = 60;

  state = {
    offset: 0,
    availableWidth: 0
  };

  maxOffset = 0;

  windowResize = () => {
      this._resetWidth();
      // the state is updated async
      setTimeout(this._resetSliderPosition, 0);
  };

  componentDidMount () {
    window.addEventListener('resize', this.windowResize);

    this._resetWidth();
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.windowResize);
  }

  componentDidUpdate () {
    const size = this.props.items.size;

    this.maxOffset
       // total number of elements times their size
       = size * this.ITEM_WIDTH
       // adding space between each slide element
       + (size - 1) * this.ITEMS_SPACING
       // adding the padding of the container which is
       // space that won't be used
       + this.CONTAINER_PADDING
       // and finally removing the width of the container
       - this.state.availableWidth;
  }

  /**
   * Resets the width of the current slide element
   * @method _resetWidth
   * @private
   */
  _resetWidth = () => {
    const itemBox = this.grid.getBoundingClientRect();

    if (this.props.direction !== 'vertical') {
      this.setState({availableWidth: itemBox.width});
    }
  }

  _resetOffset = () => {
    this.setState({ offset:0 });
  }

  /**
   * Resets the slider position of the current element. Used when the window
   * is re-sized and empty spaces before/after the slider need clearance
   * @method _resetSliderPosition
   * @private
   */
  _resetSliderPosition = () => {

    let offset = this.state.offset;

    if (offset > this.maxOffset) {
      offset = this.maxOffset;
    }

    if (offset < 0) {
      offset = 0;
    }

    if (offset !== this.state.offset) {
      this.setState({ offset });
    }
  }

  getNotCanceledBookingNumber(bookings) {
    let numberOfBookings = 0;
    if(bookings.size > 0) {
      bookings.map((booking) => {
          numberOfBookings++;
      });
    }
    return numberOfBookings;
  }


  /**
   * Returns a list of items to render
   * @method getItemsToRender
   * @returns {Array<ReactElement>}
   */
  getItemsToRender() {
    let firstBooking = true;
    return this.props.items.map((item, index) => {
      if(this.props.content === 'active-events' || this.getNotCanceledBookingNumber(item.get('bookings')) !== 0) {
        if(this.props.content === 'active-events')
        {
          let evEndDate = moment(item.getIn(['date', 'end'])).utc();
          let now = moment.utc();
          if(evEndDate.isBefore(now))
          {
            return null;
          }
        }
        
        return (
          <div className="officesGrid-item" key={index}>
            <OfficeBox
              fullView={this.props.fullView}
              firstBooking={firstBooking}
              comingUp={this.props.comingUp}
              myBookings={this.props.myBookings}
              item={item}
              geoLocationCoords={this.props.geoLocationCoords}
              officeTypesData={this.props.officeTypesData}
              showConnections={this.props.showConnections}
              content={this.props.content}
              direction={this.props.direction}
              trans={this.props.trans}
              isWhiteLabel={this.props.isWhiteLabel}
            />
            {firstBooking = false}
          </div>
        );
      }
      return null;
    });
  }

  /**
   * Computes the width of the slide container
   * @method _computeContainerWidth
   * @param {number} size
   * @returns {number | string}
   * @private
   */
  _computeContainerWidth = (size) => {
    if (this.props.direction === 'vertical' || this.props.fullView) {
      return '100%';
    }
    return size * this.ITEM_WIDTH + (size - 1) * this.ITEMS_SPACING
  }

  /**
   * Handles left slide action
   * @method _slideLeft
   * @private
   */
  _slideLeft = () => {
    let offset = this.state.offset;
    offset -= this.ITEM_WIDTH + this.ITEMS_SPACING;

    if (offset < 0) {
      offset = 0;
    }

    this.setState({ offset });
  }

  /**
   * Handles right slide action
   * @method _slideRight
   * @private
   */
  _slideRight = () => {
    let offset = this.state.offset;

    offset += this.ITEM_WIDTH + this.ITEMS_SPACING;

    if (offset > this.maxOffset) {
      offset = this.maxOffset;
    }

    this.setState({ offset });
  }

  /**
   * Prepares and renders navigation for each slider
   * @method _getSliderNavigation
   * @returns {*}
   * @private
   */
  _getSliderNavigation = () => {
    if (this.props.direction === 'vertical') {
      return null;
    }

    const size = this.props.items.size;
    const maxOffset = size * this.ITEM_WIDTH - 3 * this.ITEM_WIDTH;
    const leftClassName = classnames({
      'officesGrid-slideLeft': true,
      disabled: this.state.offset === 0
    });

    const rightClassName = classnames({
      'officesGrid-slideRight': true,
      disabled: maxOffset - this.state.offset <= 0
    });


    return (
      <div className="officesGrid-sidebarNav">
        <div className={ leftClassName } onClick={ this._slideLeft }></div>
        <div className={ rightClassName } onClick={ this._slideRight }></div>
      </div>
    );
  }

  getTitle (props) {
    const itemClass = classnames([
      'officesGrid-title-events',
      props.content === 'upcoming-events' ? 'officesGrid-title-events-upcoming' : ''
    ]);

    if (props.content === 'events' ) {
      return <div className={ itemClass }> { props.title } </div>
    }

    if (props.content === 'upcoming-events'){
      return <div className={ itemClass }>
          { props.title }
          <span className="officesGrid-title-events-near"> {props.trans.events.nearMe} <span className="officesGrid-title-events-total"> {props.items.size}</span></span>
        </div>
    }
    
    return props.title;
  }

  _getTopSection () {
    if (this.props.hideHeader) {
      return null;
    }

    return (
      <div className="officesGrid-topSection">
        <div className="officesGrid-title">
          { this.getTitle(this.props)}
          { this.props.showTotalItems ? (<span className="officesGrid-totalItems">{ this.props.items.size }</span>) : null}
        </div>
        <div className="officesGrid-topInfo">{ this.props.sectionInfo }</div>
      </div>
    )
  }

  render () {
    const itemClass = classnames([
      'officesGrid',
      `officesGrid-${ this.props.type }`,
      this.props.direction ? `officesGrid-${ this.props.direction }` : undefined,
      this.props.className
    ]);

    return (
      <div className={ itemClass } ref={ r => this.grid = r }>
        { this._getSliderNavigation() }
        <div className="officesGrid-container">
          { this._getTopSection() }
          <div className="officesGrid-gridItems" style={{
                  width: this._computeContainerWidth(this.props.items.size),
                  transform: `translateX(-${this.state.offset}px)`
              }}>
            { this.getItemsToRender() }
          </div>
        </div>
      </div>
    );
  }
}

OfficesGrid.propTypes = {
  /**
   * Decides on the type of the grid (primary/secondary) which influences the bg color display
   * @param type
   * @type string
   */
  type: PropTypes.string,

  /**
   * The rendered title inside section header
   * @param title
   * @type string
   */
  title: PropTypes.string,

  /**
   * Additional info to add to the right side of the section
   * @param sectionInfo
   * @type string
   */
  sectionInfo: PropTypes.string,

  /**
   * Collection of offices to render
   * @param offices
   * @type Immutable.List
   */
  items: PropTypes.object,
  /**
   * Flag that determins whether the containing boxes will display connections or not
   * @param showConnections
   * @type boolean
   */
  showConnections: PropTypes.bool,
  /**
   * Flag to enable/disable header display
   * @property hideHeader
   */
  hideHeader: PropTypes.bool,

  showTotalItems: PropTypes.bool
};

OfficesGrid.defaultProps = {
  title: '',
  type: 'principal',
  sectionInfo: '',
  items: Immutable.fromJS([]),
  showConnections: false
};

export default withRouter(OfficesGrid);
