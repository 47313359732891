import { connect } from 'react-redux';

import toJS from '../../../utils/to-js';
import addLoader from '../../../utils/add-loader';

import { isLoadingSelector, isSubmitedSelector, formErrorsSelector } from '../../../../../selectors/dashboard/submission-form';
import { saveSubmission } from '../../../../../actions/dashboard/submission-form';

import SubmissionForm from './SubmissionForm';

const mapDispatchToProps = dispatch => ({
  saveSubmission: data => dispatch(saveSubmission(data)),
});
const mapStateToProps = state => ({
  isLoading: isLoadingSelector(state),
  isSubmited: isSubmitedSelector(state),
  errors: formErrorsSelector(state),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(toJS(addLoader(SubmissionForm)));
