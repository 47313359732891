/**
 * Renders the Home "page" inside HeroBooking app
 * @class Home
 * @module Home
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars';
import _ from 'lodash';
import Immutable from 'immutable';
import { Link, withRouter } from 'react-router-dom';
import classnames from 'classnames';

import MyBookings from './../myBookings/myBookings';
import Sidebar from './../rightSidebar/rightSidebar';
import MapContainer from './../mapContainer/mapContainer';
import PageHeader from './../pageHeader/pageHeader';

import './css/index.scss';
import officeAvailable from './../../assets/svg/map/available.svg';
import officeAvailableSelected from './../../assets/svg/map/available-selected.svg';
import OfficesGrid from "../officesGrid/officesGrid";

const iconsMap = {
    normal: officeAvailable,
    selected: officeAvailableSelected
};

/**
 * Decides what icon to use
 * @param item
 * @param selectedItemId
 * @returns {*}
 */
const getIcon = (item, selectedItemId) => {
    const selected = item.get('id') === selectedItemId ? 'selected' : 'normal';
    return iconsMap[selected];
};

const OFFICES_TO_SHOW = 6;

class Homepage extends React.Component {
    constructor () {
        super();

        this.state = {
            mapX:          null,
            initialized:   false,
            loadMoreIndex: 1,
            selectedCategory: null,
        };

        this.onMapLoaded      = this.onMapLoaded.bind(this);
        this.onBoundsChanged  = this.onBoundsChanged.bind(this);
        this.markerClick      = this.markerClick.bind(this);
        this.getOfficeCategory = this.getOfficeCategory.bind(this);
    }

    setIcons(availableOffices) {
        return availableOffices.map(_item => {
            return _item.set('icon', getIcon(_item, this.props.mapData.get('activeMarkerID')));
        });
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        if(this.props.mapData.toJS().center.lat !== nextProps.mapData.toJS().center.lat || this.props.mapData.toJS().center.lng !== nextProps.mapData.toJS().center.lng)
        {
            this.state.mapX.panTo(nextProps.mapData.toJS().center);
        }

        if(this.props.availableOffices.toJS().length !== nextProps.availableOffices.toJS().length )
        {
            this.props.setZoomToFitApplied();
        }

    }

    componentDidMount () {
        // load items
        const { fetchAvailableOffices } = this.props;
        fetchAvailableOffices();
        if (this.props.mapData.get('activeMarkerID')) {
            this.props.markerClose(this.props.mapData.get('activeMarkerID'));
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { availableOffices } = this.props;
        const { selectedCategory: oldCategory } = prevState;
        this.zoomToFit(availableOffices.toJS());
        if (!oldCategory && availableOffices.size) {
            const countries = availableOffices.toJS().map(office => _.get(office, 'country'));
            const uniqueCountries = countries.filter((country, index, self) => self.indexOf(country) === index);
            const selectedCategory = _.get(_.orderBy(uniqueCountries), '0', null);
            this.setState({selectedCategory})
        }
    }

    componentWillUnmount() {
        const {setSelectedProductId, setSelectedProductType, setSelectedDate, setMapProducts} = this.props;
        setSelectedProductId(null);
        setSelectedProductType(null);
        const today = new Date();
        setSelectedDate(today, today);
        setMapProducts([]);
    }

    _renderMap() {
        const { availableOffices, mapData, history, markerClose, translations, coords } = this.props;
        const availableMapItems = this.setIcons(availableOffices);
        _.set(mapData, 'zoom', 4);
        return (
            <div>
                <div style={{visibility: 'visible', height: 'auto'}}>
                    <MapContainer
                        router={{ history: history }}
                        mapData={ mapData }
                        markers={ availableMapItems.filter(office => office.get('location')) }
                        onMarkerClick={ this.markerClick }
                        setCenter={ false }
                        onMarkerClose={ markerClose }
                        geoLocationCoords={coords}
                        activeMarker={ mapData.get('activeMarkerID') }
                        onBoundsChanged={this.onBoundsChanged}
                        onMapLoad={ (googleMap) => { if(googleMap != null) { this.onMapLoaded(googleMap); return; } } }
                        trans={ translations }
                        minZoom="1" //covers almost entire Europe
                        hideLegend
                    />
                </div>
            </div>
        );
    }

    markerClick(markerID, location) {
        const { markerClick } = this.props;
        markerClick(markerID, location);
    }

    zoomToFit(offices = null){
        const { availableOffices, mapData } = this.props;
        if(offices === null)
        {
            offices = availableOffices.toJS();
        }

        if(this.state.initialized === false)
        {
            if(offices.length > 0 && this.state.mapX !== null)
            {
                if(mapData.get('zoomToFitApplied') === false)
                {
                    this.setState({initialized: true});
                    var bounds = new window.google.maps.LatLngBounds();
                    offices.forEach(office => {
                        bounds.extend(new window.google.maps.LatLng(office.location.latitude, office.location.longitude));
                    });
                    this.state.mapX.fitBounds(bounds);
                }
                else
                {
                    this.state.mapX.panTo(mapData.toJS().center);
                }
            }
        }
    }

    onMapLoaded(map){
        if(this.state.mapX == null)
        {
            this.setState({mapX: map});
        }
    }

    onBoundsChanged() {
        this.delayOnBoundsChanged();
        this.props.onRadiusChange(this.state.mapX);
    };

    delayOnBoundsChanged = _.debounce(function(){
        this.props.onBoundsChanged(this.state.mapX);
    }, 100);


    _getMyBookingsSidebar() {
    //TODO
        let havePersonalRecentBookings = false;
        if(this.props.myBookings.size > 0) {
            havePersonalRecentBookings = true;

        }
        let onlyMyBooking = [];
        let notMyBooking = [];
        let myBookings = _.sortBy(this.props.bookingSummary.toJS(),['fromDate']);
        const activeBookingsOrMeetings = myBookings.filter(booking => booking.ownBooking || booking.isAttendee);
        const hasRecentBookingsOrMeetings = !!activeBookingsOrMeetings.length || havePersonalRecentBookings;
        let bookingsCounter = 0;
        myBookings.map((myBooking) => {
            let tempBooking = {
                'imageUrl': myBooking.office.imageUrl, 'bookings': {0: myBooking}
            };

            if(myBooking.ownBooking || myBooking.isAttendee) {
                onlyMyBooking.push(tempBooking);
            } else {
                notMyBooking.push(tempBooking);
            }
            bookingsCounter++;
            return null;
        });

        let haveMyBookings = false;

        if(this.props.myBookings.size > 0 && onlyMyBooking.length > 0) {
            haveMyBookings = true;
        }
        if(bookingsCounter !== 0 || havePersonalRecentBookings) {
            let myBookingSummaryCode = '';
            if(onlyMyBooking.length !== 0) {
                myBookingSummaryCode = <MyBookings
                    haveMyBookings = {haveMyBookings}
                    comingUp={true}
                    myBookings={true}
                    items={Immutable.fromJS(onlyMyBooking)}
                    personalBooking={bookingsCounter}
                    content='bookings'
                    title={this.props.translations.home.rightSidebar.activeBookingsLabel}
                    trans={this.props.translations}
                    isWhiteLabel={this.props.isWhiteLabel}
                />
                haveMyBookings = false;
            }
            let bookingSummaryCode = '';
            if(notMyBooking.length !== 0) {
                bookingSummaryCode = <MyBookings
                    haveMyBookings = {haveMyBookings}
                    comingUp={false}
                    myBookings={false}
                    items={Immutable.fromJS(notMyBooking)}
                    personalBooking={bookingsCounter}
                    content='bookings'
                    title={this.props.translations.home.rightSidebar.recentlyBookedLabel}
                    trans={this.props.translations}
                    isWhiteLabel={this.props.isWhiteLabel}
                />
            }

            return <div>
                <Link to='/my-bookings' className="homeSidebar-myBookings-link myBookings-link secondary-button">{this.props.translations.home.rightSidebar.myBookingsButton}
                </Link>
                {myBookingSummaryCode}
                {bookingSummaryCode}
            </div>
        }
    }

    getOfficeCategory(country) {
        const { translations } = this.props;
        const { selectedCategory } = this.state;
        return (
            <div className={classnames('officesGrid__category', {'officesGrid__category--selected' : selectedCategory === country})}
                 onClick={() => this.setState({selectedCategory : country, loadMoreIndex: 1})}>
                {_.get(translations, `countries.${country}`, country)}
            </div>
        )
    }

    loadMoreOffices() {
        const { loadMoreIndex } = this.state;
        this.setState({loadMoreIndex: loadMoreIndex+1});
    }

    render () {
        const { translations, availableOffices }  = this.props;
        const { selectedCategory, loadMoreIndex } = this.state;
        if (!translations)
        {
            return <div className="Loader"></div>;
        }
        let sortedCountries = [];
        if (availableOffices.size) {
            const countries = availableOffices.toJS().map(office => _.get(office, 'country'));
            const uniqueCountries = countries.filter((country, index, self) => self.indexOf(country) === index);
            sortedCountries = _.orderBy(uniqueCountries);

        }
        const filteredOffices = availableOffices.filter((item) => item.get('country') === selectedCategory);

        return (
            <div className="homeContainer">
                <section className="homeContainer-container">
                    <Scrollbars className="styleScroll">
                        <div className="homeContainer-mainContent">
                            <PageHeader
                                className="--defaultPadding"
                                pageTitle={translations.home.locations}
                                icon="booking"
                                actions={[]}
                                trans={translations}
                            />
                            { this._renderMap() }
                            {!availableOffices &&  <span id="create-community-loader" className="community__loader" /> }
                            {availableOffices && availableOffices.size && (
                                <>
                                    <div className="officesGrid__categories-container">
                                        { sortedCountries.map(this.getOfficeCategory) }
                                    </div>
                                    <OfficesGrid
                                        items={ filteredOffices.filter((item) => item.get('status') === 'live').slice(0, OFFICES_TO_SHOW*loadMoreIndex) }
                                        className="officesGrid-cutPadding --homePage"
                                        type="secondary"
                                        trans={translations}
                                    />
                                    {(filteredOffices.size > loadMoreIndex * OFFICES_TO_SHOW) ?
                                        (<span className="officesGrid__loadMore-button" onClick={()=>{this.loadMoreOffices()}}>
                                          {_.get(translations, 'home.loadMoreLocations', '')}
                                        </span>) : null
                                    }
                                </>
                            )}
                        </div>
                    </Scrollbars>
                </section>
                <Sidebar className="--full-height">
                    <Scrollbars>
                        { this._getMyBookingsSidebar() }
                    </Scrollbars>
                </Sidebar>
            </div>
        );
    }
}

Homepage.propTypes = {
    /**
     * Contains all info required by the map to render, including markers
     * @property mapData
     * @type {Immutable.Map}
     */
    mapData: PropTypes.object,
};

export default withRouter(Homepage);
