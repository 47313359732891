import Immutable, { fromJS } from 'immutable/dist/immutable';
import { SET_CO_CREATION_PROJECTS, SET_CO_CREATION_PROJECTS_IS_LOADING, SET_ACTIVE_ID } from '../../actions/dashboard/co-creation-projects';

const initialState = Immutable.Map({
  coCreationProjects: fromJS([]),
  activeId: 0,
  isLoading: false,
});

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_CO_CREATION_PROJECTS:
      return state.setIn(['coCreationProjects'], fromJS(action.coCreationProjects));
    case SET_ACTIVE_ID:
      return state.setIn(['activeId'], action.activeId);
    case SET_CO_CREATION_PROJECTS_IS_LOADING:
      return state.setIn(['isLoading'], action.isLoading);
    default:
      return state;
  }
};
