import Immutable from 'immutable';
import { SET_COMMUNITY_PAGE_EDIT_MODAL, SET_COMMUNITY_PAGE_MANAGE_MODAL } from '../../actions/community/community-modals';

const initialState = Immutable.Map({
  communityEditModal: false,
  communityManageModal: false,
});

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_COMMUNITY_PAGE_EDIT_MODAL:
      return state.setIn(['communityEditModal'], action.communityEditModal);
    case SET_COMMUNITY_PAGE_MANAGE_MODAL:
      return state.setIn(['communityManageModal'], action.communityManageModal);
    default:
      return state;
  }
};
