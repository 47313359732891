import _ from 'lodash';
import HealtyMeasuresIcon from '../../assets/svg/map/health-safety-measures_filter.svg';
import TemperatureScanIcon from '../../assets/svg/map/temperature-control-in-place_filter.svg';

export const officeTypes = (officeTypes, translations) => {
  const officeTypesData = _.map(officeTypes, officeType => {
    return { id: officeType.id, label: officeType.name, groupName: 'officesTypes' };
  });
  return _.concat(officeTypesData, { capacity: true, label: 'Capacity' });
};

export const offeredServices = translations => {
  return [
    {
      id: 1,
      label: _.get(translations, 'amenitiesFilters.1', 'Wi-Fi Access def'),
      disabled: true,
      checked: true,
      groupName: 'amenities',
    },
    {
      id: 2,
      label: _.get(translations, 'amenitiesFilters.2', 'Reception Desk def'),
      disabled: true,
      checked: true,
      groupName: 'amenities',
    },
    {
      id: 1,
      label: _.get(translations, 'services.2', 'Parking def'),
      groupName: 'services',
    },
    {
      id: 'temperature_scan_index',
      label: _.get(translations, 'healthMeasures.temperature_scan_index', 'Temperature Control def'),
      filterIcon: TemperatureScanIcon,
      groupName: 'healthMeasures',
    },
    {
      id: 'health_measures_index',
      label: _.get(translations, 'healthMeasures.health_measures_index', 'Health Safety Measures def'),
      filterIcon: HealtyMeasuresIcon,
      groupName: 'healthMeasures',
    },
  ];
};
export const premiumRole = translations => {
  return [
    { id: '1', label: _.get(translations, 'premiumRole.1', 'Premium'), groupName: 'premiumSupplier' },
    { id: '0', label: _.get(translations, 'premiumRole.0', 'Regular'), groupName: 'premiumSupplier' },
  ];
};

export const PRICE_SLIDER = {
  minValue: 2.5,
  maxValue: 250,
};
export const MAX_CAPACITY = 20;
