import React from 'react';
import { withRouter } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars';
import Sidebar from "../rightSidebar/rightSidebar";
import OfficeDetails from "../officeDetails/officeDetails";
import OfficeDetailsPlanFloor from "./components/officeDetails";
import _ from "lodash";
import buildLocationUrl from "../../utils/buildLocationUrl";
import SidebarContent from "./components/sidebarContent"
import blockIncompleteProfileAction from "../../utils/blockIncompleteProfileAction";

class Location extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rendering: true,
      product: {},
      step: 0,
    }
  }

  UNSAFE_componentWillMount() {
    this.setState({
      rendering: true
    });
  }

  componentDidMount()
  {
    const { location, match, history, floorPlans, beforeFetchOfficeDetails, setSelectedFloorIndex } = this.props;
    const fromBooking = _.get(location, 'state.fromBooking', false);
    const fromHero = _.get(location, 'state.fromHero', false);
    beforeFetchOfficeDetails();
    this.historyListener = history.listen((location, action) => {
      const officeId = location.pathname.match(/\d+/g);
      if (officeId && !_.get(location, 'state.stopFetch', false)) {
        this.props.fetchOfficeDetails(officeId.pop());
      }
    });

    this.props.fetchOfficeDetails(match.params.id);
    this.props.fetchOfficeTypes();
    this.setState({rendering: false});
    const bookingValidate = this.props.bookingValidate;
    if (bookingValidate || fromBooking || fromHero) {
      this.props.openBookingOptions();
    }
  }
  componentDidUpdate(prevState) {
    const {officeDetails: oldOfficeDetails} = prevState;
    const {officeDetails} = this.props;
    if (officeDetails.size > 0 && oldOfficeDetails.size !==  officeDetails.size ){
      this.props.history.replace(buildLocationUrl(this.props.officeDetails.toJS()));
    }
  }
  componentWillUnmount() {
    this.props.closeBookingOptions();
    this.historyListener();
    const {setSelectedProduct, setSelectedProductType, setSelectedDate, setSelectedFloorIndex, setMapProducts} = this.props;
    setSelectedProduct(null);
    setSelectedProductType(null);
    const today = new Date();
    setSelectedDate(today, today);
    setSelectedFloorIndex(0);
    setMapProducts([]);
  }

  getDefaultSidebar = function () {
    let officeDetails = this.props.officeDetails.toJS()
    if (typeof officeDetails.products !== "undefined") {
      let renderOfficeTypesCounts = _.map(officeDetails.products, (data, key) => {
        if (data.length > 0) {
          return (<div className="not-booked-office-space-item">
            <div className="not-booked-office-space-item-label">{_.get(this.props.translations.location, `${key}`, key)}</div>
            <div className="not-booked-office-space-item-value">{data.length}</div>
          </div>);
        }
        return  null;
      })
      return <Sidebar>
        <div className="not-booked-location">
          <button className="main-button" onClick={() =>
              blockIncompleteProfileAction()
                  .then(() => {
                    this.props.history.push({
                      pathname: '/account/profile/incomplete',
                      fromUrl: _.get(this.props.location, 'pathname', null),
                      state: { fromBooking: true },
                    });
                  })
                  .catch(() => {
                    this.props.openBookingOptions()

                  })}
          >
            {this.props.translations.location.bookLocation}
          </button>

          <div className="not-booked-title">{this.props.translations.location.availableOfficeSpaces}</div>
          {renderOfficeTypesCounts}

        </div>
      </Sidebar>;
    }
    return  null;
  }

  render() {
    const { bookingOptionsOpened, officeDetails } = this.props;
    const { rendering} = this.state;
    if(rendering || !officeDetails || !officeDetails.toJS().white_label )
    {
      return (
        <div className="officeDetailsLoader">
        </div>
      );
    }
    let getSidebar = '';
    if (bookingOptionsOpened) {
      getSidebar = <Sidebar>
        <div className="booking-sidebar"><SidebarContent/></div>
      </Sidebar>;
    } else {
        getSidebar = this.getDefaultSidebar();
      }

    return (
      <div className="location-container">
        <section className="location-content">
          <Scrollbars className="styleScroll">
            { bookingOptionsOpened ?
              <OfficeDetailsPlanFloor/> :
              <OfficeDetails
                details={this.props.officeDetails.toJS()}
                openSidePopup={this.props.openSidePopup}
                bookingSection={this.props.bookingSection}
                trans={this.props.translations}
              />
            }

          </Scrollbars>
        </section>
        { getSidebar }
      </div>
    );
  }
}
export default withRouter(Location);
