import Immutable from 'immutable';
import { SET_MAP_PRODUCTS, SET_MAP_PRODUCTS_IS_LOADING, SET_MUST_LOGOUT } from '../../actions/whiteLabel/products';

const initialState = Immutable.Map({
  mapProducts: [],
  isLoading: false,
  mustLogout: false,
});

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_MAP_PRODUCTS:
      return state.setIn(['mapProducts'], action.mapProducts);
    case SET_MAP_PRODUCTS_IS_LOADING:
      return state.setIn(['isLoading'], action.isLoading);
    case SET_MUST_LOGOUT:
      return state.setIn(['mustLogout'], action.mustLogout);
    default:
      return state;
  }
};
