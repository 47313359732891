import Immutable from 'immutable';
import _ from 'lodash';
import {
  CLEAR_VOUCHER,
  CREATE_HERO_PAYMENT_PROFILE,
  FETCH_BOOKING_OPTIONS,
  FETCH_BOOKING_VALIDATE,
  FETCH_PAYMENT_PROFILES,
  ON_FINISH_BOOKING,
  REDEEM_VOUCHER,
  ON_CHANGE_SECTION,
  SET_SELECTED_ROOM,
  SET_SELECTED_PRODUCT,
  SET_SELECTED_OPTIONS,
  SET_SELECTED_SERVICES,
  RESET_BOOKING,
  RESET_VOUCHER_INFO,
  CLEAR_BOOKING_ERRORS,
  DELETE_HERO_PAYMENT_PROFILE,
  FETCH_PAYMENT_PROFILE,
  BEFORE_FETCH_PAYMENT_PROFILE,
  SUBSCRIPTION_UPDATE_IN_PROGRESS,
  UPDATE_HERO_PAYMENT_PROFILE,
  SET_EVENT_REQUEST_DETAILS,
  FETCH_CHECK_AVAILABILITY,
  BEFORE_UPDATE_HERO_PAYMENT_PROFILE,
  FETCH_PAYMENT_METHODS,
  SET_SELECTED_PAYMENT_METHOD,
  SET_USE_SPENDING_LIMIT,
  SET_MEETING_ROOM_TITLE,
  SET_MEETING_ROOM_COMMENT,
} from '../actions/locationActions';

const defaultState = Immutable.fromJS({
  bookingErrors: null,
  paymentConfirmationRequiresAction: null,
  bookingOptions: null,
  bookingOptionsErrors: null,
  bookingResponse: null,
  bookingSection: 'options',
  bookingValidate: null,
  bookingValidateErrors: null,
  paymentProfileErrors: null,
  paymentProfiles: null,
  paymentProfile: null,
  paymentMethods: null,
  paymentMethodErrors: null,
  selectedPaymentMethod: null,
  selectedProduct: null,
  selectedRoom: null,
  selectedOptions: {},
  selectedServices: [],
  voucherInfo: null,
  subscrUpdateInProgress: false,
  paymentProfileUpdateInProgress: false,
  eventRequestDetails: '',
  checkAvailability: {},
  useSpendingLimit: true,
  meetingRoomTitle: '',
  meetingRoomComment: '',
});

const addBooking = (state = defaultState, action) => {
  let newState;
  let errors = null;
  switch (action.type) {
    case BEFORE_UPDATE_HERO_PAYMENT_PROFILE:
      newState = state.set('paymentProfileUpdateInProgress', true).set('paymentProfileErrors', null);
      break;
    case UPDATE_HERO_PAYMENT_PROFILE:
      //
      if (action.data === null) {
        newState = state
          .set('paymentProfileErrors', Immutable.fromJS(action.errors))
          .set('paymentProfileUpdateInProgress', false)
          .set('subscrUpdateInProgress', false);
      } else {
        newState = state
          .set('paymentProfileErrors', Immutable.fromJS(action.errors))
          .set('paymentProfiles', Immutable.fromJS([action.data]))
          .set('paymentProfileUpdateInProgress', false)
          .set('subscrUpdateInProgress', false);
      }
      break;
    case SUBSCRIPTION_UPDATE_IN_PROGRESS:
      newState = state.set('subscrUpdateInProgress', true);
      break;
    case FETCH_PAYMENT_PROFILES:
      if (action.data === null) {
        newState = state.set('paymentProfileErrors', Immutable.fromJS(action.errors));
      } else {
        // if no payment profile comes selected  - select the first one
        let selected = false;
        if (action.data.length > 0) {
          action.data.map(paymentProfile => {
            if (paymentProfile.isDefault === true) {
              selected = true;
            }
            return null;
          });
          if (selected === false) {
            action.data[0].isDefault = true;
          }
        }
        newState = state.set('paymentProfileErrors', Immutable.fromJS(action.errors)).set('paymentProfiles', Immutable.fromJS(action.data));
      }

      break;
    case FETCH_PAYMENT_METHODS:
      if (action.data.payment_methods === null) {
        newState = state.set('paymentMethodErrors', Immutable.fromJS(action.errors));
      } else {
        const paymentMethods = action.data.payment_methods;
        let selectedPaymentMethod = paymentMethods.filter(paymentMethod => paymentMethod.is_default === 1);
        if (selectedPaymentMethod.length === 0) {
          selectedPaymentMethod = paymentMethods;
        }
        newState = state
          .set('paymentMethodErrors', Immutable.fromJS(action.errors))
          .set('selectedPaymentMethod', selectedPaymentMethod[0])
          .set('paymentMethods', paymentMethods)
          .set('spendingLimit', action.data.spending_limit);
      }
      break;
    case SET_SELECTED_PAYMENT_METHOD:
      newState = state.set('selectedPaymentMethod', action.paymentMethod);
      if (action.paymentMethod.type === 'voucher') {
        newState = newState.set('useSpendingLimit', false);
      }
      break;
    case CREATE_HERO_PAYMENT_PROFILE:
      if (action.data === null) {
        newState = state.set('paymentProfileErrors', Immutable.fromJS(action.errors)).set('paymentProfileUpdateInProgress', false);
      } else {
        newState = state
          .set('paymentProfileErrors', Immutable.fromJS(action.errors))
          .set('paymentProfiles', Immutable.fromJS([action.data.paymentProfile]))
          .set('paymentProfileUpdateInProgress', false);
      }
      break;

    case DELETE_HERO_PAYMENT_PROFILE:
      newState = state.set('paymentProfileErrors', Immutable.fromJS(action.errors));
      break;

    case BEFORE_FETCH_PAYMENT_PROFILE:
      newState = state.set('paymentProfile', null);
      break;

    case FETCH_PAYMENT_PROFILE:
      newState = state.set('paymentProfile', action.data);
      break;

    case CLEAR_VOUCHER:
      newState = state.set('voucherInfo', null);
      break;

    case ON_CHANGE_SECTION:
      newState = state.set('bookingSection', action.bookingSection);
      break;
    case SET_MEETING_ROOM_TITLE:
      newState = state.set('meetingRoomTitle', action.title);
      break;
    case SET_MEETING_ROOM_COMMENT:
      newState = state.set('meetingRoomComment', action.comment);
      break;
    case SET_SELECTED_ROOM:
      newState = state
        .set('selectedRoom', action.room)
        .set('bookingValidateErrors', null)
        .set('bookingValidate', null);
      if (action.room === null) {
        newState = newState.set('bookingOptionsErrors', null).set('bookingOptions', null);
      }
      break;

    case SET_SELECTED_PRODUCT:
      newState = state.set('selectedProduct', action.product);
      break;

    case SET_SELECTED_OPTIONS:
      newState = state.set('selectedOptions', action.selectedOptions);
      if (_.isEmpty(action.selectedOptions)) {
        newState = newState
          .set('bookingOptionsErrors', null)
          .set('bookingOptions', null)
          .set('bookingValidateErrors', null)
          .set('bookingValidate', null);
      }
      break;

    case SET_SELECTED_SERVICES:
      newState = state.set('selectedServices', action.selectedServices);
      break;

    case RESET_BOOKING:
      newState = state
        .set('selectedProduct', null)
        .set('selectedRoom', null)
        .set('selectedOptions', {})
        .set('selectedServices', [])
        .set('bookingOptionsErrors', null)
        .set('bookingOptions', null)
        .set('bookingValidateErrors', null)
        .set('bookingValidate', null)
        .set('voucherInfo', null);
      break;

    case RESET_VOUCHER_INFO:
      newState = state.set('voucherInfo', null);
      break;

    case REDEEM_VOUCHER:
      newState = state.set('voucherInfo', Immutable.fromJS(action.data));
      break;
    case FETCH_BOOKING_OPTIONS:
      errors = _.get(action.errors, 'errors', _.get(action, 'errors', null));
      if (action.data === null) {
        newState = state.set('bookingOptionsErrors', Immutable.fromJS(errors));
      } else {
        newState = state.set('bookingOptionsErrors', Immutable.fromJS(errors)).set('bookingOptions', Immutable.fromJS(action.data));
      }
      break;
    case FETCH_BOOKING_VALIDATE:
      errors = _.get(action.errors, 'errors', _.get(action, 'errors', null));
      if (action.data === null) {
        newState = state.set('bookingValidateErrors', Immutable.fromJS(errors)).set('bookingErrors', null);
      } else {
        newState = state
          .set('bookingValidateErrors', Immutable.fromJS(errors))
          .set('bookingValidate', Immutable.fromJS(action.data))
          .set('bookingErrors', null);
      }
      break;

    case CLEAR_BOOKING_ERRORS:
      newState = state.set('bookingErrors', null);
      break;

    case ON_FINISH_BOOKING:
      newState = state.set('bookingErrors', Immutable.fromJS(action.errors));
      if (action.data !== null) {
        if (action.data.code === 'invoice_payment_intent_requires_action') {
          newState = state.set('paymentConfirmationRequiresAction', Immutable.fromJS(action.data.clientSecret));
        }
        if (action.data.externalPaymentIntent !== null && action.data.externalPaymentIntent !== undefined) {
          newState = state.set('externalPaymentIntent', action.data.externalPaymentIntent);
        }

        if (action.data.bookingId !== null && action.data.bookingId !== undefined) {
          newState = state.set('bookingResponse', action.data);
          window.location = `${window.__env.baseUrl}/booking/${action.data.bookingId}/confirmed`;
        }
        if (action.data.bookingId === null) {
          // no error has occurred // booking for Event Room was confirmed
          newState = state.set('bookingResponse', { eventRoomRequestSent: true });
        }
      }
      break;
    case SET_EVENT_REQUEST_DETAILS:
      newState = state.set('eventRequestDetails', action.details);
      break;
    case FETCH_CHECK_AVAILABILITY:
      newState = state.set('checkAvailability', action.data);
      break;
    case SET_USE_SPENDING_LIMIT:
      newState = state.set('useSpendingLimit', action.value);
      break;
    default:
      newState = state;
  }
  return newState;
};

export default addBooking;
