import React, { Component } from 'react';
import Truncate from 'react-truncate';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import _ from 'lodash';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import closeIcon from '../../../../assets/svg/community/close-modal.svg';
import closeIconRed from '../../../../assets/svg/community/close-modal-red.svg';
import textIcon from '../../../../assets/svg/community/community-text.svg';
import textIconHover from '../../../../assets/svg/community/community-text-black.svg';
import textIconDisabled from '../../../../assets/svg/community/community-text-disabled.svg';
import photoIcon from '../../../../assets/svg/community/community-photo.svg';
import photoIconHover from '../../../../assets/svg/community/community-photo-black.svg';
import photoIconDisabled from '../../../../assets/svg/community/community-photo-disabled.svg';
import videoIcon from '../../../../assets/svg/community/community-video.svg';
import videoIconHover from '../../../../assets/svg/community/community-video-black.svg';
import videoIconDisabled from '../../../../assets/svg/community/community-video-disabled.svg';
import documentIcon from '../../../../assets/svg/community/community-file.svg';
import documentIconHover from '../../../../assets/svg/community/community-file-black.svg';
import documentIconDisabled from '../../../../assets/svg/community/community-file-disabled.svg';
import recycleIcon from '../../../../assets/svg/community/community-recycle-icon.svg';
import { fileIsAllowed } from '../../utils/config';

import { filesizeFormatter } from '../../utils/filesize-formatter';
import getAttachmentTypeImage from '../create-post/utils/utils';
import Embedder from '../../utils/embedder';

const { CancelToken } = axios;

class EditPostForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFilled: true,
      fileType: null,
      attachment: null,
      content: null,
      attachmentSize: null,
      formErrors: [],
      selectedAttachment: {},
      uploadedAttachment: {},
      uploadInProgress: false,
      iconClass: {
        text: 'active-item',
        photo: '',
        video: '',
        document: '',
      },
    };

    this.fileUploadInputRef = React.createRef();
    this.cancelTokenRef = React.createRef();
    this.triggerUpload = this.triggerUpload.bind(this);
    this.handlePostButtonStatus = this.handlePostButtonStatus.bind(this);
    this.handleTextPost = this.handleTextPost.bind(this);
    this.clearFormErrors = this.clearFormErrors.bind(this);
    this.handleUpload = this.handleUpload.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.renderUploadAttachmentPreview = this.renderUploadAttachmentPreview.bind(this);
    this.handleIcon = this.handleIcon.bind(this);
    this.resetHandleIcon = this.resetHandleIcon.bind(this);
  }

  componentDidMount() {
    const { postData } = this.props;
    const postAttachment = _.get(postData, 'attachments[0][originalName]', '');
    const attachmentSize = _.get(postData, 'attachments[0][attachmentSize]', 0);
    const attachmentType = _.get(postData, 'attachments[0][attachmentType]', 'document');
    const content = _.get(postData, 'content', '');
    this.setState({
      content,
    });
    if (postAttachment) {
      this.setState({
        attachmentSize,
        selectedAttachment: postAttachment,
        uploadedAttachment: { originalName: postAttachment },
        fileType: attachmentType,
      });
      this.handleIcon(attachmentType);
    }
  }

  renderProgressBar() {
    const { isUploading, uploadedPercent, translations } = this.props;
    const { selectedAttachment, fileType } = this.state;
    const icon = getAttachmentTypeImage(fileType);
    const filename = _.get(selectedAttachment, 'name', 0);
    const filesize = _.get(selectedAttachment, 'size', 0);
    const of = _.get(translations, 'postForm.of', 0);
    const uploading = _.get(translations, 'postForm.uploading', 'postForm.uploading');
    const formattedFilesize = filesizeFormatter(filesize);
    const uploaded = filesizeFormatter((uploadedPercent * filesize) / 100);

    if (!isUploading) {
      return null;
    }

    return (
      <div className="upload-progress-bar__container">
        <img src={icon} className="upload-progress-bar__img" alt={filename} />
        <div className="upload-progress-bar__info">
          <div className="upload-progress-bar__filename">{filename}</div>
          <div className="upload-progress-bar__indicator">
            <div className="floater" style={{ width: `${uploadedPercent}%` }} />
          </div>
          <div className="upload-progress-bar__status">
            <div className="upload-progress-bar__size">{`${uploaded} ${of} ${formattedFilesize}`}</div>
            <div className="upload-progress-bar__percent">
              {uploading} {uploadedPercent}%
            </div>
          </div>
        </div>
        <img
          className="upload-progress-bar-cancel"
          src={closeIconRed}
          alt="Cancel"
          onClick={() => this.cancelTokenRef.current.cancel('Message')}
        />
      </div>
    );
  }

  handleTextPost(event) {
    this.clearFormErrors();
    const content = event.target.value.length > 0 ? event.target.value : null;

    this.setState({ content }, function() {
      this.handlePostButtonStatus();
    });
  }

  renderAttachmentPreview() {
    const { content } = this.state;
    return (
      <div className="embedded-media__container">
        <Embedder content={content} />
      </div>
    );
  }

  handleIcon(type) {
    switch (type) {
      case 'image':
        this.setState({ iconClass: { text: 'disabled-item', photo: 'active-item', video: 'disabled-item', document: 'disabled-item' } });
        break;
      case 'video':
        this.setState({ iconClass: { text: 'disabled-item', photo: 'disabled-item', video: 'active-item', document: 'disabled-item' } });
        break;
      case 'document':
        this.setState({ iconClass: { text: 'disabled-item', photo: 'disabled-item', video: 'disabled-item', document: 'active-item' } });
        break;
      default:
        this.setState({ iconClass: { text: 'active-item', photo: 'disabled-item', video: 'disabled-item', document: 'disabled-item' } });
    }
  }

  resetHandleIcon() {
    this.setState({ iconClass: { text: 'active-item', photo: '', video: '', document: '' } });
  }

  handleUpload(e) {
    const { translations, uploadAttachment } = this.props;
    const { fileType, formErrors } = this.state;
    const attachment = e.target.files[0];
    this.setState({ attachmentSize: attachment.size, selectedAttachment: attachment });

    if (!fileIsAllowed(attachment.name, attachment.type, fileType)) {
      formErrors.invalidAttachmentType = _.get(translations, 'postForm.invalidFileType', 'postForm.invalidFileType');
      this.resetHandleIcon();
    } else {
      this.handleIcon(fileType);
    }

    if (!_.isEmpty(formErrors)) {
      this.setState({ formErrors });
    } else {
      this.setState({ uploadInProgress: true });
      this.cancelTokenRef.current = CancelToken.source();
      uploadAttachment({ attachment, cancelToken: this.cancelTokenRef.current })
        .then(uploadedAttachment => {
          this.setState({ uploadedAttachment, uploadInProgress: false }, this.handlePostButtonStatus);
        })
        .catch(() => {
          this.resetHandleIcon();
        });
      this.setState({ attachment: true }, this.handlePostButtonStatus);
    }
  }

  handlePostButtonStatus() {
    const { attachment, content, uploadInProgress } = this.state;
    if (uploadInProgress || !content || (attachment && !content)) {
      this.setState({ isFilled: false });
    } else {
      this.setState({ isFilled: true });
    }
  }

  clearFormErrors() {
    this.setState({ formErrors: {} });
  }

  submitForm() {
    const { content, uploadedAttachment, fileType } = this.state;
    const { updatePost, community, closeModal, showCommunitiesPageToast, history, postData } = this.props;
    const communityId = _.get(community, 'id', null);
    const filename = _.get(uploadedAttachment, 'filename', null);
    const originalName = _.get(uploadedAttachment, 'originalName', null);
    const attType = !filename ? null : fileType;
    const postId = _.get(postData, 'id', null);
    const formData = {
      content,
      communityId,
      filename,
      originalName,
      attachmentType: attType,
      postId,
    };

    updatePost(formData)
      .then(() => {
        closeModal();
      })
      .catch(response => {
        const errorCode = _.get(response, 'errorCode', null);
        if (errorCode) {
          history.replace({
            pathname: `/communities#errorCode=${errorCode}`,
          });
        }
      });
  }

  triggerUpload(type, status) {
    if (status === 'disabled-item') {
      return;
    }
    const { uploadedAttachment } = this.state;

    if (Object.keys(uploadedAttachment).length > 0) {
      return;
    }

    this.clearFormErrors();
    this.setState({ fileType: type });
    this.fileUploadInputRef.current.click();
  }

  renderUploadAttachmentPreview() {
    const { uploadedAttachment, fileType, attachmentSize } = this.state;
    if (_.isEmpty(uploadedAttachment)) {
      return null;
    }
    const attIcon = getAttachmentTypeImage(fileType);
    const name = _.get(uploadedAttachment, 'originalName', '');
    const size = filesizeFormatter(attachmentSize);

    const action = () => {
      this.setState({
        uploadedAttachment: {},
        fileType: null,
        iconClass: { text: 'active-item', photo: '', video: '', document: '' },
      });
    };

    return (
      <div className="create-post-attachment">
        <img className="attachment-icon" src={attIcon} alt={fileType} />
        <div className="attachment-details-wrapper">
          <div className="attachment-name">
            <Truncate lines={1} ellipsis={<>...</>}>
              {name}
            </Truncate>
          </div>
          <div className="attachment-size">{size}</div>
        </div>
        <div className="attachment-delete">
          <img className="attachment-delete-img" id="attachment-delete" src={recycleIcon} alt="Delete" onClick={() => action()} />
        </div>
      </div>
    );
  }

  render() {
    const { closeModal, translations, isLoading, postData, editPostWidth } = this.props;
    const { isFilled, formErrors, iconClass } = this.state;
    const postLabel = _.get(translations, 'postForm.postButton', 'postButton');
    const createNewPost = _.get(translations, 'postForm.createNewPost', 'createNewPost');
    const error = Object.keys(formErrors).length > 0 ? formErrors[Object.keys(formErrors)[0]] : null;
    const postContent = _.get(postData, 'content', '');
    return (
      <>
        <div className="create-post-modal-overlay" />
        <div className="create-new-post-modal edit-post-modal" style={{ width: editPostWidth }}>
          <input
            type="file"
            ref={this.fileUploadInputRef}
            onChange={this.handleUpload}
            onClick={event => {
              event.target.value = null;
            }}
            name="attachment"
            className="attachment__input"
          />
          <div className="new-post-wrapper">
            <img className="new-post-close-modal" src={closeIcon} alt="Close" onClick={closeModal} />
            <div className="new-post-top-section">
              <textarea
                className="new-post-textarea-input"
                placeholder={createNewPost}
                onChange={this.handleTextPost}
                defaultValue={postContent}
              />
            </div>
            <div className="new-post-middle-section">
              {this.renderAttachmentPreview()}
              {this.renderProgressBar()}
              {this.renderUploadAttachmentPreview()}
            </div>
            <div className="new-post-bottom-section">
              <div className="icons-wrapper">
                <div className={`icons-wrapper-item ${iconClass.text}`} id="new-post-text" onClick={e => this.handleTextPost}>
                  <img src={textIcon} className="icons-wrapper-item-img" alt="Text Icon" />
                  <img src={textIconHover} className="icons-wrapper-item-img--hover" alt="Text Icon" />
                  <img src={textIconDisabled} className="icons-wrapper-item-img--disabled" alt="Text Icon" />
                </div>
                <div
                  className={`icons-wrapper-item ${iconClass.photo}`}
                  id="new-post-photo"
                  onClick={() => this.triggerUpload('image', iconClass.photo)}
                >
                  <img src={photoIcon} className="icons-wrapper-item-img" alt="Camera Icon" />
                  <img src={photoIconHover} className="icons-wrapper-item-img--hover" alt="Camera Icon" />
                  <img src={photoIconDisabled} className="icons-wrapper-item-img--disabled" alt="Camera Icon" />
                </div>
                <div
                  className={`icons-wrapper-item ${iconClass.video}`}
                  id="new-post-video"
                  onClick={() => this.triggerUpload('video', iconClass.video)}
                >
                  <img src={videoIcon} className="icons-wrapper-item-img" alt="Video Icon" />
                  <img src={videoIconHover} className="icons-wrapper-item-img--hover" alt="Video Icon" />
                  <img src={videoIconDisabled} className="icons-wrapper-item-img--disabled" alt="Video Icon" />
                </div>
                <div
                  className={`icons-wrapper-item ${iconClass.document}`}
                  id="new-post-document"
                  onClick={() => this.triggerUpload('document', iconClass.document)}
                >
                  <img src={documentIcon} className="icons-wrapper-item-img" alt="Document Icon" />
                  <img src={documentIconHover} className="icons-wrapper-item-img--hover" alt="Document Icon" />
                  <img src={documentIconDisabled} className="icons-wrapper-item-img--disabled" alt="Document Icon" />
                </div>
              </div>
              {!isLoading && (
                <button
                  type="button"
                  id="create-new-post"
                  className={classnames('entity-button', 'entity-button--yellow', 'create-new-post-btn', {
                    'create-new-post-btn--disabled': !isFilled,
                  })}
                  onClick={this.submitForm}
                  disabled={!isFilled}
                >
                  {postLabel}
                </button>
              )}
              {isLoading && <span id="create-post-loader" className="new-post-wrapper__loader" />}
            </div>
            {error && <div className="validation-errors">{error}</div>}
          </div>
        </div>
      </>
    );
  }
}

EditPostForm.propTypes = {
  updatePost: PropTypes.func.isRequired,
  isUploading: PropTypes.bool,
  isLoading: PropTypes.bool,
  uploadedPercent: PropTypes.number,
  translations: PropTypes.shape({}).isRequired,
  community: PropTypes.shape({}).isRequired,
  uploadAttachment: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  showCommunitiesPageToast: PropTypes.func.isRequired,
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
  }).isRequired,
};

EditPostForm.defaultProps = {
  isUploading: false,
  isLoading: false,
  uploadedPercent: 0,
};

export default withRouter(EditPostForm);
