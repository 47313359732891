import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'moment/locale/en-gb';
import Calendar from 'react-datepicker';
import classnames from 'classnames';
import CalendarIcon from '../../../assets/svg/datePicker/calendar-icon.svg';
import { DATE_FORMAT_EXPERIENCE } from '../../../constants';

class DatePicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: null,
    };
  }

  componentDidMount() {
    const { date } = this.props;
    if (date !== null) {
      this.setState({
        date: new Date(date),
      });
    }
  }

  handleChangeRange(date) {
    this.setState({ date: Date.parse(date) });
  }

  render() {
    const { onDateChange, path, error, date } = this.props;
    const showDate = date !== null ? Date.parse(date) : null;
    return (
      <div
        className={classnames(
          'datepicker__container',
          { 'datepicker__container--active': showDate },
          { 'profile-form__input profile-form__input-error': error }
        )}
        onClick={this.expandDatePicker}
      >
        <img className="datepicker__input-icon" src={CalendarIcon} alt="" />
        <Calendar
          selected={showDate}
          onChange={date => {
            onDateChange(date, path);
            this.handleChangeRange(date);
          }}
          placeholderText={showDate ? new Date(date) : 'Select date'}
          dateFormat="MMMM, yyyy"
          showMonthYearPicker
        />
      </div>
    );
  }
}

DatePicker.propTypes = {
  date: PropTypes.string,
  path: PropTypes.string,
  error: PropTypes.bool,
  onDateChange: PropTypes.func,
};

DatePicker.defaultProps = {
  date: null,
  path: null,
  error: null,
  onDateChange: () => null,
};

export default DatePicker;
