import { withRouter } from 'react-router-dom';
import React from 'react';
import _ from 'lodash';
import homescreenImage from '../../assets/images/whitelabel/homescreen.png';
import Footer from '../openAuth/Footer';
import LanguageSelector from '../header/languageSelector';

const WmsHomescreen = props => {
  return (
    <div id="wms-homescreen">
      <div className="wmsHomescreen__container">
        <div className="wmsHomescreen__header">
          <div className="wmsHomescreen__logo" />
          <LanguageSelector />
        </div>
        <div className="wmsHomescreen__main-content">
          <div className="wmsHomescreen__left-content">
            <img src={homescreenImage} className="wmsHomescreen__image" alt="Homescreen" />
            <a href={`${window.__env.public_site}`} target="_blank" className="wmsHomescreen__button" rel="noreferrer">
              {_.get(props.translations, 'visitOurWebsite', 'Visit our website')}
            </a>
          </div>
          <div className="wmsHomescreen__right-content">
            <div className="wmsHomescreen__role-item">
              <span className="wmsHomescreen__role-type">{_.get(props.translations, 'asAUser', 'As a user')}</span>
              <span className="wmsHomescreen__role-text">
                {_.get(
                  props.translations,
                  'userText',
                  'If you have been invited by your company to use the workplace management software then click here. If not, please see with your company admin to invite you.'
                )}
              </span>
              <a href="/login" className="wmsHomescreen__button" rel="noreferrer">
                {_.get(props.translations, 'loginAsUser', 'Login as a user')}
              </a>
            </div>
            <div className="wmsHomescreen__role-item">
              <span className="wmsHomescreen__role-type">{_.get(props.translations, 'asAnAdmin', 'As an admin')}</span>
              <span className="wmsHomescreen__role-text">
                {_.get(
                  props.translations,
                  'adminText',
                  'If you have been invited as an admin for your company, please login here. If you want to be added as admin on your company account, please see with your company admin to invite you.'
                )}
              </span>
              <a href={`${window.__env.backendUrl}/auth/login`} target="_blank" className="wmsHomescreen__button" rel="noreferrer">
                {_.get(props.translations, 'loginAsAdmin', 'Login as an admin')}
              </a>
            </div>
            <p
              className="wmsHomescreen__contact-us"
              dangerouslySetInnerHTML={{
                __html: _.replace(
                  _.get(
                    props.translations,
                    'ifYouHaveQuestions',
                    'If you have questions, please send an email to <a href=mailto:wmssupport@workero.com>wmssupport@workero.com</a> or talk to us through the online chat.'
                  ),
                  '<a',
                  '<br><a'
                ),
              }}
            />
          </div>
        </div>
        <Footer translations={props.openAuthTranslations} />
      </div>
    </div>
  );
};
export default withRouter(WmsHomescreen);
