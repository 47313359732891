import LocalizedStrings from 'react-localization';

import { GET_TRANSLATIONS } from '../actions/global';

const defaultState = null;

const translations = (state = defaultState, action) => {
  let newState;

  switch (action.type) {
    case GET_TRANSLATIONS:
      if (state === null) {
        newState = new LocalizedStrings(action.data);
      } else {
        newState = state.setContent(action.data);
      }

      break;

    default:
      newState = state;
  }

  return newState;
};

export default translations;
