/**
 * Renders the main Direct Chat view
 * @module DirectChat
 * @class DirectChat
 */

import Immutable from 'immutable';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import AboutContact from './components/aboutContact';
import ContactsList from './components/contactsList';
import MessageWindow from './components/messageWindow';
import ModalDialog from './../modalDialog/modalDialog';
import Sidebar from './../rightSidebar/rightSidebar';

import './css/index.scss';

class DirectChat extends Component {
  constructor() {
    super();
    this.interval = null;
    this.handleBodyClick = this.handleBodyClick.bind(this);
  }

  componentDidMount() {

    if(this.props.connectionsInitialLoaded === false)
    {
        this.props.actions.fetchChatConnections();
    }

    if(this.props.conversationsInitialLoaded === false)
    {
        this.props.actions.fetchDirectChatConversations();
    }
    document.body.addEventListener('click', this.handleBodyClick);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
    this.interval = null;
    document.body.removeEventListener('click', this.handleBodyClick);
    this.props.actions.closeActiveConversation();
    this.props.actions.fetchChatConnections();
    this.props.actions.fetchDirectChatConversations();
  }

  handleBodyClick(e) {
    if (
      !e.target.classList.contains('directChat-messages-optionsItem') &&
      !e.target.classList.contains('directChat-messages-optionsIcon') &&
      !e.target.classList.contains('directChat-messages-optionsButton')
    ) {
      this.props.actions.toggleConversationOptions(false);
    }
  }

  render () {

    // console.log('%c directChat _______PROPS', 'background: #bada55; color: black', this.props.conversations.toJS());
    // console.log('%c directChat _______STATE', 'background: #101F28; color: #F9AF45', this.state);

    let { meta } = this.props;
    let newMeta = meta.toJS();
    let trans = this.props.translations;

    if(this.props.conversations.size === 0 && this.props.conversationsInitialLoaded === false)
    {
      newMeta.showConversations = false;
    }

    if(this.props.conversationsInitialLoaded === false) return <div className="Loader"></div>;
    if(trans === null) return <div className="Loader"></div>;

    let activeConversation = this.props.conversations.find(conversation => conversation.get('active') === true) || Immutable.Map();
    
    let conversationHeroDetails = this.props.connections.find(connection => connection.get('id') === activeConversation.getIn(['hero', 'id'])) || Immutable.Map();

    return (
      <div className="directChat">
        <section className="directChat-contacts-container">
          <div className="directChat-contactsContent">
            <ContactsList
              setToUpdateScrollContacts={this.setToUpdateScrollContacts}
              saveScrollStateContacts={this.saveScrollStateContacts}
              actions={this.props.actions}
              connections={this.props.connections}
              conversations={this.props.conversations}
              meta={Immutable.fromJS(newMeta)}
              now={this.props.now}
              heroProfile={this.props.heroProfile}
              scrollHeightContacts={this.props.scrollHeightContacts}
              storeScrollHeightContacts={this.props.actions.storeScrollHeightContacts}
              trans={trans}
            />
          </div>
        </section>

        <section className="directChat-messages-container">
          <div className="directChat-messagesContent">
            <MessageWindow
              actions={this.props.actions}
              conversation={activeConversation}
              hasConversations={this.props.conversations.size > 0}
              meta={this.props.meta}
              now={this.props.now}
              heroProfile={this.props.heroProfile}
              sendChatMessage={this.props.sendChatMessage}
              lastFetchedEpoch={this.props.lastFetchedEpoch}
              scrollHeight={this.props.scrollHeight}
              fetchDirectChatConversations={this.props.actions.fetchDirectChatConversations}
              trans={trans}
            />
          </div>
        </section>

        <Sidebar>
          <div className="directChat-aboutContent">
            <AboutContact
              actions={this.props.actions}
              conversation={activeConversation}
              conversationHeroDetails={conversationHeroDetails}
              trans={trans}
            />
          </div>
        </Sidebar>

        <ModalDialog
          actions={this.props.actions}
          className="directChat-dialog"
          label="double-confirmation"
          modalOpened={ this.props.meta.get('modalShowing') }
          modalData={ this.props.meta.get('modalData') }
          onModalClose = { () => {
            this.props.actions.clearModalData();
          }}
          type="directChat-modal"
        />
      </div>
    );
  }
}

DirectChat.propTypes = {
  /**
   * Contains all available dispatched actions for this component
   * @type {Object}
   */
  actions: PropTypes.object.isRequired,
  /**
   * Contains available connections data
   * @type {Immutable.List<Connection>}
   */
  connections: PropTypes.object.isRequired,
  /**
   * Contains latest conversation data
   * @type {Immutable.List<Conversation>}
   */
  conversations: PropTypes.object.isRequired,
  /**
   * Contains information about the component interactions and state
   * @type {Immutable.Map<Meta>}
   */
  meta: PropTypes.object.isRequired,
  /**
   * Representative date object of NOW
   * @type {Object}
   */
  now: PropTypes.object.isRequired
};

export default DirectChat;
