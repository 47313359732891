import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { Link } from 'react-router-dom';
import './../css/showConnections.scss';

class ShowConnections extends React.Component {

  onBlock = () => {
    this.props.onAction('block', this.props.userId);
  }

  static buildNameAndLabel(data) {
    const { firstName, lastName, heroConnectionCount } = data;
    if (heroConnectionCount === undefined) {
      return firstName + ' ' + lastName;
    }
    return (
      <div>
        <span 
          className="connection__name"
        >{firstName} {lastName}</span>
      </div>
    )
  }

  static buildConnection(connection, index) {
    const image = connection.get('profile_picture_url');
    const heroConnectionCount = connection.get('hero_connections_count');
    return (
      <Link
        key={`cim_${index}`}
        className="directChat-aboutContent-connectionDataLink"
        to={"/connection/" + connection.get('id')}
      >
        <div className="connectionItem">
          <div className="connection-image" style={{ backgroundColor: '#354149', backgroundImage: image !== null ? `url("${image}")` : null }}></div>
          {ShowConnections.buildNameAndLabel({
            firstName: connection.get('first_name'),
            lastName: connection.get('last_name'),
            heroConnectionCount
          })}
        </div>
      </Link>);
  }

  render () {
    const { type, connections } = this.props;
    const title = (type === 'hero') ? this.props.trans.modals.showConnections.heroTitle : this.props.trans.modals.showConnections.title;
    return (
      <div className="showConnections">
        <div className="showConnections-title items-style1">{title}</div>
        <div className="showConnections-description" style={{height: connections.size * 65}}>
            <Scrollbars className="styleScroll">
              {connections.map(this.constructor.buildConnection)}
            </Scrollbars>
        </div>
        <div className="showConnections-actions">
          <button
            style={{marginLeft: '0'}}
            className="showConnections-cancelButton main-button"
            onClick={ this.props.onClose }>{this.props.trans.modals.showConnections.closeButton}</button>
        </div>
      </div>
    );
  }
}

export default ShowConnections;
