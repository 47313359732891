export const FETCH_ADVANCED_SEARCH_DATA = 'FETCH_ADVANCED_SEARCH_DATA';
export const ON_PRICE_CHANGE = 'ON_PRICE_CHANGE';
export const ON_RATING_CHANGE = 'ON_RATING_CHANGE';
export const ON_OFFICE_TYPE_CHANGE = 'ON_OFFICE_TYPE_CHANGE';
export const ON_AMENITIES_CHANGE = 'ON_AMENITIES_CHANGE';
export const ON_SERVICES_CHANGE = 'ON_SERVICES_CHANGE';
export const ON_INDUSTRIES_CHANGE = 'ON_INDUSTRIES_CHANGE';
export const ON_CAPACITY_CHANGE = 'ON_CAPACITY_CHANGE';
export const ON_WORK_HOURS_FROM_CHANGE = 'ON_WORK_HOURS_FROM_CHANGE';
export const ON_WORK_HOURS_UNTIL_CHANGE = 'ON_WORK_HOURS_UNTIL_CHANGE';
export const ON_HEALTH_MEASURES_CHANGE = 'ON_HEALTH_MEASURES_CHANGE';
export const ON_APPLY_FILTERS = 'ON_APPLY_FILTERS';
export const ON_RESET_FILTERS = 'ON_RESET_FILTERS';
export const TOGGLE_MODE = 'TOGGLE_MODE';
export const ON_REMOVE_FILTER = 'ON_REMOVE_FILTER';
export const ON_DATE_CHANGE = 'ON_DATE_CHANGE';
export const ON_CLOSE_SEARCHBOX = 'ON_CLOSE_SEARCHBOX';
export const ON_PREMIUM_SUPPLIER_CHANGE = 'ON_PREMIUM_SUPPLIER_CHANGE';

export const fetchAdvancedSearchData = () => {
  return {
    type: FETCH_ADVANCED_SEARCH_DATA,
    // url: 'http://herobooking.exn/advanced-search'
    url: `${window.__env.apiUrl}/offices/search`,
  };
};

export const onPriceChange = value => {
  return {
    type: ON_PRICE_CHANGE,
    value,
  };
};

export const onRatingChange = value => {
  return {
    type: ON_RATING_CHANGE,
    value,
  };
};

export const onOfficeTypeChange = (value, checked) => {
  return {
    type: ON_OFFICE_TYPE_CHANGE,
    value,
    checked,
  };
};

export const onAmenitiesChange = (value, checked) => {
  return {
    type: ON_AMENITIES_CHANGE,
    value,
    checked,
  };
};

export const onHealthMeasuresChange = (value, checked) => {
  return {
    type: ON_HEALTH_MEASURES_CHANGE,
    value,
    checked,
  };
};
export const onPremiumSupplierChange = (value, checked) => {
  return {
    type: ON_PREMIUM_SUPPLIER_CHANGE,
    value,
    checked,
  };
};

export const onServicesChange = (value, checked) => {
  return {
    type: ON_SERVICES_CHANGE,
    value,
    checked,
  };
};

export const onIndustriesChange = (value, checked) => {
  return {
    type: ON_INDUSTRIES_CHANGE,
    value,
    checked,
  };
};

export const onCapacityChange = value => {
  return {
    type: ON_CAPACITY_CHANGE,
    value,
  };
};

export const onWorkHoursFromChange = value => {
  return {
    type: ON_WORK_HOURS_FROM_CHANGE,
    value,
  };
};

export const onWorkHoursUntilChange = value => {
  return {
    type: ON_WORK_HOURS_UNTIL_CHANGE,
    value,
  };
};

export const onApplyFilters = (filters, resetMarkers) => {
  return {
    type: ON_APPLY_FILTERS,
    // url: window.__env.apiUrl + "/offices/search",
    filters,
    resetMarkers,
  };
};

export const onResetFilters = filters => {
  const searchUrl = localStorage.getItem('access-key')
    ? `${window.__env.apiUrl}/offices/search`
    : `${window.__env.apiUrl}/public/offices/search`;
  return {
    type: ON_RESET_FILTERS,
    url: searchUrl,
    filters,
  };
};

export const onCloseSearchBox = () => {
  return {
    type: ON_CLOSE_SEARCHBOX,
  };
};

export const onDateChange = (startDate, endDate) => {
  return {
    type: ON_DATE_CHANGE,
    startDate,
    endDate,
  };
};

export const toggleMode = mode => {
  return {
    type: TOGGLE_MODE,
    mode,
  };
};

export const onRemoveFilter = (keys, value, removeType) => {
  return {
    type: ON_REMOVE_FILTER,
    keys,
    value,
    removeType,
  };
};
