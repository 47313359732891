import Immutable from 'immutable';
import _ from 'lodash';
import {
  FETCH_NOTIFICATIONS,
  GET_UNREAD_NOTIFICATIONS_COUNT,
  ON_NOTIFICATION_CLICK,
  ON_MODAL_CLOSE,
  MARK_AS_READ,
  MARK_ONE_AS_READ, HIDE_NOTIFICATIONS,
} from '../actions/notificationsActions';

const notifications = (
  state = Immutable.fromJS({
    notificationsData: [],
    notificationsCount: 0,
    markedAsRead: false,
  }),
  action
) => {
  let newState;

  switch (action.type) {
    case FETCH_NOTIFICATIONS:
      if (_.get(action, 'data.pageNumber', '1') === '1') {
        const notifications = _.get(action, 'data.notifications', []);
        newState = state.set('notificationsData', notifications);
      } else {
        const notifications = _.get(action, 'data.notifications', []);
        newState = state.set('notificationsData', state.get('notificationsData').concat(notifications));
      }
      break;

    case GET_UNREAD_NOTIFICATIONS_COUNT:
      newState = state.set('notificationsCount', action.unreadNotificationsCount);
      break;

    case ON_NOTIFICATION_CLICK:
      newState = state.set('notificationClicked', !state.get('notificationClicked'));
      break;

    case ON_MODAL_CLOSE:
      newState = state.set('modalOpened', !state.get('modalOpened'));
      break;

    case MARK_AS_READ:
      newState = state.set('markedAsRead', true).set('notificationsCount', 0);
      newState = newState.updateIn(['notificationsData'], arr => {
        return arr.map(item => _.set(item, 'seen', 1));
      });
      break;
    case MARK_ONE_AS_READ:
      const index = state.get('notificationsData').findIndex(item => item.id === parseInt(action.notificationId));
      newState = state
        .updateIn(['notificationsData', index, 'seen'], seen => 1)
        .updateIn(['notificationsCount'], notificationsCount => notificationsCount - 1);
      break;
    case HIDE_NOTIFICATIONS:
      newState = state.set('notificationClicked', false);
      break;
    default:
      newState = state;
  }

  return newState;
};

export default notifications;
