import { connect } from 'react-redux';
import _ from 'lodash';
import BookingRecommendation from './BookingRecommendation';
import toJS from '../../utils/to-js';
import addLoader from '../../utils/add-loader';

import { fetchBookingRecommendation } from '../../../../actions/dashboard/booking-recommendation';
import {
  bookingRecommendationSelector,
  bookingRecommendationIsLoadingSelector,
  bookingRecommendationImagesSelector,
} from '../../../../selectors/dashboard/booking-recommendation';
import { translations } from '../../../../selectors/translations';

const mapStateToProps = state => ({
  bookingRecommendation: bookingRecommendationSelector(state),
  images: bookingRecommendationImagesSelector(state),
  isLoading: bookingRecommendationIsLoadingSelector(state),
  translations: _.extend(_.get(translations(state), 'dashboard', {}), _.get(translations(state), 'healthStamps', {}) ),
});

const mapDispatchToProps = dispatch => ({
  fetchBookingRecommendation: () => dispatch(fetchBookingRecommendation()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(toJS(addLoader(BookingRecommendation)));
