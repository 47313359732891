import Immutable from 'immutable';
import { SET_COMMUNITY_UPLOADS } from '../../actions/community/community-uploads';

const initialState = Immutable.Map({
  uploads: [],
});

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_COMMUNITY_UPLOADS:
      return state.setIn(['uploads'], action.uploads);
    default:
      return state;
  }
};
