import { connect } from 'react-redux';
import _ from 'lodash';

import CommunitySearch from './CommunitySearch';
import toJS from '../../utils/to-js';

import { translations } from '../../../../selectors/translations';

import { searchedInviteHeroesSelector } from '../../../../selectors/community/invite-members-community';

const mapStateToProps = state => ({
  searchedHeroes: searchedInviteHeroesSelector(state),
  translations: _.get(translations(state), 'community', {}),
  heroProfile: state.global.get('heroProfile'),
});

export default connect(mapStateToProps)(toJS(CommunitySearch));
