import { connect } from 'react-redux';
import Location from './locationWhiteLabel';
import './css/index.scss';

import {
  fetchOfficeDetails,
  onFinishBooking,
  beforeFetchOfficeDetails,
  openBookingOptions,
  closeBookingOptions,
  resetBooking,
  setSelectedRoom,
  fetchBookingOptions,
  bookingRequest,
  openSidePopup,
  closeSidePopup,
  openHeroSidePopup,
  closeHeroSidePopup,
  toggleDateExpanded,
  onChangeSection,
} from '../../actions/locationActions';
import { fetchOfficeTypes } from '../../actions/generalDataActions';
import {fetchMapProducts, setMapProducts} from '../../actions/whiteLabel/products';
import { setFloorPlans, setSelectedFloorIndex, fetchBuildingHeroes, setFeatures, setSelectedFeatures } from '../../actions/whiteLabel/filter';
import { setSelectedDate, setSelectedProductId, setSelectedProductType } from '../../actions/whiteLabel/booking';
import { fetchHeroesBookings } from '../../actions/whiteLabel/heroes-booking-list';
import {
  floorPlansSelector,
  selectedFeaturesSelector,
  selectedFloorIndexSelector
} from '../../selectors/whiteLabel/filter';
import { mapProductsSelector } from '../../selectors/whiteLabel/products';
import { selectedDateSelector, selectedProductTypeSelector } from '../../selectors/whiteLabel/booking';

const mapStateToProps = state => {
  return {
    bookingOptionsOpened: state.location.get('bookingOptionsOpened'),
    bookingRequestResponse: state.location.get('bookingRequestResponse'),
    bookingRequestResponseErrors: state.location.get('bookingRequestResponseErrors'),
    bookingSection: state.addBooking.get('bookingSection'),
    datePickerExpanded: state.location.get('datePickerExpanded'),
    heroPopupErrorOpened: state.location.get('heroPopupErrorOpened'),
    officeDetails: state.location.get('details'),
    officeTypesData: state.location.get('officeTypesData'),
    selectedOptions: state.addBooking.get('selectedOptions'),
    bookingValidate: state.addBooking.get('bookingValidate'),
    selectedRoom: state.addBooking.get('selectedRoom'),
    selectedProduct: state.addBooking.get('selectedProduct'),
    sidePopupOpened: state.location.get('sidePopupOpened'),
    translations: state.translations,
    floorPlans: floorPlansSelector(state),
    selectedFloorIndex: selectedFloorIndexSelector(state),
    selectedDate: selectedDateSelector(state),
    mapProducts: mapProductsSelector(state),
    selectedProductType: selectedProductTypeSelector(state),
    selectedFeatures: selectedFeaturesSelector(state),
  };
};

const mapDispatchProps = dispatch => {
  return {
    fetchBookingOptions: (officeId, officeTypeId, productId, time_date) =>
      dispatch(fetchBookingOptions(officeId, officeTypeId, productId, time_date)),
    resetBooking: () => dispatch(resetBooking()),
    setSelectedProduct: product => dispatch(setSelectedProductId(product)),
    setSelectedRoom: room => dispatch(setSelectedRoom(room)),
    openBookingOptions: () => dispatch(openBookingOptions()),
    closeBookingOptions: () => dispatch(closeBookingOptions()),
    beforeFetchOfficeDetails: id => dispatch(beforeFetchOfficeDetails(id)),
    fetchOfficeDetails: id => dispatch(fetchOfficeDetails(id)),
    fetchOfficeTypes: () => dispatch(fetchOfficeTypes()),
    onFinishBooking: args => dispatch(onFinishBooking(args)),
    bookingRequest: args => dispatch(bookingRequest(args)),
    openSidePopup: () => dispatch(openSidePopup()),
    closeSidePopup: () => dispatch(closeSidePopup()),
    openHeroSidePopup: () => dispatch(openHeroSidePopup()),
    closeHeroSidePopup: () => dispatch(closeHeroSidePopup()),
    toggleDateExpanded: expanded => dispatch(toggleDateExpanded(expanded)),
    onChangeSection: bookingSection => dispatch(onChangeSection(bookingSection)),
    fetchMapProducts: (officeId, floorNumber, productTypeId, startDate, endDate, features) =>
      dispatch(fetchMapProducts(officeId, floorNumber, productTypeId, startDate, endDate, features)),
    setFloorPlans: floorPlans => dispatch(setFloorPlans(floorPlans)),
    setSelectedDate: (from, to) => dispatch(setSelectedDate(from, to)),
    setSelectedFloorIndex: selectedFloorIndex => dispatch(setSelectedFloorIndex(selectedFloorIndex)),
    fetchHeroesBookings: (officeId, startDate, endDate, type, departmentId) =>
      dispatch(fetchHeroesBookings(officeId, startDate, endDate, type, departmentId)),
    setSelectedProductType: productType => dispatch(setSelectedProductType(productType)),
    setFeatures: features => dispatch(setFeatures(features)),
    setSelectedFeatures: feature => dispatch(setSelectedFeatures(feature)),
    fetchBuildingHeroes: id => {
      dispatch(fetchBuildingHeroes(id));
    },
    setMapProducts: products => {
      dispatch(setMapProducts(products));
    },
  };
};

export default connect(mapStateToProps, mapDispatchProps)(Location);
