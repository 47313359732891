import Immutable from 'immutable';
import { CLICK, RIGHT_CLICK, FETCH_OFFICES, FETCH_PROGRESS, FETCH_AVAILABILITY_PROGRESS } from './../actions/buttonActions';

const demoButton = (state = Immutable.fromJS({ buttonData: [], fetchProgress: false, fetchAvailabilityProgress: false }), action) => {
  let newState;

  switch (action.type) {
    case CLICK:
      newState = state.set('clicked', !state.get('clicked'));
      newState = newState.set('special', false);
      newState = newState.set('isFetching', true);
      break;

    case FETCH_PROGRESS:
      newState = state.set('fetchProgress', action.status);
      break;

    case FETCH_AVAILABILITY_PROGRESS:
      newState = state.set('fetchAvailabilityProgress', action.status);
      break;

    case FETCH_OFFICES:
      newState = state.set('buttonData', Immutable.fromJS(action.data.items));
      newState = newState.set('fetchProgress', false);
      break;

    case RIGHT_CLICK:
      newState = state.set('clicked', false);
      newState = newState.set('special', true);
      break;

    default:
      newState = state;
  }

  return newState;
};

export default demoButton;
