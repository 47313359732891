import _ from 'lodash';
import { connect } from 'react-redux';
import { translations } from '../../selectors/translations';
import IsMobile from './isMobile';

const mapStateToProps = state => ({
  translations: translations(state),
});

export default connect(mapStateToProps)(IsMobile);
