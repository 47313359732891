import Immutable from 'immutable';
import { SET_LEAVE_COMMUNITY_ERRORS } from '../../actions/community/leave-community';

const initialState = Immutable.Map({
  leaveCommunityErrors: undefined,
});

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_LEAVE_COMMUNITY_ERRORS:
      return state.setIn(['leaveCommunityErrors'], action.leaveCommunityErrors);
    default:
      return state;
  }
};
