import React, { Component } from 'react';
import ReactModal from 'react-modal';
import classnames from 'classnames';
import ModalDialogCenter from './modalDialogCenter';
import ModalDialogCenterConfirmation from './modalDialogCenterConfirmation';
import ModalDialogCenterEdit from './modalDialogCenterEdit';
import ModalDirectChat from './modalDirectChat';
import './css/modalDialog.scss';

class ModalDialog extends Component {
  constructor(props) {
    super(props);
    this.state = { showModal: props.modalOpened };
  }

  getContent(type, props) {
    let content = null;

    switch (type) {
      case 'location-review':
        content = (
          <ModalDialogCenter
            title={this.props.title}
            image={this.props.image}
            rating={this.props.rating}
            onCancel={this.props.onModalClose}
          />
        );
        break;

      case 'business-account-redirect':
        content = (
          <ModalDialogCenterConfirmation
            title={this.props.title}
            body={this.props.children}
            onProceed={this.props.onProceed}
            onCancel={this.props.onModalClose}
            trans={this.props.trans}
          />
        );
        break;

      case 'event-room-book-request':
        content = (
          <ModalDialogCenterConfirmation
            title={this.props.title}
            body={this.props.children}
            onProceed={this.props.onProceed}
            showCancel={this.props.showCancel}
            proceedText={this.props.proceedText}
            trans={this.props.trans}
          />
        );
        break;

      case 'welcome-popup':
        content = (
          <ModalDialogCenterConfirmation
            title={this.props.title}
            body={this.props.children}
            onProceed={this.props.onProceed}
            showOk={this.props.showOk}
            proceedText={this.props.proceedText}
            onCancel={this.props.onCancel}
            showCancel={this.props.showCancel}
            cancelText={this.props.cancelText}
            trans={this.props.trans}
          />
        );
        break;

      case 'edit-account':
        content = (
          <ModalDialogCenterEdit
            title={this.props.title}
            trans={this.props.trans}
            body={this.props.children}
            onSave={this.props.onSave}
            onModalClose={this.props.onModalClose}
          />
        );
        break;

      case 'directChat-modal':
        content = <ModalDirectChat {...(props || {})} />;
        break;

      default:
        content = this.props.children;
    }

    return content;
  }

  render() {
    const modalClassName = classnames([
      {
        'modal-dialog': false,
        'modal-review': true,
        'modal-dialog-centered': !this.props.display,
        'modal-dialog-left': this.props.display === 'left',
      },
      this.props.className,
    ]);

    return (
      <ReactModal className={modalClassName} shouldCloseOnOverlayClick isOpen={this.props.modalOpened} contentLabel={this.props.label}>
        <div className="modal-dialog-content">
          <div
            className="edit-overlay-loading"
            style={{ display: this.props.validating !== undefined && this.props.validating === true ? 'inline-block' : 'none' }}
          />
          <span className="close" onClick={this.props.onModalClose} />
          {this.getContent(this.props.type, this.props)}
        </div>
      </ReactModal>
    );
  }
}

export default ModalDialog;
