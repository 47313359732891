import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _ from 'lodash';

const DeleteModal = props => {
  const { hideDeleteModal, deleteAction, selectedHero, translations } = props;
  return (
    <div className={classnames('base-modal', 'meeting-attendees-delete-modal')}>
      <div className="base-modal__modal-overlay" />
      <div className="base-modal__modal-content">
        <div className="base-modal__left-area">
          <span className="icon icon-location base-modal__left-area-icon" />
        </div>
        <div className="base-modal__right-area">
          <div className="base-modal__modal-header">
            <div className="base-modal__modal-header-title">
              {_.get(translations, 'meetingAttendees.deleteModalTitle', "Delete :name:'s invitation ?").replace(
                ':name:',
                `${selectedHero.firstName} ${selectedHero.lastName}`
              )}
            </div>
            <span
              className="base-modal__close"
              onClick={() => {
                hideDeleteModal();
              }}
            >
              &times;
            </span>
          </div>
          <div className="base-modal__modal-description">
            {_.get(
              translations,
              'meetingAttendees.deleteModalDescription',
              'Are you sure you want to delete :name:’s invitation? '
            ).replace(':name:', `${selectedHero.firstName} ${selectedHero.lastName}`)}
          </div>
          <div className="base-modal__modal-body">
            <div className="meeting-attendee-confirmation-modal__container">
              <div className="meeting-attendee-modal__confirmation-message" />
              <div className="meeting-attendee-modal__actions">
                <button
                  className="meeting-attendee-modal__delete-button"
                  onClick={() => {
                    deleteAction(selectedHero.attendeeId);
                    hideDeleteModal();
                  }}
                >
                  {_.get(translations, 'meetingAttendees.deleteButton', 'Delete')}
                </button>
                <button
                  className="button-yellow meeting-attendee-modal__cancel-button"
                  onClick={() => {
                    hideDeleteModal();
                  }}
                >
                  {_.get(translations, 'meetingAttendees.cancelDelete', 'Don’t delete')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

DeleteModal.propTypes = {
  deleteAction: PropTypes.func.isRequired,
  hideDeleteModal: PropTypes.func.isRequired,
  errors: PropTypes.shape({}),
  translations: PropTypes.shape({}),
  selectedHero: PropTypes.shape({}).isRequired,
};

DeleteModal.defaultProps = {
  errors: {},
  translations: {},
};

export default DeleteModal;
