import Immutable, { fromJS } from 'immutable';
import { SET_HERO_RECOMMENDATION, SET_HERO_RECOMMENDATION_IS_LOADING } from '../../actions/dashboard/hero-recommendation';

const initialState = Immutable.Map({
  heroRecommendation: fromJS({}),
  isLoading: false,
});

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_HERO_RECOMMENDATION:
      return state.setIn(['heroRecommendation'], fromJS(action.heroRecommendation));
    case SET_HERO_RECOMMENDATION_IS_LOADING:
      return state.setIn(['isLoading'], action.isLoading);
    default:
      return state;
  }
};
