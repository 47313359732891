import { connect } from 'react-redux';
import _ from 'lodash';
import toJS from '../../../utils/to-js';

import { saveHeroMediaFeedback } from '../../../../../actions/dashboard/hero-media-feedback';
import { setMediaArticle } from '../../../../../actions/dashboard/media-article';
import { mediaArticleSelector } from '../../../../../selectors/dashboard/media-article';
import { translations } from '../../../../../selectors/translations';

import MediaFeedback from './MediaFeedback';

const mapStateToProps = state => ({
  mediaArticle: mediaArticleSelector(state),
  translations: _.get(translations(state), 'dashboard', {}),

});

const mapDispatchToProps = dispatch => ({
  saveHeroMediaFeedback: data => dispatch(saveHeroMediaFeedback(data)),
  setMediaArticle: data => dispatch(setMediaArticle(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(toJS(MediaFeedback));
