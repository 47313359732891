import React from 'react';
import _ from 'lodash';
import Dropdown from '../../dashboard/common/dropdown/Dropdown';
import Config from '../../../utils/const';

const CommunicationEmail = props => {
  const { translations, heroProfile, setHeroProfile } = props;
  const languages = Config.locales.map(locale => _.set(locale, 'value', _.get(translations, `languages.${locale.value}`, locale.value)));

  return (
    <div className="profile-form__row">
      <div className="profile-form__columns">
        <div className="profile-form__field">
          <div className="profile-form__label">{_.get(translations, 'openAuth.communicationEmail', 'Communication Email default')}</div>
          <Dropdown
            onChange={id => {
              const heroProfileHash = heroProfile.toJS();
              heroProfileHash.language = id;
              setHeroProfile(heroProfileHash);
            }}
            options={languages}
            selectedId={_.get(heroProfile.toJS(), 'language', '')}
            className="dropdown__selector filter__dropdown"
          />
        </div>
      </div>
    </div>
  );
};
export default CommunicationEmail;
