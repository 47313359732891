import { connect } from 'react-redux';
import _ from 'lodash';
import { translations } from '../../../selectors/translations';
import CommunicationEmail from './CommunicationEmail';
import { setHeroProfile } from '../../../actions/heroProfile';

const mapStateToProps = state => ({
  translations: translations(state),
  heroProfile: state.heroProfile.get('heroProfile'),
});
const mapDispatchToProps = dispatch => ({
  setHeroProfile: heroProfile => dispatch(setHeroProfile(heroProfile)),
});
export default connect(mapStateToProps, mapDispatchToProps)(CommunicationEmail);
