import Immutable from 'immutable';
import { SET_HEROES_BOOKINGS, SET_DEPARTMENTS, SET_HEROES_BOOKINGS_LOADING } from '../../actions/whiteLabel/heroes-booking-list';

const initialState = Immutable.Map({
  list: [],
  departments: [],
  isLoading: false,
});

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_HEROES_BOOKINGS:
      return state.setIn(['list'], action.bookings);
    case SET_HEROES_BOOKINGS_LOADING:
      return state.setIn(['isLoading'], action.isLoading);
    case SET_DEPARTMENTS:
      return state.setIn(['departments'], action.departments);
    default:
      return state;
  }
};
