import { connect } from 'react-redux';
import _ from 'lodash';
import PaymentAccountType from './PaymentAccountType';
import {onChangeSection, resetBooking} from '../../../actions/locationActions';
import { translations } from '../../../selectors/translations';

const mapStateToProps = state => ({
  countries: state.account.get('countries'),
  translations: _.get(translations(state), 'paymentProfile', {}),
  paymentProfiles: state.addBooking.get('paymentProfiles'),
});

const mapDispatchToProps = dispatch => ({
  onChangeSection: bookingSection => dispatch(onChangeSection(bookingSection)),
  resetBooking: () => dispatch(resetBooking()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentAccountType);
