import classnames from 'classnames';
import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';

const SkillItem = props => {
  const { id, name, value, onSkillClick } = props;
  return (
    <div className={classnames('profile-form__tag', { 'profile-form__tag-checked': value })} onClick={() => onSkillClick(id)}>
      {name}
    </div>
  );
};

const SkillPicker = props => {
  const { question, allSkills, checkedSkills, onSkillClick, error } = props;
  const hmapChecked = {};
  for (let i = 0; i < checkedSkills.length; i++) {
    hmapChecked[_.get(checkedSkills[i], 'id')] = checkedSkills[i];
  }

  return (
    <div className="profile-form__picker">
      <div className="profile-form__label">{_.capitalize(question)}</div>
      <div className="profile-form__tags">
        {allSkills.map(skill => (
          <SkillItem id={skill.id} key={skill.id} name={skill.name} value={!!hmapChecked[skill.id]} onSkillClick={onSkillClick} />
        ))}
      </div>
      {error && (<span className="profile-form__error-text">The {question} are required.</span>) }
    </div>
  );
};

SkillPicker.propTypes = {
  allSkills: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ),
  checkedSkills: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ),
  question: PropTypes.string,
  error: PropTypes.bool,
  onSkillClick: PropTypes.func.isRequired,
};
SkillPicker.defaultProps = {
  allSkills: [],
  checkedSkills: [],
  question: '',
  error: false,
};

SkillItem.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  value: PropTypes.bool,
  onSkillClick: PropTypes.func.isRequired,
};
SkillItem.defaultProps = {
  id: null,
  name: null,
  value: null,
};

export default SkillPicker;
