import Immutable from 'immutable';
import { SET_COMMUNITY_COMMENT_EDIT_IS_LOADING, SET_COMMUNITY_COMMENT_EDIT_ERRORS } from '../../actions/community/edit-comment';

const initialState = Immutable.Map({
  isLoading: false,
  errors: {},
});

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_COMMUNITY_COMMENT_EDIT_IS_LOADING:
      return state.setIn(['isLoading'], action.isLoading);
    case SET_COMMUNITY_COMMENT_EDIT_ERRORS:
      return state.setIn(['errors'], action.errors);
    default:
      return state;
  }
};
