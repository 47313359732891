import React from 'react';
import classnames from 'classnames';
import ProfileMenu from '../profileMenu';
import ProfileMenuUnlogged from '../profileMenu/profileMenuUnlogged';
import './css/index.scss';
import CONST from '../../utils/const';

export default props => {
  return (
    <div className={classnames('profile', { active: props.active === true })}>
      <div className="profile-overlay" />
      {props.isAuthenticated ? (
        <ProfileMenu profileMenuClick={props.profileMenuClick} profileMenuClicked={props.active} trans={props.trans} />
      ) : (
        <ProfileMenuUnlogged profileMenuClick={props.profileMenuClick} profileMenuClicked={props.active} trans={props.trans} />
      )}
    </div>
  );
};
