import React from 'react';
import PropTypes from 'prop-types';

/**
 * Renders connections for a OfficeBox
 *
 * @method ConnectionsData
 * @param {object} props
 * @returns {ReactElement}
 * @constructor
 */
const ConnectionsData = (props) => {
  const secondConnection = props.second ? <span>, { props.second }</span>  : '';
  return (
    <div className="officeBox-connections">
      <span>{ props.first }</span>{ secondConnection } { props.total > 0 ? (props.total > 1 ? props.trans.formatString(props.trans.events.andXconnections, props.total) : props.trans.events.and1connection)  : '' }
    </div>
  )
};

ConnectionsData.propTypes = {
  total: PropTypes.number.isRequired,
  first: PropTypes.string.isRequired
};

export default ConnectionsData;
