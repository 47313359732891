import React from 'react';

const youtubeParser = url => {
  if (!url) {
    return false;
  }

  const urlMatches = url.match(/\bhttps?:\/\/\S+/gi);

  if (urlMatches && urlMatches.length > 0) {
    const extractedUrl = urlMatches[0];
    const regExp = /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w-]+\?v=|embed\/|v\/)?)([\w-]+)(\S+)?$/;
    const match = extractedUrl.match(regExp);
    return match && match[5].length === 11 ? match[5] : false;
  }
  return false;
};

const vimeoParser = url => {
  if (!url) {
    return false;
  }

  const urlMatches = url.match(/\bhttps?:\/\/\S+/gi);

  if (urlMatches && urlMatches.length > 0) {
    const extractedUrl = urlMatches[0];
    const regExp = /^(http|https)?:\/\/(www\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|)(\d+)(?:|\/\?)/;
    const match = extractedUrl.match(regExp);

    return match && match[4].length > 0 ? match[4] : false;
  }
  return false;
};

const imageParser = content => {
  if (!content) {
    return false;
  }
  const urlMatches = content.match(/(http)?s?:?(\/\/[^"']*\.(?:png|jpg|jpeg|gif|png|svg))/gi);
  if (urlMatches && urlMatches.length > 0) {
    return urlMatches[0];
  }
  return false;
};
const vimeoVideoRenderer = videoId => {
  return (
    <iframe
      src={`https://player.vimeo.com/video/${videoId}`}
      width="710"
      height="399"
      frameBorder="0"
      allow="autoplay; fullscreen"
      allowFullScreen
    />
  );
};

const youtubeVideoRenderer = videoId => {
  return (
    <iframe
      width="710"
      height="399"
      src={`https://www.youtube.com/embed/${videoId}?controls=0`}
      frameBorder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    >
      {' '}
    </iframe>
  );
};

const imageRenderer = url => {
  return <img src={url} width="478" alt="" />;
};

const parsers = [
  {
    check: vimeoParser,
    render: vimeoVideoRenderer,
  },
  {
    check: youtubeParser,
    render: youtubeVideoRenderer,
  },
  {
    check: imageParser,
    render: imageRenderer,
  },
];

const Embedder = props => {
  const { content } = props;
  for (let i = 0; i < parsers.length; i++) {
    const parser = parsers[i];
    const mediaIdentifier = parser.check(content);
    if (mediaIdentifier) {
      return parser.render(mediaIdentifier);
    }
  }
  return null;
};

export default Embedder;
