import { connect } from 'react-redux';
import HeroesPicker from './HeroesPicker';
import { onLocationChangeSearch } from '../../../../../actions/map';
import { globalSearch, globalSearchHeroes, globalSearchTrigger } from '../../../../../actions/global';

const mapStateToProps = state => {
  return {
    globalSearchResults: state.global.get('globalSearchResults'),
    globalSearching: state.global.get('globalSearching'),
    translations: state.translations,
    heroProfile: state.global.get('heroProfile'),
    isAuthenticated: state.auth.get('isAuthenticated'),
  };
};

const mapDispatchProps = dispatch => {
  return {
    onLocationChangeSearch: () => {
      dispatch(onLocationChangeSearch());
    },
    globalSearch: term => {
      dispatch(globalSearch(term));
    },
    globalSearchHeroes: term => {
      dispatch(globalSearchHeroes(term));
    },
    globalSearchTrigger: () => {
      dispatch(globalSearchTrigger());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchProps
)(HeroesPicker);
