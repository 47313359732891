import _ from 'lodash';
import api from '../components/dashboard/utils/api';
import { setVerticalAndFetch } from './global';
/*
 * Constants
 */

export const SET_HERO_PROFILE = 'SET_HERO_PROFILE';
export const SET_HERO_PROFILE_LOADING = 'SET_HERO_PROFILE_LOADING';
export const SAVE_HERO_PROFILE = 'SAVE_HERO_PROFILE';
export const SET_HERO_PROFILE_ERRORS = 'SAVE_HERO_PROFILE';
/*
 * Sync Actions
 */

export const setHeroProfile = heroProfile => ({
  type: SET_HERO_PROFILE,
  heroProfile,
});
export const setHeroProfileIsLoading = isLoading => ({
  type: SET_HERO_PROFILE_LOADING,
  isLoading,
});
export const setHeroProfileErrors = errors => ({
  type: SET_HERO_PROFILE_ERRORS,
  errors,
});
/*
 * Async Actions
 */

export const fetchHeroProfile = () => dispatch => {
  dispatch(setHeroProfileIsLoading(true));
  api
    .get(`${window.__env.apiUrl}/profile/list`)
    .then(({ data = {} }) => {
      const defaultVertical = _.get(data, 'defaultVertical.id', null);
      if (defaultVertical) {
        dispatch(setVerticalAndFetch(defaultVertical));
      }
      dispatch(setHeroProfile(data));
    })
    .catch(() => {})
    .finally(() => {
      dispatch(setHeroProfileIsLoading(false));
    });
};

export const saveHeroProfile = heroProfile => dispatch => {
  dispatch(setHeroProfileIsLoading(true));
  return new Promise((resolve, reject) => {
    api
      .post(`${window.__env.apiUrl}/profile/store`, heroProfile)
      .then(() => {
        resolve();
      })
      .catch(({ response }) => {
        dispatch(setHeroProfileErrors(_.get(response, 'data.errors')));
        dispatch(setHeroProfileIsLoading(false));
        reject();
      })
      .finally(() => {});
  });
};
