export const FETCH_PROFILE = 'FETCH_PROFILE';
export const SAVE_PROFILE = 'SAVE_PROFILE';
export const SAVE_ORIGINAL_INFORMATION = 'SAVE_ORIGINAL_INFORMATION';
export const REQUEST_EXPORT_DATA = 'REQUEST_EXPORT_DATA';
export const REQUEST_DELETE_DATA = 'REQUEST_DELETE_DATA';

export const FETCH_PERMISSIONS = 'FETCH_PERMISSIONS';
export const SAVE_PERMISSION = 'SAVE_PERMISSION';
export const FETCH_RESTRICTIONS = 'FETCH_RESTRICTIONS';

export const fetchProfile = () => {
  return {
    type: FETCH_PROFILE,
    url: `${window.__env.apiUrl}/hero/profile`,
  };
};

export const saveProfile = args => {
  return {
    type: SAVE_PROFILE,
    url: `${window.__env.apiUrl}/hero/profile`,
    args,
  };
};

export const saveOriginalInformation = information => {
  return {
    type: SAVE_ORIGINAL_INFORMATION,
    information,
  };
};

export const requestExportData = () => {
  return {
    type: REQUEST_EXPORT_DATA,
    url: `${window.__env.apiUrl}/hero/exportData`,
  };
};

export const requestDeleteData = () => {
  return {
    type: REQUEST_DELETE_DATA,
    url: `${window.__env.apiUrl}/hero/deleteAccount`,
  };
};

export const fetchPermissions = () => {
  return {
    type: FETCH_PERMISSIONS,
    url: `${window.__env.apiUrl}/permissions/get`,
  };
};

export const fetchRestrictions = () => {
  return {
    type: FETCH_RESTRICTIONS,
    url: `${window.__env.apiUrl}/permissions/companyRestrictions`,
  };
}

export const savePermission = permission => {
  return {
    type: SAVE_PERMISSION,
    url: `${window.__env.apiUrl}/permissions/store`,
    permission,
  };
};
