import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import _ from 'lodash';

const MyBookingsPageMenu = props => {
  const pathName = props.history.location.pathname;
  const { translations, hero } = props;
  const isWhiteLabel = _.get(hero, 'isWhiteLabel', 0);
  return (
    <div className="my-bookings-menu">
      <ul className="my-bookings-menu-list">
        <li className={`my-bookings-menu__link ${pathName === '/my-bookings' ? 'my-bookings-menu__link-active' : ''}`}>
          <Link to="/my-bookings">
            {_.get(translations, 'myBookings.activeBookings', '')}
            <span className="my-bookings-menu-border" />
          </Link>
        </li>
        <li className={`my-bookings-menu__link ${pathName === '/my-meetings' ? 'my-bookings-menu__link-active' : ''}`}>
          <Link to="/my-meetings">
            {_.get(translations, 'myBookings.myMeetings', '')}
            <span className="my-bookings-menu-border" />
          </Link>
        </li>
        {isWhiteLabel === 1 && (
          <li className={`my-bookings-menu__link ${pathName === '/my-parking-bookings' ? 'my-bookings-menu__link-active' : ''}`}>
            <Link to="/my-parking-bookings">
              {_.get(translations, 'myBookings.myParkingBookingsMenu', '')}
              <span className="my-bookings-menu-border" />
            </Link>
          </li>
        )}
      </ul>
    </div>
  );
};

export default withRouter(MyBookingsPageMenu);
