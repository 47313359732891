export const SHOW_MESSAGE_MODAL = 'SHOW_MESSAGE_MODAL';
export const SHOW_SHARE_MODAL = 'SHOW_SHARE_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';

export const showMessageModal = userData => ({
  type: SHOW_MESSAGE_MODAL,
  userData
});

export const showShareModal = (currentUser, post) => ({
  type: SHOW_SHARE_MODAL,
  currentUser,
  post
});

export const closeModal = modalType => ({
  type: CLOSE_MODAL,
  modalType
});
