/**
 * Renders a single office box
 *
 * Usage:
 * <OfficeBox
 *   item={ item }
 *   showConnections={ true }
 * />
 *
 * @class OfficeBox
 * @module OfficeBox
 */
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _ from 'lodash';
import { Link } from 'react-router-dom';

import DefaultOfficeInfo from './defaultOfficeInfo';
import BookedOfficeInfo from './bookedOfficeInfo';
import FullBookingInfo from './fullBookingInfo';
import EventBox from './eventBox';
import sizedImage from '../../utils/imageHelper';
import healthSafetyMeasuresIcon from '../../assets/svg/health-measures/health-safety-measures.svg';
import temperatureControlIcon from '../../assets/svg/health-measures/temperature-control-in-place.svg';
import premiumPartnerIcon from '../../assets/svg/map/premium-partner.svg';

import './css/index.scss';

class OfficeBox extends React.Component {
  getOfficeInfo() {
    if (this.props.fullView) {
      return <FullBookingInfo item={this.props.item} fullView={this.props.fullView} className={this.props.fullView ? '--fullView' : ''} />;
    }

    if (this.props.content === 'bookings') {
      return (
        <BookedOfficeInfo
          item={this.props.item}
          fullView={this.props.fullView}
          trans={this.props.trans}
          className={this.props.fullView ? '--fullView' : ''}
        />
      );
    }

    if (this.props.content === 'events' || this.props.content === 'upcoming-events' || this.props.content === 'active-events') {
      return <EventBox trans={this.props.trans} item={this.props.item} content={this.props.content} />;
    }

    return (
      <DefaultOfficeInfo
        item={this.props.item}
        fullView={this.props.fullView}
        geoLocationCoords={this.props.geoLocationCoords}
        officeTypesData={this.props.officeTypesData}
        showConnections={this.props.showConnections}
      />
    );
  }

  getBoxImage(item) {
    if (this.props.content === 'events' || this.props.content === 'upcoming-events' || this.props.content === 'active-events') {
      return item.get('photo');
    }

    return item.get('imageUrl');
    // TO DO to return false when no image from API
  }

  render() {
    const { trans, item, content, direction, fullView } = this.props;
    const boxClassName = classnames({
      officeBox: true,
      '--fullView': fullView,
      [`officeBox-${direction}`]: direction,
      [`officeBox-${content}`]: content,
    });

    const boxImageClassName = classnames([
      'officeBox-image',
      content === 'active-events' && item.get('commingUp') ? 'officeBox-image-event-commingUp' : '',
      { 'officeBox-image-recommended': item.get('recommended') !== undefined && item.get('recommended') === true },
    ]);

    const healthMeasuresStamp = item.get('healthMeasuresStamp') || false;
    const temperatureControlStamp = item.get('temperatureControlStamp') || false;
    const isPremiumPartner = item.get('premiumSupplier') || false;
    const image = (
      <div
        className={boxImageClassName}
        style={{
          bacgroundPosition: 'center center',
          position: 'relative',
          backgroundImage: this.getBoxImage(item) !== null ? `url("${sizedImage(this.getBoxImage(item), 417, 320)}")` : '#0e161e',
        }}
      >
        {isPremiumPartner ? (
          <div className="premium-partner__container">
            <img
              src={premiumPartnerIcon}
              alt={_.get(trans, 'premiumPartner.image_alt', 'Premium partner')}
              className="premium-partner__icon"
            />
            <div className="health__stamps-tooltip health__stamps-tooltip--premium">
              {_.get(trans, 'premiumPartner.tooltip', 'Premium Partner')}
            </div>
          </div>
        ) : null}
        <div className="health__stamps-container">
          {healthMeasuresStamp ? (
            <div className="health__stamps-container--health">
              <img
                src={healthSafetyMeasuresIcon}
                alt={_.get(trans, 'healthStamps.health_safety_measures_image_alt', 'Health Measures')}
                className="health__stamp-icon"
              />
              <div className="health__stamps-tooltip health__stamps-tooltip--health">
                {_.get(trans, 'healthStamps.health_safety_measures', 'Health Safety Measures')}
              </div>
            </div>
          ) : null}
          {temperatureControlStamp ? (
            <div className="health__stamps-container--temperature">
              <img
                src={temperatureControlIcon}
                alt={_.get(trans, 'healthStamps.temperature_control_points_image_alt', 'Temperature control')}
                className="health__stamp-icon"
              />
              <div className="health__stamps-tooltip health__stamps-tooltip--temperature">
                {_.get(trans, 'healthStamps.temperature_control_points', 'Temperature control points')}
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );

    const imageWrapped =
      content === 'events' || content === 'upcoming-events' ? <Link to={`/event/${item.get('id')}`}>{image}</Link> : image;

    return (
      <div className={boxClassName}>
        {imageWrapped}
        <div className="officeBox-info">{this.getOfficeInfo()}</div>
      </div>
    );
  }
}

OfficeBox.propTypes = {
  /**
   * Immutable map containing an office description
   * @param item
   * @type Immutable.Map
   */
  item: PropTypes.object.isRequired,
  /**
   * Flag which determines if connections are displayed or not
   * @param showConnections
   * @type boolean
   */
  showConnections: PropTypes.bool,
};

OfficeBox.defaultProps = {
  showConnections: false,
};

export default OfficeBox;
