import { connect } from 'react-redux';
import Main from './Main';
import { getTranslations, handleSubdomain, fetchAppTimezone } from '../../actions/global';
import { setHeroAuthentication } from '../../actions/openAuth/login';

const mapStateToProps = state => ({
  isAuthenticated: state.auth.get('isAuthenticated'),
  isWhiteLabel: state.global.get('isWhiteLabel'),
  validSubdomain: state.global.get('existingSubdomain'),
  restrictions: state.account.get('restrictions'),
  ssoProvider: state.global.get('ssoProvider'),
});
const mapDispatchToProps = dispatch => ({
  getTranslations: lang => dispatch(getTranslations(lang)),
  setHeroAuthentication: data => dispatch(setHeroAuthentication(data)),
  handleSubdomain: subdomain => dispatch(handleSubdomain(subdomain)),
  fetchAppTimezone: () => dispatch(fetchAppTimezone()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Main);
