/**
 * Renders one message
 * @module DirectChat
 * @class Message
 */
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const Message = props => {

  let avatarStyle = props.poster.get('profilePictureUrl') !== null ? {
    backgroundSize: 'cover',
    backgroundImage: `url('` + props.poster.get('profilePictureUrl') + `')`
  } : {};

  return (
    <div
      className={classnames('directChat-messages-messageWrapper', {
        directionShift: props.directionShift,
        mine: props.direction === 1
      })}
    >
      {
        props.showAvatar
          ? (
            <div
              className={classnames("directChat-messages-messageAvatar", "directChat-contacts-conversationImage", {"defaultAvatar2": props.poster.get('profilePictureUrl') === null },"insideChatAvatar")}
              style={avatarStyle}
            >
            </div>
          )
          : undefined
      }
      <div
        className={classnames('directChat-messages-message', {
          mine: props.direction === 1,
          withImage: !!props.image,
          withoutAvatar: !props.showAvatar
        })}
      >
        {props.message}
        {
          !!props.image
            ? (
              <div className="directChat-messages-messageImageWrapper">
                <img
                  alt={props.message}
                  className="directChat-messages-messageImageUpload"
                  src={props.image}
                />
            </div>
            )
            :undefined
        }
      </div>
    </div>
  );
}

Message.propTypes = {
  /**
   * Whether I sent or received this message
   * @type {Number}
   */
  direction: PropTypes.number.isRequired,
  /**
   * Whether the direction has shifted since the previous message
   * @type {Boolean}
   */
  directionShift: PropTypes.bool.isRequired,
  /**
   * Path to possible comment image upload
   * @type {String}
   */
  image: PropTypes.string,
  /**
   * Text representation of the message
   * @type {String}
   */
  message: PropTypes.string.isRequired,
  /**
   * Data representation of the person user is chatting with
   * @type {Immutable.Map<Connection>}
   */
  poster: PropTypes.object.isRequired,
  /**
   * Whether this message should show the avatar or not
   * @type {Boolean}
   */
  showAvatar: PropTypes.bool.isRequired
};

export default Message;
