import './index.scss';

import { connect } from 'react-redux';
import Audience from './Audience';

const mapStateToProps = state => ({
  allInterests: state.global.get('globalVerticals'),
});

export default connect(mapStateToProps)(Audience);
