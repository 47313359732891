import React from 'react';
import _ from 'lodash';
import AudienceItem from './audience-item';
import InterestPicker from './interest-picker';
import HeroesPicker from './heroes-picker';

const Audience = props => {
  const toggleInterests = (selectedInterests, interest) => _.xor(selectedInterests, [interest]);

  const onInterestClick = interest => {
    const { selectedInterests, handleSelection } = props;
    const newSelectedInterests = toggleInterests(selectedInterests, interest);
    if (newSelectedInterests.length > 3) {
      newSelectedInterests.pop();
    }
    handleSelection({ selectedInterests: newSelectedInterests });
  };

  const handleHeroClick = (heroId, heroName) => {
    const { selectedHeroes, handleSelection } = props;

    const index = selectedHeroes.findIndex(hero => hero.id === heroId);

    if (index === -1) {
      handleSelection({ selectedHeroes: [...selectedHeroes, { id: heroId, name: heroName }] });
    }
  };

  const handleEmailClick = email => {
    const { handleSelection } = props;
    const guest = {
      id: email,
      name: email,
    };
    handleSelection({ selectedHeroes: [...selectedHeroes, guest] });
  };

  const handleHeroRemoveClick = heroId => {
    const { selectedHeroes, handleSelection } = props;

    const newHeroesList = selectedHeroes.filter(hero => hero.id !== heroId);

    handleSelection({ selectedHeroes: newHeroesList });
  };

  const onMembersCheckboxClick = () => {
    const { noMembers, handleSelection } = props;
    handleSelection({ noMembers: !noMembers });
  };

  const { audienceType, allInterests, selectedInterests, selectedHeroes, noMembers, handleSelection, translations } = props;

  return (
    <div className="audience-container">
      <AudienceItem
        id="public__audience-item"
        label={_.get(translations, 'communityPublicAudience', '')}
        isCircle
        isChecked={audienceType === 'public'}
        handleOptionChange={() => handleSelection({ audienceType: 'public', noMembers: false, selectedInterests: [], selectedHeroes: [] })}
      />
      <AudienceItem
        id="specific_verticals__audience-item"
        label={_.get(translations, 'communitySpecificVerticalsAudience', '')}
        extraLabel={_.get(translations, 'communitySpecificVerticalsAudienceExtra', '')}
        interestCount={selectedInterests.length}
        isCircle
        isChecked={audienceType === 'specific_verticals'}
        handleOptionChange={() => handleSelection({ audienceType: 'specific_verticals', noMembers: false, selectedHeroes: [] })}
      />
      {audienceType === 'specific_verticals' ? (
        <InterestPicker allInterests={allInterests} selectedInterests={selectedInterests} onInterestClick={onInterestClick} />
      ) : (
        ''
      )}
      <AudienceItem
        id="closed__audience-item"
        label={_.get(translations, 'communityClosedAudience', '')}
        isCircle
        isChecked={audienceType === 'closed'}
        handleOptionChange={() => handleSelection({ audienceType: 'closed', selectedInterests: [] })}
      />
      {audienceType === 'closed' ? (
        <HeroesPicker
          selectedHeroes={selectedHeroes}
          searchHeroesPlaceholder={_.get(translations, 'communitySearchHeroesPlaceholder', '')}
          noMembers={noMembers}
          noMembersLabel={_.get(translations, 'communityNoMembers', '')}
          handleHeroClick={handleHeroClick}
          handleEmailClick={handleEmailClick}
          handleHeroRemoveClick={handleHeroRemoveClick}
          onMembersCheckboxClick={onMembersCheckboxClick}
        />
      ) : (
        ''
      )}
    </div>
  );
};

export default Audience;
