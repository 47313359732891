import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import CONST from '../../../../utils/const';
import noProfilePicture from '../../../../assets/images/defaultAvatarimage.png';

class CommentForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
    };
    this.fieldRef = React.createRef();

    this.submitForm = this.submitForm.bind(this);
  }

  clearFormErrors() {
    this.setState({ errors: {} });
  }

  submitForm(event) {
    const { saveComment, post, isReplyForm, parentCommentId, handleReplyForm, history, uniqueIdentifier } = this.props;
    const field = event.target;
    const comment = event.target.value;

    if (event.keyCode === CONST.keyCodes.ESC) {
      field.value = '';
    }
    if (event.keyCode === CONST.keyCodes.ENTER && !_.isEmpty(comment)) {
      const postId = _.get(post, 'id', null);
      const formData = {
        content: comment,
        isReplyForm,
        parentCommentId,
        postId,
        fieldRef: `${uniqueIdentifier}-${postId}`,
      };
      saveComment(formData)
        .then(() => {
          this.clearFormErrors();
          field.value = '';
          if (_.isFunction(handleReplyForm)) {
            handleReplyForm(false);
          }
        })
        .catch(response => {
          const errorCode = _.get(response, 'errorCode', null);
          if (errorCode) {
            history.replace({
              pathname: `/communities#errorCode=${errorCode}`,
            });
          }
        });
    }
  }

  render() {
    const {
      heroProfile,
      translations,
      isLoading,
      isReplyLoading,
      post,
      history,
      isReplyForm,
      errors,
      replyErrors,
      uniqueIdentifier,
      heroIsCommunityMember,
      showCommunityPageToast,
    } = this.props;
    const placeholderTranslation = _.get(translations, 'communityComments.placeholderLabel', 'communityComments.placeholderLabel');
    const authorId = _.get(post, 'authorId', null);
    const currentHeroId = _.get(heroProfile, 'id', null);
    const profilePictureUrl = _.get(heroProfile, 'profilePictureUrl', null);
    const firstName = _.get(heroProfile, 'firstName', null);
    const lastName = _.get(heroProfile, 'lastName', null);
    const postId = _.get(post, 'id', null);
    const error = !_.isEmpty(errors) && !_.isString(errors) ? _.get(errors, `${postId}.content[0]`) : null;
    const replyFormIdentifier = `${uniqueIdentifier}-${postId}`;
    const replyError = !_.isEmpty(replyErrors) && !_.isString(replyErrors) ? _.get(replyErrors, `${replyFormIdentifier}.content[0]`) : null;
    const displayError = isReplyForm ? replyError : error;
    const hasLoader = (isReplyLoading && isReplyForm) || (isLoading && !isReplyForm);
    const heroInitials = _.get(heroProfile, 'heroInitials', null);
    return (
      <div
        className={classnames('community__comment-form', {
          'community__comment-form--isReplyForm': isReplyForm,
        })}
      >
        <div className="community-comment__avatar">
          {profilePictureUrl ? (
            <img
              className="community-comment__hero-picture"
              onClick={() => {
                history.push(authorId === currentHeroId ? '/account/profile' : `/connection/${authorId}`);
              }}
              src={profilePictureUrl}
              alt={`${firstName} ${lastName}`}
              title={`${firstName} ${lastName}`}
            />
          ) : (
            <div
              className="hero-initials add-comment-hero-initials"
              onClick={() => {
                history.push(authorId === currentHeroId ? '/account/profile' : `/connection/${authorId}`);
              }}
            >
              {heroInitials}
            </div>
          )}
        </div>
        <div className="community-post__comment-input-container">
          {!heroIsCommunityMember ? (
            <>
              <input
                type="text"
                className="community-post__comment-input"
                placeholder={placeholderTranslation}
                onClick={e => {
                  e.currentTarget.setAttribute('disabled', true);
                  return showCommunityPageToast('toast.onlyMemberCanPost');
                }}
              />
            </>
          ) : (
            <input
              type="text"
              className="community-post__comment-input"
              placeholder={placeholderTranslation}
              onKeyUp={e => this.submitForm(e)}
            />
          )}

          {displayError && <div className="community-post__validation-errors">{displayError}</div>}
          {hasLoader && <div className="comments-wrapper__loader" />}
        </div>
      </div>
    );
  }
}

CommentForm.propTypes = {
  saveComment: PropTypes.func.isRequired,
  post: PropTypes.shape({}).isRequired,
  isReplyForm: PropTypes.bool,
  parentCommentId: PropTypes.number,
  handleReplyForm: PropTypes.func,
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
  }).isRequired,
  errors: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  replyErrors: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  heroProfile: PropTypes.shape({}),
  translations: PropTypes.shape({}).isRequired,
  isLoading: PropTypes.bool.isRequired,
  isReplyLoading: PropTypes.bool.isRequired,
  uniqueIdentifier: PropTypes.string,
};

CommentForm.defaultProps = {
  errors: {},
  replyErrors: {},
  parentCommentId: undefined,
  isReplyForm: null,
  handleReplyForm: null,
  uniqueIdentifier: undefined,
  heroProfile: undefined,
};

export default withRouter(CommentForm);
