export const SET_TOAST_MESSAGE = 'SET_TOAST_MESSAGE';

/*
 * Sync Actions
 */
export const setToastMessage = toastMessage => ({
  type: SET_TOAST_MESSAGE,
  toastMessage,
});

export const setDashboardToastMessage = (toastMessage, timer = 3000) => dispatch => {
  dispatch(setToastMessage(toastMessage));
  setTimeout(() => {
    dispatch(setToastMessage(null));
  }, timer);
};
