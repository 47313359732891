import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Link, withRouter} from 'react-router-dom';
import ProfileMenuContainer from './../profileMenuContainer/profileMenuContainer';
import { connect } from 'react-redux';
import { suggestionsOff } from './../../actions/global.js';
import { fetchDirectChatConversations, updateUnreadConversationsCount, getDirectChatConversations } from './../../actions/directChat';
import _ from 'lodash';
import { translations } from '../../selectors/translations';
import defaultAvatarImage from '../../assets/images/defaultProfilePicture.png';
import {currentRouteGroup, currentRouteRestriction} from "../../utils/currentRouteGroup";

import CONST from '../../utils/const';

import './css/index.scss';

class Navigation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      profileMenuOpened: false
    };
  }
  profileMenuClick = () => {
    this.setState({
      profileMenuOpened: !this.state.profileMenuOpened
    });
  }

  componentDidMount () {
    if(this.props.isAuthenticated) {
      this.props.fetchDirectChatConversations();
    }
  }

  componentDidUpdate () {
    if(this.props.conversations.size > 0 && this.props.heroProfile !== null)
    {
      let unreadConversationsCount = 0;
      let conversations = this.props.conversations.toJS();
      conversations.map((conversation) => {
        if(conversation.isRead !== true && conversation.lastMessage !== null && conversation.lastMessage.heroId !== this.props.heroProfile.id)
        {
          unreadConversationsCount++;
        }
        return null;
      });
      this.delayUpdateCoversationsCount(unreadConversationsCount);
    }
  }

  delayUpdateCoversationsCount = _.debounce(function(unreadConversationsCount){
    if(this.props.unreadConversationsCount !== unreadConversationsCount)
    {
       this.props.updateUnreadConversationsCount(unreadConversationsCount);
    }
  }, 200);

  render() {
    const {translations:trans , location, heroProfile, restrictions} = this.props;

    if(trans === null) return null;

    const profileCompleted = _.get(heroProfile, 'profileCompleted', 0);
    const heroInitials = _.get(heroProfile,'heroInitials', null);
    const profilePictureUrl = _.get(heroProfile, 'profilePictureUrl', null);
    const linkToDirectChat = profileCompleted ? "/direct-chat" : "/account/profile/incomplete";
    const myAccountLabel = _.get(trans, 'myAccount', 'My account');
    const disableLink = this.props.isWmsApp && !this.props.isAuthenticated;

    return (
      <div>
        <nav className="navigation">
          <ul>
            <li className={classnames("navigationItem", {
              "navigationItem-inactive" : _.find(restrictions.toJS(), x => x.restriction_code === currentRouteRestriction(CONST.navigation.dashboard)) || disableLink}
            )}>
            <Link
              to="/dashboard"
              id="navigationItem-dashboard"
              className={classnames('navigationItem-anchor', {
                'navigationItem-anchor-active': currentRouteGroup(location.pathname) === CONST.navigation.dashboard
              })}
            >
              <div className="navigation__dashboard_icon"></div>
              <span className="navigationItem-caption">{_.get(trans, 'dashboard', 'Dashboard')}</span>
            </Link>
          </li>
            <li className={classnames("navigationItem", {
              "navigationItem-inactive" : _.find(restrictions.toJS(), x => x.restriction_code === currentRouteRestriction(CONST.navigation.booking)) || disableLink}
            )}>
            <Link
              to="/booking"
              id="navigationItem-booking"
              className={classnames('navigationItem-anchor', {
                'navigationItem-anchor-active': currentRouteGroup(location.pathname) === CONST.navigation.booking
              })}
            >
              <span className="icon icon-booking navigationItem-icon" />
              <span className="navigationItem-caption">{_.get(trans, 'booking', 'Booking')}</span>
            </Link>
          </li>
            <li className={classnames("navigationItem", {
              "navigationItem-inactive" : _.find(restrictions.toJS(), x => x.restriction_code === currentRouteRestriction(CONST.navigation.community)) || disableLink}
            )}>
            <Link
                to="/communities"
                id="navigationItem-community"
                className={classnames('navigationItem-anchor', {
                  'navigationItem-anchor-active': currentRouteGroup(location.pathname) === CONST.navigation.community
                })}
            >
              <span className="icon icon-community navigationItem-icon"/>
              <span className="navigationItem-caption">{_.get(trans, 'community', 'Communities')}</span>
            </Link>
          </li>
            <li className={classnames("navigationItem", {
              "navigationItem-inactive" : _.find(restrictions.toJS(), x => x.restriction_code === currentRouteRestriction(CONST.navigation.connections)) || disableLink}
            )}>
            <Link
            to="/connections"
            onClick={this.props.suggestionsOff}
            id="navigationItem-connections"
            className={classnames('navigationItem-anchor', {
            'navigationItem-anchor-active': currentRouteGroup(location.pathname) === CONST.navigation.connections
          })}
            >
            <span className="icon icon-connections navigationItem-icon" />
            <span className="navigationItem-caption">{_.get(trans, 'connections', 'Connections')}</span>
            </Link>
          </li>
          <li className={classnames("navigationItem", {
            "navigationItem-inactive" : _.find(restrictions.toJS(), x => x.restriction_code === currentRouteRestriction(CONST.navigation.directChat)) || disableLink}
          )}>
            <Link
            to={linkToDirectChat}
            onClick={this.props.getDirectChatConversations}
            id="navigationItem-chat"
            className={classnames('navigationItem-anchor', {
            'navigationItem-anchor-active':currentRouteGroup(location.pathname) === CONST.navigation.directChat
          })}
            >
            <span className="icon icon-directChat navigationItem-icon" />
            {this.props.unreadConversationsCount > 0 ? (
                <span className="badge">{this.props.unreadConversationsCount}</span>) : null}
            <span className="navigationItem-caption">{_.get(trans, 'chat', 'Direct chat')}</span>
            </Link>
          </li>
          </ul>
          <div
              className={classnames('heroInitials',{'opened': this.state.profileMenuOpened === true}, "navigationAvatar")}
              onClick={this.profileMenuClick}
              style={profilePictureUrl ? {backgroundImage: `url(${profilePictureUrl})`, backgroundSize: 'cover'} : !heroInitials ? {backgroundImage: `url(${defaultAvatarImage})`, backgroundSize: 'cover'}:null}
          >
            {!profilePictureUrl ? (<span>{heroInitials}</span>) : null }
          </div>
          <div className="account-tooltip__container">
            <div className="account-tooltip-label">
              {myAccountLabel}
            </div>
            <div className="account-tooltip-arrow"/>
          </div>
        </nav>
        <ProfileMenuContainer
          isAuthenticated={this.props.isAuthenticated}
          profileMenuClick={this.profileMenuClick}
          active={this.state.profileMenuOpened}
          trans={this.props.translations}
        />
      </div>
    )
  }
}

Navigation.propTypes = {
  active: PropTypes.string
};

const mapStateToProps = state => {
  return {
    translations: _.extend(_.get(translations(state), 'navigation', {}), _.get(translations(state), 'header', {})),
    isAuthenticated: state.auth.get('isAuthenticated'),
    conversations: state.directChat.get('conversations'),
    unreadConversationsCount: state.directChat.get('unreadConversations'),
    heroProfile: state.global.get('heroProfile'),
    bookingSection: state.addBooking.get('bookingSection'),
    restrictions: state.account.get('restrictions'),
    isWmsApp: window.__env.isWmsApp,
  }
};

const mapDispatchProps = dispatch => {
  return {
    suggestionsOff: () => {
      dispatch(suggestionsOff());
    },
    getDirectChatConversations: () => {
      dispatch(getDirectChatConversations(''));
    },
    fetchDirectChatConversations: () => dispatch(fetchDirectChatConversations()),
    updateUnreadConversationsCount: (count) => dispatch(updateUnreadConversationsCount(count)),
  }
};

export default withRouter(connect(mapStateToProps, mapDispatchProps)(Navigation));
