import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

class OfficeBooked extends React.Component {

  getOfficeType = () => {
    const type = this.props.details.selectedRoom.type;
    const typeName = this.props.details.selectedRoom.typeName;
    return (
      <div className="office-booked-details">
        <div className="office-booked-title">{typeName}</div>
        <div className={classnames(`icon-${type}`)}>{type}</div>
      </div>
    )
  }

  getBookingPeriod (selectedOptions) {
    if (typeof selectedOptions.endDate !== 'undefined' && typeof selectedOptions.startDate !== 'undefined') {
      return (
        <div className="office-booked-details-description">{selectedOptions.startDate} - {selectedOptions.endDate}</div>
      )
    }
  }

  getWorkDays (selectedOptions) {
    if (typeof selectedOptions.hoursFrom !== 'undefined' && typeof selectedOptions.hoursUntil !== 'undefined') {
      return (
        <div className="office-booked-details-description">{selectedOptions.hoursFrom} - {selectedOptions.hoursUntil}</div>
      )
    }
  }

  render() {
    let amenitiesList = this.props.details.amenities.map(amenity => (
        <div
          key={amenity.id}
          className={amenity.available ? 'office-booked-details-description office-booked-details-available-before' : 'office-booked-details-description office-booked-details-unavailable-before'}>{amenity.name}</div>
    ));

    let rulesList = this.props.details.houseRules.map(rule => (
      <div
        key={rule.id}
        className={rule.available ? 'office-booked-details-description office-booked-details-available' : ' office-booked-details-description office-booked-details-unavailable'}>
          {rule.name}
          {rule.attachments ? <div className="office-rules-attachment">{rule.attachments.join(',')}</div> : ''}
      </div>
    ));

    return (
      <div className="office-booked">
        <div className="office-booked-list">
          {this.getOfficeType()}
          <div className="office-booked-details office-booked-details-with-titles">
              <div className="office-booked-title">BOOKING PERIOD</div>
              { this.getBookingPeriod(this.props.details.selectedOptions) }
              <div className="office-booked-title">YOUR CHOSEN WORK DAYs</div>
              <div className="office-booked-details-description">{this.props.details.workingHours.weekDays.join(', ')}</div>
              <div className="office-booked-title">YOUR CHOSEN WORKING HOURS</div>
              {this.getWorkDays(this.props.details.selectedOptions)}
              <div className="office-booked-title">House RULES</div>
              { rulesList }
          </div>
          <div className="office-booked-details">
            <div className="office-booked-title">AMENITIES THAT YOU CHOOSE</div>
              { amenitiesList }
          </div>
        </div>
      </div>
    )
  }
};

OfficeBooked.propTypes = {
  details: PropTypes.object.isRequired,
};

export default OfficeBooked;
