import Immutable, { fromJS } from 'immutable';
import { HERO_HAS_OWN_COMMUNIY, SET_HERO_COMMUNITIES, SET_HERO_COMMUNITIES_IS_LOADING } from '../../actions/community/hero-communities';

const initialState = Immutable.Map({
  heroCommunities: fromJS([]),
  hasOwnCommunity: 1,
});

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_HERO_COMMUNITIES:
      return state.setIn(['heroCommunities'], action.heroCommunities);
    case SET_HERO_COMMUNITIES_IS_LOADING:
      return state.setIn(['isLoading'], action.isLoading);
    case HERO_HAS_OWN_COMMUNIY:
      return state.setIn(['hasOwnCommunity'], action.heroOwnCommunity);
    default:
      return state;
  }
};
