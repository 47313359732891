import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import Dropdown from '../../dashboard/common/dropdown/Dropdown';
import PaymentProfilesOptions from '../../paymentProfile/paymentProfilesOptions';

const alphanumeric = input => {
  const letterNumber = /^[0-9a-zA-Z]+$/;
  return !!input.match(letterNumber);
};

class PaymentAccountType extends Component {
  constructor(props) {
    super(props);
    const { accountData, countries, history } = props;
    const account_type = _.get(accountData, 'account_type', '');
    const accType = _.get(history, 'location.state.paymentType', account_type);
    let accTypeInt = '';
    switch (accType) {
      case 'personal':
        accTypeInt = 0;
        break;
      case 'business':
        accTypeInt = 1;
        break;
      default:
        accTypeInt = '';
    }
    const countryName = _.trim(_.get(accountData, 'country', ''));
    const countryId = countries ? countries.filter(item => _.trim(_.get(item, 'name', null)) === countryName).map(item => item.id) : '';
    this.state = {
      accountType: accTypeInt,
      address: _.get(accountData, 'address', ''),
      city: _.get(accountData, 'city', ''),
      postalCode: _.get(accountData, 'postal_code', ''),
      country: _.get(countryId, '0', ''),
      companyName: _.get(accountData, 'company.name', ''),
      vatNumber: _.get(accountData, 'company.vatNumber', ''),
      paymentProfileId: _.get(accountData, 'paymentProfileId', null),
      inputErrors: [],
    };

    this.handleOptionChange = this.handleOptionChange.bind(this);
    this.handleCountryChange = this.handleCountryChange.bind(this);
    this.renderBusinessSection = this.renderBusinessSection.bind(this);
    this.renderPersonalSection = this.renderPersonalSection.bind(this);
    this.enableContinueToPaymentButton = this.enableContinueToPaymentButton.bind(this);
    this.checkInputFields = this.checkInputFields.bind(this);
    this.handleCancelButton = this.handleCancelButton.bind(this);
    this.sendDataToPaymentStep = this.sendDataToPaymentStep.bind(this);
    this.handleProfileAccount = this.handleProfileAccount.bind(this);
  }

  handleOptionChange(e) {
    this.setState({
      accountType: parseInt(e.target.value, 10),
      paymentProfileId: null,
      address: null,
      city: null,
      postalCode: null,
      country: null,
      companyName: null,
      vatNumber: null,
    });
  }

  handleCountryChange(country) {
    this.setState({
      country,
    });
  }

  handleCancelButton() {
    const { history, onChangeSection, resetBooking } = this.props;
    const previousLocation = _.get(history, 'location.state.from', '/dashboard');
    resetBooking();
    onChangeSection('options');
    history.push(previousLocation);
  }

  enableContinueToPaymentButton() {
    const { accountType, address, city, postalCode, country, companyName, vatNumber } = this.state;
    if (accountType === 0 && address && city && postalCode && country) return 1;
    if (accountType === 1 && address && city && postalCode && country && companyName && vatNumber) return 1;
    return 0;
  }

  handleProfileAccount(paymentProfile) {
    this.setState({
      paymentProfileId: _.get(paymentProfile, 'id', null),
      accountType: _.get(paymentProfile, 'company_name', null) ? 1 : 0,
      address: _.get(paymentProfile, 'company_address', ''),
      city: _.get(paymentProfile, 'city', ''),
      postalCode: _.get(paymentProfile, 'postal_code', ''),
      country: _.get(paymentProfile, 'country', ''),
      companyName: _.get(paymentProfile, 'company_name', ''),
      vatNumber: _.get(paymentProfile, 'company_vat_number', ''),
    });
  }

  checkInputFields() {
    const { accountType, inputErrors, vatNumber } = this.state;
    // add extra validations here if needed
    if (accountType === 1 && !alphanumeric(vatNumber)) {
      inputErrors.push('vatNumber');
      this.setState({ inputErrors });
      return false;
    }
    return this.sendDataToPaymentStep();
  }

  sendDataToPaymentStep() {
    const { proceedToPaymentMethods, countries } = this.props;
    const { accountType, address, city, country, postalCode, companyName, vatNumber, paymentProfileId } = this.state;
    const fullData = {};
    const countryName = countries ? countries.filter(item => _.get(item, 'id', null) === country).map(item => item.name) : '';
    fullData.isDefault = 1;
    fullData.account_type = !accountType ? 'personal' : 'business';
    fullData.address = address;
    fullData.city = city;
    fullData.country = _.get(countryName, '0', '');
    fullData.postal_code = postalCode;
    fullData.paymentProfileId = paymentProfileId;
    if (accountType === 1) {
      fullData.company = { name: companyName, vatNumber, postal_code: postalCode, country, city };
    }
    return proceedToPaymentMethods(fullData);
  }

  renderPersonalSection() {
    const { address, city, postalCode, country } = this.state;
    const { countries, translations } = this.props;
    const countriesArray = countries ? countries.map(item => ({ id: item.id, value: item.name })) : [];
    const requiredLabel = _.get(translations, 'required', 'required');

    return (
      <div className="payment-address-section">
        <div className="payment-info-group">
          <label className="payment-info-label" htmlFor="address-input">
            {_.get(translations, 'address', 'Address')} ({requiredLabel})
            <input
              id="address-input"
              type="text"
              className={`payment-info-input ${address ? 'payment-info-input-completed' : ''}`}
              name="address"
              value={address}
              onChange={e => {
                this.setState({ address: e.target.value });
              }}
            />
          </label>
        </div>
        <div className="payment-info-group-multiple">
          <label className="payment-info-label" htmlFor="city-input">
            {_.get(translations, 'city', 'City')} ({requiredLabel})
            <input
              id="city-input"
              type="text"
              className={`payment-info-input ${city ? 'payment-info-input-completed' : ''}`}
              name="city"
              value={city}
              onChange={e => {
                this.setState({ city: e.target.value });
              }}
            />
          </label>
          <label className="payment-info-label" htmlFor="postal_code-input">
            {_.get(translations, 'postal_code', 'Postal code')} ({requiredLabel})
            <input
              id="postal_code-input"
              type="text"
              className={`payment-info-input ${postalCode ? 'payment-info-input-completed' : ''}`}
              name="postal_code"
              value={postalCode}
              onChange={e => {
                this.setState({ postalCode: e.target.value });
              }}
            />
          </label>
          <label className="payment-info-label" htmlFor="country-input">
            {_.get(translations, 'country', 'Country')} ({requiredLabel})
            <Dropdown
              onChange={this.handleCountryChange}
              options={countriesArray}
              placeHolder={_.get(translations, 'select', 'Select')}
              className="dropdown__selector country__selector"
              selectedId={country}
            />
          </label>
        </div>
      </div>
    );
  }

  renderBusinessSection() {
    const { companyName, vatNumber, inputErrors } = this.state;
    const { translations } = this.props;
    const requiredLabel = _.get(translations, 'required', 'required');

    return (
      <div className="payment-business-section">
        <div className="business-info-group">
          <label className="payment-info-label" htmlFor="company-name-input">
            {_.get(translations, 'companyName', 'Company Name')} ({requiredLabel})
            <input
              id="company-name-input"
              type="text"
              className={`company-name-input payment-info-input ${companyName ? 'payment-info-input-completed' : ''}`}
              name="company_name"
              value={companyName}
              onChange={e => {
                this.setState({ companyName: e.target.value });
              }}
            />
          </label>
          <label className="payment-info-label" htmlFor="vat-number-input">
            {_.get(translations, 'vatNumber', 'VAT number')} ({requiredLabel})
            <input
              id="vat-number-input"
              type="text"
              className={`vat-number-input payment-info-input ${vatNumber ? 'payment-info-input-completed' : ''} ${
                inputErrors.includes('vatNumber') ? 'payment-input-error' : ''
              }`}
              name="vat_number"
              value={vatNumber}
              onChange={e => {
                this.setState({ vatNumber: e.target.value });
              }}
            />
            <span>{_.get(translations, 'vatNumberMessage', 'e.g.: BE0xxxxxxxxx (no dots)')}</span>
          </label>
        </div>
        {this.renderPersonalSection()}
      </div>
    );
  }

  render() {
    const { accountType, paymentProfileId } = this.state;
    const { translations, firstPaymentProfile, paymentProfiles } = this.props;
    const enableContinueToPaymentButton = this.enableContinueToPaymentButton();
    if (!paymentProfiles) {
      return <div className="payment-profile__loader" />;
    }

    return (
      <div className="select-account-type">
        <div className="account-types">
          <div className="account-type_title">{_.get(translations, 'selectAccType', 'Select account type')}</div>
          { !paymentProfiles.toJS().payment_profiles.length ? (
            <div className="text-field__content">
              <div className="account-type-select">
                <label htmlFor="personal_account">
                  <input
                    id="personal_account"
                    type="radio"
                    name="account_type"
                    value={0}
                    checked={accountType === 0}
                    onChange={this.handleOptionChange}
                    className="radio-button"
                  />
                  <span>{_.get(translations, 'typeOptions.personal', 'Personal')}</span>
                </label>
              </div>
              <div className="account-type-select">
                <label htmlFor="business_account">
                  <input
                    id="business_account"
                    type="radio"
                    name="account_type"
                    value={1}
                    checked={accountType === 1}
                    onChange={this.handleOptionChange}
                    className="radio-button"
                  />
                  <span>{_.get(translations, 'typeOptions.company', 'Business')}</span>
                </label>
              </div>
            </div>
          ) : (
            <PaymentProfilesOptions handleAccountType={this.handleOptionChange} handleProfileAccount={this.handleProfileAccount} />
          )}
        </div>
        {!paymentProfileId ? (
          <div className="booking-payment-form">
            {accountType === 0 ? (
              <div className="booking-payment-personal">
                <div className="personal-info-headline">
                  {_.get(translations, 'typeOptionsInformation.personal', 'Personal Information')}
                </div>
                {this.renderPersonalSection()}
              </div>
            ) : null}
            {accountType === 1 ? (
              <div className="booking-payment-business">
                <div className="personal-info-headline">{_.get(translations, 'typeOptionsInformation.company', 'Company Information')}</div>
                {this.renderBusinessSection()}
              </div>
            ) : null}
          </div>
        ) : null}

        <div className="account-type-buttons">
          <button
            className={`${enableContinueToPaymentButton ? 'continue-to-payment' : 'continue-to-payment-disabled'}`}
            type="button"
            onClick={this.checkInputFields}
            disabled={!enableContinueToPaymentButton}
          >
            {_.get(translations, 'continueToPaymentMethod', 'Continue to payment method')}
          </button>
          {!firstPaymentProfile && (
            <button className="cancel-booking-payment" type="button" onClick={this.handleCancelButton}>
              {_.get(translations, 'bookLater', "I don't want to book now")}
            </button>
          )}
        </div>
      </div>
    );
  }
}

PaymentAccountType.propTypes = {
  translations: PropTypes.shape({}),
  firstPaymentProfile: PropTypes.bool,
};

PaymentAccountType.defaultProps = {
  translations: {},
  firstPaymentProfile: false,
};
export default withRouter(PaymentAccountType);
