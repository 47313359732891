import React from 'react';
import PropTypes from 'prop-types';
import './../css/mobileApp.scss';
import { isAndroid, isIOS } from 'react-device-detect';
import importAll from './../../../utils/helpers';

const badges = importAll(require.context('../../../assets/images/badges', false, /\.(png|jpe?g|svg)$/));

class MobileApp extends React.Component {

  onRequest = () => {
    this.props.onAction();
  }

  onCancelRequest = () => {
    this.props.onCancelAction(false);
  }

  goToAndroidPlay = () => {
    let win = window.open('https://play.google.com/store/apps/details?id=com.workero.runner', '_blank');
    win.focus();
    this.props.onCancelAction(false);
  }

  goToAppStore = () => {
    let win = window.open('https://itunes.apple.com/us/app/workero/id1388349529?ls=1&mt=8', '_blank');
    win.focus();
    this.props.onCancelAction(false);
  }

  render () {
    // console.log('trans', this.props.trans);
    
    return (
      <div className="mobileApp">
        <div onClick={ this.onCancelRequest } className="modal-close-button"></div>
        <div className="mobileApp-title items-style1">{this.props.trans.modals.mobileApp.title}</div>
        <div className="mobileApp-info">{this.props.trans.modals.mobileApp.info}</div>
        <div style={{textAlign: 'center'}}>
        { isAndroid === true ? <img alt="androidPlay" onClick={this.goToAndroidPlay} src={badges['badgeAndroid.png']}/> : null }
        { isIOS === true ? <img alt="AppStore" onClick={this.goToAppStore} src={badges['badgeApple.png']}/> : null }
        { isAndroid === false && isIOS === false ? <div><img alt="androidPlay" style={{cursor: 'pointer'}} onClick={this.goToAndroidPlay} src={badges['badgeAndroid.png']}/><img alt="AppStore" onClick={this.goToAppStore} style={{height: '45px', cursor: 'pointer', marginLeft: '30px'}} src={badges['badgeApple.png']}/></div> : null }
        </div>
        <div className="mobileApp-actions">
          <button
            style={{marginLeft: '0'}}
            className="mobileApp-cancelButton main-button"
            onClick={ this.onCancelRequest }>{this.props.trans.modals.mobileApp.cancel}</button>
        </div>
      </div>
    );
  }
}

MobileApp.propTypes = {
  onAction: PropTypes.func
};

export default MobileApp;
