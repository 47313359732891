import React from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import _ from 'lodash';
import classnames from 'classnames';
import MapContainer from '../mapContainer/mapContainer';

import officePin from '../../assets/svg/map/available.svg';

const OfficeContactInfo = props => {
  const { hasPaddingBottom, contactInfo } = props;
  const { map, address, name, phone, emailPerson, emailOffice, additional } = contactInfo;

  const { bookedStatus } = props;
  const { title } = props;
  const classWrapper = !bookedStatus ? 'contact-info' : 'contact-info-booked';
  const classInfoWrapper = `contact-info ${classWrapper}`;
  const getTitle = !bookedStatus ? '' : <div className="contact-info-fields-title">{title}</div>;
  /* set a default pin for office location */
  map.markers.forEach(marker => (marker.icon = marker.icon || officePin));

  return (
    <div className={classInfoWrapper}>
      <div className="contact-info-title">{_.get(props, 'trans.bookingPage.contactInformation', 'Contact information')}</div>
      <div className="contact-info-map-container">
        <MapContainer
          hideLegend
          className="--no-padding --full-height"
          mapData={Immutable.fromJS(map)}
          activeMarker={map.activeMarker}
          scrWheel={false}
          draggable={false}
        />
      </div>

      <div className={`contact-info-fields ${hasPaddingBottom ? 'contact-info-fields--padding-bottom' : ''}`}>
        <div className="contact-info__section">
          {getTitle}
          <div className="contact-info-adress">
            <div className="contact-info-label">{_.get(props, 'trans.bookingPage.address', 'Address')}</div>
            <span className="contact-info-value">{`${address.street}, ${address.postCode}, ${address.city}`}</span>
          </div>
          <div className="contact-info-name">
            <div className="contact-info-label">{_.get(props, 'trans.bookingPage.contactPerson', 'Contact Person')}</div>
            <span className="contact-info-value">{name}</span>
          </div>
          {additional && (
            <div className="contact-info-additional">
              <div className="contact-info-label">{_.get(props, 'trans.bookingPage.additionalInformation', 'Additional information')}</div>
              {_.get(additional, 'title', null) && <span className="contact-info-value">{_.get(additional, 'title')}</span>}
              {_.get(additional, 'title', null) && <br />}
              {_.get(additional, 'comment', null) && (
                <span
                  className={classnames(`${_.get(additional, 'title', null) ? 'office-cancelation-disclaimer' : 'contact-info-value'}`)}
                >
                  {_.get(additional, 'comment')}
                </span>
              )}
            </div>
          )}
        </div>
        <div className="contact-info__section">
          {phone && (
            <div className="contact-info-phone">
              <div className="contact-info-label">{_.get(props, 'trans.bookingPage.phoneNumber', 'Phone Number')}</div>
              <span className="contact-info-value">{phone}</span>
            </div>
          )}
          <div className="contact-info-emailPerson">
            <div className="contact-info-label">{_.get(props, 'trans.bookingPage.emailContactPerson', 'E-mail Contact Person')}</div>
            <span className="contact-info-value">{emailPerson}</span>
          </div>
        </div>
        <div className="contact-info__section">
          <div className="contact-info-emailOffice">
            <div className="contact-info-label">{_.get(props, 'trans.bookingPage.officeEmail', 'Office E-mail"')}</div>
            <span className="contact-info-value">{emailOffice}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

OfficeContactInfo.propTypes = {
  contactInfo: PropTypes.object.isRequired,
  hasPaddingBottom: PropTypes.bool,
};

OfficeContactInfo.defaultProps = {
  hasPaddingBottom: false,
};

export default OfficeContactInfo;
