import React from 'react';
import _ from "lodash";

class AddNewHeroForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      company: '',
      heroName: '',
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleInvitation = this.handleInvitation.bind(this);
  }
  componentDidMount() {
    this.setState({heroName: this.props.heroName})
  }
  handleChange = (ev) =>  {
    this.setState({
      [ev.target.name]: ev.target.value
    })
  }

  handleInvitation() {
    const { heroName, email, company } = this.state;
    const {handleInviationGuest, handleCancel } = this.props;
    let firstName = heroName.substr(0,heroName.indexOf(' '));
    let lastName = heroName.substr(heroName.indexOf(' ')+1);

    if (firstName === '') {
      firstName = lastName;
      lastName = '';
    }

    const guest = {
      id: null,
      email: email,
      company: company,
      firstName: firstName,
      lastName: lastName,
    }
    handleInviationGuest(guest);
  }

  render() {
    const {heroName, email, company} = this.state;

    return (
      <form onSubmit={event => {
        event.preventDefault();
        event.stopPropagation();
        this.handleInvitation();
      }}
      >
        <div className="booking-invite-members__add-new-member">
          <input
            type="text"
            value={heroName}
            onChange={this.handleChange
            }
            name='heroName'
            required
          />
          <input type="email" value={email} name="email" placeholder="E-mail (mandatory)" required onChange={this.handleChange}  />
          <input type="text" name="company" value={company} placeholder="Company" onChange={this.handleChange} />
          <div className="booking-invite-member__button-section">
            <button type="submit" className="hero-invite-button">
              {_.get(this.props.translations, 'meetingAttendees.inviteButton', 'Invite')}
            </button>
            <span
              type="button"
              className="button-delete-invitation"
              onClick={() => {
                this.props.handleCancel();
              }}
            >
              {_.get(this.props.translations, 'meetingAttendees.cancelButton', 'Cancel')}
            </span>
          </div>
        </div>
      </form>
    );
  }
}
export default AddNewHeroForm;
