/**
 * Renders the messages information for the selected conversation
 * @module DirectChat
 * @class MessageWindow
 */
import classnames from 'classnames';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import TimeAgo from './../../timeAgo/timeAgo';
import _ from 'lodash';

import CONST from './../../../utils/const';
import Message from './message';

import greenOffice from './../../../assets/images/direct-chat/green_office.jpg';

class MessageWindow extends Component {

  constructor(props){
    super(props);

    this.state = {
      loadingMoreMessages: false,
      isScrolledDown: false
    };

    this.handleScrollStop = this.handleScrollStop.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps){
    let messages = nextProps.conversation.get('thread');
    if(messages !== undefined) {
      let firstMessageId = messages.getIn([0, 'id']);
      this.setState({firstMessageId: firstMessageId});
    }
  }

  componentDidUpdate(prevProps) {
    const props = this.props;
    if (
      props.conversation.get('id') !== prevProps.conversation.get('id') &&
      this.scrollbars
    ) {

      setTimeout(() => {
        this.messageInput.focus();
        this.messageInput.value = '';
      }, 100);
    }

    if(this.state.loadingMoreMessages === true)
    {
         this.setState({
          loadingMoreMessages: false
        });
    }

    if(this.props.conversation.toJS().newConv !== undefined && this.props.conversation.toJS().newConv === false)
    {
       this.props.actions.fetchConversationMessages(this.props.conversation.get('id'));
    }

    let previousConversation = prevProps.conversation.toJS();
    let currentConversation = this.props.conversation.toJS();

    if(previousConversation.id !== undefined && currentConversation.id !== undefined)
    {
      if(previousConversation.id !== currentConversation.id)
      {
        this.scrollbars.scrollToBottom();
      }

      if(previousConversation.thread !== undefined && currentConversation.thread !== undefined && previousConversation.thread.length !== currentConversation.thread.length)
      {
        this.scrollbars.scrollToBottom();
      }
    }

    var firstMessageId = 'message-' + this.state.firstMessageId;
    if (this.state.firstMessageId !== this.state.scrolledMessageId)
    {
      let messageElement = document.getElementById(firstMessageId);
      if (messageElement) {
        if (this.state.scrolledMessageId) {
          let lastTopMessageElement = document.getElementById('message-' + this.state.scrolledMessageId);
          if (lastTopMessageElement) {
            lastTopMessageElement.scrollIntoView();
            this.scrollbars.scrollTop(this.scrollbars.getScrollTop() - 16); //16px is the height of the loader
          }
        }
        else {
          this.scrollbars.scrollToBottom();
        }

        this.setState({scrolledMessageId: this.state.firstMessageId});
      }
    }
  }

  toggleLoadingMoreMessages = () => {
    this.setState({
      loadingMoreMessages: !this.state.loadingMoreMessages
    });
  }

  componentDidMount() {
    if(this.messageInput)
      this.messageInput.focus();
  }

  handleScrollStop() {
    if(this.scrollbars && this.state.loadingMoreMessages === false &&  this.scrollbars.getScrollTop() === 0 )
    {
        if(this.props.conversation.toJS().thread !== undefined && this.props.conversation.toJS().thread[0] !== undefined)
        {
            if(this.props.conversation.toJS().thread[0].timestamp !== this.props.lastFetchedEpoch)
            {
              this.setState({
                loadingMoreMessages: true
              }, () => {
                this.props.actions.fetchConversationMessages(this.props.conversation.get('id'), this.props.conversation.toJS().thread[0].timestamp);
              });
            }
        }
    }
    if(this.scrollbars.getValues().top === 1)
    {
        // is at bottom
        if(this.state.isScrolledDown === false)
        {
          this.setState({
            isScrolledDown: true
          });
        }
    }
    else
    {
       // scrolled
       if(this.state.isScrolledDown === true)
       {
         this.setState({
           isScrolledDown: false
         });
       }
    }
  }

  render() {
    const props = this.props;
    let moreMessagesLoader = <div style={{visibility: this.state.loadingMoreMessages === true ? 'visible' : 'hidden'}} className="MessageLoader"></div>;
    if (props.conversation.isEmpty()) {
      let message = '';
      if (props.hasConversations === false) {
        message = props.trans.chat.youHaveNoConversations;
      }
      else
      {
        message = props.trans.chat.pleaseSelectAConversation;
      }
      return (
        <div className="directChat-messages-messageWindow">
          <div className="directChat-messages-informationWrapper">
            <span className="directChat-messages-information">
              {message}
            </span>
          </div>
        </div>
      );
    }
    
    return (
      <div className="directChat-messages-messageWindow">
        <div className="directChat-messages-header">
          <div >
          <TimeAgo
            epoch={props.conversation.getIn(['lastMessage', 'timestamp'])}
            now={props.now}
            method='dateDifferenceToDay'
            trans={props.trans}
          />
          </div>
          <div style={{visibility: 'hidden'}} className={classnames('directChat-messages-options', {
            active: props.meta.get('optionsOpened') === true
          })}>
            <span
              className="directChat-messages-optionsButton icon icon-dots"
              onClick={() => {
                props.actions.toggleConversationOptions(!props.meta.get('optionsOpened'));
              }}
            />
            <ul className="directChat-messages-optionsList">
              <li
                className="directChat-messages-optionsItem"
                onClick={() => {
                  if (props.conversation.get('muted')) {
                    props.actions.unmuteConversation(props.conversation.get('id'));
                  } else {
                    props.actions.muteConversationConfirmation(props.conversation.get('id'));
                  }
                  props.actions.toggleConversationOptions(false);
                }}
              >
                <span className={classnames('directChat-messages-optionsIcon icon', {
                  'icon-muted': props.conversation.get('muted') !== true,
                  'icon-mute': props.conversation.get('muted') === true
                })} />
                {`${props.conversation.get('muted') ? 'Unmute' : 'Mute'} Conversation`}
              </li>
              <li
                className="directChat-messages-optionsItem"
                onClick={() => {
                  props.actions.markConversationAsRead(props.conversation.get('id'));
                  props.actions.toggleConversationOptions(false);
                }}
              >
                <span className="directChat-messages-optionsIcon icon icon-markRead" />
                {this.props.trans.markAsRead}
              </li>
              <li
                className="directChat-messages-optionsItem"
                onClick={() => {
                  props.actions.deleteConversationConfirmation(props.conversation.get('id'));
                  props.actions.toggleConversationOptions(false);
                }}
              >
                <span className="directChat-messages-optionsIcon icon icon-delete" />
                {this.props.trans.deleteConversation}
              </li>
              <li
                className="directChat-messages-optionsItem"
                onClick={() => {
                  props.actions.reportConversationConfirmation(props.conversation.get('id'));
                  props.actions.toggleConversationOptions(false);
                }}
              >
                <span className="directChat-messages-optionsIcon icon icon-report" />
                {this.props.trans.reportConversation}
              </li>
            </ul>
          </div>
        </div>
        <div className="directChat-messages-content">
          <Scrollbars
            universal={true}
            onScrollStop={this.handleScrollStop}
            ref={scrollbars => {
              this.scrollbars = scrollbars;
            }}
          >
            {moreMessagesLoader}
            <div style={{paddingRight: '10px'}}>
            {
              props.conversation.get('thread', Immutable.List()).map((message, index) => {
                if (_.isString(message) || !_.isObject(message)) {
                  return null;
                }
                let direction = message.get('heroId') !== message.get('clientId') ? 1 : -1;
                let previousMessage = props.conversation.getIn(['thread', index - 1]);
                let previousDirection = previousMessage.get('heroId') !== previousMessage.get('clientId') ? 1 : -1;
                let messageId = 'message-' + message.get('id');
                return(
                <div id={messageId} key={index} className={classnames({'mineMessageGroup': direction === 1}, {'otherMessageGroup': direction !== 1})}>
                  <Message
                    direction={direction}
                    directionShift={index !== 0 &&
                      message.get('direction') !== props.conversation.getIn(['thread', index - 1, 'direction'])}
                    image={message.get('imageUrl')}
                    message={message.get('text')}
                    poster={props.conversation.get('hero')}
                    showAvatar={direction === -1 && (index === 0 ||
                      direction !== previousDirection)}
                  />
                </div>
              )
            })
            }
            </div>
          </Scrollbars>
        </div>
        <div className="directChat-messages-newMessage">
          <textarea
            className={classnames('directChat-messages-newMessageInput', {
              withImage: !!props.meta.get('messageImage')
            })}
            placeholder={_.get(this.props.trans, 'enterMessage', '')}
            type="text"
            ref={messageInput => {
              this.messageInput = messageInput;
            }}
            onKeyUp={e => {
              if (e.keyCode === CONST.keyCodes.ESC) {
                e.target.value = '';
                props.actions.removeImage();
              }
              if (e.keyCode === CONST.keyCodes.ENTER && !e.shiftKey && e.target.value.trim()) 
              {
                props.actions.addMessage(props.conversation.get('id'), e.target.value, this.props.heroProfile.id);
                props.sendChatMessage(e.target.value.trim(), props.conversation.getIn(['hero', 'id']), +new Date());
                props.fetchDirectChatConversations();
                e.target.value = '';
              }
            }}
          />
          <button style={{visibility: 'hidden'}}
            className="directChat-messages-newMessageImageUpload icon icon-photo"
            onClick={e => {
              props.actions.addImage(greenOffice);
              setTimeout(() => {
                this.messageInput.focus();
              }, 0);
            }}
          />
          {
            props.meta.get('messageImage') !== null
              ? (
                <div className="directChat-messages-imageWrapper">
                  <img
                    alt="comment upload"
                    className="directChat-messages-image"
                    src={props.meta.get('messageImage')}
                  />
                  <span
                    className="directChat-messages-removeImage icon icon-close"
                    onClick={() => {
                      props.actions.removeImage();
                      this.messageInput.focus();
                    }}
                  />
                </div>
              )
              :undefined
          }
        </div>
      </div>
    );
  }
}

MessageWindow.propTypes = {
  /**
   * Contains all available dispatched actions for this component
   * @type {Object}
   */
  actions: PropTypes.object.isRequired,
  /**
   * Contains latest conversation data for specified contact
   * @type {Immutable.Map<Conversation>}
   */
  conversation: PropTypes.object.isRequired,
  /**
   * Contains information about the component interactions and state
   * @type {Immutable.Map<Meta>}
   */
  meta: PropTypes.object.isRequired,
  /**
   * Representative date object of NOW
   * @type {Object}
   */
  now: PropTypes.object.isRequired
};

export default MessageWindow;
