import Immutable from 'immutable';
import { SET_IS_LOADING_PARKING_BOOKINGS, SET_PARKING_BOOKINGS, SET_GO_TO_PARKING_FLOOR, SET_PARKING_BOOKING_DATES } from '../../actions/whiteLabel/my-parkings';

const initialState = Immutable.Map({
  parkingBookings: {},
  isLoading: true,
  goToParkingFloor: false,
  bookingDates: {},
});

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_IS_LOADING_PARKING_BOOKINGS:
      return state.setIn(['isLoading'], action.isLoading);
    case SET_PARKING_BOOKINGS:
      return state.setIn(['parkingBookings'], action.parkingBookings);
    case SET_GO_TO_PARKING_FLOOR:
      return state.setIn(['goToParkingFloor'], action.goToParkingFloor);
    case SET_PARKING_BOOKING_DATES:
      return state.setIn(['bookingDates'], action.bookingDates);
    default:
      return state;
  }
};
