import { connect } from 'react-redux';
import MeetingDetails from './MeetingDetails';
import './index.scss';
import { acceptMeetingInvitation, declineMeetingInvitation } from '../../../actions/meetingActions';

const mapStateToProps = state => ({
  translations: state.translations,
  heroProfile: state.global.get('heroProfile'),
});

const mapDispatchProps = dispatch => ({
  acceptMeetingInvitation: (attendeeId, status, bookingId, officeId) =>
    dispatch(acceptMeetingInvitation(attendeeId, status, bookingId, officeId)),
  declineMeetingInvitation: (attendeeId, status, bookingId, officeId) =>
    dispatch(declineMeetingInvitation(attendeeId, status, bookingId, officeId)),
});

export default connect(
  mapStateToProps,
  mapDispatchProps
)(MeetingDetails);
