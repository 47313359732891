/**
 * Renders a single office card
 *
 * Usage:
 * <OfficeCard
 *   item={item}
 *   translations={translations}
 * />
 *
 * @class OfficeCard
 * @module officeCard
 */
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _ from 'lodash';
import { Link, withRouter } from 'react-router-dom';

import sizedImage from '../../utils/imageHelper';
import healthSafetyMeasuresIcon from '../../assets/svg/health-measures/health-safety-measures.svg';
import temperatureControlIcon from '../../assets/svg/health-measures/temperature-control-in-place.svg';
import premiumPartnerIcon from '../../assets/svg/officeDetails/premium-partner-full.svg';

import './css/index.scss';
import buildLocationUrl from '../../utils/buildLocationUrl';
import imagesCard from '../../assets/svg/dashboard/images-card.png';
import BookingRecommendationModal from '../dashboard/common/modals/booking-recommendation-modal';

class OfficeCard extends React.Component {
  constructor() {
    super();
    this.state = {
      showModal: false,
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  getImageContainer() {
    const { item, translations } = this.props;
    const imageUrl = _.get(item, 'imageUrl', null);
    const isPremiumPartner = _.get(item, 'premiumSupplier', false);
    const healthMeasuresStamp = _.get(item, 'healthMeasuresStamp', false);
    const temperatureControlStamp = _.get(item, 'temperatureControlStamp', false);
    const imagesCount = _.get(item, 'imagesCount', null);
    const moreImagesIcon =
      imagesCount >= 1 ? (
        <div
          className="office-card__images-container"
          onClick={() => {
            this.openModal();
          }}
        >
          <img className="office-card__images-icon" src={imagesCard} alt="Card Images" />
          <span className="office-card__images-count">{imagesCount}</span>
        </div>
      ) : null;

    return (
      <div
        className="office-card__image-container"
        style={{ backgroundImage: imageUrl !== null ? `url("${sizedImage(imageUrl, 297, 216)}")` : '#0e161e' }}
      >
        {isPremiumPartner ? (
          <div className="office-card__premium">
            <img
              src={premiumPartnerIcon}
              alt={_.get(translations, 'premiumPartner.image_alt', 'Premium partner')}
              className="premium-partner__icon--full"
            />
            <div className="health__stamps-tooltip health__stamps-tooltip--premium">
              {_.get(translations, 'premiumPartner.tooltip', 'Premium Partner')}
            </div>
          </div>
        ) : null}
        {moreImagesIcon}
        {healthMeasuresStamp || temperatureControlStamp ? (
          <div className="office-card__stamps">
            {healthMeasuresStamp ? (
              <div className="health__stamps-container--health">
                <img
                  src={healthSafetyMeasuresIcon}
                  alt={_.get(translations, 'healthStamps.health_safety_measures_image_alt', 'Health Measures')}
                  className="health__stamp-icon"
                />
                <div className="health__stamps-tooltip health__stamps-tooltip--health">
                  {_.get(translations, 'healthStamps.health_safety_measures', 'Health Safety Measures')}
                </div>
              </div>
            ) : null}
            {temperatureControlStamp ? (
              <div className="health__stamps-container--temperature">
                <img
                  src={temperatureControlIcon}
                  alt={_.get(translations, 'healthStamps.temperature_control_points_image_alt', 'Temperature control')}
                  className="health__stamp-icon"
                />
                <div className="health__stamps-tooltip health__stamps-tooltip--temperature">
                  {_.get(translations, 'healthStamps.temperature_control_points', 'Temperature control points')}
                </div>
              </div>
            ) : null}{' '}
          </div>
        ) : null}
      </div>
    );
  }

  getOfficeInfo() {
    const { item } = this.props;
    const title = _.get(item, 'name', null);
    const address = _.get(item, 'address', 'Address');

    return (
      <div className="office-card__info-container">
        <div className="office-card__title">{title}</div>
        <div className="office-card__address" title={address}>
          {address}
        </div>
      </div>
    );
  }

  getMoreDetailsButton() {
    const { item, translations } = this.props;
    const buttonLabel = _.get(translations, 'officeCard.moreDetails', 'More details');
    const locationId = _.get(item, 'id', null);
    return (
      <Link to={buildLocationUrl(item)} className="office-card__button" id={`location-${locationId}`}>
        {buttonLabel}
      </Link>
    );
  }

  getProducts() {
    const { item, translations } = this.props;
    const desksCount = _.get(item, 'productsCount.desks', 0);
    const privateOfficesCount = _.get(item, 'productsCount.privateOffices', 0);
    const meetingRoomsCount = _.get(item, 'productsCount.meetingRooms', 0);
    const eventRoomsCount = _.get(item, 'productsCount.eventRooms', 0);
    const title = _.get(translations, 'officeCard.officeSpaces', 'Office spaces');

    return (
      <div className="office-card__products-wrapper">
        <div className="office-card__products-title">{title}</div>

        <div className="office-card__products-container">
          {desksCount ? (
            <div className="office-card__product">
              <span className="office-card__product-icon desk-icon" />
              <span className="office-card__product-count">{desksCount}</span>
            </div>
          ) : null}
          {meetingRoomsCount ? (
            <div className="office-card__product">
              <span className="office-card__product-icon meetingRoom-icon" />
              <span className="office-card__product-count">{meetingRoomsCount}</span>
            </div>
          ) : null}
          {privateOfficesCount ? (
            <div className="office-card__product">
              <span className="office-card__product-icon privateOffice-icon" />
              <span className="office-card__product-count">{privateOfficesCount}</span>
            </div>
          ) : null}
          {eventRoomsCount ? (
            <div className="office-card__product">
              <span className="office-card__product-icon eventRoom-icon" />
              <span className="office-card__product-count">{eventRoomsCount}</span>
            </div>
          ) : null}
        </div>
      </div>
    );
  }

  getTags() {
    const { item, translations } = this.props;
    const tags = _.get(item, 'tags', []);
    const title = _.get(translations, 'officeCard.technologyTags', 'Techonology tags');
    return (
      <div className="office-card__tags-wrapper">
        <div className="office-card__tags-title">{title}</div>
        <div className="office-card__tags">
          {tags.map(tag => (
            <div key={`office-card__tag-${_.get(tag, 'name', null)}`} className="office-card__tag">
              {_.get(tag, 'name', null)}
            </div>
          ))}
        </div>
      </div>
    );
  }

  openModal() {
    this.setState({
      showModal: true,
    });
  }

  closeModal() {
    this.setState({
      showModal: false,
    });
  }

  render() {
    const { showModal } = this.state;
    const { item, history, translations } = this.props;
    const isPremiumPartner = _.get(item, 'premiumSupplier', false);
    const images = _.get(item, 'images', null);
    const imagesWithOriginal = images ? images.map(({ url: original, ...rest }) => ({ original, ...rest })) : null;
    const officeName = _.get(item, 'name', '');
    const officeDescription = _.get(item, 'description', '');
    const country = _.get(item, 'country', false);
    const notWhitelabel = !country;
    if (showModal) {
      return (
        <BookingRecommendationModal
          closeModal={this.closeModal}
          images={imagesWithOriginal}
          officeTitle={officeName}
          officeDescription={officeDescription}
          handleOnClick={() => {
            history.push(buildLocationUrl(item));
          }}
          translations={_.get(translations, 'dashboard', null)}
        />
      );
    }

    return (
      <div key={`office-card-${_.get(item, 'id', '')}`} className="office-card__container">
        {this.getImageContainer()}
        <div className={classnames('office-card__info-wrapper', { 'office-card__info-wrapper--premium': isPremiumPartner })}>
          {this.getOfficeInfo()}
          {this.getMoreDetailsButton()}
          {this.getProducts()}
          {notWhitelabel && this.getTags()}
        </div>
      </div>
    );
  }
}

OfficeCard.propTypes = {
  item: PropTypes.shape({}).isRequired,
  translations: PropTypes.shape({}),
};

OfficeCard.defaultProps = {
  translations: {},
};

export default withRouter(OfficeCard);
