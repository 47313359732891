import Immutable from 'immutable';
import { SET_COMMUNITY_COMMENTS_IS_LOADING } from '../../actions/community/comments';

const initialState = Immutable.Map({
  comments: [],
  isLoading: false,
});

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_COMMUNITY_COMMENTS_IS_LOADING:
      return state.setIn(['isLoading'], action.isLoading);
    default:
      return state;
  }
};
