import { connect } from 'react-redux';
import OfficeDetails from './officeDetails';

import { fetchOfficeTypes } from '../../../../actions/generalDataActions';
import { fetchMapProducts } from '../../../../actions/whiteLabel/products';
import {
  setFloorPlans,
  setSelectedFloorIndex,
  fetchBuildingHeroes,
  setFeatures,
  setSelectedFeatures,
} from '../../../../actions/whiteLabel/filter';
import {
  setSelectedDate,
  setSelectedProductId,
  setSelectedProductType,
  setNoParkingsAvailable,
} from '../../../../actions/whiteLabel/booking';
import { fetchHeroesBookings } from '../../../../actions/whiteLabel/heroes-booking-list';
import { floorPlansSelector, selectedFeaturesSelector, selectedFloorIndexSelector } from '../../../../selectors/whiteLabel/filter';
import { mapProductsSelector } from '../../../../selectors/whiteLabel/products';
import { selectedDateSelector, selectedProductTypeSelector } from '../../../../selectors/whiteLabel/booking';
import { goToParkingFloorSelector, parkingBookingDatesSelector } from '../../../../selectors/whiteLabel/parkingBookings';
import { setGoToParkingFloor } from '../../../../actions/whiteLabel/my-parkings';

const mapStateToProps = state => {
  return {
    details: state.location.get('details').toJS(),
    officeId: state.location.get('details').get('id'),
    officeTypesData: state.location.get('officeTypesData'),
    selectedOptions: state.addBooking.get('selectedOptions'),
    selectedProduct: state.addBooking.get('selectedProduct'),
    translations: state.translations,
    floorPlans: floorPlansSelector(state),
    selectedFloorIndex: selectedFloorIndexSelector(state),
    selectedDate: selectedDateSelector(state),
    mapProducts: mapProductsSelector(state),
    selectedProductType: selectedProductTypeSelector(state),
    selectedFeatures: selectedFeaturesSelector(state),
    goToParkingFloor: goToParkingFloorSelector(state),
    parkingBookingDates: parkingBookingDatesSelector(state),
  };
};

const mapDispatchProps = dispatch => {
  return {
    setSelectedProduct: product => dispatch(setSelectedProductId(product)),
    setSelectedProductId: productId => dispatch(setSelectedProductId(productId)),
    fetchOfficeTypes: () => dispatch(fetchOfficeTypes()),
    fetchMapProducts: (officeId, floorNumber, productTypeId, startDate, endDate, features) =>
      dispatch(fetchMapProducts(officeId, floorNumber, productTypeId, startDate, endDate, features)),
    setFloorPlans: floorPlans => dispatch(setFloorPlans(floorPlans)),
    setSelectedDate: (from, to) => dispatch(setSelectedDate(from, to)),
    setSelectedFloorIndex: selectedFloorIndex => dispatch(setSelectedFloorIndex(selectedFloorIndex)),
    fetchHeroesBookings: (officeId, startDate, endDate, type, departmentId) =>
      dispatch(fetchHeroesBookings(officeId, startDate, endDate, type, departmentId)),
    setSelectedProductType: productType => dispatch(setSelectedProductType(productType)),
    setFeatures: features => dispatch(setFeatures(features)),
    setSelectedFeatures: feature => dispatch(setSelectedFeatures(feature)),
    fetchBuildingHeroes: id => {
      dispatch(fetchBuildingHeroes(id));
    },
    setNoParkingsAvailable: flag => dispatch(setNoParkingsAvailable(flag)),
    setGoToParkingFloor: goTo => dispatch(setGoToParkingFloor(goTo)),
  };
};

export default connect(mapStateToProps, mapDispatchProps)(OfficeDetails);
