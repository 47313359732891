import React from 'react';
import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars';
import _ from 'lodash';

class CheckAvailabilityTable extends React.Component {
  buildTableHeader(products) {
    const { trans } = this.props;
    const roomTrans = _.get(trans, 'location.checkAvailability.table.room', 'Room');
    const capacityTrans = _.get(trans, 'location.checkAvailability.table.capacity', 'Capacity');
    const rooms = [];
    const capacity = [];
    for (let i = 0; i < products.length; i++) {
      rooms[i] = <th key={`room-${products[i].id}`}>{products[i].name}</th>;
      capacity[i] = <th key={`capacity-${products[i].id}`}>{products[i].capacity}</th>;
    }
    return (
      <thead className="check-availability-table__header">
        <tr>
          <th>{roomTrans}</th>
          {rooms}
        </tr>
        <tr>
          <th>{capacityTrans}</th>
          {capacity}
        </tr>
      </thead>
    );
  }

  buildTableBody(availability) {
    const { trans } = this.props;
    const availableTrans = _.get(trans, 'location.checkAvailability.table.available', 'Available');
    const closedTrans = _.get(trans, 'location.checkAvailability.table.closed', '(closed)');

    const rows = [];
    for (let i = 0; i < availability.length; i++) {
      const products = Object.keys(availability[i].products).map(x => availability[i].products[x]);
      const columns = [];
      for (let j = 0; j < products.length; j++) {
        const status = products[j].available ? availableTrans : closedTrans;
        columns[j] = (
          <td key={`product-${i}-${products[j].id}`} className={products[j].available ? 'check-availability-table__data--availabile' : ''}>
            {status}
          </td>
        );
      }
      rows[i] = (
        <tr key={`hour-${i}-${availability[i].hour}`}>
          <td>{availability[i].hour}</td>
          {columns}
        </tr>
      );
    }

    return <tbody className="check-availability-table__body">{rows}</tbody>;
  }

  render() {
    const { data, trans } = this.props;
    const noDataTrans = _.get(trans, 'location.checkAvailability.table.noData', 'No data def');

    if (!data || !data.products || !data.products.length || !data.availability || !data.availability.length) {
      return <div className="check-availability-table__no-data">{noDataTrans}</div>;
    }

    return (
      <div className="table-responsive check-availability-table">
        <Scrollbars>
          <table className="table table-striped table-borderless table-sm check-availability-table__table">
            {this.buildTableHeader(data.products)}
            {this.buildTableBody(data.availability)}
          </table>
        </Scrollbars>
      </div>
    );
  }
}

CheckAvailabilityTable.propTypes = {
  data: PropTypes.object.isRequired,
};

export default CheckAvailabilityTable;
