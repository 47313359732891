import _ from 'lodash';
import api from '../../components/dashboard/utils/api';
import { verticalSelector } from '../../selectors/vertical-selector';

/*
 * Constants
 */
export const SET_CO_CREATION_PROJECTS = 'SET_CO_CREATION_PROJECTS';
export const SET_ACTIVE_ID = 'SET_ACTIVE_ID';
export const SET_CO_CREATION_PROJECTS_IS_LOADING = 'SET_CO_CREATION_PROJECTS_IS_LOADING';

/*
 * Sync Actions
 */
export const setCoCreationProjects = coCreationProjects => ({
  type: SET_CO_CREATION_PROJECTS,
  coCreationProjects,
});
export const setActiveId = activeId => ({
  type: SET_ACTIVE_ID,
  activeId,
});
export const setCoCreationProjectsIsLoading = isLoading => ({
  type: SET_CO_CREATION_PROJECTS_IS_LOADING,
  isLoading,
});

/*
 * Async Actions
 */
const fetchProjects = projects => {
  return projects.map(project => {
    const params = JSON.parse(project.params);
    const data = {};
    for (let i = 0; i < params.length; i += 1) {
      if (params[i].question && params[i].question.code) {
        data[params[i].question.code] = params[i].answer;
      }
    }
    return {
      description: _.get(data, 'title', null),
      thumb: _.get(project, 'image', null),
      duration: _.get(data, 'estimation', null),
      remuneration: _.get(data, 'remuneration', null),
      id: _.get(project, 'id', null),
    };
  });
};

export const fetchCoCreationProjects = () => (dispatch, getState) => {
  const selectedVertical = verticalSelector(getState());
  const url = selectedVertical ? `/sidebar/projects?vertical=${selectedVertical}` : '/sidebar/projects';
  dispatch(setCoCreationProjectsIsLoading(true));
  api
    .get(url)
    .then(({ data = {} }) => {
      dispatch(setActiveId(0));
      dispatch(setCoCreationProjects(fetchProjects(data.projects)));
    })
    .catch(() => {})
    .finally(() => {
      dispatch(setCoCreationProjectsIsLoading(false));
    });
};
