import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import moment from 'moment';
import ModalDialog from './../../modalDialog/modalDialog';
import MobileApp from './../../modalDialog/modals/mobileApp';
import ShowConnections from './../../modalDialog/modals/showConnections';
import getLang from './../../../utils/lang';
import _ from 'lodash';
import { fromJS } from 'immutable';

let lang = getLang() !== null ? getLang() : 'en';

class EventBox extends React.Component {

  constructor() {
    super();
    this.state = {
      mobileAppModalOpened: false,
      heroListModalOpen: 0,
    };

    this.openHeroListModal = this.openHeroListModal.bind(this);
    this.closeHeroListModal = this.closeHeroListModal.bind(this);

  }

  openHeroListModal(id) {
    this.setState({ heroListModalOpen: id });
  }

  closeHeroListModal() {
    this.setState({ heroListModalOpen: 0 });
  }
  /**
   * Decides whether or not si required and renders connections info
   *
   * @method getConnections
   * @param {Immutable.Map} item
   * @returns {ReactElement}
   */
  getConnections (item) {
    const { heroListModalOpen } = this.state;
    const currentItem = item.toJS();
    const id = _.get(currentItem, 'id', 0);
    const attendees = _.get(currentItem, 'attendees', []);
    const connections = fromJS(attendees.map((attendee) => attendee.user));
    const attendeesText = attendees.length === 1 ? this.props.trans.events.oneHeroJoining : attendees.length + ' ' + this.props.trans.events.heroesJoining;
    const attendeesBlock = attendees.length === 0 ? null : (<span className="event__attendees" onClick={this.openHeroListModal.bind(this, id)}>{attendeesText}</span>);

    return (
      <div>
        {attendeesBlock}
        <ModalDialog
          modalOpened={heroListModalOpen === id}
          onModalClose={this.closeHeroListModal}
          className="--show-connections-booking-modal"
          label="cancelBooking">
            <ShowConnections
              type='hero'
              onClose={this.closeHeroListModal}
              trans={this.props.trans}
              connections={connections}
            />
        </ModalDialog>
      </div>
    );
    // const connections = item.get('connections');
    // const attendees = item.get('noAttendees', 0);
    // if (!connections || !connections.size) {
    //   return attendees === 1 ? this.props.trans.events.onePersonJoining : attendees + ' ' + this.props.trans.events.peopleJoining;
    // }

    // const firstName = (typeof connections.getIn([0, 'user', 'first_name']) !== 'undefined') ? connections.getIn([0, 'user', 'first_name']) : '';
    // const lastName = (typeof connections.getIn([0,  'user', 'last_name']) !== 'undefined') ? connections.getIn([0, 'user', 'last_name']) : '';
    // const firstConnection = firstName + ' ' + lastName;
    // const secondConnection = (connections.size > 1 && connections.getIn([1, 'user', 'first_name']) !== undefined && connections.getIn([1, 'user', 'last_name']))  ? connections.getIn([1, 'user', 'first_name']) + ' ' + connections.getIn([1, 'user', 'last_name']) : '';

    // const connectionsTotal = secondConnection !== '' ? connections.size - 2 : connections.size - 1;
    
    // return (
    //   <ConnectionsData
    //     first={ firstConnection }
    //     second={ secondConnection }
    //     total={ connectionsTotal }
    //     trans={this.props.trans}
    //     />
    // );
  }

  buildEventTimeLabel() {
    const { item, trans } = this.props;
    const startDate = moment(item.toJS().date.start).utc();
    const now = moment.utc();
    return startDate.isAfter(now) ? trans.officeBox.startsIn : trans.officeBox.endsIn;
  }

  computeStartsIn (item) {

    const now = moment.utc();
    const startDate = moment(item.toJS().date.start).utc();
    const useDate = startDate.isAfter(now) ? startDate : moment(item.toJS().date.end).utc();

    let usedUnits = 0;
    let returnStr = '';
    const duration  = moment.duration(useDate.diff(now));
    const years     = Math.floor(duration.years());
    const months    = Math.floor(duration.months());
    const days      = Math.floor(duration.days());
    const minutes   = (Math.floor(duration.minutes()) < 10) ? '0' + Math.floor(duration.minutes()) : Math.floor(duration.minutes());
    const hours     = (Math.floor(duration.hours()) < 10) ? '0' + Math.floor(duration.hours()) : Math.floor(duration.hours());

    if(usedUnits < 2 && years > 0)
    {
      returnStr += `${years}${this.props.trans.officeBox.startsInTrans.y} `;
      usedUnits++;
    }
    if(usedUnits < 2 && months > 0)
    {
      returnStr += `${months}${this.props.trans.officeBox.startsInTrans.mm} `;
      usedUnits++;
    }
    if(usedUnits < 2 && days > 0)
    {
      returnStr += `${days}${this.props.trans.officeBox.startsInTrans.d} `;
      usedUnits++;
    }
    if(usedUnits < 2 && hours > 0)
    {
      returnStr += `${hours}${this.props.trans.officeBox.startsInTrans.h} `;
      usedUnits++;
    }
    if(usedUnits < 2 && minutes > 0)
    {
      returnStr += `${minutes}${this.props.trans.officeBox.startsInTrans.m}`;
      usedUnits++;
    }
    return returnStr;
  }

  openMobileAppModal = () => {
    this.setState({mobileAppModalOpened: true});
  }

  onModalClose = () => {
    this.setState({mobileAppModalOpened: false});
  }

  render () {
    let evStartDate = this.props.item.getIn(['date', 'start']);
    let eventDate =  moment(evStartDate, 'YYYY-MM-DD HH:mm').locale(lang).format('dddd, D MMMM');
   
    const boxActions = (this.props.content === 'events' || this.props.content === 'upcoming-events') ? '': <div className="bookedOffice-actions">
        <button onClick={this.openMobileAppModal} className="bookedOffice-directions secondary-button-leftSide">
          <span className="bookedOffice-directionsIcon"></span>
          {this.props.trans.officeBox.planDrive}
        </button>
        <div className="officeBox-infoSmall">
          {this.buildEventTimeLabel()}
          <span className="officeBox-infoHighlighted">{this.computeStartsIn(this.props.item)}</span>
        </div>
      </div>;

    const eventLink = (this.props.content === 'upcoming-events') ? <Link to={ `/event/${this.props.item.get('id')}` } className="officeBox-location" /> : '';

    const content = (this.props.content === 'active-events') ?
          <div>
            <div className="bookedOffice">
                <div className="bookedOffice-details">
                  <div className="officeBox-title items-style1">{ this.props.item.get('title') }</div>
                  { eventLink }
                </div>
                { boxActions }
            </div>
            <div className="officeBox-infoSmall">{ eventDate }&nbsp;&nbsp;•&nbsp;&nbsp;{ this.props.item.get('organizer') }<br/>
            <div style={{paddingTop: '5px'}}>
              { this.getConnections(this.props.item) }
            </div>
            </div>
          </div>
        : <div className="bookedOffice">
            <div className="bookedOffice-details">
              <div className="officeBox-title items-style1">{ this.props.item.get('title') }</div>
              <div className="officeBox-infoSmall">{ eventDate }{ this.props.item.get('venueName') ? '  •  ' + this.props.item.get('venueName') : '' }
                <div style={{paddingTop: '5px', fontSize: '14px'}}>
                { this.getConnections(this.props.item) }
               </div>
              </div>
              { eventLink }
            </div>
            { boxActions }
          </div>;
    return (
        <div>
        { content }
        <ModalDialog
          modalOpened={this.state.mobileAppModalOpened}
          className="--postHeader"
          onModalClose={this.onModalClose}
          label="removeConnection">
          <MobileApp
            onAction={this.removePost}
            onCancelAction={this.onModalClose}
            trans={this.props.trans}
          />
        </ModalDialog>
        </div>
    );
  }
}

EventBox.propTypes = {
  /**
   * Immutable map containing an office description
   * @param item
   * @type Immutable.Map
   */
  item: PropTypes.object.isRequired
};

export default EventBox;
