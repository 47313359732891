import React from 'react';
import _ from 'lodash';
import moment from 'moment/moment';
import { Link } from 'react-router-dom';

class activeBooking extends React.Component {
  constructor(props) {
    super(props);
    const [nextBooking] = this.props.bookings;
    this.state = {
      nextBooking,
    };
  }

  render() {
    const { translations } = this.props;
    return (
      <div className="booking-options">
        <div>
          <div className="contact-info-label">{_.get(translations, 'location.bookingStatus', '')}</div>
          <a href={`/booking/${this.state.nextBooking.id}`}>
            <span className="contact-info-value booking-success-status">{_.capitalize(this.state.nextBooking.status)}</span>
          </a>
        </div>
        <div>
          <div className="contact-info-label">{_.get(translations, 'location.totalPrice', '')}</div>
          <span className="contact-info-value">
            {`${this.state.nextBooking.totalPriceVatIncluded
              .toFixed(2)
              .toString()
              .replace('.', window.__env.decimalSeparator)}€`}
          </span>
        </div>
        <div>
          <div className="contact-info-label">{_.get(translations, 'booking.options.dateLabel', '')}</div>
          <span className="contact-info-value">
            {`${moment.unix(this.state.nextBooking.fromDate).format('MMM DD')} - ${moment
              .unix(this.state.nextBooking.toDate)
              .format('MMM DD, YYYY')}`}
          </span>
        </div>
        <div>
          <div className="contact-info-label">{_.get(translations, 'booking.options.desiredWorkingHours', '')}</div>
          <span className="contact-info-value">
            {`${this.state.nextBooking.hours.from.hour}:${this.state.nextBooking.hours.from.minute}`} -{' '}
            {`${this.state.nextBooking.hours.to.hour}:${this.state.nextBooking.hours.to.minute}`}
          </span>
        </div>
      </div>
    );
  }
}

export default activeBooking;
