import React from 'react';
import { injectStripe } from 'react-stripe-elements';
import _ from 'lodash';

class CheckoutFormBancontact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cardErrors: null,
    };
  }

  componentDidMount() {
    const { autoRedirect } = this.props;
    if (autoRedirect === false) {
      return;
    }
    this.handleSubmit();
  }

  handleSubmit() {
    const { paymentIntent } = this.props;
    this.props.stripe.confirmBancontactPayment(paymentIntent, {
      payment_method: {
        billing_details: {
          name: `${localStorage.getItem('first-name')} ${localStorage.getItem('last-name')}`,
        },
      },
      return_url: `${window.__env.apiUrl}/stripe-approve`,
    });
  }

  hasBancontactPaymentMethod() {
    const { availablePaymentMethods } = this.props;
    if (typeof availablePaymentMethods === 'undefined') {
      return false;
    }
    const hasBancontactPayment = availablePaymentMethods.find(type => {
      return type === 'bancontact';
    });

    return hasBancontactPayment;
  }

  render() {
    const { autoRedirect, trans } = this.props;
    const hasBancontactPayment = this.hasBancontactPaymentMethod();
    if (autoRedirect === false && !!hasBancontactPayment) {
      return (
        <>
          <div className="repay-form__card-container repay-form__card-container--bancontact">
            <div className="alternative-payment-section">{_.get(trans, 'or', 'or')}</div>
            <button
              type="button"
              onClick={() => {
                this.handleSubmit();
              }}
              className="add-card-button"
            >
              {_.get(trans, 'payWithMaestro', 'payWithMaestro')}
            </button>
          </div>
        </>
      );
    }
    return null;
  }
}

export default injectStripe(CheckoutFormBancontact);
