import React from 'react';
import PropTypes from 'prop-types';
import { buildInfoBlock } from '../../utils/create-article-blocks';
import addLoader from '../../utils/add-loader';

const InfoCard = addLoader(({ mediaArticle }) => (
  <>{mediaArticle && mediaArticle.blocks ? mediaArticle.blocks.map(buildInfoBlock) : null}</>
));

InfoCard.propTypes = {
  mediaArticle: PropTypes.shape({}).isRequired,
};

export default InfoCard;
