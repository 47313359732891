import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import classnames from 'classnames';
import { withRouter } from 'react-router-dom';
import { allowedImages } from '../../utils/config';
import Audience from '../audience';

const getHeroIds = heroes => heroes.map(hero => hero.id);

class CommunityForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      communityLogo: null,
      invalidUploadedLogo: null,
      name: null,
      description: null,
      fileContent: null,
      formErrors: {},
      audienceType: null,
      selectedInterests: [],
      noMembers: false,
      selectedHeroes: [],
    };
    this.fileUploadInputRef = React.createRef();

    this.triggerUpload = this.triggerUpload.bind(this);
    this.handleUpload = this.handleUpload.bind(this);
    this.removeUploadedFile = this.removeUploadedFile.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleAudienceSelection = this.handleAudienceSelection.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.handleSubmitButtonStatus = this.handleSubmitButtonStatus.bind(this);
  }

  componentDidMount() {
    this.props.clearErrors();
  }

  triggerUpload() {
    this.fileUploadInputRef.current.click();
  }

  handleUpload(e) {
    const uploadedImage = e.target.files[0];
    if (!allowedImages.includes(uploadedImage.type)) {
      const { formErrors } = this.state;
      const { translations } = this.props;
      formErrors.invalidUploadedLogo = _.get(translations, 'form.invalidFileType', 'form.invalidFileType');
      this.setState({ communityLogo: null });
      this.setState({ formErrors });
    } else {
      this.setState({ communityLogo: URL.createObjectURL(uploadedImage), fileContent: uploadedImage });
    }
  }

  removeUploadedFile() {
    this.setState({ communityLogo: null });
  }

  handleChange(event) {
    switch (event.target.name) {
      case 'file':
        this.setState({ communityLogo: event.target.value });
        break;
      case 'name':
        this.setState({ name: event.target.value });
        break;
      case 'description':
        this.setState({ description: event.target.value });
        break;
      default:
        break;
    }
  }

  handleAudienceSelection(audienceSelection) {
    this.setState(audienceSelection);
  }

  handleSubmitButtonStatus() {
    const { communityLogo, name, description, audienceType, noMembers, selectedInterests, selectedHeroes } = this.state;
    if (
      !communityLogo ||
      !audienceType ||
      !name ||
      !description ||
      (audienceType === 'specific_verticals' && selectedInterests.length === 0) ||
      (audienceType === 'closed' && noMembers === false && selectedHeroes.length === 0)
    ) {
      return false;
    }

    return true;
  }

  validateFields() {
    // clear form errors
    this.setState({ formErrors: {} });

    const errors = {};
    const { communityLogo, name, description, audienceType, noMembers, selectedInterests, selectedHeroes } = this.state;
    if (!communityLogo) {
      errors.communityLogo = ['Required'];
    }
    if (!description) {
      errors.description = ['Required'];
    }
    if (!name) {
      errors.name = ['Required'];
    }

    if (!audienceType) {
      errors.audience = ['Required'];
    }

    if (audienceType === 'specific_verticals' && selectedInterests.length === 0) {
      errors.audience = ['Please select at least one vertical'];
    }

    if (audienceType === 'closed' && noMembers === false && selectedHeroes.length === 0) {
      errors.audience = ['Please select at least one hero'];
    }

    if (Object.keys(errors).length) {
      this.setState({ formErrors: errors });
      return false;
    }

    return true;
  }

  submitForm() {
    if (this.validateFields() === false) {
      return false;
    }
    const { name, description, fileContent, audienceType, noMembers, selectedInterests, selectedHeroes } = this.state;
    const { saveCommunity, history, showCommunityPageToast, translations } = this.props;
    const heroIds = getHeroIds(selectedHeroes);
    const formData = {
      name,
      description,
      audienceType,
      image: fileContent,
      verticals: selectedInterests,
      heroes: heroIds,
      noMembers,
    };

    saveCommunity(formData).then(url => {
      showCommunityPageToast('toast.communityCreated');
      history.push({
        pathname: url,
      });
    });
  }

  renderUploadBlock() {
    const { translations } = this.props;
    const { formErrors } = this.state;
    const errorRequired = _.get(formErrors, 'communityLogo', null);
    const errorFileType = _.get(formErrors, 'invalidUploadedLogo', null);
    const error = errorFileType || errorRequired;
    return (
      <>
        <div className="community-logo">
          <span className="icon icon-community" />
        </div>
        <div
          className={classnames('community-logo-content', 'community-logo__field', {
            'form-error': error,
          })}
        >
          <span className="requirements">{_.get(translations, 'form.pictureRequirements', 'form.pictureRequirements')}</span>
          <div className="community-form__image-buttons-wrapper">
            <button type="button" className="community-form__upload-button" onClick={this.triggerUpload}>
              {_.get(translations, 'form.uploadButton', 'form.uploadButton')}
            </button>
          </div>
          {error && <div className="form-error-message">{error}</div>}
        </div>
      </>
    );
  }

  renderSelectedFile() {
    const { communityLogo } = this.state;
    const { translations } = this.props;
    return (
      <>
        <div className="community-logo" style={{ backgroundImage: `url("${communityLogo}")` }} />
        <div className="community-logo-content">
          <span className="requirements">{_.get(translations, 'form.pictureLabel', 'form.pictureLabel')}</span>
          <div className="community-form__image-buttons-wrapper">
            <button type="button" className="community-form__upload-button" onClick={this.triggerUpload}>
              {_.get(translations, 'form.reuploadButton', 'form.reuploadButton')}
            </button>
            <span className="image-buttons__dot">•</span>
            <button type="button" className="community-form__upload-button community-form__remove-button" onClick={this.removeUploadedFile}>
              {_.get(translations, 'form.removeButton', 'form.removeButton')}
            </button>
          </div>
        </div>
      </>
    );
  }

  render() {
    const { communityLogo, formErrors, audienceType, selectedInterests, selectedHeroes, noMembers } = this.state;
    const { translations, closeForm, errors, isLoading } = this.props;
    const combinedErrors = { ...formErrors, ...errors };
    const nameError = combinedErrors.name && combinedErrors.name[0];
    const descriptionError = combinedErrors.description;
    const audienceError = combinedErrors.audience;
    const fileContainer = communityLogo ? this.renderSelectedFile() : this.renderUploadBlock();
    const buttonStatus = this.handleSubmitButtonStatus();

    return (
      <div className="community-form">
        <input
          type="file"
          ref={this.fileUploadInputRef}
          onChange={this.handleUpload}
          onClick={event => {
            event.target.value = null;
          }}
          name="image"
          className="attachment__input"
        />
        <div className="community-form__image">{fileContainer}</div>
        <div className="community-form__field community-form__name">
          <div className="field-label">
            {_.get(translations, 'form.communityName', 'form.communityName')}{' '}
            <span>({_.get(translations, 'form.requiredField', 'form.requiredField')})</span>
          </div>
          <input
            type="text"
            name="name"
            className={classnames('community-form__name community-form__italic-placeholder', {
              'form-error': nameError,
            })}
            placeholder={_.get(translations, 'form.communityNamePlaceholder', 'form.communityNamePlaceholder')}
            onChange={this.handleChange}
          />
          {nameError && <div className="form-error-message">{nameError}</div>}
        </div>
        <div className="community-form__field community-form__description">
          <div className="field-label">
            {_.get(translations, 'form.communityDescription', 'form.communityDescription')}{' '}
            <span>({_.get(translations, 'form.requiredField', 'form.requiredField')})</span>
          </div>
          <textarea
            name="description"
            form="apply-form"
            className={classnames('community-form__motivation community-form__italic-placeholder', {
              'form-error': descriptionError,
            })}
            placeholder={_.get(translations, 'form.communityDescriptionPlaceholder', 'form.communityDescriptionPlaceholder')}
            onChange={this.handleChange}
          />
          {descriptionError && <div className="form-error-message">{descriptionError}</div>}
        </div>
        <div className="community-form__field community-form__audience">
          <div className="field-label">
            {_.get(translations, 'form.communityAudience', 'form.communityAudience')}{' '}
            <span>({_.get(translations, 'form.requiredField', 'form.requiredField')})</span>
          </div>
          <Audience
            audienceType={audienceType}
            selectedInterests={selectedInterests}
            selectedHeroes={selectedHeroes}
            noMembers={noMembers}
            handleSelection={this.handleAudienceSelection}
            translations={_.get(translations, 'form', null)}
          />
          {audienceError && <div className="form-error-message">{audienceError}</div>}
        </div>

        <div className="community-form__actions">
          <a id="cancel-community-button" href="#" onClick={closeForm} className="community__button-cancel">
            {_.get(translations, 'form.cancelButton', 'form.cancelButton')}
          </a>
          {!isLoading && (
            <button
              id="create-community-button"
              type="button"
              className={classnames('button-yellow community__button-submit', {
                'button-disabled': !buttonStatus,
              })}
              onClick={this.submitForm}
              disabled={!buttonStatus}
            >
              {_.get(translations, 'form.createCommunityButton', 'form.createCommunityButton')}
            </button>
          )}
          {isLoading && <span id="create-community-loader" className="community__loader" />}
        </div>
      </div>
    );
  }
}

CommunityForm.propTypes = {
  saveCommunity: PropTypes.func.isRequired,
  closeForm: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  errors: PropTypes.shape({}),
  translations: PropTypes.shape({}),
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

CommunityForm.defaultProps = {
  errors: {},
  translations: {},
};

export default withRouter(CommunityForm);
