import React from 'react';
import PropTypes from 'prop-types';
import './../css/cancelBooking.scss';
import _ from 'lodash';
import moment from 'moment';

class CancelBooking extends React.Component {

  onBlock = () => {
    this.props.onAction('block', this.props.userId);
  }

  onCancelBlock = () => {
    this.props.onCancelAction(false);
  }

  static buildHighlightText(value) {
    return <span className="cancel-booking__highlight">{value}</span>;
  }

  buildFeeWarning()
  {
    const { trans, booking, customMessage } = this.props;
    const penaltyValue = booking.get('penalty_value') ? booking.get('penalty_value', 0) : 0;
    const freeCancellationDateUnix = moment.unix(booking.get('freeCancelationDate', Date.now())).utc();
    const freeCancellationDate = moment.unix(booking.get('freeCancelationDate', Date.now())).utc().format("D MMMM YYYY, h:mm a");
    const currencyType = _.get(trans, 'modals.cancelBooking.currency', 'Euro');
    const noDate = !freeCancellationDateUnix.isAfter(moment.unix(booking.get('createdAt', Date.now())).utc());
    const warningTrans = noDate ? 'modals.cancelBooking.warningDatePast' : 'modals.cancelBooking.warning';
    const warningTextRaw = _.get(trans, warningTrans, 'Cancel your booking?');
    const currencyHighlightElement = `<span class="cancel-booking__highlight">${penaltyValue} ${currencyType}</span>`;
    const dateHighlightElement = `<span class="cancel-booking__highlight">${freeCancellationDate}</span>`;
    const warningText = trans.formatString(warningTextRaw, currencyHighlightElement, dateHighlightElement);

    return warningText;
  }

  buildNoFeeWarning()
  {
    const { trans, booking } = this.props;
    const freeCancellationDate = moment.unix(booking.get('freeCancelationDate', Date.now())).utc().format("D MMMM YYYY, h:mm a");
    const warningTextRaw = _.get(trans, 'modals.cancelBooking.warningNoFee', 'Cancel your booking?');
    const dateHighlightElement = `<span class="cancel-booking__highlight">${freeCancellationDate}</span>`;
    const warningText = trans.formatString(warningTextRaw, dateHighlightElement);

    return warningText;
  }

  render () {
    const { booking, customMessage } = this.props;
    const freeCancelationDate = moment.unix(booking.get('freeCancelationDate', Date.now())).utc();
    const noFee = moment().utc().isBefore(freeCancelationDate);
    const feeRelatedMessage = noFee ? this.buildNoFeeWarning() : this.buildFeeWarning();
    const warningText = customMessage ? customMessage : feeRelatedMessage;
    return (
      <div className="cancelBooking">
        <div onClick={ this.onCancelBlock } className="modal-close-button"></div>
        <div className="cancelBooking-title items-style1">{this.props.trans.modals.cancelBooking.title}</div>
        <div className="cancelBooking-warning" dangerouslySetInnerHTML={{__html: warningText}}></div>
        <div className="cancelBooking-actions">
          <button
          style={{marginLeft: '0'}}
            className="cancelBooking-blockButton secondary-button"
            onClick={ this.onBlock }>{this.props.trans.modals.cancelBooking.cancelBooking}</button>
          <button
            style={{marginLeft: '0'}}
            className="cancelBooking-cancelButton main-button"
            onClick={ this.onCancelBlock }>{this.props.trans.modals.cancelBooking.changedMyMindButtonLabel}</button>
        </div>
      </div>
    );
  }
}

CancelBooking.propTypes = {
  onAction: PropTypes.func
};

export default CancelBooking;
