import Immutable, { fromJS } from 'immutable/dist/immutable';
import { SET_TOAST_MESSAGE } from '../../actions/dashboard/toasts';

const initialState = Immutable.Map({
  toastMessage: null,
});

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_TOAST_MESSAGE:
      return state.setIn(['toastMessage'], fromJS(action.toastMessage));
    default:
      return state;
  }
};
