import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
import 'react-datepicker/dist/react-datepicker.css';
import { Scrollbars } from 'react-custom-scrollbars';
import ProfileInputField from '../../../heroProfile/profile-input-field';
import SkillPicker from './SkillPicker';
import LoadingGif from '../../../../assets/images/loader.gif';
import ComunicationEmail from '../../../heroProfile/comunication-email';

class ProfileForm extends Component {
  constructor(props) {
    super(props);
    this.goToRef = React.createRef();
    this.onInputChange = this.onInputChange.bind(this);
    this.onSpecificSkillClick = this.onSpecificSkillClick.bind(this);
    this.onTrainingSkillClick = this.onTrainingSkillClick.bind(this);
    this.saveHeroProfileData = this.saveHeroProfileData.bind(this);
  }

  componentDidMount() {
    const { fetchHeroProfile } = this.props;
    fetchHeroProfile();
  }

  onInputChange(event, path) {
    const { heroProfile, setHeroProfile } = this.props;
    const { value } = event.target;
    _.set(heroProfile, path, value);
    setHeroProfile(heroProfile);
  }

  onSpecificSkillClick(id) {
    const { heroProfile, setHeroProfile } = this.props;
    const allSpecificSkills = _.get(heroProfile, 'allSpecificSkills', []);
    const heroSpecificSkills = _.get(heroProfile, 'heroSpecificSkills', []);
    let clickedSkill = heroSpecificSkills.find(skill => skill.id === id);
    const noSkillName = 'No specific skills';
    if (clickedSkill) {
      const index = heroSpecificSkills.indexOf(clickedSkill);
      heroProfile.heroSpecificSkills.splice(index, 1);
    } else {
      clickedSkill = allSpecificSkills.find(skill => skill.id === id);
      if (_.get(clickedSkill, 'name', null) === noSkillName) {
        heroProfile.heroSpecificSkills = [clickedSkill];
      } else {
        const noSkill = heroSpecificSkills.find(skill => skill.name === noSkillName);
        const index = heroSpecificSkills.indexOf(noSkill);
        if (index >= 0) {
          heroProfile.heroSpecificSkills.splice(index, 1);
        }
        heroProfile.heroSpecificSkills.push(clickedSkill);
      }
    }
    setHeroProfile(heroProfile);
  }

  onTrainingSkillClick(id) {
    const { heroProfile, setHeroProfile } = this.props;
    const allTrainingSkills = _.get(heroProfile, 'allTrainingSkills', []);
    const heroTrainingSkills = _.get(heroProfile, 'heroTrainingSkills', []);
    let clickedSkill = heroTrainingSkills.find(skill => skill.id === id);
    const noSkillName = 'No specific trainings';
    if (clickedSkill) {
      const index = heroTrainingSkills.indexOf(clickedSkill);
      heroProfile.heroTrainingSkills.splice(index, 1);
    } else {
      clickedSkill = allTrainingSkills.find(skill => skill.id === id);
      if (_.get(clickedSkill, 'name', null) === noSkillName) {
        heroProfile.heroTrainingSkills = [clickedSkill];
      } else {
        const noSkill = heroTrainingSkills.find(skill => skill.name === noSkillName);
        const index = heroTrainingSkills.indexOf(noSkill);
        if (index >= 0) {
          heroProfile.heroTrainingSkills.splice(index, 1);
        }
        heroProfile.heroTrainingSkills.filter(skill => _.get(skill, 'name', null) !== _.get(noSkill, 'name', null));
        heroProfile.heroTrainingSkills.push(clickedSkill);
      }
    }
    setHeroProfile(heroProfile);
  }

  saveHeroProfileData() {
    const { heroProfile, saveHeroProfile, history } = this.props;
    const args = {};
    args.firstName = _.get(heroProfile, 'firstName', null);
    args.lastName = _.get(heroProfile, 'lastName', null);
    args.headline = _.get(heroProfile, 'headline', null);
    args.language = _.get(heroProfile, 'language', null);
    args.heroSpecificSkills = _.get(heroProfile, 'heroSpecificSkills');
    args.heroTrainingSkills = _.get(heroProfile, 'heroTrainingSkills');
    saveHeroProfile(args)
      .then(() => {
        const lang = localStorage.getItem('lang', 'en');
        localStorage.setItem('lang', _.get(heroProfile, 'language'));
        history.push('/booking');
        if (lang !== _.get(heroProfile, 'language')) {
          window.location.reload();
        }
      })
      .catch();
  }

  render() {
    const { heroProfile, errors, isLoading, translations } = this.props;

    const firstName = _.get(heroProfile, 'firstName', '');
    const lastName = _.get(heroProfile, 'lastName', '');
    const email = _.get(heroProfile, 'email', '');
    const profileCompleted = _.get(heroProfile, 'profileCompleted', false);
    const company = _.get(heroProfile, 'company', '');
    const position = _.get(heroProfile, 'headline', '');
    const allSpecificSkills = _.get(heroProfile, 'allSpecificSkills', null);
    const specificSkills = _.get(heroProfile, 'heroSpecificSkills', null);
    const allTrainingSkills = _.get(heroProfile, 'allTrainingSkills', null);
    const trainingSkills = _.get(heroProfile, 'heroTrainingSkills', null);
    const buttonLabel = profileCompleted ? _.get(translations, 'profile.save', '') : _.get(translations, 'profile.continue', '');

    return (
      <Scrollbars className="styleScroll">
        {isLoading ? (
          <img className="profile-loader__container whitelabel-profile" src={LoadingGif} alt="Loading" />
        ) : (
          <div className="hero-profile__container">
            <div className="hero-profile__welcome-message">{_.get(translations, 'profile.title', '')}</div>
            <div className="hero-profile__profile-message">{_.get(translations, 'profile.selectSkillsText', '')}</div>
            <form className="profile-form__form">
              <div className="profile-form__left" ref={this.goToRef}>
                <div className="profile-form__row">
                  <div className="profile-form__columns">
                    <ProfileInputField
                      label={_.get(translations, 'openAuth.firstName', '')}
                      name="firstName"
                      value={firstName}
                      changeable
                      onInputChange={this.onInputChange}
                      path="firstName"
                      error={!!errors.firstName}
                    />
                    <ProfileInputField
                      label={_.get(translations, 'openAuth.surname', '')}
                      name="lastName"
                      value={lastName}
                      changeable
                      onInputChange={this.onInputChange}
                      path="lastName"
                      error={!!errors.lastName}
                    />
                  </div>
                </div>
                <div className="profile-form__row">
                  <ProfileInputField
                    label={_.get(translations, 'profile.primaryEmailLabel', '')}
                    name="email"
                    value={email}
                    changeable={false}
                  />
                </div>
                <div className="profile-form__row profile-form__row-margin-top">
                  <div className="profile-form__columns">
                    <ProfileInputField
                      label={_.get(translations, 'profile.whereDoYouWork', '')}
                      name="company"
                      placeholder={_.get(translations, 'profile.placeholderCompany', '')}
                      value={company || null}
                      changeable={false}
                    />

                    <ProfileInputField
                      label={_.get(translations, 'profile.currentPosition', '')}
                      name="title"
                      placeholder={_.get(translations, 'profile.placeholderPosition', '')}
                      value={position || null}
                      changeable
                      onInputChange={this.onInputChange}
                      path="headline"
                      error={!!errors.headline}
                    />
                  </div>
                </div>
                <ComunicationEmail />
                <div className="profile-form__row">
                  <button
                    className="add-experience__button profile-form__button"
                    type="submit"
                    onClick={event => {
                      event.preventDefault();
                      event.stopPropagation();
                      this.saveHeroProfileData();
                    }}
                  >
                    {buttonLabel}
                  </button>
                </div>
              </div>
              <div className="profile-form__right">
                {allSpecificSkills ? (
                  <>
                    <SkillPicker
                      key="specificSkills"
                      question={_.get(translations, 'whiteLabel.skills', '')}
                      allSkills={allSpecificSkills}
                      checkedSkills={specificSkills}
                      onSkillClick={this.onSpecificSkillClick}
                      error={!!errors.heroSpecificSkills}
                    />
                  </>
                ) : null}
                {allTrainingSkills ? (
                  <SkillPicker
                    key="trainingSkills"
                    question={_.get(translations, 'whiteLabel.trainingSkills', '')}
                    allSkills={allTrainingSkills}
                    checkedSkills={trainingSkills}
                    onSkillClick={this.onTrainingSkillClick}
                    error={!!errors.heroTrainingSkills}
                  />
                ) : null}
              </div>
            </form>
          </div>
        )}
      </Scrollbars>
    );
  }
}

ProfileForm.propTypes = {
  heroProfile: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    businessEmail: PropTypes.string,
    communicationBusinessEmail: PropTypes.bool,
    communicationPersonalEmail: PropTypes.bool,
    positions: PropTypes.arrayOf(),
    allSpecificSkills: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
      })
    ),
    specificSkills: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
      })
    ),
    allTrainingSkills: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
      })
    ),
    trainingSkills: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
      })
    ),
  }),
  fetchHeroProfile: PropTypes.func.isRequired,
  setHeroProfile: PropTypes.func.isRequired,
  saveHeroProfile: PropTypes.func.isRequired,
};
ProfileForm.defaultProps = {
  heroProfile: {},
};

export default withRouter(ProfileForm);
