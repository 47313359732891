import React from 'react';
import classnames from 'classnames';
import moment from 'moment';
import _ from 'lodash';
import OfficesGrid from './officesGrid/officesGrid';

import './css/index.scss';

class MyBookings extends React.Component {
  render() {
    const componentClass = classnames([
      'myBookings',
      this.props.className,
      this.props.fullView && '--noTopPadding',
      this.props.content === 'active-events' ? 'myBookings-events' : '',
    ]);

    let howManyActive = null;
    let howManyActiveEvents = null;
    const { trans, items, myBookings } = this.props;
    if (items.size > 0 && myBookings) {
      howManyActive = <div className="active-booking-label">{`${items.size} ${_.get(trans, 'home.active')}`}</div>;
    }

    if (this.props.content === 'active-events') {
      let count = 0;
      items.map(item => {
        const evEndDate = moment(item.getIn(['date', 'end'])).utc();
        const now = moment.utc();
        if (evEndDate.isAfter(now)) {
          count++;
        }
        return null;
      });

      howManyActiveEvents = (
        <div className="active-booking-label" style={{ marginBottom: '20px', marginTop: '10px', fontWeight: 'bold' }}>
          {count} {count === 1 ? trans.events.sgEvent : trans.events.plEvents}
        </div>
      );
    }

    let sectionTitle = null;
    if (this.props.title !== undefined) {
      sectionTitle = this.props.title;
    } else {
      sectionTitle = trans.home.rightSidebar.activeBookingsLabel;
    }

    return (
      <section className={componentClass} style={{ paddingBottom: myBookings ? 0 : 'inherit', paddingTop: myBookings ? 0 : 'inherit' }}>
        <div className="booking-title">{sectionTitle}</div>
        {howManyActive}
        {howManyActiveEvents}

        <OfficesGrid
          type="secondary"
          comingUp={this.props.comingUp}
          myBookings={myBookings}
          className={this.props.fullView ? '--fullView' : ''}
          fullView={this.props.fullView}
          content={this.props.content}
          items={items}
          geoLocationCoords={this.props.geoLocationCoords}
          direction="vertical"
          trans={this.props.trans}
          isWhiteLabel={this.props.isWhiteLabel}
        />
      </section>
    );
  }
}

export default MyBookings;
