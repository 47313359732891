import React from 'react';
import '../css/showConnections.scss';
import '../css/showVoucherBalance.scss';
import _ from 'lodash';

const ShowVoucherBalance = props => {
  const { balanceValue, onAction, trans } = props;
  const titleTrans = _.get(trans, 'modals.showVoucherBalance.title', '');
  const descriptionTrans = _.get(trans, 'modals.showVoucherBalance.description', '');
  const buttonTrans = _.get(trans, 'modals.showVoucherBalance.closeButton', '');

  return (
    <div className="showConnections showVoucherBalance">
      <div onClick={onAction} className="modal-close-button" />
      <div className="showConnections-title items-style1">{titleTrans}</div>
      <div className="showVoucherBalance-message">
        <div className="showVoucherBalance-description">{descriptionTrans}</div>
        <div className="showVoucherBalance-price">
          {` ${balanceValue
            .toFixed(2)
            .toString()
            .replace('.', window.__env.decimalSeparator)} euros`}
        </div>
        <div className="showVoucherBalance-description">.</div>
      </div>

      <div className="showConnections-actions">
        <button type="button" style={{ marginLeft: '0' }} className="showConnections-cancelButton main-button" onClick={onAction}>
          {buttonTrans}
        </button>
      </div>
    </div>
  );
};

export default ShowVoucherBalance;
