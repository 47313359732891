import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './filtersContainer.scss';
import Immutable from 'immutable';
import _ from 'lodash';
import CustomFilterDropdown from './CustomFilterDropdown';
import { officeTypes, premiumRole, offeredServices, defaultTimezone } from './constants';
import DatePicker from '../datePicker/datePicker';
import goTo from '../../assets/svg/goTo.svg';

class FiltersContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasSelectedItems: false,
    };
    this.onChange = this.onChange.bind(this);
    this.resetFilters = this.resetFilters.bind(this);
  }

  resetFilters() {
    this.setState({ hasSelectedItems: false });
    this.props.onResetFilters();
  }

  onChange(value, groupName, checked = true) {
    this.setState({ hasSelectedItems: true });
    switch (groupName) {
      case 'officesTypes':
        this.props.onOfficeTypeChange(value, checked);
        break;
      case 'services':
        this.props.onServicesChange(value, checked);
        break;
      case 'healthMeasures':
        this.props.onHealthMeasuresChange(value, checked);
        break;
      case 'premiumSupplier':
        this.props.onPremiumSupplierChange(value, checked);
        break;
      case 'seats':
        this.props.onCapacityChange(value);
        break;
      default:
        break;
    }
  }

  render() {
    const { filtersData, officeTypesData, trans } = this.props;
    const applied = filtersData.get('applied');
    const { filters, paymentProfile } = trans;
    const { hasSelectedItems } = this.state;
    const { date } = applied.toJS();
    let startDate = null;
    let endDate = null;
    if (typeof date.start !== 'undefined') startDate = date.start;
    if (typeof date.end !== 'undefined') endDate = date.end;
    return (
      <div className="filters">
        <div className="map-filter__container-dropdown">
          <CustomFilterDropdown
            placeHolder={_.get(paymentProfile, 'select', '')}
            customText={filters.chooseOfficeType}
            className="dropdown__selector map-filter__dropdown "
            onChange={this.onChange}
            selectedItems={applied.get('officeTypes')}
            list={officeTypes(officeTypesData.toJS(), filters)}
          />
          <CustomFilterDropdown
            placeHolder={_.get(paymentProfile, 'select', '')}
            list={offeredServices(filters)}
            customText={filters.amenitiesAndHealthMeasures}
            className="dropdown__selector map-filter__dropdown"
            selectedItems={applied
              .get('amenities')
              .concat(applied.get('services'))
              .concat(applied.get('healthMeasures'))}
            onChange={this.onChange}
          />
          <DatePicker
            startDate={startDate}
            endDate={endDate}
            onChange={this.props.onDateChange}
            customText={filters.date}
            className="dropdown__selector map-filter__dropdown"
            isDatepickerOnFilterMap
            placeHolder={_.get(filters, 'selectDateRange', '')}
          />
          <CustomFilterDropdown
            placeHolder={_.get(paymentProfile, 'select', '')}
            list={premiumRole(filters)}
            customText={filters.partnerType}
            className="dropdown__selector map-filter__dropdown"
            onChange={this.onChange}
            selectedItems={
              applied.get('premiumSupplier') ? Immutable.fromJS({ value: applied.get('premiumSupplier') }) : Immutable.fromJS([])
            }
          />
          <div className="search-filter">
            <button
              className={classnames({ hidden: !hasSelectedItems && !Object.keys(date).length }, 'button-reset')}
              onClick={this.resetFilters}
            >
              {filters.resetFilters}
            </button>
            <button
              className={classnames({ disabled: !hasSelectedItems && !Object.keys(date).length }, 'button-apply')}
              onClick={this.props.onApplyFilters}
            >
              <img src={goTo} alt="Workero filter go to" />
            </button>
          </div>
        </div>
      </div>
    );
  }
}

FiltersContainer.propTypes = {
  filtersMode: PropTypes.object,
};

export default FiltersContainer;
