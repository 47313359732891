import { connect } from 'react-redux';
import Booking from './Booking';
import { beforeFetchBookingDetails, fetchBookingDetails, fetchBookingSummary } from '../../../../actions/bookingActions';
import { setGoToParkingFloor, setParkingBookingDates } from '../../../../actions/whiteLabel/my-parkings';

import { cancelBooking, checkIn, checkOut, extendBooking } from '../../../../actions/whiteLabel/booking';
import { beforeFetchOfficeDetails, fetchOfficeDetails, openBookingOptions } from '../../../../actions/locationActions';
import { canceledBookingDataSelector, cancelLoaderSelector } from '../../../../selectors/whiteLabel/booking';
import { mapProductsSelector } from '../../../../selectors/whiteLabel/products';

const mapStateToProps = state => {
  return {
    details: state.booking.get('details'),
    officeDetails: state.location.get('details'),
    cancelBookingData: canceledBookingDataSelector(state),
    cancelLoader: cancelLoaderSelector(state),
    translations: state.translations,
    isWhiteLabel: state.global.get('isWhiteLabel'),
    formatString: state.translations ? state.translations.formatString : () => {},
    officeTimezone: state.location.get('details').get('timezone'),
    officeProducts: mapProductsSelector(state),
    appTimezone: state.global.get('appTimezone'),
  };
};

const mapDispatchProps = dispatch => {
  return {
    beforeFetchBookingDetails: () => dispatch(beforeFetchBookingDetails()),
    fetchBookingDetails: id => dispatch(fetchBookingDetails(id)),
    beforeFetchOfficeDetails: id => dispatch(beforeFetchOfficeDetails(id)),
    fetchOfficeDetails: id => dispatch(fetchOfficeDetails(id)),
    cancelBooking: id => dispatch(cancelBooking(id)),
    fetchBookingSummary: () => dispatch(fetchBookingSummary()),
    checkin: id => dispatch(checkIn(id)),
    checkout: id => dispatch(checkOut(id)),
    extendBooking: (id, extendHour) => dispatch(extendBooking(id, extendHour)),
    openBookingOptions: () => dispatch(openBookingOptions()),
    setGoToParkingFloor: goTo => dispatch(setGoToParkingFloor(goTo)),
    setParkingBookingDates: (bookingDates) => dispatch(setParkingBookingDates(bookingDates)),
  };
};

export default connect(mapStateToProps, mapDispatchProps)(Booking);
