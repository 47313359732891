import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import StarRating from './../../starRating/starRating';
import ModalDialog from './../../modalDialog/modalDialog';
import MobileApp from './../../modalDialog/modals/mobileApp';
import buildLocationUrl from "../../../utils/buildLocationUrl";
import blockIncompleteProfileAction from "../../../utils/blockIncompleteProfileAction";
import _ from 'lodash';
class BookedOfficeInfo extends React.Component {

  constructor() {
    super();
    this.state = {
      mobileAppModalOpened: false
    };
  }

  computeDates (item) {
    const storageLang = localStorage.getItem('lang');
    const lang = (storageLang === 'default' || !storageLang) ? 'en' : storageLang;
    const firstBooking = item.toJS().bookings[0];
    if (!firstBooking || !_.get(firstBooking, 'fromDate') || !_.get(firstBooking, 'toDate')) {
      return '';
    }
    let startDate = new Date(firstBooking.fromDate * 1000).toLocaleString(lang, {day: 'numeric', month: 'long', year: 'numeric', timeZone: 'UTC'});
    let endDate = new Date(firstBooking.toDate * 1000).toLocaleString(lang, {day: 'numeric', month: 'long', year: 'numeric', timeZone: 'UTC'});

    return startDate === endDate ? `${startDate[0].toUpperCase()}${startDate.substr(1)}` :
    `${startDate[0].toUpperCase()}${startDate.substr(1)} - ${endDate[0].toUpperCase()}${endDate.substr(1)}`;
  }

  openMobileAppModal = () => {
    this.setState({mobileAppModalOpened: true});
  }

  computeStartsIn (item) {
    let timeZone = this.props.item.getIn(['bookings', '0', 'timeZone']);
    let startDate = moment.unix(item.toJS().bookings[0].fromDate).set({
      hour: item.toJS().bookings[0].hours.from.hour,
      minute:item.toJS().bookings[0].hours.from.minute,
    })

    const startingBookingString = startDate.format('YYYY-MM-DD HH:mm:ss')
    const now = moment.tz(timeZone)
    const startBooking = moment.tz(startingBookingString, 'YYYY-MM-DD HH:mm:ss', timeZone);


    if(startBooking.isAfter(now))
    {
      let usedUnits = 0;
      let returnStr = '';
      let duration  = moment.duration(startBooking.diff(now));

      let years     = Math.floor(duration.years());
      let months    = Math.floor(duration.months());
      let days      = Math.floor(duration.days());
      let minutes   = (Math.floor(duration.minutes()) < 10) ? '0' + Math.floor(duration.minutes()) : Math.floor(duration.minutes());
      let hours     = (Math.floor(duration.hours()) < 10) ? '0' + Math.floor(duration.hours()) : Math.floor(duration.hours());

      if(usedUnits < 2 && years > 0)
      {
        returnStr += `${years}${this.props.trans.officeBox.startsInTrans.y} `;
        usedUnits++;
      }
      if(usedUnits < 2 && months > 0)
      {
        returnStr += `${months}${this.props.trans.officeBox.startsInTrans.mm} `;
        usedUnits++;
      }
      if(usedUnits < 2 && days > 0)
      {
        returnStr += `${days}${this.props.trans.officeBox.startsInTrans.d} `;
        usedUnits++;
      }
      if(usedUnits < 2 && hours > 0)
      {
        returnStr += `${hours}${this.props.trans.officeBox.startsInTrans.h} `;
        usedUnits++;
      }
      if(usedUnits < 2 && minutes > 0)
      {
        returnStr += `${minutes}${this.props.trans.officeBox.startsInTrans.m}`;
        usedUnits++;
      }

      return returnStr;
    }
    return null;
  }

  _getRating() {
    let votes = this.props.item.getIn(['rating', 'votes']);
    if(votes === 0) return null;
    return (
      <div className="officeBox-infoSmall">
      { votes } <StarRating
        initialRate={ this.props.item.getIn(['rating', 'average']) }
        theme={'theme-yellow-small'}
        readonly={ true } />
      </div>
    );
  }

  onModalClose = () => {
    this.setState({mobileAppModalOpened: false});
  }
  handleProductType() {
    const {trans, myBookings} = this.props;
    if (myBookings &&
      this.props.item.getIn(['bookings', '0', 'product', 'officeTypeId']) === 3 &&
      !this.props.item.getIn(['bookings', '0', 'isAttendee'])
    ) {
      return (<div className="officeBox-invitationSend">
        <span> {`${this.props.item.getIn(['bookings', '0', 'countAttendees', 'acceptedInvitation'])} ${_.get(trans, 'home.accepted', '')}`}</span>
        <span style={{color:"#7c878e"}}>{` /${this.props.item.getIn(['bookings', '0', 'countAttendees', 'totalInvitationsSend'])}`}</span>
      </div>);
    }
    return (
      <div className="officeBox-infoSmall-productName">
        { this.props.item.getIn(['bookings', '0', 'product', 'name']) }
      </div>
    );
  }
  handleBookingUrl() {
    if (this.props.item.getIn(['bookings', '0', 'isAttendee'])){
      return (
        <Link className="officeBox-infoSmall-date"
          onClick={(e) => {
            blockIncompleteProfileAction()
              .then(() => {
                this.props.history.push({ pathname: '/account/profile/incomplete', fromUrl:`/meeting/${this.props.item.getIn(['bookings', '0', 'id'])}`})
              })
              .catch(() => {
                this.props.history.push({pathname:`/meeting/${this.props.item.getIn(['bookings', '0', 'id'])}`})
              })}
          }
        >
        { this.computeDates(this.props.item) }
        </Link>
      );
    }
    return (
      <Link className="officeBox-infoSmall-date" to={ `/booking/${this.props.item.getIn(['bookings', '0', 'id'])}` }>
      { this.computeDates(this.props.item) }
      </Link>
    );
  }

  render () {
    return (
      <div className={ `bookedOffice officeBox ${this.props.className}` }>

        <div className="bookedOffice-details">
          <Link className="officeBox-title items-style1" to={  buildLocationUrl(this.props.item.getIn(['bookings', '0', 'office']).toJS()) } style={{marginBottom: 0}}>{ this.props.item.getIn(['bookings', '0', 'office', 'name']) }</Link>
         <div className="officeBox-address">{this.props.item.getIn(['bookings', '0', 'office', 'address'])}</div>
        {this.props.myBookings ?
          this.handleBookingUrl():
          <Link className="officeBox-infoSmall-date" to={ buildLocationUrl(this.props.item.getIn(['bookings', '0', 'office']).toJS())  }>{ this.computeDates(this.props.item) }</Link>}
          {this.handleProductType()}
        </div>

         { this.props.myBookings || this.props.item.getIn(['bookings', '0', 'isAttendee']) ?
              <div className="bookedOffice-actions">
                  {this.props.fullView ? null : (<button onClick={this.openMobileAppModal}
                                                         className="bookedOffice-directions secondary-button-leftSide"
                                                         style={{display: 'inherit'}}>
                      <span className="bookedOffice-directionsIcon"></span>
                      {this.props.trans.officeBox.planDrive}
                  </button>)}
                  <div className="officeBox-infoSmall"
                       style={{display: this.computeStartsIn(this.props.item) !== null ? 'inherit' : 'none'}}>
                      {this.props.trans.officeBox.startsIn}
                      <span className="officeBox-infoHighlighted">{this.computeStartsIn(this.props.item)}</span>
                  </div>
              </div>
             :
             null
          }

        <ModalDialog
          modalOpened={this.state.mobileAppModalOpened}
          className="--postHeader"
          onModalClose={this.onModalClose}
          label="removeConnection">
          <MobileApp
            onAction={this.removePost}
            onCancelAction={this.onModalClose}
            trans={this.props.trans}
          />
        </ModalDialog>
      </div>
    );
  }
}

BookedOfficeInfo.propTypes = {
  /**
   * Immutable map containing an office description
   * @param item
   * @type Immutable.Map
   */
  item: PropTypes.object.isRequired,
  /**
   * Flag which determines if connections are displayed or not
   * @param showConnections
   * @type boolean
   */
  showConnections: PropTypes.bool
};

export default withRouter(BookedOfficeInfo);
