import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Truncate from 'react-truncate';
import _ from 'lodash';
import nl2br from 'react-nl2br';
import { Link } from 'react-router-dom';
import deskIcon from '../../../../assets/svg/dashboard/desk-office-icon.svg';
import privateOfficeIcon from '../../../../assets/svg/dashboard/private-office-icon.svg';
import meetingRoomIcon from '../../../../assets/svg/dashboard/meeting-room-icon.svg';
import eventRoomIcon from '../../../../assets/svg/dashboard/event-room-icon.svg';
import noCompanyPicture from '../../../../assets/svg/dashboard/no-company-picture.svg';
import JobAnnouncement from './job-announcement/JobAnnouncement';
import buildTags from '../../utils/build-tags';
import buildLocationUrl from '../../../../utils/buildLocationUrl';
import premiumPartnerIcon from "../../../../assets/svg/map/premium-partner.svg";

const IconMap = {
  deskIcon,
  privateOfficeIcon,
  meetingRoomIcon,
  eventRoomIcon,
};

class NewCompanyProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      descriptionSeeAll: false,
    };
    this.toggleDescriptionSeeAll = this.toggleDescriptionSeeAll.bind(this);
    this.buildCompanyDescription = this.buildCompanyDescription.bind(this);
    this.buildOfficeSpace = this.buildOfficeSpace.bind(this);
    this.buildJobAnnouncement = this.buildJobAnnouncement.bind(this);
    this.buildProductType = this.buildProductType.bind(this);
  }

  componentDidMount() {
    const { fetchNewCompanyProfile, media } = this.props;
    if (media !== true) {
      fetchNewCompanyProfile();
    }
  }

  buildJobAnnouncement(job) {
    const { translations } = this.props;
    return <JobAnnouncement key={_.get(job, 'id')} job={job} translations={translations} />;
  }

  buildProductType(productType, index) {
    const { translations } = this.props;
    const locationDetails = _.get(productType, 'officeDetails', '');
    const slug = _.get(productType, 'slug', '');
    const name = _.get(productType, 'name', '');
    return (
      <Link key={index} className="product__container" to={buildLocationUrl(locationDetails)}>
        <img className="product__logo" src={IconMap[`${slug}Icon`]} alt="" />
        <div className="product-name__container">
          <p className="product__name">{_.get(translations, name, name)}</p>
        </div>
      </Link>
    );
  }

  toggleDescriptionSeeAll() {
    const { descriptionSeeAll } = this.state;
    this.setState({ descriptionSeeAll: !descriptionSeeAll });
  }

  buildCompanyDescription() {
    const { descriptionSeeAll } = this.state;
    const { company, translations } = this.props;
    const companyDescription = _.get(company, 'description', '');
    let displayElement = null;
    if (companyDescription) {
      if (!descriptionSeeAll) {
        displayElement = (
          <>
            <Truncate
              lines={2}
              ellipsis={
                <>
                  ...{' '}
                  <button onClick={this.toggleDescriptionSeeAll} className="button__text-link" type="button">
                    {_.get(translations, 'readMore', 'Read more')}
                  </button>
                </>
              }
            >
              {nl2br(companyDescription)}
            </Truncate>
          </>
        );
      } else {
        displayElement = (
          <>
            {nl2br(companyDescription)}
            <button onClick={this.toggleDescriptionSeeAll} className="button__text-link" type="button">
              {_.get(translations, 'showLess', 'Show less')}
            </button>
          </>
        );
      }
    }

    return <div className="new-company__description">{displayElement}</div>;
  }

  buildOfficeSpace() {
    const { productTypes, translations } = this.props;
    if (_.isEmpty(productTypes) || !_.isArray(productTypes)) {
      return null;
    }
    return (
      <div className="new-company__office-space">
        <div className="card__subtitle"> {_.get(translations, 'selectAnOffice', 'Select an office space to start')}</div>
        <div className="product-types__container">{productTypes.map(this.buildProductType)}</div>
      </div>
    );
  }

  render() {
    const { company, jobs, companyLogo, tags, media, translations } = this.props;
    const name = _.get(company, 'name', '');
    const address = _.get(company, 'address', '');
    const postCode = _.get(company, 'postal_code', '');
    const city = _.get(company, 'city', '');
    const country = _.get(company, 'country', '');
    const premiumSupplier = _.get(company, 'premiumSupplier', '');
    const logo = companyLogo || noCompanyPicture;
    if (!company || _.isEmpty(company)) {
      return null;
    }
    return (
      <div className="dashboard-grid__row dashboard-grid__row--margin-bottom">
        <div className="card__container card__container--border card__new-company">
          <h4 className="card__title">
            {`${media ? '' : _.get(translations, 'new', 'New')} ${_.get(translations, 'companyProfile', 'Company Profile')}`}
          </h4>
          <div className="new-company__container">
            <div className="new-company__header">
              {premiumSupplier ? (
                <div className="premium-partner__container">
                  <img
                    src={premiumPartnerIcon}
                    alt={_.get(translations, 'premiumPartner', 'Premium partner')}
                    className="premium-partner__icon"
                  />
                  <div className="health__stamps-tooltip health__stamps-tooltip--premium">
                    {_.get(translations, 'premiumPartner', 'Premium Partner')}
                  </div>
                </div>
              ) : null}
              <div className={classnames('company-logo__container', { 'company-logo__container-no-logo': !companyLogo })}>
                <img className="company__logo" src={logo} alt="Company Logo" />
              </div>
              <div className="company__info-content">
                <div className="company__name">{name}</div>
                <div className="company__address">
                  {address}, {postCode}
                </div>
                <div className="company__address">
                  {city}, {country}
                </div>
              </div>
            </div>
            {this.buildCompanyDescription()}
            {!media ? this.buildOfficeSpace() : null}
          </div>
          {_.isEmpty(jobs) || !_.isArray(jobs) ? null : (
            <div className="new-company__jobs">
              <div className="card__subtitle">{_.get(translations, 'jobs', 'Jobs')}</div>
              <div className="job-announcements__wrapper">{jobs.map(this.buildJobAnnouncement)}</div>
            </div>
          )}
          {_.isEmpty(tags) || !_.isArray(tags) || media ? null : (
            <div className="new-company__tags">
              <div className="card__subtitle">{_.get(translations, 'companyTags', 'Company tags')}</div>
              <div className="tags__wrapper">{buildTags(tags, '', translations)}</div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

NewCompanyProfile.propTypes = {
  company: PropTypes.shape({
    name: PropTypes.string,
    address: PropTypes.string,
  }),
  companyLogo: PropTypes.string,
  jobs: PropTypes.arrayOf(
    PropTypes.shape({
      params: PropTypes.string,
    })
  ),
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ),
  productTypes: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      slug: PropTypes.string,
      officeDetails : PropTypes.shape({}),
    })
  ),
  media: PropTypes.bool,
  fetchNewCompanyProfile: PropTypes.func,
};

NewCompanyProfile.defaultProps = {
  company: {},
  companyLogo: '',
  jobs: [],
  tags: [],
  productTypes: [],
  media: false,
  fetchNewCompanyProfile: () => null,
};

export default NewCompanyProfile;
