import moment from 'moment';
import React from 'react';
import _ from 'lodash';
import Immutable from 'immutable';
import DatePicker from '../../../../datePicker/datePicker';
import AddBookingCheckboxesGroup from '../../../../addBookingSidebar/addBookingCheckboxesGroup/addBookingCheckboxesGroup';
import Dropdown from '../../../../addBookingSidebar/dropdown';
import StaffListing from '../staffListing';

const weekDaysOrder = [1, 2, 3, 4, 5, 6, 7];

class DateTimePicker extends React.Component {
  constructor(props) {
    super(props);
  }

  renderCalendar() {
    const { whiteLabel, onUpdateDate, selectDateRange, translations, selectedProductType, officeOpenDaysAndHours, officeTimezone } = this.props;
    const startDate = _.get(selectDateRange, 'from', null);
    const endDate = _.get(selectDateRange, 'to', null);
    const excludedDates = [];
    const disabledDays = [];
    const selectedDays = _.map(officeOpenDaysAndHours.toJS(), data => data.weekDay);
    if (selectedDays) {
      for (let weekD = 1; weekD <= 7; weekD++) {
        if (!_.find(selectedDays, data => data === weekD)) {
          disabledDays.push(weekD);
        }
      }
    }
    const maxBookingDays = _.get(whiteLabel, 'details.max_bookable_days', 30);
    const today = moment.tz(officeTimezone);
    const todayPlusDays = today.add(maxBookingDays, 'days');

    return (
      <div>
        <div className="white-label__booking-options-title" onClick={e => e.stopPropagation()}>
          {_.get(translations, 'booking.options.dateLabel', 'Date ennn')}
        </div>
        <DatePicker
          toggleDateExpanded={this.toggleDateExpanded}
          placeholder={_.get(translations, 'booking.options.selectRentingDate', 'Renting')}
          startDate={startDate}
          endDate={selectedProductType === 3 ? startDate : endDate}
          excludeDates={excludedDates}
          disabledDays={disabledDays}
          onChange={onUpdateDate}
          trans={translations}
          single={selectedProductType === 3}
          maxDate={todayPlusDays}
          officeTimezone={officeTimezone}
        />
      </div>
    );
  }

  renderWorkingWeekDays() {
    const { selectedDays, officeOpenDaysAndHours, translations, selectDateRange } = this.props;
    const startDate = _.get(selectDateRange, 'from', '');
    const endDate = _.get(selectDateRange, 'to', '');

    const daysSelected = [];
    for (let m = moment(startDate, 'MMM D, YYYY'); m.isSameOrBefore(moment(endDate, 'MMM D, YYYY')); m.add(1, 'days')) {
      daysSelected.push(m.isoWeekday());
    }

    const checkboxes = [];
    weekDaysOrder.map(weekDayIndex => {
      const isDisabled =
        !_.find(daysSelected, x => x === weekDayIndex) || !_.find(officeOpenDaysAndHours.toJS(), data => data.weekDay === weekDayIndex);
      checkboxes.push({
        id: weekDayIndex,
        name: `day[${weekDayIndex}]`,
        label: _.get(translations, `location.weekDaysMap[${weekDayIndex}]`),
        disabled: isDisabled,
      });
      return null;
    });

    return (
      <div>
        <AddBookingCheckboxesGroup
          titleClassName="white-label__booking-options-title mt-20 pb-6"
          groupName="amenities"
          groupLabel={_.get(translations, 'booking.options.desiredWorkingDays', 'Desired Working Days')}
          selectedItems={selectedDays}
          items={Immutable.fromJS(checkboxes)}
          onChange={this.props.onChangeWeekDays}
        />
        <div style={{ clear: 'both' }} />
      </div>
    );
  }

  renderWorkingHours() {
    const { selectedHours, officeOpenDaysAndHours, translations } = this.props;
    const fromHour = _.get(officeOpenDaysAndHours.toJS(), '0.fromHour', '00');
    const fromMinute = _.get(officeOpenDaysAndHours.toJS(), '0.fromMinute', '00');
    const untilHour = _.get(officeOpenDaysAndHours.toJS(), '0.toHour', '00');
    const untilMinute = _.get(officeOpenDaysAndHours.toJS(), '0.toMinute', '00');
    if (fromHour && untilHour) {
      const from = moment()
        .set('hour', fromHour)
        .set('minute', fromMinute);

      const fromHours = [];
      const fromNextHour = from;

      if (fromMinute !== '00' && fromMinute !== '30') {
        // find first nearest half hour if start hours is not :00 or :30
        const remainder = 30 - (from.minute() % 30);
        fromNextHour.add(remainder, 'minutes');
      }

      while (parseInt(fromNextHour.format('HHmm'), 10) <= parseInt(untilHour + untilMinute, 10)) {
        fromHours.push(fromNextHour.format('HH:mm'));
        fromNextHour.add(30, 'minutes');
        // Here the hour 24:00 is 00:00 so after 23:30 comes 00:00
        if(parseInt(fromNextHour.format('HHmm'), 10) == 0)
          // Exit form the iteration if we've reached the last hour
          break;
      }

      return (
        <div>
          <div className="white-label__booking-options-title desiredHours mt-20">
            {_.get(translations, 'booking.options.desiredWorkingHours')}
          </div>
          <div className="booking-options-hours">
            <Dropdown
              placeHolder="From"
              onChange={value => this.props.onUpdateHours('hoursFrom', value)}
              options={fromHours}
              selected={selectedHours.hoursFrom}
            />
            <Dropdown
              placeHolder="Until"
              onChange={value => this.props.onUpdateHours('hoursUntil', value)}
              options={fromHours}
              selected={selectedHours.hoursUntil}
            />
          </div>
        </div>
      );
    }
  }

  render() {
    const { renderErrorFullyBooked, renderErrorWeekday, renderErrorHours } = this.props;
    return (
      <div>
        {this.renderCalendar()}
        {renderErrorFullyBooked}
        <StaffListing />
        {this.renderWorkingWeekDays()}
        {renderErrorWeekday}
        {this.renderWorkingHours()}
        {renderErrorHours}
      </div>
    );
  }
}

export default DateTimePicker;
