import Immutable, { fromJS } from 'immutable';
import {
  SET_NEWS,
  SET_NEWS_IS_LOADING,
  SET_NEWS_IS_LOADING_MORE,
  SET_LOAD_MORE_OFFSET,
  INCREMENT_LOAD_MORE_OFFSET,
  ADD_NEWS,
  SET_HAS_MORE,
} from '../../actions/dashboard/news';
import { NEWS_PER_PAGE } from '../../components/dashboard/constants';

const initialState = Immutable.Map({
  news: fromJS([]),
  loadMoreOffset: 0,
  isLoading: false,
  isLoadingMore: false,
  hasMore: true,
});

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_NEWS:
      return state.setIn(['news'], action.news);
    case ADD_NEWS:
      return state.setIn(['news'], state.get('news', []).concat(action.news));
    case SET_LOAD_MORE_OFFSET:
      return state.setIn(['loadMoreOffset'], action.loadMoreOffset);
    case INCREMENT_LOAD_MORE_OFFSET:
      return state.setIn(['loadMoreOffset'], state.get('loadMoreOffset', 0) + NEWS_PER_PAGE);
    case SET_NEWS_IS_LOADING:
      return state.setIn(['isLoading'], action.isLoading);
    case SET_NEWS_IS_LOADING_MORE:
      return state.setIn(['isLoadingMore'], action.isLoadingMore);
    case SET_HAS_MORE:
      return state.setIn(['hasMore'], action.hasMore);
    default:
      return state;
  }
};
