import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import moment from 'moment';
import { Link } from 'react-router-dom';
import './css/index.scss';
import _ from 'lodash';
import PropTypes from 'prop-types';
import AccountPaymentSettingsMenu from './AccountPaymentSettingsMenu';
import PageHeader from '../pageHeader/pageHeader';
import LoadMore from './LoadMore';

class VoucherHistoryView extends React.Component {
  constructor(props) {
    super(props);
    this.buildVoucherHistoryRow = this.buildVoucherHistoryRow.bind(this);
    this.fetchMoreVouchers = this.fetchMoreVouchers.bind(this);
  }

  componentDidMount() {
    const { fetchVoucherHistory } = this.props;
    fetchVoucherHistory();
  }

  fetchMoreVouchers() {
    const { fetchVoucherHistory } = this.props;
    fetchVoucherHistory();
  }

  buildVoucherHistoryRow(voucherInfo) {
    const { translations } = this.props;
    const bookingId = _.get(voucherInfo, 'id', '');
    const voucherCode = _.get(voucherInfo, 'voucher_code', '');
    const duration = _.get(voucherInfo, 'duration', '');
    const voucherCodeLine = translations.formatString(_.get(translations, 'voucherHistory.voucherCode', 'Voucher code:{0}'), voucherCode);
    const daysLabel =
      duration > 1 ? _.get(translations, 'voucherHistory.daysLabel', 'days') : _.get(translations, 'voucherHistory.dayLabel', 'day');
    const lastInfo = _.get(voucherInfo, 'isExtraCharge', false) ? 'extra charge' : `${duration} ${daysLabel}`;

    const voucherDetails = translations.formatString(
      _.get(translations, 'voucherHistory.voucherDetails', 'Payment for {0} - {1}, {2}'),
      _.get(voucherInfo, 'office', ''),
      _.get(voucherInfo, 'product_type', ''),
      lastInfo
    );

    return (
      <div key={`voucher-${bookingId}`} className="voucher-history__booking">
        <div className="voucher-history__column voucher-date">{moment.utc(voucherInfo.booking_date).format('MMM DD, YYYY')}</div>
        <div className="voucher-history__column voucher-info">
          <Link to={`/booking/${bookingId}`}>{voucherDetails}</Link>
          <span>{voucherCodeLine}</span>
        </div>
        <div className="voucher-history__column voucher-amount">&euro; {_.get(voucherInfo, 'voucher_value', '')}</div>
      </div>
    );
  }

  renderLoadMoreButton() {
    const { translations, isLoading, hasMore } = this.props;
    if (!hasMore) {
      return null;
    }
    return (
      <div className="voucher-history__loadMore-container">
        {isLoading ? (
          <div className="voucher-history__loading" />
        ) : (
          <LoadMore fetchVouchers={this.fetchMoreVouchers} trans={translations} />
        )}
      </div>
    );
  }

  render() {
    const { translations, vouchers, isLoading } = this.props;
    return (
      <>
        <div className="accountPaymentSettings">
          <div className="payment-settings">
            <section className="payment-settings-content">
              <Scrollbars autoHide className="styleScroll">
                <PageHeader
                  className="--defaultPadding"
                  pageTitle={_.get(translations, 'paymentSettings.title', 'Payment Settings')}
                  icon="payment-settings"
                  actions={[]}
                />
                <AccountPaymentSettingsMenu />
                {!isLoading && vouchers.length === 0 ? (
                  <div className="payment-settings-voucher-history__no-records">
                    <div className="content">
                      <span className="percent-icon" />
                      <div className="line1">{_.get(translations, 'voucherHistory.noVouchersUsedLine1', 'You have no vouchers.')}</div>
                      <div className="line2">
                        {_.get(translations, 'voucherHistory.noVouchersUsedLine2', 'It seems that you don’t have any voucher history.')}
                      </div>
                    </div>
                  </div>
                ) : null}
                <div className="payment-settings-voucher-history">
                  {vouchers.length > 0 ? (
                    <div className="voucher-history__wrapper">
                      <div className="voucher-history__header">
                        <div className="voucher-history__column voucher-date">
                          {_.get(translations, 'voucherHistory.bookingDate', 'Date')}
                        </div>
                        <div className="voucher-history__column voucher-info">
                          {_.get(translations, 'voucherHistory.voucherUsedFor', 'Voucher used for')}
                        </div>
                        <div className="voucher-history__column voucher-amount">
                          {_.get(translations, 'voucherHistory.amount', 'Amount')}
                        </div>
                      </div>
                      <div className="voucher-history__list">{vouchers.map(this.buildVoucherHistoryRow)}</div>
                    </div>
                  ) : null}
                  {this.renderLoadMoreButton()}
                </div>
              </Scrollbars>
            </section>
          </div>
        </div>
      </>
    );
  }
}

VoucherHistoryView.propTypes = {
  fetchVoucherHistory: PropTypes.func.isRequired,
  translations: PropTypes.shape({}).isRequired,
  isLoading: PropTypes.bool,
  hasMore: PropTypes.bool,
  vouchers: PropTypes.shape([]).isRequired,
};

VoucherHistoryView.defaultProps = {
  isLoading: false,
  hasMore: true,
};

export default VoucherHistoryView;
