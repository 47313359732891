import React from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import MapContainer from './../mapContainer/mapContainer';

import officePin from './../../assets/svg/map/available.svg';

const OfficeContactInfo = (props) => {
  let {map, address, name, phone, emailPerson, emailOffice } = props.contactInfo;

  const bookedStatus = props.bookedStatus;
  const title = props.title;
  const classWrapper = (!bookedStatus) ? 'contact-info' : 'contact-info-booked';
  const classInfoWrapper = 'contact-info ' + classWrapper;
  const getTitle = (!bookedStatus) ? '' : <div className="contact-info-fields-title">{ title }</div>;
  /* set a default pin for office location */
  map.markers.forEach(marker => (marker.icon = marker.icon || officePin));

  return (
    <div className={ classInfoWrapper }>
      <div className="contact-info-title">{props.trans.location.contactInformation}</div>
        <div className="contact-info-map-container">
          <MapContainer
            hideLegend={true}
            className="--no-padding --full-height"
            mapData={Immutable.fromJS(map)}
            activeMarker={ map.activeMarker }
            scrWheel={false}
            draggable={false}
            />
        </div>

        <div className="contact-info-fields">
          <div className="contact-info__section">
            { getTitle }
            <div className="contact-info-adress">
              <div className="contact-info-label">{props.trans.location.address}</div>
              <span className="contact-info-value">{address.street + ', ' + address.postCode + ', ' + address.city}</span>
            </div>
            <div className="contact-info-name">
              <div className="contact-info-label">{props.trans.location.contactPerson}</div>
              <span className="contact-info-value">{name}</span>
            </div>
          </div>
          <div className="contact-info__section">
            <div className="contact-info-emailPerson">
              <div className="contact-info-label">{props.trans.location.emailContactPerson}</div>
              <span className="contact-info-value">{emailPerson}</span>
            </div>
              <div className="contact-info-emailOffice">
                  <div className="contact-info-label">{props.trans.location.officeEmail}</div>
                  <span className="contact-info-value">{emailOffice}</span>
              </div>
          </div>

          {(!phone) ? (<span></span>) : (
                <div className="contact-info__section">
                  <div className="contact-info-phone">
                    <div className="contact-info-label">{props.trans.location.phoneNumber}</div>
                    <span className="contact-info-value">{phone}</span>
                  </div>
                </div>
            )}

        </div>
    </div>
  )
};

OfficeContactInfo.propTypes = {
  contactInfo: PropTypes.object.isRequired,
};

export default OfficeContactInfo;
