import { connect } from 'react-redux';
import Connection from './../../components/connection/connection';
import { fetchProfile } from './../../actions/accountActions';

import {
    beforeFetchConnection,
    fetchConnection,
    fetchConnectionConnections
} from './../../actions/connections';
import {fetchDirectChatConversations, openConversation, openConversationAndFetch, fetchChatConnections } from "../../actions/directChat";

const mapStateToProps = state => {
    return {
        connection: state.connections.get('currentConnection'),
        add_connection: state.connections.get('add_connection'),
        heroProfile: state.global.get('heroProfile'),
        conversations: state.directChat.get('conversations'),
        translations: state.translations,
    };
};

const mapDispatchProps = dispatch => {
    return {
        beforeFetchConnection: () => {
            dispatch(beforeFetchConnection());
        },

        fetchConnection: (connectionId) => {
            dispatch(fetchConnection(connectionId));
        },

        fetchChatConnections: () => {
            dispatch(fetchChatConnections());
        },
                fetchProfile: () => {
            dispatch(fetchProfile());
        },
        openConversationAndFetch: (heroId, conversatioId) => {
        dispatch(openConversationAndFetch(heroId, conversatioId));
        },
        openConversation: (heroId, poster) => {
        dispatch(openConversation(heroId, poster));
        },
        fetchDirectChatConversations: () => {
            dispatch(fetchDirectChatConversations());
        },
        fetchConnectionConnections: (connectionId) => {
            dispatch(fetchConnectionConnections(connectionId));
        }
    }
};

const ConnectionView = connect(mapStateToProps, mapDispatchProps)(Connection);

export default ConnectionView;
