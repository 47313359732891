import Immutable, { fromJS } from 'immutable';
import _ from 'lodash';
import {
  SET_COMMUNITY,
  SET_COMMUNITY_IS_LOADING,
  SET_HERO_IS_COMMUNITY_ADMIN,
  SET_HERO_IS_COMMUNITY_MEMBER,
} from '../../actions/community/community-page';
import { ADD_HERO_TO_COMMUNITY } from '../../actions/community/join-community';

const initialState = Immutable.Map({
  community: {},
  isLoading: false,
  heroIsCommunityAdmin: false,
  heroIsCommunityMember: false,
});

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_COMMUNITY:
      return state.setIn(['community'], action.community);
    case SET_COMMUNITY_IS_LOADING:
      return state.setIn(['isLoading'], action.isLoading);
    case SET_HERO_IS_COMMUNITY_ADMIN:
      return state.setIn(['heroIsCommunityAdmin'], action.isCommunityAdmin);
    case SET_HERO_IS_COMMUNITY_MEMBER:
      return state.setIn(['heroIsCommunityMember'], action.isCommunityMember);
    case ADD_HERO_TO_COMMUNITY:
      return state.updateIn(['community', 'members'], members => _.concat(members, action.hero));
    default:
      return state;
  }
};
