import React  from 'react';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import PropTypes from 'prop-types';
import CommentCard from './CommentCard';
import formatComment from '../../utils/comment-formatter';

class CommentsCardContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showAll: false,
    };

    this.renderCommentCard = this.renderCommentCard.bind(this);
    this.showAllComments = this.showAllComments.bind(this);
  }
  componentDidMount() {
    const {isSinglePostPage} = this.props;
    if (isSinglePostPage) {
      this.showAllComments();
    }

  }

  showAllComments() {
    const { fetchComments, post } = this.props;
    const postId = _.get(post, 'id', null);
    this.setState({ showAll: true });
    fetchComments(postId);
  }

  renderCommentCard(comment, index) {
    const {
      post,
      heroProfile,
      translations,
      deleteCommunityComment,
      showCommunityPageToast,
      heroIsCommunityAdmin,
      report,
      community,
      heroIsCommunityMember
    } = this.props;

    return (
      <CommentCard
        key={`comment-card-${index}`}
        uniqueCommentIdentifier={`comment-card-${index}`}
        comment={comment}
        post={post}
        heroProfile={heroProfile}
        translations={translations}
        heroIsCommunityAdmin={heroIsCommunityAdmin}
        deleteCommunityComment={deleteCommunityComment}
        showCommunityPageToast={showCommunityPageToast}
        report={report}
        community={community}
        heroIsCommunityMember={heroIsCommunityMember}
      />
    );
  }

  render() {
    const { translations, post, isLoading } = this.props;
    const { showAll } = this.state;
    const numberOfComments = _.get(post, 'numberOfComments', 0);
    const comments = formatComment(_.get(post, 'comments', []));
    const commentsCount = comments.length;
    const displayComments = showAll ? comments : comments.slice(0, 3);
    const nbCommentsAvailable = numberOfComments - 3;
    const viewAll =
      nbCommentsAvailable === 1
        ? _.get(translations, 'communityComments.viewOneMoreComment', 'communityComments.viewOneMoreComment')
        : _.get(translations, 'communityComments.viewAllComments', 'communityComments.viewAllComments').replace(
            '#numberOfComments#',
            nbCommentsAvailable
          );

    if (isLoading) {
      return <div className="comments-wrapper__loader" />;
    }

    if (numberOfComments > 0) {
      return (
        <>
          {displayComments.map(this.renderCommentCard)}
          {commentsCount > 3 && !showAll && (
            <div className="community__comment-container">
              <span className="comments-show-all" onClick={this.showAllComments}>
                {viewAll}
              </span>
            </div>
          )}
        </>
      );
    }

    return null;
  }
}

CommentsCardContainer.propTypes = {
  fetchComments: PropTypes.func.isRequired,
  post: PropTypes.shape({}).isRequired,
  heroProfile: PropTypes.shape({}).isRequired,
  translations: PropTypes.shape({}).isRequired,
  deleteCommunityComment: PropTypes.func.isRequired,
  showCommunityPageToast: PropTypes.func.isRequired,
  heroIsCommunityAdmin: PropTypes.bool.isRequired,
  report: PropTypes.func.isRequired,
  community: PropTypes.shape({}).isRequired,
  isLoading: PropTypes.bool,
};

CommentsCardContainer.defaultProps = {
  isLoading: undefined,
};


export default withRouter(CommentsCardContainer);
