import Immutable from 'immutable';
import {
  FETCH_PROFILE,
  SAVE_PROFILE,
  SAVE_ORIGINAL_INFORMATION,
  FETCH_PERMISSIONS,
  SAVE_PERMISSION,
  FETCH_RESTRICTIONS
} from './../actions/accountActions';
import { FETCH_SKILLS, FETCH_INDUSTRIES, FETCH_COUNTRY_CODES, FETCH_COUNTRIES } from './../actions/generalDataActions';
import {
  CLEAR_DELETE_ERRORS,
  DELETE_HERO_PAYMENT_PROFILE,
  DELETE_PAYMENT_METHOD, LOADER_DELETE
} from './../actions/locationActions';

const defaultState = Immutable.fromJS({
  profile: null,
  skills: null,
  industries: null,
  countryCodes: null,
  countries: null,
  originalInformation: null,
  deletePaymentProfileErrors: null,
  permissions: [],
  restrictions: [],
  loaderDelete: false,
});

const account = (state = defaultState, action) => {
  let newState;

  switch (action.type) {
    case FETCH_PROFILE:
      newState = state.set('profile', Immutable.fromJS(action.data));
      break;
    case DELETE_HERO_PAYMENT_PROFILE:
      newState = state.set('deletePaymentErrors', Immutable.fromJS(action.errors));
      break;
    case SAVE_PROFILE:
      newState = state.set('profile', Immutable.fromJS(action.data));
      break;
    case FETCH_SKILLS:
      newState = state.set('skills', Immutable.fromJS(action.data));
      break;
    case FETCH_INDUSTRIES:
      newState = state.set('industries', Immutable.fromJS(action.data));
      break;
    case FETCH_COUNTRY_CODES:
      newState = state.set('countryCodes', Immutable.fromJS(action.data));
      break;
    case FETCH_COUNTRIES:
      newState = state.set('countries', action.data);
      break;
    case SAVE_ORIGINAL_INFORMATION:
      newState = state.set('originalInformation', Immutable.fromJS(action.information));
      break;
    case FETCH_PERMISSIONS:
      newState = state.set('permissions', Immutable.fromJS(action.data));
      break;
    case FETCH_RESTRICTIONS:
      newState = state.set('restrictions', Immutable.fromJS(action.data.restrictions));
      break;
    case SAVE_PERMISSION:
      newState = state.set('permissions', Immutable.fromJS(action.data));
      break;
    case DELETE_PAYMENT_METHOD:
      newState = state.set('deletePaymentErrors', Immutable.fromJS(action.errors));
      break;
    case CLEAR_DELETE_ERRORS:
      newState = state.set('deletePaymentErrors', null);
      break;
    case LOADER_DELETE:
      newState = state.set('loaderDelete', action.data);
      break;
    default:
      newState = state;
  }

  return newState;
};

export default account;
