import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classnames from 'classnames';
import './css/experienceBox.scss';

class ExperienceBox extends React.Component {
  _computeDate () {
    let month = (this.props.startMonth) ? moment(this.props.startMonth, 'MM').format('MMMM') + ' ' : '';
    let year = (this.props.startDate) ? moment(this.props.startDate, 'YYYY').format('YYYY') + ' ' : '';
    let endMonth = (this.props.endMonth) ? moment(this.props.endMonth, 'MM').format('MMMM') + ' ' : '';
    let endYear = (this.props.endDate) ? moment(this.props.endDate, 'YYYY').format('YYYY') + ' ' : '';
    let present = '';

    if(endMonth === '' && endYear === '') {
      present = ` - ${this.props.trans.accountProfile.present} `;
    } else {
      present = ' - ' + endMonth + ' ' + endYear;
    }
    let diffTime = '';
    let experience = '';

    if(this.props.diffInYears !== null) {
      let diffInYears = this.props.diffInYears;
      if(diffInYears === 1) {
        diffTime = diffInYears + ' yr ';
      }
      if(diffInYears > 1) {
        diffTime += diffInYears + ' yrs ';
      }
      if(diffInYears === 0 && !this.props.diffInMonths) {
        diffTime += ' Less than a year ';
      }
    }

    if(this.props.diffInMonths) {
      let diffInMonths = this.props.diffInMonths;
      if(diffInMonths < 0) {
        diffInMonths = diffInMonths * (-1);
      }
      diffTime += diffInMonths + ' mo ';
    }

    if(diffTime !== '') {
      experience = '• ' + diffTime;
    }

    if(this.props.diffInYears !== null && this.props.diffInMonths !== null) {
      return month + year + present + experience
    }
  }

  render () {
    let iconStyle = { };
    if (this.props.icon) {
      iconStyle = { backgroundImage: `url('${this.props.icon}')`, backgroundSize: 'cover' };
    }

    return (
      <div className={ `experienceBox --${this.props.type} ${this.props.className}` }>
        <div className={classnames('experienceBox-icon', {'defaultCompanyIcon' : !this.props.icon })} style={ iconStyle }></div>
        <div className="experienceBox-info">
          <h2 className="experienceBox-title">{ this.props.title }</h2>
          <div className="experienceBox-smallInfo">{ this.props.company || this.props.speciality }</div>
          <div className="experienceBox-smallInfo">{ this._computeDate() }</div>
          {(this.props.description !== null || this.props.description !== '') ? <div className="experienceBox-description">{ this.props.description }</div> : null}
          {((this.props.description === null || this.props.description === '') && (this.props.summary !== null || this.props.summary !== '')) ? <div className="experienceBox-description">{ this.props.summary }</div> : null }
        </div>
      </div>
    );
  }
}

export default ExperienceBox;

ExperienceBox.propTypes = {
  title: PropTypes.string.isRequired,
  company: PropTypes.string,
  startDate: PropTypes.number,
  endDate: PropTypes.number,
  diffInMonths: PropTypes.any.isRequired,
  diffInYears: PropTypes.any.isRequired,
  description: PropTypes.string,
  icon: PropTypes.string,
  type: PropTypes.string
};

ExperienceBox.defaultProps = {
  type: 'experience',
  icon: '',
  description: '',
  company: '',
  startDate: '',
  endDate: ''
};
