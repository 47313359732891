export const FETCH_OFFICE_TYPES         = 'FETCH_OFFICE_TYPES';
export const FETCH_AMENITIES            = 'FETCH_AMENITIES';
export const FETCH_SERVICES             = 'FETCH_SERVICES';
export const FETCH_HOUSE_RULES          = 'FETCH_HOUSE_RULES';
export const FETCH_SKILLS               = 'FETCH_SKILLS';
export const FETCH_INDUSTRIES           = 'FETCH_INDUSTRIES';
export const FETCH_COUNTRY_CODES        = 'FETCH_COUNTRY_CODES';
export const FETCH_COUNTRIES            = 'FETCH_COUNTRIES';

let lang = localStorage.getItem('lang') !== null ? localStorage.getItem('lang') : 'en';

export const fetchOfficeTypes = () => {
    return {
        type: FETCH_OFFICE_TYPES,
        url: window.__env.apiUrl + "/data/officeTypes?language=" + lang
    }
};

export const fetchAmenities = () => {  
    return {
        type: FETCH_AMENITIES,
        url: window.__env.apiUrl + '/data/amenities?language=' + lang
    }
};

export const fetchServices = () => {
    return {
        type: FETCH_SERVICES,
        url: window.__env.apiUrl + '/data/services?language=' + lang
    }
};

export const fetchHouseRules = () => {
    return {
        type: FETCH_HOUSE_RULES,
        url: window.__env.apiUrl + '/data/houseRules?language=' + lang
    }
};

export const fetchSkills = () => {
    return {
        type: FETCH_SKILLS,
        url: window.__env.apiUrl + '/data/skills?language=' + lang
    }
};

export const fetchIndustries = () => {
    return {
        type: FETCH_INDUSTRIES,
        url: window.__env.apiUrl + '/data/industries?language=' + lang
    }
};

export const fetchCountryCodes = () => {
    return {
        type: FETCH_COUNTRY_CODES,
        url: window.__env.apiUrl + '/data/countryCodes'
    }
};

export const fetchCountries = () => {
    return {
        type: FETCH_COUNTRIES,
        url: window.__env.apiUrl + '/data/countries'
    }
};
