import React from 'react';
import { Link } from 'react-router-dom';
import './css/index.scss';
import onClickOutside from 'react-onclickoutside';
import _ from 'lodash';

class profileMenuUnlogged extends React.Component {
    handleClickOutside = evt => {
        if(this.props.profileMenuClicked)
            this.props.profileMenuClick(evt);
    };

  render() {
      return (
      <div className="profile-menu">
        <div className="profile-menu-item">
          <Link onClick={this.props.profileMenuClick} to="/sign-up">
            <div className="menu-icon general-settings-icon" />
            <div className="profile-menu-item-label">{_.get(this.props.trans, 'signup', 'Sign up')}</div>
          </Link>
          <div className="clear" />
        </div>
        <div className="profile-menu-item">
          <Link onClick={this.props.profileMenuClick} to="/login">
            <div className="menu-icon profile-icon" />
            <div className="profile-menu-item-label">{_.get(this.props.trans, 'login', 'Login')}</div>
          </Link>
          <div className="clear" />
        </div>
        <div className="profile-menu-item">
          <a
            onClick={this.props.profileMenuClick}
            href="https://www.iubenda.com/terms-and-conditions/82572254"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="menu-icon terms-conditions-icon" />
            <div className="profile-menu-item-label">{_.get(this.props.trans, 'profile.termsConditions', 'Terms ans Conditions')}</div>
          </a>
          <div className="clear" />
        </div>
        <div className="profile-menu-item">
          <a onClick={this.props.profileMenuClick} href="https://join.workero.com/gdpr-form" target="_blank" rel="noopener noreferrer">
            <div className="menu-icon terms-conditions-icon" />
            <div className="profile-menu-item-label">{_.get(this.props.trans, 'profile.gdpr', 'Exercise your GDPR rights')}</div>
          </a>
          <div className="clear" />
        </div>
        <div className="profile-menu-item">
          <a onClick={this.props.profileMenuClick} href={`${window.__env.public_site}/privacy`} target="_blank" rel="noopener noreferrer">
            <div className="menu-icon terms-conditions-icon" />
            <div className="profile-menu-item-label">{_.get(this.props.trans, 'profile.privacyPolicy', 'Privacy policy')}</div>
          </a>
          <div className="clear" />
        </div>
        <div className="profile-menu-item">
          <a onClick={this.props.profileMenuClick} href={`${window.__env.public_site}/cookies`} target="_blank" rel="noopener noreferrer">
            <div className="menu-icon terms-conditions-icon" />
            <div className="profile-menu-item-label">{_.get(this.props.trans, 'profile.cookiePolicy', 'Cookie policy')}</div>
          </a>
          <div className="clear" />
        </div>
      </div>
    );
  }
}

export default onClickOutside(profileMenuUnlogged);
