const TWITTER_LINK_BASE = 'https://twitter.com/';
const TWITTER_EXTERNAL_LINK_BASE = 'https://t.co/';
const TWITTER_EXTERNAL_LINK_BASE_HTTP = 'http://t.co/';

const parseTweetContent = content =>
  content
    .replace(/(@[a-zA-z0-9]*)/g, (match, p1) => {
      if (!p1.startsWith('@') || !p1) {
        return p1;
      }
      return `<a class="twitter__excerpt-link" target="_blank" href="${TWITTER_LINK_BASE}/${p1}">${p1}</a>`;
    })
    .replace(/(#[a-zA-z0-9]*)/g, (match, p1) => {
      if (!p1.startsWith('#') || !p1) {
        return p1;
      }
      return `<a class="twitter__excerpt-link" target="_blank" href="${TWITTER_LINK_BASE}/hashtag/${p1.substr(1)}">${p1}</a>`;
    })
    .replace(/(http:\/\/t.co\/[a-zA-z0-9]*)/g, (match, p1) => {
      if (!p1.startsWith(TWITTER_EXTERNAL_LINK_BASE_HTTP) || !p1) {
        return p1;
      }
      return `<a class="twitter__excerpt-link" target="_blank" href="${p1}">${p1}</a>`;
    })
    .replace(/(https:\/\/t.co\/[a-zA-z0-9]*)/g, (match, p1) => {
      if (!p1.startsWith(TWITTER_EXTERNAL_LINK_BASE) || !p1) {
        return p1;
      }
      return `<a class="twitter__excerpt-link" target="_blank" href="${p1}">${p1}</a>`;
    });

export default parseTweetContent;
