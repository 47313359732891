/*
 * Constants
 */
export const SET_COMMUNITY_PAGE_EDIT_MODAL = 'SET_COMMUNITY_PAGE_EDIT_MODAL';
export const SET_COMMUNITY_PAGE_MANAGE_MODAL = 'SET_COMMUNITY_PAGE_MANAGE_MODAL';

/*
 * Sync Actions
 */

export const setShowEditCommunityModal = communityEditModal => ({
  type: SET_COMMUNITY_PAGE_EDIT_MODAL,
  communityEditModal,
});

export const setShowManageCommunityModal = communityManageModal => ({
  type: SET_COMMUNITY_PAGE_MANAGE_MODAL,
  communityManageModal,
});
