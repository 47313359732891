import { connect } from 'react-redux';

import toJS from '../../utils/to-js';

import InviteMembersModal from './InviteMembersModal';

import {
  fetchMoreSearchedInviteHeroes,
  fetchSearchedInviteHeroes,
  inviteHeroes,
} from '../../../../actions/community/invite-members-community';
import {
  inviteFormSelector,
  searchedInviteHeroesHasMoreSelector,
  searchedInviteHeroesHasMoreSelectorIsLoading,
  searchedInviteHeroesSelector,
  searchedInviteHeroesSelectorIsLoading,
} from '../../../../selectors/community/invite-members-community';
import { communitySelector } from '../../../../selectors/community/community-page';
import { showCommunityPageToast } from '../../../../actions/community/community-toasts';

const mapDispatchToProps = dispatch => ({
  fetchSearchedInviteHeroes: (communityId, searchInput, offset) => dispatch(fetchSearchedInviteHeroes(communityId, searchInput, offset)),
  fetchMoreSearchedInviteHeroes: (communityId, searchInput, offset) =>
    dispatch(fetchMoreSearchedInviteHeroes(communityId, searchInput, offset)),
  inviteHeroes: (communityId, heroes, guests) => dispatch(inviteHeroes(communityId, heroes, guests)),
  showCommunityPageToast: message => dispatch(showCommunityPageToast(message)),
});

const mapStateToProps = state => ({
  searchedHeroes: searchedInviteHeroesSelector(state),
  hasMore: searchedInviteHeroesHasMoreSelector(state),
  community: communitySelector(state),
  isSubmitted: inviteFormSelector(state),
  isLoading: searchedInviteHeroesSelectorIsLoading(state),
  loadMoreIsLoading: searchedInviteHeroesHasMoreSelectorIsLoading(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(toJS(InviteMembersModal));
