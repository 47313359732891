import Immutable from 'immutable';
import {
  FETCH_ADVANCED_SEARCH_DATA,
  ON_RATING_CHANGE,
  ON_PRICE_CHANGE,
  ON_OFFICE_TYPE_CHANGE,
  ON_AMENITIES_CHANGE,
  ON_SERVICES_CHANGE,
  ON_INDUSTRIES_CHANGE,
  ON_CAPACITY_CHANGE,
  ON_WORK_HOURS_FROM_CHANGE,
  ON_WORK_HOURS_UNTIL_CHANGE,
  ON_RESET_FILTERS,
  ON_APPLY_FILTERS,
  TOGGLE_MODE,
  ON_REMOVE_FILTER,
  ON_DATE_CHANGE,
  ON_CLOSE_SEARCHBOX,
  ON_HEALTH_MEASURES_CHANGE,
  ON_PREMIUM_SUPPLIER_CHANGE,
} from '../actions/filtersActions';

import { PRICE_SLIDER } from '../components/filtersContainer/constants';

const defaultAppliedState = {
  officeTypes: [],
  amenities: [],
  services: [],
  price: {
    min: PRICE_SLIDER.minValue,
    max: PRICE_SLIDER.maxValue,
  },
  workHours: {
    from: '00',
    until: '00',
  },
  date: {},
  capacity: [],
  radius: 0,
  rating: 0,
  industries: [],
  healthMeasures: [],
  premiumSupplier: null,
};

const defaultState = Immutable.fromJS({
  mode: 'bar',
  applied: defaultAppliedState,
  amenitiesList: [],
  industryList: [],
  units: {},
});

const filters = (state = defaultState, action) => {
  let newState;
  switch (action.type) {
    case FETCH_ADVANCED_SEARCH_DATA:
      newState = state
        .set('amenitiesList', Immutable.fromJS(action.data.amenitiesList))
        .set('industryList', Immutable.fromJS(action.data.industryList))
        .set('units', Immutable.fromJS(action.data.units));
      break;
    case ON_PRICE_CHANGE:
      newState = state.setIn(['applied', 'price'], action.value);
      break;
    case ON_RATING_CHANGE:
      newState = state.setIn(['applied', 'rating'], action.value);
      break;
    case ON_CAPACITY_CHANGE:
      newState = state.setIn(['applied', 'capacity'], action.value);
      break;
    case ON_OFFICE_TYPE_CHANGE:
      newState = state.updateIn(['applied', 'officeTypes'], arr => {
        let arrJS = arr.toJS();
        arrJS = action.checked ? [action.value] : [];
        return Immutable.fromJS(arrJS);
      });
      if (action.value === 1) {
        newState = newState.setIn(['applied', 'capacity'], Immutable.fromJS([]));
      }
      break;
    case ON_AMENITIES_CHANGE:
      newState = state.updateIn(['applied', 'amenities'], arr => {
        return action.checked ? arr.push(action.value) : arr.pop(action.value);
      });
      break;
    case ON_SERVICES_CHANGE:
      newState = state.updateIn(['applied', 'services'], arr => {
        return action.checked ? arr.push(action.value) : arr.pop(action.value);
      });
      break;
    case ON_HEALTH_MEASURES_CHANGE:
      newState = state.updateIn(['applied', 'healthMeasures'], arr => {
        return action.checked
          ? arr.push(action.value)
          : arr.filter(function(value) {
              return value !== action.value;
            });
      });
      break;
    case ON_PREMIUM_SUPPLIER_CHANGE:
      if (action.checked) {
        newState = state.setIn(['applied', 'premiumSupplier'], action.value);
      } else {
        newState = state.setIn(['applied', 'premiumSupplier'], null);
      }
      break;
    case ON_INDUSTRIES_CHANGE:
      newState = state.updateIn(['applied', 'industries'], arr => {
        const itemIndex = arr.findIndex(item => item.get('value') === action.value.get('value'));
        return action.checked ? arr.push(action.value) : arr.delete(itemIndex);
      });
      break;
    case ON_WORK_HOURS_FROM_CHANGE:
      newState = state.setIn(['applied', 'workHours', 'from'], action.value);
      break;
    case ON_WORK_HOURS_UNTIL_CHANGE:
      newState = state.setIn(['applied', 'workHours', 'until'], action.value);
      break;
    case ON_RESET_FILTERS:
      newState = state.set('applied', Immutable.fromJS(defaultAppliedState)).set('mode', Immutable.fromJS('bar'));
      break;
    case ON_APPLY_FILTERS:
      newState = state.set('mode', Immutable.fromJS('applied'));
      break;
    case ON_CLOSE_SEARCHBOX:
      newState = state.set('mode', Immutable.fromJS('applied'));
      break;
    case ON_DATE_CHANGE:
      const dates = { start: action.startDate, end: action.endDate };
      newState = state.setIn(['applied', 'date'], dates);
      break;
    case TOGGLE_MODE:
      newState = state.set('mode', Immutable.fromJS(action.mode));
      break;
    case ON_REMOVE_FILTER:
      if (action.removeType === 'replace') {
        newState = state.setIn([...action.keys], action.value);
      } else {
        newState = state.updateIn(['applied', ...action.keys], arr => {
          const itemIndex = arr.findIndex(item => item === action.value);
          return arr.delete(itemIndex);
        });
      }
      break;

    default:
      newState = state;
  }

  return newState;
};

export default filters;
