import api from '../../components/dashboard/utils/api';
import { verticalSelector } from '../../selectors/vertical-selector';

/*
 * Constants
 */
export const SET_COMMUNITY_ACTIVITY = 'SET_COMMUNITY_ACTIVITY';
export const SET_COMMUNITY_ACTIVITY_IS_LOADING = 'SET_COMMUNITY_ACTIVITY_IS_LOADING';
/*
 * Sync Actions
 */

export const setCommunityActivity = posts => ({
  type: SET_COMMUNITY_ACTIVITY,
  posts,
});

export const setCommunityActivityIsLoading = isLoading => ({
  type: SET_COMMUNITY_ACTIVITY_IS_LOADING,
  isLoading,
});

/*
 * Async Actions
 */
export const fetchCommunityActivity = () => (dispatch, getState) => {
  const selectedVertical = verticalSelector(getState());
  const url = selectedVertical;
  dispatch(setCommunityActivityIsLoading(true));
  api
    .get('/sidebar/community-activity')
    .then(({ data = {} }) => {
      dispatch(setCommunityActivity(data.posts));
    })
    .catch(() => {})
    .finally(() => {
      dispatch(setCommunityActivityIsLoading(false));
    });
};
