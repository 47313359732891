import React, { Component } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import _ from 'lodash';
import { geolocated } from 'react-geolocated';
import { getDistance as geolibGetDistance } from 'geolib';
import MyBookingsPageMenu from '../myBookingsPage/MyBookingsPageMenu';
import Masonry from '../../utils/Masonry';
import Tile from './tile';
import CreateBookingTile from './tile/CreateBookingTile';

// const masonryBreakPoints = [500, 900, 1400, 1920];
const masonryBreakPoints = [870, 1300, 1700, 1900];

class MyMeetings extends Component {
  constructor(props) {
    super(props);
    this.buildTile = this.buildTile.bind(this);
    this.togglePanel = this.togglePanel.bind(this);
  }

  componentDidMount() {
    const { fetchMeetings } = this.props;
    fetchMeetings();
  }

  togglePanel(e) {
    this.setState({ open: !this.state.open });
  }

  getDistance(location) {
    if (this.props.isGeolocationEnabled && this.props.coords !== null) {
      const dist = geolibGetDistance(
        { latitude: _.get(this.props, 'coords.latitude', 0), longitude: _.get(this.props, 'coords.longitude', 0) },
        { latitude: _.get(location, 'latitude', 0), longitude: _.get(location, 'longitude', 0) }
      );

      const distance = dist / 1000;

      return (
        <>
          {distance > 10
            ? distance.toFixed(0)
            : distance
                .toFixed(2)
                .toString()
                .replace('.', window.__env.decimalSeparator)}{' '}
          km away
        </>
      );
    }
    return null;
  }

  buildTile(office) {
    const distanceLabel = this.getDistance(office.location);
    return <Tile office={office} distanceLabel={distanceLabel} />;
  }

  render() {
    const { meetings, translations, isLoading, heroProfile } = this.props;

    if (isLoading) {
      return <div className="Loader" />;
    }

    let meetingTiles = [];
    if (meetings) {
      meetingTiles = Object.values(meetings);
      meetingTiles.push({ newTile: true });
    }

    return (
      <div style={{ height: '100%' }} className="my-meetings">
        <Scrollbars className="styleScroll">
          <a
            onClick={e => {
              e.preventDefault();
              this.props.history.push('/booking');
            }}
            className="myBookings-navigation"
          >
            {_.get(translations, 'meetings.back', 'meetings.back')}
          </a>
          <div className="meetings-container">
            <div className="my-meetings-title">{_.get(translations, 'meetings.sectionTitle', 'meetings.sectionTitle')}</div>
            <MyBookingsPageMenu translations={translations} hero={heroProfile}/>
            <div className="masonry-container">
              {!_.isEmpty(meetings) ? (
                <Masonry brakePoints={masonryBreakPoints} break>
                  {meetingTiles.map(office => this.buildTile(office))}
                </Masonry>
              ) : (
                <CreateBookingTile
                  title={_.get(translations, 'myBookings.startNewMeeting', 'START  new meeting')}
                  subtitle={_.get(
                    translations,
                    'myBookings.chooseYourLocation',
                    'Why not choose your work location, avoid traffic and explore the true meaning of flexible work?'
                  )}
                  buttonText={_.get(translations, 'myBookings.startNewMeeting', 'START  new meeting')}
                />
              )}
            </div>
          </div>
        </Scrollbars>
      </div>
    );
  }
}

export default geolocated({
  positionOptions: {
    enableHighAccuracy: true,
  },
  userDecisionTimeout: 5000,
})(MyMeetings);
