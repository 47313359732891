import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withRouter, useHistory } from 'react-router-dom';
import classnames from 'classnames';
import Truncate from 'react-truncate';
import cvImage from '../../../../../assets/images/cv-image.png';
import blockIncompleteProfileAction from '../../../../../utils/blockIncompleteProfileAction';

class SubmissionForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      fileContent: null,
      motivation: null,
      video_resume: null,
      formErrors: {},
    };
    this.fileUploadInputRef = React.createRef();

    this.triggerUpload = this.triggerUpload.bind(this);
    this.handleUpload = this.handleUpload.bind(this);
    this.removeUploadedFile = this.removeUploadedFile.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }

  triggerUpload() {
    this.fileUploadInputRef.current.click();
  }

  handleUpload(e) {
    this.setState({ file: e.target.files[0].name, fileContent: e.target.files[0] });
  }

  removeUploadedFile() {
    this.setState({ file: null });
  }

  handleChange(event) {
    switch (event.target.name) {
      case 'file':
        this.setState({ cv: event.target.value });
        break;
      case 'motivation':
        this.setState({ motivation: event.target.value });
        break;
      case 'video_resume':
        this.setState({ video_resume: event.target.value });
        break;
      default:
        break;
    }
  }

  validateFields() {
    // clear form errors
    this.setState({ formErrors: {} });

    const errors = {};
    const { file, motivation } = this.state;
    if (!file) {
      errors.cv = ['Required'];
    }
    if (!motivation) {
      errors.motivation = ['Required'];
    }
    if (Object.keys(errors).length) {
      this.setState({ formErrors: errors });
      return false;
    }

    return true;
  }

  submitForm() {
    if (this.validateFields() === false) {
      return false;
    }
    const { saveSubmission, data } = this.props;
    const { motivation, video_resume, fileContent } = this.state;
    const articleId = _.get(data, 'id', '');
    const type = _.get(data, 'type', '');
    const formData = {
      cv: fileContent,
      motivation,
      video_resume,
      id: articleId,
      type,
    };
    return saveSubmission(formData);
  }

  renderUploadBlock() {
    return (
      <>
        <button type="button" className="dashboard-button dashboard-button--border" onClick={this.triggerUpload}>
          Choose file
        </button>
        <div className="media-form__no-file">No file selected</div>
      </>
    );
  }

  renderSelectedFile() {
    const { file } = this.state;
    return (
      <>
        <div className="media-form__left">
          <img src={cvImage} alt="CV" />
        </div>
        <div className="media-form__right">
          <div className="media-form__cv-title">
            <div className="media-form_selected-file">
              <Truncate lines={1} ellipsis={<>...</>}>
                {file}
              </Truncate>
            </div>
          </div>
          <div className="media-form__buttons">
            <button
              type="button"
              className="dashboard-button dashboard-button--border media-form__re-upload-btn"
              onClick={this.triggerUpload}
            >
              RE-upload
            </button>
            <button
              type="button"
              className="dashboard-button dashboard-button--border media-form__delete-btn"
              onClick={this.removeUploadedFile}
            >
              REMOVE
            </button>
          </div>
        </div>
      </>
    );
  }

  renderResponse(jobTitle) {
    return (
      <div className="media-form__success-title" id="apply-form">
        <h4>Thank you for applying!</h4>
        <p className="media-form__success-text">
          You have applied to <span>{jobTitle}</span> successfully.
        </p>
      </div>
    );
  }

  render() {
    const { data, isSubmited, errors, history } = this.props;
    const { file, formErrors } = this.state;
    const jobTitle = _.get(data, 'jobTitle', '');
    const jobLabel = _.get(data, 'label', '');
    const jobPlaceholder = _.get(data, 'placeholder', '');
    const jobMotivationTitle = _.get(data, 'motivationTitle', '');
    const fileContainer = file ? this.renderSelectedFile() : this.renderUploadBlock();
    const combinedErrors = { ...formErrors, ...errors };

    const applied = _.get(data, 'applied');
    const cvError = combinedErrors.cv && combinedErrors.cv[0];
    const motivationError = combinedErrors.motivation;
    const videoError = combinedErrors.video_resume;

    if (isSubmited) {
      return this.renderResponse(jobTitle);
    }

    if (applied)
      return (
        <div className="media__attachment">
          <h4>You have already applied for this position.</h4>
        </div>
      );

    return (
      <>
        <input type="file" ref={this.fileUploadInputRef} onChange={this.handleUpload} name="cv" className="attachment__input" />
        <div className="media__form" id="apply-form">
          <div className="media-form__title">
            Apply for <span>{jobTitle}</span>
          </div>
          <div className="media-form__upload-text">
            Upload your CV (.pdf, .doc or .docx) <span>(required)</span>
          </div>
          <div className="media-form__upload">{fileContainer}</div>
          {cvError && <div className="form-error-message">{cvError}</div>}
          <div className="media-form__motivation-wrapper">
            <div className="media-form__upload-text">
              {jobMotivationTitle} <span>(required)</span>
            </div>
            <textarea
              name="motivation"
              form="apply-form"
              className={classnames('media-form__motivation media-form__italic-placeholder', {
                'form-error': motivationError,
              })}
              placeholder={jobPlaceholder}
              onChange={this.handleChange}
            />
            {motivationError && <div className="form-error-message">{motivationError}</div>}
          </div>
          <div className="media-form__video-wrapper">
            <div className="media-form__upload-text">Video resume</div>
            <input
              type="text"
              name="video_resume"
              className={classnames('media-form__video media-form__italic-placeholder', {
                'form-error': videoError,
              })}
              placeholder="Paste your link"
              onChange={this.handleChange}
            />
            {videoError && <div className="form-error-message">{videoError}</div>}
          </div>
          <button
            type="button"
            className="button-yellow"
            onClick={() =>
              blockIncompleteProfileAction()
                .then(() => {
                  history.push({ pathname: '/account/profile/incomplete' });
                })
                .catch(() => {
                  this.submitForm();
                })
            }
          >
            {jobLabel}
          </button>
        </div>
      </>
    );
  }
}

SubmissionForm.propTypes = {
  data: PropTypes.shape({}).isRequired,
  isSubmited: PropTypes.bool.isRequired,
  errors: PropTypes.shape({}).isRequired,
  saveSubmission: PropTypes.func.isRequired,
};

export default withRouter(SubmissionForm);
