import { connect } from 'react-redux';
import MyParkingBookings from './MyParkingBookings';
import { fetchParkingBookings } from '../../../../../actions/whiteLabel/my-parkings';
import { parkingBookingsIsLoadingSelector, parkingBookingsSelector } from '../../../../../selectors/whiteLabel/parkingBookings';

const mapStateToProps = state => ({
  translations: state.translations,
  heroProfile: state.global.get('heroProfile'),
  isLoading: parkingBookingsIsLoadingSelector(state),
  parkingBookings: parkingBookingsSelector(state),
});

const mapDispatchProps = dispatch => ({
  fetchParkingBookings: () => dispatch(fetchParkingBookings()),
});

export default connect(mapStateToProps, mapDispatchProps)(MyParkingBookings);
