import React from 'react';
import _ from 'lodash';
import classnames from 'classnames';

const BookingDetails = props => {
  const { translations, meeting } = props;

  const houseRules = _.get(meeting, 'houseRules.houserules_file', []).concat(_.get(meeting, 'houseRules.houserules_checkbox', []));

  const amenities = _.get(meeting, 'amenitiesAndServices.amenity', []);
  const services = _.get(meeting, 'amenitiesAndServices.service', []);
  const equipments = _.get(meeting, 'equipments.equipments', []);
  const features = _.get(meeting, 'features.features', []);

  const houseRulesList = houseRules.map(rule => (
    <div
      key={rule.id + rule.file}
      className={classnames({ attachments: rule.file, 'rule-available': !rule.file, 'rule-unavailable': rule.file })}
    >
      {_.capitalize(rule.name)}
      {rule.file && (
        <div className="">
          <a target="_blank" rel="noopener noreferrer" data-label={_.get(translations, 'chat.view', 'chat.view')} className="office-rules attachments" href={rule.url} />
        </div>
      )}
    </div>
  ));

  const amenitiesList = amenities.map(amenity => (
    <div className="amenity-available" key={`amenity-${amenity.name}`}>
      <div className="label">{amenity.name}</div>
    </div>
  ));

  const servicesList = services.map(service => (
    <div className="amenity-available" key={`service-${service.name}`}>
      <div className="label">{service.name}</div>
    </div>
  ));

  const equipmentsList = equipments.map(equipment => (
    <div className="amenity-available" key={`equipment-${equipment.name}`}>
      <div className="label">{equipment.name}</div>
    </div>
  ));

  const featuresList = features.map(feature => (
    <div className="" key={`feature-${feature.name}`}>
      <div className="rule-available">{feature.name}</div>
    </div>
  ));

  return (
    <div className="meeting-details__booking-details-container">
      {!_.isEmpty(houseRulesList) && (
        <div className="booking-office-services">
          <div className="office-booked-title">{_.get(translations, 'bookingPage.houseRules', 'bookingPage.houseRules')}</div>
          <div className="spacer-5" />
          <div className="booking-office-rules">{houseRulesList}</div>
        </div>
      )}
      {!_.isEmpty(featuresList) && (
        <div className="booking-office-services">
          <div className="office-booked-title">{_.get(translations, 'whiteLabel.features', 'whiteLabel.features')}</div>
          <div className="spacer-5" />
          <div className="booking-office-rules">{featuresList}</div>
        </div>
      )}
      {(!_.isEmpty(amenities) || !_.isEmpty(services)) && (
        <div className="booking-office-services">
          <div className="office-booked-title">
            {_.get(translations, 'bookingPage.bookingAmenitiesAndServices', 'bookingPage.bookingAmenitiesAndServices')}
          </div>
          <div className="spacer-5" />
          {amenitiesList}
          {servicesList}
        </div>
      )}
      {!_.isEmpty(equipments) && (
        <div className="booking-office-services">
          <div className="office-booked-title">
            {_.get(translations, 'bookingPage.bookingEquipments', 'bookingPage.bookingEquipments')}
          </div>
          <div className="spacer-5" />
          {equipmentsList}
        </div>
      )}
    </div>
  );
};

export default BookingDetails;
