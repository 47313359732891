/**
 * @class SelectBox
 * @module SelectBox
 */
import React from 'react';
import ReactDOM from 'react-dom';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Scrollbars } from 'react-custom-scrollbars';
import _ from 'lodash';

import './css/index.scss';

class SelectBox extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      triggered: false,
      disabled: (typeof props.disabled !== 'undefined') ? props.disabled : false,
      selected: ((typeof props.selectedItems !== 'undefined') && props.selectedItems.toJS().length > 0) ? props.selectedItems : props.initialValue,
      id: _.uniqueId('selectBox_'),
      showOnTop: false,
      listHeight: 0
    };

    this.renderThumbVertical = this.renderThumbVertical.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      disabled: nextProps.disabled,
      selected: nextProps.selectedItems
    });
  }

  /**
   * Computes the label to display
   * @method getDisplayLabel
   * @returns {string}
   */
  getDisplayLabel() {
    let label;
    if (!this.state.selected.size) {
      return this.props.empty;
    }

    label = this.state.selected.getIn([0, 'label']);
    
    if (this.state.selected.size > 1) {
      let remaining = this.state.selected.size - 1;
      let plusXmoreLabel = this.props.trans.formatString(this.props.trans.accountProfile.plusXmore, [remaining]);
      label = remaining === 1 ? `${label}, ${this.props.trans.accountProfile.plus1more}` :  `${label}, ${plusXmoreLabel}`;
    }
    return label;
  }

  /**
   * Handles click action on a option
   * @method onOptionClick
   * @param value
   * @returns {Function}
   */
  onOptionClick = (value) => {
    return (event) => {
      if (value) {
        this.updateSelected(value);
      }

      if (!this.props.multiple) {
        this.toggle();
      }
    }
  }

  /**
   * Checks if an item is selected
   * @method isSelected
   * @param selectedItem
   * @returns {boolean}
   */
  isSelected = (selectedItem) => {
    return (typeof this.state.selected !== 'string') ? this.state.selected.findIndex(item => item.get('value') === selectedItem.get('value')) !== -1 : false;
  }

  /**
   * Updates current selection
   * @method updateSelected
   * @param selectedItem
   */
  updateSelected = (selectedItem) => {
      const valueIndex = (typeof this.state.selected !== 'string') ? this.state.selected.findIndex(item => item.get('value') === selectedItem.get('value')) : false;
      let newSelected;

      if (this.props.multiple) {
        // Multiple Select - value does not exists
        if (valueIndex === -1) {
          if (!this.props.multiple) {
            newSelected = Immutable.fromJS([selectedItem]);
          } else {
            newSelected = this.state.selected.push(selectedItem);
          }
        } else {
          if (this.props.multiple) {
            newSelected = this.state.selected.delete(valueIndex);
          }
        }
      } else {
        // Simple Select - overwrite value
        newSelected = Immutable.fromJS([selectedItem]);
      }

    this.setState({ selected: newSelected });

    if (this.props.onChange) {
      this.props.multiple ? this.props.onChange(selectedItem, (valueIndex === -1)) : this.props.onChange(newSelected);
    }

  }

  /**
   * Method to handle clicking on body outside selectboxOptions. To refactor this
   * @method closeOptions
   * @param event
   */
  closeOptions = (event) => {
    if (!event.target.classList.contains('selectBox-option') &&
      !event.target.classList.contains(`select-box__thumb-${this.state.id}`)
    ) {
      const id = `select-box__options-wrapper-${this.state.id}`;
      for (let i = 0; i < event.path.length; i++) {
        if (event.path[i].id === id) {
          return;
        }
      }
      this.toggle(false);
    }
  }

  /**
   * Handles click on SelectBox display label
   * @method onToggleClick
   * @param event
   */
  onToggleClick = (event) => {
    event.preventDefault();
    this.toggle();
  }

  /**
   * Toggles the open/close status of the dropdown
   * @method toggle
   * @param newState
   */
  toggle = (newState) => {
    if (!this.state.triggered || newState) {
      document.body.addEventListener('click', this.closeOptions);
      document.body.addEventListener('touchstart', this.closeOptions);
    } else {
      document.body.removeEventListener('click', this.closeOptions);
      document.body.removeEventListener('touchstart', this.closeOptions);
    }
    this.setState({ triggered: newState !== undefined ? newState : !this.state.triggered }, () => {
      if(this.state.triggered)
      {
        let documentHeight = document.documentElement.clientHeight;
        let selectBxHeight = ReactDOM.findDOMNode(this).children[1].children[0].offsetHeight;
        let offsetTop = document.getElementById(this.state.id).getBoundingClientRect().top;
        if(selectBxHeight + offsetTop > documentHeight - 100)
        {
          this.setState({showOnTop:true, listHeight:selectBxHeight});
        }
        else{
          this.setState({showOnTop:false});
        }
      }
    });
  }

  /**
   * Computes and renders available options
   * @method getOptionsDIsplay
   * @returns {*}
   */
  getOptionsDisplay = () => {
    if (!this.state.triggered) {
      return null;
    }

    let {options, grouped, groupedBy, groupedByValues} = this.props;
    let tempOptions     = options.toJS();
    let groupedOptions  = [];
    let optionsList;

    if(grouped === true && groupedBy !== undefined
      && groupedByValues !== undefined && groupedByValues.length > 0 && options !== null)
    {
      let usedGroups = [];
      groupedByValues.map((groupedByValue) => {
        tempOptions.map((tempOption) => {
          if(tempOption[groupedBy] === groupedByValue.id)
          {
            if(!usedGroups.includes(groupedByValue.id))
            {
              usedGroups.push(groupedByValue.id);
              groupedOptions.push(Immutable.fromJS({groupLabel: groupedByValue.name}));
            }
            groupedOptions.push(Immutable.fromJS(tempOption));
          }
          return null;
        });
        return null;
      });

      groupedOptions.push(Immutable.fromJS({groupLabel: 'General'}));
      tempOptions.map((tempOption, index) => {
        if(tempOption[groupedBy] === null)
        {
          groupedOptions.push(Immutable.fromJS(tempOption));
        }
        return null;
      });

      if((this.props.otherOption === true))
      {
        groupedOptions.push(Immutable.fromJS({value: 'other', label: this.props.trans.accountProfile.other}));
      }

      let groupedOpts = groupedOptions.map((option, index) => {

        if(option.get('groupLabel') !== undefined)
        {
          return (
            <li className="dropdown-header"
                key={ 'group' + index }
                >
              { option.get('groupLabel') }
            </li>
          );
        }

        const optionClassName = classnames({
          'selectBox-option': true,
          '--selected': this.isSelected(option)
        });

        return (
          <li className={ optionClassName }
              key={ option.get('value') }
              onClick={ this.onOptionClick(option) }>
            { option.get('label') }
          </li>
        );
      });

      optionsList = groupedOpts;
    }
    else
    {
        if((this.props.otherOption === true))
        {
          let newOptions = options.toJS();
          newOptions.push(Immutable.fromJS({value: 'other', label: this.props.trans.accountProfile.other}));
          options = Immutable.fromJS(newOptions);
        }
        optionsList = options.map(option => {
          const optionClassName = classnames({
            'selectBox-option': true,
            '--selected': this.isSelected(option)
          });

          return (
            <li className={ optionClassName }
                key={ option.get('value') + option.get('label') }
                onClick={ this.onOptionClick(option) }>
              { option.get('label') }
            </li>
          );
      });
    }
    
    return (
      <div style={{height: this.props.options.size >= 5 ? '250px' : (this.props.options.size * 48 + 'px'), width: '100%', position: 'absolute', background: '#fff', zIndex: '100',     border: 'solid 1px #cdd1d4', marginTop: this.state.showOnTop === true ? '-'+(this.state.listHeight+48)+'px' : '0'}}
      id={`select-box__options-wrapper-${this.state.id}`}
      >
      <Scrollbars renderThumbVertical={this.renderThumbVertical}>
      <div className="selectBox-optionsContainer">
        <ul className="selectBox-options" onScroll={ this.killScroll } style={{top: '-1px'}}>
          { optionsList }
        </ul>
      </div>
      </Scrollbars>
      </div>
    );
  }

  renderThumbVertical() {
    return (
      <div 
      id={`select-box__thumb-${this.state.id}`}
      style={{
        position: 'relative', 
        display: 'block', 
        width: '120%', 
        cursor: 'pointer', 
        borderRadius: 'inherit', 
        backgroundColor: 'rgba(0, 0, 0, 0.2)', 
        height: '30px', 
        transform: 'translateY(0px)',
      }} 
      />
    )
  }

  render () {

    const selectBoxClass = classnames({
      'selectBox': true,
      '--triggered': this.state.triggered,
      '--multiple': this.props.multiple,
    //  '--selected': this.state.selected.size,
      '--classic': this.props.classic,
      [this.props.className]: this.props.className
    });
    
    if(this.props.trans === null) return null;

    return (
      <div className={ selectBoxClass } id={ this.state.id }>
        <div className="selectBox-content" onClick={ this.state.disabled ? null : this.onToggleClick } style={{ background: this.state.disabled ? '#cdd1d4' : 'inherit' }}>
          <div className="selectBox-labelContainer">
            <div className="selectBox-label">
              { this.getDisplayLabel() }
            </div>
          </div>
        </div>
        { this.getOptionsDisplay() }
      </div>
    );
  }
}

export default SelectBox;

SelectBox.propTypes = {
  /**
   * The initial selection made
   * @property initial
   * @type {Immutable.List<{ label, value }>}
   */
  initial: PropTypes.object,
  /**
   * The text to display when no selection is made
   * @property empty
   * @type {string}
   */
  empty: PropTypes.string,
  /**
   * Flag to enable/disable multiple selections
   * @property multiple
   * @type {boolean}
   */
  multiple: PropTypes.bool,
  /**
   * List of available options to display in dropdown
   * @property options
   * @type {Immutable.List<{ label, value }>}
   */
  options: PropTypes.object,
  /**
   * Callback method to use when selection is changed
   * @property onSelect
   * @type {function}
   */
  onSelect: PropTypes.func,
  /**
   * Boolean flag to decide to render a default dropdown or a custom one
   * @property onSelect
   * @type {function}
   */
  classic: PropTypes.bool
};

SelectBox.defaultProps = {
  initialValue: Immutable.fromJS([]),
  options: Immutable.fromJS([]),
  empty: 'Select Option',
  multiple: false,
  classic: false
};
