import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import classnames from 'classnames';
import { withRouter } from 'react-router-dom';
import { allowedImages } from '../../utils/config';
import sizedImage from '../../../../utils/imageHelper';

class EditCommunityForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      communityLogo: null,
      description: null,
      fileContent: null,
      formErrors: {},
    };
    this.fileUploadInputRef = React.createRef();

    this.triggerUpload = this.triggerUpload.bind(this);
    this.handleUpload = this.handleUpload.bind(this);
    this.removeUploadedFile = this.removeUploadedFile.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.handleSubmitButtonStatus = this.handleSubmitButtonStatus.bind(this);
  }

  componentDidMount() {
    const { community, clearErrors } = this.props;
    clearErrors();
    this.setState({
      description: _.get(community, 'description', ''),
    });
  }

  triggerUpload() {
    this.fileUploadInputRef.current.click();
  }

  handleUpload(e) {
    const uploadedImage = e.target.files[0];
    if (!allowedImages.includes(uploadedImage.type)) {
      const { formErrors } = this.state;
      const { translations } = this.props;
      formErrors.invalidUploadedLogo = _.get(translations, 'form.invalidFileType', 'form.invalidFileType');
      this.setState({ communityLogo: null });
      this.setState({ formErrors });
    } else {
      this.setState({ communityLogo: URL.createObjectURL(uploadedImage), fileContent: uploadedImage });
    }
  }

  removeUploadedFile() {
    this.setState({ communityLogo: null });
  }

  handleChange(event) {
    switch (event.target.name) {
      case 'file':
        this.setState({ communityLogo: event.target.value });
        break;
      case 'description':
        this.setState({ description: event.target.value });
        break;
      default:
        break;
    }
  }

  handleSubmitButtonStatus() {
    const { description } = this.state;
    return !!description;
  }

  validateFields() {
    // clear form errors
    this.setState({ formErrors: {} });

    const errors = {};
    const { description } = this.state;

    if (!description) {
      errors.description = ['Required'];
    }

    if (Object.keys(errors).length) {
      this.setState({ formErrors: errors });
      return false;
    }

    return true;
  }

  submitForm(communityId) {
    if (this.validateFields() === false) {
      return false;
    }
    const { description, fileContent } = this.state;
    const { translations, updateCommunity, showCommunityEditModal, setShowEditCommunityModal, showCommunityPageToast } = this.props;
    const formData = {
      description,
      image: fileContent,
    };

    updateCommunity(communityId, formData).then(url => {
      setShowEditCommunityModal(!showCommunityEditModal);
      showCommunityPageToast(_.get(translations, 'toast.communityEdited', 'toast.communityEdited'));
    });
    return null;
  }

  renderUploadBlock() {
    const { translations, community } = this.props;
    const { formErrors } = this.state;
    const errorRequired = _.get(formErrors, 'communityLogo', null);
    const errorFileType = _.get(formErrors, 'invalidUploadedLogo', null);
    const error = errorFileType || errorRequired;
    const communityImage = _.get(community, 'image', null);

    return (
      <>
        <img
          className="community-logo"
          src={sizedImage(communityImage, 60, 0)}
          alt={_.get(translations, 'editForm.companyLogo', 'editForm.companyLogo')}
        />
        <div
          className={classnames('community-logo-content', 'community-logo__field', {
            'form-error': error,
          })}
        >
          <span className="requirements">{_.get(translations, 'editForm.pictureDescription', 'pictureDescription')}</span>
          <div className="community-form__image-buttons-wrapper">
            <button type="button" className="community-form__upload-button" onClick={this.triggerUpload}>
              {_.get(translations, 'editForm.uploadButton', 'editForm.uploadButton')}
            </button>
          </div>
          {error && <div className="form-error-message">{error}</div>}
        </div>
      </>
    );
  }

  renderSelectedFile() {
    const { communityLogo } = this.state;
    const { translations } = this.props;
    return (
      <>
        <div className="community-logo" style={{ backgroundImage: `url("${communityLogo}")` }} />
        <div className="community-logo-content">
          <span className="requirements">{_.get(translations, 'editForm.pictureLabel', 'editForm.pictureLabel')}</span>
          <div className="community-form__image-buttons-wrapper">
            <button type="button" className="community-form__upload-button" onClick={this.triggerUpload}>
              {_.get(translations, 'editForm.reuploadButton', 'editForm.reuploadButton')}
            </button>
            <span className="image-buttons__dot">•</span>
            <button type="button" className="community-form__upload-button community-form__remove-button" onClick={this.removeUploadedFile}>
              {_.get(translations, 'editForm.removeButton', 'editForm.removeButton')}
            </button>
          </div>
        </div>
      </>
    );
  }

  render() {
    const { formErrors, description, communityLogo } = this.state;
    const { translations, closeForm, errors, isLoading, community, callbackDeleteCommunity } = this.props;
    const combinedErrors = { ...formErrors, ...errors };
    const descriptionError = combinedErrors.description;
    const fileContainer = communityLogo ? this.renderSelectedFile() : this.renderUploadBlock();
    const buttonStatus = this.handleSubmitButtonStatus();
    const audienceType = _.get(community, 'audienceType', '');
    const communityId = _.get(community, 'id', null);
    const verticals = _.get(community, 'verticals', []);

    return (
      <div className="community-form">
        <input
          type="file"
          ref={this.fileUploadInputRef}
          onChange={this.handleUpload}
          onClick={event => {
            /* eslint no-param-reassign: "error" */
            event.target.value = null;
          }}
          name="image"
          className="attachment__input"
        />
        <div className="community-form__image">{fileContainer}</div>
        <div className="community-form__field">
          <div className="field-label edit-community_field-label">
            {_.get(translations, 'editForm.communityName', 'editForm.communityName')}
            <span>({_.get(translations, 'editForm.cantBeEdited', 'editForm.cantBeEdited')})</span>
          </div>
          <div className="edit-community__field-text--not-editable">{_.get(community, 'name')}</div>
        </div>
        <div className="community-form__field community-form__description">
          <div className="field-label">
            {_.get(translations, 'editForm.communityDescription', 'editForm.communityDescription')}{' '}
            <span>({_.get(translations, 'editForm.requiredField', 'editForm.requiredField')})</span>
          </div>
          <textarea
            name="description"
            form="apply-form"
            className={classnames('community-form__motivation community-form__italic-placeholder', {
              'form-error': descriptionError,
            })}
            placeholder={_.get(translations, 'editForm.communityDescriptionPlaceholder', 'editForm.communityDescriptionPlaceholder')}
            onChange={this.handleChange}
            value={description || ''}
          />
          {descriptionError && <div className="form-error-message">{descriptionError}</div>}
        </div>

        <div className="community-form__field">
          <div className="field-label edit-community_field-label">
            {_.get(translations, 'editForm.communityAudience', 'editForm.communityAudience')}
            <span>({_.get(translations, 'editForm.cantBeEdited', 'editForm.cantBeEdited')})</span>
          </div>
          <div className="edit-community__field-text--not-editable">
            {_.get(translations, `editForm.${audienceType}-Audience`, 'editForm.audienceType')}
          </div>
          {!_.isEmpty(verticals) ? <div className="edit-community__verticals">{verticals.join(', ')}</div> : null}
        </div>

        <div className="community-form__actions community-edit-form__actions">
          <button
            type="button"
            onClick={() => callbackDeleteCommunity()}
            id="edit-community__delete-button"
            className="community-confirmation-modal__delete-button"
          >
            {_.get(translations, 'editForm.deleteButton', 'editForm.deleteButton')}
          </button>
          <div className="community-form__right-buttons">
            <button
              type="button"
              onClick={closeForm}
              id="edit-community__cancel-button"
              className="community-confirmation-modal__cancel-button"
            >
              {_.get(translations, 'editForm.cancelButton', 'editForm.cancelButton')}
            </button>
            {!isLoading && (
              <button
                id="edit-community__save-button"
                type="button"
                className={classnames('button-yellow community__button-submit', {
                  'button-disabled': !buttonStatus,
                })}
                onClick={() => this.submitForm(communityId)}
                disabled={!buttonStatus}
              >
                {_.get(translations, 'editForm.saveCommunityButton', 'form.saveCommunityButton')}
              </button>
            )}
            {isLoading && <span className="community__loader edit-community__loader" />}
          </div>
        </div>
      </div>
    );
  }
}

EditCommunityForm.propTypes = {
  updateCommunity: PropTypes.func.isRequired,
  closeForm: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  errors: PropTypes.shape({}),
  translations: PropTypes.shape({}),
  community: PropTypes.shape({}).isRequired,
  clearErrors: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

EditCommunityForm.defaultProps = {
  errors: {},
  translations: {},
};

export default withRouter(EditCommunityForm);
