import store from './store';

const intercomLoaded = false;
let storeUnsubscribe;

const unsubscribe = () => {
  if (typeof storeUnsubscribe === 'function') {
    storeUnsubscribe();
  }
};

const intercomLoadOnProfileFetch = () => {
  const { account } = store.getState();
  const profile = account.get('profile');
  if (profile && typeof profile.toJS === 'function' && !intercomLoaded && window.Intercom && typeof window.Intercom === 'function') {
    const { id, firstName, lastName, email, heroIntercomHash } = profile.toJS();
    const intercomSettings = {
      app_id: window.__env.intercomAppId,
      user_id: `hero_${id}`,
      name: `${firstName} ${lastName}`, // Full name
      email: email, // Full name
      user_hash: heroIntercomHash,
    };
    if (typeof firstName === 'string' && typeof lastName === 'string') {
      intercomSettings.name = `${firstName} ${lastName}`;
    }
    window.Intercom('boot', intercomSettings);
    unsubscribe();
  }
};

export default () => {
  storeUnsubscribe = store.subscribe(intercomLoadOnProfileFetch);
};

export const logout = () => {
  window.Intercom('shutdown');
};
