import { connect } from 'react-redux';

import _ from 'lodash';
import toJS from '../../utils/to-js';
import { translations } from '../../../../selectors/translations';
import {
  newsSelector,
  newsCountSelector,
  loadMoreOffsetSelector,
  newsIsLoadingSelector,
  newsIsLoadingMoreSelector,
  hasMoreSelector,
} from '../../../../selectors/dashboard/news';
import { fetchFirstNews, fetchNews } from '../../../../actions/dashboard/news';
import News from './News';

const mapStateToProps = state => ({
  news: newsSelector(state),
  newsCount: newsCountSelector(state),
  loadMoreOffset: loadMoreOffsetSelector(state),
  isLoading: newsIsLoadingSelector(state),
  isLoadingMore: newsIsLoadingMoreSelector(state),
  hasMore: hasMoreSelector(state),
  translations: _.get(translations(state), 'dashboard', {}),
  translationsOpenAuth: _.get(translations(state), 'openAuth', {}),
});

const mapDispatchToProps = dispatch => ({
  fetchFirstNews: () => dispatch(fetchFirstNews()),
  fetchNews: () => dispatch(fetchNews()),
});

export default connect(mapStateToProps, mapDispatchToProps)(toJS(News));
