import Immutable from 'immutable';
import {PAGE_VIEW_SWITCH} from './../actions/homeActions';

const defaultState = Immutable.fromJS({
  pageView: 'map'
});

const home = (state = defaultState, action) => {
  let newState;

  switch (action.type) {
    case PAGE_VIEW_SWITCH:
      newState = state.set('pageView', action.view);
      break;
    default:
      newState = state;
  }
  return newState;
};

export default home;
