export const companySelector = state => {
    return state.mediaCompanyProfile.get('company');
};

export const companyLogoSelector = state => {
    return state.mediaCompanyProfile.get('companyLogo');
};

export const isLoadingSelector = state => {
    return state.mediaCompanyProfile.get('isLoading');
};
