import { connect } from 'react-redux';
import FilterContainer from './filtersContainer';

const mapStateToProps = state => {
  return {
    officeTypesData: state.location.get('officeTypesData'),
  };
};
const mapDispatchProps = dispatch => {};
export default connect(mapStateToProps, mapDispatchProps)(FilterContainer);
