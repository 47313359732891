import api from '../../components/dashboard/utils/api';

import { resetNews, fetchNews } from './news';

/*
 * Constants
 */
export const SET_MEDIA_FILTERS = 'SET_MEDIA_FILTERS';
export const SET_MEDIA_FILTER = 'SET_MEDIA_FILTER';
export const SET_MEDIA_FILTERS_IS_LOADING = 'SET_MEDIA_FILTERS_IS_LOADING';

/*
 * Sync Actions
 */
export const setMediaFilters = mediaFilters => ({
  type: SET_MEDIA_FILTERS,
  mediaFilters,
});

export const setMediaFilter = mediaFilter => ({
  type: SET_MEDIA_FILTER,
  mediaFilter,
});

export const setMediaFiltersIsLoading = isLoading => ({
  type: SET_MEDIA_FILTERS_IS_LOADING,
  isLoading,
});

/*
 * Async Actions
 */
export const changeMediaFilter = mediaFilter => dispatch => {
  dispatch(setMediaFilter(mediaFilter));
  dispatch(resetNews());
  dispatch(fetchNews());
};

export const fetchMediaFilters = () => dispatch => {
  dispatch(setMediaFiltersIsLoading(true));
  api
    .get('/media/filters')
    .then(({ data = {} }) => {
      if (data.data) {
        dispatch(setMediaFilters(data.data));
      }
    })
    .catch(() => {})
    .finally(() => {
      dispatch(setMediaFiltersIsLoading(false));
    });
};
