/**
 * Renders a single office box
 *
 * Usage:
 * <OfficeBox
 *   item={ item }
 *   showConnections={ true }
 * />
 *
 * @class OfficeBox
 * @module OfficeBox
 */
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link, withRouter } from 'react-router-dom';

import _ from 'lodash';
import moment from 'moment-timezone';
import DefaultOfficeInfo from './defaultOfficeInfo';
import BookedOfficeInfo from './bookedOfficeInfo';
import FullBookingInfo from './fullBookingInfo';
import EventBox from './eventBox';
import sizedImage from '../../../utils/imageHelper';

import './css/index.scss';
import healthSafetyMeasuresIcon from '../../../assets/svg/health-measures/health-safety-measures.svg';
import temperatureControlIcon from '../../../assets/svg/health-measures/temperature-control-in-place.svg';
import premiumPartnerIcon from '../../../assets/svg/map/premium-partner.svg';
import buildLocationUrl from '../../../utils/buildLocationUrl';

class OfficeBox extends React.Component {
  getOfficeInfo() {
    if (this.props.fullView) {
      return (
        <FullBookingInfo
          geoLocationCoords={this.props.geoLocationCoords}
          item={this.props.item}
          fullView={this.props.fullView}
          className={this.props.fullView ? '--fullView' : ''}
          trans={this.props.trans}
        />
      );
    }

    if (this.props.content === 'bookings') {
      return (
        <BookedOfficeInfo
          item={this.props.item}
          myBookings={this.props.myBookings}
          fullView={this.props.fullView}
          className={this.props.fullView ? '--fullView' : ''}
          trans={this.props.trans}
        />
      );
    }

    if (this.props.content === 'events' || this.props.content === 'upcoming-events' || this.props.content === 'active-events') {
      return <EventBox item={this.props.item} content={this.props.content} trans={this.props.trans} />;
    }

    return (
      <DefaultOfficeInfo
        item={this.props.item}
        fullView={this.props.fullView}
        geoLocationCoords={this.props.geoLocationCoords}
        officeTypesData={this.props.officeTypesData}
        showConnections={this.props.showConnections}
      />
    );
  }

  getBoxImage(item) {
    if (this.props.content === 'events' || this.props.content === 'upcoming-events' || this.props.content === 'active-events') {
      return item.get('photo');
    }

    if (item.get('imageUrl') !== undefined) {
      return item.get('imageUrl');
    }
  }

  renderCheckinCheckout() {
    const { history, item, trans } = this.props;
    const items = item.toJS();
    const bookingId = _.get(items, 'bookings[0].id', null);
    const canCheckout = _.get(items, 'bookings[0].canCheckOut', null);
    const canCheckedIn = _.get(items, 'bookings[0].canCheckIn', null);
    const canExtendBooking = _.get(items, 'bookings[0].canExtendHour', null);
    if (canCheckout) {
      return (
        <div className="bookedOffice-checkout">
          <button
            className="main-button bookedOffice-checkinbutton"
            onClick={() => history.push({ pathname: `/booking/${bookingId}`, state: { showConfirmationPopup: 'checkout' } })}
          >
            {_.get(trans, 'notifications.booking.checkout_action', 'notifications.booking.checkout_action')}
          </button>
          {canExtendBooking ? (
            <button
              className="secondary-button bookedOffice-checkinbutton-extend"
              onClick={() => history.push({ pathname: `/booking/${bookingId}`, state: { showConfirmationPopup: 'extend' } })}
            >
              {_.get(trans, 'notifications.booking.extend_action', 'notifications.booking.extend_action')}
            </button>
          ) : null}
        </div>
      );
    }
    if (canCheckedIn)
      return (
        <div className="bookedOffice-checkin">
          <button
            className="main-button bookedOffice-checkinbutton"
            onClick={() => history.push({ pathname: `/booking/${bookingId}`, state: { showConfirmationPopup: 'checkin' } })}
          >
            {_.get(trans, 'notifications.booking.checkin_action', 'notifications.booking.checkin_action')}
          </button>
          <span
            className="bookedOffice-cancelBooking"
            onClick={() => history.push({ pathname: `/booking/${bookingId}`, state: { showConfirmationPopup: 'cancel' } })}
          >
            {_.get(trans, 'notifications.booking.cancel_action', 'notifications.booking.cancel_action')}
          </span>
        </div>
      );
    return null;
  }

  render() {
    const { trans, isWhiteLabel, item, comingUp, firstBooking } = this.props;

    const boxClassName = classnames({
      officeBox: true,
      '--fullView': this.props.fullView,
      [`officeBox-${this.props.direction}`]: this.props.direction,
      [`officeBox-${this.props.content}`]: this.props.content,
    });

    const boxImageClassName = classnames([
      'officeBox-image',
      this.props.content === 'active-events' && this.props.item.get('commingUp') ? 'officeBox-image-event-commingUp' : '',
    ]);
    const timeZone = item.getIn(['bookings', '0', 'timeZone']);
    const startDate = moment.unix(item.toJS().bookings[0].fromDate).set({
      hour: item.toJS().bookings[0].hours.from.hour,
      minute: item.toJS().bookings[0].hours.from.minute,
    });
    const startingBookingString = startDate.format('YYYY-MM-DD HH:mm:ss');
    const startBooking = moment.tz(startingBookingString, 'YYYY-MM-DD HH:mm:ss', timeZone);
    const now = moment.utc().tz(timeZone);

    const office = _.get(this.props.item.toJS(), 'bookings[0].office', this.props.item.toJS());
    const healthMeasuresStamp = _.get(this.props.item.toJS(), 'bookings[0].office.healthMeasuresStamp', false);
    const temperatureControlStamp = _.get(this.props.item.toJS(), 'bookings[0].office.temperatureControlStamp', false);
    const isPremiumPartner = _.get(this.props.item.toJS(), 'bookings[0].office.premiumSupplier', false);

    return (
      <div className={boxClassName}>
        <Link className="officeBox-title items-style1" to={buildLocationUrl(office)} style={{ marginBottom: 0 }}>
          <div
            className={boxImageClassName}
            style={{
              background:
                this.getBoxImage(this.props.item) !== null
                  ? `url("${sizedImage(this.getBoxImage(this.props.item), 417, 320)}")`
                  : '#0e161e',
              backgroundPosition: 'center center',
              position: 'relative',
            }}
          >
            <div className="upper-stamps">
              {comingUp && firstBooking && startBooking.isAfter(now) ? (
                <div className="comingUp-label">{_.get(trans, 'map.comingUpUnavailable', 'COMING UP')}</div>
              ) : (
                ''
              )}
              {isPremiumPartner ? (
                <div className="premium-partner__container">
                  <img
                    src={premiumPartnerIcon}
                    alt={_.get(trans, 'premiumPartner.image_alt', 'Premium partner')}
                    className="premium-partner__icon"
                  />
                  <div className="health__stamps-tooltip health__stamps-tooltip--premium">
                    {_.get(trans, 'premiumPartner.tooltip', 'Premium Partner')}
                  </div>
                </div>
              ) : null}
            </div>

            <div className="health__stamps-container">
              {healthMeasuresStamp ? (
                <div className="health__stamps-container--health">
                  <img
                    src={healthSafetyMeasuresIcon}
                    alt={_.get(trans, 'healthStamps.health_safety_measures_image_alt', 'Health Measures')}
                    className="health__stamp-icon"
                  />
                  <div className="health__stamps-tooltip health__stamps-tooltip--health">
                    {_.get(trans, 'healthStamps.health_safety_measures', 'Health Safety Measures')}
                  </div>
                </div>
              ) : null}
              {temperatureControlStamp ? (
                <div className="health__stamps-container--temperature">
                  <img
                    src={temperatureControlIcon}
                    alt={_.get(trans, 'healthStamps.temperature_control_points_image_alt', 'Temperature control')}
                    className="health__stamp-icon"
                  />
                  <div className="health__stamps-tooltip health__stamps-tooltip--temperature">
                    {_.get(trans, 'healthStamps.temperature_control_points', 'Temperature control points')}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </Link>
        <div className="officeBox-info">
          {this.getOfficeInfo()}
          {isWhiteLabel ? this.renderCheckinCheckout() : null}
        </div>
      </div>
    );
  }
}

OfficeBox.propTypes = {
  /**
   * Immutable map containing an office description
   * @param item
   * @type Immutable.Map
   */
  item: PropTypes.object.isRequired,
  /**
   * Flag which determines if connections are displayed or not
   * @param showConnections
   * @type boolean
   */
  showConnections: PropTypes.bool,
};

OfficeBox.defaultProps = {
  showConnections: false,
};

export default withRouter(OfficeBox);
