import React from 'react';
import { useHistory } from 'react-router-dom';

const goToMeeting = Component => ComponentProps => {
  const history = useHistory();
  const meetingId = localStorage.getItem('meeting-id');
  const attendeeKey = localStorage.getItem('meeting-attendee-key');
  const healthMeasuresKey = localStorage.getItem('health-measure-key');
  if (meetingId && !attendeeKey) {
    history.push(`/meeting/${meetingId}`);
    localStorage.removeItem('meeting-id');
    return null;
  }
  if (healthMeasuresKey === undefined || healthMeasuresKey == null) {
    history.push(`/health-safety-certificate`);
    localStorage.setItem('health-measure-key', true);
    return null;
  }
  return <Component {...ComponentProps} />;
};

export default goToMeeting;
