import Immutable from 'immutable';
import { FETCH_PROFILE } from '../actions/accountActions';
import {
  GLOBAL_SEARCH,
  GLOBAL_SEARCH_TRIGGER,
  GET_VERTICALS,
  SET_VERTICAL,
  TOGGLE_SUGGESTIONS,
  SUGGESTIONS_OFF,
  GLOBAL_SEARCH_HEROES,
  SET_IS_WHITE_LABEL,
  CHECK_SUBDOMAIN,
  FETCH_APP_TIMEZONE,
} from '../actions/global';

const defaultState = Immutable.fromJS({
  showSuggestions: false,
  heroProfile: null,
  globalSearchResults: null,
  globalSearching: false,
  globalVerticals: [],
  selectedVertical: null,
  isWhiteLabel: null,
  existingSubdomain: null,
  appTimezone: 'Europe/Brussels',
});

const global = (state = defaultState, action) => {
  let newState;

  switch (action.type) {
    case TOGGLE_SUGGESTIONS:
      newState = state.set('showSuggestions', !state.get('showSuggestions'));
      break;
    case SUGGESTIONS_OFF:
      newState = state.set('showSuggestions', false);
      break;
    case FETCH_PROFILE:
      newState = state.set('heroProfile', action.data).set('isWhiteLabel', !!action.data.isWhiteLabel);
      break;
    case GLOBAL_SEARCH_TRIGGER:
      newState = state.set('globalSearching', true);
      break;
    case GLOBAL_SEARCH:
      newState = state.set('globalSearchResults', action.data).set('globalSearching', false);
      break;
    case GLOBAL_SEARCH_HEROES:
      newState = state.set('globalSearchResults', action.data).set('globalSearching', false);
      break;
    case GET_VERTICALS: {
      const defaultVertical = action.data.find(vertical => vertical.defaultVertical === true);
      if (state.get('hasDefault') !== true) {
        newState = state
          .set('globalVerticals', action.data)
          .set('selectedVertical', parseInt(defaultVertical.id, 10))
          .set('hasDefault', true);
      } else {
        newState = state.set('globalVerticals', action.data);
      }
      break;
    }
    case SET_VERTICAL:
      newState = state.set('selectedVertical', parseInt(action.vertical, 10));
      break;
    case SET_IS_WHITE_LABEL:
      newState = state.set('isWhiteLabel', action.isWhiteLabel);
      break;
    case CHECK_SUBDOMAIN:
      newState = state
        .set('existingSubdomain', action.data.success)
        .set('calendarSync', action.data.calendar_sync)
        .set('ssoProvider', action.data.sso_provider, null);
      break;
    case FETCH_APP_TIMEZONE:
      newState = state.set('appTimezone', Immutable.fromJS(action.data));
      break;

    default:
      newState = state;
  }

  return newState;
};

export default global;
