import _ from 'lodash';
import { connect } from 'react-redux';
import ForgotPasswordForm from './ForgotPasswordForm';
import { translations } from '../../../selectors/translations';
import { forgot } from '../../../actions/openAuth/forgot';
import { setIsWhiteLabel } from '../../../actions/global';

const mapStateToProps = state => ({
  translations: _.get(translations(state), 'openAuth', {}),
});

const mapDispatchProps = dispatch => ({
  forgot: data => dispatch(forgot(data)),
  setIsWhiteLabel: isWhiteLabel => dispatch(setIsWhiteLabel(isWhiteLabel)),
});

export default connect(mapStateToProps, mapDispatchProps)(ForgotPasswordForm);
