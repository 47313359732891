import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { withRouter, Link } from 'react-router-dom';
import _ from 'lodash';
import classnames from 'classnames';
import LoginForm from './loginForm';
import ThirdPartyAuth from './thirdPartyAuth';
import Footer from './Footer';
import setCommunityUrlInLocalStorage from './utils/setCommunityUrlInLocalStorage';

const queryString = require('query-string');

class OpenLogin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showForm: false,
    };
    this.handleEmailButtonClick = this.handleEmailButtonClick.bind(this);
  }

  componentDidMount() {
    const { history, location, impersonate } = this.props;
    let queryParams = _.get(location, 'search');
    if (queryParams) {
      const params = queryString.parse(queryParams);
      const impersonationKey = _.get(params, 'impersonation', null);
      const source = _.get(params, 'source', null);
      if (impersonationKey) {
        const data = { impersonationKey, source };
        impersonate(data)
          .then(response => {
            history.push('/');
          })
          .catch(error => {
            history.push('/');
          });
      }
    } else {
      queryParams = _.get(history, 'location.state.queryParams', null);
      if (queryParams) {
        const params = queryString.parse(queryParams);
        const invitationKey = _.get(params, 'invitation_key', null);
        if (invitationKey) {
          localStorage.setItem('invitation-key', invitationKey);
        }
        const meetingKey = _.get(params, 'meeting_attendee_key', null);
        if (meetingKey) {
          localStorage.setItem('meeting-attendee-key', meetingKey);
          const meetingId = _.get(history, 'location.state.from', null).split('/')[2];
          localStorage.setItem('meeting-id', meetingId);
        }
      }
      const historyState = _.get(history, 'location.state', null);
      if (_.startsWith(_.get(historyState, 'from', null), '/community')) {
        setCommunityUrlInLocalStorage(historyState);
      }
      if (localStorage.getItem('invitation-whitelabel')) {
        this.setState({ showForm: true });
      }
    }
  }

  handleEmailButtonClick() {
    const { showForm } = this.state;
    if (!showForm) {
      this.setState({ showForm: true });
    }
  }

  render() {
    const { translations, history, location } = this.props;
    const { showForm } = this.state;
    const loginTranslations = _.get(translations, 'login', null);

    return (
      <Scrollbars className="styleScroll">
        <div className="open-login__container--full">
          <button type="button" onClick={() => history.goBack()} className="navigation__back-to-button">
            {_.get(translations, 'backToPreviousPage', 'Back to previous page')}
          </button>
          <div className="open-login__title">{_.get(loginTranslations, 'title', 'Log in')}</div>
          <div className="open-login__subtitle">
            {_.get(loginTranslations, 'subtitle', 'Please use your credential below to enter your account.')}
          </div>
          <div className={classnames('open-login__container--small', { 'open-login__container--centered': !showForm })}>
            {_.isNull(localStorage.getItem('invitation-whitelabel')) || localStorage.getItem('invitation-whitelabel') == 0 ? (
              <ThirdPartyAuth translations={loginTranslations} handleEmailButtonClick={this.handleEmailButtonClick} />
            ) : null}
            {showForm ? <LoginForm /> : null}
            <div className="open-login__account-message">
              <div className="open-login__info-message">{_.get(loginTranslations, 'footerMessage', "You don't have an account?")}</div>
              <Link
                to={{
                  pathname: '/sign-up',
                  fromUrl: _.get(location, 'fromUrl', location.pathname),
                  state: { fromBooking: _.get(location, 'state.fromBooking', false) },
                }}
                className="open-login__action-message"
              >
                {_.get(loginTranslations, 'footerAction', 'Sign up')}
              </Link>
            </div>
          </div>
        </div>
        <Footer translations={translations} />
      </Scrollbars>
    );
  }
}

export default withRouter(OpenLogin);
