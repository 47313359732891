import React, { Component } from 'react';
import classnames from 'classnames';
import _ from 'lodash';
import AudienceItem from '../audience-item';
import HeroesList from './heroes-list';
import Search from '../../../../header/search';
import isValidEmail from '../../../utils/emailValidator';

class HeroesPicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayEmailSuggestion: null,
      shouldDisplaySuggestionBox: false,
      emailError: false,
    };
    this.handleEmailAddress = this.handleEmailAddress.bind(this);
    this.renderEmailSuggestionBox = this.renderEmailSuggestionBox.bind(this);
    this.selectGuestEmail = this.selectGuestEmail.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { globalSearchResults: globalSearchResultsOld } = prevProps;
    const { globalSearchResults } = this.props;
    const heroOld = _.get(globalSearchResultsOld, 'heroes', []);
    const heroNow = _.get(globalSearchResults, 'heroes', []);

    if (
      (heroOld.length !== heroNow.length && (!heroNow.length || this.state.shouldDisplaySuggestionBox)) ||
      (heroOld.length === heroNow.length &&
        ((!this.state.displayEmailSuggestion && this.state.shouldDisplaySuggestionBox) ||
          (this.state.displayEmailSuggestion && !this.state.shouldDisplaySuggestionBox)) &&
        !heroNow.length)
    ) {
      this.handleSuggestionBox();
    }
  }

  handleEmailAddress(text) {
    this.setState({ displayEmailSuggestion: null });
    if (text !== null && text.length) {
      this.setState({ displayEmailSuggestion: text });
    }
  }

  handleSuggestionBox() {
    this.setState({ shouldDisplaySuggestionBox: !this.state.shouldDisplaySuggestionBox });
  }

  selectGuestEmail(guestEmail) {
    const { handleEmailClick } = this.props;
    if (!isValidEmail(guestEmail)) {
      this.setState({ emailError: guestEmail, displayEmailSuggestion: null });
      setTimeout(() => {
        this.setState({ emailError: false });
      }, 5000);
    } else {
      this.setState({ emailError: false, displayEmailSuggestion: null });
      handleEmailClick(guestEmail);
    }
    this.handleSuggestionBox();
  }

  renderEmailSuggestionBox() {
    const { displayEmailSuggestion } = this.state;
    return (
      <div className="community-form__email-suggestion-box" onClick={() => this.selectGuestEmail(displayEmailSuggestion)}>
        {displayEmailSuggestion}
      </div>
    );
  }

  render() {
    const { noMembers, noMembersLabel, searchHeroesPlaceholder, onMembersCheckboxClick, translations, heroProfile, isAuthenticated } = this.props;
    const heroIsInternal = _.get(heroProfile, 'isInternal', false);
    const notValidEmail = _.get(translations.community, 'notValidEmail', 'notValidEmail');
    const { shouldDisplaySuggestionBox, emailError } = this.state;
    const suggestionBox = shouldDisplaySuggestionBox && !heroIsInternal ? this.renderEmailSuggestionBox() : null;
    return (
      <div className="community-audience__heroes-container">
        <AudienceItem label={noMembersLabel} isChecked={noMembers} handleOptionChange={onMembersCheckboxClick} />
        <div className={classnames('search', { 'disabled-search': noMembers })}>
          <Search
            onLocationChange={this.props.onLocationChange}
            placeholder={searchHeroesPlaceholder}
            globalSearch={this.props.globalSearchHeroes}
            globalSearchTrigger={this.props.globalSearchTrigger}
            globalSearchResults={this.props.globalSearchResults}
            globalSearching={this.props.globalSearching}
            handleClick={this.props.handleHeroClick}
            trans={this.props.translations}
            disabled={noMembers}
            handleNonExistingMembers={this.handleEmailAddress}
            isAuth={isAuthenticated}
          />
          {/* <input className="search-input" type="text" placeholder="Search for Heroes" /> */}
        </div>
        {suggestionBox}
        {emailError ? (
          <div className="community-audience_email-error">
            <span>{emailError}</span> {notValidEmail}
          </div>
        ) : null}
        {!noMembers ? <HeroesList heroes={this.props.selectedHeroes} handleHeroRemoveClick={this.props.handleHeroRemoveClick} /> : ''}
      </div>
    );
  }
}

export default HeroesPicker;
