import api from '../../components/myMeetings/utils/api';
/*
 * Constants
 */
export const SET_HERO_DETAILS = 'SET_HERO_DETAILS';
export const SET_HERO_DETAILS_LOADING = 'SET_HERO_DETAILS_LOADING';

/*
 * Sync Actions
 */
export const setHeroDetails = heroDetails => ({
  type: SET_HERO_DETAILS,
  heroDetails,
});

export const setHeroDetailsLoading = isLoading => ({
  type: SET_HERO_DETAILS_LOADING,
  isLoading,
});

/*
 * Async Actions
 */
export const fetchHeroDetails = heroId => dispatch => {
  dispatch(setHeroDetailsLoading(true));
  api
    .get(`whitelabel/hero/${heroId}/details`)
    .then(({ data = {} }) => {
      if (data) {
        dispatch(setHeroDetails(data));
      }
    })
    .catch(() => {})
    .finally(() => {
      dispatch(setHeroDetailsLoading(false));
    });
};
