import React, { Component } from 'react';
import _ from 'lodash';
import './css/linkedin.scss';
import { Scrollbars } from 'react-custom-scrollbars';
import moment from 'moment';
import { isAndroid, isIOS, isMobile } from 'react-device-detect';
import queryString from 'query-string';
import importAll from '../../utils/helpers';

const images = importAll(require.context('../../assets/images/hero-invitation', false, /\.(png|jpe?g|svg)$/));
const badges = importAll(require.context('../../assets/images/badges', false, /\.(png|jpe?g|svg)$/));

const deepLinkUrl = () => {
  const fullUrl = window.location.href;
  const { protocol } = window.location;
  return fullUrl.replace(protocol, 'workero:');
};

const isUrlMobileWithRequest = () => {
  return (
    isMobile &&
    _.has(window, 'location.pathname') &&
    _.isString(window.location.pathname) &&
    !_.isEmpty(window.location.pathname.replace('/', ''))
  );
};

class IsMobile extends Component {
  constructor(props) {
    super(props);
    this.deeplinkRef = null;
    this.setDeeplinkRef = element => {
      this.deeplinkRef = element;
    };
    this.state = {
      featureUnavailableOnMobile: false,
    };
  }

  componentDidMount() {
    if (this.deeplinkRef && isUrlMobileWithRequest()) {
      // this.deeplinkRef.click();
    }
    const urlSlug = window.location.pathname.split('/')[1];
    if (urlSlug === 'meeting') {
      this.setState({ featureUnavailableOnMobile: true });
    }
  }

  buildUrlMobileRequestLink() {
    if (!isUrlMobileWithRequest()) {
      return null;
    }
    return (
      <div style={{ opacity: 0, position: 'absolute', width: 0, height: 0 }}>
        <a ref={this.setDeeplinkRef} href={deepLinkUrl()}>
          Deeplink Url
        </a>
      </div>
    );
  }

  render() {
    const { featureUnavailableOnMobile } = this.state;

    let appBadgeLink = null;
    if (isAndroid) {
      appBadgeLink = (
        <a href="https://play.google.com/store/apps/details?id=com.workero.runner">
          <img alt="" src={badges['badgeAndroid.png']} />
        </a>
      );
    } else if (isIOS) {
      appBadgeLink = (
        <a href="https://itunes.apple.com/us/app/workero/id1388349529?ls=1&mt=8">
          <img alt="" src={badges['badgeApple.png']} />
        </a>
      );
    } else {
      appBadgeLink = (
        <div>
          <a href="https://play.google.com/store/apps/details?id=com.workero.runner">
            <img alt="" src={badges['badgeAndroid.png']} />
          </a>
          <div />
          <a href="https://itunes.apple.com/us/app/workero/id1388349529?ls=1&mt=8">
            <img alt="" src={badges['badgeApple.png']} />
          </a>
        </div>
      );
    }

    return (
      <Scrollbars>
        {this.buildUrlMobileRequestLink()}
        <div className="body-landing">
          <div className="container-fluid imgBackground">
            <div className="row">
              <div className="col-12 centerSection">
                <div className="row">
                  <div className="col-12 custom30 custom60 text-center">
                    <div className="icon-holder">
                      <div className="logo positionCenter">
                        <img alt="" className="conditionalLogo" src="#" />
                        <img alt="" src={images['workero@3x.png']} />
                      </div>
                    </div>
                    {featureUnavailableOnMobile ? (
                      <p className="w-p">
                        <span className="white yellow">
                          This feature is currently unavailable on mobile. Please enter our desktop version to be able to use it.
                        </span>
                      </p>
                    ) : (
                      <>
                        <span className="w-h2 white yellow">Please download our mobile app</span>
                        <div>{appBadgeLink}</div>
                      </>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 custom30 custom60 " />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-8 offset-md-2 text-center footer">
                <div className="intro">
                  <span className="service">Service provided by</span>
                  <span className="copyright active">© Workero {moment().format('YYYY')}</span>

                  <div className="icon-holder">
                    <div className="workero-logo" />
                  </div>
                </div>

                <div className="list-inline-dot">
                  <a href="https://www.workero.com/privacy/" target="_blank" rel="noopener noreferrer">
                    Privacy
                  </a>
                  <a className="bulletDot" />
                  <a className="margin-left-25" href="https://www.iubenda.com/terms-and-conditions/82572254" target="_blank" rel="noopener noreferrer">
                    Terms
                  </a>
                  <a className="bulletDot" />
                  <a href={`${window.__env.public_site}/cookies`} target="_blank" rel="noopener noreferrer">
                    Cookie policy
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Scrollbars>
    );
  }
}

export default IsMobile;
