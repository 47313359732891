import { connect } from 'react-redux';

import { geolocated } from 'react-geolocated';
import Home from '../../components/home/home';

import {
  fetchNearestOffices,
  fetchRecentlyAdded,
  fetchFeaturedOffices,
  fetchAvailableOffices,
  fetchCompanies,
  fetchMyBookings,
} from '../../actions/officesActions';
import {fetchAmenities, fetchOfficeTypes} from '../../actions/generalDataActions';
import { pageViewSwitch } from '../../actions/homeActions';
import {
  markerClick,
  markerClose,
  onBoundsChanged,
  onRadiusChange,
  onZoomChanged,
  onLocationChangeSearch,
  setZoomToFitApplied,
} from '../../actions/map';
import { fetchBookingSummary } from '../../actions/bookingActions';
import {
  fetchAdvancedSearchData,
  onRatingChange,
  onPriceChange,
  onOfficeTypeChange,
  onAmenitiesChange,
  onServicesChange,
  onIndustriesChange,
  onCapacityChange,
  onWorkHoursFromChange,
  onWorkHoursUntilChange,
  onApplyFilters,
  onCloseSearchBox,
  onResetFilters,
  toggleMode,
  onRemoveFilter,
  onDateChange,
  onHealthMeasuresChange,
  onPremiumSupplierChange,
} from '../../actions/filtersActions';

const mapStateToProps = state => {
  return {
    availableOffices: state.offices.get('availableOffices'),
    availableCompanies: state.offices.get('availableCompanies'),
    nearestOffices: state.offices.get('nearestOffices'),
    recentlyAdded: state.offices.get('recentlyAdded'),
    featuredOffices: state.offices.get('featuredOffices'),
    filters: state.filters,
    myBookings: state.offices.get('myBookings'),
    mapData: state.map,
    homeData: state.home,
    history: state.history,
    officeTypesData: state.location.get('officeTypesData'),
    bookingSummary: state.booking.get('bookingSummary'),
    translations: state.translations,
    heroProfile: state.account.get('profile'),
  };
};

const mapDispatchProps = dispatch => {
  return {
    fetchNearestOffices: filters => {
      dispatch(fetchNearestOffices(filters));
    },
    fetchRecentlyAdded: () => {
      dispatch(fetchRecentlyAdded());
    },
    fetchFeaturedOffices: () => {
      dispatch(fetchFeaturedOffices());
    },
    fetchOfficeTypes: () => {
      dispatch(fetchOfficeTypes());
    },
    fetchAmenities: () => {
      dispatch(fetchAmenities());
    },
    fetchAvailableOffices: (filters, resetMarker) => {
      dispatch(fetchAvailableOffices(filters, resetMarker));
    },
    fetchCompanies: (filters, resetMarker) => {
      dispatch(fetchCompanies(filters, resetMarker));
    },
    /* filters actions */
    toggleMode: mode => {
      dispatch(toggleMode(mode));
    },
    fetchAdvancedSearchData: (filters, resetMarker) => {
      dispatch(fetchAdvancedSearchData(filters, resetMarker));
    },
    onPriceChange: value => {
      dispatch(onPriceChange(value));
    },
    onRadiusChange: value => {
      dispatch(onRadiusChange(value));
    },
    onRatingChange: value => {
      dispatch(onRatingChange(value));
    },
    onOfficeTypeChange: (value, checked) => {
      dispatch(onOfficeTypeChange(value, checked));
    },
    onAmenitiesChange: (value, checked) => {
      dispatch(onAmenitiesChange(value, checked));
    },
    onHealthMeasuresChange: (value, checked) => {
      dispatch(onHealthMeasuresChange(value, checked));
    },
    onPremiumSupplierChange: (value, checked) => {
      dispatch(onPremiumSupplierChange(value, checked));
    },
    onServicesChange: (value, checked) => {
      dispatch(onServicesChange(value, checked));
    },
    onIndustriesChange: (value, checked) => {
      dispatch(onIndustriesChange(value, checked));
    },
    onCapacityChange: value => {
      dispatch(onCapacityChange(value));
    },
    onWorkHoursFromChange: value => {
      dispatch(onWorkHoursFromChange(value));
    },
    onWorkHoursUntilChange: value => {
      dispatch(onWorkHoursUntilChange(value));
    },
    onApplyFilters: (filters, resetMarker) => {
      dispatch(onApplyFilters(filters, resetMarker));
    },
    onCloseSearchBox: () => {
      dispatch(onCloseSearchBox());
    },
    onResetFilters: filters => {
      dispatch(onResetFilters(filters));
    },
    onLocationChangeSearch: () => {
      dispatch(onLocationChangeSearch());
    },
    onRemoveFilter: (keys, value, removeType) => {
      dispatch(onRemoveFilter(keys, value, removeType));
    },
    onDateChange: (startDate, endDate) => {
      dispatch(onDateChange(startDate, endDate));
    },
    markerClick: (markerID, location, googleMap) => {
      dispatch(markerClick(markerID, location, googleMap));
    },
    markerClose: markerID => {
      dispatch(markerClose(markerID));
    },
    onBoundsChanged: newMap => {
      dispatch(onBoundsChanged(newMap));
    },
    onZoomChanged: zoom => {
      dispatch(onZoomChanged(zoom));
    },
    fetchMyBookings: () => {
      dispatch(fetchMyBookings());
    },
    fetchBookingSummary: () => {
      dispatch(fetchBookingSummary());
    },
    pageViewSwitch: view => {
      dispatch(pageViewSwitch(view));
    },
    setZoomToFitApplied: () => {
      dispatch(setZoomToFitApplied());
    },
  };
};

const HomeView = connect(
  mapStateToProps,
  mapDispatchProps
)(Home);

export default geolocated({
  positionOptions: {
    enableHighAccuracy: false,
  },
  userDecisionTimeout: 0,
  geolocationProvider: navigator.geolocation,
})(HomeView);
