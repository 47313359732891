import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Truncate from 'react-truncate';
import _ from 'lodash';
import { Link } from 'react-router-dom';

const goTo = id => {
  window.location.href = `/dashboard/media/${id}`;
};

const buildSimilarItem = item => {
  const { title, tags, image } = item;
  return (
    <div key={item.id} className="entity" onClick={() => goTo(item.id)}>
      <div className="entity-wrapper">
        <div className="entity__tags">
          <Truncate lines={1} ellipsis={<>...</>}>
            {tags}
          </Truncate>
        </div>
        <div className="entity__title">
          <Truncate lines={2} ellipsis={<>...</>}>
            {title}
          </Truncate>
        </div>
      </div>
      {image ? (
        <div className="entity__image-container">
          <img src={image} alt="Profile" className="entity__image" />
        </div>
      ) : null}
    </div>
  );
};

class SimilarMedia extends Component {
  render() {
    const { items, articleType, showAdditional } = this.props;
    const verticalName = _.get(items, '0.verticalName');

    if (!showAdditional) {
      return (
        <div className="entity-card">
          <h4 className="card__title">
            Similar {articleType} in {verticalName}
          </h4>
          <div className="entities__container">{items.map(buildSimilarItem)}</div>
        </div>
      );
    }

    return (
      <div className="entity-card">
        <h4 className="card__title">
          {articleType} in {verticalName}
        </h4>
        <div className="entities__container">{items.map(buildSimilarItem)}</div>
      </div>
    );
  }
}

SimilarMedia.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})),
  articleType: PropTypes.string,
  showAdditional: PropTypes.bool,
};

SimilarMedia.defaultProps = {
  items: [],
  articleType: '',
  showAdditional: false,
};

export default SimilarMedia;
