import React from 'react';
import PropTypes from 'prop-types';
import './../css/deletePaymentAccount.scss';
import _ from 'lodash';

class DeletePaymentMethod extends React.Component {

    onDelete = () => {
        this.props.onAction(this.props.profileId);
    };

    onCancelDelete = () => {
        this.props.onCancelAction(false);
    };

    render () {
        const { paymentType, trans } = this.props;

        let textMessage;
        switch (paymentType) {
            case 'stripe' : textMessage = _.get(trans, 'modals.deletePaymentMethod.cc', 'You are about to delete this Credit Card'); break;
            case 'bancontact' : textMessage = _.get(trans, 'modals.deletePaymentMethod.bancontact', 'You are about to delete Maestro/Bancontact'); break;
            case 'voucher' : textMessage = _.get(trans, 'modals.deletePaymentMethod.voucher', 'You are about to delete this Voucher'); break;
            default : textMessage = _.get(trans, 'modals.deletePaymentMethod.defaultPayment', 'You are about to delete this Payment Method'); break;
        }

        return (
            <div className="deletePaymentAccount">
                <div onClick={this.onCancelDelete} className="modal-close-button" />
                <div className="deletePaymentAccount-title items-style1">{_.get(trans, 'modals.deletePaymentMethod.title', 'Delete Payment Method')}</div>
                <div className="deletePaymentAccount-warning">{textMessage}</div>
                <div className="deletePaymentAccount-actions">
                    <button
                        className="deletePaymentAccount-blockButton secondary-button"
                        onClick={ this.onDelete }>{_.get(trans, 'modals.deletePaymentMethod.makeDefaultPaymentAccountButtonLabel', 'Delete Payment Method')}</button>
                    <button
                        className="deletePaymentAccount-cancelButton main-button"
                        onClick={ this.onCancelDelete }>{_.get(trans,'modals.deletePaymentMethod.changedMyMindButtonLabel', 'I changed my mind')}</button>
                </div>
            </div>
        );
    }
}

DeletePaymentMethod.propTypes = {
    onAction: PropTypes.func
};

export default DeletePaymentMethod;
