import React, { Component } from 'react';
import PropTypes from 'prop-types';

import _ from 'lodash';
import Filter from './Filter';
import Card from './Card';
import TwitterCard from './TwitterCard';
import LoadMore from './LoadMore';
import addLoader from '../../utils/add-loader';
import { NEWS_PER_PAGE } from '../../constants';
import Footer from '../../../openAuth/Footer';

const buildNewsCard = (news, translations) => {
  if (news.type === 'twitter') return <TwitterCard key={`${news.type}-${news.id}`} id={news.id} news={news} translations={translations} />;
  return <Card key={`${news.type}-${news.id}`} id={news.id} news={news} translations={translations} />;
};

const buildNewsMessage = (newsCount, translations) => {
  if (newsCount === 0) {
    return _.get(translations, 'noArticles', 'No articles');
  }
  if (newsCount < NEWS_PER_PAGE) {
    return null;
  }
  return _.get(translations, 'noMoreArticles', 'No more articles');
};

const NewsSection = addLoader(({ news, newsCount, isLoadingMore, hasMore, fetchNews, translations }) => (
  <>
    <div className="news__cards-container">
      {news.map(function(item) {
        return buildNewsCard(item, translations);
      })}
    </div>
    <div className="news__load-more-container">
      {hasMore ? (
        <LoadMore isLoading={isLoadingMore} fetchNews={fetchNews} translations={translations} />
      ) : (
        buildNewsMessage(newsCount, translations)
      )}
    </div>
  </>
));

class News extends Component {
  componentDidMount() {
    const { fetchFirstNews } = this.props;
    fetchFirstNews();
  }

  render() {
    return (
      <div className="news__container">
        <div className="news__filter-container">
          <Filter />
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <NewsSection {...this.props} />
        </div>
        <Footer translations={this.props.translationsOpenAuth} />
      </div>
    );
  }
}

News.propTypes = {
  fetchFirstNews: PropTypes.func.isRequired,
  fetchNews: PropTypes.func.isRequired,
  news: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  hasMore: PropTypes.bool.isRequired,
};

export default News;
