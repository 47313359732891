// eslint-disable-next-line import/prefer-default-export
export const similarMediaSelector = state => state.similarMedia.get('similarMedia');
export const additionalMediaSelector = state => state.similarMedia.get('additionalMedia');
export const isLoadingSelector = state => state.similarMedia.get('isLoading');
export const verticalNameSelector = state => {
  const verticals = state.global.get('globalVerticals');
  const localStorageSelected = parseInt(localStorage.getItem('selected-vertical'), 10);
  const selectedVerticalId = state.global.get('selectedVertical') || localStorageSelected;

  const selectedVertical = verticals.find(obj => {
    return obj.id === selectedVerticalId;
  });

  if (selectedVertical) {
    return selectedVertical.name;
  }

  return null;
};
