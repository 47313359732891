import _ from 'lodash';
import { Link, useHistory } from 'react-router-dom';
import React, { useRef, useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import buildLocationUrl from '../../../utils/buildLocationUrl';
import sizedImage from '../../../utils/imageHelper';
import Accordion from '../accordion';
import CreateBookingTile from './CreateBookingTile';
import { getWorkDays } from '../../../utils/weekDaysUtils';
import getLang from "../../../utils/lang";

const lang = getLang() !== null ? getLang() : 'en';

const Tile = props => {
  const { office, heroProfile, distanceLabel, translations } = props;
  const titleRef = useRef();

  const history = useHistory();
  const [isExtended, setIsExtended] = useState(false);

  const officeName = _.get(office, 'name', '');
  const newTile = _.get(office, 'newTile', false);
  const bookings = _.get(office, 'bookings', []);
  const bookingsToShow = isExtended ? bookings : _.take(bookings, 2);
  const officeImageUrl = _.get(office, 'imageUrl', null);
  const officeLink = buildLocationUrl(office);
  const officeId = _.get(office, 'id', null);

  const buildExpandableSection = booking => {
    const productName = _.get(booking, 'product.name', '');
    const bookingId = _.get(booking, 'id', '');
    const bookingStatus = _.get(booking, 'status', '');
    const isOrganiser = _.get(booking, 'isOrganizer', 0);

    const detailsLink = isOrganiser ? `/booking/${bookingId}` : `/meeting/${bookingId}`;

    const fromDate = _.get(booking, 'fromDate', 0);
    const toDate = _.get(booking, 'toDate', 0);
    const startPeriod = moment.unix(fromDate).utc();
    const endPeriod = moment.unix(toDate).utc();
    const title =
      startPeriod.format('MMM D YYYY') === moment(endPeriod).format('MMM D YYYY')
        ? `${startPeriod.locale(lang).format('MMM D YYYY')}`
        : `${startPeriod.locale(lang).format('MMM D YYYY')} - ${moment(endPeriod).locale(lang).format('MMM D YYYY')}`;
    const meetingAttendees = _.get(booking, 'meetingAttendees', []);
    const noOfInvited = meetingAttendees.length;
    const noOfAccepted = meetingAttendees.reduce((n, x) => n + (x.status === 'accepted'), 0);
    const invitedBy = `${_.get(booking, 'organizer.first_name', '')} ${_.get(booking, 'organizer.last_name')}`;

    const invitedByProfile = `/connection/${_.get(booking, 'organizer.id', '')}`;

    const fromHour = _.get(booking, 'hours.from.hour', 0);
    const fromMinute = _.get(booking, 'hours.from.minute', 0);
    const toHour = _.get(booking, 'hours.to.hour', 0);
    const toMinute = _.get(booking, 'hours.to.minute', 0);
    const meetingHours = `${fromHour}:${fromMinute} - ${toHour}:${toMinute}`;


    const bookingFee = _.get(booking, 'totalPricePayable', 0);
    const meetingAttendee = meetingAttendees.filter(attendee => _.get(attendee, 'heroId', null) === _.get(heroProfile, 'id', null));

    const meetingStatus = _.get(meetingAttendee, '0.status', '');
    const meetingAttendeeId = _.get(meetingAttendee, '0.id', '');
    const weekDays = _.get(booking, 'weekDays', '');
    let alreadyStarted = false;
    let timeZone = _.get(booking, 'timeZone', '');
    let startDate = moment.unix(fromDate).set({
      hour: fromHour,
      minute:fromMinute,
    })

    const startingBookingString = startDate.format('YYYY-MM-DD HH:mm:ss')
    const now = moment.tz(timeZone)
    const startBooking = moment.tz(startingBookingString, 'YYYY-MM-DD HH:mm:ss', timeZone);

    if (startBooking.isBefore(now)) {
      alreadyStarted = true;
    }

    return (
      <div className="accordion-wrapper">
        <Accordion
          title={title}
          officeName={productName}
          officeId={officeId}
          officeLink={detailsLink}
          meetingHours={meetingHours}
          isOrganiser={isOrganiser}
          noOfInvited={noOfInvited}
          noOfAccepted={noOfAccepted}
          bookingFee={bookingFee}
          meetingStatus={meetingStatus}
          invitedBy={invitedBy}
          invitedByProfile={invitedByProfile}
          meetingAttendeeId={meetingAttendeeId}
          bookingId={bookingId}
          bookingStatus={bookingStatus}
          bookingDays={getWorkDays(weekDays, _.get(translations, 'location.weekDaysMap', []))}
          alreadyStarted={alreadyStarted}
        />
      </div>
    );
  };

  if (!officeName && newTile) {
    return (
      <CreateBookingTile
        title={_.get(translations, 'myBookings.startNewMeeting', '')}
        subtitle={_.get(
          translations,
          'myBookings.chooseYourLocation',
          'Why not choose your work location, avoid traffic and explore the true meaning of flexible work?'
        )}
        buttonText={_.get(translations, 'myBookings.startNewMeeting', 'START  new meeting')}
      />
    );
  }
  const handleScrollTo = () => {
    titleRef.current.scrollIntoView({ behavior: 'smooth' });
  };
  return (
    <div className="meetings-tile" ref={titleRef}>
      <Link to={officeLink}>
        <img className="meeting-main-image" src={sizedImage(officeImageUrl, 417, 0)} alt="Office" />
      </Link>
      <div className="meetings-wrapper">
        <div className="office-title">
          {officeName} <span>({bookings.length})</span>
        </div>
        <div className="office-distance">{distanceLabel}</div>
        <div className="rooms-wrapper">{bookingsToShow.map(booking => buildExpandableSection(booking))}</div>
        {bookings.length > 2 && (
          <button
            type="button"
            onClick={() => {
              if (isExtended) {
                handleScrollTo();
              }
              setIsExtended(!isExtended);
            }}
            className="entity-button meetings__extend-button"
          >
            {isExtended ? _.get(translations, 'myBookings.showLess', '') : _.get(translations, 'myBookings.showMore', '')}
          </button>
        )}
      </div>
    </div>
  );
};

Tile.propTypes = {
  office: PropTypes.shape({}).isRequired,
  heroProfile: PropTypes.shape({}).isRequired,
  distanceLabel: PropTypes.string,
};

Tile.defaultProps = {
  distanceLabel: '',
};

export default Tile;
