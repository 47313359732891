import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _ from 'lodash';
import onClickOutside from 'react-onclickoutside';
class CustomFilterDropdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      expanded: false,
      selected: [],
      customInputValue: 1,
      capacityDisabled: false
    };

    this.toggleExpand = this.toggleExpand.bind(this);
    this.onChange = this.onChange.bind(this);
    this.calculateValue = this.calculateValue.bind(this);
    this.buildList = this.buildList.bind(this);
  }

  handleClickOutside = (e) => {
    this.setState({
      expanded: false
    });
  }

  componentDidMount() {
    const { initialValue } = this.props;
    if (initialValue) {
      this.setState({selected: initialValue});
    }
  }

  componentDidUpdate(prevProps) {
    const { selectedItems, initialValue} = this.props;
    const { selectedItems:oldSelectedItems } = prevProps;
    //clear filters on reset
    if (oldSelectedItems && oldSelectedItems.size !== 0 && !selectedItems.size && !initialValue) {
      this.setState({selected: []})
    }
    if (initialValue && _.get(oldSelectedItems, '0.id') !== _.get(selectedItems, '0.id')) {
      this.setState({selected: selectedItems})
    }
  }

  onChange(id, value, groupName) {
    const { onChange, closeOnSelection } = this.props;
    const { selected } = this.state;
    const isChecked = _.isEmpty(selected.filter(x => x.id === id));
    if (groupName !== 'officesTypes' && groupName !== 'premiumSupplier') {
      const isSelected = selected.find(x => x.id === id);
      if (!isSelected) {
        this.setState({selected: [...selected, { id,value }] })
      } else {
        this.setState({selected: selected.filter(x => x.id !== id)})
      }
    } else {
      const isSelected = selected.find(x => x.id === id);
      if (!isSelected) {
        this.setState({
          selected: [{ id, value }],
        })
      } else {
        this.setState({
          selected: [],
        })
      }

    }
    if (groupName === 'officesTypes' ) {
      if (id === 1) {
        this.setState({customInputValue : 1, capacityDisabled : true})
        onChange(1,'seats');
      } else {
        this.setState({capacityDisabled : false})
      }
    }
    onChange(id, groupName, isChecked);
    if(closeOnSelection) {
      this.toggleExpand()
    }
  }

  toggleExpand() {
    const { expanded } = this.state;
    this.setState({
      expanded: !expanded,
    });
  }
  calculateValue(incrementValue, decrementValue) {
    const { customInputValue, capacityDisabled } = this.state;
    if (!capacityDisabled) {
      this.setState({customInputValue:(incrementValue ? customInputValue+1 : (decrementValue ? customInputValue-1 : customInputValue))});
      this.props.onChange(customInputValue+1,'seats');
    }
  }
  buildList(el) {
    const {id, capacity, label, checked, disabled, filterIcon, groupName } = el;
    const { selected, customInputValue } = this.state;

    if (capacity) {
      return (<div key={`input_check_${label}-${id}`} className="map-filter__capacity">
        <span className='list-label'>{label}</span>
        <div className="map-filter__input-number">
          <span className={classnames("decrease-number", {'decrease-number--disabled' : customInputValue <= 1})}
                onClick={()=>{ if(customInputValue !== 1) {this.calculateValue( false, true)}}} ></span>
          <input type='text' value={customInputValue} name={groupName} readOnly/>
          <span className="increase-number"  onClick={()=>{this.calculateValue(true, false)}}></span>
        </div>
      </div>);
    }
    return (
      <div key={`input_check_${label}-${id}`} className={classnames({disabled}, 'list')} onClick={() => { this.onChange(id, label, groupName)}} >
        <input
          disabled={disabled}
          name={groupName}
          type='checkbox'
          defaultChecked={checked || (!_.isEmpty(selected) && selected.find(x => x.value === label))}
          readOnly
          />
        <span className={classnames('list-label',{'checked': checked || (!_.isEmpty(selected) && selected.find(x => x.value === label)) }, {disabled})}>{label}
          {filterIcon && <img src={filterIcon} alt="filter-icon" className="filterIcon"/> }
        </span>

      </div>
    );
  }
  render() {
    const {placeHolder, className, customText, list } = this.props;
    const { expanded, selected } = this.state;
    let selectedItem = !_.isEmpty(selected) ? selected.map(x => x.value).join(', ') : {};
    let firstLine = _.isEmpty(selectedItem) ? placeHolder : selectedItem;

    return (
      <div key={customText} className={classnames({ expanded }, className, 'dropdown')}>
        <div className={classnames({'completed': !_.isEmpty(selected)  }, 'dropdown-input-field')} onClick={this.toggleExpand}>
          <span className="dropdown-field-title">{customText}</span>
          <span className={classnames({'completed': !_.isEmpty(selected) }, 'dropdown-field-value')}>{firstLine} </span>
        </div>
        <div className="container">
          {list.map(this.buildList)}
        </div>
      </div>
    );
  }
}

CustomFilterDropdown.propTypes = {
  placeHolder: PropTypes.string,
  list: PropTypes.arrayOf(PropTypes.shape({})),
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  selectedId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  customText: PropTypes.string,
  closeOnSelection: PropTypes.bool
};

CustomFilterDropdown.defaultProps = {
  placeHolder: '',
  list: [],
  selectedId: null,
  className: '',
  customText: '',
  closeOnSelection: false,
};

export default onClickOutside(CustomFilterDropdown);
