/**
 * Renders the Home "page" inside HeroBooking app
 * @class Home
 * @module Home
 */
import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import _ from 'lodash';
import { Link, withRouter } from 'react-router-dom';

import Sidebar from '../../../rightSidebar/rightSidebar';
import HeroDetailsSidebar from './HeroDetailsSidebar';
import HeroBookings from './HeroBookings';

import '../../css/index.scss';

class HeroDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { fetchHeroDetails, match } = this.props;
    const heroId = _.get(match, 'params.id', null);
    fetchHeroDetails(heroId);
  }

  renderLoader() {
    const { translations } = this.props;

    return (
      <div className="location-container">
        <section className="location-content">
          <Scrollbars className="styleScroll">
            <div className="office-details">
              <Link to="/booking" className="office-details-back-link">
                {_.get(translations, 'whiteLabel.backToMap', 'Back to map')}
              </Link>
              <div className="hero-booking-list-loader" />
            </div>
          </Scrollbars>
        </section>
        <Sidebar>
          <div className="hero-booking-list-loader" />
        </Sidebar>
      </div>
    );
  }

  render() {
    const { translations, isLoading, heroDetails, setSelectedPin, setSelectedDate, setSelectedProductType } = this.props;

    if (isLoading) {
      return this.renderLoader();
    }

    return (
      <div className="location-container">
        <section className="location-content">
          <Scrollbars className="styleScroll">
            <div className="office-details">
              <Link to="/booking" className="office-details-back-link">
                {_.get(translations, 'whiteLabel.backToMap', 'Back to map')}
              </Link>
              <div className="heroes-active-row active-row-colleague">
                <div className="heroes-booked-avatar-mask">
                  <span>{_.get(heroDetails, 'hero.hero_initials', null)}</span>
                </div>

                <div className="heroes-details-wrapper colleague-details-wrapper">
                  <div className="heroes-details-name colleague-name">
                    {_.get(heroDetails, 'hero.first_name', null)} {_.get(heroDetails, 'hero.last_name', null)}
                  </div>
                  <div className="heroes-details-floor colleague-position">{_.get(heroDetails, 'hero.headline', null)}</div>
                </div>
              </div>
              <HeroBookings
                heroBookings={_.get(heroDetails, 'bookings', [])}
                heroData={_.get(heroDetails, 'hero', [])}
                translations={translations}
                setSelectedPin={setSelectedPin}
                setSelectedDate={setSelectedDate}
                setSelectedProductType={setSelectedProductType}
              />
            </div>
          </Scrollbars>
        </section>
        <Sidebar>
          <HeroDetailsSidebar sidebarDetails={heroDetails.hero} translations={translations} />
        </Sidebar>
      </div>
    );
  }
}

export default withRouter(HeroDetails);
