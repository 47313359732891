import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import ResetPasswordForm from './resetPassword';
import Footer from './Footer';

class OpenResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      token: '',
    };
  }

  render() {
    const { translations, history } = this.props;
    const resetPasswordTranslations = _.get(translations, 'resetPassword', null);
    return (
      <Scrollbars className="styleScroll">
        <div className="open-login__container--full">
          <button type="button" onClick={() => history.goBack()} className="navigation__back-to-button">
            {_.get(translations, 'backToPreviousPage', 'Back to previous page')}
          </button>
          <div className="open-login__title">{_.get(resetPasswordTranslations, 'title', 'Enter your new password')}</div>
          <div className="open-login__subtitle">
            {_.get(resetPasswordTranslations, 'subtitle', 'Please fill in the fields below and follow the instructions.')}
          </div>
          <div className="open-login__container--small">
            <ResetPasswordForm />
          </div>
        </div>
        <Footer translations={translations} />
      </Scrollbars>
    );
  }
}

export default withRouter(OpenResetPassword);
