import _ from 'lodash';
import store from '../store';

const blockIncompleteProfileAction = () => {
  return new Promise((resolve, reject) => {
    const state = store.getState();
    const profile = state.global.get('heroProfile');
    let profileCompleted = 0;
    if (profile) {
      profileCompleted = _.get(profile,'profileCompleted', 0);
    }
    if (!profileCompleted) {
      resolve();
    } else {
      reject();
    }
  });
};

export default blockIncompleteProfileAction;
