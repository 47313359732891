// eslint-disable-next-line import/prefer-default-export
export const verticalSelector = state => {
  const localStorageSelected = parseInt(localStorage.getItem('selected-vertical'), 10);
  if (localStorageSelected) {
    return localStorageSelected;
  }

  const selectedVertical = state.global.get('selectedVertical');
  if (selectedVertical) {
    return parseInt(selectedVertical, 10);
  }

  return 0;
};
