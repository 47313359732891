/**
 * Renders the Home "page" inside HeroBooking app
 * @class Home
 * @module Home
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars';
import _ from 'lodash';
import Immutable from 'immutable';
import { Link, withRouter } from 'react-router-dom';
import classnames from 'classnames';

import OfficesGrid from './../officesGrid/officesGrid';
import MyBookings from './../myBookings/myBookings';
import Sidebar from './../rightSidebar/rightSidebar';
import FiltersContainer from './../filtersContainer/index';
import MapContainer from './../mapContainer/mapContainer';
import PageHeader from './../pageHeader/pageHeader';

import './css/index.scss';
import officeAvailable from './../../assets/svg/map/available.svg';
import officeUnavailable from './../../assets/svg/map/normal-unavailable.svg';
import officeUnavailableForReview from './../../assets/svg/map/coming-up.svg';
import officePartial from './../../assets/svg/map/partially.svg';
import officePartialUnavailable from './../../assets/svg/map/partially-unavailable.svg';
import parkingAvailable from './../../assets/svg/map/parking.svg';
import parkingUnavailable from './../../assets/svg/map/parking-unavailable.svg';
import officeAvailableSelected from './../../assets/svg/map/available-selected.svg';
import officePartialSelected from './../../assets/svg/map/partially-selected.svg';
import parkingAvailableSelected from './../../assets/svg/map/parking-selected.svg';
import officeUnavailableForReviewSelected from './../../assets/svg/map/coming-up-selected.svg';
import Dropdown from "../dashboard/common/dropdown/Dropdown";

const iconsMap = {
  normal: {
    normal: officeAvailable,
    selected: officeAvailableSelected
  },
  partially: {
    normal: officePartial,
    selected: officePartialSelected
  },
  parking: {
    normal: parkingAvailable,
    selected: parkingAvailableSelected
  },
  normalUnavailable: {
    normal: officeUnavailable,
    selected: officeUnavailable
  },
  reviewUnavailable: {
    normal: officeUnavailableForReview,
    selected: officeUnavailableForReviewSelected
  },
  partiallyUnavailable: {
    normal: officePartialUnavailable,
    selected: officePartialUnavailable
  },
  parkingUnavailable: {
    normal: parkingUnavailable,
    selected: parkingUnavailable
  }
};

/**
 * Decides what icon to use depending on location availability
 * @param item
 * @param selectedItemId
 * @returns {*}
 */
const getIcon = (item, selectedItemId) => {
  const selected = item.get('id') === selectedItemId ? 'selected' : 'normal';
  const status = item.get('officeStatus');

  let icon = iconsMap['reviewUnavailable'];

  if(item.get('status') === 'live') {
    icon = status === 'available' ? iconsMap['normal'] : iconsMap['normalUnavailable'];
  }
  return icon[selected];
};

const OFFICES_TO_SHOW = 8;

class Home extends React.Component {

  constructor () {
    super();

    this.state = {
      mapX:                   null,
      initialized:            false,
      initialOfficesFetched:  false,
      selectedCategory: 'featured',
      filterPremium: true,
      loadMoreIndex: 1,
    };

    this.onMapLoaded      = this.onMapLoaded.bind(this);
    this.onBoundsChanged  = this.onBoundsChanged.bind(this);
    this.markerClick      = this.markerClick.bind(this);
    this.getOfficeCategories = this.getOfficeCategories.bind(this);
    this.getFilter = this.getFilter.bind(this);
  }

  setIcons(availableOffices) {
    return availableOffices.map(_item => {
      return _item.set('icon', getIcon(_item, this.props.mapData.get('activeMarkerID')));
    });
  }

  componentDidMount () {
    // load items
    this.props.fetchRecentlyAdded();
    this.props.fetchOfficeTypes();
    this.props.fetchFeaturedOffices();
    this.props.fetchAmenities();

    if(this.props.mapData.toJS().initialized === false)
    {
        this.getNearestOffices();
    }

    if (this.props.mapData.get('activeMarkerID')) {
      this.props.markerClose(this.props.mapData.get('activeMarkerID'));
    }
    if (!this.props.bookingSummary) {
      this.props.fetchBookingSummary();
    }
  }
  componentWillUnmount() {
    this.onResetFiltersClick();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if(this.props.mapData.toJS().center.lat !== nextProps.mapData.toJS().center.lat || this.props.mapData.toJS().center.lng !== nextProps.mapData.toJS().center.lng)
    {
      this.state.mapX.panTo(nextProps.mapData.toJS().center);
    }

    if(this.props.availableOffices.toJS().length !== nextProps.availableOffices.toJS().length )
    {
       this.props.setZoomToFitApplied();
    }

    if(this.state.initialOfficesFetched === false)
    {
      this.delaySearchOffices();
    }
  }

  componentDidUpdate(prevProps) {
    const { featuredOffices, recentlyAdded } = this.props;
    const { selectedCategory } = this.state;
    const { featuredOffices:oldFeaturedOffices } = prevProps;
    if (selectedCategory === "featured" && _.get(featuredOffices, 'size', 0) === 0 && recentlyAdded) {
      this.setState({selectedCategory: "newlyAdded"});
    }
    if (oldFeaturedOffices !== featuredOffices && featuredOffices && featuredOffices.size !== 0) {
      this.setState({selectedCategory: "featured"});
    }
    this.zoomToFit(this.props.availableOffices.toJS());
  }

  _getBookAgain() {
    const {myBookings, translations}  = this.props;
    const { loadMoreIndex } = this.state;
    if (!myBookings || _.get(myBookings, 'size', 0) === 0) {
      return null;
    }

    return (
      <OfficesGrid
        items={ myBookings.slice(0, OFFICES_TO_SHOW*loadMoreIndex) }
        className="--homePage"
        trans={translations}
        />
    );
  }

  _getMainItemsContainer() {

      let availableOffices = [];
      this.props.availableOffices.toJS().map((office) => {
        if(office.officeStatus === 'available'){
          availableOffices.push(office);
        }
        return null;
      });

    const hasAppliedFilters = _.get(this.props.filters.toJS(), 'mode', null);
    if (hasAppliedFilters !== 'applied') {
      availableOffices = availableOffices.concat(this.props.availableCompanies.toJS());
      availableOffices = Immutable.fromJS(availableOffices);
    } else {
      availableOffices = this.props.availableOffices;
    }
    const availableMapItems = this.setIcons(availableOffices);
      return (
        <div>
          <div style={{visibility: 'visible', height: 'auto'}}>
            <MapContainer
              router={{ history: this.props.history }}
              mapData={ this.props.mapData }
              markers={ availableMapItems.filter(office => office.get('location')) }
              onMarkerClick={ this.markerClick }
              setCenter={ false }
              geoLocationCoords={this.props.coords}
              officeTypesData={this.props.officeTypesData}
              onMarkerClose={ this.props.markerClose }
              activeMarker={ this.props.mapData.get('activeMarkerID') }
              onBoundsChanged={this.onBoundsChanged}
              onMapLoad={ (googleMap) => { if(googleMap != null) { this.onMapLoaded(googleMap); return; } } }
              trans={this.props.translations}
            />
         </div>
      </div>
    );
  }

  markerClick(markerID, location) {
    this.props.markerClick(markerID, location);
  }

  zoomToFit(offices = null){

    if(offices === null)
    {
      offices = this.props.availableOffices.toJS();
    }

    if(this.state.initialized === false)
    {
      if(offices.length > 0 && this.state.mapX !== null)
      {
          if(this.props.mapData.get('zoomToFitApplied') === false)
          {
            this.setState({initialized: true});
            var bounds = new window.google.maps.LatLngBounds();
            offices.forEach(office => {
              bounds.extend(new window.google.maps.LatLng(office.location.latitude, office.location.longitude));
            });
            this.state.mapX.fitBounds(bounds);
          }
          else
          {
            this.state.mapX.panTo(this.props.mapData.toJS().center);
          }
      }
    }
  }

  onMapLoaded(map){
    if(this.state.mapX == null)
    {
        this.setState({mapX: map});
    }
  }

  onBoundsChanged() {
    this.delayOnBoundsChanged();
    this.props.onRadiusChange(this.state.mapX);
    this.delaySearchOffices();
  };

  delayOnBoundsChanged = _.debounce(function(){
    this.props.onBoundsChanged(this.state.mapX);
  }, 100);

  delaySearchOffices = _.debounce(function(e){
    this.searchOffices();
  }, 400);

  searchOffices() {
    let query_filters = {};
    if(this.state.initialOfficesFetched === false)
    {
        if(this.props.isGeolocationAvailable === true && this.props.isGeolocationEnabled === true && this.props.coords !== null)
        {
            let loc         = {};
            loc.center      = {};
            loc.center.lat  = this.props.coords.latitude;
            loc.center.lng  = this.props.coords.longitude;
            query_filters.location = Immutable.fromJS(loc);
        }
        else
        {
            query_filters.filters   = this.props.filters;
            query_filters.location  = this.props.mapData;
        }
        this.setState({initialOfficesFetched: true}, () => {
          this.props.fetchAvailableOffices(query_filters, true);
          this.props.fetchCompanies(query_filters, true);
        });
    }
    else
    {
        query_filters.filters   = this.props.filters;
        query_filters.location  = this.props.mapData;
        this.props.fetchAvailableOffices(query_filters, true);
    }
  }

  getNearestOffices() {
    let query_filters = {};
    query_filters.mapData   = this.props.mapData;
    this.props.fetchNearestOffices(query_filters);
  }

  /**
   * Handles view type switch
   * @method _onPageViewSwitch
   * @param {string} view
   * @private
   */
  _onPageViewSwitch = (view) => {
    this.props.pageViewSwitch(view);
    this.props.onCloseSearchBox();
  }

  onResetFiltersClick = () => {
      let query_filters = {};
      query_filters.location  = this.props.mapData;
      this.props.onResetFilters(query_filters)
  }

  onApplyFiltersClick = () => {
    let query_filters = {};
    query_filters.filters   = this.props.filters;
    query_filters.location  = this.props.mapData;
    this.props.fetchAvailableOffices(query_filters, true);
    this.props.onApplyFilters();
  }

  _getMyBookingsSidebar() {

    let havePersonalRecentBookings = false;
    if(this.props.myBookings.size > 0) {
      havePersonalRecentBookings = true;

    }
    let onlyMyBooking = [];
    let notMyBooking = [];
    let myBookings = _.sortBy(this.props.bookingSummary.toJS(),['fromDate']);
    const activeBookingsOrMeetings = myBookings.filter(booking => booking.ownBooking || booking.isAttendee);
    const hasRecentBookingsOrMeetings = !!activeBookingsOrMeetings.length || havePersonalRecentBookings;
    let bookingsCounter = 0;
    myBookings.map((myBooking) => {
      let tempBooking = {
        'imageUrl': myBooking.office.imageUrl, 'bookings': {0: myBooking}
      };

      if(myBooking.ownBooking || myBooking.isAttendee) {
        onlyMyBooking.push(tempBooking);
      } else {
        notMyBooking.push(tempBooking);
      }
      bookingsCounter++;
      return null;
    });

    let haveMyBookings = false;

    if(this.props.myBookings.size > 0 && onlyMyBooking.length > 0) {
      haveMyBookings = true;
    }

    if(bookingsCounter !== 0 || havePersonalRecentBookings) {
      let myBookingSummaryCode = '';
      if(onlyMyBooking.length !== 0) {
        myBookingSummaryCode = <MyBookings
          haveMyBookings = {haveMyBookings}
          comingUp={true}
          myBookings={true}
          items={Immutable.fromJS(onlyMyBooking)}
          personalBooking={bookingsCounter}
          content='bookings'
          title={this.props.translations.home.rightSidebar.activeBookingsLabel}
          trans={this.props.translations}
        />
        haveMyBookings = false;
      }
      let bookingSummaryCode = '';
      if(notMyBooking.length !== 0) {
        bookingSummaryCode = <MyBookings
          haveMyBookings = {haveMyBookings}
          comingUp={false}
          myBookings={false}
          items={Immutable.fromJS(notMyBooking)}
          personalBooking={bookingsCounter}
          content='bookings'
          title={this.props.translations.home.rightSidebar.recentlyBookedLabel}
          trans={this.props.translations}
        />
      }

      return <div>
        <Link to='/my-bookings' className="homeSidebar-myBookings-link myBookings-link secondary-button">{this.props.translations.home.rightSidebar.myBookingsButton}
        </Link>
        {myBookingSummaryCode}
        {bookingSummaryCode}
      </div>
    }
  }


  getOfficeCategories(hasFeatured, hasBookings, hasNewlyAdded)
  {
    const { translations } = this.props;
    const { selectedCategory } = this.state;

    return (
        <div className="officesGrid__categories-container">
          {hasFeatured ? (
              <div className={classnames('officesGrid__category', {'officesGrid__category--selected' : selectedCategory === 'featured'})}
                               onClick={() => this.setState({selectedCategory : 'featured', loadMoreIndex: 1})}>
                { _.get(translations, 'home.featuredOffices', 'Featured offices')}
              </div>
          ) : null}
          {hasBookings ? (
              <div className={classnames('officesGrid__category', {'officesGrid__category--selected' : selectedCategory === 'bookAgain'})}
                   onClick={() => this.setState({selectedCategory : 'bookAgain', loadMoreIndex: 1})}
              >
                { _.get(translations, 'home.bookAgain', 'Book again')}
              </div>
          ) : null}
          {hasNewlyAdded ? (
              <div className={classnames('officesGrid__category', {'officesGrid__category--selected' : selectedCategory === 'newlyAdded'})}
                   onClick={() => this.setState({selectedCategory : 'newlyAdded', loadMoreIndex: 1})}
              >
                { _.get(translations, 'home.newlyAddedLocations', 'Newly added locations')}
              </div>
          ) : null}
        </div>
        );

  }

  getFilter()
  {
    const {filterPremium, selectedCategory} = this.state;
    const {translations} = this.props;
    const filtersArray = [{id : 0, value: 'Premium'}, {id : 1, value: 'Regular'}];
    return selectedCategory === 'newlyAdded' ? (
        <div className="officesGrid__filter-container">
          <Dropdown
              onChange={(id) => {this.setState({filterPremium: id===0})}}
              options={filtersArray}
              selectedId={filterPremium ? 0 : 1}
              placeHolder={_.get(translations, 'premium', 'Premium')}
              className="dropdown__selector filter__dropdown"
              customText={_.get(translations, 'filter', 'Filter: ')}
          />
        </div>
        ) : null;
  }

  loadMoreOffices()
  {
    const {loadMoreIndex} = this.state;
    this.setState({loadMoreIndex: loadMoreIndex+1});
  }

  render () {
    const { translations, myBookings, recentlyAdded, featuredOffices, heroProfile }  = this.props;
    const isInternal = heroProfile ? _.get(heroProfile.toJS(), 'isInternal', false) : false;
    if (!translations)
    {
      return <div className="Loader"></div>;
    }
    const { selectedCategory, filterPremium, loadMoreIndex } = this.state;
    const hasFeatured = featuredOffices && featuredOffices.size;
    const hasBookings = myBookings && myBookings.size;
    const hasNewlyAdded = recentlyAdded && recentlyAdded.size;
    const newlyAdded = isInternal ? recentlyAdded : recentlyAdded.filter((item) => item.get('premiumSupplier') == filterPremium);

    return (
      <div className="homeContainer">
        <section className="homeContainer-container">
          <Scrollbars className="styleScroll">
              <div className="homeContainer-mainContent">
            <PageHeader
              className="--defaultPadding"
              pageTitle={translations.home.locations}
              icon="booking"
              actions={[]}
              trans={translations}
            />
            <FiltersContainer
              onRemoveFilter={this.props.onRemoveFilter}
              fetchAdvancedSearchData={this.props.fetchAdvancedSearchData}
              onRatingChange={this.props.onRatingChange}
              onPriceChange={this.props.onPriceChange}
              onOfficeTypeChange={this.props.onOfficeTypeChange}
              onAmenitiesChange={this.props.onAmenitiesChange}
              onHealthMeasuresChange={this.props.onHealthMeasuresChange}
              onPremiumSupplierChange ={this.props.onPremiumSupplierChange }
              onServicesChange={this.props.onServicesChange}
              onCapacityChange={this.props.onCapacityChange}
              onApplyFilters={this.onApplyFiltersClick}
              onResetFilters={this.onResetFiltersClick}
              onDateChange={this.props.onDateChange}
              filtersData={this.props.filters}
              trans={translations}
            />
            { this._getMainItemsContainer() }
            { this.getOfficeCategories(hasFeatured, hasBookings, hasNewlyAdded) }
            { hasNewlyAdded && !isInternal ? this.getFilter() : null}
            { selectedCategory === 'bookAgain' ? this._getBookAgain() : null}
            { selectedCategory === 'featured' && featuredOffices ? (
                  <OfficesGrid
                      items={ featuredOffices.filter((item) => item.get('status') === 'live').slice(0, OFFICES_TO_SHOW*loadMoreIndex) }
                      className="officesGrid-cutPadding --homePage"
                      type="secondary"
                      trans={translations}
                  />
                ) : null }
                { selectedCategory === 'newlyAdded' && recentlyAdded ? (
                      <OfficesGrid
                          items={ newlyAdded.filter((item) => item.get('status') === 'live').slice(0, OFFICES_TO_SHOW*loadMoreIndex) }
                          className="officesGrid-cutPadding --homePage"
                          type="secondary"
                          trans={translations}
                      />
                    ) : null }

                { (selectedCategory === 'featured' && featuredOffices && featuredOffices.size > loadMoreIndex * OFFICES_TO_SHOW) ||
                  (selectedCategory === 'bookAgain' && myBookings && myBookings.size > loadMoreIndex * OFFICES_TO_SHOW) ||
                  (selectedCategory === 'newlyAdded' && !isInternal && recentlyAdded && recentlyAdded.filter((item) => item.get('premiumSupplier') == filterPremium ).size > loadMoreIndex * OFFICES_TO_SHOW) ||
                  (selectedCategory === 'newlyAdded' && isInternal && recentlyAdded && isInternal && recentlyAdded.size > loadMoreIndex * OFFICES_TO_SHOW)?
                    (<span className="officesGrid__loadMore-button" onClick={()=>{this.loadMoreOffices()}}>
                  {_.get(translations, 'officeCard.loadMoreLocations', 'Load 8 more locations')}
                </span>) : null}

              </div>
            </Scrollbars>
        </section>

        <Sidebar className="--full-height">
          <Scrollbars>
            { this._getMyBookingsSidebar() }
          </Scrollbars>
        </Sidebar>
      </div>
    );
  }
}

Home.propTypes = {
  /**
   * Contains all info required by the map to render, including markers
   * @property mapData
   * @type {Immutable.Map}
   */
  mapData: PropTypes.object,

  /**
   * Contains a list of locations already booked
   * @property bookAgain
   * @type {Immutable.List<Immutable.Map>}
   */
  neareastOffices: PropTypes.object,

};

export default withRouter(Home);
