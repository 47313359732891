/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import ImageGallery from 'react-image-gallery';
import _ from 'lodash';

import workeroHomeIcon from '../../../../../assets/svg/navigation/dashboard-icon--active.svg';

const BookingRecommendationModal = ({ closeModal, images, officeTitle, officeDescription, translations, handleOnClick }) => {
  return (
    <div className="base-modal">
      <div className="base-modal__modal-overlay" onClick={closeModal} />
      <div className="base-modal__modal-content">
        <div className="base-modal__left-area">
          <img className="base-modal__icon" src={workeroHomeIcon} alt="workero-home" />
        </div>
        <div className="base-modal__right-area">
          <div className="base-modal__modal-header">
            <span className="base-modal__close" onClick={closeModal}>
              &times;
            </span>
          </div>
          <div className="base-modal__modal-body">
            <ImageGallery
              items={images}
              showFullscreenButton={false}
              showPlayButton={false}
              showThumbnails={false}
              useTranslate3D={false}
            />
          </div>
          <div className="base-modal__modal-footer">
            <div className="base-modal__modal-footer-description-container">
              <div className="base-modal__modal-footer-title">{officeTitle}</div>
              <span className="base-modal__modal-footer-description">{officeDescription}</span>
            </div>
            <button type="button" onClick={handleOnClick} className="base-modal__see-availability-button">
              {_.get(translations, 'seeAvailability', 'See availability')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

BookingRecommendationModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  images: PropTypes.shape([]).isRequired,
  officeTitle: PropTypes.string.isRequired,
  officeDescription: PropTypes.string.isRequired,
};

export default BookingRecommendationModal;
