import _ from 'lodash';
import api from '../../components/dashboard/utils/api';

export const SET_IS_LOADING_PARKING_BOOKINGS = 'SET_IS_LOADING_PARKING_BOOKINGS';
export const SET_PARKING_BOOKINGS = 'SET_PARKING_BOOKINGS';
export const SET_GO_TO_PARKING_FLOOR = 'SET_GO_TO_PARKING_FLOOR';
export const SET_PARKING_BOOKING_DATES = 'SET_PARKING_BOOKING_DATES';

/*
 * Sync Actions
 */

export const setParkingBookings = parkingBookings => ({
  type: SET_PARKING_BOOKINGS,
  parkingBookings,
});

export const setIsLoading = isLoading => ({
  type: SET_IS_LOADING_PARKING_BOOKINGS,
  isLoading,
});

export const setGoToParkingFloor = goToParkingFloor => ({
  type: SET_GO_TO_PARKING_FLOOR,
  goToParkingFloor,
});

export const setParkingBookingDates = bookingDates => ({
  type: SET_PARKING_BOOKING_DATES,
  bookingDates,
});

/*
 * Async Actions
 */
export const fetchParkingBookings = () => dispatch => {
  dispatch(setIsLoading(true));
  return new Promise((resolve, reject) => {
    api
      .get(`${window.__env.apiUrl}/whitelabel/hero/parking-bookings`)
      .then(response => {
        if (response.data) {
          dispatch(setParkingBookings(response.data));
        }
        resolve();
      })
      .catch(({ response }) => {
        reject();
      })
      .finally(() => {
        dispatch(setIsLoading(false));
      });
  });
};
