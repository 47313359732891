import { connect } from 'react-redux';
import _ from 'lodash';
import SignUpForm from './SignUpForm';
import { signup } from '../../../actions/openAuth/signup';
import { translations } from '../../../selectors/translations';
import { setHeroDataAfterLogin } from '../../../actions/openAuth/login';
import { updateRegisteredAttendee } from '../../../actions/meetingAttendeesActions';
import { joinPrivateCommunity } from '../../../actions/community/join-community';
import { setIsWhiteLabel } from '../../../actions/global';

const mapStateToProps = state => ({
  translations: _.get(translations(state), 'openAuth', {}),
});

const mapDispatchProps = dispatch => ({
  signup: data => dispatch(signup(data)),
  setHeroDataAfterLogin: data => dispatch(setHeroDataAfterLogin(data)),
  updateRegisteredAttendee: data => dispatch(updateRegisteredAttendee(data)),
  joinPrivateCommunity: communityUrl => dispatch(joinPrivateCommunity(communityUrl)),
  setIsWhiteLabel: isWhiteLabel => dispatch(setIsWhiteLabel(isWhiteLabel)),
});

export default connect(mapStateToProps, mapDispatchProps)(SignUpForm);
