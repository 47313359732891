import api from '../../components/dashboard/utils/api';
import {verticalSelector} from "../../selectors/vertical-selector";

/*
 * Constants
 */
export const SET_HERO_RECOMMENDATION = 'SET_HERO_RECOMMENDATION';
export const SET_HERO_RECOMMENDATION_IS_LOADING = 'SET_HERO_RECOMMENDATION_IS_LOADING';

/*
 * Sync Actions
 */
export const setHeroRecommendation = heroRecommendation => ({
  type: SET_HERO_RECOMMENDATION,
  heroRecommendation,
});

export const setHeroRecommendationIsLoading = isLoading => ({
  type: SET_HERO_RECOMMENDATION_IS_LOADING,
  isLoading,
});

/*
 * Async Actions
 */
export const fetchHeroRecommendation = () => (dispatch, getState) => {
  const selectedVertical = verticalSelector(getState());
  const url = selectedVertical ? `/recommendation/hero?vertical=${selectedVertical}` : '/recommendation/hero';
  dispatch(setHeroRecommendationIsLoading(true));
  api
    .get(url)
    .then(({ data = {} }) => {
      if (data.recommendedHero) {
        dispatch(setHeroRecommendation(data.recommendedHero));
      }
    })
    .catch(() => {})
    .finally(() => {
      dispatch(setHeroRecommendationIsLoading(false));
    });
};
