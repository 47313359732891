import { connect } from 'react-redux';
import MostActiveHeroes from './MostActiveHeroes';
import addLoader from '../../utils/add-loader';
import toJS from '../../utils/to-js';
import _ from 'lodash';
import { translations } from '../../../../selectors/translations';

import { fetchMostActiveHeroes } from '../../../../actions/dashboard/most-active-heroes';
import { activeHeroesSelector, isLoadingSelector } from '../../../../selectors/dashboard/most-active-heroes';
import { openConversation } from '../../../../actions/directChat';

const mapStateToProps = state => ({
  activeHeroes: activeHeroesSelector(state),
  isLoading: isLoadingSelector(state),
  translations: _.get(translations(state), 'dashboard', {})
});

const mapDispatchToProps = dispatch => ({
  fetchMostActiveHeroes: () => dispatch(fetchMostActiveHeroes()),
  openConversation: (id, hero) => dispatch(openConversation(id, hero)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(toJS(addLoader(MostActiveHeroes, true)));
