/**
 * Renders a connection-type maps infoWindow
 *
 * @class ConnectionMarker
 * @module MapContainer
 */
import React from 'react';
import ProfileBox from './../../profileBox/profileBox';
import PropTypes from 'prop-types';

class ConnectionMarker extends React.Component {

  render () {
    return (
      <div className="markerInfo">
        <ProfileBox
          id={ this.props.connection.get('id') }
          noLink={ true }
          showMessageModal={ this.props.showMessageModal }
          className={ '--mapInfo' }
          grade={ this.props.connection.get('grade') }
          firstName={ this.props.connection.get('firstName') }
          distance={ this.props.connection.get('distance') }
          lastName={ this.props.connection.get('lastName') }
          options={ { distance: true, location: true } }
          avatar={ this.props.connection.getIn(['images', 'medium']) }
          jobName={ this.props.connection.getIn(['job', 'name'])}
          currentLocation={ this.props.connection.getIn(['location', 'name']) }
          matchValue={this.props.heroProfile !== null && this.props.get('id') !== this.props.heroProfile.id ? this.props.get('matchingPercentage') : null}
          connected={ this.props.connection.get('connected') }
          showGrade={ true }
          heroInitials={ this.props.connection.get('heroInitials') }
        />
      </div>
    );
  }
}

ConnectionMarker.defaultProps = {
  /**
   * @property connection
   * @type Immutable.Map<Connection>
   */
  connection: PropTypes.object,
  /**
   * Provides message modal action where context is not present
   * @property showMessageModal
   * @type {function}
   */
  showMessageModal: PropTypes.func
};

export default ConnectionMarker;
