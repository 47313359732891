import React from 'react';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import { Elements, StripeProvider } from 'react-stripe-elements';
import { Scrollbars } from 'react-custom-scrollbars';
import queryString from 'query-string';
import backIcon from '../../assets/svg/back.svg';
import checkSymbol from '../../assets/svg/check-symbol.svg';
import InjectedCardForm from '../accountAddEditPaymentProfile/CardForm';
import Dropdown from '../dashboard/common/dropdown/Dropdown';
import PaymentProfileInfo from './paymentProfileInfo/PaymentProfileInfo';
import PaymentProfilesOptions from './paymentProfilesOptions';

const alphanumeric = input => {
  const letterNumber = /^[0-9a-zA-Z]+$/;
  return !!input.match(letterNumber);
};
const lang = localStorage.getItem('lang') !== null && localStorage.getItem('lang') !== 'default' ? localStorage.getItem('lang') : 'en';

const type = ['stripe', 'bancontact', 'voucher'];

class PaymentProfile extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      accountType: '',
      address: '',
      city: '',
      postalCode: '',
      country: '',
      companyName: '',
      vatNumber: '',
      paymentType: '',
      inputErrors: [],
      cardConnected: false,
      showCardForm: false,
      paymentProfileErrors: null,
      stripeResult: null,
      isDefault: false,
      paymentProfileId: null,
      voucher: null,
      voucherApplied: false,
      voucherInfo: null,
    };
    this.handleAccountType = this.handleAccountType.bind(this);
    this.handlePaymentMethod = this.handlePaymentMethod.bind(this);
    this.handleCountryChange = this.handleCountryChange.bind(this);
    this.renderBusinessSection = this.renderBusinessSection.bind(this);
    this.renderPersonalSection = this.renderPersonalSection.bind(this);
    this.renderCreditCardSection = this.renderCreditCardSection.bind(this);
    this.removeCreditCard = this.removeCreditCard.bind(this);
    this.updateValidating = this.updateValidating.bind(this);
    this.updateStripeToken = this.updateStripeToken.bind(this);
    this.renderMakeDefaultCheckbox = this.renderMakeDefaultCheckbox.bind(this);
    this.enableSaveButton = this.enableSaveButton.bind(this);
    this.handleDataToSave = this.handleDataToSave.bind(this);
    this.checkInputFields = this.checkInputFields.bind(this);
    this.renderVoucherSection = this.renderVoucherSection.bind(this);
    this.handleProfileAccount = this.handleProfileAccount.bind(this);
    this.applyVoucher = this.applyVoucher.bind(this);
    this.removeVoucher = this.removeVoucher.bind(this);
  }

  componentDidMount() {
    const { fetchCountries, history, location, fetchPaymentProfiles } = this.props;
    fetchPaymentProfiles();
    const search = queryString.parse(_.get(location, 'search', ''));
    if (search) {
      switch (search.type) {
        case 'cc':
          this.handlePaymentMethod(0);
          break;
        case 'maestro':
          this.handlePaymentMethod(1);
          break;
        case 'voucher':
          this.handlePaymentMethod(2);
          break;
        default:
          history.push('/account/paymentSettings');
          break;
      }
    }
    fetchCountries();
  }

  handleAccountType(e) {
    this.setState({
      accountType: parseInt(e.target.value, 10),
      paymentProfileId: null,
      address: null,
      city: null,
      postalCode: null,
      country: null,
      companyName: null,
      vatNumber: null,
    });
  }

  handlePaymentMethod(value) {
    this.setState({
      paymentType: value,
    });
  }

  handleCountryChange(country) {
    this.setState({
      country,
    });
  }

  handleDataToSave() {
    const {
      paymentProfileId,
      accountType,
      address,
      city,
      postalCode,
      country,
      companyName,
      vatNumber,
      paymentType,
      isDefault,
      stripeResult,
      voucherInfo,
    } = this.state;
    const { countries } = this.props;
    const countryName = countries ? countries.filter(item => _.get(item, 'id', null) === country).map(item => item.name) : '';
    let company = null;
    let data = {};
    const payment_method = {
      profile_id: paymentProfileId,
      is_default: isDefault,
      type: type[paymentType],
      card: _.get(stripeResult, 'card', {}),
      voucher: voucherInfo,
      stripeToken: _.get(stripeResult, 'id', ''),
    };
    if (paymentProfileId) {
      return { payment_method };
    }
    if (accountType === 1) {
      company = {
        name: _.trim(companyName),
        vatNumber: _.trim(vatNumber),
        postal_code: _.trim(postalCode),
        country: _.trim(countryName),
        city: _.trim(city),
      };
    }
    data = {
      payment_method,
      address: _.trim(address),
      postal_code: _.trim(postalCode),
      country: _.trim(countryName),
      city: _.trim(city),
      company,
    };
    if (stripeResult !== null) {
      data.token = stripeResult.id;
    }
    return data;
  }

  checkInputFields() {
    const { accountType, inputErrors, vatNumber } = this.state;
    if (accountType === 1 && !alphanumeric(vatNumber)) {
      inputErrors.push('vatNumber');
      this.setState({ inputErrors });
      return false;
    }
    return true;
  }

  removeCreditCard() {
    this.setState({ stripeResult: null, cardConnected: false });
  }

  updateValidating() {
    this.setState({ stripeResult: null, paymentProfileErrors: null, cardConnected: false });
  }

  updateStripeToken(token) {
    this.setState({ stripeResult: token, cardConnected: true });
  }

  enableSaveButton() {
    const { accountType, address, city, postalCode, country, companyName, vatNumber, paymentType, cardConnected, voucherInfo } = this.state;
    if (!(address && city && postalCode && country)) return 0;
    if (accountType === 1 && !(companyName && vatNumber)) return 0;
    if (!paymentType && !cardConnected) return 0;
    if (paymentType === 2 && _.get(voucherInfo, 'status', 'inactive') !== 'active') return 0;

    return 1;
  }

  applyVoucher() {
    const { voucher } = this.state;
    const { validateVoucher } = this.props;
    if (voucher) {
      this.setState({ voucherApplied: true }, () => {
        validateVoucher(voucher)
          .then(response => {
            this.setState({ voucherInfo: response });
          })
          .catch(response => {
            this.setState({ voucherInfo: { error: response } });
          });
      });
    }
  }

  removeVoucher() {
    this.setState({ voucherApplied: false, voucherInfo: null, voucher: '' });
  }

  renderVoucherSection() {
    const { voucher, voucherApplied, voucherInfo } = this.state;
    const { translations } = this.props;
    const voucherErrorMessage = _.get(voucherInfo, 'error.details', null) || _.get(voucherInfo, 'error.message', null);

    let voucherError = null;
    if (voucherInfo && voucherApplied && voucherInfo.error) {
      voucherError = (
        <div className="voucher-errors-container">
          <div key={voucherInfo.error.message} className="error-item">
            {voucherErrorMessage}
          </div>
        </div>
      );
    }
    let voucherConfirmation = null;
    if (voucherInfo && voucherInfo.status === 'active' && voucherApplied) {
      const voucherBalance = voucherInfo.balance;
      voucherConfirmation = (
        <div className="payment-method_cc voucher-added-group">
          <div className="voucher-applied">{_.get(translations, 'applyVoucher', 'Voucher Applied')}</div>
          <div className="voucher-balance">
            <img src={checkSymbol} className="check-symbol" alt="Check" />
            {_.get(translations, 'voucherBalance', 'Voucher balance')}
            {` ${voucherBalance
              .toFixed(2)
              .toString()
              .replace('.', window.__env.decimalSeparator)} euros`}
          </div>
          <button className="remove-voucher" type="button" onClick={this.removeVoucher}>
            {_.get(translations, 'removeVoucher', 'Remove voucher')}
          </button>
        </div>
      );
    }

    return (
      <>
        <div className="payment-method_voucher">
          <input
            id="voucher-input"
            type="text"
            className={`payment-info-input voucher-input ${voucher ? 'payment-info-input-completed' : ''}`}
            name="voucher"
            value={voucher}
            placeholder={_.get(translations, 'placeholderVoucher', '')}
            onChange={e => {
              this.setState({ voucher: e.target.value });
            }}
          />
          <button type="button" className="voucher-button" onClick={this.applyVoucher}>
            {_.get(translations, 'applyVoucher', 'Apply Voucher')}
          </button>
        </div>
        {voucherError}
        {voucherConfirmation}
      </>
    );
  }

  renderCreditCardSection() {
    const { cardConnected, showCardForm, paymentProfileErrors, paymentType } = this.state;
    const { translations } = this.props;
    if (cardConnected && paymentType === 0 && !paymentProfileErrors) {
      return (
        <div className="payment-method_cc">
          <div className="card-connected">
            <div className="credit-card-connected">
              <img src={checkSymbol} className="check-symbol" alt="Check" />
              {_.get(translations, 'creditCardConnected', 'Credit Card Connected')}
            </div>
            <button onClick={this.removeCreditCard} className="credit-card-remove-button remove-voucher" type="button">
              {_.get(translations, 'removeCreditCard', 'Remove Credit Card')}
            </button>
          </div>
        </div>
      );
    }

    if (showCardForm) {
      return (
        <>
          <div className="payment-method_cc">
            <StripeProvider apiKey={window.__env.stripeApiKey}>
              <Elements locale={lang}>
                <InjectedCardForm
                  updateValidating={this.updateValidating}
                  updateStripeToken={this.updateStripeToken}
                  customerName={`${localStorage.getItem('first-name')} ${localStorage.getItem('last-name')}`}
                  buttonText={_.get(translations, 'addCard', '')}
                  trans={translations}
                />
              </Elements>
            </StripeProvider>
          </div>
          {paymentProfileErrors ? <div className="card-errors-container">{paymentProfileErrors}</div> : ''}
        </>
      );
    }
    return (
      <div className="payment-method_cc">
        <div className="add-cc-container">
          <span className="card-title">{_.get(translations, 'connectYourCC', 'Connect your Credit Card')}</span>
          <div className="spacer" />
          <button
            type="button"
            className="card-button"
            onClick={() => {
              this.setState({ showCardForm: true });
            }}
          >
            {_.get(translations, 'connectCC', 'Connect Credit Card')}
          </button>
        </div>
      </div>
    );
  }

  renderPersonalSection() {
    const { address, city, postalCode, country } = this.state;
    const { countries, translations } = this.props;
    const countriesArray = countries ? countries.map(item => ({ id: item.id, value: item.name })) : [];
    const requiredLabel = _.get(translations, 'required', 'required');

    return (
      <div className="payment-address-section">
        <div className="payment-info-group">
          <label className="payment-info-label" htmlFor="address-input">
            {_.get(translations, 'address', 'Address')} ({requiredLabel})
            <input
              id="address-input"
              type="text"
              className={`payment-info-input ${address ? 'payment-info-input-completed' : ''}`}
              name="address"
              value={address}
              onChange={e => {
                this.setState({ address: e.target.value });
              }}
            />
          </label>
        </div>
        <div className="payment-info-group-multiple">
          <label className="payment-info-label" htmlFor="city-input">
            {_.get(translations, 'city', 'City')} ({requiredLabel})
            <input
              id="city-input"
              type="text"
              className={`payment-info-input ${city ? 'payment-info-input-completed' : ''}`}
              name="city"
              value={city}
              onChange={e => {
                this.setState({ city: e.target.value });
              }}
            />
          </label>
          <label className="payment-info-label" htmlFor="postal_code-input">
            {_.get(translations, 'postal_code', 'Postal code')} ({requiredLabel})
            <input
              id="postal_code-input"
              type="text"
              className={`payment-info-input ${postalCode ? 'payment-info-input-completed' : ''}`}
              name="postal_code"
              value={postalCode}
              onChange={e => {
                this.setState({ postalCode: e.target.value });
              }}
            />
          </label>
          <label className="payment-info-label" htmlFor="country-input">
            {_.get(translations, 'country', 'Country')} ({requiredLabel})
            <Dropdown
              onChange={this.handleCountryChange}
              options={countriesArray}
              placeHolder="Select"
              className="dropdown__selector country__selector"
              selectedId={country}
            />
          </label>
        </div>
      </div>
    );
  }

  renderBusinessSection() {
    const { companyName, vatNumber, inputErrors } = this.state;
    const { translations } = this.props;
    const requiredLabel = _.get(translations, 'required', 'required');

    return (
      <div className="payment-business-section">
        <div className="business-info-group">
          <label className="payment-info-label" htmlFor="company-name-input">
            {_.get(translations, 'companyName', 'Company Name')} ({requiredLabel})
            <input
              id="company-name-input"
              type="text"
              className={`company-name-input payment-info-input ${companyName ? 'payment-info-input-completed' : ''}`}
              name="company_name"
              value={companyName}
              onChange={e => {
                this.setState({ companyName: e.target.value });
              }}
            />
          </label>
          <label className="payment-info-label" htmlFor="vat-number-input">
            {_.get(translations, 'vatNumber', 'VAT number')} ({requiredLabel})
            <input
              id="vat-number-input"
              type="text"
              className={`vat-number-input payment-info-input ${vatNumber ? 'payment-info-input-completed' : ''} ${
                inputErrors.includes('vatNumber') ? 'payment-input-error' : ''
              }`}
              name="vat_number"
              value={vatNumber}
              onChange={e => {
                this.setState({ vatNumber: e.target.value });
              }}
            />
            <span>{_.get(translations, 'vatNumberMessage', 'e.g.: BE0xxxxxxxxx (no dots)')}</span>
          </label>
        </div>
        {this.renderPersonalSection()}
      </div>
    );
  }

  renderMakeDefaultCheckbox() {
    const { translations } = this.props;
    return (
      <div className="card-checkbox">
        <label>
          <input
            type="checkbox"
            name="default"
            value="default"
            checked={this.state.isDefault}
            onChange={e => {
              this.setState({ isDefault: !this.state.isDefault });
            }}
          />
          <span>{_.get(translations, 'makeDefault', 'Make default')}</span>
        </label>
      </div>
    );
  }

  handleProfileAccount(paymentProfile) {
    const { paymentType } = this.state;
    this.setState({
      paymentProfileId: _.get(paymentProfile, 'id', null),
      accountType: _.get(paymentProfile, 'company_name', null) ? 1 : 0,
      address: _.get(paymentProfile, 'company_address', ''),
      city: _.get(paymentProfile, 'city', ''),
      postalCode: _.get(paymentProfile, 'postal_code', ''),
      country: _.get(paymentProfile, 'country', ''),
      companyName: _.get(paymentProfile, 'company_name', ''),
      vatNumber: _.get(paymentProfile, 'company_vat_number', ''),
      paymentType,
    });
  }

  render() {
    const { accountType, paymentType, paymentProfileId, address, city, postalCode, country, companyName, vatNumber } = this.state;
    const { history, translations, paymentProfiles, heroProfile } = this.props;
    const firstName = _.get(heroProfile, 'firstName', '');
    const lastName = _.get(heroProfile, 'lastName', '');
    const enableSaveButton = this.enableSaveButton();
    if (!paymentProfiles) {
      return <div className="payment-profile__loader" />;
    }

    return (
      <Scrollbars className="styleScroll">
        <div className="payment-container">
          <div className="payment-content">
            <div className="payment-content__left-area">
              <div className="booking-payment-container payment-profile__container">
                <div className="booking-payment_back" onClick={() => history.push('/account/paymentSettings')}>
                  <img src={backIcon} alt="back" />
                  {_.get(translations, 'backToPreviousPage', 'Back to previous page')}
                </div>
                <div className="booking-payment_title">{_.get(translations, 'addCreditCard', 'Add credit card')}</div>
                <div className="booking-payment_subtitle">
                  {_.get(translations, 'acceptCreditCard', 'Workero accepts credit cards & debit cards')}
                </div>
                <div className="select-account-type">
                  <div className="account-types">
                    <div className="account-type_title">{_.get(translations, 'selectAccType', 'Select account type')}</div>
                    <div className="account-types__profiles">
                      <PaymentProfilesOptions
                        handleAccountType={this.handleAccountType}
                        handleProfileAccount={this.handleProfileAccount}
                        paymentType={paymentType}
                      />
                    </div>
                  </div>
                  <div className="booking-payment-form">
                    {accountType === 0 && !paymentProfileId ? (
                      <div className="booking-payment-personal">
                        <div className="personal-info-headline">
                          {_.get(translations, 'typeOptionsInformation.personal', 'Personal Information')}
                        </div>
                        {this.renderPersonalSection()}
                      </div>
                    ) : null}
                    {accountType === 1 && !paymentProfileId ? (
                      <div className="booking-payment-business">
                        <div className="personal-info-headline">
                          {_.get(translations, 'typeOptionsInformation.company', 'Company Information')}
                        </div>
                        {this.renderBusinessSection()}
                      </div>
                    ) : null}
                  </div>
                </div>
                {accountType !== '' ? (
                  <div className="select-payment-methods">
                    {paymentType === 0 && this.renderCreditCardSection()}
                    {paymentType === 2 && this.renderVoucherSection()}
                    {paymentType !== '' && this.renderMakeDefaultCheckbox()}
                  </div>
                ) : null}
                <div className={`account-type-buttons ${paymentType !== 0 ? 'account-type-buttons--margin' : null}`}>
                  <button
                    className={`payment-profile__button ${enableSaveButton ? '' : 'payment-profile__button--disabled'}`}
                    type="button"
                    onClick={() => {
                      const checkFields = this.checkInputFields();
                      if (checkFields) {
                        const data = this.handleDataToSave();
                        this.props.beforeUpdateHeroPaymentProfile();
                        if (paymentProfileId) {
                          this.props.createPaymentMethod(data, response => {
                            if (_.get(response, 'success', false)) {
                              history.push('/account/paymentSettings');
                            } else {
                              const { error } = response;
                              this.setState({ paymentProfileErrors: error });
                            }
                          });
                        } else {
                          this.props.createHeroPaymentProfile(data, response => {
                            if (_.get(response, 'success', false)) {
                              history.push('/account/paymentSettings');
                            } else {
                              const { error } = response;
                              this.setState({ paymentProfileErrors: error });
                            }
                          });
                        }
                      }
                    }}
                    disabled={!enableSaveButton}
                  >
                    {_.get(translations, 'save', 'Save')}
                  </button>
                  <button
                    className="payment-profile__button secondary"
                    type="button"
                    onClick={() => {
                      history.push('/account/paymentSettings');
                    }}
                  >
                    {_.get(translations, 'cancel', 'Cancel')}
                  </button>
                </div>
              </div>
            </div>
            {paymentProfileId ? (
              <div className="payment-content__right-area add-payment-account-details">
                <PaymentProfileInfo
                  address={address}
                  city={city}
                  country={country}
                  lastName={lastName}
                  firstName={firstName}
                  companyName={companyName}
                  postalCode={postalCode}
                  vatNumber={vatNumber}
                  translations={translations}
                />
              </div>
            ) : null}
          </div>
        </div>
      </Scrollbars>
    );
  }
}
export default withRouter(PaymentProfile);
