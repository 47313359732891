import Immutable, { fromJS } from 'immutable';
import {
  SET_COMPANY,
  SET_JOBS,
  SET_TAGS,
  SET_PRODUCT_TYPES,
  SET_NEW_COMPANY_PROFILE_IS_LOADING,
  SET_COMPANY_LOGO,
} from '../../actions/dashboard/new-company-profile';

const initialState = Immutable.Map({
  company: fromJS({}),
  jobs: fromJS([]),
  productTypes: fromJS([]),
  companyLogo: null,
  isLoading: false,
});

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_COMPANY:
      return state.setIn(['company'], fromJS(action.company));
    case SET_COMPANY_LOGO:
      return state.setIn(['companyLogo'], fromJS(action.companyLogo));
    case SET_JOBS:
      return state.setIn(['jobs'], fromJS(action.jobs));
    case SET_TAGS:
      return state.setIn(['tags'], fromJS(action.tags));
    case SET_PRODUCT_TYPES:
      return state.setIn(['productTypes'], fromJS(action.productTypes));
    case SET_NEW_COMPANY_PROFILE_IS_LOADING:
      return state.setIn(['isLoading'], action.isLoading);
    default:
      return state;
  }
};