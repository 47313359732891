import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import CONST from '../../../../utils/const';
import noProfilePicture from '../../../../assets/images/defaultAvatarimage.png';

class EditCommentForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formErrors: null,
    };

    this.submitForm = this.submitForm.bind(this);
  }

  clearFormErrors() {
    this.setState({ formErrors: null });
  }

  submitForm(event) {
    const { saveComment, comment, handleEditFormCallback, history } = this.props;
    const field = event.target;
    const commentContent = event.target.value;
    this.clearFormErrors();

    if (event.keyCode === CONST.keyCodes.ESC) {
      field.value = '';
    }

    if (event.keyCode === CONST.keyCodes.ENTER) {
      if (_.isEmpty(commentContent)) {
        this.setState({ formErrors: 'The comment can not be empty!' });
        return false;
      }

      const commentId = _.get(comment, 'commentId', null);
      const formData = {
        content: commentContent,
        id: commentId,
      };
      saveComment(formData)
        .then(() => {
          this.clearFormErrors();
          field.value = '';
          handleEditFormCallback(false);
        })
        .catch(response => {
          const errorCode = _.get(response, 'errorCode', null);
          if (errorCode) {
            history.replace({
              pathname: `/communities#errorCode=${errorCode}`,
            });
          }
        });
    }
  }

  render() {
    const { comment, history, heroProfile, isLoading, errors } = this.props;
    const { formErrors } = this.state;
    const profilePictureUrl = _.get(heroProfile, 'profilePictureUrl', null) || noProfilePicture;
    const content = _.get(comment, 'content', null);
    const isReply = _.get(comment, 'isReply', false);
    const error = formErrors || (!_.isEmpty(errors) && !_.isString(errors) ? errors.content[0] : null);

    return (
      <div
        className={classnames('community__comment-form', {
          'community__comment-form--isReplyForm': isReply,
        })}
      >
        <div className="community-comment__avatar">
          <img
            className="community-comment__hero-picture"
            onClick={() => {
              history.push('/account/profile');
            }}
            src={profilePictureUrl}
            alt=""
          />
        </div>
        <div className="community-post__comment-input-container">
          <input
            type="text"
            className="community-post__comment-input"
            placeholder=""
            defaultValue={content}
            onKeyUp={e => this.submitForm(e)}
          />
          {error && <div className="community-post__validation-errors">{error}</div>}
          {isLoading && <div className="comments-wrapper__loader" />}
        </div>
      </div>
    );
  }
}

EditCommentForm.propTypes = {
  saveComment: PropTypes.func.isRequired,
  comment: PropTypes.shape({}).isRequired,
  handleEditFormCallback: PropTypes.func.isRequired,
  showCommunitiesPageToast: PropTypes.func.isRequired,
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
  }).isRequired,
  heroProfile: PropTypes.shape({}).isRequired,
  isLoading: PropTypes.bool.isRequired,
  errors: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
};

EditCommentForm.defaultProps = {
  errors: {},
};

export default withRouter(EditCommentForm);
