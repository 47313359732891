export const dateTimeFormatter = postDate => {
  const date = new Date(postDate).getTime();
  const current = new Date().getTime();
  const msPerMinute = 60 * 1000;
  const msPerHour = msPerMinute * 60;
  const msPerDay = msPerHour * 24;
  const msPerMonth = msPerDay * 30;
  const msPerYear = msPerDay * 365;

  const elapsed = Math.abs(current - date);

  if (elapsed < msPerMinute) {
    return `${Math.round(elapsed / 1000)} sec`;
  }

  if (elapsed < msPerHour) {
    return `${Math.round(elapsed / msPerMinute)} min`;
  }

  if (elapsed < msPerDay) {
    return `${Math.round(elapsed / msPerHour)} h`;
  }

  if (elapsed < msPerMonth) {
    return ` ${Math.round(elapsed / msPerDay)} d`;
  }

  if (elapsed < msPerYear) {
    return `${Math.round(elapsed / msPerMonth)} m`;
  }

  return `${Math.round(elapsed / msPerYear)} y`;
};
