import React from 'react';
import PropTypes from 'prop-types';

const InfoToast = props => {
  const { message } = props;
  return (
    <div className="info-toast success-message">
      <span>{message}</span>
    </div>
  );
};

InfoToast.propTypes = {
  message: PropTypes.string.isRequired,
};

export default InfoToast;
