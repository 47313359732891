import { connect } from 'react-redux';
import MeetingAttendeesBox from './meetingAttendeesBox';
import { globalSearchHeroes, globalSearchTrigger } from '../../actions/global';
import {
  sendInviteToMeeting,
  fetchHeroInvitations,
  addInvitedHeroToList,
  deleteInvitedAttendee,
  deleteInvitedHero,
  resendInvitation,
} from '../../actions/meetingAttendeesActions';

const mapStateToProps = state => ({
  globalSearchResults: state.global.get('globalSearchResults'),
  globalSearching: state.global.get('globalSearching'),
  translations: state.translations,
  heroProfile: state.global.get('heroProfile'),
  isAuthenticated: state.auth.get('isAuthenticated'),
  invitedHeroes: state.meetingAttendees.get('meetingAttendeesInvitedHeroes'),
  isLoadingInvitedMember: state.meetingAttendees.get('isLoadingInvitedMember'),
  meetingAttendeesToastMessage: state.meetingAttendees.get('meetingAttendeesToastMessage'),
});

const mapDispatchProps = dispatch => ({
  globalSearchHeroes: (term, allProfiles) => dispatch(globalSearchHeroes(term, allProfiles)),
  globalSearchTrigger: () => dispatch(globalSearchTrigger()),
  sendInviteToMeeting: hero => dispatch(sendInviteToMeeting(hero)),
  fetchHeroInvitations: bookingId => dispatch(fetchHeroInvitations(bookingId)),
  addInvitedHeroToList: hero => dispatch(addInvitedHeroToList(hero)),
  deleteInvitedAttendee: attendeeId => dispatch(deleteInvitedAttendee(attendeeId)),
  deleteInvitedHero: heroId => dispatch(deleteInvitedHero(heroId)),
  resendInvitation: attendeeId => dispatch(resendInvitation(attendeeId)),
});

export default connect(mapStateToProps, mapDispatchProps)(MeetingAttendeesBox);
