import _ from 'lodash';
import moment from 'moment';
import ImageResizer from '../../../utils/imageHelper';

const buildJobsBlock = (data, articleType, articleDate, applied, articleId) => {
  const startDate = _.get(data, 'startdate', null) ? moment(_.get(data, 'startdate', null)).format('DD MMMM YYYY') : '';
  const blocks = [];

  blocks.push({
    blockName: 'header-block',
    data: {
      type: articleType,
      title: _.get(data, 'title', ''),
      date: articleDate,
    },
  });
  blocks.push({ blockName: 'description-block', data: _.get(data, 'description', '') });
  blocks.push({
    blockName: 'skills-block',
    data: {
      heading: 'Skills',
      content: _.get(data, 'skills', ''),
    },
  });
  blocks.push({
    blockName: 'offer-block',
    data: {
      heading: 'Job Offer',
      content: _.get(data, 'offer', ''),
    },
  });
  blocks.push({
    blockName: 'attachment-block',
    data: {
      form: 1,
      jobTitle: _.get(data, 'title', ''),
      label: 'Apply now',
      placeholder: 'Tell us in short a little bit about yourself and why you want this job.',
      motivationTitle: 'What is your Personal motivation for applying to this job?',
      applied,
      id: articleId,
      type: articleType,
    },
  });
  blocks.push({
    blockName: 'apply-button-block',
    data: {
      label: 'Apply now',
      link: '',
      form: 1,
      applied,
      appliedText: 'You have already applied for this job.',
    },
  });
  blocks.push({
    blockName: 'job-type-block',
    data: {
      label: 'Job Type',
      content: _.get(data, 'type', ''),
    },
  });
  blocks.push({
    blockName: 'start-date-block',
    data: {
      label: 'Start Date',
      content: startDate,
    },
  });
  blocks.push({
    blockName: 'verticals-block',
    data: {
      label: 'Verticals',
      content: _.get(data, 'verticals', ''),
    },
  });
  blocks.push({
    blockName: 'tags-block',
    data: {
      label: 'Job Tags',
      content: _.get(data, 'tags', ''),
    },
  });

  return blocks;
};

const buildNewProjectBlock = (data, articleType, articleDate, applied, articleId) => {
  const blocks = [];

  blocks.push({
    blockName: 'header-block',
    data: {
      type: articleType,
      title: _.get(data, 'title', ''),
      date: articleDate,
    },
  });
  blocks.push({ blockName: 'description-block', data: data.description });
  blocks.push({
    blockName: 'scope-block',
    data: {
      heading: 'Project Scope',
      content: _.get(data, 'scope', ''),
    },
  });
  blocks.push({
    blockName: 'attachment-block',
    data: {
      form: 1,
      jobTitle: _.get(data, 'title', ''),
      label: 'I want to join',
      placeholder: 'Tell us in short a little bit about yourself and why you want to be a part of the project.',
      motivationTitle: 'What is your personal motivation for joining this project?',
      id: articleId,
      type: articleType,
      applied,
    },
  });
  blocks.push({
    blockName: 'apply-button-block',
    data: {
      label: 'I want to join',
      link: '',
      form: 1,
      applied,
      appliedText: 'You have already applied for this co-creation project.',
    },
  });
  blocks.push({
    blockName: 'project-location-block',
    data: {
      label: 'Location',
      content: _.get(data, 'where', ''),
    },
  });
  blocks.push({
    blockName: 'project-length-block',
    data: {
      label: 'Project Length',
      content: _.get(data, 'estimation', ''),
    },
  });
  blocks.push({
    blockName: 'project-remuneration-block',
    data: {
      label: 'Remuneration',
      content: _.get(data, 'remuneration', ''),
    },
  });
  blocks.push({
    blockName: 'verticals-block',
    data: {
      label: 'Verticals',
      content: _.get(data, 'verticals', ''),
    },
  });
  blocks.push({
    blockName: 'tags-block',
    data: {
      label: 'Co-Creation Project Tags',
      content: _.get(data, 'tags', ''),
    },
  });

  return blocks;
};

const buildCompanyNewsBlock = (data, articleType, articleDate, articleId) => {
  const image = ImageResizer(_.get(data, 'image', ''), 625, 470, articleId);
  const blocks = [];

  blocks.push({
    blockName: 'header-block',
    data: {
      type: articleType,
      title: _.get(data, 'title', ''),
      date: articleDate,
    },
  });
  blocks.push({
    blockName: 'image-block',
    data: {
      source: image,
      alt: _.get(data, 'title', ''),
    },
  });
  blocks.push({ blockName: 'description-block', data: _.get(data, 'description', '') });
  blocks.push({ blockName: 'video-block', data: _.get(data, 'video', '') });
  blocks.push({
    blockName: 'attachment-block',
    data: {
      source: _.get(data, 'file', ''),
      attachmentCount: '1 Attachment',
      link: _.get(data, 'link', ''),
      linkTitle: 'Company News URL',
      form: 0,
    },
  });
  blocks.push({
    blockName: 'verticals-block',
    data: {
      label: 'Verticals',
      content: _.get(data, 'verticals', ''),
    },
  });
  blocks.push({
    blockName: 'tags-block',
    data: {
      label: 'Company News Tags',
      content: _.get(data, 'tags', ''),
    },
  });

  return blocks;
};

const buildPressReleaseBlock = (data, articleType, articleDate, articleId) => {
  const image = ImageResizer(_.get(data, 'image', ''), 625, 470, articleId);
  const blocks = [];

  blocks.push({
    blockName: 'header-block',
    data: {
      type: articleType,
      title: _.get(data, 'title', ''),
      date: articleDate,
    },
  });
  blocks.push({
    blockName: 'image-block',
    data: {
      source: image,
      alt: _.get(data, 'title', ''),
    },
  });
  blocks.push({ blockName: 'description-block', data: _.get(data, 'description', '') });
  blocks.push({ blockName: 'video-block', data: _.get(data, 'video', '') });
  blocks.push({
    blockName: 'attachment-block',
    data: {
      source: _.get(data, 'file', ''),
      sourceTitle: 'Download Press Release',
      attachmentCount: '1 Attachment',
      link: _.get(data, 'link', ''),
      linkTitle: 'Press Release URL',
      form: 0,
    },
  });
  blocks.push({
    blockName: 'verticals-block',
    data: {
      label: 'Verticals',
      content: _.get(data, 'verticals', ''),
    },
  });
  blocks.push({
    blockName: 'tags-block',
    data: {
      label: 'Press Release Tags',
      content: _.get(data, 'tags', ''),
    },
  });

  return blocks;
};

const buildProductDealsBlock = (data, articleType, articleDate) => {
  const blocks = [];
  const applied = 0;
  blocks.push({
    blockName: 'header-block',
    data: {
      type: articleType,
      title: _.get(data, 'title', ''),
      date: articleDate,
    },
  });
  blocks.push({ blockName: 'description-block', data: _.get(data, 'description', '') });
  blocks.push({
    blockName: 'attachment-block',
    data: {
      link: _.get(data, 'link', ''),
      linkTitle: 'Product Deal URL',
      form: 0,
    },
  });
  blocks.push({
    blockName: 'apply-button-block',
    data: {
      label: 'Get Deal',
      link: _.get(data, 'link', ''),
      applied,
      appliedText: '',
    },
  });
  blocks.push({
    blockName: 'verticals-block',
    data: {
      label: 'Verticals',
      content: _.get(data, 'verticals', ''),
    },
  });
  blocks.push({
    blockName: 'tags-block',
    data: {
      label: 'Product Deal Tags',
      content: _.get(data, 'tags', ''),
    },
  });

  return blocks;
};

const buildEventsBlock = (data, articleType, articleDate, articleId) => {
  const image = ImageResizer(_.get(data, 'image', ''), 625, 470, articleId);
  const startDate = _.get(data, 'startdate', null) ? moment(_.get(data, 'startdate', null)).format('DD MMMM YYYY') : '';
  const blocks = [];
  const applied = 0;
  blocks.push({
    blockName: 'header-block',
    data: {
      type: articleType,
      title: _.get(data, 'title', ''),
      date: articleDate,
    },
  });
  blocks.push({
    blockName: 'image-block',
    data: {
      source: image,
      alt: _.get(data, 'title', ''),
    },
  });
  blocks.push({ blockName: 'description-block', data: _.get(data, 'description', '') });
  blocks.push({ blockName: 'video-block', data: _.get(data, 'video', '') });
  blocks.push({
    blockName: 'attachment-block',
    data: {
      link: _.get(data, 'link', ''),
      linkTitle: 'Event URL',
      form: 0,
    },
  });
  blocks.push({
    blockName: 'apply-button-block',
    data: {
      label: 'Buy Tickets',
      link: _.get(data, 'link', ''),
      applied,
      appliedText: '',
    },
  });
  blocks.push({
    blockName: 'start-date-block',
    data: {
      label: 'Date',
      content: startDate,
    },
  });
  blocks.push({
    blockName: 'event-price-block',
    data: {
      label: 'Event Pricing',
      content: _.get(data, 'price_event', ''),
    },
  });
  blocks.push({
    blockName: 'verticals-block',
    data: {
      label: 'Verticals',
      content: _.get(data, 'verticals', ''),
    },
  });
  blocks.push({
    blockName: 'tags-block',
    data: {
      label: 'Event Tags',
      content: _.get(data, 'tags', ''),
    },
  });

  return blocks;
};

const parseMediaParams = mediaParams => {
  const item = JSON.parse(mediaParams);
  const data = {};

  for (let i = 0; i < item.length; i += 1) {
    data[item[i].question.code] = item[i].answer;
  }

  return data;
};

const parseMediaArticle = article => {
  const articleId = _.get(article, 'id', '');
  const articleCodeType = _.get(article, 'type.code', '');
  const articleType = _.get(article, 'type.name', '');
  const applied = _.get(article, 'applied', false);
  const articleDate = _.get(article, 'created_at', null) ? moment(_.get(article, 'created_at', null)).format('DD MMMM YYYY') : '';
  const data = parseMediaParams(article.params);

  switch (articleCodeType) {
    case 'jobs':
      return { ...article, blocks: buildJobsBlock(data, articleType, articleDate, applied, articleId) };
    case 'new-projects':
      return { ...article, blocks: buildNewProjectBlock(data, articleType, articleDate, applied, articleId) };
    case 'company-news':
      return { ...article, blocks: buildCompanyNewsBlock(data, articleType, articleDate, articleId) };
    case 'events':
      return { ...article, blocks: buildEventsBlock(data, articleType, articleDate, articleId) };
    case 'press-release':
      return { ...article, blocks: buildPressReleaseBlock(data, articleType, articleDate, articleId) };
    case 'product-deals':
      return { ...article, blocks: buildProductDealsBlock(data, articleType, articleDate, articleId) };

    default:
      console.log('No data for selected type');
  }

  return true;
};

export default parseMediaArticle;
