import api from '../../components/community/utils/api';
import _ from 'lodash';
/*
 * Constants
 */
export const SET_HERO_COMMUNITIES = 'SET_HERO_COMMUNITIES';
export const SET_HERO_COMMUNITIES_IS_LOADING = 'SET_HERO_COMMUNITIES_IS_LOADING';
export const HERO_HAS_OWN_COMMUNIY = 'HERO_HAS_OWN_COMMUNIY';

/*
 * Sync Actions
 */
export const setHeroCommunities = heroCommunities => ({
  type: SET_HERO_COMMUNITIES,
  heroCommunities,
});

export const heroHasOwnCommunity = heroOwnCommunity => ({
  type: HERO_HAS_OWN_COMMUNIY,
  heroOwnCommunity,
});

export const setHeroCommunitiesIsLoading = isLoading => ({
  type: SET_HERO_COMMUNITIES_IS_LOADING,
  isLoading,
});

/*
 * Async Actions
 */
export const fetchHeroCommunities = () => dispatch => {
  dispatch(setHeroCommunitiesIsLoading(true));
  api
    .get('/myCommunities')
    .then(({ data = {} }) => {
      if (data.heroCommunities) {
        const isAdmin = _.get(data.heroCommunities, 'isAdmin[0].isAdmin', 0);
        dispatch(setHeroCommunities(data.heroCommunities));
        dispatch(heroHasOwnCommunity(isAdmin));
      }
    })
    .catch(() => {})
    .finally(() => {
      dispatch(setHeroCommunitiesIsLoading(false));
    });
};
