import React from 'react';
import {Scrollbars} from 'react-custom-scrollbars';
import { withRouter } from 'react-router-dom';

import OfficeDetails from '../officeDetails/officeDetails';
import AddBookingSidebar from '../addBookingSidebar/addBookingSidebar';
import Sidebar from '../rightSidebar/rightSidebar';
import ActiveBooking from './activeBooking';
import buildLocationUrl from '../../utils/buildLocationUrl';

import './css/index.scss';
import './css/booked-location.scss';
import './css/not-booked-location.scss';
import moment from 'moment';
import _ from 'lodash';
import ShowVoucherBalance from '../modalDialog/modals/showVoucherBalance';
import ModalDialog from '../modalDialog/modalDialog';

class Location extends React.Component {
    props;
    constructor(props) {
        super(props);
        this.state = {
            rendering: true,
            step: 0,
            voucherBalance: null,
            showVoucherModal: false,
            shownVoucherModal: false
        }
    }

    UNSAFE_componentWillMount() {
      this.setState({
        rendering: true
      });
    }

    componentDidMount()
    {
        this.props.beforeFetchOfficeDetails();
        this.historyListener = this.props.history.listen((location, action) => {
            const officeId = location.pathname.match(/\d+/g);
            if (officeId) {
                this.props.fetchOfficeDetails(officeId.pop());
            }
        });

        this.props.fetchOfficeDetails(this.props.match.params.id);
        this.props.fetchAmenities();
        this.props.fetchServices();
        this.props.fetchHouseRules();
        this.props.fetchOfficeTypes();
        this.setState({rendering: false});
        const bookingValidate = this.props.bookingValidate;
        if (bookingValidate || _.get(this.props.location, 'state.fromBooking', false)) {
            this.props.openBookingOptions();
        }
    }
    componentDidUpdate(prevState) {
        const {officeDetails: oldOfficeDetails} = prevState;
        const {officeDetails, match} = this.props;
        if (officeDetails.size > 0 && oldOfficeDetails.size !==  officeDetails.size ){
            this.props.history.replace(buildLocationUrl(this.props.officeDetails.toJS()));
        }
    }

    getBookedSidebar() {
        let activeBooking = this.props.officeDetails.get('bookings');
        let cancelBooking = [];
        let firstBooking = activeBooking.toJS()[0];
        const { translations } = this.props;
        const isInternalEnv =  !!_.get(this.props.officeDetails.toJS(), 'internalEnv', false);
        if(activeBooking.size > 0 && !(this.props.officeDetails.get('bookableEvent') === true && firstBooking.officeTypeId === 4 )) {
            let cancelDateAreInFuture = (firstBooking.freeCancelationDate > moment().unix()) ? true : false;
            if(firstBooking.status !== 'canceled' && !isInternalEnv) {
                if (cancelDateAreInFuture && firstBooking.cancelationRefundPercentage === 100) {
                    cancelBooking['label'] = _.toUpper(_.get(translations, 'bookingPage.cancelBooking', '') + '*');
                    cancelBooking['asterix'] = <span>*</span>;
                    cancelBooking['message'] = translations.formatString( _.get(translations , 'location.penaltyLabels.noPenaltiesUntil' , 'This booking can be canceled without penalties until {0}. Please check your cancellation policy.'), moment.unix(firstBooking.freeCancelationDate).utc().format("D MMMM YYYY, h:mm a"));
                } else if(cancelDateAreInFuture && firstBooking.cancelationRefundPercentage < 100) {
                    cancelBooking['label'] = _.toUpper(_.get(translations, 'bookingPage.cancelBooking', '') + '*');
                    cancelBooking['asterix'] = <span>*</span>;
                    cancelBooking['message'] = translations.formatString( _.get(translations , 'location.penaltyLabels.somePenaltiesUntil' , 'This booking can be canceled with ({0}%) penalty until {1}. Please check your cancellation policy.'), (100 - firstBooking.cancelationRefundPercentage), moment.unix(firstBooking.freeCancelationDate).utc().format("D MMMM YYYY, h:mm a"));
                } else if(firstBooking.cancelationRefundPercentage === 0 || !cancelDateAreInFuture) {
                    cancelBooking['label'] = _.toUpper(_.get(translations, 'bookingPage.cancelBooking', '') + '*');
                    cancelBooking['asterix'] = <span>*</span>;
                    cancelBooking['message'] = translations.formatString( _.get(translations , 'location.penaltyLabels.percentagePenalties' , 'Penalties worth {0} ({1}%) will be applied. Please check your cancellation policy.'), firstBooking.penalty_value + '€', firstBooking.cancellationFeePercentage);
                }
            }
        }
       
        return (
            <Sidebar>
                <div className="myBookings booked-location">
                    <button className="main-button" onClick={this.props.openBookingOptions} style={{marginTop:'5px'}}>{this.props.translations.location.bookLocation}</button>
                    <ActiveBooking bookings={this.props.officeDetails.toJS().bookings} translations={this.props.translations}></ActiveBooking>
                    {
                        !firstBooking.isContract &&
                        <div>
                            <span className="cancel-booking">{cancelBooking['label']}</span>
                            <p>{cancelBooking['asterix']}{cancelBooking['message']}</p>
                        </div>
                    }
                </div>
            </Sidebar>
        )
    }

    getOfficeSpaceCount = function () {
        let officeSpaceCount = {
            desk: 0,
            private: 0,
            meeting: 0,
            event: 0,
        };
        let officeDetails = this.props.officeDetails.toJS()

        //prepare available office space:
        if (typeof officeDetails.products !== "undefined") {
            if (typeof officeDetails.products.desks !== "undefined") {
                if (officeDetails.products.desks.length) {
                    officeDetails.products.desks.forEach(function (element) {
                        officeSpaceCount.desk += element.count;
                    });
                }
            }
            if (typeof officeDetails.products.privateOffices !== "undefined") {
                if (officeDetails.products.privateOffices.length) {
                    officeSpaceCount.private = officeDetails.products.privateOffices.length;
                }
            }
            if (typeof officeDetails.products.meetingRooms !== "undefined") {
                if (officeDetails.products.meetingRooms.length) {
                    officeSpaceCount.meeting = officeDetails.products.meetingRooms.length;
                }
            }
            if (typeof officeDetails.products.eventRooms !== "undefined") {
                if (officeDetails.products.eventRooms.length) {
                    officeSpaceCount.event = officeDetails.products.eventRooms.length;
                }
            }
        }
        return officeSpaceCount;
    }

    getDefaultSidebar = function () {
        let officeSpaceCount = this.getOfficeSpaceCount();
        
        if(officeSpaceCount.desk === 0
            && officeSpaceCount.private === 0
            && officeSpaceCount.event === 0
            && officeSpaceCount.meeting === 0)
        {
          return null;
        }

        return <Sidebar>
            <div className="not-booked-location">
                <button className="main-button" onClick={() =>
                    this.props.openBookingOptions()
                }
                >
                    {this.props.translations.location.bookLocation}
                </button>
                <button onClick={() => {window.location = '#office-space'}} className="secondary-button">
                    {this.props.translations.location.viewOfficeSpaces}
                </button>

                <div className="not-booked-title">{this.props.translations.location.availableOfficeSpaces}</div>

                {officeSpaceCount.desk > 0 ?
                    (<div className="not-booked-office-space-item">
                        <div className="not-booked-office-space-item-label">{this.props.translations.location.desks}</div>
                        <div className="not-booked-office-space-item-value">{officeSpaceCount.desk}</div>
                    </div>)
                    :
                    null
                }
                {officeSpaceCount.private > 0 ?
                    (<div className="not-booked-office-space-item">
                        <div className="not-booked-office-space-item-label">{this.props.translations.location.privateOffices}</div>
                        <div className="not-booked-office-space-item-value">{officeSpaceCount.private}</div>
                    </div>)
                    :
                    null
                }
                {officeSpaceCount.meeting > 0 ?
                    (<div className="not-booked-office-space-item">
                        <div className="not-booked-office-space-item-label">{this.props.translations.location.meetingRooms}</div>
                        <div className="not-booked-office-space-item-value">{officeSpaceCount.meeting}</div>
                    </div>)
                    :
                    null
                }
                {officeSpaceCount.event > 0 ?
                    (<div className="not-booked-office-space-item">
                        <div className="not-booked-office-space-item-label">{this.props.translations.location.eventRooms}</div>
                        <div className="not-booked-office-space-item-value">{officeSpaceCount.event}</div>
                    </div>)
                    :
                    null
                }
            </div>
        </Sidebar>;
    }

    fetchBookingOptions = () => {
      let time_date = {
        fromDate: this.props.selectedOptions.startDate,
        toDate: this.props.selectedOptions.endDate,
        weekDays: this.props.selectedOptions.selectedWeekDays,
        hoursFrom: this.props.selectedOptions.hoursFrom,
        hoursUntil: this.props.selectedOptions.hoursUntil,
      };
      this.props.fetchBookingOptions(
        this.props.match.params.id,
        this.props.selectedRoom !== null ? this.props.selectedRoom.id : null,
        this.props.selectedRoom !== null ? this.props.selectedProduct.id : null,
        time_date);
    }

    showVoucherBalance = (value) => {
        if(this.state.showVoucherModal === false && this.state.shownVoucherModal === false) {
            this.setState({
                showVoucherModal: true,
                voucherBalance: value
            });
        }
    }

    onModalClose = () => {
        this.setState({
            showVoucherModal: false,
            shownVoucherModal: true,
        });
    }

    onVoucherDelete = () => {
        this.setState({
            showVoucherModal: false,
            shownVoucherModal: false,
        });
    }

    componentWillUnmount() {
      this.props.closeBookingOptions();
      this.historyListener();
    }

    render() {

        let haveBookings = false;
        (this.props.officeDetails.size > 0 && this.props.officeDetails.toJS().bookings.length > 0) ? haveBookings = true : haveBookings = false;
        const bookedStatus = (this.state.rendering === false && haveBookings) ? true : false;

        if(this.state.rendering === true || this.props.translations === null)
        {
          return (
            <div className="officeDetailsLoader">
            </div>
          );
        }

        let getSidebar = '';
        if (!bookedStatus || this.props.bookingOptionsOpened) {
            if (this.props.bookingOptionsOpened) {
                getSidebar = <Sidebar>
                    <AddBookingSidebar
                        datePickerExpanded={this.props.datePickerExpanded}
                        bookingRequest={this.props.bookingRequest}
                        bookingRequestResponse={this.props.bookingRequestResponse}
                        bookingRequestResponseErrors={this.props.bookingRequestResponseErrors}
                        sidePopupOpened={this.props.sidePopupOpened}
                        openSidePopup={this.props.openSidePopup}
                        closeSidePopup={this.props.closeSidePopup}
                        heroPopupErrorOpened={this.props.heroPopupErrorOpened}
                        openHeroSidePopup={this.props.openHeroSidePopup}
                        closeHeroSidePopup={this.props.closeHeroSidePopup}
                        toggleDateExpanded={this.props.toggleDateExpanded}
                        officeTypes={this.props.officeTypesData.toJS()}
                        officeLocation={this.props.officeDetails.toJS()}
                        selectedProductId={this.props.selectedProductId}
                        redeemVoucher={this.props.redeemVoucher}
                        clearVoucher={this.props.clearVoucher}
                        selectProduct={(productId, productType) => {this.props.setSelectedProduct({id: productId, type: productType});}}
                        _fetchBookinOptions={this.fetchBookingOptions}
                        onFinishBooking={ this.props.onFinishBooking }
                        showVoucherBalance={this.showVoucherBalance}
                        onVoucherDelete={this.onVoucherDelete}
                        trans={this.props.translations}
                    />
                </Sidebar>;
            }
            else {
                getSidebar = this.getDefaultSidebar();
            }

        } else {
            getSidebar = this.getBookedSidebar();
        }

        return (
            <div className="location-container">
                <section className="location-content">
                    <Scrollbars className="styleScroll">
                        <OfficeDetails
                            amenities={this.props.amenities.toJS()}
                            services={this.props.services.toJS()}
                            details={this.props.officeDetails.toJS()}
                            houseRules={this.props.houseRules.toJS()}
                            openSidePopup={this.props.openSidePopup}
                            bookingSection={this.props.bookingSection}
                            selectProduct={(productId, productType) => {
                              this.props.resetBooking();
                              let room = {};
                              switch(productType)
                              {
                                case 'desk':
                                    room.id = 1;
                                    room.type = productType;
                                    room.typeName = this.props.translations.location.desk;
                                  break;
                                case 'private':
                                    room.id = 2;
                                    room.type = productType;
                                    room.typeName = this.props.translations.location.privateOffice;
                                  break;
                                case 'meeting':
                                    room.id = 3;
                                    room.type = productType;
                                    room.typeName = this.props.translations.location.meetingRoom;
                                  break;
                                case 'event':
                                    room.id = 4;
                                    room.type = productType;
                                    room.typeName = this.props.translations.location.eventRoom;
                                  break;
                                default:
                                break;
                              }
                              this.props.openBookingOptions();
                              this.props.setSelectedProduct({id: productId, type: productType});
                              this.props.setSelectedRoom(room);
                              this.setState({step: this.state.step++}, () => {
                                this.fetchBookingOptions();
                              });
                            }}
                            trans={this.props.translations}
                        />
                    </Scrollbars>
                </section>
                <ModalDialog
                    modalOpened={this.state.showVoucherModal}
                    className="--cancel-booking-modal"
                    onModalClose={null}
                    label="giftVoucher">
                    <ShowVoucherBalance
                        onAction={this.onModalClose}
                        trans={this.props.translations}
                        balanceValue={this.state.voucherBalance}
                    />
                </ModalDialog>
                { getSidebar }
            </div>
        );
    }
}

export default withRouter(Location);
