import Immutable, { fromJS } from 'immutable';
import { SET_MOST_ACTIVE_HEROES, SET_MOST_ACTIVE_HEROES_IS_LOADING } from '../../actions/dashboard/most-active-heroes';

const initialState = Immutable.Map({
  activeHeroes: fromJS([]),
  isLoading: false,
});

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_MOST_ACTIVE_HEROES:
      return state.setIn(['activeHeroes'], fromJS(action.heroes));
    case SET_MOST_ACTIVE_HEROES_IS_LOADING:
      return state.setIn(['isLoading'], action.isLoading);
    default:
      return state;
  }
};
