import ChatService from '../services/chat.service';
import { logout as IntercomLogout } from '../intercomLoader';

const LogoutHero = (props) => {
  const { clearHeroAuthenticationData } = props;
  const { isWmsApp } = window.__env;
  ChatService.close();
  clearHeroAuthenticationData(false);
  localStorage.removeItem('access-key');
  IntercomLogout();
  localStorage.removeItem('selected-vertical');
  localStorage.removeItem('first-name');
  localStorage.removeItem('last-name');
  localStorage.removeItem('impersonatingHero');
  window.location.href = isWmsApp ? '/' : '/dashboard';
};

export default LogoutHero;
