const formatComment = (comments) => {
  let formattedComments = [];

  for (let i = 0; i < Object.keys(comments).length; i += 1) {
    const comment = comments[i];
    const { author } = comment;
    formattedComments.push({
      author: {
        fullName: `${author.first_name} ${author.last_name}`,
        avatar: author.profile_picture_url,
        heroInitials: author.hero_initials,
      },
      commentId: comment.id,
      parentCommentId: comment.parent_comment_id,
      authorId: comment.hero_id,
      created: comment.created_at,
      isReply: !!comment.parent_comment_id,
      content: comment.content,
    });

    if (comment.replies && comment.replies.length > 0) {
      formattedComments = formattedComments.concat(formatComment(comment.replies));
    }
  }
  return formattedComments;
};

export default formatComment;
