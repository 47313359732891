import api from '../../components/community/utils/api';

export const report = reportData => dispatch => {
  return new Promise((resolve, reject) => {
    const formData = new FormData();
    formData.append('data', reportData);
    api
      .post(`/report`, reportData)
      .then(response => {
        resolve(response.data);
      })
      .catch(({ response }) => {
        reject(response.data);
      });
  });
};

export default report;
