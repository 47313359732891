import React, { useEffect, useRef, useState } from 'react';
import { Marker } from 'react-leaflet';

const ExtendedMarker = props => {
  const { isSelected, selectedProductType } = props;
  const markerRef = useRef();
  const [hasPinSelected, setPinSelected] = useState(false);
  const [hasChangedProductType, setChangeProductType] = useState(1);
  useEffect(() => {
    setPinSelected(true);
    setChangeProductType(selectedProductType);
  }, [props.isSelected]);
  useEffect(() => {
    if (hasPinSelected) {
      setChangeProductType(props.selectedProductType);
    }
  }, [props.selectedProductType]);

  if (isSelected && markerRef.current) {
    markerRef.current.openPopup();
  }
  if (selectedProductType !== hasChangedProductType && markerRef.current) {
    if (markerRef.current.isPopupOpen()) {
      markerRef.current.closePopup();
    }
  }

  return <Marker ref={markerRef} {...props} />;
};
export default ExtendedMarker;
