import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { translations } from '../../selectors/translations';

const createCookie = (name, value, days) => {
  let expires;
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = `; expires=${date.toGMTString()}`;
  } else {
    expires = '';
  }
  document.cookie = `${encodeURIComponent(name)}=${encodeURIComponent(value)}${expires}; path=/`;
};
const readCookie = name => {
  const nameEQ = `${encodeURIComponent(name)}=`;
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return decodeURIComponent(c.substring(nameEQ.length, c.length));
  }
  return null;
};
function CookieBanner(props) {
  const [cookieBanner, setCookieBanner] = useState(false);
  useEffect(() => {
    if (readCookie('cookie-consent')) {
      setCookieBanner(true);
    }
  }, []);

  return (
    !cookieBanner && (
      <div id="cookie-notification" className="cookie-container">
        <div className="cookie-content">
          <p>
            {_.get(
              props.translations,
              'cookieTerms',
              'Workero.com uses cookies and plug-ins to make the site simpler to use. Browsing our website means you consent to the usage of cookies and plug-ins. For more information, see our'
            )}
            <a
              className="cookie-privacy-policy"
              id="privacy-link"
              href={`${window.__env.public_site}/cookies`}
              rel="noreferrer noopener"
              target="_blank"
            >
              {_.get(props.translations, 'cookiePrivacyPolicy', ' Privacy Policy')}
            </a>
          </p>
          <span
            className="button cookie-button"
            id="cookie-btn"
            onClick={() => {
              createCookie('cookie-consent', '1', 360);
              setCookieBanner(true);
            }}
          >
            {_.get(props.translations, 'cookieAccept', 'Ok')}
          </span>
        </div>
      </div>
    )
  );
}
const mapStateToProps = state => ({
  translations: _.get(translations(state), 'cookieBanner', {}),
});
const mapDispatchToProps = dispatch => ({});
export default connect(mapStateToProps, mapDispatchToProps)(CookieBanner);
