import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import appStore from './reducers/index';
import fetchMiddleware from './reducers/middlewares/fetchMiddleware';
import fetchGeneralDataMiddleware from './reducers/middlewares/fetchGeneralDataMiddleware';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(appStore, composeEnhancers(applyMiddleware(thunk, fetchMiddleware, fetchGeneralDataMiddleware)));

export default store;
