import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import addLoader from '../dashboard/utils/add-loader';

const LoadMore = ({ fetchVouchers, trans }) => (
  <div className="all-communities__load_more">
    <button id="all-communities-load-more" type="button" className="dashboard-button dashboard-button--border" onClick={fetchVouchers}>
      {_.get(trans, 'dashboard.loadMore', '')}
    </button>
  </div>
);

LoadMore.propTypes = {
  fetchVouchers: PropTypes.func.isRequired,
  trans: PropTypes.shape({}).isRequired,
};

export default addLoader(LoadMore);
