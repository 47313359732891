import _ from 'lodash';
// imageHelper.js

const sizedImage = (url, width, height, id) => {
  let sizedImageUrl = _.replace(url, "{width}", width);
  sizedImageUrl = _.replace(sizedImageUrl, "{height}", height);
  if (id) {
    sizedImageUrl = _.replace(sizedImageUrl, "{id}", id);
  }
  return sizedImageUrl;
}

export default sizedImage;
