import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import moment from 'moment';
import _ from 'lodash';

class OfficeBooked extends React.Component {

  getOfficeType = () => {
    let selectedType  = null;
    let roomName      = null;
    if(this.props.details.toJS().desk !== undefined)
    {
      selectedType  = 'desk';
      roomName      = this.props.details.toJS().desk.name;
    }
    if(this.props.details.toJS().privateOffice !== undefined)
    {
      selectedType = 'private';
      roomName      = this.props.details.toJS().privateOffice.name;
    }
    if(this.props.details.toJS().eventRoom !== undefined)
    {
      selectedType = 'event';
      roomName      = this.props.details.toJS().eventRoom.name;
    }
    if(this.props.details.toJS().meetingRoom !== undefined)
    {
      selectedType = 'meeting';
      roomName      = this.props.details.toJS().meetingRoom.name;
    }
    return (
      <div className="office-booked-details">
        <div className="office-booked-title">{this.props.trans.bookingPage.officeSpace}</div>
        <div className={classnames(`icon-${selectedType}`)}>{roomName}</div>
      </div>
    )
  }

  getBookingPeriod (selectedOptions) {
    if (typeof selectedOptions.endDate !== 'undefined' && typeof selectedOptions.startDate !== 'undefined') {
      if(moment.unix(selectedOptions.startDate).format('MMMM DD, YYYY') === moment.unix(selectedOptions.endDate).format('MMMM DD, YYYY')) {
        return (
          <div className="office-booked-details-description">{moment.unix(selectedOptions.endDate).format('MMMM DD, YYYY')}</div>
        )
      }
      return (
        <div className="office-booked-details-description">{moment.unix(selectedOptions.startDate).format('MMMM DD')} - {moment.unix(selectedOptions.endDate).format('MMMM DD, YYYY')}</div>
      )
    }
    return null;
  }

  getWorkDays (weekDays) {
    const { trans:translations } = this.props;
    const weekDaysMap = _.get(translations, 'location.weekDaysMap', [] )
    if (typeof weekDays !== 'undefined') {
      let weekDaysLabel = [];
      weekDays.map((weekday) => {
        weekDaysLabel.push(weekDaysMap[weekday]);
        return null;
      });
      return weekDaysLabel.join(', ');
    }
    return null;
  }

  getWorkingHours (hours) {

    if (typeof hours !== 'undefined') {
      let hoursStart  = hours.from.hour + ':' + hours.from.minute;
      let hoursEnd    = hours.to.hour + ':' + hours.to.minute;
      return hoursStart + ' - ' + hoursEnd;
    }
    return null;
  }

  render() {
    let amenitiesList =  this.props.details.toJS().amenities.map(amenity => (
      <div className="amenity-available" key={amenity.id}>
        <div className="label">{amenity.name}</div>
        <div className="sublabel">{this.props.trans.bookingPage.complementary}</div>
      </div>
    ));

    let servicesList =  this.props.details.toJS().services.map(service => {

      if(service.totalPrice !== undefined && service.totalPrice > 0)
      {
        let subLabel = null;
        if(service.priceType === 'time')
        {
          if(service.hours > 0 || service.days > 0 || service.weeks > 0)
          {
             let subLabelItems = [];
             if(service.hours > 0)
             {
               subLabelItems.push(<div key={"h"+service.id}>{service.priceHour.toFixed(2).toString().replace(".", window.__env.decimalSeparator)}€/hour × {service.hours}</div>);
             }
             if(service.days > 0)
             {
               subLabelItems.push(<div key={"d"+service.id}>{service.priceDay.toFixed(2).toString().replace(".", window.__env.decimalSeparator)}€/day × {service.days}</div>);
             }
             if(service.weeks > 0)
             {
               subLabelItems.push(<div key={"w"+service.id}>{service.priceWeek.toFixed(2).toString().replace(".", window.__env.decimalSeparator)}€/week × {service.weeks}</div>);
             }
             subLabel = <div className="sublabel">{subLabelItems}</div>
          }
        }
        return (
        <div key={service.id} className="service-available-nonfree">
          <div className="label">{service.name}</div>
          <div className="price">{service.totalPrice.toFixed(2).toString().replace(".", window.__env.decimalSeparator)}€</div>
          {subLabel}
        </div>
        );
      }
      else {
        if(service.priceUnit === undefined || service.priceUnit === 0 || service.priceUnit === null)
        {
          return (
          <div key={service.id}>
            <div className="service-available">{service.name}
                <div className="sublabel">{this.props.trans.bookingPage.complementary}</div>
            </div>
          </div>
          );
        }
        else
        {
          return (
          <div key={service.id}>
            <div className="service-available">{service.name}
                <div className="sublabel">{service.priceUnit.toFixed(2).toString().replace(".", window.__env.decimalSeparator)}€/{service.priceType}</div>
            </div>
          </div>
          );
        }
      }
    });

    let equipmentsList =  this.props.details.toJS().equipments.map(equipment => {

      if(equipment.totalPrice !== undefined && equipment.totalPrice > 0)
      {
        let subLabel = null;
        if(equipment.priceType === 'time')
        {
          if(equipment.hours > 0 || equipment.days > 0 || equipment.weeks > 0)
          {
             let subLabelItems = [];
             if(equipment.hours > 0)
             {
               subLabelItems.push(<div key={"h"+equipment.id}>{equipment.priceHour.toFixed(2).toString().replace(".", window.__env.decimalSeparator)}€/hour × {equipment.hours}</div>);
             }
             if(equipment.days > 0)
             {
               subLabelItems.push(<div key={"d"+equipment.id}>{equipment.priceDay.toFixed(2).toString().replace(".", window.__env.decimalSeparator)}€/day × {equipment.days}</div>);
             }
             if(equipment.weeks > 0)
             {
               subLabelItems.push(<div key={"w"+equipment.id}>{equipment.priceWeek.toFixed(2).toString().replace(".", window.__env.decimalSeparator)}€/week × {equipment.weeks}</div>);
             }
             subLabel = <div className="sublabel">{subLabelItems}</div>
          }
        }
        return (
        <div key={equipment.id} className="service-available-nonfree">
          <div className="label">{equipment.name}</div>
          <div className="price">{equipment.totalPrice.toFixed(2).toString().replace(".", window.__env.decimalSeparator)}€</div>
          {subLabel}
        </div>
        );
      }
      else {

        if(equipment.totalPrice === undefined || equipment.totalPrice === 0)
        {
          return (
          <div key={equipment.id}>
            <div className="service-available">{equipment.name}
                <div className="sublabel">{this.props.trans.bookingPage.complementary}</div>
            </div>
          </div>
          );
        }

        return (
        <div key={equipment.id}>
          <div className="service-available">{equipment.name}
              <div className="sublabel">{equipment.price.toFixed(2).toString().replace(".", window.__env.decimalSeparator)}€/{equipment.priceType}</div>
          </div>
        </div>
        );
      }
    });

    let rulesList = this.props.details.toJS().houseRules.map(rule =>

      (
        <div
          key={rule.id + rule.fileUrl}
          className={classnames({'attachments': rule.fileUrl, 'rule-available': !rule.fileUrl, 'rule-unavailable': rule.fileUrl})}>
            {_.capitalize(rule.name)}
            {rule.fileUrl ? <div className=""><a target="_blank" data-label={_.get(this.props.trans, 'chat.view', 'chat.view')} className="office-rules attachments" href={rule.fileUrl}> </a></div> : ''}
        </div>
      )
    );

    return (
      <div className="office-booked">
        <div className="office-booked-list">
          {this.getOfficeType()}
          <div className="office-booked-details office-booked-details-with-titles">
              <div className="office-booked-title">{this.props.trans.bookingPage.bookingPeriod}</div>
              { this.getBookingPeriod({'startDate': this.props.details.toJS().fromDate, 'endDate': this.props.details.toJS().toDate}) }
              <div className="office-booked-title">{this.props.trans.bookingPage.chosenWorkingDays}</div>
              <div className="office-booked-details-description">{this.getWorkDays(this.props.details.toJS().weekDays)}</div>
              <div className="office-booked-title">{this.props.trans.bookingPage.chosenWorkingHours}</div>
              <div className="spacer-5"></div>
              {this.getWorkingHours(this.props.details.toJS().hours)}
              {rulesList.length > 0 ? (
                  <>
                      <div className="office-booked-title">{this.props.trans.bookingPage.houseRules}</div>
                      <div className="spacer-5"></div>
                      <div className="booking-office-rules">{ rulesList }</div>
                  </>
              ) : null}
          </div>
          <div className="booking-office-services">
            <div className="office-booked-title">{this.props.trans.bookingPage.bookingAmenitiesAndServices}</div>
              <div className="spacer-5"></div>
              { amenitiesList }
              { servicesList }
              {equipmentsList.length > 0 ? <div className="office-booked-title">{this.props.trans.bookingPage.bookingEquipments}</div> : null}
              <div className="spacer-5"></div>
              { equipmentsList }
          </div>
        </div>
      </div>
    )
  }
};

OfficeBooked.propTypes = {
  details: PropTypes.object.isRequired,
};

export default OfficeBooked;
