import _ from 'lodash';
import nl2br from 'react-nl2br';
import Truncate from 'react-truncate';
import React from 'react';
import classnames from 'classnames';
import pdfPreview from '../../../assets/images/pdf-preview.jpg';
import downloadArrow from '../../../assets/svg/dashboard/download-arrow.svg';
import SubmissionForm from '../components/media/submission-form';

export const createArticleHeaderBlock = data => {
  const type = _.get(data, 'type', '');
  const title = _.get(data, 'title', '');
  const date = _.get(data, 'date', '');
  return (
    <>
      <div className="media-header">
        <a className="media-header__previous" href="/dashboard">
          Dashboard
        </a>
        <span className="dot_between">•</span>
        <span className="media-header__type">{type}</span>
      </div>
      <div className="media__title">{title}</div>
      <div className="media__date">{date}</div>
    </>
  );
};

export const createArticleDescriptionBlock = data => {
  return <div className="media__content-description">{nl2br(data)}</div>;
};

export const createArticleGenericBlock = data => {
  const heading = _.get(data, 'heading', '');
  const content = _.get(data, 'content', '');
  return (
    <div className="media__content-generic">
      <h2>{heading}</h2>
      <div className="media__content-generic-data">{nl2br(content)}</div>
    </div>
  );
};

export const createArticleImageBlock = data => {
  const source = _.get(data, 'source', '');
  const alt = _.get(data, 'alt', '');
  return (
    <div className="media__content-generic">
      <img src={source} alt={alt} />
    </div>
  );
};

export const createArticleVideoBlock = data => {
  return (
    <div className="media__content-generic">
      <div className="media__content-video-wrapper">
        <iframe
          className="media__content-generic-video"
          title="video"
          src={data}
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; "
          allowFullScreen
        />
      </div>
    </div>
  );
};

export const createArticleAttachmentBlock = data => {
  const hasForm = _.get(data, 'form', 0);
  if (hasForm) {
    return <SubmissionForm data={data} />;
  }

  const link = _.get(data, 'link', '');
  if (link == null) return null;

  const source = _.get(data, 'source', '');
  const sourceTitle = _.get(data, 'sourceTitle', '');

  const linkTitle = _.get(data, 'linkTitle', '');
  const attachmentCount = _.get(data, 'attachmentCount', '');

  return (
    <>
      <div className="media__attachment">
        {source ? (
          <div className="media__attachment-container">
            {sourceTitle ? <h4 className="attachment-title">{sourceTitle}</h4> : null}
            <div className="attachment-header">
              <span className="attachment-count">{attachmentCount}</span>
              <a href={source} download={sourceTitle} target="_blank" rel="noopener noreferrer">
                <span className="attachment-download">DOWNLOAD ATTACHMENT</span>
              </a>
            </div>

            <div className="attachment-link">
              <a href={source} download={sourceTitle} target="_blank" rel="noopener noreferrer">
                <img className="attachment-pdf" src={pdfPreview} alt={sourceTitle} />
                <div className="attachment-overlay">
                  <div className="attachment-down-arrow">
                    <img src={downloadArrow} alt="Download" />
                  </div>
                </div>
              </a>
            </div>
          </div>
        ) : null}
        <div className="media__link-container">
          <h4>{linkTitle}</h4>
          <a href={link} target="_blank" rel="noopener noreferrer">
            <div className="link-container">
              <Truncate lines={1} ellipsis={<>... </>}>
                {link}
              </Truncate>
            </div>
          </a>
        </div>
      </div>
    </>
  );
};

export const createArticleInfoGenericBlock = data => {
  return (
    <div className="infocard__generic">
      <div className="generic__title">{data.label}</div>
      <div className="generic__data">{data.content}</div>
    </div>
  );
};

const handleClick = link => {
  if (link == null) {
    return;
  }
  if (link) {
    window.open(link, '_blank');
  } else {
    const element = document.getElementById('apply-form');
    element.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
  }
};

export const createArticleInfoApplyButton = data => {
  const { link } = data;
  const { applied } = data;
  const { form } = data;

  if (applied) {
    return <h4 className="infocard__already-applied">{data.appliedText}</h4>;
  }

  return (
    <button
      type="button"
      className={classnames('infocard__button button-yellow', { 'infocard__button-disabled': !link && !form })}
      onClick={handleClick.bind(null, link)}
    >
      {data.label}
    </button>
  );
};

const buildTag = ({ value, label }) => (
  <div key={value} className="dashboard__tag">
    {label}
  </div>
);

export const createArticleTagsBlock = data => {
  return (
    <div className="tags__wrapper">
      <div className="tags__title">{data.label}</div>
      <div className="dashboard__tags">{data.content.map(buildTag)}</div>
    </div>
  );
};

const buildArticleFunctions = {
  'header-block': createArticleHeaderBlock,
  'description-block': createArticleDescriptionBlock,
  'skills-block': createArticleGenericBlock,
  'offer-block': createArticleGenericBlock,
  'scope-block': createArticleGenericBlock,
  'image-block': createArticleImageBlock,
  'video-block': createArticleVideoBlock,
  'pricing-conditions-block': createArticleDescriptionBlock,
  'attachment-block': createArticleAttachmentBlock,
};

const buildInfoFunctions = {
  'apply-button-block': createArticleInfoApplyButton,
  'job-type-block': createArticleInfoGenericBlock,
  'start-date-block': createArticleInfoGenericBlock,
  'verticals-block': createArticleTagsBlock,
  'tags-block': createArticleTagsBlock,
  'project-location-block': createArticleInfoGenericBlock,
  'project-length-block': createArticleInfoGenericBlock,
  'project-remuneration-block': createArticleInfoGenericBlock,
  'event-price-block': createArticleInfoGenericBlock,
};

export const buildArticleBlock = block => {
  const blockFunction = buildArticleFunctions[block.blockName];
  if (_.isFunction(blockFunction) && block.data) {
    return <div key={block.blockName}>{blockFunction(block.data)}</div>;
  }
  return null;
};

export const buildInfoBlock = block => {
  const blockFunction = buildInfoFunctions[block.blockName];
  if (_.isFunction(blockFunction) && block.data) {
    return (
      <div className="data__container" key={block.blockName}>
        {blockFunction(block.data)}
      </div>
    );
  }
  return null;
};
