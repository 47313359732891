export const companySelector = state => {
  return state.newCompanyProfile.get('company');
};

export const companyLogoSelector = state => {
  return state.newCompanyProfile.get('companyLogo');
};

export const jobsSelector = state => {
  return state.newCompanyProfile.get('jobs');
};

export const tagsSelector = state => {
  return state.newCompanyProfile.get('tags');
};

export const productTypesSelector = state => {
  return state.newCompanyProfile.get('productTypes');
};

export const isLoadingSelector = state => {
  return state.newCompanyProfile.get('isLoading');
};
