import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './addBookingCheckboxesGroup.scss';

class AddBookingCheckboxesGroup extends Component {

  render(){
    let {groupName, groupLabel, onChange, items, selectedItems} = this.props;
    let optionInputColumns = [];
    let column = 0;

    for(let i = 0; i < items.size; i++)
    {
      if(optionInputColumns[column] === undefined)
      {
        optionInputColumns[column] = [];
      }

      optionInputColumns[column].push(
        <label key={items.toJS()[i]['id']}>
              <input
                type='checkbox'
                name={items.toJS()[i]['name']||groupName}
                value={items.toJS()[i]['id']}
                checked={items.toJS()[i]['checked'] || (selectedItems.includes(items.toJS()[i]['id']) && items.toJS()[i]['disabled'] === false)}
                onChange={(e) => { if(items.toJS()[i]['disabled'] === false){onChange(items.toJS()[i]['id'], e.target.checked);}}} />
              <span className={items.toJS()[i]['disabled'] === true ? 'disabled' : ''}>{items.toJS()[i]['label']}</span>
          </label>
      );

      if((i+1) % 4 === 0)
      {
        column++;
      }
    }

    let optionInputColumnsRendered = optionInputColumns.map((column, index) => (
      <div key={'cl'+index} className="add-booking-checkboxes-column">{column}</div>
    ));

    return (
      <div className="add-booking-checkboxes-group">
        <h3 className={this.props.titleClassName}>{groupLabel}</h3>
        <div className="add-booking-checkboxes-group-list">
          {optionInputColumnsRendered}
        </div>
      </div>
    )
  }

}


AddBookingCheckboxesGroup.propTypes = {
  titleClassName: PropTypes.string,
  groupName: PropTypes.string,
  groupLabel: PropTypes.string,
  items: PropTypes.object,
  selectedItems: PropTypes.array,
  onChange: PropTypes.func
};

export default AddBookingCheckboxesGroup;
