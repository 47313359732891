import { connect } from 'react-redux';
import AmenitiesAndServices from './AmenitiesAndServices';
import { selectedProductIdSelector, selectedProductTypeSelector } from '../../../../../selectors/whiteLabel/booking';
import { mapProductsSelector } from '../../../../../selectors/whiteLabel/products';

const mapStateToProps = state => {
  return {
    translations: state.translations.booking,
    amenities: state.location.get('details').get('amenities'),
    selectedProductId: selectedProductIdSelector(state),
    products: mapProductsSelector(state),
    selectedProductType: selectedProductTypeSelector(state),
    officeMeetingRooms: state.location
      .get('details')
      .get('products')
      .get('meetingRooms'),
  };
};

const mapDispatchProps = dispatch => {
  return {};
};

export default connect(mapStateToProps, mapDispatchProps)(AmenitiesAndServices);
