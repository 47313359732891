import React, {Component} from 'react';
import moment from 'moment';
import {Link, withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';

import FilterContainer from "../filterContainer";
import FloorPlan from "../officeDetailsFloorPlan";
import HeroesBookedList from "../heroesBookedList";
import _ from "lodash";
import {DATE_FORMAT} from "../../../../constants";

class OfficeDetails extends Component {
  props;
  context;

  static contextTypes = {
    router: PropTypes.object.isRequired
  };

  constructor() {
    super();
    this.DATE_FORMAT = 'YYYY-MM-DD';
    this.state = {
      officeTypes: []
    }

    this.changeDate = this.changeDate.bind(this);
    this.selectFloor = this.selectFloor.bind(this);
    this.selectProductType = this.selectProductType.bind(this);
    this.selectFeatures = this.selectFeatures.bind(this);
  }

  componentDidMount() {
    //extract floors and fetch products
    const {
      location,
      selectedDate,
      officeId,
      details,
      setFloorPlans,
      setSelectedFloorIndex,
      selectedFloorIndex,
      fetchBuildingHeroes,
      selectedFeatures,
      setFeatures,
      translations,
      goToParkingFloor,
      parkingBookingDates,
      setSelectedDate,
      setSelectedProductId,
      setNoParkingsAvailable,
      selectedProductType} = this.props;
    const { white_label } = details;
    const { fetchMapProducts, setSelectedProductType, fetchHeroesBookings } = this.props;
    const floorPlans = _.get(white_label, 'floor_plans', []);
    const floors = [];
    let index = null;

    floorPlans.map(function(plan) {
      const nbParkingSpots = _.get(plan, 'no_products.parking', 0);
      floors.push({
        id: plan.id,
        label: plan.floor_number,
        image: plan.image_url,
        products: plan.products,
        planWidth: plan.width,
        planHeight: plan.height,
        hasParkingSpots: nbParkingSpots > 0
      });
    });
    const fromHero = _.get(location, 'state.fromHero', false);
    const floorId = _.get(location, 'state.floorId', false);
    setFloorPlans(floors);
    let selectedIndex = 0;
    if (fromHero) {
      floors.map(function(plan,index){
        if(plan.id === floorId) {
          selectedIndex = index;
          return true;
        }
      });
    } else {

      if (goToParkingFloor) {
        // display the first floor with parking spots
        index = floors.findIndex(obj => {
          return obj.hasParkingSpots === true;
        });
      }
      const floorIndex = index >= 0 && !_.isNull(index) ? index : selectedFloorIndex;

      selectedIndex = floorIndex ? floorIndex : floorPlans.reduce(function(lowest, next, index) {
            return next < _.get(floorPlans, 'lowest.floor_number') ? index : lowest; },
          0);
    }
    setSelectedFloorIndex(selectedIndex);
    const floorNumber = _.get(floorPlans, `${selectedIndex}.floor_number`);

    const features = _.get(white_label, 'features', []);
    const defaultFeature = {id: 0, title: _.get(translations, 'whiteLabel.allFeatures', 'All features')};
    setFeatures([defaultFeature, ...features]);
    const startDate = _.get(selectedDate, 'from', null);
    const endDate = _.get(selectedDate, 'to', null);
    const startDateParam = _.get(parkingBookingDates, 'fromDate', null) || moment.utc(startDate, DATE_FORMAT).unix();
    const endDateParam = _.get(parkingBookingDates, 'toDate', null) || moment.utc(endDate, DATE_FORMAT).unix();
    let listOfficeTypes = [];
    _.map(details.products, (data, key) => {
      if (data.length) {
        switch (key) {
          case 'desks' :
            listOfficeTypes.push({ id: 1, label: _.get(translations, 'booking.options.officeTypes.desk', ''), groupName: 'officesTypes' })
            break;
          case 'meetingRooms':
            listOfficeTypes.push({ id: 3, label: _.get(translations, 'booking.options.officeTypes.meeting', ''), groupName: 'officesTypes' })
            break;
          case 'parking':
            listOfficeTypes.push({ id: 5, label: _.get(translations, 'booking.options.officeTypes.parking', ''), groupName: 'officesTypes' })
            break;
          default:
            break;
        }
      }

    })

    //set product type filter to parking
    let productType = _.get(listOfficeTypes, '0.id');
    if (goToParkingFloor) {
      productType = 5;
    } else if (selectedProductType) {
      productType = selectedProductType;
    }
    if (listOfficeTypes.length) {
      setSelectedProductType(productType);
    }

    this.setState({officeTypes: listOfficeTypes})
    fetchMapProducts(officeId, floorNumber, productType, startDateParam, endDateParam, selectedFeatures).then(response => {
      if (goToParkingFloor) {
        const hasProductsOfType = !!_.size(_.filter(response, index => index.productableType === 5));
        const selectedProduct = _.first(
            _.filter(response, function(index) {
              if (index.status && index.status === 'available' && index.productableType === 5) {
                return index;
              }
            })
        );
        setSelectedProductId(_.get(selectedProduct, 'id', null));
        if (_.isEmpty(selectedProduct)) {
          setNoParkingsAvailable(true);
        }
      }
    });
    fetchHeroesBookings(officeId, startDateParam, endDateParam, productType);
    fetchBuildingHeroes(officeId);

    if(goToParkingFloor) {
      const parkingBookingStartDate = _.get(parkingBookingDates, 'fromDate', null);
      const parkingBookingEndDate = _.get(parkingBookingDates, 'toDate', null);

      setSelectedDate(
          moment.unix(parkingBookingStartDate).format(DATE_FORMAT),
          moment.unix(parkingBookingEndDate).format(DATE_FORMAT)
      );
    }
  }

  //used for refetch on date change
  componentDidUpdate(prevProps) {
    const {selectedDate, officeId, selectedProductType, selectedFloorIndex, floorPlans, selectedFeatures, mapProducts} = this.props;
    const {fetchMapProducts, fetchHeroesBookings, setSelectedProductType} = this.props;
    const {selectedDate:oldSelectedDate, selectedFeatures:oldSelectedFeatures, mapProducts:oldMapProducts, selectedProductType:oldSelectedProductType} = prevProps;
    const floorNumber = _.get(floorPlans, `${selectedFloorIndex}.label`);
    const startDate = _.get(selectedDate, 'from', null);
    const endDate = _.get(selectedDate, 'to', null);
    const startDateParam = moment.utc(startDate, DATE_FORMAT).unix();
    const endDateParam = moment.utc(endDate, DATE_FORMAT).unix();
    if (oldSelectedDate.from !== selectedDate.from || oldSelectedDate.to !== selectedDate.to) {
      fetchMapProducts(officeId, floorNumber, selectedProductType, startDateParam, endDateParam, selectedFeatures);
      fetchHeroesBookings(officeId, startDateParam, endDateParam,selectedProductType );
    }
    if (oldSelectedFeatures !== selectedFeatures) {
      fetchMapProducts(officeId, floorNumber, selectedProductType, startDateParam, endDateParam, selectedFeatures);
    }
    if (_.isEmpty(oldMapProducts) && !_.isEmpty(mapProducts)) {
      const existingProductTypes = _.union(_.map(mapProducts, product => product.productableType));
      if (!_.includes(existingProductTypes, selectedProductType)) {
        const newSelectedProductType = _.get(existingProductTypes, 0);
        setSelectedProductType(newSelectedProductType);
        fetchMapProducts(officeId, floorNumber, newSelectedProductType, startDateParam, endDateParam);
      }
    }
  }

  componentWillUnmount() {
    const { goToParkingFloor, setGoToParkingFloor } = this.props;
    if (goToParkingFloor) {
      setGoToParkingFloor(false);
    }
  }

  getTitle (title, bookedStatus) {
    if (bookedStatus) {
      return <div className="location-booked-title items-style1">{this.props.translations.location.yourBookingAt} { title } <span className="success-message">{this.props.translations.location.isConfirmed}</span>. {this.props.translations.location.cantWait}</div>
    } else {
      return <h3 className="items-style1">{title}</h3>
    }
  }

  selectFloor(floorId) {
    const {
      setSelectedProduct,
      officeId,
      floorPlans,
      selectedDate,
      setSelectedFloorIndex,
      fetchMapProducts,
      selectedProductType,
      setSelectedFeatures,
      setSelectedProductType,
      fetchHeroesBookings,
      setSelectedDate
    } = this.props;
    let selectedIndex = 0;
    floorPlans.map(function(plan,index){
      if(plan.id === floorId) {
        selectedIndex = index;
        return true;
      }
    });
    setSelectedProduct(null);
    setSelectedFloorIndex(selectedIndex);
    setSelectedFeatures([0]);
    const floor = _.get(floorPlans, `${selectedIndex}.label`);
    const startDate = _.get(selectedDate, 'from', null);
    const endDate = _.get(selectedDate, 'to', null);
    const startDateParam = moment.utc(startDate, DATE_FORMAT).unix();
    const endDateParam = moment.utc(endDate, DATE_FORMAT).unix();
    fetchMapProducts(officeId, floor, selectedProductType, startDateParam, endDateParam).then(data => {
      const existingProductTypes = _.union(_.map(data, product => product.productableType));
      if (!_.includes(existingProductTypes, selectedProductType)) {
        const newSelectedProductType = _.get(existingProductTypes, 0);
        setSelectedProductType(newSelectedProductType);
        if (newSelectedProductType === 3) {
          setSelectedDate(startDate, startDate);
        }
        fetchMapProducts(officeId, floor, newSelectedProductType, startDateParam, endDateParam);
        fetchHeroesBookings(officeId, startDateParam, endDateParam, newSelectedProductType);
      }
    });
  }

  selectProductType(productType) {
    const {
      setSelectedProduct,
      officeId,
      floorPlans,
      selectedDate,
      setSelectedProductType,
      fetchMapProducts,
      selectedFloorIndex,
      selectedFeatures,
      fetchHeroesBookings,
      setSelectedDate,
      setSelectedFeatures
    } = this.props;
    setSelectedProductType(productType);
    setSelectedProduct(null);
    setSelectedFeatures([0]);
    const startDate = _.get(selectedDate, 'from', null);
    const endDate = _.get(selectedDate, 'to', null);
    if(productType === 3) {
      setSelectedDate(startDate, startDate);
    }
    const floor = _.get(floorPlans, `${selectedFloorIndex}.label`, '');
    const startDateParam = moment.utc(startDate, DATE_FORMAT).unix();
    const endDateParam = moment.utc(endDate, DATE_FORMAT).unix();
    fetchMapProducts(officeId, floor, productType, startDateParam, endDateParam, selectedFeatures);
    fetchHeroesBookings(officeId, startDateParam, endDateParam,productType );

  }

  changeDate(startDate, endDate) {
    const { setSelectedDate } = this.props;
    if (!endDate) {
      setSelectedDate(moment(startDate).format(DATE_FORMAT), moment(startDate).format(DATE_FORMAT));
    } else {
      setSelectedDate(startDate, endDate);
    }
  }

  selectFeatures(features) {
    const { setSelectedFeatures } = this.props;
    setSelectedFeatures(features);
  }

  render () {
    const { selectedFloorIndex, translations, details} = this.props;
    const { officeTypes} = this.state;
    const { name, id } = details;
    const bookedStatus = details['booked-status-location' + id];

    return (
      <div className="office-details">
        <Link to="/booking" className="office-details-back-link">{translations.location.back}</Link>
        {this.getTitle(name, bookedStatus)}
        <FilterContainer
            officeTypes={officeTypes}
            selectFloor={this.selectFloor}
            onDateChange={this.changeDate}
            selectProductType={this.selectProductType}
            selectFeatures={this.selectFeatures}
        />
        <FloorPlan selectedFloorIndex={selectedFloorIndex} />
        <HeroesBookedList selectFloor={this.selectFloor} />
      </div>
    );
  }
}

OfficeDetails.propTypes = {
  details: PropTypes.object.isRequired
};

export default withRouter(OfficeDetails);
