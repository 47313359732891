import { connect } from 'react-redux';

import toJS from '../../utils/to-js';

import CommunityInfo from './CommunityInfo';
import { communitySelector, heroIsCommunityMemberSelector } from '../../../../selectors/community/community-page';
import { openConversation } from '../../../../actions/directChat';
import { clearPopularCommunities } from '../../../../actions/community/popular-communities';
import { leaveCommunity } from '../../../../actions/community/leave-community';
import { communityEditModalSelector, communityManageModalSelector } from '../../../../selectors/community/community-modals';
import { setShowEditCommunityModal, setShowManageCommunityModal } from '../../../../actions/community/community-modals';
import { joinCommunity } from '../../../../actions/community/join-community';

const mapDispatchToProps = dispatch => ({
  openConversation: (id, hero) => dispatch(openConversation(id, hero)),
  leaveCommunity: communityId => dispatch(leaveCommunity(communityId)),
  clearPopularCommunities: () => dispatch(clearPopularCommunities()),
  setShowEditCommunityModal: data => dispatch(setShowEditCommunityModal(data)),
  setShowManageCommunityModal: data => dispatch(setShowManageCommunityModal(data)),
  joinCommunity: communitySlug => dispatch(joinCommunity(communitySlug)),
});
const mapStateToProps = state => ({
  community: communitySelector(state),
  heroProfile: state.global.get('heroProfile'),
  showCommunityEditModal: communityEditModalSelector(state),
  showCommunityManageModal: communityManageModalSelector(state),
  heroIsCommunityMember: heroIsCommunityMemberSelector(state),
});
export default connect(mapStateToProps, mapDispatchToProps)(toJS(CommunityInfo));
