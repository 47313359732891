import React from 'react';
import moment from 'moment';
import { Scrollbars } from 'react-custom-scrollbars';
import Sidebar from '../rightSidebar/rightSidebar';
import ProfileBox from './../profileBox/profileBox';
import ExperienceBox from './../experienceBox/experienceBox';
import ModalDialog from './../modalDialog/modalDialog';
import SelectBox from './../selectBox/selectBox';
import Checkbox from './checkbox';
import Immutable from 'immutable';
import classnames from 'classnames';
import TextareaAutosize from 'react-autosize-textarea';
import _ from 'lodash';

import './css/index.scss';
class AccountProfile extends React.Component {
  context;

  constructor(props) {
    super(props);

    this.state = {
      businessEmail: '',
      communicationBusinessEmail: false,
      communicationPersonalEmail: true,
      desiredEnvironment: '',
      editModalOpened: false,
      editModelValidating: false,
      editExpectationsModalOpened: false,
      editDesiredEnvironmentModalOpened: false,
      expectations: '',
      industry: null,
      phoneNumber: null,
      rendering: true,
      selectedSkills: [],
      selectedIndustryInterests: [],
      selectedIndustrySkillsInterests: [],
      firstLoad: false,
      otherSkill: '',
      profileQuestion: '',
    };

    this.onChangeProfileQuestion = this.onChangeProfileQuestion.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.setState({
      rendering: true
    });
  }

  componentDidMount () {
      this.props.fetchProfile();
      this.props.fetchSkills();
      this.props.fetchIndustries();
      this.props.fetchCountryCodes();
      this.setState({rendering: false});
      this.setState({firstLoad: true});

      if(this.props.newHero) {
        this.saveOriginalInformation();
        this.setState({editModalOpened: true});
      }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {

    let newState = this.state;
    let stateChanged = false;

    if(this.state.phoneNumber === null && nextProps.profileDetails !== null && nextProps.profileDetails.toJS().phoneNumber !== null && nextProps.countryCodes !== null)
    {
      let countryLabel = null;
      nextProps.countryCodes.toJS().map((countryCode) => {
        if(countryCode.value === nextProps.profileDetails.toJS().phoneNumber.countryCode)
        {
          countryLabel = countryCode.label;
        }
        return null;
      });
      newState.phoneNumber = {number: nextProps.profileDetails.toJS().phoneNumber.number, countryCode: {value: nextProps.profileDetails.toJS().phoneNumber.countryCode, label: countryLabel}};
      stateChanged = true;
    }

    if(this.state.selectedSkills !== undefined && this.state.selectedSkills.length === 0 && nextProps.profileDetails !== null && nextProps.profileDetails.toJS().skills.length > 0)
    {
      if(newState.firstLoad) {
        newState.selectedSkills = nextProps.profileDetails.toJS().skills;
        stateChanged = true;
      }
    }

    if(this.state.expectations === '' && nextProps.profileDetails !== null && nextProps.profileDetails.toJS().expectations !== null && nextProps.profileDetails.toJS().expectations !== '')
    {
      if(newState.firstLoad) {
        newState.expectations = nextProps.profileDetails.toJS().expectations;
        stateChanged = true;
      }
    }

    if(this.state.desiredEnvironment === '' && nextProps.profileDetails !== null && nextProps.profileDetails.toJS().desiredEnvironment !== null && nextProps.profileDetails.toJS().desiredEnvironment !== '')
    {
      if(newState.firstLoad) {
        newState.desiredEnvironment = nextProps.profileDetails.toJS().desiredEnvironment;
        stateChanged = true;
      }
    }

    if(this.state.profileQuestion === '' && nextProps.profileDetails !== null && nextProps.profileDetails.toJS().profileQuestion !== null && nextProps.profileDetails.toJS().profileQuestion !== '')
    {
      if(newState.firstLoad) {
        newState.profileQuestion = nextProps.profileDetails.toJS().profileQuestion;
        stateChanged = true;
      }
    }

    if(this.state.selectedIndustryInterests !== undefined && this.state.selectedIndustryInterests.length === 0 && nextProps.profileDetails !== null && nextProps.profileDetails.toJS().interests.length > 0)
    {
      if(newState.firstLoad) {
        let selectedIndustryInterests = [];
        nextProps.profileDetails.toJS().interests.map((interest) => {
          if(interest.industry !== undefined)
          {
            selectedIndustryInterests.push(interest.industry);
          }
          return null;
        });
        if(selectedIndustryInterests.length > 0)
        {
          newState.selectedIndustryInterests = selectedIndustryInterests;
          stateChanged = true;
        }
      }
    }

    if(this.state.selectedIndustryInterests !== undefined && this.state.selectedIndustryInterests.length === 0 && nextProps.profileDetails !== null && nextProps.profileDetails.toJS().interests.length > 0)
    {
      if(newState.firstLoad) {
        let selectedIndustryInterests = [];
        nextProps.profileDetails.toJS().interests.map((interest) => {
          if (interest.industry !== undefined) {
            selectedIndustryInterests.push(interest.industry);
          }
          return null;
        });
        if (selectedIndustryInterests.length > 0) {
          newState.selectedIndustryInterests = selectedIndustryInterests;
          stateChanged = true;
        }
      }
    }

    if(this.state.selectedIndustrySkillsInterests !== undefined && this.state.selectedIndustrySkillsInterests.length === 0 && nextProps.profileDetails !== null && nextProps.profileDetails.toJS().interests.length > 0)
    {
      if(newState.firstLoad) {
        let selectedIndustrySkillsInterests = [];
        nextProps.profileDetails.toJS().interests.map((interest) => {
          if (interest.skills !== undefined) {
            interest.skills.map((skill) => {
              selectedIndustrySkillsInterests.push(skill);
              return null;
            });
          }
          return null;
        });
        if (selectedIndustrySkillsInterests.length > 0) {
          newState.selectedIndustrySkillsInterests = selectedIndustrySkillsInterests;
          stateChanged = true;
        }
      }
    }

    if(nextProps.profileDetails !== null && nextProps.profileDetails.toJS().otherSkill !== this.state.otherSkill && this.props.translations !== null && nextProps.profileDetails.toJS().otherSkill !== null)
    {
      newState.otherSkill = nextProps.profileDetails.toJS().otherSkill;
      newState.selectedIndustrySkillsInterests.push({id: "other", name: this.props.translations.accountProfile.other});
      stateChanged = true;
    }

    if(nextProps.profileDetails !== null && this.state.businessEmail !== nextProps.profileDetails.toJS().businessEmail)
    {
      newState.businessEmail = nextProps.profileDetails.toJS().businessEmail;
      stateChanged = true;
    }

    if(nextProps.profileDetails !== null && this.state.businessEmail !== nextProps.profileDetails.toJS().businessEmail)
    {
      newState.businessEmail = nextProps.profileDetails.toJS().businessEmail;
      stateChanged = true;
    }

    if(nextProps.profileDetails !== null && this.state.communicationBusinessEmail !== nextProps.profileDetails.toJS().communicationBusinessEmail)
    {
      newState.communicationBusinessEmail = nextProps.profileDetails.toJS().communicationBusinessEmail;
      stateChanged = true;
    }

    if(nextProps.profileDetails !== null && this.state.communicationPersonalEmail !== nextProps.profileDetails.toJS().communicationPersonalEmail)
    {
      newState.communicationPersonalEmail = nextProps.profileDetails.toJS().communicationPersonalEmail;
      stateChanged = true;
    }
    
    if(nextProps.profileDetails !== null && nextProps.profileDetails.toJS().industry !== this.state.industry)
    {
      newState.industry = nextProps.profileDetails.toJS().industry;
      stateChanged = true;
    }

    if(stateChanged === true)
    {
      newState.firstLoad = false;
      this.setState(newState);
    }

    if(this.state.editModelValidating === true)
    {
      if(nextProps.profileDetails !== null)
      {
        if(nextProps.profileDetails.toJS().errors !== undefined
        //&& nextProps.profileDetails.toJS().errors
        ){
            this.setState({
              editModelValidating: false
            }, () => {
              //this.props.fetchProfile();
            });
        }
        else
        {
            this.setState({
              editModalOpened: false,
              editModelValidating: false
            }, () => {
              // this.props.fetchProfile();
            });
        }
      }
    }
  }

  _getInfo (sectionTitle, data, type=null) {
    let infoString = data;
    let arr = [];
    if (!data || data.size === 0) {
      infoString = '-';
    } else {
      if (typeof data !== 'string') {
        if (data.get('name') === undefined && sectionTitle !== this.props.translations.accountProfile.industryInterests && sectionTitle !== this.props.translations.accountProfile.interestedIndustrySkills) {
          arr = [];
          data.forEach((item, key) => {
            arr.push(item.get('name'));
          });
          infoString = arr.join(', ');
        }
        else {
          infoString = data.get('name');
        }
      }

      if (sectionTitle === this.props.translations.accountProfile.industryInterests) {
        arr = [];
        data.forEach((item, key) => {
          if (item.get('industry') !== undefined)
            arr.push(item.getIn(['industry', 'name']));
        });
          if(arr.length > 0) {
              infoString = arr.join(', ');
          } else {
              infoString = '-';
          }
      }

      if (sectionTitle === this.props.translations.accountProfile.interestedIndustrySkills) {
        arr = [];
        data.forEach((item, key) => {
            if(item.get('skills')) {
              if (item.get('skills') !== undefined) {
                item.get('skills').forEach((val, key) => {
                  arr.push(val.get('name'));
                });
              }
            }
        });
        if(arr.length > 0) {
            infoString = arr.join(', ');
        } else {
            infoString = '-';
        }
      }
    }
    const infoTextId = type ? "sidebarInfoText-" + type : null;

    return (
      <div className="connection-sidebarInfo">
        <div className="connection-sectionTitle --smallMargin">{ sectionTitle }</div>
        <div id={infoTextId} className="connection-sidebarInfoText">{ infoString }</div>
      </div>
    );
  }

  _getSection (items, type) {
    if (!items || !items.size) {
      return null;
    }

    return (
      <div className="connection-experience">
        <div className="connection-sectionTitle">{ type.label }</div>
        { this._getExperience(items, type.name) }
      </div>
    );
  }

  _getExperience(items, type = "positions") {
    if (!items) {
      return null;
    }
    return items.map((item, index) => {
      let startMonth = item.get('startMonth', null);
      let startYear = item.get('startYear', null);
      let endMonth = item.get('endMonth', null);
      let endYear = item.get('endYear', null);

      let positionStartDate = false;
      let positionEndDate = false;
      if(startMonth === null && startYear !== null) {
        positionStartDate = moment(`${item.get('startYear')}`, "YYYY");
      }
      if(startMonth !== null && startYear !== null) {
        positionStartDate = moment(`${item.get('startMonth')}-${item.get('startYear')}`, "MM-YYYY");
      }
      if(endMonth === null && endYear !== null) {
        positionEndDate = moment(`${item.get('endYear')}`, "YYYY");
      }
      if(endMonth !== null && endYear !== null) {
        positionEndDate = moment(`${item.get('endMonth')}-${item.get('endYear')}`, "MM-YYYY");
      }

      return (
        <ExperienceBox
          className="connection-experienceBox"
          key={ `${type} - ${index}`}
          type={ type }
          title={ item.get('title') }
          icon={ item.get('imageUrl') }
          startMonth={ item.get('startMonth') }
          startDate={ item.get('startYear') }
          endMonth={ item.get('endMonth') }
          endDate={ item.get('endYear') }
          diffInYears={ (positionStartDate) ?  Math.floor(moment(positionEndDate).diff(positionStartDate, 'years')) : false }
          diffInMonths={ (positionStartDate && startMonth !== null) ? Math.floor(moment(positionEndDate).diff(positionStartDate, 'months') % 12) : false }
          company={ item.get('company') }
          summary={ item.get('summary') }
          description={ item.get('companyDescription') }
          trans={this.props.translations}
        />
      );
    });
  }

  _getExperienceSections () {
    const { profileDetails } = this.props;
    if (profileDetails && profileDetails.get('id')) {
    return (
      <div className="connection-pagePadding">
        { this._getSection(profileDetails.get('positions'), { name: 'experience', label: this.props.translations.accountProfile.experience})}
        { this._getSection(profileDetails.get('educations'), { name: 'education', label: this.props.translations.accountProfile.education})}
      </div>
    );
    }
    return null;
  }

  _getExpectations () {
    const { profileDetails } = this.props;
    if (profileDetails && profileDetails.get('id')) {
    return (
      <div className="connection-expectations">
        {/* <div onClick={this.editExpectations} className="edit-button edit-button-profile"></div> */}
        <div className="connection-sectionTitle">{this.props.translations.accountProfile.expectationsLabel}</div>
        { (profileDetails.get('expectations') !== null) ? profileDetails.get('expectations') : '-' }
      </div>
    );
    }
    return null;
  }
  _getProfileBox() {
    const { profileDetails } = this.props;
    if (profileDetails && profileDetails.get('id')) {
      return (
        <ProfileBox
          id={ profileDetails.get('id') }
          fetchConversation={false}
          className={ '--wide' }
          grade={ profileDetails.get('grade') }
          firstName={ profileDetails.get('firstName') }
          distance={ profileDetails.get('distance') }
          lastName={ profileDetails.get('lastName') }
          options={ { distance: true, location: true } }
          avatar={ profileDetails.get('profilePictureUrl') }
          jobName={ profileDetails.getIn(['positions', 0, 'title'])}
          headline={ profileDetails.get('headline')}
          currentLocation={ profileDetails.getIn(['positions', 0, 'company']) }
          matchValue={ null }
          hasActiveSubscription={ profileDetails.get('hasCompanySubscription') }
          subscriptionCompanyName={ profileDetails.get('subscriptionCompanyName') }
          hideConnect={true}
          noLink={true}
          showGrade={ false }
          showSpendingLimit={true}
          spent={profileDetails.toJS().spendingLimit !== null && profileDetails.toJS().spendingLimit.availableLimit !== null ? (profileDetails.toJS().spendingLimit.totalLimit - profileDetails.toJS().spendingLimit.availableLimit) : 0}
          spendingLimit={profileDetails.toJS().spendingLimit !== null ?  profileDetails.toJS().spendingLimit.totalLimit : 0}
          trans={this.props.translations}
          isProfilePage={true}
          heroInitials={ profileDetails.get('heroInitials') }
          />
      );
    }
    return null;
  }

  saveOriginalInformation (){
    setTimeout(() => {
      let originalInfo = {};
      originalInfo.phoneNumber = this.state.phoneNumber;
      originalInfo.selectedSkills = this.state.selectedSkills;
      originalInfo.selectedIndustryInterests = this.state.selectedIndustryInterests;
      originalInfo.selectedIndustrySkillsInterests = this.state.selectedIndustrySkillsInterests;
      originalInfo.expectations = this.state.expectations;
      originalInfo.desiredEnvironment = this.state.desiredEnvironment;
      originalInfo.profileQuestion = this.state.profileQuestion;
      originalInfo.industry = this.state.industry;
      this.props.saveOriginalInformation(originalInfo);
    });
  }

  restoreOriginalInformation() {
    let originalInformation = this.props.originalInformation.toJS();
    let phoneNumber = originalInformation.phoneNumber;
    let selectedSkills = originalInformation.selectedSkills;
    let selectedIndustryInterests = originalInformation.selectedIndustryInterests;
    let selectedIndustrySkillsInterests = originalInformation.selectedIndustrySkillsInterests;
    let expectations = originalInformation.expectations;
    let desiredEnvironment = originalInformation.desiredEnvironment;
    const profileQuestion = originalInformation.profileQuestion;
    const industry = originalInformation.industry;
    this.setState({
      phoneNumber,
      selectedSkills,
      selectedIndustryInterests,
      selectedIndustrySkillsInterests,
      expectations,
      desiredEnvironment,
      profileQuestion,
      industry,
    });
  }

  editProfile = () => {
    window.location.href ="/profile";
    // this.setState({
    //   errors: null,
    //   editModalOpened: true
    // }, this.saveOriginalInformation());
  }

  onCancelEdit = () => {
    this.setState({
      editModalOpened: false,
    }, this.restoreOriginalInformation());
  }

  editExpectations = () => {
    this.setState({
      editExpectationsModalOpened: true
    }, this.saveOriginalInformation());
  }

  onCancelEditExpectations = () => {
    this.setState({
      editExpectationsModalOpened: false
    }, this.restoreOriginalInformation());
  }

  editDesiredEnvironment = () => {
    this.saveOriginalInformation();
    this.setState({
      editDesiredEnvironmentModalOpened: true
    });
  }

  onCancelEditDesiredEnvironment = () => {
    this.setState({
      editDesiredEnvironmentModalOpened: false
    }, this.restoreOriginalInformation());
  }

  onSave = () => {
    let args = {};
    // args.phoneNumber = null;
    if(this.state.phoneNumber !== null && (this.state.phoneNumber.number !== undefined || this.state.phoneNumber.countryCode !== undefined))
    {
      args.phoneNumber = {
        countryCode:
          (this.state.phoneNumber.countryCode !== undefined && this.state.phoneNumber.countryCode.value !== undefined) ?
            this.state.phoneNumber.countryCode.value : null,
        number:
          (this.state.phoneNumber.number !== undefined) ?
            this.state.phoneNumber.number : null};
    }
    args.industryId = this.state.industry !== null ? this.state.industry.id : null;
    args.skillIds = this.state.selectedSkills.map((selectedSkill) => { return selectedSkill.id;});
    args.interests = {
      industryIds: [],
      skillIds: []
    };
    args.interests.industryIds = this.state.selectedIndustryInterests.map((selectedIndustryInterest) => {
      return selectedIndustryInterest.id;
    });
    args.interests.skillIds = this.state.selectedIndustrySkillsInterests.map((selectedIndustrySkillsInterest) => {
      return selectedIndustrySkillsInterest.id;
    });
    args.expectations       = this.state.expectations;
    args.desiredEnvironment = this.state.desiredEnvironment;
    args.otherSkill         = this.state.otherSkill;
    args.profileQuestion = this.state.profileQuestion;
    args.businessEmail      = this.state.businessEmail;
    args.communicationBusinessEmail = this.state.communicationBusinessEmail;
    args.communicationPersonalEmail = this.state.communicationPersonalEmail;
    this.setState({editModelValidating: true});

    if(this.props.newHero) {
        args.newHero = true;
    }

    this.props.saveProfile(args);
  }

  onSaveExpectations = () => {
    let args = {};
    args.expectations = this.state.expectations;
    this.props.saveProfile(args);
    this.setState({
      editExpectationsModalOpened: false
    });
  }

  onSaveDesiredEnvironment = () => {
    let args = {};
    args.desiredEnvironment = this.state.desiredEnvironment;
    this.props.saveProfile(args);
    this.setState({
      editDesiredEnvironmentModalOpened: false
    });
  }

  phoneNumberChanged = (ev) => {
    let { phoneNumber } = this.state;
    const re = /^\+?\d*$/;
    if (ev.target.value === '' || re.test(ev.target.value)) {
        if(phoneNumber === null)
        {
          phoneNumber = {};
        }
        phoneNumber.number = ev.target.value;
       this.setState({phoneNumber});
    }
  }

  onChangeExpectations = (ev) => {
    this.setState({expectations: ev.target.value });
  }

  onChangeOtherSkill = (ev) => {
    this.setState({otherSkill: ev.target.value });
  }

  onChangeDesiredEnvironment = (ev) => {
    this.setState({desiredEnvironment: ev.target.value });
  }

  onChangeProfileQuestion(ev) {
    this.setState({profileQuestion: ev.target.value });
  }

  onPhoneNumberPrefixChange = (prefix) => {
    let { phoneNumber } = this.state;
    if(phoneNumber === null)
    {
      phoneNumber = {};
    }
    phoneNumber.countryCode = prefix.toJS()[0];
    this.setState({phoneNumber});
  }

  togglePersonalEmailUseForComm = () => {
    let communicationPersonalEmail = this.state.communicationPersonalEmail;
    let communicationBusinessEmail = this.state.communicationBusinessEmail;
    if(!(communicationBusinessEmail === false && communicationPersonalEmail === true))
    {
      this.setState({communicationPersonalEmail: !this.state.communicationPersonalEmail});
    }
    else
    {
      this.setState({communicationBusinessEmail: !this.state.communicationBusinessEmail, communicationPersonalEmail: false});
    }
  }

  toggleBusinessEmailUseForComm = () => {
    let communicationPersonalEmail = this.state.communicationPersonalEmail;
    let communicationBusinessEmail = this.state.communicationBusinessEmail;
    if(communicationPersonalEmail === false && communicationBusinessEmail === true)
    {
      this.setState({communicationBusinessEmail: !this.state.communicationBusinessEmail, communicationPersonalEmail: true});
    }
    else
    {
      this.setState({communicationBusinessEmail: !this.state.communicationBusinessEmail});
    }
  }

  toggleBusinessEmailChange = (e) => {
    this.setState({businessEmail: e.target.value});
  }

  EmailSection = () => {
    let { profileDetails } = this.props;
    if(profileDetails === null) return null;
    return (
      <div className="edit-section">
        <div className="edit-section-row">
          <div className="edit-section-cell">
            <div className="edit-section-label">{this.props.translations.accountProfile.eMail}</div>
          </div>
          <div className="edit-section-cell">
            <div style={{ textAlign: 'right' }}>
              <Checkbox
                  className="align-right"
                  checked={this.state.communicationPersonalEmail}
                  onChange={this.togglePersonalEmailUseForComm}
              />
            </div>
          </div>
        </div>
        <div className="edit-section-row">
          <div className="edit-section-cell">
            {profileDetails.get('email')}
          </div>
          <div className="edit-section-cell">
            
            <div style={{textTransform: 'capitalize', textAlign: 'right', color: '#000'}}>
              {profileDetails.get('communicationPersonalEmail') === true ? this.props.translations.accountProfile.usedForComm : this.props.translations.accountProfile.notUsedForComm }
            </div>
          </div>
        </div>
      </div>
    );
  }

  BusinessEmailSection = () => {
    let { profileDetails } = this.props;
    if(profileDetails === null) return null;
    return (
      <div className="edit-section">
        <div className="edit-section-row">
          <div className="edit-section-cell">
            <div className="edit-section-label">{this.props.translations.accountProfile.businessEMail}</div>
          </div>
          <div className="edit-section-cell">
            <div style={{ textAlign: 'right' }}>
              <Checkbox
                  className="align-right"
                  checked={this.state.communicationBusinessEmail}
                  onChange={this.toggleBusinessEmailUseForComm}
              />
            </div>
          </div>
        </div>
        <div className="edit-section-row">
          <div className="edit-section-cell">
            <input onChange={this.toggleBusinessEmailChange} type="text" value={this.state.businessEmail ? this.state.businessEmail : ''} style={{ color: '#131e29', padding: '13px 20px', borderRadius: '3px', border: 'solid 1px #cdd1d4' }} />
          </div>
          <div className="edit-section-cell">           
            <div style={{textTransform: 'capitalize', textAlign: 'right', color: '#000'}}>
              {profileDetails.get('communicationBusinessEmail') === true ? this.props.translations.accountProfile.usedForComm : this.props.translations.accountProfile.notUsedForComm}
            </div>
          </div>
        </div>
      </div>
    );
  }

  ExpectationsSection = () => {
    let { profileDetails } = this.props;

    let ExpectationsError = false;

    if(this.props.profileDetails.toJS() !== undefined && this.props.profileDetails.toJS().errors !== null && this.props.profileDetails.toJS().errors !== undefined && this.props.profileDetails.toJS().errors['expectations'] !== undefined)
    {
      ExpectationsError = true;
    }

    if(ExpectationsError && this.state.expectations !== '') {
      ExpectationsError = false
    }

    if(profileDetails === null) return null;
    return (
      <div className="edit-section fullWidth">
        <div className="edit-section-row">
          <div className="edit-section-cell">
            <div className="edit-section-label">{this.props.translations.accountProfile.expectationsLabel}</div>
          </div>
        </div>
        <div className={classnames("edit-section-row", {'--validation-error': ExpectationsError})}>
          <div className="edit-section-cell">
            <TextareaAutosize className="edit-input-wrapper edit-area" placeholder={this.props.translations.accountProfile.whatDoYouWantToSay} onChange={this.onChangeExpectations} value={this.state.expectations}>{this.state.expectations}</TextareaAutosize>
          </div>
        </div>
      </div>
    );
  }

  DesiredEnvironmentSection = () => {
    let { profileDetails } = this.props;

    let desiredEnvironmentError = false;

    if(this.props.profileDetails.toJS() !== undefined && this.props.profileDetails.toJS().errors !== null && this.props.profileDetails.toJS().errors !== undefined && this.props.profileDetails.toJS().errors['desiredEnvironment'] !== undefined)
    {
      desiredEnvironmentError = true;
    }


    if(desiredEnvironmentError && this.state.desiredEnvironment !== '') {
      desiredEnvironmentError = false
    }

    if(profileDetails === null) return null;
    return (
      <div className="edit-section fullWidth">
        <div className="edit-section-row">
          <div className="edit-section-cell">
            <div className="edit-section-label">{this.props.translations.accountProfile.desiredEnvironmentLabel}</div>
          </div>
        </div>
        <div className={classnames("edit-section-row", {'--validation-error': desiredEnvironmentError})}>
          <div className="edit-section-cell">
            <TextareaAutosize className="edit-input-wrapper edit-area" placeholder={this.props.translations.accountProfile.whatDoYouWantToSay} onChange={this.onChangeDesiredEnvironment} value={this.state.desiredEnvironment}>{this.state.desiredEnvironment}</TextareaAutosize>
          </div>
        </div>
      </div>
    );
  }

  ProfileQuestionSection() {
    const { profileDetails, translations } = this.props;
    const { profileQuestion } = this.state;

    if (!profileDetails) {
      return null;
    }

    const profileQuestionError = _.get(profileDetails.toJS(), 'errors.profileQuestion', false);
    return (
      <div className="edit-section fullWidth">
        <div className="edit-section-row">
          <div className="edit-section-cell">
            <div className="edit-section-label">
              {_.get(translations, 'accountProfile.profileQuestionLabel', 'Profile Question')}
              {' '}<span className="label-optional">{_.get(translations, 'accountProfile.optional', '(Optional)')}</span>
            </div>
          </div>
        </div>
        <div className={classnames("edit-section-row", {'--validation-error': profileQuestionError})}>
          <div className="edit-section-cell">
            <TextareaAutosize className="edit-input-wrapper edit-area" placeholder={this.props.translations.accountProfile.whatDoYouWantToSay} onChange={this.onChangeProfileQuestion} value={profileQuestion}>{profileQuestion}</TextareaAutosize>
          </div>
        </div>
      </div>
    );
  }

  PhoneSection = () => {
    let { countryCodes } = this.props;
    let selectedCountryCode  = [];
    if(this.state.phoneNumber !== null && this.state.phoneNumber.countryCode !== undefined)
    {
        selectedCountryCode.push(this.state.phoneNumber.countryCode);
    }

    let phonePrefixError = false;
    let phoneNumberError = false;

    if(this.props.profileDetails.toJS() !== undefined && this.props.profileDetails.toJS().errors !== undefined && this.props.profileDetails.toJS().errors['phoneNumber.countryCode'] !== undefined)
    {
      phonePrefixError = true;
    }

    if(this.state.phoneNumber !== undefined && (phonePrefixError && this.state.phoneNumber !== null && this.state.phoneNumber.countryCode !== undefined && this.state.phoneNumber.countryCode !== '')) {
      phonePrefixError = false
    }

    if(this.props.profileDetails.toJS() !== undefined && this.props.profileDetails.toJS().errors !== undefined && this.props.profileDetails.toJS().errors['phoneNumber.number'] !== undefined)
    {
      phoneNumberError = true;
    }

    if(this.state.phoneNumber !== undefined && (phoneNumberError && this.state.phoneNumber !== null && this.state.phoneNumber.number !== undefined && this.state.phoneNumber.number !== '')) {
      phoneNumberError = false
    }

    return (
      <div className="edit-section">
      <div className="edit-section-row">
        <div className="edit-section-cell">
          <div className="edit-section-label">{this.props.translations.accountProfile.mobileNumber}</div>
        </div>
        <div className="edit-section-cell">
        </div>
      </div>
      <div className="edit-section-row">
        <div className="edit-section-cell">
          <div className="edit-phone-number">
            <div className={classnames("edit-phone-number-prefix", {'--validation-error': phonePrefixError})}>
              <SelectBox
                options={ countryCodes }
                classic={ true }
                empty={ 'Country' }
                multiple={ false }
                className="--fluid"
                onChange={this.onPhoneNumberPrefixChange}
                selectedItems = {Immutable.fromJS(selectedCountryCode)}
                trans={this.props.translations}
              />
            </div>
            <div className={classnames("edit-phone-number-number", {'--validation-error': phoneNumberError})}>
              <div className="edit-input-wrapper">
                <input className="edit-input phone-number-input" placeholder="Phone number" value={(this.state.phoneNumber !== null && this.state.phoneNumber.number !== undefined) ? this.state.phoneNumber.number : ''} onChange={this.phoneNumberChanged} />
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    );
  }

  IndustrySection = () => {
    let { profileDetails, industries } = this.props;

    if(profileDetails === null || industries === null)
      return null;

    let industriesList = [];
    industries.map((industry) => {
      industriesList.push(Immutable.fromJS({value: industry.get('id'), label: industry.get('name')}));

      return null;
    });

    let selectedIndustriesList = [];
    if(this.state.industry !== null)
    {
      
      industries.map((industry) => {
        if(industry.get('id') === this.state.industry.id)
        {
          selectedIndustriesList.push({value: industry.get('id'), label: industry.get('name')});  
        }
        return null;
      });
    }

    let industryError = null;
    if(this.props.profileDetails.toJS() !== undefined && this.props.profileDetails.toJS().errors !== undefined && this.props.profileDetails.toJS().errors['industryId'] !== undefined)
    {
      industryError = true;
    }

    return (
      <div className="edit-section">
        <div className="edit-section-row">
          <div className="edit-section-cell">
            <div className="edit-section-label">{this.props.translations.accountProfile.industry}</div>
          </div>
          <div className="edit-section-cell">
          </div>
        </div>
        <div className="edit-section-row">
          <div className={classnames("edit-section-cell", {"--validation-error": industryError})}>
            <SelectBox
              options={ Immutable.fromJS(industriesList) }
              classic={ true }
              empty={ 'Enter your industry' }
              multiple={ false }
              className="--fluid"
              onChange={this.onIndustryChange}
              selectedItems = {Immutable.fromJS(selectedIndustriesList)}
              trans={this.props.translations}
            />
          </div>
          <div className="edit-section-cell">
          </div>
        </div>
      </div>
    );
  }

  SkillsSection = () => {
    let { profileDetails, skills } = this.props;
    if(profileDetails === null || skills === null)
      return null;
    let skillsList = [];
    skills.map((skill) => {
      //if(skill.get('industryId') === null || skill.get('industryId') === profileDetails.toJS().industry.id)
      skillsList.push(Immutable.fromJS({value: skill.get('id'), label: skill.get('name')}));
      return null;
    });
    let selectedSkills = [];
    this.state.selectedSkills.map((skill) => {
      skillsList.map((existingSkill) => {
        if(existingSkill.get('value') === skill.id) {
          selectedSkills.push(Immutable.fromJS({value: skill.id, label: skill.name}));
        }
        return null;
      });
      return null;
    });
    let skillsError = false;
    if(this.props.profileDetails.toJS() !== undefined && this.props.profileDetails.toJS().errors !== undefined && this.props.profileDetails.toJS().errors['skillIds'] !== undefined)
    {
      skillsError = true;
    }
    if(skillsError && this.state.selectedSkills.length !== 0) {
      skillsError = false
    }

    return (
      <div className="edit-section">
        <div className="edit-section-row">
          <div className="edit-section-cell">
            <div className="edit-section-label">{this.props.translations.accountProfile.industrySkills}</div>
          </div>
          <div className="edit-section-cell"></div>
        </div>
        <div className="edit-section-row">
          <div className={classnames("edit-section-cell", {'--validation-error': skillsError})}>
            <SelectBox
              options={ Immutable.fromJS(skillsList) }
              classic={ true }
              empty={ 'Enter your skills' }
              multiple={ true }
              className="--fluid"
              onChange={this.onSkillsChange}
              selectedItems = {Immutable.fromJS(selectedSkills)}
              trans={this.props.translations}
            />
          </div>
          <div className="edit-section-cell"></div>
        </div>
      </div>
    );
  }

  IndustryInterestsSection = () => {
    let { profileDetails, industries } = this.props;
    if(profileDetails === null || industries === null)
      return null;
    let industriesList = [];
    industries.map((industry) => {
      industriesList.push(Immutable.fromJS({value: industry.get('id'), label: industry.get('name')}));
      return null;
    });
    let selectedIndustriesList = [];
    this.state.selectedIndustryInterests.map((industryInterest) => {
      selectedIndustriesList.push(Immutable.fromJS({value: industryInterest.id, label: industryInterest.name}));
      return null;
    });
    let interestsIndustryIdsError = false;
    if(this.props.profileDetails.toJS() !== undefined && this.props.profileDetails.toJS().errors !== undefined && this.props.profileDetails.toJS().errors['interests.industryIds'] !== undefined)
    {
      interestsIndustryIdsError = true;
    }

    if(interestsIndustryIdsError && this.state.selectedIndustryInterests.length !== 0) {
      interestsIndustryIdsError = false
    }

    return (
      <div className="edit-section">
        <div className="edit-section-row">
          <div className="edit-section-cell">
            <div className="edit-section-label">{this.props.translations.accountProfile.industryInterests}</div>
          </div>
          <div className="edit-section-cell"></div>
        </div>
        <div className="edit-section-row">
          <div className={classnames("edit-section-cell", {'--validation-error': interestsIndustryIdsError})}>
            <SelectBox
              options={ Immutable.fromJS(industriesList) }
              classic={ true }
              empty={ 'Enter your industry interests' }
              multiple={ true }
              className="--fluid"
              onChange={this.onIndustryInterestsChange}
              selectedItems = {Immutable.fromJS(selectedIndustriesList)}
              trans={this.props.translations}
            />
          </div>
          <div className="edit-section-cell"></div>
        </div>
      </div>
    );
  }

  IndustrySkillsSection = () => {
    let { profileDetails, skills } = this.props;
    if(profileDetails === null || skills === null)
      return null;
    let skillsList = skills.map((skill) => {
      return Immutable.fromJS({value: skill.get('id'), label: skill.get('name'), industryId: skill.get('industryId')});
    });
    let selectedSkills = this.state.selectedIndustrySkillsInterests.map((skill) => {
      return Immutable.fromJS({value: skill.id, label: skill.name});
    });
    let interestsSkillIdsError = false;
    let otherInterestSkillError = false;
    if(this.props.profileDetails.toJS() !== undefined && this.props.profileDetails.toJS().errors !== undefined && this.props.profileDetails.toJS().errors['interests.skillIds'] !== undefined)
    {
      interestsSkillIdsError = true;
    }

    // if(interestsSkillIdsError && this.state.selectedIndustrySkillsInterests.length !== 0) {
    //   interestsSkillIdsError = false
    // }

    let hasOther = false;
    selectedSkills.map((sk) => {
      if(sk.get('value') === 'other')
      {
        hasOther = true;
      }
      return null;
    });

    if(hasOther === true)
    {
      if(this.state.otherSkill !== null && this.state.otherSkill.length === 0)
      {
        otherInterestSkillError = true;
      }
    }

    return (
      <div className="edit-section">
        <div className="edit-section-row">
          <div className="edit-section-cell">
            <div className="edit-section-label">{this.props.translations.accountProfile.interestedIndustrySkills}</div>
          </div>
          <div className="edit-section-cell">
            {hasOther === true ? <div className="edit-section-label">
            {this.props.translations.accountProfile.otherIndustryInterests} </div> : null}
          </div>
        </div>
        <div className="edit-section-row">
          <div className={classnames("edit-section-cell", {"--validation-error": interestsSkillIdsError})}>
            <SelectBox
              options={ skillsList }
              classic={ true }
              empty={ 'Enter your skills' }
              multiple={ true }
              grouped={ true }
              groupedBy={'industryId'}
              groupedByValues={this.state.selectedIndustryInterests}
              otherOption={true}
              className="--fluid"
              onChange={this.onIndustrySkillsChange}
              selectedItems = { Immutable.fromJS(selectedSkills) }
              trans={this.props.translations}
            />
          </div>
          <div className={classnames("edit-section-cell", {"--validation-error": otherInterestSkillError})}>
            {hasOther === true ? <div className="edit-input-wrapper"><input onChange={this.onChangeOtherSkill} value={this.state.otherSkill} placeholder={this.props.translations.accountProfile.otherIndustryInterests + " ..."} className="edit-input other-industry-skill-interests-input" type="text" /></div> : null }
          </div>
        </div>
      </div>
    );
  }

  onIndustryChange = (selectedItem) => {
    
    this.setState({
      industry: {
        id: selectedItem.toJS()[0].value,
        name: selectedItem.toJS()[0].label
      }
    });
  }

  onIndustryInterestsChange = (selectedItem, added) => {
    let { selectedIndustryInterests } = this.state;
    if(added === true)
    {
      selectedIndustryInterests.push({id: selectedItem.toJS().value, name: selectedItem.toJS().label});
    }
    else
    {
      let valueIndex = this.state.selectedIndustryInterests.findIndex(item => item.id === selectedItem.get('value'));
      selectedIndustryInterests.splice(valueIndex, 1);
    }
    this.setState({selectedIndustryInterests});
  }

  onIndustrySkillsChange = (selectedItem, added) => {
    let { selectedIndustrySkillsInterests } = this.state;
    if(added === true)
    {
      selectedIndustrySkillsInterests.push({id: selectedItem.toJS().value, name: selectedItem.toJS().label});
    }
    else
    {
      let valueIndex = this.state.selectedIndustrySkillsInterests.findIndex(item => item.id === selectedItem.get('value'));
      selectedIndustrySkillsInterests.splice(valueIndex, 1);
    }

    let hasOther = false;
    selectedIndustrySkillsInterests.map((sk) => {
      if(sk.id === "other")
      {
        hasOther = true;
      }
      return null;
    });

    this.setState({
      selectedIndustrySkillsInterests,
      otherSkill: hasOther === false ? '' : this.state.otherSkill
    });
  }

  onSkillsChange = (selectedItem, added) => {
    let { selectedSkills } = this.state;
    if(added === true)
    {
      selectedSkills.push({id: selectedItem.toJS().value, name: selectedItem.toJS().label});
    }
    else
    {
      let valueIndex = this.state.selectedSkills.findIndex(item => item.id === selectedItem.get('value'));
      selectedSkills.splice(valueIndex, 1);
    }
    this.setState({selectedSkills});
  }

  onProfileCompletedDone = () => {
    this.props.fetchProfile();
    window.location.href = '/';
  }

  profileQuestion() {
    const { profileDetails, translations } = this.props;
    const profileQuestion = profileDetails.get('profileQuestion');
    return (
      <div className="connection-expectations">
        <div className="connection-sectionTitle">{_.get(translations, 'accountProfile.profileQuestionLabel', 'Profile Question') }</div>
        { profileQuestion ? profileQuestion : '-' }
      </div>
    );
  }

  render() {
    if(this.state.rendering === true || this.props.translations === null)
    {
      return (
        <div className="officeDetailsLoader">
        </div>
      );
    }

    let { profileDetails } = this.props;

    // console.log('%c _______PROPS', 'background: #bada33; color: black', this.props);
    // console.log('%c _______STATE', 'background: #222; color: #bada33', this.state);

    if(profileDetails === null)
    {
      return <div className="Loader"></div>;
    }

    let profileJustCompleted = (profileDetails.get('profileJustCompleted') !== undefined) ? profileDetails.get('profileJustCompleted') : false;
    return (
      <div className="connection">
        <section className="connection-content">
          <Scrollbars className="styleScroll">
            <button type="button" onClick={this.props.history.goBack} className="navigation__back-to-button navigation__back-to-button--margin-left">
              {_.get(this.props.translations, 'connection.back', 'connection.back')}
            </button>
            { this._getProfileBox() }
            { this._getExperienceSections() }
          </Scrollbars>
        </section>

        <Sidebar className="--full-height">
          <Scrollbars className="styleScroll">
            <div className="connection-sidebarContent">
              <div onClick={this.editProfile} className="edit-button edit-button-sidebar"></div>
              { profileDetails.get('communicationPersonalEmail') === true ? this._getInfo(_.get( this.props.translations, 'accountProfile.eMail', 'Personal E-mail'), profileDetails.get('email')) : "" }
              { profileDetails.get('communicationBusinessEmail') === true ? this._getInfo(_.get( this.props.translations, 'accountProfile.businessEMail', 'Business E-mail'), profileDetails.get('businessEmail')) : ""}
              { this._getInfo(_.get(this.props, 'translations.accountProfile.allVerticals', 'Your news preference'), profileDetails.get('heroVerticals'), 'heroVerticals') }
              { this._getInfo(_.get(this.props, 'translations.accountProfile.defaultVertical', 'Your starting dashboard'), profileDetails.get('defaultVertical'), 'defaultVertical') }
              { this._getInfo(_.get(this.props, 'translations.accountProfile.allGoals', 'Your goals in Workero network'), profileDetails.get('heroGoals'), 'heroGoals') }
            </div>
          </Scrollbars>
        </Sidebar>

        <ModalDialog
          label="Edit Account"
          title={this.props.translations.accountProfile.editContactInfo}
          type="edit-account"
          modalOpened={this.state.editModalOpened}
          className="edit-account-dialog-modal"
          onModalClose={this.onCancelEdit}
          validating={this.state.editModelValidating}
          onSave={this.onSave}
          trans={this.props.translations}
          >
          <div>
            {this.EmailSection()}
            {this.BusinessEmailSection()}
            {this.PhoneSection()}
            {this.IndustrySection()}
            {this.SkillsSection()}
            {this.IndustryInterestsSection()}
            {this.IndustrySkillsSection()}
            {this.ProfileQuestionSection()}
            {/* {this.ExpectationsSection()} */}
            {/* {this.DesiredEnvironmentSection()} */}
          </div>
        </ModalDialog>

        <ModalDialog
          label="Edit Expectations"
          title={"Edit expectations"}
          type="edit-account"
          modalOpened={this.state.editExpectationsModalOpened}
          className="edit-expectations-dialog-modal"
          onModalClose={this.onCancelEditExpectations}
          onSave={this.onSaveExpectations}
          trans={this.props.translations}
          >
          <div>
            <div className="edit-section">
              <div className="edit-section-row">
                <div className="edit-section-cell">
                </div>
                <div className="edit-section-cell">
                </div>
              </div>
              <div className="edit-section-row">
                <TextareaAutosize placeholder={this.props.translations.accountProfile.whatDoYouWantToSay} onChange={this.onChangeExpectations} value={this.state.expectations}>{this.state.expectations}</TextareaAutosize>
              </div>
            </div>
          </div>
        </ModalDialog>

        <ModalDialog
          label="Edit Desired Environment"
          title={"Edit desired environment"}
          type="edit-account"
          modalOpened={this.state.editDesiredEnvironmentModalOpened}
          className="edit-desired-environment-dialog-modal"
          onModalClose={this.onCancelEditDesiredEnvironment}
          onSave={this.onSaveDesiredEnvironment}
          trans={this.props.translations}
          >
          <div>
            <div className="edit-section">
              <div className="edit-section-row">
                <div className="edit-section-cell">
                </div>
                <div className="edit-section-cell">
                </div>
              </div>
              <div className="edit-section-row">
                <TextareaAutosize placeholder={this.props.translations.accountProfile.whatDoYouWantToSay} onChange={this.onChangeDesiredEnvironment} value={this.state.desiredEnvironment}>{this.state.desiredEnvironment}</TextareaAutosize>
              </div>
            </div>
          </div>
        </ModalDialog>
        <ModalDialog
          label="Thank you for joining the Workero network."
          title={"Thank you for joining the Workero network."}
          type="welcome-popup"
          modalOpened={profileJustCompleted}
          onProceed={this.onProfileCompletedDone}
          showCancel={false}
          proceedText="OK"
          className="welcome-modal"
        >
          <div>
            Your profile is completed.
          </div>
        </ModalDialog>


      </div>
    );
  }
}

export default AccountProfile;
