/*
 * Constants
 */
import _ from 'lodash';
import api from '../../components/myMeetings/utils/api';
import {SET_HERO_DETAILS_LOADING} from "./hero-details";

export const SET_SELECTED_PRODUCT_ID = 'SET_SELECTED_PRODUCT_ID';
export const SET_SELECTED_PRODUCT_TYPE = 'SET_SELECTED_PRODUCT_TYPE';
export const SET_SELECTED_DATE = 'SET_SELECTED_DATE';
export const SET_SELECTED_PIN = 'SET_SELECTED_PIN';
export const SET_WHITELABEL_CANCEL_BOOKING_DATA = 'SET_WHITELABEL_CANCEL_BOOKING_DATA';
export const SET_CANCEL_LOADING = 'SET_CANCEL_LOADING';
export const SET_PARKING_VALIDATION = 'SET_PARKING_VALIDATION';
export const SET_COMPLETE_BOOKING_LOADING = 'SET_COMPLETE_BOOKING_LOADING';

/*
 * Sync Actions
 */
export const setSelectedProductId = productId => ({
  type: SET_SELECTED_PRODUCT_ID,
  productId,
});

export const setSelectedProductType = productType => ({
  type: SET_SELECTED_PRODUCT_TYPE,
  productType,
});

export const setSelectedDate = (from, to) => ({
  type: SET_SELECTED_DATE,
  from,
  to,
});

export const setSelectedPin = productId => ({
  type: SET_SELECTED_PIN,
  productId,
});

export const setCancelBookingData = data => ({
  type: SET_WHITELABEL_CANCEL_BOOKING_DATA,
  data,
});

export const setCancelLoading = isLoading => ({
  type: SET_CANCEL_LOADING,
  isLoading,
});

export const setCompleteBookingLoading = isLoading => ({
  type: SET_COMPLETE_BOOKING_LOADING,
  isLoading,
});
export const setNoParkingsAvailable = validationFlag => ({
  type: SET_PARKING_VALIDATION,
  validationFlag,
});

export const bookingValidate = data => dispatch => {
  return new Promise((resolve, reject) => {
    const productId = _.get(data, 'productId', null);
    const lang = localStorage.getItem('lang') || 'en';
    api
      .post(`whitelabel/validate?language=${lang}`, data)
      .then((data = {}) => {
        if (data.data.status) {
          resolve(data.data);
        } else {
          reject(data.data);
        }
      })
      .catch((data = {}) => {
        dispatch(setSelectedPin(productId));
        reject(data.response.data);
      })
      .finally(() => {});
  });
};
export const makeBooking = data => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(setCompleteBookingLoading(true));
    api
      .post(`whitelabel/book`, data)
      .then((data = {}) => {
        if (data.data.status) {
          resolve(data.data);
        } else {
          reject(data.data);
        }
      })
      .catch((data = {}) => {
        reject(data.response.data);
      })
      .finally(() => {
        dispatch(setCompleteBookingLoading(false));
      });
  });
};
export const cancelBooking = id => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(setCancelLoading(true));
    api
      .post(`whitelabel/cancel`, { id })
      .then((response = {}) => {
        if (response.data.success) {
          window.location.href = `/booking/${id}`;
        } else {
          dispatch(setCancelBookingData(response.data));
          dispatch(setCancelLoading(false));
          resolve(response.data);
        }
      })
      .catch((response = {}) => {
        reject(response.data);
        dispatch(setCancelLoading(false));
      })
      .finally(() => {});
  });
};
export const checkIn = id => dispatch => {
  return new Promise((resolve, reject) => {
    api
      .post(`whitelabel/booking/${id}/checkin`, [])
      .then((response = {}) => {
        resolve(response.data);
      })
      .catch((data = {}) => {
        reject(data.response.data.message);
      })
      .finally(() => {});
  });
};
export const checkOut = id => dispatch => {
  return new Promise((resolve, reject) => {
    api
      .post(`whitelabel/booking/${id}/checkout`, [])
      .then((response = {}) => {
        resolve(response.data);
      })
      .catch((data = {}) => {
        reject(data.response.data.message);
      })
      .finally(() => {});
  });
};
export const extendBooking = (id, extendHour) => dispatch => {
  return new Promise((resolve, reject) => {
    api
      .post(`whitelabel/booking/${id}/extend`, { extendHour })
      .then((response = {}) => {
        resolve(response.data);
      })
      .catch((data = {}) => {
        reject(data.response.data.message);
      })
      .finally(() => {});
  });
};
