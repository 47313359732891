import Immutable from 'immutable';
import { SET_LOADER, SET_VOUCHERS, INCREMENT_VOUCHERS_OFFSET, SET_HAS_MORE } from '../../actions/accountPaymentSettings/vouchers';

const initialState = Immutable.Map({
  vouchers: [],
  offset: 1,
  isLoading: true,
  hasMore: true,
});

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_LOADER:
      return state.setIn(['isLoading'], action.isLoading);
    case SET_VOUCHERS:
      return state.setIn(['vouchers'], state.get('vouchers', []).concat(action.vouchers));
    case INCREMENT_VOUCHERS_OFFSET:
      return state.setIn(['offset'], state.get('offset', 0) + 1);
    case SET_HAS_MORE:
      return state.setIn(['hasMore'], action.hasMore);
    default:
      return state;
  }
};
