import _ from 'lodash';
import { Scrollbars } from 'react-custom-scrollbars';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import MemberCard from '../manage-community-modal/member-card';
import CommunitySearch from '../../common/community-search';

const noResults = noResultText => {
  return <div className="manage-community__label manage-community__label--regular">{noResultText}</div>;
};

class SeeAllMembersModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchString: '',
    };
    this.setSearchString.bind(this);
  }

  setSearchString(searchString) {
    this.setState({ searchString });
  }

  render() {
    const { members, heroProfile, translations } = this.props;
    const { searchString } = this.state;
    const authId = _.get(heroProfile, 'id', null);
    // set admin flag to 1 in order to display members as admins are displayed in manage community modal - not showing the context menu
    const formattedMembers = members.map(member => ({ ...member, name: `${member.firstName} ${member.lastName}`, admin: 1 }));
    const filteredMembers = formattedMembers.filter(member => _.toLower(member.name).includes(_.toLower(searchString)));
    const noResultText = _.get(translations, 'inviteMembersModal.noResult', 'inviteMembersModal.noResult');

    return (
      <div className="community-form see-all-members__form">
        <div className="see-all-members__grey-container">
          <CommunitySearch
            searchCallback={event => {
              this.setSearchString(event.target.value);
            }}
          />
          <div className="manage-community__heroes">
            <Scrollbars className="styleScroll">
              {filteredMembers.length
                ? filteredMembers.map(member => (
                    <MemberCard
                      key={`member${member.id}`}
                      member={member}
                      memberType="active"
                      authId={authId}
                      translations={translations}
                      displayActions
                    />
                  ))
                : noResults(noResultText)}
            </Scrollbars>
          </div>
        </div>
      </div>
    );
  }
}
SeeAllMembersModal.propTypes = {
  translations: PropTypes.shape({}).isRequired,
  heroProfile: PropTypes.shape({}).isRequired,
  members: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default withRouter(SeeAllMembersModal);
