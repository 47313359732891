import React, {Component} from 'react';
import onClickOutside from 'react-onclickoutside';
import './css/notifications.scss';
import TextareaAutosize from 'react-autosize-textarea';
import classnames from "classnames";
import circleLoader from './../../assets/images/circle-loader-64px.gif';
import {Scrollbars} from 'react-custom-scrollbars';

class Feedback extends Component {
  constructor(props) {
    super(props);

    this.state = {
      notificationHeight: 300,
      feedbackContent: '',
      sendFeedbackLoader: false,
      feedbackError: false,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      feedbackContent: nextProps.feedbackContent,
      sendFeedbackLoader: nextProps.sendFeedbackLoader,
      feedbackError: nextProps.feedbackError
    });
  }


  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions = ()  =>{
    this.setState({width:window.innerWidth,height:window.innerHeight});
  }

  handleClickOutside = evt => {
    if(this.props.feedbackClicked)
      this.props.onFeedbackClick(evt);
  };

  onFeedbackClick = () => {
    this.props.onFeedbackClick();
  };

  onChangeExpectations = (ev) => {
    this.setState({feedbackContent: ev.target.value });
  }

  feedbackSend = () => {
    this.setState({'sendFeedbackLoader': true});
    this.props.feedbackSend(this.state.feedbackContent);
  };

  wrapperResize = () => {
      let textAreaWithResize = this.refs.textAreaWithResize;
      let textAreaWrapper = this.refs.textAreaWrapper;
      if(textAreaWrapper.container !== null && textAreaWrapper.container.style !== null)
      textAreaWrapper.container.style.height = (textAreaWithResize.textarea.offsetHeight + 100) + "px";
  };

  getFeedbackPopUpContent() {
    return <div className="edit-section">
      {(this.state.sendFeedbackLoader) ?
        <div className="feedbackLoader">
          <img alt="" style={{height: 58, width: 58}} src={circleLoader} />
        </div>
        :
        <div className={classnames("edit-section-row")}>
          <div className="edit-section-cell">
            <div className={classnames({'--validation-error': this.state.feedbackError})}>
              <TextareaAutosize
                className='edit-input-wrapper feedbackInput'
                placeholder={this.props.trans.header.feedBackPlaceholder}
                onChange={this.onChangeExpectations}
                value={this.state.feedbackContent}
                onResize={this.wrapperResize}
                ref={"textAreaWithResize"}
              >{this.state.feedbackContent}</TextareaAutosize>
            </div>
            <button onClick={this.feedbackSend}>{this.props.trans.header.feedbackSendButtonLabel}</button>
          </div>
        </div>
      }

    </div>;
  }

  getFeedbackIcon() {
    const clicked = (this.props.feedbackClicked ? ' notifications-icon-on' : '');
    let icon = <span onClick={this.onFeedbackClick}><div className='leave-us-feedback'><div className="callToFeedback">{this.props.trans.header.leaveFeedBackLabel}</div><span className="arrow"></span><span className={"notifications-icon" + clicked} ></span></div></span>
    return icon;
  }

  render() {
    const clicked = (this.props.feedbackClicked ? ' notifications-active' : '');

    if(this.props.trans === null) return null;

    return (
        <div className={'feedback notifications' + clicked}>
          {this.getFeedbackIcon()}
          <div className="notifications-results">
            <div className="notifications-results-header">
              <span>{this.props.trans.header.feedbackTitle}</span>
            </div>
              <Scrollbars autoHide className="styleScroll" ref={"textAreaWrapper"} style={{minHeight: '165px', maxHeight: this.state.height*0.8 + 'px'}}>
                {this.getFeedbackPopUpContent()}
              </Scrollbars>
          </div>
        </div>
    );
  }
};

export default onClickOutside(Feedback);
