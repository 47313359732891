import React from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import Truncate from 'react-truncate';
import Lightbox from 'react-images';
import sizedImage from '../../../../utils/imageHelper';
import ContextMenu from '../../common/context-menu/ContextMenu';
import CommunityModal from '../../common/modals';
import likeIcon from '../../../../assets/svg/community/community-like-post.svg';
import likeIconOrange from '../../../../assets/svg/community/community-like-post-orange.svg';
import commentIcon from '../../../../assets/svg/community/community-comment-post.svg';
import NoPostCard from '../NoPosts';
import NoMorePostsMessage from './NoMorePosts';
import uploadDocument from '../../../../assets/svg/community/uploads/community-uploads-document.svg';
import uploadVideo from '../../../../assets/svg/community/uploads/community-uploads-video.svg';
import downloadIcon from '../../../../assets/svg/community/download-attachment.svg';
import { filesizeFormatter } from '../../utils/filesize-formatter';
import LoadMore from './LoadMore';
import Embedder from '../../utils/embedder';
import LoadingGif from '../../../../assets/images/loader.gif';
import InfoToast from '../../common/info-toast/InfoToast';
import CommentsCardContainer from '../community-comment';
import CommentForm from '../create-comment';
import { dateTimeFormatter } from '../../utils/datetime-formatter';
import { urlify } from '../../utils/url-parser';
import ReportModal from '../../common/modals/report-modal';
import noProfilePicture from '../../../../assets/images/defaultAvatarimage.png';
import EditPostForm from '../edit-post';
import closeIcon from '../../../../assets/svg/community/close-modal.svg';

class PostCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModalDelete: false,
      showReportModal: false,
      postId: null,
      isReportDisabled: {},
      showDeletePostToastWithSuccess: false,
      showDeletePostToastWithFailure: false,
      expandedCommentBlocks: [],
      title: _.get(props.translations, 'report.whyReport', 'report.whyReport'),
      showAllContent: false,
      showEditPost: false,
      imagePreview: null,
    };
    this.notifyCommunity = this.notifyCommunity.bind(this);
    this.handleClickOnLike = this.handleClickOnLike.bind(this);
    this.buildPostCard = this.buildPostCard.bind(this);
    this.handleContextMenuItems = this.handleContextMenuItems.bind(this);
    this.buildRemovePostModal = this.buildRemovePostModal.bind(this);
    this.displayReportModal = this.displayReportModal.bind(this);
    this.setReportModalVisibility = this.setReportModalVisibility.bind(this);
    this.onReportSuccess = this.onReportSuccess.bind(this);
    this.toggleContentSeeAll = this.toggleContentSeeAll.bind(this);
    this.handleEditPost = this.handleEditPost.bind(this);
    this.renderEditPost = this.renderEditPost.bind(this);
    this.handleImagePreview = this.handleImagePreview.bind(this);

    this.communityPagePostRef = React.createRef();
  }

  notifyCommunity(postId, isNotified) {
    const { community, notifyPost, showCommunityPageToast, translations } = this.props;
    const communityId = _.get(community, 'id', null);
    if (isNotified) {
      const toastMessage = _.get(translations, 'toast.alreadyNotifiedPost', '');
      showCommunityPageToast(toastMessage);
    } else {
      notifyPost(communityId, postId)
        .then(messageCode => {
          const toastMessage = _.get(translations, `toast.${messageCode}`, '');
          showCommunityPageToast(toastMessage);
        })
        .catch(errorCode => {
          const toastMessage = _.get(translations, `toast.${errorCode}`, '');
          showCommunityPageToast(toastMessage);
        });
    }
  }

  handleEditPost(idPost) {
    const { showEditPost, postId } = this.state;
    this.setState({ showEditPost: !showEditPost, postId: postId !== idPost ? idPost : null });
  }

  renderEditPost(postData) {
    const editPostWidth = this.communityPagePostRef.current ? this.communityPagePostRef.current.clientWidth : 0;
    const { translations } = this.props;
    return <EditPostForm closeModal={this.handleEditPost} postData={postData} translations={translations} editPostWidth={editPostWidth} />;
  }

  handleRemoveModal(idPost) {
    const { showModalDelete, postId } = this.state;
    this.setState({
      showModalDelete: !showModalDelete,
      postId: postId !== idPost ? idPost : null,
    });
  }

  displayReportModal(postId) {
    const { heroIsCommunityMember, showCommunityPageToast } = this.props;
    if (!heroIsCommunityMember) {
      return showCommunityPageToast('toast.onlyMemberCanReport');
    }
    this.setState({ showReportModal: true, postId });
  }

  setReportModalVisibility() {
    const { showReportModal } = this.state;
    this.setState({ showReportModal: !showReportModal });
  }

  onReportSuccess(data) {
    const { report, showCommunityPageToast } = this.props;
    const { isReportDisabled } = this.state;
    const postId = _.get(data, 'postId', null);
    report(data)
      .then(() => {
        showCommunityPageToast('report.success');
        _.set(isReportDisabled, `${postId}`, true);
        this.setState({ isReportDisabled });
      })
      .catch(() => {
        showCommunityPageToast('report.fail');
      });
    this.setState({ showReportModal: false });
  }

  renderReportModal() {
    const { translations, heroProfile, community } = this.props;
    const { postId, title } = this.state;
    const reporterFirstName = _.get(heroProfile, 'firstName', '');
    const reporterLastName = _.get(heroProfile, 'lastName', '');
    const reporterName = `${reporterFirstName} ${reporterLastName}`;
    const communityName = _.get(community, 'name', '');
    const communityId = _.get(community, 'id', null);
    const heroId = _.get(heroProfile, 'id', null);
    return (
      <CommunityModal
        translations={translations}
        title={title}
        wrapperClasses="community-modals report-modal"
        body={
          <ReportModal
            translations={translations}
            reporterName={reporterName}
            communityName={communityName}
            communityId={communityId}
            postId={postId}
            heroId={heroId}
            reportType="post"
            toggleNextTitle={nextTitle => {
              this.setState({ title: nextTitle });
            }}
            onReportSuccess={this.onReportSuccess}
          />
        }
        closeModal={this.setReportModalVisibility}
      />
    );
  }

  handleClickOnLike(postId, alreadyLiked) {
    if (alreadyLiked) return;
    const { likeCommunityPost, heroProfile, heroIsCommunityMember, showCommunityPageToast } = this.props;
    if (!heroIsCommunityMember) {
      return showCommunityPageToast('toast.onlyMemberCanLikePost');
    }
    const currentHeroId = _.get(heroProfile, 'id', null);
    likeCommunityPost(currentHeroId, postId);
  }

  handleClickOnComment(postId) {
    const { expandedCommentBlocks } = this.state;
    const { heroIsCommunityMember, showCommunityPageToast } = this.props;
    if (!heroIsCommunityMember) {
      return showCommunityPageToast('toast.onlyMemberCanPost');
    }
    expandedCommentBlocks.push(postId);
    this.setState({ expandedCommentBlocks });
  }

  handleLikes(numberOfLikes, heroLikedPost) {
    const { translations } = this.props;
    const postTranslations = _.get(translations.communityPage, 'post', '');
    const likeRule =
      numberOfLikes === 0
        ? postTranslations.noLike
        : numberOfLikes > 1
        ? `${numberOfLikes} ${postTranslations.likes}`
        : `${numberOfLikes} ${postTranslations.like}`;
    if (heroLikedPost) {
      return (
        <>
          <img src={likeIconOrange} alt="" />
          <span className="community-page__post-like-orange"> {likeRule}</span>
        </>
      );
    }
    return (
      <>
        <img src={likeIcon} alt="" />
        <span className="community-page__post-like"> {likeRule}</span>
      </>
    );
  }

  handleContextMenuItems(authorId, postId, isNotified) {
    const { heroProfile, heroIsCommunityAdmin, translations } = this.props;
    const { isReportDisabled } = this.state;
    const currentHeroId = _.get(heroProfile, 'id', null);
    const postTranslations = _.get(translations.communityPage, 'post', '');

    let items = [];
    if (heroIsCommunityAdmin) {
      items = [
        {
          text: postTranslations.notifyCommunity,
          action: () => this.notifyCommunity(postId, isNotified),
        },
      ];
      if (currentHeroId === authorId) {
        items.push({
          text: postTranslations.editPost,
          action: this.handleEditPost.bind(this, postId),
        });
      }
      items.push({
        text: postTranslations.removePost,
        action: this.handleRemoveModal.bind(this, postId),
      });
    } else if (currentHeroId === authorId) {
      items = [
        {
          text: postTranslations.editPost,
          action: this.handleEditPost.bind(this, postId),
        },
        {
          text: postTranslations.removePost,
          action: this.handleRemoveModal.bind(this, postId),
        },
      ];
    } else {
      items = [
        {
          text: postTranslations.reportPost,
          action: this.displayReportModal.bind(this, postId),
          isDisabled: _.get(isReportDisabled, `${postId}`, false),
        },
      ];
    }

    return items;
  }

  handleAttachments(attachment, index, heroIsCommunityMember, showCommunityPageToast) {
    const attachmentSize = filesizeFormatter(attachment.attachmentSize);

    switch (attachment.attachmentType) {
      case 'image':
        return (
          <div key={index} className="community-page__posts-uploaded_image">
            <img
              src={sizedImage(attachment.attachmentUrl, 330, 0)}
              alt=""
              className="community-page__posts_image"
              onClick={() => this.handleImagePreview(attachment.attachmentUrl)}
            />
          </div>
        );
      case 'video':
        return (
          <a
            key={index}
            href={!heroIsCommunityMember ? '' : attachment.attachmentUrl}
            download={attachment.originalName}
            target="_blank"
            rel="noopener noreferrer"
            className="community-page__posts-uploaded_video"
            onClick={e => {
              if (!heroIsCommunityMember) {
                e.preventDefault();
                return showCommunityPageToast('toast.notCommunityMember');
              }
              return true;
            }}
          >
            <div className="community-page__posts-uploaded_video__container">
              <img src={uploadVideo} className="community-page__posts-uploaded_video__img" alt={attachment.originalName} />
              <div className="community-page__posts-uploaded_video__details">
                <div className="community-page__posts-uploaded_video__title">
                  <Truncate lines={1} ellipsis={<>... </>}>
                    {attachment.originalName}
                  </Truncate>
                </div>
                <div className="community-page__posts-uploaded_video__size">{attachmentSize}</div>
              </div>
              <div className="community-page__posts-uploaded_video__download-btn">
                <img src={downloadIcon} alt={attachment.originalName} />
              </div>
            </div>
          </a>
        );
      case 'document':
        return (
          <a
            key={index}
            href={!heroIsCommunityMember ? '' : attachment.attachmentUrl}
            download={attachment.originalName}
            target="_blank"
            rel="noopener noreferrer"
            className="community-page__posts-uploaded_video"
            onClick={e => {
              if (!heroIsCommunityMember) {
                e.preventDefault();
                return showCommunityPageToast('toast.notCommunityMember');
              }
              return true;
            }}
          >
            <div className="community-page__posts-uploaded_video__container community-page__posts-uploaded_document__container">
              <img src={uploadDocument} className="community-page__posts-uploaded_video__img" alt={attachment.originalName} />
              <div className="community-page__posts-uploaded_video__details">
                <div className="community-page__posts-uploaded_video__title">
                  <Truncate lines={1} ellipsis={<>... </>}>
                    {attachment.originalName}
                  </Truncate>
                </div>
                <div className="community-page__posts-uploaded_video__size">{attachmentSize}</div>
              </div>
              <div className="community-page__posts-uploaded_video__download-btn">
                <img src={downloadIcon} alt={attachment.originalName} />
              </div>
            </div>
          </a>
        );
      default:
        return '';
    }
  }

  handleImagePreview(image) {
    this.setState({ imagePreview: image });
  }

  toggleContentSeeAll() {
    const { showAllContent } = this.state;
    this.setState({ showAllContent: !showAllContent });
  }

  handlePostContent(content) {
    const { translations } = this.props;
    const { showAllContent } = this.state;
    const readMore = _.get(translations, 'readMore', 'readMore');
    const showLess = _.get(translations, 'showLess', 'showLess');
    if (content.length <= 550) {
      return <span className="community-page__post-container-title" dangerouslySetInnerHTML={{ __html: content }} />;
    }
    if (showAllContent) {
      return (
        <span className="community-page__post-container-title">
          <span dangerouslySetInnerHTML={{ __html: content }} />{' '}
          <button className="button__text-link" onClick={this.toggleContentSeeAll}>
            {showLess}
          </button>
        </span>
      );
    }
    const toShow = `${content.substring(0, 550)}...`;
    return (
      <span className="community-page__post-container-title">
        <span dangerouslySetInnerHTML={{ __html: toShow }} />{' '}
        <button className="button__text-link" onClick={this.toggleContentSeeAll}>
          {readMore}
        </button>
      </span>
    );
  }

  buildPostCard(post) {
    const { translations, heroProfile, history, heroIsCommunityMember, showCommunityPageToast } = this.props;
    const { expandedCommentBlocks, showEditPost, postId } = this.state;
    const postTranslations = _.get(translations.communityPage, 'post', '');
    const authorIsAdmin = _.get(post, 'authorIsAdmin', false);
    const adminLabel = _.get(translations, 'admin', 'admin');
    const authorId = _.get(post, 'authorId', null);
    const postAttachments = _.get(post, 'attachments', []);
    const currentHeroId = _.get(heroProfile, 'id', null);
    const numberOfComments = _.get(post, 'numberOfComments', 0);
    const commentsBlock = expandedCommentBlocks.indexOf(post.id) !== -1 || numberOfComments > 0 ? this.renderCommentsBlock(post) : null;
    const formattedCreationDate = dateTimeFormatter(post.createdAt);
    const profilePictureUrl = post.authorProfilePicture || noProfilePicture;
    const commentRule =
      numberOfComments === 0
        ? postTranslations.noComment
        : numberOfComments > 1
        ? `${numberOfComments} ${postTranslations.comments}`
        : `${numberOfComments} ${postTranslations.comment}`;
    const isNotified = _.get(post, 'notified', false);
    const renderEditPost = showEditPost && post.id === postId ? this.renderEditPost(post) : null;
    const heroInitials = _.get(post, 'heroInitials', null);
    return (
      <div key={`communityPost_${post.id}`} className="community-page__post" ref={this.communityPagePostRef}>
        {renderEditPost}
        <div className="community-page__post-hero-details">
          {post.authorProfilePicture ? (
            <img
              className="community-page__post-hero-picture"
              onClick={() => {
                history.push(authorId === currentHeroId ? '/account/profile' : `/connection/${authorId}`);
              }}
              src={profilePictureUrl}
              alt={post.authorName}
              title={post.authorName}
            />
          ) : (
            <div className="hero-initials post-card-hero-initials">{heroInitials}</div>
          )}

          <div className="community-page__post-hero-detail">
            <span
              className="community-page__post-hero-name"
              onClick={() => {
                history.push(authorId === currentHeroId ? '/account/profile' : `/connection/${authorId}`);
              }}
            >
              {post.authorName}
              {authorIsAdmin ? <span className="community-info-admin__label">{adminLabel}</span> : ''}
            </span>
            <span className="community-page__post-hero-headline"> {post.authorHeadline} </span>
            <span className="community-page__post-time"> {formattedCreationDate} </span>
          </div>
          <ContextMenu items={this.handleContextMenuItems(authorId, post.id, isNotified)} />
        </div>
        <div className="community-page__post-container">
          {this.handlePostContent(urlify(post.content))}
          {postAttachments.map((attachment, index) => {
            return this.handleAttachments(attachment, index, heroIsCommunityMember, showCommunityPageToast);
          })}
          {postAttachments.length === 0 && (
            <div className="community-page__posts-embedded-media">
              <Embedder content={post.content} />
            </div>
          )}
        </div>
        <div className="community-page__post-buttons">
          <div className="community-page__post-like-container" onClick={() => this.handleClickOnLike(post.id, post.liked)}>
            {this.handleLikes(post.numberOfLikes, post.liked)}
          </div>
          <div className="community-page__post-comment-container" onClick={() => this.handleClickOnComment(post.id)}>
            <img src={commentIcon} alt="Add comment" />
            <span className="community-page__post-comment"> {commentRule} </span>
          </div>
        </div>
        {commentsBlock}
      </div>
    );
  }

  renderCommentsBlock(post) {
    const { history, translations, isSinglePostPage } = this.props;
    const postId = _.get(post, 'id', null);
    return (
      <div key={`coments-block-${postId}`} className="community-page__comments-container">
        <CommentForm translations={translations} key={`comment-form${postId}`} post={post} history={history} />
        <CommentsCardContainer
          key={`coments-container-${postId}`}
          post={post}
          translations={translations}
          isSinglePostPage={isSinglePostPage}
        />
      </div>
    );
  }

  deletePostAction(postId) {
    const { deleteCommunityPost } = this.props;
    deleteCommunityPost(postId)
      .then(response => {
        this.setState({ showDeletePostToastWithSuccess: true, showModalDelete: false }, () => {
          setTimeout(() => {
            this.setState({ showDeletePostToastWithSuccess: false });
          }, 2000);
        });
      })
      .catch(() => {
        this.setState({ showDeletePostToastWithFailure: true, showModalDelete: false }, () => {
          setTimeout(() => {
            this.setState({ showDeletePostToastWithFailure: false });
          }, 2000);
        });
      });
  }

  buildRemovePostModal() {
    const { translations } = this.props;
    const { postId } = this.state;
    return (
      <CommunityModal
        title={_.get(translations.communityPage, 'post.deletePostModalTitle', 'post.deletePostModalTitle')}
        closeModal={this.handleRemoveModal.bind(this)}
        wrapperClasses="community-modals community-confirmation-modal__wrapper"
        body={
          <div className="community-confirmation-modal__container">
            <div className="community-confirmation-modal__confirmation-message">
              {_.get(translations.communityPage, 'post.deletePostMessage', 'post.deletePostMessage')}
            </div>
            <div className="community-confirmation-modal__actions">
              <button className="community-confirmation-modal__cancel-button" onClick={this.handleRemoveModal.bind(this)}>
                {_.get(translations, 'deleteCommunityModal.cancelButton', 'deleteCommunityModal.cancelButton')}
              </button>
              <button className="button-yellow community-confirmation-modal__save-button" onClick={() => this.deletePostAction(postId)}>
                {_.get(translations, 'deleteCommunityModal.deleteButton', 'deleteCommunityModal.deleteButton')}
              </button>
            </div>
          </div>
        }
      />
    );
  }

  renderImagePopupPreview() {
    const { imagePreview } = this.state;
    return (
      <Lightbox
        onClose={() => this.handleImagePreview('')}
        images={[{ src: sizedImage(imagePreview, 0, 0) }]}
        isOpen={!!imagePreview}
        currentImage={0}
      />
    );
  }

  render() {
    const { communityPosts, translations, action, isLoading, hasMorePosts, fetchMoreCommunityPost, isSinglePostPage } = this.props;
    const { showDeletePostToastWithSuccess, showDeletePostToastWithFailure, showModalDelete, showReportModal, imagePreview } = this.state;
    const createReportModal = showReportModal ? this.renderReportModal() : null;
    const showImagePreview = imagePreview ? this.renderImagePopupPreview() : null;
    if (isLoading) {
      return (
        <div className="community-loader-posts__container">
          <img className="community-loader__image" src={LoadingGif} alt="Loading" />
        </div>
      );
    }
    const openDeletePostModal = showModalDelete ? this.buildRemovePostModal() : '';
    return (
      <>
        {createReportModal}
        {showImagePreview}
        {openDeletePostModal}
        {showDeletePostToastWithSuccess && (
          <InfoToast message={_.get(translations, 'toast.deletePostWithSucces', 'toast.deletePostWithSucces')} />
        )}
        {showDeletePostToastWithFailure && (
          <InfoToast message={_.get(translations, 'toast.deletePostWithFailure', 'toast.deletePostWithFailure')} />
        )}
        {communityPosts && communityPosts.length > 0 ? (
          <>
            {communityPosts.map(this.buildPostCard)}
            {!isSinglePostPage && (
              <div className="community-page__load-more-container">
                {hasMorePosts ? (
                  <LoadMore fetchNextPosts={fetchMoreCommunityPost} trans={translations} />
                ) : communityPosts.length > 6 ? (
                  <NoMorePostsMessage trans={translations} action={() => action()} />
                ) : null}
              </div>
            )}
          </>
        ) : (
          !isSinglePostPage && <NoPostCard translations={translations} action={() => action()} />
        )}
      </>
    );
  }
}
export default withRouter(PostCard);
