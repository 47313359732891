import uploadImage from '../../../../../assets/svg/community/uploads/community-uploads-image.svg';
import uploadVideo from '../../../../../assets/svg/community/uploads/community-uploads-video.svg';
import uploadDocument from '../../../../../assets/svg/community/uploads/community-uploads-document.svg';

const getAttachmentTypeImage = type => {
  switch (type) {
    case 'image':
      return uploadImage;
    case 'video':
      return uploadVideo;
    default:
      return uploadDocument;
  }
};

export default getAttachmentTypeImage;
