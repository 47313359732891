import { connect } from 'react-redux';
import _ from 'lodash';
import PaymentProfile from './PaymentProfile';
import {
  beforeFetchPaymentProfile,
  beforeUpdateHeroPaymentProfile,
  createHeroPaymentProfile,
  fetchPaymentProfile,
  fetchPaymentProfiles,
  updateHeroPaymentProfile,
  createPaymentMethod,
} from '../../actions/locationActions';
import { translations } from '../../selectors/translations';
import './index.scss';
import { fetchProfile } from '../../actions/accountActions';
import { fetchCountries } from '../../actions/generalDataActions';
import { validateVoucher } from '../../actions/accountPaymentSettings/vouchers';

const mapStateToProps = state => ({
  heroProfile: state.global.get('heroProfile'),
  translations: _.get(translations(state), 'paymentProfile', {}),
  paymentProfile: state.addBooking.get('paymentProfile'),
  paymentProfileErrors: state.addBooking.get('paymentProfileErrors'),
  paymentProfileUpdateInProgress: state.addBooking.get('paymentProfileUpdateInProgress'),
  paymentProfiles: state.addBooking.get('paymentProfiles'),
  countries: state.account.get('countries'),
  formatString: _.get(translations(state), 'formatString', () => {}),
});

const mapDispatchToProps = dispatch => ({
  validateVoucher: code => {
    return dispatch(validateVoucher(code));
  },
  fetchProfile: () => {
    dispatch(fetchProfile());
  },
  fetchPaymentProfiles: () => {
    dispatch(fetchPaymentProfiles());
  },
  fetchPaymentProfile: id => {
    dispatch(fetchPaymentProfile(id));
  },
  beforeFetchPaymentProfile: () => {
    dispatch(beforeFetchPaymentProfile());
  },
  beforeUpdateHeroPaymentProfile: () => {
    dispatch(beforeUpdateHeroPaymentProfile());
  },
  createHeroPaymentProfile: (data, cb) => {
    dispatch(createHeroPaymentProfile(data, cb));
  },
  createPaymentMethod: (data, cb) => {
    dispatch(createPaymentMethod(data, cb));
  },
  updateHeroPaymentProfile: data => {
    dispatch(updateHeroPaymentProfile(data));
  },
  fetchCountries: () => {
    dispatch(fetchCountries());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentProfile);
