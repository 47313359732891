import React from 'react';
import {CardElement} from 'react-stripe-elements';

class CardSection extends React.Component {
  render() {
    return (
      <label style={{width: '100%'}}>
        <CardElement
          elementRef={this.props.onCardLoaded}
          hidePostalCode={true}
          style={
          {base: {fontSize: '18px', '::placeholder': {color: '#000'}}}
      } />
      </label>
    );
  }
};

export default CardSection;
