import { connect } from 'react-redux';
import _ from 'lodash';
import { translations } from '../../../../selectors/translations';
import { communitySelector } from '../../../../selectors/community/community-page';
import { deleteCommunity } from '../../../../actions/community/delete-community';
import DeleteCommunityModal from './DeleteCommunityModal';
import { isLoadingSelector } from '../../../../selectors/community/delete-community';

const mapStateToProps = state => ({
  translations: _.get(translations(state), 'community', []),
  community: communitySelector(state),
  isLoading: isLoadingSelector(state),
});

const mapDispatchToProps = dispatch => ({
  deleteCommunity: id => dispatch(deleteCommunity(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeleteCommunityModal);
