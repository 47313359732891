import api from '../../components/community/utils/api';
/*
 * Constants
 */
export const SET_COMMUNITY_COMMENT_EDIT_IS_LOADING = 'SET_COMMUNITY_COMMENT_EDIT_IS_LOADING';
export const SET_COMMUNITY_COMMENT_EDIT_ERRORS = 'SET_COMMUNITY_COMMENT_EDIT_ERRORS';
export const SET_COMMUNITY_UPDATED_COMMENT = 'SET_COMMUNITY_UPDATED_COMMENT';

/*
 * Sync Actions
 */
export const setCommunityCommentEditIsLoading = isLoading => ({
  type: SET_COMMUNITY_COMMENT_EDIT_IS_LOADING,
  isLoading,
});

export const setFormError = errors => ({
  type: SET_COMMUNITY_COMMENT_EDIT_ERRORS,
  errors,
});

export const updateComment = comment => ({
  type: SET_COMMUNITY_UPDATED_COMMENT,
  comment,
});

/*
 * Async Actions
 */
export const saveComment = data => dispatch => {
  dispatch(setCommunityCommentEditIsLoading(true));
  dispatch(setFormError({}));

  return new Promise((resolve, reject) => {
    api
      .post(`comments/edit`, data)
      .then(response => {
        dispatch(setCommunityCommentEditIsLoading(false));
        dispatch(updateComment(response.data.data));
        resolve(true);
      })
      .catch(err => {
        if (err.response.data.error) {
          dispatch(setFormError(err.response.data.error));
          dispatch(setCommunityCommentEditIsLoading(false));
          reject(err.response.data);
        }
      });
  });
};
