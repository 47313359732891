import { connect } from 'react-redux';
import _ from 'lodash';
import { auth } from '../../../actions/openAuth/login';
import ThirdPartyAuth from './ThirhPartyAuth';
import { translations } from '../../../selectors/translations';
import { updateRegisteredAttendee } from '../../../actions/meetingAttendeesActions';
import { setIsWhiteLabel } from '../../../actions/global';
import { joinPrivateCommunity } from '../../../actions/community/join-community';

const mapStateToProps = state => ({
  openAuthTranslations: _.get(translations(state), 'openAuth', {}),
});

const mapDispatchToProps = dispatch => ({
  auth: data => dispatch(auth(data)),
  updateRegisteredAttendee: data => dispatch(updateRegisteredAttendee(data)),
  setIsWhiteLabel: isWhiteLabel => dispatch(setIsWhiteLabel(isWhiteLabel)),
  joinPrivateCommunity: communityUrl => dispatch(joinPrivateCommunity(communityUrl)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ThirdPartyAuth);
