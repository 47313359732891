import React from 'react';
import Truncate from 'react-truncate';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { withRouter } from 'react-router-dom';
import globalIcon from '../../../../assets/svg/community/community-global.svg';
import verticalIcon from '../../../../assets/svg/community/community-verticals.svg';
import lockIcon from '../../../../assets/svg/community/community-lock.svg';
import sizedImage from '../../../../utils/imageHelper';
import blockIncompleteProfileAction from '../../../../utils/blockIncompleteProfileAction';

const communityTypeImage = type => {
  switch (type) {
    case 'specific_verticals':
      return verticalIcon;
    case 'closed':
      return lockIcon;
    default:
      return globalIcon;
  }
};

const audienceText = (type, verticals) => {
  const noOfVerticals = verticals.length;
  if (noOfVerticals > 0) return noOfVerticals - 1 ? `${verticals[0]}, +${noOfVerticals - 1} more` : `${verticals[0]}`;
  return type === 'public' ? 'Public' : 'Private';
};

const CommunityCard = props => {
  const { img, title, members, buttonText, type, verticals, action, translations, history, location } = props;
  const isAllCommunities = _.get(props, 'isAllCommunities', false);
  const transBasedOnVerticals = _.get(translations, 'all.basedOnVerticals', 'all.basedOnVerticals');
  const transBasedOnSimilarCommunities = _.get(translations, 'all.basedOnSimilarCommunities', 'all.basedOnSimilarCommunities');
  const membersCountLabel = members > 1 ? _.get(translations, 'members', 'members') : _.get(translations, 'member', 'member');

  return (
    <div
      className="community-card"
      onClick={event => {
        event.preventDefault();
        event.stopPropagation();
        blockIncompleteProfileAction()
          .then(() => {
            history.push({ pathname: '/account/profile/incomplete', fromUrl: location.pathname });
          })
          .catch(() => {
            action();
          });
      }}
    >
      <img src={sizedImage(img, 60, 0)} alt={title} className="community-card__image" />
      <div className="community-card__details">
        {isAllCommunities ? (
          <div className="community-card__based-on-info">{type === 'public' ? transBasedOnSimilarCommunities : transBasedOnVerticals}</div>
        ) : null}
        <div className="community-card__name">
          <Truncate lines={3} ellipsis={<>... </>}>
            {title}
          </Truncate>
        </div>
        <div className="community-card__footer">
          <img src={communityTypeImage(type)} alt={title} className="community-card__svg" />
          <span>{audienceText(type, verticals)}</span>
          <span className="dot-between__footer">•</span>
          <span className="community-members">
            <span>{members}</span>&nbsp;<span>{membersCountLabel}</span>
          </span>
        </div>
      </div>
      <button
        type="button"
        className="dashboard-button dashboard-button--secondary"
        onClick={event => {
          event.preventDefault();
          event.stopPropagation();
          blockIncompleteProfileAction()
            .then(() => {
              history.push({ pathname: '/account/profile/incomplete', fromUrl: location.pathname });
            })
            .catch(() => {
              action();
            });
        }}
      >
        {buttonText}
      </button>
    </div>
  );
};

CommunityCard.propTypes = {
  img: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  members: PropTypes.number.isRequired,
  buttonText: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  verticals: PropTypes.arrayOf(PropTypes.string),
  translations: PropTypes.arrayOf(),
  action: PropTypes.func,
};

CommunityCard.defaultProps = {
  action: () => null,
  verticals: [],
  translations: {},
};

export default withRouter(CommunityCard);
