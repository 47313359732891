export const FETCH_EVENTS_ALL = 'FETCH_EVENTS_ALL';
export const FETCH_EVENTS_NEARBY = 'FETCH_EVENTS_NEARBY';
export const FETCH_EVENTS_ACTIVE = 'FETCH_EVENTS_ACTIVE';
export const FETCH_EVENT = 'FETCH_EVENT';
export const CLEAR_EVENT = 'CLEAR_EVENT';
export const ADD_HERO_TO_EVENT = 'ADD_HERO_TO_EVENT';

/**
 * ! Deprecated
 * We sync on eventbrite event directly from booking app
 * 
 * See: #4405
 */
export const UPDATE_HERO_EVENTS_ATTENDEES = 'UPDATE_HERO_EVENTS_ATTENDEES';

export const fetchEventsAll = () => {
  return {
    type: FETCH_EVENTS_ALL,
    url: window.__env.apiUrl + `/events/getAll`,
  }
};

export const fetchEventsNearby = (coord) => {
  return {
    type: FETCH_EVENTS_NEARBY,
    url: window.__env.apiUrl + `/events/getNear`,
    coord
  }
};

export const fetchActiveEvents = () => {
  return {
    type: FETCH_EVENTS_ACTIVE,
    url: window.__env.apiUrl + `/events/getActive`,
  }
};

/**
 * ! Deprecated
 * We sync on eventbrite event directly from booking app
 * 
 * See: #4405
 */
export const updateHeroEventsAttendees = () => {
  return {
    type: UPDATE_HERO_EVENTS_ATTENDEES,
    url: window.__env.apiUrl + `/events/updateHeroEventsAttendees`,
  }
};

export const fetchEvent = (eventId) => {
  return {
    type: FETCH_EVENT,
    url: window.__env.apiUrl + `/events/eventDetails`,
    eventId
  }
};

export const clearEvent = () => {
  return {
    type: CLEAR_EVENT
  }
};

export const addHeroToEvent = (eventId) => ({
  type: ADD_HERO_TO_EVENT,
  url: `${window.__env.apiUrl}/events/attendEvent`,
  eventId
});
