import React from 'react';
import classnames from 'classnames';

import './css/checkbox.scss';

const Checkbox = (props) => {
  let {label, checked, onChange, className} = props;

  return (
    <div className={classnames({'checked': checked}, 'checkbox', className)} onClick={onChange}>{label}</div>
  );
};

export default Checkbox;
