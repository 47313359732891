import Immutable from 'immutable';
import _ from 'lodash';
import {
  FETCH_CONNECTIONS,
  FETCH_CONNECTION_CONNECTIONS,
  BEFORE_FETCH_CONNECTION,
  FETCH_CONNECTION,
  FETCH_NEARBY_CONNECTIONS,
  BEFORE_FETCH_RECOMMENDED_CONNECTIONS,
  FETCH_RECOMMENDED_CONNECTIONS,
  REMOVE_CONNECTION,
  ADD_CONNECTION,
  ON_SUGGESTIONS_CHANGE,
  CHANGE_SUGGESTION_FILTERS,
  DESELECT_ALL,
  TOTAL_AVAILABLE_CONNECTIONS,
} from '../actions/connections';
import { FETCH_NOTIFICATIONS, TRIGGER_REFRESH_NOTIFICATIONS } from '../actions/notificationsActions';

// const pageSize = 16;

const defaultState = Immutable.fromJS({
  add_connection: false,
  add_multiple_connections: false,
  currentConnection: {},
  existing: [],
  loaded: false,
  nearby: [],
  recommended: [],
  refreshNotifications: false,
  selectedConnections: [],
  selectedFilters: ['1', '2', '3'],
  page: 1,
  loadMoreSpinner: false,
  total: 0,
  lastRecommendedConnections: [],
  hasMoreToLoad: true,
  hasMore: true,
  totalAvailableConnections: 0,
});

const connections = (state = defaultState, action) => {
  let newState;
  switch (action.type) {
    case FETCH_CONNECTIONS:
      if (_.get(action, 'page', 0) === 1) {
        newState = state.set('existing', Immutable.fromJS(action.data.connections));
      } else {
        newState = state.set('existing', state.get('existing').concat(Immutable.fromJS(action.data.connections)));
      }
      newState = newState.set('total', action.data.total);
      newState = newState.set('loaded', true);
      newState = newState.set('hasMore', action.data.hasMore);
      break;

    case CHANGE_SUGGESTION_FILTERS:
      const selectedFilters = state.get('selectedFilters').toJS();
      selectedFilters.includes(action.value)
        ? selectedFilters.splice(selectedFilters.indexOf(action.value), 1)
        : selectedFilters.push(action.value);
      newState = state.set('selectedFilters', Immutable.fromJS(selectedFilters));
      break;

    case FETCH_CONNECTION_CONNECTIONS:
      // newState = state.set('existing', state.get('existing').concat(Immutable.fromJS(action.data.items)));
      newState = state.set('existing', Immutable.fromJS(action.data));
      newState = newState.set('loaded', true);
      // newState = newState.set('newConnections', action.data.newConnections);
      break;

    case FETCH_NEARBY_CONNECTIONS:
      // newState = state.set('nearby', Immutable.fromJS(action.data.items));
      break;

    case BEFORE_FETCH_RECOMMENDED_CONNECTIONS:
      newState = state.set('loadMoreSpinner', true);
      break;

    case FETCH_RECOMMENDED_CONNECTIONS:
      const newConnections = _.get(action, 'data.data', []);
      const isFirstPage = _.get(action, 'data.page.currentPage', 1) === 1;
      const hasMoreToLoad = _.get(action, 'data.page.hasMorePages', false);
      const selectedConnections = state.get('selectedConnections', Immutable.List()).toJS();
      const connections = isFirstPage
        ? newConnections
        : state
            .get('recommended', Immutable.List())
            .toJS()
            .concat(newConnections);
      const prevSelectedConnections = isFirstPage ? [] : selectedConnections;
      const newSelectedConnections = [...new Set(prevSelectedConnections.concat(newConnections.map(x => x.id)))];
      newState = state
        .set('recommended', Immutable.fromJS(connections))
        .set('selectedConnections', Immutable.fromJS(newSelectedConnections))
        .set('hasMoreToLoad', hasMoreToLoad)
        .set('loadMoreSpinner', false);
      break;

    case BEFORE_FETCH_CONNECTION:
      newState = state.set('currentConnection', Immutable.fromJS({}));
      break;

    case FETCH_NOTIFICATIONS:
      newState = state.set('refreshNotifications', false);
      break;

    case TRIGGER_REFRESH_NOTIFICATIONS:
      newState = state.set('refreshNotifications', true);
      break;

    case FETCH_CONNECTION:
      newState = state.set('currentConnection', Immutable.fromJS(action.data));
      break;

    case REMOVE_CONNECTION:
      newState = state.set('refreshNotifications', true);
      newState = newState.setIn(['currentConnection', 'connection', 'isConnected'], false);
      break;
    case ADD_CONNECTION:
      newState = state.set('add_connection', Immutable.fromJS(action.data));
      break;
    case ON_SUGGESTIONS_CHANGE:
      newState = state.updateIn(['selectedConnections'], arr => {
        return action.checked ? arr.push(action.value) : arr.splice(arr.indexOf(action.value), 1);
      });
      break;
    case DESELECT_ALL:
      newState = state.set('selectedConnections', Immutable.fromJS([]));
      break;
    case TOTAL_AVAILABLE_CONNECTIONS:
      newState = state.set('totalAvailableConnections', action.data.connectionsAvailableCount);
      break;
    default:
      newState = state;
  }
  return newState;
};

export default connections;
