import Immutable from 'immutable';
import {
  FETCH_NEAREST_OFFICES,
  FETCH_RECENTLY_ADDED,
  FETCH_MY_BOOKINGS,
  FETCH_AVAILABLE_OFFICES,
  FETCH_COMPANIES,
  FETCH_FEATURED_OFFICES,
  SET_BOOKINGS,
  RESET_BOOKING_MAP
} from '../actions/officesActions';
import { ON_RESET_FILTERS } from '../actions/filtersActions';
import { ON_LOCATION_CHANGE_SEARCH } from '../actions/map';


const defaultState = Immutable.fromJS({
  availableCompanies: [],
  nearestOffices: [],
  myBookings: [],
  availableOffices: [],
  recentlyAdded: [],
  isLoading: true,
});

const offices = (state = defaultState, action) => {
  let newState;
  switch (action.type) {
    case FETCH_NEAREST_OFFICES:
      newState = state.set('nearestOffices', Immutable.fromJS(action.data));
      break;

    case FETCH_COMPANIES:
      newState = state.set('availableCompanies', Immutable.fromJS(action.data));
      break;

    case FETCH_RECENTLY_ADDED:
      newState = state.set('recentlyAdded', Immutable.fromJS(action.data));
      break;
    case SET_BOOKINGS:
      newState = state.set('isLoading', Immutable.fromJS(action.isLoading));
      break;

    case FETCH_FEATURED_OFFICES:
      newState = state.set('featuredOffices', Immutable.fromJS(action.data));
      break;

    case FETCH_MY_BOOKINGS:
      newState = state.set('myBookings', Immutable.fromJS(action.data));
      break;

    case ON_LOCATION_CHANGE_SEARCH:
    case RESET_BOOKING_MAP:
      newState = state.set('availableOffices', Immutable.fromJS([]));
      break;

    case ON_RESET_FILTERS:
    case FETCH_AVAILABLE_OFFICES:
      const stateOffices = state.toJS().availableOffices;
      const newOffices = action.data;
      let found = false;
      let i = 0;
      let j = 0;

      if (newOffices.length > 0) {
        for (i = 0; i < newOffices.length; i++) {
          if (stateOffices.length > 0) {
            found = false;
            for (j = 0; j < stateOffices.length; j++) {
              if (stateOffices[j].id === newOffices[i].id) {
                found = true;
                stateOffices[j] = newOffices[i];
              }
            }
            if (found === false) {
              stateOffices.push(newOffices[i]);
            }
          } else {
            stateOffices.push(newOffices[i]);
          }
        }
      }

      const finalOffices = stateOffices.length > 0 ? stateOffices : action.data;
      newState = state.set('availableOffices', Immutable.fromJS(finalOffices));
      break;

    default:
      newState = state;
  }

  return newState;
};

export default offices;
