import React from 'react';
import _ from 'lodash';
import getLang from './../../utils/lang';

import {Elements, injectStripe, StripeProvider} from 'react-stripe-elements';
import CardSection from '../accountAddEditPaymentProfile/CardSection';
import './css/repayForm.scss';
import CheckoutFormBancontact from "../addBookingSidebar/CheckoutFormBancontact";
import classnames from 'classnames';

const lang = getLang() !== null ? getLang() : 'en';
class RepayForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cardErrors: null,
            spinner: false,
            message: props.errorMessage,
            error: null,
            success: false
        };
    }

    handleSubmit = (ev) => {
        // We don't want to let default form submission happen here, which would refresh the page.
        ev.preventDefault();
        this.setState({
            spinner: true
        });
        this.props.stripe.handleCardPayment(
            this.props.paymentIntentSecretId
        ).then(function (result) {
            if (result.paymentIntent) {
                this.setState({
                    success: true,
                    error: null
                });
                window.location.href = window.__env.apiUrl + "/stripe-approve?payment_intent=" + result.paymentIntent.id;
            }
            if(result.error) {
                this.setState({
                    spinner: false,
                    error: result.error.message
                });
            }
            // Handle result.error or result.paymentIntent
        }.bind(this));

        // Within the context of `Elements`, this call to createToken knows which Element to
        // tokenize, since there's only one in this group.
    }

    onCardLoaded = (elementRef) => {
        elementRef.addEventListener('change', function (event) {
            let displayError = null;
            if (event.error) {
                displayError = event.error.message;
            } else {
                displayError = null;
            }
            this.setState({
                cardErrors: displayError
            });
        }.bind(this));
    }

    message = () => {
        if(this.state.error === null) {
            return this.state.message
        } else {
            return this.state.error
        }
    }

    render() {
        const { trans } = this.props;

        if (this.props.paymentIntentSecretId && this.props.paymentIntentSecretId !== null) {
            if(!this.state.success) {
                return (
                    <div className="repay-form__container">
                        <div className={classnames({ 'hidden': this.state.spinner ? true : false })}>
                            <div className="repay-form__message-container">
                                <span className="repay-form__message">
                                    {this.message()}
                                </span>
                            </div>
                            <div className="repay-form__card-container">
                                <form onSubmit={this.handleSubmit}>
                                    <CardSection
                                        onCardLoaded={this.onCardLoaded}
                                    />
                                    {this.props.errors}
                                    <div>
                                        <button
                                            className={classnames({ 'disabled': this.state.cardErrors === null ? false : true }, 'add-card-button')}>{this.props.buttonText ? this.props.buttonText : _.get(trans, 'addCart', '')}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className={classnames({ 'hidden': this.state.spinner ? false : true })}>
                            <div className="LoaderInverse"></div>
                        </div>
                        <div>
                            <StripeProvider apiKey={window.__env.stripeApiKey}>
                                <Elements locale={lang}>
                                    <CheckoutFormBancontact
                                        autoRedirect={false}
                                        paymentIntent={this.props.paymentIntentSecretId}
                                        availablePaymentMethods={_.get(this.props, 'availablePaymentMethods', [])}
                                        trans={_.get(this.props, 'trans', [])} />
                                </Elements>
                            </StripeProvider>
                        </div>
                    </div>
                );
            } else {
                return (
                    <div className="repay-form__container">
                        <div className={classnames({ 'hidden': this.state.spinner ? false : true })}>
                            <div className="LoaderInverse"></div>
                        </div>
                    </div>
                );
            }
        } else {
            return null;
        }
    }
}

export default injectStripe(RepayForm);