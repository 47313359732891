import React, { useEffect } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import _ from 'lodash';
import Embedder from '../community/utils/embedder';
import temperatureControl from '../../assets/svg/healthMeasure/temperature-control-in-place.svg';
import healthSafety from '../../assets/svg/healthMeasure/health-safety-measures.svg';
import floorMarks from '../../assets/svg/healthMeasure/floorMarks.svg';
import secureDistance from '../../assets/svg/healthMeasure/secureDistance.svg';
import plexiglass from '../../assets/svg/healthMeasure/plexiglass.svg';
import disinfectant from '../../assets/svg/healthMeasure/disinfectant.svg';
import information from '../../assets/svg/healthMeasure/information.svg';
import handShake from '../../assets/svg/healthMeasure/handShake.svg';

const HealthMeasures = props => {
  useEffect(() => {
    localStorage.setItem('health-measure-key', true);
  }, []);

  return (
    <Scrollbars className="styleScroll">
      <div className="health-measure__container">
        <div className="health-measure-grid">
          <div className="health-measure-grid__column health-measure-grid__column--left">
            <div className="health-measure__left-container">
              <h1 className="health-measure-title">{_.get(props.translations, 'title', 'title')}</h1>
              <span className="health-measure-subtitle">{_.get(props.translations, 'subtitle', 'subtitle')}</span>
              <p className="health-measure-description">{_.get(props.translations, 'description', 'description')}</p>
              <div className="health-measure__embedded-media">
                <Embedder content="https://www.youtube.com/watch?v=Vg8vSmlgYjw&feature=youtu.be" />
              </div>
            </div>
          </div>
          <div className="health-measure-grid__column health-measure-grid__column--right">
            <div className="health-measure__sidebar_section">
              <div className="health-measure__guideline-section">
                <span className="health-measure__guideline-title">{_.get(props.translations, 'guidelineTitle', 'guidelineTitle')}</span>
                <p className="health-measure__guideline-subtitle">
                  {_.get(props.translations, 'guidelineDescription', 'guidelineDescription')}
                </p>
                <div className="health-measure__guideline-grid">
                  <div className="health-measure__guideline-row">
                    <div className="health-measure__guideline-column">
                      <img src={floorMarks} alt="Floor markings" />
                      <span className="header-measure__icon-title"> {_.get(props.translations, 'floorMarkings', 'floorMarkings')}</span>
                      <span className="header-measure__icon-subtitle">
                        {_.get(props.translations, 'floorMarkingsSubtitle', 'floorMarkingsSubtitle')}
                      </span>
                    </div>
                    <div className="health-measure__guideline-column">
                      <img src={secureDistance} alt="Floor markings" />
                      <span className="header-measure__icon-title"> {_.get(props.translations, 'security', 'security')}</span>
                      <span className="header-measure__icon-subtitle">
                        {_.get(props.translations, 'securitySubtitle', 'securitySubtitle')}
                      </span>
                    </div>
                    <div className="health-measure__guideline-column">
                      <img src={plexiglass} alt="Plexiglas" />
                      <span className="header-measure__icon-title"> {_.get(props.translations, 'plexiglas', 'plexiglas')}</span>
                    </div>
                  </div>
                  <div className="health-measure__guideline-row">
                    <div className="health-measure__guideline-column">
                      <img src={information} alt="Information available" />
                      <span className="header-measure__icon-title"> {_.get(props.translations, 'cleaning', 'cleaning')}</span>
                      <span className="header-measure__icon-subtitle">
                        {_.get(props.translations, 'cleaningSubtitle', 'cleaningSubtitle')}
                      </span>
                    </div>
                    <div className="health-measure__guideline-column">
                      <img src={disinfectant} alt="Disinfectant gel, masks available" />
                      <span className="header-measure__icon-title"> {_.get(props.translations, 'disinfectant', 'disinfectant')}</span>
                      <span className="header-measure__icon-subtitle">
                        {_.get(props.translations, 'disinfectantSubtitle', 'disinfectantSubtitle')}
                      </span>
                    </div>
                    <div className="health-measure__guideline-column">
                      <img src={handShake} alt="A sound and conscious business approach" />
                      <span className="header-measure__icon-title">
                        {_.get(props.translations, 'soundAndConscious', 'soundAndConscious')}
                      </span>
                      <span className="header-measure__icon-subtitle">
                        {_.get(props.translations, 'soundAndConsciousSubtitle', 'soundAndConsciousSubtitle')}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="health-measure__discover-measures card__container card__container--border ">
                <div className="health-measure__stamps">
                  <img src={healthSafety} alt="Health safety measure" />
                  <img src={temperatureControl} alt="Temperature control in place" />
                </div>
                <span className="health-measure__discover-title">
                  {_.get(props.translations, 'discoverMeasuresTitle', 'discoverMeasuresTitle')}
                </span>
                <p className="health-measure__discover-subtitle">
                  {_.get(props.translations, 'discoverMeasuresSubtitle', 'discoverMeasuresSubtitle')}
                </p>
                <a
                  href={`mailto:${_.get(props.translations, 'discoverMeasureEmail', 'discoverMeasureEmail')}`}
                  className="health-measure__email"
                >
                  {_.get(props.translations, 'discoverMeasureEmail', 'discoverMeasureEmail')}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Scrollbars>
  );
};
export default HealthMeasures;
