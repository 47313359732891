import React, { Component } from 'react';
import PropTypes from 'prop-types';
import sizedImage from './../../utils/imageHelper';
import Lightbox from 'react-images';

class OfficeGallery extends Component {

  constructor(props){
    super(props);
    this.state = {
      galleryOpened: false,
      currentImage: {}
    };
  }

  onOpenGallery = () => {
    this.setState({
      galleryOpened: true
    });
  }

  onCloseGallery = () => {
    this.setState({
      galleryOpened: false
    });
  }

  gotoPrevious = () => {
    this.setState({
      currentImage: this.state.currentImage - 1
    });
  }

  gotoNext = () => {
    this.setState({
      currentImage: this.state.currentImage + 1
    });
  }

  onClickThumbnail = (e) => {
    this.setState({
      currentImage: e
    });
  }

  render(){
    let [featured, middle, more, ...remaining] = this.props.images;
    let remainingNoLabel = '';
    if(remaining.length > 0)
    {
      if(remaining.length === 1)
      {
        remainingNoLabel = this.props.trans.bookingPage.plus1more;
      }
      else
      {
        remainingNoLabel = this.props.trans.formatString(this.props.trans.bookingPage.plusXmore, remaining.length);
      }
    }

    if(typeof featured === "undefined"){
      featured = {};
      featured.url = '';
    }

    if(typeof middle === "undefined"){
      middle = {};
      middle.url = '';
    }

    let galleryImages = [];
    this.props.images.map((image) => {
      galleryImages.push({src: sizedImage(image.url, 1920, 1080)});
      return null;
    });

    let remainingEl = (typeof more === "undefined") ? <div></div> : <div
      className="office-gallery-more"
      onClick={() => { this.setState({currentImage: 2}, () => { this.onOpenGallery();});}}
      data-label={remainingNoLabel}
      style={{ backgroundImage: `url(${ sizedImage(more.url, 464, 305)})`, backgroundPosition: 'center center', cursor: 'pointer'}}></div>;

    let secondImage = middle.url === '' ? <div></div> : <div
      onClick={() => { this.setState({currentImage: 1}, () => { this.onOpenGallery();});}}
      className="office-gallery-middle"
      style={{ backgroundImage: `url(${ sizedImage(middle.url, 464, 305)})`, backgroundPosition: 'center center', cursor: 'pointer'}}></div>;
    
    return (
      <div className="office-gallery">
        <div
          className="office-gallery-featured"
          onClick={() => { this.setState({currentImage: 0}, () => { this.onOpenGallery();});}}
          style={{ backgroundImage: `url(${ sizedImage(featured.url, 756, 610)})`, backgroundPosition: 'center center', cursor: 'pointer'}}></div>
        <div className="office-gallery-aside">
            { secondImage }
            { remainingEl }
        </div>

        <Lightbox
           images={galleryImages}
           currentImage={this.state.currentImage}
           isOpen={this.state.galleryOpened}
           onClickPrev={this.gotoPrevious}
           onClickNext={this.gotoNext}
           onClose={this.onCloseGallery}
           showThumbnails={true}
           onClickThumbnail={this.onClickThumbnail}
           preloadNextImage={true}
           width={1920}
         />
      </div>
    )
  }
}

OfficeGallery.propTypes = {
  images: PropTypes.array.isRequired,
};

export default OfficeGallery;
