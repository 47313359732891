import { connect } from 'react-redux';
import NewCompanyProfile from './NewCompanyProfile';
import addLoader from '../../utils/add-loader';
import toJS from '../../utils/to-js';
import _ from 'lodash';
import { fetchNewCompanyProfile } from '../../../../actions/dashboard/new-company-profile';
import { fetchMediaCompanyProfile } from '../../../../actions/dashboard/media-company-profile';

import {
  companySelector,
  companyLogoSelector,
  isLoadingSelector,
  jobsSelector,
  tagsSelector,
  productTypesSelector,
} from '../../../../selectors/dashboard/new-company-profile';

import {
  companySelector as mediaCompanySelector,
  companyLogoSelector as mediaCompanyLogoSelector,
  isLoadingSelector as mediaIsLoadingSelector,
} from '../../../../selectors/dashboard/media-company-profile';
import { translations } from '../../../../selectors/translations';
const mapStateToPropsNew = state => ({
  company: companySelector(state),
  companyLogo: companyLogoSelector(state),
  jobs: jobsSelector(state),
  tags: tagsSelector(state),
  productTypes: productTypesSelector(state),
  isLoading: isLoadingSelector(state),
  translations: _.get(translations(state), 'dashboard', {})
});

const mapDispatchToPropsNew = dispatch => ({
  fetchNewCompanyProfile: () => dispatch(fetchNewCompanyProfile()),
});

const NewCompanyProfileWithConnect = connect(
  mapStateToPropsNew,
  mapDispatchToPropsNew
)(toJS(addLoader(NewCompanyProfile, true)));

const mapStateToPropsMedia = state => ({
  company: mediaCompanySelector(state),
  companyLogo: mediaCompanyLogoSelector(state),
  isLoading: mediaIsLoadingSelector(state),
});

const mapDispatchToPropsMedia = dispatch => ({
  fetchMediaCompanyProfile: () => dispatch(fetchMediaCompanyProfile()),
});

const MediaCompanyProfileWithConnect = connect(
  mapStateToPropsMedia,
  mapDispatchToPropsMedia
)(toJS(addLoader(NewCompanyProfile, true)));

export { MediaCompanyProfileWithConnect as MediaCompanyProfile, NewCompanyProfileWithConnect as NewCompanyProfile };
