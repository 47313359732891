import { connect } from 'react-redux';
import Accordion from './Accordion';
import { acceptMeetingInvitation, declineMeetingInvitation } from '../../../actions/meetingAttendeesActions';

const mapStateToProps = state => ({
  translations: state.translations,
  heroProfile: state.global.get('heroProfile'),
  isLoadingBookingId: state.meetingAttendees.get('isLoadingBookingId'),
});

const mapDispatchProps = dispatch => ({
  acceptMeetingInvitation: (attendeeId, status, bookingId, officeId) =>
    dispatch(acceptMeetingInvitation(attendeeId, status, bookingId, officeId)),
  declineMeetingInvitation: (attendeeId, status, bookingId, officeId) =>
    dispatch(declineMeetingInvitation(attendeeId, status, bookingId, officeId)),
});

export default connect(
  mapStateToProps,
  mapDispatchProps
)(Accordion);
