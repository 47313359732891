import React from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import InputField from '../inputField';

class ForgotPasswordForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      isLoading: false,
      errors: {},
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.transformEmail = this.transformEmail.bind(this);
  }

  handleInputChange(key, value) {
    this.setState({ email: value });
  }

  handleFormSubmit() {
    const { email } = this.state;
    const { history, forgot, handleEmailChange, setIsWhiteLabel } = this.props;
    const data = {
      email,
    };
    this.setState({ isLoading: true });
    forgot(data)
      .then(response => {
        this.setState({ isLoading: false });
        const mainEmail = _.get(response, 'data.email', '');
        const transformedEmail = this.transformEmail(mainEmail);
        handleEmailChange(transformedEmail);
      })
      .catch(error => {
        this.setState({ isLoading: false });
        const errorType = _.get(error, 'response.data.type', '');
        const { isWmsApp } = window.__env;
        if (errorType === 'email') {
          this.setState({ errors: { email: _.get(error, 'response.data.message', null) } });
        } else if (errorType === 'wms' && !isWmsApp) {
          setIsWhiteLabel(true);
        } else {
          history.push('/');
        }
      });
  }

  transformEmail(mainEmail) {
    const atIndex = mainEmail.indexOf('@');
    const hiddenLength = atIndex - 2;
    return mainEmail.substr(0, 1) + _.repeat('*', hiddenLength) + mainEmail.substr(atIndex - 1);
  }

  render() {
    const { translations } = this.props;
    const { isLoading, errors } = this.state;
    const email = '';
    const forgotTranslations = _.get(translations, 'forgotPassword', []);
    const requiredLabel = _.get(translations, 'required', '(required)');
    const emailLabel = `${_.get(translations, 'email', 'Email')} ${requiredLabel}`;
    const submitMessage = _.get(forgotTranslations, 'action', 'Reset password');
    const emailError = _.get(errors, 'email', null) ? _.get(forgotTranslations, errors.email, true) : false;
    return (
      <form
        className="login-form__container"
        onSubmit={event => {
          event.preventDefault();
          event.stopPropagation();
          this.handleFormSubmit();
        }}
      >
        <div className="login-form__row">
          <InputField
            label={emailLabel}
            name="email"
            value={email}
            type="email"
            handleInputChange={this.handleInputChange}
            error={emailError}
          />
        </div>

        <div className="login-form__row">
          <button id="open-forgot" className="login-form__submit-button" type="submit">
            {!isLoading ? submitMessage : <div className="open-login__loader" />}
          </button>
        </div>
      </form>
    );
  }
}

export default withRouter(ForgotPasswordForm);
