import React from 'react';
import PropTypes from 'prop-types';
import Sidebar from '../rightSidebar/rightSidebar';
import { Scrollbars } from 'react-custom-scrollbars';
import OfficeBooked from './officeBooked';
import OfficeCancelation from './officeCancelation';
import ModalDialog from './../modalDialog/modalDialog';
import CancelBooking from './../modalDialog/modals/cancelBooking';
import OfficeGallery from './officeGallery';
import OfficeContactInfo from './officeContactInfo';
import _ from 'lodash';

import './css/index.scss';
import moment from "moment/moment";
import circleLoader from './../../assets/images/circle-loader-64px.gif';
import { StripeProvider, Elements } from 'react-stripe-elements';
import RepayForm from './RepayForm';
import MeetingAttendeesBox from '../meetingAttendees';
import getLang from "../../utils/lang";

const lang = getLang() !== null ? getLang() : 'en';

class Booking extends React.Component {
    context;

    static contextTypes = {
        router: PropTypes.object.isRequired
    };

    constructor(props) {
        super(props);

        this.state = {
            officeDetailsFetched: false,
            showBackButton: true,
            cancelBookingSpinner: false,
            cancelBookingError: '',
            showCancelBookingButton: true,
            showCancelBookingModal: false,
            eventBookablePopupShow: false,
        };

        this.onEventBookableClose = this.onEventBookableClose.bind(this);
    }

    componentDidMount() {
        const { beforeFetchBookingDetails, fetchBookingDetails, history } = this.props;
        beforeFetchBookingDetails();
        fetchBookingDetails(this.props.match.params.id);
        this.historyListener = history.listen((location) => {
            const bookingId = location.pathname.split('/')[2];
            if (bookingId) {
                beforeFetchBookingDetails();
                fetchBookingDetails(bookingId);
            }
        });
    }

    componentWillUnmount() {
        this.props.fetchBookingSummary();
        this.historyListener();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {


        if (this.props.details.toJS().status !== undefined && this.props.details.toJS().status !== 'canceled' && nextProps.match.params.confirmed !== undefined && nextProps.match.params.confirmed === 'confirmed') {
            this.setState({
                showBackButton: false
            });
        }

        if (nextProps.details.toJS().office !== undefined && nextProps.details.toJS().office.id !== undefined && this.state.officeDetailsFetched === false) {
            this.props.beforeFetchOfficeDetails();
            this.props.fetchOfficeDetails(nextProps.details.toJS().office.id);
            const { details } = nextProps;
            const isEvent = _.get(details.toJS(), 'eventRoom', false);
            const eventStatus = _.get(details.toJS(), 'status', false);
            const eventBookablePopupShow = !!isEvent && eventStatus === 'pending' && !!parseInt(localStorage.getItem('showPopup'));
            this.setState({ officeDetailsFetched: true, eventBookablePopupShow });

        }

        if (this.state.cancelBookingSpinner === true) {
            this.setState({ cancelBookingSpinner: false }, () => {
                this.props.beforeFetchBookingDetails();
                this.props.fetchBookingDetails(this.props.match.params.id);
            });
        }

        if (nextProps.cancelBookingData.get('success') !== undefined) {
            if (nextProps.cancelBookingData.get('success') === true) {
                this.setState({ showCancelBookingButton: false });
            } else {
                this.setState({ cancelBookingError: nextProps.cancelBookingData.get('message') });
            }
        }
    }

    cancelBooking = () => {
        this.setState({
            showCancelBookingButton: false,
            cancelBookingSpinner: true,
            showCancelBookingModal: false,
        });
        this.props.cancelBooking(this.props.details.get('id'));

    };

    onModalOpen = () => {
        this.setState({
            showCancelBookingModal: true
        });
    }

    onModalClose = () => {
        this.setState({
            showCancelBookingModal: false
        });
    }

    onEventBookableClose() {
        this.setState({ eventBookablePopupShow: false});
        localStorage.removeItem('showPopup');
    }


    render() {
        let getSidebar = null;
        let isMeetingRoom = false;
        const { details, officeDetails, translations } = this.props;
        const { eventBookablePopupShow } = this.state;
        const isBookableEvent = _.get(officeDetails.toJS(), 'bookableEvent', false);
        const isInternalEnv = !!_.get(this.props.details.toJS(), 'internalEnv', false);
        let roomName = null;
        if (this.props.details.toJS().desk !== undefined) {
            roomName = this.props.details.toJS().desk.name;
        }
        if (this.props.details.toJS().privateOffice !== undefined) {
            roomName = this.props.details.toJS().privateOffice.name;
        }
        if (this.props.details.toJS().eventRoom !== undefined) {
            roomName = this.props.details.toJS().eventRoom.name;
        }
        if (this.props.details.toJS().meetingRoom !== undefined) {
            isMeetingRoom = true;
            roomName = this.props.details.toJS().meetingRoom.name;
        }

        let repayForm = <div className="payment-options-box-light">
            <StripeProvider apiKey={window.__env.stripeApiKey} >
                <Elements locale={lang}>
                    <RepayForm 
                        paymentIntentSecretId = {details.get('paymentIntentId')}
                        availablePaymentMethods = {details.get('paymentTypesAvailable')}
                        errorMessage = {details.get('error_message')}
                        trans={_.get(translations, 'repayForm', [])}
                    />
                </Elements>
            </StripeProvider>
        </div>;
        if (details.size > 0 && officeDetails.size > 0) {
            let price = details.toJS().price;

            let totalInfo = (
                <table style={{ width: "100%" }}>
                    <tbody>
                        <tr className="not-booked-office-space-item not-booked-office-space-item--highlight">
                            <td style={{ textAlign: "left", maxWidth: "60%" }}
                                className="not-booked-office-space-item-label">{_.get(this.props.translations, 'bookingPage.total', '')}
                            </td>
                            <td style={{ textAlign: "right", minWidth: "40%" }}
                                className="not-booked-office-space-item-value">{price.totalPriceVatIncluded.toFixed(2).toString().replace(".", window.__env.decimalSeparator)}€
                        </td>
                        </tr>
                    </tbody>
                </table>
            );

            let amenitiesAndServices = price.servicesPrice > 0 ? (
                <table style={{ width: "100%" }}>
                    <tbody>
                        <tr className="not-booked-office-space-item">
                            <td style={{ textAlign: "left", maxWidth: "70%" }}
                                className="not-booked-office-space-item-label">{this.props.translations.bookingPage.amenitiesAndServices}
                            </td>
                            <td style={{ textAlign: "right", minWidth: "30%" }}
                                className="not-booked-office-space-item-value">{price.servicesPrice.toFixed(2).toString().replace(".", window.__env.decimalSeparator)}€
                        </td>
                        </tr>
                    </tbody>
                </table>
            ) : null;

            let equipments = price.roomEquipmentsPrice > 0 ? (
                <table style={{ width: "100%" }}>
                    <tbody>
                        <tr className="not-booked-office-space-item">
                            <td style={{ textAlign: "left", maxWidth: "70%" }}
                                className="not-booked-office-space-item-label">{this.props.translations.bookingPage.equipments}
                            </td>
                            <td style={{ textAlign: "right", minWidth: "30%" }}
                                className="not-booked-office-space-item-value">{price.roomEquipmentsPrice.toFixed(2).toString().replace(".", window.__env.decimalSeparator)}€
                        </td>
                        </tr>
                    </tbody>
                </table>
            ) : null;

            let companyInfo = price.paidAmountCompany > 0 ? (
                <td>
                    <div className="not-booked-office-space-item">
                        <div className="not-booked-office-space-item-label">{details.toJS().companyName}</div>
                        <div
                            className="not-booked-office-space-item-value">-{price.paidAmountCompany.toFixed(2).toString().replace(".", window.__env.decimalSeparator)}€
                            </div>
                    </div>
                </td>
            ) : <td></td>;


            let startDate = moment.unix(details.toJS().fromDate).set('hour', details.getIn(['hours', 'from', 'hour'])).set('minute', details.getIn(['hours', 'from', 'minute']));
            let nowDate = moment();
            let diffInMinutes = Math.floor(startDate.diff(nowDate, 'minutes'));

            let cancelButtonElement = '';
            if (diffInMinutes <= 0) {
                cancelButtonElement =
                    <a className="myBookings-link secondary-button disabled" disabled>{this.props.translations.bookingPage.cancelBooking}</a>;
            } else {
                cancelButtonElement =
                    <a className="myBookings-link secondary-button" onClick={this.onModalOpen}>{this.props.translations.bookingPage.cancelBooking}</a>;
            }

            const { translations } = this.props;
            let cancelBookingMessage = null;
            let cancelDateAreInFuture = (details.toJS().freeCancelationDate > moment().unix()) ? true : false;
            if (details.toJS().status !== 'canceled' && !isInternalEnv) {
                if (diffInMinutes > 0 && cancelDateAreInFuture && details.toJS().cancelationRefundPercentage === 100) {
                    cancelBookingMessage = translations.formatString(_.get(translations, 'bookingPage.noPenaltiesUntil', 'This booking can be canceled without penalties until {0}. Please check your cancellation policy.'), moment.unix(details.toJS().freeCancelationDate).utc().format("D MMMM YYYY, h:mm a"));
                } else if (diffInMinutes > 0 && cancelDateAreInFuture && details.toJS().cancelationRefundPercentage < 100) {
                    cancelBookingMessage = translations.formatString(_.get(translations, 'bookingPage.somePenaltiesUntil', 'This booking can be canceled with ({0}%) penalty until {1}. Please check your cancellation policy.'), (100 - details.toJS().cancelationRefundPercentage), moment.unix(details.toJS().freeCancelationDate).utc().format("D MMMM YYYY, h:mm a"));
                } else if ((details.toJS().cancelationRefundPercentage === 0 || !cancelDateAreInFuture) && diffInMinutes > 0) {
                    cancelBookingMessage = translations.formatString(_.get(translations, 'bookingPage.percentagePenalties', 'Penalties worth {0} ({1}%) will be applied. Please check your cancellation policy.'), (details.get('penalty_value') ? details.get('penalty_value', 0) : 0) + '€', details.toJS().cancellationFeePercentage);
                }
            }
            let cancelButton = (details.toJS().status !== 'canceled') ? (
                <div className="cancelActionContainer">
                    {(this.state.cancelBookingSpinner) ?
                        <div><img alt="" style={{ height: 58, width: 58 }} src={circleLoader} /></div> : ''}
                    {(this.state.showCancelBookingButton) ?
                        cancelButtonElement
                        : ''}
                    {(this.state.cancelBookingError !== '') ?
                        <span className="cancelBookingError">{this.state.cancelBookingError}</span> : ''}
                </div>
            ) : null;

            let voucherInfo = <td></td>;
            if (price.paidAmountVoucher !== null && price.paidAmountVoucher !== 0) {
                voucherInfo = (
                    <td>
                        <div className="not-booked-office-space-item">
                            <div className="not-booked-office-space-item-label">{_.get(translations, 'booking.payment.voucher', '')}</div>
                            <div
                                className="not-booked-office-space-item-value">-{price.paidAmountVoucher.toFixed(2).toString().replace(".", window.__env.decimalSeparator)}€
                            </div>
                        </div>
                    </td>
                );
            }

            const subtotalInfo = (
                <td>
                    <div className="not-booked-office-space-item not-booked-office-space-item--highlight">
                        <div className="not-booked-office-space-item-label">{_.get(translations, 'booking.payment.subtotal', '')}</div>
                        <div
                            className="not-booked-office-space-item-value">{(price.totalPriceVatIncluded+price.paidAmountVoucher).toFixed(2).toString().replace(".", window.__env.decimalSeparator)}€
                            </div>
                    </div>
                </td>
            );

            getSidebar = <Sidebar>
            <div className="not-booked-location">
                <table style={{ width: "100%" }}>
                    <tbody>
                        <tr>
                            <td>
                                <table style={{ width: "100%", margin: "0px" }}>
                                    <tbody>
                                        {officeDetails.toJS().bookableEvent && this.props.details.toJS().eventRoom !== undefined && (this.props.details.toJS().status === 'active' || this.props.details.toJS().status === 'canceled') ? null : [
                                            <tr key={`pbd1`}>
                                                <td style={{ paddingTop: "30px", paddingBottom: "10px" }}
                                                    className="not-booked-title">{_.get(translations, 'booking.payment.bookingDetailsLabel', '')}
                                        </td>
                                            </tr>,
                                            <tr key={`pbd2`} className="not-booked-office-space-item">
                                                <td style={{ textAlign: "left", maxWidth: "60%" }}
                                                    className="not-booked-office-space-item-label">{_.capitalize(roomName)}</td>
                                                <td style={{ textAlign: "right", minWidth: "40%" }}
                                                    className="not-booked-office-space-item-value">{price.productPrice.toFixed(2).toString().replace(".", window.__env.decimalSeparator)}€
                                        </td>
                                            </tr>,
                                            <tr key={`pbd3`}>
                                                <td>{amenitiesAndServices}</td>
                                            </tr>,
                                            <tr key={`pbd4`}>
                                                <td>{equipments}</td>
                                            </tr>,
                                            <tr key={`pbd5`}>{companyInfo}</tr>,
                                            <tr key={`pbd6`} className="not-booked-office-space-item">
                                                <td style={{ textAlign: "left", maxWidth: "60%" }}
                                                    className="not-booked-office-space-item-label">{_.get(translations, 'booking.payment.vat', '')}
                                                </td>
                                                <td style={{ textAlign: "right", minWidth: "40%" }}
                                                    className="not-booked-office-space-item-value">{price.vat.toFixed(2).toString().replace(".", window.__env.decimalSeparator)}€
                                                </td>
                                            </tr>,
                                            <tr key={`pbd7`}>{subtotalInfo}</tr>,
                                            <tr key={`pbd8`}>{voucherInfo}</tr>,
                                            <tr key={`pbd9`}>
                                                <td>{totalInfo}</td>
                                            </tr>
                                        ]}
                                        <tr>
                                            <td>
                                                { !details.toJS().isContract  &&  cancelButton}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </tbody>
                </table>
                { !details.toJS().isContract &&
                <section className="myBookings booked-location">
                    {(cancelBookingMessage !== null && !(officeDetails.toJS().bookableEvent && this.props.details.toJS().eventRoom !== undefined)) ? <p><span>*</span>{cancelBookingMessage}</p> : null}
                </section>
                }
            </div>
            </Sidebar>;

            if (officeDetails.toJS().bookableEvent && details.toJS().eventRoom !== undefined && details.toJS().status === 'pending') {
                getSidebar = null;
            }


            let images = officeDetails.toJS().images;
            let map = {
                defaultZoom: 10,
                zoom: 15,
                center: { lat: officeDetails.toJS().location.latitude, lng: officeDetails.toJS().location.longitude },
                markers: [
                    {
                        id: 1,
                        location: {
                            latitude: officeDetails.toJS().location.latitude,
                            longitude: officeDetails.toJS().location.longitude
                        }
                    }
                ]
            };
            let contactInfo = {
                map: map,
                address: officeDetails.toJS().address,
                name: officeDetails.toJS().contactPerson.name,
                phone: officeDetails.toJS().phoneNumber.countryCode + ' ' + officeDetails.toJS().phoneNumber.number,
                emailPerson: officeDetails.toJS().contactPerson.email,
                emailOffice: officeDetails.toJS().email, // email
                additional: details.toJS().additionalInfo
            };

            let backButton = this.state.showBackButton === true ? <a onClick={this.props.history.goBack}
                className="myBooking-navigation">{this.props.translations.bookingPage.back}</a> : <a style={{ visibility: 'hidden' }} onClick={this.props.history.goBack}
                    className="myBooking-navigation">{this.props.translations.bookingPage.back}</a>;

            let statusLabel = <span className="success-message"> {this.props.translations.bookingPage.isConfirmed}</span>;

            switch (this.props.details.toJS().status) {
                case 'canceled':
                    statusLabel = <span className="error-message"> {this.props.translations.bookingPage.isCanceled}</span>;
                    break;
                case 'active':
                    statusLabel = <span className="active-message"> {this.props.translations.bookingPage.isActive}</span>;
                    break;
                case 'closed':
                    statusLabel = <span className="closed-message"> {this.props.translations.bookingPage.isClosed}</span>;
                    break;
                case 'pending':
                    statusLabel = <span className="pending-message"> {this.props.translations.bookingPage.isPending}</span>;
                    break;
                default:
                    break;
            }

            if (this.props.details.toJS().status === 'active' && this.props.match.params.confirmed !== undefined && this.props.match.params.confirmed === 'confirmed') {
                statusLabel = <span className="success-message"> {this.props.translations.bookingPage.isConfirmed}</span>;
            }
            const cantWait = _.get(translations, 'location.cantWait', "We can't wait to meet you!");

            return (
                <div className="location-container">
                    <section className="location-content">
                        <Scrollbars className="styleScroll">
                            <div className="location-holder">

                                {repayForm}
                                {backButton}
                                <div className="location-booked-title items-style1">{this.props.translations.bookingPage.yourBookingAt} {details.toJS().office.name}
                                    {statusLabel}{this.props.details.toJS().status === 'active' ?  '. ' + cantWait : '.'}</div>
                                {!details.toJS().isContract && isMeetingRoom &&  <MeetingAttendeesBox capacity={this.props.details.toJS().meetingRoom.seats} bookingId={this.props.details.get('id')} diffInMinutes={diffInMinutes} isInternalEnv bookingStatus={this.props.details.toJS().status}/> }
                                <OfficeBooked details={this.props.details} trans={this.props.translations} />
                                <OfficeGallery images={images} trans={this.props.translations} />
                                <OfficeContactInfo hasPaddingBottom={isBookableEvent} contactInfo={contactInfo} title={this.props.translations.bookingPage.locationName} trans={this.props.translations} />
                                { !details.toJS().isContract && (!officeDetails.toJS().bookableEvent || (officeDetails.toJS().bookableEvent && this.props.details.toJS().status !== 'pending')) ? <OfficeCancelation office={this.props.details.toJS().office} bookableEvent={isBookableEvent} policies={this.props.officeDetails.toJS().policies} trans={this.props.translations} /> : null}
                            </div>
                        </Scrollbars>
                    </section>
                    {getSidebar}
                    <ModalDialog
                        modalOpened={this.state.showCancelBookingModal}
                        className="--cancel-booking-modal"
                        onModalClose={this.onModalClose}
                        label="cancelBooking">
                        <CancelBooking
                            onAction={this.cancelBooking}
                            onCancelAction={this.onModalClose}
                            trans={this.props.translations}
                            booking={this.props.details}
                        />
                    </ModalDialog>
                    <ModalDialog
                        label="Event Room Booking Request"
                        title={this.props.translations.modals.eventRoomBookingRequest.title}
                        type="event-room-book-request"
                        modalOpened={eventBookablePopupShow}
                        onModalClose={this.onEventBookableClose}
                        onProceed={this.onEventBookableClose}
                        showCancel={false}
                        proceedText={this.props.translations.modals.eventRoomBookingRequest.ok}
                        className="business-dialog-modal"
                        trans={this.props.trans}
                    >
                        <div>{this.props.translations.modals.eventRoomBookingRequest.info}</div>
                    </ModalDialog>
                </div>
            );
        }
        else {
            return <div className="Loader"></div>;
        }
    }
}

export default Booking;
