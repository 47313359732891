import React from 'react';
import _ from 'lodash';

const OfficeAmenities = props => {
  const { amenities } = props;
  const { availableAmenities } = props;
  const renderAmenities = [];

  for (let i = 0; i < amenities.length; i++) {
    if (availableAmenities.indexOf(amenities[i].id) >= 0) {
      renderAmenities.push({
        id: amenities[i].id,
        name: amenities[i].name,
        isAvailable: _.get(amenities[i], 'isAvailable', true),
      });
    }
  }

  const amenitiesList = renderAmenities.map(availableAmenity => (
    <div key={availableAmenity.id} className= {availableAmenity.isAvailable ? "amenity-available" : "amenity-unavailable"}>
      {availableAmenity.name}
    </div>
  ));

  if (amenitiesList.length > 0) {
    return (
      <div className="office-amenities">
        <div className="office-amenities-title">{props.trans.location.amenities}</div>
        <div className="office-amenities-list">{amenitiesList}</div>
      </div>
    );
  }
  return <div />;
};

export default OfficeAmenities;
