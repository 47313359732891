import { connect } from 'react-redux';
import './index.scss';

import _ from 'lodash';
import toJS from './utils/to-js';
import addLoader from './utils/add-loader';

import { mediaArticleSelector, mediaArticleIsLoadingSelector } from '../../selectors/dashboard/media-article';
import { fetchMediaArticle, setMediaArticle } from '../../actions/dashboard/media-article';
import { fetchSimilarMediaItems, fetchAdditionalMediaItems } from '../../actions/dashboard/similar-media';
import { fetchMediaCompanyProfile } from '../../actions/dashboard/media-company-profile';
import { similarMediaSelector, additionalMediaSelector, verticalNameSelector } from '../../selectors/dashboard/similar-media';
import { translations } from '../../selectors/translations';
import { toastMessageSelector } from '../../selectors/dashboard/toasts';

import Dashboard from './Dashboard';
import Media from './Media';

const mapStateToProps = state => ({
  mediaArticle: mediaArticleSelector(state),
  similarMediaItems: similarMediaSelector(state),
  additionalMediaItems: additionalMediaSelector(state),
  selectedVertical: verticalNameSelector(state),
  translations: _.get(translations(state), 'dashboard', {}),
  isAuthenticated: state.auth.get('isAuthenticated'),
});

const mapDispatchToProps = dispatch => ({
  fetchMediaArticle: id => dispatch(fetchMediaArticle(id)),
  fetchSimilarMediaItems: (article, typeId) => dispatch(fetchSimilarMediaItems(article, typeId)),
  fetchAdditionalMediaItems: (article, typeId) => dispatch(fetchAdditionalMediaItems(article, typeId)),
  fetchMediaCompanyProfile: id => dispatch(fetchMediaCompanyProfile(id)),
  setMediaArticle: mediaArticle => dispatch(setMediaArticle(mediaArticle)),
});

const MediaWithConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(toJS(Media));
const DashboardWithConnect = connect(state => ({
  translations: _.get(translations(state), 'dashboard', {}),
  isAuthenticated: state.auth.get('isAuthenticated'),
  toastMessage: toastMessageSelector(state),
}))(Dashboard);

export { DashboardWithConnect as Dashboard, MediaWithConnect as Media };
