/* eslint-disable no-underscore-dangle */
import axios from 'axios';
import _ from 'lodash';

let baseApiUrl = '';

if (window.__env && window.__env.apiUrl) {
  baseApiUrl = window.__env.apiUrl;
}

const baseURL = `${baseApiUrl}/public/community`;

const instance = axios.create({
  responseType: 'json',
  baseURL,
  headers: {
    'Content-Type': 'application/json',
    'access-key': localStorage.getItem('access-key'),
  },
});
instance.interceptors.request.use(
  config => {
    const token = localStorage.getItem('access-key');
    if (token) {
      config.headers['access-key'] = token;
      config.baseURL = `${baseApiUrl}/community`;
    }
    config.headers['Content-Type'] = 'application/json';
    return config;
  },
  error => {
    Promise.reject(error);
  }
);
// Add a response interceptor
instance.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    const errorCode = _.get(error, 'response.data.errorCode', null);
    if (errorCode) {
      window.location.href = `/communities#error=${errorCode}`;
      return false;
    }
    return Promise.reject(error);
  }
);

export default instance;
