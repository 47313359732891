import classnames from 'classnames';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import DatePicker from '../date-picker';
import ProfileInputField from '../profile-input-field/ProfileInputField';

const ProfileTextAreaField = props => {
  const { label, name, value, placeholder, changeable, onInputChange, path, error } = props;
  return (
    <div className="profile-form__field">
      <div className="profile-form__label">{label}</div>
      <textarea
        className={classnames(
          'profile-form__input profile-form__textarea',
          { 'profile-form__input-value': value },
          { 'profile-form__label-error': error }
        )}
        name={name}
        value={value || ''}
        placeholder={placeholder}
        onChange={changeable ? event => onInputChange(event, path) : null}
      />
    </div>
  );
};

const ProfileDatePickerField = props => {
  const { label, value, onDateChange, path, error } = props;
  return (
    <div className="profile-form__field">
      <div className={classnames('profile-form__label', { 'profile-form__label-error': error })}>{label}</div>
      <DatePicker date={value} onDateChange={onDateChange} path={path} error={error} />
    </div>
  );
};

class PositionSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showEndDate: null,
    };
    this.onCheckboxChange = this.onCheckboxChange.bind(this);
  }

  onCheckboxChange() {
    const { position } = this.props;
    const showEndDate = this.state.showEndDate !== null ? this.state.showEndDate : position.endYear && position.endMonth;
    this.setState({ showEndDate: !showEndDate });
  }

  render() {
    const { position, onInputChange, onDateChange, errors, onDelete, translations } = this.props;

    const { positionId, company, title, startMonth, startYear, endMonth, endYear, summary, singlePosition } = position;

    const startDate = startMonth && startYear ? `${startYear}/${startMonth}/01` : null;
    const endDate = endMonth && endYear ? `${endYear}/${endMonth}/01` : null;

    const relativePath = `positions.${positionId}`;
    const namePath = `${relativePath}.company`;
    const titlePath = `${relativePath}.title`;
    const startPath = `${relativePath}.start`;
    const endPath = `${relativePath}.end`;
    const descriptionPath = `${relativePath}.summary`;
    const showEndDate = this.state.showEndDate !== null ? this.state.showEndDate : endYear && endMonth;
    const hmapErrors = {};
    if (errors) {
      for (const key in errors) {
        hmapErrors[key] = errors[key];
      }
    }

    return (
      <>
        {!singlePosition ? (
          <div
            className="profile-form__delete-section"
            onClick={() => {
              onDelete(positionId);
            }}
          >
            {_.get(translations, 'removeExperience', '')}
          </div>
        ) : null}
        <div className="profile-form__row profile-form__row-margin-top">
          <div className="profile-form__columns">
            <ProfileInputField
              label={_.get(translations, 'whereDoYouWork', '')}
              name="company"
              placeholder={_.get(translations, 'placeholderCompany', '')}
              value={company || null}
              changeable
              path={namePath}
              onInputChange={onInputChange}
              error={!!hmapErrors[namePath]}
            />

            <ProfileInputField
              label={_.get(translations, 'currentPosition', '')}
              name="title"
              placeholder={_.get(translations, 'placeholderPosition', '')}
              value={title || null}
              changeable
              onInputChange={onInputChange}
              path={titlePath}
              error={!!hmapErrors[titlePath]}
            />
          </div>
        </div>
        <div
          className="profile-form__row profile-form__row-margin-top"
          onClick={() => {
            this.onCheckboxChange();
            onDateChange(null, endPath);
          }}
        >
          <span className={classnames('profile-form__checkbox', { 'profile-form__input-value': !showEndDate })}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className={classnames('icon', { 'icon-checked': !showEndDate }, { 'icon-not-checked': showEndDate })}
            >
              <polyline points="13 3 7 12 3 8" />
            </svg>
          </span>
          <div
            className={classnames('profile-form__label profile-form__label-checkbox', {
              'profile-form__label-checkbox-checked': !showEndDate,
            })}
          >
            {_.get(translations, 'currentJob', '')}
          </div>
        </div>
        <div className="profile-form__row">
          <div className="profile-form__columns">
            <ProfileDatePickerField
              label={_.get(translations, 'startDate', '')}
              name="startDate"
              value={startDate || null}
              changeable
              onDateChange={onDateChange}
              path={startPath}
              error={(!!hmapErrors[`${startPath}Month`] && !!hmapErrors[`${startPath}Year`]) || !!hmapErrors[`${positionId}.startDate`]}
            />
            {showEndDate ? (
              <ProfileDatePickerField
                label={_.get(translations, 'endDate', '')}
                name="endDate"
                value={endDate}
                changeable
                onDateChange={onDateChange}
                path={endPath}
                error={(!!hmapErrors[`${endPath}Month`] && !!hmapErrors[`${endPath}Year`]) || !!hmapErrors[`${positionId}.endDate`]}
              />
            ) : null}
          </div>
        </div>
        <div className="profile-form__row">
          <ProfileTextAreaField
            label={_.get(translations, 'jobDescription', '')}
            name="summary"
            placeholder={_.get(translations, 'placeholderJobDescription', '')}
            value={summary || null}
            changeable
            onInputChange={onInputChange}
            path={descriptionPath}
            error={!!hmapErrors[descriptionPath]}
          />
        </div>
      </>
    );
  }
}
PositionSection.propTypes = {
  position: PropTypes.shape({
    id: PropTypes.number,
    positionId: PropTypes.number,
    company: PropTypes.string,
    title: PropTypes.string,
    startDate: PropTypes.instanceOf(Date),
    endDate: PropTypes.instanceOf(Date),
    summary: PropTypes.string,
  }),
  onInputChange: PropTypes.func.isRequired,
  onDateChange: PropTypes.func.isRequired,
};
PositionSection.propTypes = {
  position: PropTypes.shape({
    id: null,
    positionId: null,
    company: '',
    title: '',
    startDate: null,
    endDate: null,
    summary: '',
  }),
};

export default PositionSection;
