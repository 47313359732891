import { connect } from 'react-redux';

import toJS from '../../utils/to-js';
import {
  isLoadingSelector,
  isUploadingSelector,
  uploadedPercentSelector,
  formErrorsSelector,
} from '../../../../selectors/community/create-post';
import { communitySelector } from '../../../../selectors/community/community-page';
import { savePost, uploadAttachment, clearErrors } from '../../../../actions/community/create-post';

import CreatePostForm from './CreatePostForm';
import { showCommunityPageToast, showCommunitiesPageToast } from '../../../../actions/community/community-toasts';

const mapDispatchToProps = dispatch => ({
  savePost: data => dispatch(savePost(data)),
  uploadAttachment: data => dispatch(uploadAttachment(data)),
  clearErrors: () => dispatch(clearErrors()),
  showCommunityPageToast: data => dispatch(showCommunityPageToast(data)),
  showCommunitiesPageToast: data => dispatch(showCommunitiesPageToast(data)),
});
const mapStateToProps = state => ({
  community: communitySelector(state),
  isLoading: isLoadingSelector(state),
  isUploading: isUploadingSelector(state),
  uploadedPercent: uploadedPercentSelector(state),
  errors: formErrorsSelector(state),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(toJS(CreatePostForm));
