import api from '../components/myMeetings/utils/api';

export const FETCH_MY_BOOKINGS = 'FETCH_MY_BOOKINGS';
export const FETCH_AVAILABLE_OFFICES = 'FETCH_AVAILABLE_OFFICES';
export const FETCH_COMPANIES = 'FETCH_COMPANIES';
export const FETCH_NEAREST_OFFICES = 'FETCH_NEAREST_OFFICES';
export const FETCH_RECENTLY_ADDED = 'FETCH_RECENTLY_ADDED';
export const FETCH_FEATURED_OFFICES = 'FETCH_FEATURED_OFFICES';
export const SET_BOOKINGS = 'SET_BOOKINGS';
export const RESET_BOOKING_MAP = 'RESET_BOOKING_MAP';
/**
 * set is loading results -  for bookings loader
 * @param isLoading
 * @returns {{isLoading: *, type: string}}
 */
export const setBookingIsLoading = isLoading => ({
  type: SET_BOOKINGS,
  isLoading,
});
/**
 * set bookings to redux - async method
 * @param data
 * @returns {{data: *, type: string}}
 */
export const setBookings = data => ({
  type: FETCH_MY_BOOKINGS,
  data,
});

export const resetBookingsMap = data => ({
  type: RESET_BOOKING_MAP,
  data,
});


/**
 * Fetch bookings to hero -> promise
 * @returns {function(*=): Promise<unknown>}
 */
export const fetchMyBookings = () => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(setBookingIsLoading(true));
    api
      .get(`/hero/bookings`)
      .then(({ data }) => {
        dispatch(setBookings(data));
        resolve();
      })
      .catch(() => {
        reject();
      })
      .finally(() => {
        dispatch(setBookingIsLoading(false));
      });
  });

export const fetchAvailableOffices = (filters, resetMarkers) => {
  const apiPrefix = localStorage.getItem('access-key') ? '' : '/public';
  return {
    type: FETCH_AVAILABLE_OFFICES,
    url: `${window.__env.apiUrl + apiPrefix}/offices/search`,
    filters,
    resetMarkers,
  };
};

export const fetchCompanies = (filters, resetMarkers) => {
  const apiPrefix = localStorage.getItem('access-key') ? '' : '/public';
  return {
    type: FETCH_COMPANIES,
    url: `${window.__env.apiUrl + apiPrefix}/companies/search`,
    filters,
    resetMarkers,
  };
};

export const fetchNearestOffices = filters => {
  const apiPrefix = localStorage.getItem('access-key') ? '' : '/public';
  return {
    type: FETCH_NEAREST_OFFICES,
    url: `${window.__env.apiUrl + apiPrefix}/offices/nearest`,
    filters,
  };
};

export const fetchRecentlyAdded = () => {
  const apiPrefix = localStorage.getItem('access-key') ? '' : '/public';
  return {
    type: FETCH_RECENTLY_ADDED,
    url: `${window.__env.apiUrl + apiPrefix}/offices/recentlyAdded`,
  };
};

export const fetchFeaturedOffices = () => {
  const apiPrefix = localStorage.getItem('access-key') ? '' : '/public';
  return {
    type: FETCH_FEATURED_OFFICES,
    url: `${window.__env.apiUrl + apiPrefix}/offices/featured`,
  };
};
