import React from 'react';
import _ from "lodash";

import './css/index.scss';

class NotificationsGeneralSettings extends React.Component {

    constructor(props) {
        super(props);
        this.buildNotificationInput = this.buildNotificationInput.bind(this);
    }

    buildNotificationInput(item, index) {
        return <label key={index}>
            <input type='checkbox'
                   checked={_.get(item, 'value')}
                   onChange={this.props.handleEmailCheckboxClick.bind(null, item)}
            />
            <span className={`notification-description ${_.get(item, 'value', false) ? 'active' : ''}`}>
                {_.get(this.props.translations, `generalSettings.permissions.${_.get(item, 'name', '')}`, null)}
            </span>
        </label>
    }

    render() {
        const { permissions }  = this.props;
        return (
            <div className="notifications-checkboxes-group">
                {permissions.map(this.buildNotificationInput)}
            </div>
        );
    }
}

export default NotificationsGeneralSettings;
