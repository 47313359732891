import { connect } from 'react-redux';
import _ from 'lodash';
import toJS from '../../utils/to-js';
import {
  adminsSelector,
  activeMembersSelector,
  blockedMembersSelector,
  communityInvitationsSelector,
  isLoadingSelector,
} from '../../../../selectors/community/all-members';

import ManageCommunityModal from './ManageCommunityModal';
import {
  fetchCommunityMembers,
  fetchCommunityInvitations,
  makeHeroAdmin,
  removeHeroFromCommunity,
  blockHeroFromCommunity,
  unblockMember,
  resendInvitation,
  cancelInvitation,
} from '../../../../actions/community/all-members';
import { communitySelector } from '../../../../selectors/community/community-page';

const mapDispatchToProps = dispatch => ({
  fetchCommunityMembers: () => dispatch(fetchCommunityMembers()),
  fetchCommunityInvitations: () => dispatch(fetchCommunityInvitations()),
  makeHeroAdmin: heroId => dispatch(makeHeroAdmin(heroId)),
  removeHeroFromCommunity: heroId => dispatch(removeHeroFromCommunity(heroId)),
  blockHeroFromCommunity: heroId => dispatch(blockHeroFromCommunity(heroId)),
  unblockMember: heroId => dispatch(unblockMember(heroId)),
  resendInvitation: heroId => dispatch(resendInvitation(heroId)),
  cancelInvitation: heroId => dispatch(cancelInvitation(heroId)),
});

const mapStateToProps = state => ({
  admins: adminsSelector(state),
  activeMembers: activeMembersSelector(state),
  blockedMembers: blockedMembersSelector(state),
  communityInvitations: communityInvitationsSelector(state),
  heroProfile: state.global.get('heroProfile'),
  communityAudienceType: _.get(communitySelector(state), 'audienceType', null),
  isLoading: isLoadingSelector(state),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(toJS(ManageCommunityModal));
