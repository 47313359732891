import api from '../../components/dashboard/utils/api';
import { verticalSelector } from '../../selectors/vertical-selector';

/*
 * Constants
 */
export const SET_MOST_ACTIVE_HEROES = 'SET_MOST_ACTIVE_HEROES';
export const SET_MOST_ACTIVE_HEROES_IS_LOADING = 'SET_MOST_ACTIVE_HEROES_IS_LOADING';
/*
 * Sync Actions
 */

export const setMostActiveHeroes = heroes => ({
  type: SET_MOST_ACTIVE_HEROES,
  heroes,
});

export const setMostActiveHeroesIsLoading = isLoading => ({
  type: SET_MOST_ACTIVE_HEROES_IS_LOADING,
  isLoading,
});

/*
 * Async Actions
 */
export const fetchMostActiveHeroes = () => (dispatch, getState) => {
  const selectedVertical = verticalSelector(getState());
  const url = selectedVertical;
  dispatch(setMostActiveHeroesIsLoading(true));
  api
    .get('/sidebar/active-heroes')
    .then(({ data = {} }) => {
      dispatch(setMostActiveHeroes(data.heroes));
    })
    .catch(() => {})
    .finally(() => {
      dispatch(setMostActiveHeroesIsLoading(false));
    });
};
