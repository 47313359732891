import React from 'react';
import _ from 'lodash';

class HeroDetailsSidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  renderSkills(specificSkills) {
    return <div className="sidebar-border-content"> {_.get(specificSkills, 'name', '')} </div>;
  }

  renderDepartment(departmentName) {
    return <div className="sidebar-detail">{_.get(departmentName, 'name', '')}</div>;
  }

  render() {
    const { sidebarDetails, translations } = this.props;

    const specificSkills = _.get(sidebarDetails, 'specific_skills', []);
    const trainingSkills = _.get(sidebarDetails, 'training_skills', []);
    const heroDepartment = _.get(sidebarDetails, 'departments', []);

    return (
      <div className="hero-details-sidebar booking-sidebar">
        <div className="coleague-sidebar">
          <div className="colleague-sidebar-section">
            <div className="sidebar-headline">{_.get(translations, 'whiteLabel.emailAddress', 'E-mail address')}</div>
            <div className="sidebar-detail">{_.get(sidebarDetails, 'email', null)}</div>
          </div>
          <div className="colleague-sidebar-section">
            <div className="sidebar-headline">{_.get(translations, 'whiteLabel.department', 'Department')}</div>
            {heroDepartment.map(this.renderDepartment)}
          </div>

          {specificSkills.length ? (
            <div className="colleague-sidebar-section">
              <div className="sidebar-headline">{_.get(translations, 'whiteLabel.skills', 'Skills')}</div>
              {specificSkills.map(this.renderSkills)}
            </div>
          ) : (
            <div />
          )}

          {trainingSkills.length ? (
            <div className="colleague-sidebar-section">
              <div className="sidebar-headline">{_.get(translations, 'whiteLabel.trainingSkills', 'Training skills')}</div>
              {trainingSkills.map(this.renderSkills)}
            </div>
          ) : (
            <div />
          )}
        </div>
      </div>
    );
  }
}

export default HeroDetailsSidebar;
