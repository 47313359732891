import api from '../../components/community/utils/api';
import _ from 'lodash';
import { communitySelector, heroIsCommunityMemberSelector } from '../../selectors/community/community-page';
import { communityPostsCountSelector } from '../../selectors/community/community-posts';
import { fetchCommunityUploads } from './community-uploads';
import { showCommunityPageToast } from './community-toasts';
import { triggerRefreshNotifications } from '../notificationsActions';
/*
 * Constants
 */
export const SET_COMMUNITY_POST = 'SET_COMMUNITY_POST';
export const SET_MORE_COMMUNITY_POSTS = 'SET_MORE_COMMUNITY_POSTS';
export const SET_POST_IS_LOADING = 'SET_POST_IS_LOADING';
export const SET_POSTS_HAS_MORE = 'SET_POSTS_HAS_MORE';
export const SET_LIKE_ON_POST = 'SET_LIKE_ON_POST';

/*
 * Sync Actions
 */
export const setCommunityPosts = communityPosts => ({
  type: SET_COMMUNITY_POST,
  communityPosts,
});

export const setMoreCommunityPosts = communityPosts => ({
  type: SET_MORE_COMMUNITY_POSTS,
  communityPosts,
});

export const setPostIsLoading = isLoading => ({
  type: SET_POST_IS_LOADING,
  isLoading,
});
export const setPostLike = likeOnPost => ({
  type: SET_LIKE_ON_POST,
  likeOnPost,
});

export const setHasMore = hasMorePosts => ({
  type: SET_POSTS_HAS_MORE,
  hasMorePosts,
});

/*
 * Async Actions
 */
export const fetchCommunityPost = () => (dispatch, getState) => {
  dispatch(setPostIsLoading(true));
  const community = communitySelector(getState());
  const offset = 0;

  return new Promise(resolve => {
    api
      .get(`posts/all/communityId/${community.id}/offset/${offset}`)
      .then(response => {
        dispatch(setCommunityPosts(response.data.data));
        dispatch(setHasMore(response.data.hasMore));
      })
      .catch(({ response }) => {})
      .finally(() => {
        dispatch(setPostIsLoading(false));
      });
  });
};

export const fetchMoreCommunityPost = () => (dispatch, getState) => {
  const community = communitySelector(getState());
  const postsCount = communityPostsCountSelector(getState());
  const offset = postsCount || 0;
  if (!heroIsCommunityMemberSelector(getState())) {
    return dispatch(showCommunityPageToast('toast.onlyMemberCanSeeMore'));
  }
  return new Promise(resolve => {
    api
      .get(`posts/all/communityId/${community.id}/offset/${offset}`)
      .then(response => {
        dispatch(setMoreCommunityPosts(response.data.data));
        dispatch(setHasMore(response.data.hasMore));
      })
      .catch(({ response }) => {});
  });
};

export const likeCommunityPost = (heroId, postId) => dispatch => {
  return new Promise(resolve => {
    api
      .post(`posts/like`, { heroId, postId })
      .then(response => {
        dispatch(setPostLike(postId));
      })
      .catch(({ response }) => {
        dispatch(setPostIsLoading(false));
      });
  });
};
export const deleteCommunityPost = postId => dispatch => {
  return new Promise((resolve, reject) => {
    api
      .delete(`posts/delete/${postId}`)
      .then(response => {
        dispatch(fetchCommunityUploads());
        dispatch(fetchCommunityPost());
        resolve(true);
      })
      .catch(({ response }) => {
        dispatch(setPostIsLoading(false));
        reject();
      });
  });
};
export const fetchSinglePostPage = (slug, postId) => dispatch => {
  dispatch(setPostIsLoading(true));
  return new Promise((resolve, reject) => {
    api
      .get(`posts/singlePage/${slug}/${postId}`)
      .then(response => {
        dispatch(setCommunityPosts(response.data.data));
        resolve(true);
      })
      .catch(({ response }) => {
        reject(response.data.error);
      })
      .finally(() => {
        dispatch(setPostIsLoading(false));
      });
  });
};
export const notifyPost = (communityId, postId) => dispatch => {
  return new Promise((resolve, reject) => {
    api
      .post(`notifications/add`, { communityId, postId })
      .then(() => {
        dispatch(triggerRefreshNotifications());
        resolve('postIsNotified');
      })
      .catch(({ response }) => {
        const errorCode = _.get(response, 'data.error', 'errorNotifyingPost');
        reject(errorCode);
      });
  });
};
