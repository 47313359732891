import React from 'react';
import classnames from 'classnames';
import _ from 'lodash';
import CustomFilterDropdown from '../../../filtersContainer/CustomFilterDropdown';
import Search from '../searchHeroes';
import iconSearch from '../../../../assets/svg/header/icon-search.svg';
import DatePicker from '../../../datePicker/datePicker';
import moment from "moment-timezone";


/**
 *  Filter floor and date range for office floor
 *  also have search for heroes from white label company
 */
class FilterContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchExpanded: false,
    };
    this.expandSearch = this.expandSearch.bind(this);
    this.selectFloor = this.selectFloor.bind(this);
    this.selectProduct = this.selectProduct.bind(this);
    this.selectFeature = this.selectFeature.bind(this);
    this.onDateChange = this.onDateChange.bind(this);
    this.resetFilter = this.resetFilter.bind(this);
  }

  expandSearch() {
    const { searchExpanded } = this.state;
    this.setState({ searchExpanded: !searchExpanded });
  }

  selectFloor(floorId) {
    const { selectFloor, setSelectedProductId } = this.props;
    selectFloor(floorId);
    setSelectedProductId(null);
  }

  selectProduct(productType) {
    const { selectProductType } = this.props;
    selectProductType(productType);
  }

  selectFeature(feature) {
    const { selectFeatures, selectedFeatures } = this.props;
    let features = selectedFeatures;
    const idAlreadySelected = selectedFeatures.indexOf(feature) > -1;
    if (idAlreadySelected) {
      features = selectedFeatures.filter(f => f !== feature);
      if (_.isEmpty(features)) {
        features = [0];
      }
    } else {
      if (feature === 0) {
        features = [];
      } else {
        features = selectedFeatures.filter(f => f > 0);
      }
      features.push(feature);
    }
    selectFeatures(features);
  }

  onDateChange(startDate, endDate) {
    const { onDateChange } = this.props;
    onDateChange(startDate, endDate);
  }

  resetFilter() {
    const { whiteLabel, selectFloor, onDateChange, selectFeatures } = this.props;
    const floorPlans = _.get(whiteLabel, 'floor_plans', []);
    const floorId = _.get(floorPlans, '0.id');
    selectFloor(floorId);
    selectFeatures([0]);
    onDateChange(new Date());
  }

  render() {
    const {
      whiteLabel,
      selectedDate,
      selectedFloorIndex,
      selectedProductType,
      officeOpenDaysAndHours,
      translations,
      officeTimezone,
      features,
      selectedFeatures,
      officeTypes,
      mapProducts
    } = this.props;
    const floorPlans = _.get(whiteLabel, 'floor_plans', [{ id: null, floor_number: null }]);
    const { searchExpanded } = this.state;
    const firstFloor = selectedFloorIndex
      ? _.get(floorPlans, `${selectedFloorIndex}`, null)
      : floorPlans[0];
    const initialValue = { id: _.get(firstFloor, 'id'), value:  _.get(floorPlans, `${selectedFloorIndex}.name`) ?? _.get(firstFloor, 'floor_number') };
    const selectedFloor = {
      id: _.get(floorPlans, `${selectedFloorIndex}.id`),
      value: _.get(floorPlans, `${selectedFloorIndex}.name`) ?? _.get(floorPlans, `${selectedFloorIndex}.floor_number`),
    };
    let selectedProductTypeLabel = '';
    switch (selectedProductType) {
      case 1:
        selectedProductTypeLabel = _.get(translations, 'booking.options.officeTypes.desk', '');
        break;
      case 5:
        selectedProductTypeLabel = _.get(translations, 'booking.options.officeTypes.parking', '');
        break;
      case 3:
        selectedProductTypeLabel = _.get(translations, 'booking.options.officeTypes.meeting', '');
        break;
    }
    const selectedType = {
      id: selectedProductType,
      value: selectedProductTypeLabel,
    };
    const maxBookingDays = _.get(whiteLabel, 'details.max_bookable_days', 30);
    //add verification for parking also
    const isDesk = selectedProductType === 1 || selectedProductType === 5;
    const today = moment.tz(officeTimezone);
    const todayPlusDays = moment.tz(officeTimezone).add(maxBookingDays, 'days');

    const disabledDays = [];
    const selectedDays = _.map(officeOpenDaysAndHours.toJS(), data => data.weekDay);
    if (selectedDays) {
      for (let weekD = 1; weekD <= 7; weekD++) {
        if (!_.find(selectedDays, data => data === weekD)) {
          disabledDays.push(weekD);
        }
      }
    }
    const initialFeature = {id: 0, value: _.get(translations, 'whiteLabel.allFeatures', 'All features')};
    const productFeatures = mapProducts
        .filter(product =>
            _.get(product, 'productableType', null) === selectedProductType && _.get(product, 'customFeature', '').length
        )
        .map(product => _.get(product, 'customFeature', '').split(', '))
        .reduce((previous, current) => _.union(previous, current), []);
    const allFeatures = features
        .filter(feature => (_.includes(productFeatures, feature.title) || feature.id === 0))
        .map(function(feature) {
          return { id: feature.id, label: feature.title};
    });
    const filteredFeatures = allFeatures.filter(feature => _.indexOf(selectedFeatures, feature.id) >= 0).map(function(feature) {
      return { id: feature.id, value: feature.label};
    });
    const existingProductTypes = _.union(_.map(mapProducts, product => product.productableType));
    const availableOfficeTypes = _.filter(officeTypes, officeType => _.includes(existingProductTypes, officeType.id));

    return (
      <div className="filters">
        <div className="map-filter__container-dropdown">
          <CustomFilterDropdown
            placeHolder={_.get(translations, 'whiteLabel.select', '')}
            customText={_.get(translations, 'whiteLabel.floor', '')}
            className="dropdown__selector map-filter__dropdown map-filter__floor"
            onChange={this.selectFloor}
            selectedItems={[selectedFloor]}
            list={floorPlans.map(function(plan) {
              const floorName = plan.name ?? plan.floor_number;
              return { id: plan.id, label: floorName, groupName: 'officesTypes' };
            })}
            initialValue={[initialValue]}
            closeOnSelection
          />
          <CustomFilterDropdown
            placeHolder={_.get(translations, 'whiteLabel.select', '')}
            customText={_.get(translations, 'whiteLabel.product', '')}
            className="dropdown__selector map-filter__dropdown map-filter__type"
            onChange={this.selectProduct}
            selectedItems={[selectedType]}
            list={availableOfficeTypes}
            initialValue={[selectedType]}
            closeOnSelection
          />
          <CustomFilterDropdown
              placeHolder={_.get(translations, 'whiteLabel.select', '')}
              customText={_.get(translations, 'whiteLabel.features', 'Features')}
              className={classnames("dropdown__selector map-filter__dropdown map-filter__type", {'map-filter__dropdown--disabled': _.size(allFeatures) < 2 })}
              onChange={this.selectFeature}
              selectedItems={filteredFeatures}
              list={allFeatures}
              initialValue={[initialFeature]}
              closeOnSelection
          />
          <DatePicker
            startDate={_.get(selectedDate, 'from')}
            endDate={isDesk ? _.get(selectedDate, 'to') : _.get(selectedDate, 'from')}
            onChange={this.onDateChange}
            disabledDays={disabledDays}
            customText={_.get(translations, 'whiteLabel.date', '')}
            className={classnames("dropdown__selector map-filter__dropdown map-filter__date", {'filter__date--no-show': searchExpanded})}
            placeHolder="Select date"
            single={!isDesk}
            isDatepickerOnFilterMap
            maxDate={todayPlusDays}
            minDate={today}
            officeTimezone={officeTimezone}
          />
          {!searchExpanded && (<div className="search-filter">
            <button type="button" className={classnames('button-reset')} onClick={this.resetFilter}>
              {_.get(translations, 'whiteLabel.reset', '')}
            </button>
          </div>)}
          {!searchExpanded ? (
            <div className="small-search" onClick={this.expandSearch}>
              <img src={iconSearch} />
            </div>
          ) : (
            <Search placeholder="" className="white-label__search" expandSearch={this.expandSearch} />
          )}
        </div>
      </div>
    );
  }
}
export default FilterContainer;
