import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import './index.scss';
import _ from 'lodash';
import moment from 'moment-timezone';
import { withRouter } from 'react-router';
import classnames from 'classnames';
import DateTimePicker from './dateTimePicker';
import ProductOverview from './productOverview';
import OfficeTypePicker from './officeTypePicker';
import AmenitiesAndServices from './amenitiesAndServices';
import blockIncompleteProfileAction from '../../../../utils/blockIncompleteProfileAction';
import circleLoader from '../../../../assets/images/circle-loader-64px.gif';
import Checkbox from '../../../addBookingSidebar/checkbox';

const DATE_FORMAT = 'MMM D, YYYY';

/**
 *
 */
class SidebarContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDays: [],
      selectedHours: [],
      selectedDateRange: [],
      selectedServices: [],
      selectedAmenities: [],
      title: '',
      comment: '',
      errors: [],
      isOnlineMeeting: false,
      virtualMeetingAllowed: false,
    };
    this.onUpdateDate = this.onUpdateDate.bind(this);
    this.onChangeWeekDays = this.onChangeWeekDays.bind(this);
    this.onUpdateHours = this.onUpdateHours.bind(this);
    this.toggleServices = this.toggleServices.bind(this);
    this.makeBooking = this.makeBooking.bind(this);
    this.toggleAmenities = this.toggleAmenities.bind(this);
  }

  componentDidMount() {
    this.initOptions();
  }

  componentDidUpdate(prevProps) {
    const { selectedProductId, selectDateRange } = this.props;
    const { selectedProductId: oldSelectedProductId, selectDateRange: oldDateRange } = prevProps;

    if (oldSelectedProductId !== selectedProductId) {
      this.initOptions(selectedProductId !== null);
    }
    if (selectDateRange.from !== oldDateRange.from || selectDateRange.to !== oldDateRange.to) {
      const needValidation = selectedProductId !== null && oldSelectedProductId === selectedProductId;
      this.initOptions(needValidation);
    }
  }

  initOptions(needValidation = false) {
    const { officeOpenDaysAndHours, selectDateRange, officeTimezone } = this.props;
    const fromHour = _.get(officeOpenDaysAndHours.toJS(), '0.fromHour', '00');
    const fromMinute = _.get(officeOpenDaysAndHours.toJS(), '0.fromMinute', '00');
    const untilHour = _.get(officeOpenDaysAndHours.toJS(), '0.toHour', '00');
    const untilMinute = _.get(officeOpenDaysAndHours.toJS(), '0.toMinute', '00');
    const startDate = _.get(selectDateRange, 'from', '');
    const endDate = _.get(selectDateRange, 'to', '');
    const selectedDays = this.getSelectedWeekdays(officeOpenDaysAndHours, startDate, endDate);

    // by default these are the opening hours
    let from = moment()
      .set('hour', fromHour)
      .set('minute', fromMinute);

    const to = moment()
      .set('hour', untilHour)
      .set('minute', untilMinute);

    const today = moment().tz(officeTimezone);
    if (today.format('YYYY-MM-DD') === moment(startDate, 'MMM D, YYYY').format('YYYY-MM-DD')) {
      // only for current day  we select the next available interval
      const nowTZ = moment().tz(officeTimezone);
      const minutesTZ = nowTZ.minutes() >= 30 ? '00' : '30';
      const nextAvailableInterval = moment()
        .set('hour', nowTZ.hour())
        .set('minute', minutesTZ);

      if (nowTZ.minutes() >= 30) {
        nextAvailableInterval.add('1', 'hours');
      }

      // check if the next interval is valid
      if (nextAvailableInterval.isBetween(from, to)) {
        from = nextAvailableInterval;
      }
    }

    this.setState(
      {
        selectedDays,
        selectedHours: { hoursFrom: from.format('HH:mm'), hoursUntil: to.format('HH:mm') },
        selectedAmenities: [],
        errors: [],
      },
      needValidation ? this.bookingValidate : null
    );
  }

  getSelectedWeekdays(officeOpenDaysAndHours, startDate, endDate) {
    let selectedDays = [];
    const openDays = _.map(officeOpenDaysAndHours.toJS(), data => data.weekDay);
    for (let m = moment(startDate, 'MMM D, YYYY'); m.isSameOrBefore(moment(endDate, 'MMM D, YYYY')); m.add(1, 'days')) {
      if (openDays.includes(m.isoWeekday())) {
        selectedDays = _.concat(selectedDays, m.isoWeekday());
      }
    }
    return selectedDays;
  }

  prepareData(useOpeningHours = false) {
    const { selectedProductId, selectDateRange, officeTimezone, officeOpenDaysAndHours } = this.props;
    const {
      selectedHours,
      selectedAmenities,
      selectedServices,
      title,
      comment,
      isOnlineMeeting,
      virtualMeetingAllowed,
      selectedDays,
    } = this.state;
    const hFrom = moment(moment().format('YYYY-MM-DD ') + selectedHours.hoursFrom, 'YYYY-MM-DD hh:mm A');
    const hUntil = moment(moment().format('YYYY-MM-DD ') + selectedHours.hoursUntil, 'YYYY-MM-DD hh:mm A');

    let fromHour = parseInt(hFrom.format('H'), 10);
    let fromMinute = parseInt(hFrom.format('m'), 10);
    let untilHour = parseInt(hUntil.format('H'), 10);
    let untilMinute = parseInt(hUntil.format('m'), 10);

    // Use office starting/ending hour + minute if filter date differs from the current day
    if (useOpeningHours && moment.utc(selectDateRange.from).format('YYYY-MM-DD') !== moment.utc().format('YYYY-MM-DD')) {
      fromHour = parseInt(_.get(officeOpenDaysAndHours.toJS(), '0.fromHour', '00'), 10);
      fromMinute = parseInt(_.get(officeOpenDaysAndHours.toJS(), '0.fromMinute', '00'), 10);
      untilHour = parseInt(_.get(officeOpenDaysAndHours.toJS(), '0.toHour', '00'), 10);
      untilMinute = parseInt(_.get(officeOpenDaysAndHours.toJS(), '0.toMinute', '00'), 10);
    }
    // Use next available interval for hour and minutes if filter date is in the current day
    if (useOpeningHours && moment.utc(selectDateRange.from).format('YYYY-MM-DD') === moment.utc().format('YYYY-MM-DD')) {
      const nowTZ = moment().tz(officeTimezone);
      const minutesTZ = nowTZ.minutes() >= 30 ? '00' : '30';
      const nextAvailableInterval = moment()
        .set('hour', nowTZ.hour())
        .set('minute', minutesTZ);
      if (nowTZ.minutes() >= 30) {
        nextAvailableInterval.add('1', 'hours');
      }
      fromHour = nextAvailableInterval.hour();
      fromMinute = nextAvailableInterval.minutes();
    }

    return {
      fromDate: moment.utc(selectDateRange.from, DATE_FORMAT).unix(),
      toDate: moment.utc(selectDateRange.to, DATE_FORMAT).unix(),
      productId: selectedProductId,
      weekDays: selectedDays,
      hours: {
        from: {
          hour: fromHour,
          minute: fromMinute,
        },
        to: {
          hour: untilHour,
          minute: untilMinute,
        },
      },
      amenities: selectedAmenities,
      equipments: selectedServices,
      officeTimezone,
      title,
      additionalComment: comment,
      isOnlineMeeting,
      virtualMeetingAllowed,
    };
  }

  bookingValidate(useOpeningHours = false) {
    const { bookingValidate } = this.props;
    bookingValidate(this.prepareData(useOpeningHours))
      .then(data => {
        this.setState({ errors: {} });
        this.setState({
          virtualMeetingAllowed: _.get(data, 'virtualMeetingAllowed', false) ? Boolean(data.virtualMeetingAllowed) : false,
        });
      })
      .catch(response => {
        this.setState({ errors: response.error });
        this.setState({
          virtualMeetingAllowed: _.get(response, 'virtualMeetingAllowed', false) ? Boolean(response.virtualMeetingAllowed) : false,
        });
      });
  }

  makeBooking() {
    const { makeBooking, history } = this.props;
    makeBooking(this.prepareData())
      .then(data => {
        this.setState({ errors: {} });

        if (data.bookingId) {
          history.push(`/booking/${data.bookingId}`);
        }
      })
      .catch(response => {
        this.setState({ errors: response.error });
      });
  }

  toggleServices(service) {
    const { selectedServices } = this.state;
    const services = selectedServices.includes(service)
      ? selectedServices.filter(id => {
          return id !== service;
        })
      : _.concat(selectedServices, [service]);
    this.setState({ selectedServices: services });
  }

  toggleAmenities(amenity) {
    const { selectedAmenities } = this.state;
    const amenities = selectedAmenities.includes(amenity)
      ? selectedAmenities.filter(id => {
          return id !== amenity;
        })
      : _.concat(selectedAmenities, [amenity]);
    this.setState({ selectedAmenities: amenities }, () => {
      this.bookingValidate();
    });
  }

  async onUpdateDate(startDate, endDate) {
    const { fetchStaffMembers, officeId, setSelectedDate } = this.props;
    if (!endDate) {
      await setSelectedDate(moment(startDate).format(DATE_FORMAT), moment(startDate).format(DATE_FORMAT));
    } else {
      await setSelectedDate(startDate, endDate);
    }
    const date = moment(startDate, DATE_FORMAT).unix();
    fetchStaffMembers(officeId, date);
    this.bookingValidate();
  }

  onUpdateHours(type, value) {
    const { selectedHours } = this.state;
    this.setState({ selectedHours: { ...selectedHours, [type]: value } }, () => {
      this.bookingValidate();
    });
  }

  onChangeWeekDays(weekday) {
    const { selectedDays } = this.state;
    const days = selectedDays.includes(weekday)
      ? selectedDays.filter(day => {
          return day !== weekday;
        })
      : _.concat(selectedDays, [weekday]);
    this.setState({ selectedDays: days }, () => {
      this.bookingValidate();
    });
  }

  renderErrors(errors) {
    return (
      <div className={classnames('white-label__book-max-days', 'white-label__book-max-days--error')}>
        {_.map(errors, error => {
          return _.isArray(error) ? _.get(error, '0', '') : error;
        })}
      </div>
    );
  }

  render() {
    const { completeBookingLoader, translations, selectedProductId, history, location, selectedProductTypeId, ssoProvider } = this.props;
    const {
      selectedDays,
      selectedHours,
      selectedAmenities,
      selectedServices,
      errors,
      title,
      comment,
      isOnlineMeeting,
      virtualMeetingAllowed,
    } = this.state;
    const disabledButton = !_.isEmpty(errors) ? 'disabled' : '';
    const completeBookingButton = completeBookingLoader ? (
      <img className="sidebar-loader__image" src={circleLoader} alt="Loading" />
    ) : (
      _.get(translations, 'whiteLabel.completeBooking', 'Complete booking')
    );

    return (
      <div className="booking-options-content white-label__booking-options">
        <Scrollbars className="styleScrollDark">
          <div className="booking-options-scrollable">
            <OfficeTypePicker />
            {selectedProductId ? (
              <div className="booking-options-details">
                <ProductOverview />
                <DateTimePicker
                  selectedDays={selectedDays}
                  selectedHours={selectedHours}
                  onUpdateDate={this.onUpdateDate}
                  onChangeWeekDays={this.onChangeWeekDays}
                  onUpdateHours={this.onUpdateHours}
                  renderErrorFullyBooked={this.renderErrors([_.get(errors, 'fullyBooked', '')])}
                  renderErrorWeekday={this.renderErrors([_.get(errors, 'weekDays', '')])}
                  renderErrorHours={this.renderErrors([_.get(errors, 'fullyBookedHours', ''), _.get(errors, 'hours.from.hour', '')])}
                />
                <AmenitiesAndServices
                  toggleServices={this.toggleServices}
                  selectedServices={selectedServices}
                  toggleAmenities={this.toggleAmenities}
                  selectedAmenities={selectedAmenities}
                  inactiveAmenities={_.get(errors, 'amenitiesIds', '')}
                />
                {selectedProductTypeId === 3 ? (
                  <>
                    <div className="sidebar__field">
                      <input
                        className="sidebar__field--input"
                        type="text"
                        value={title}
                        maxLength="100"
                        onChange={e => {
                          this.setState({ title: e.target.value });
                        }}
                        placeholder={_.get(translations, 'booking.sidebar.form.meetingTitle', 'Meeting title (optional)')}
                      />
                      <span className="white-label__book-max-days--error">{_.get(errors, 'title', '')}</span>
                    </div>

                    <div className="sidebar__field">
                      <textarea
                        className="sidebar__field--textarea"
                        value={comment}
                        maxLength="500"
                        onChange={e => {
                          this.setState({ comment: e.target.value });
                        }}
                        placeholder={_.get(translations, 'booking.sidebar.form.comment', 'Additional comment (optional)')}
                      />
                      <span className="white-label__book-max-days--error">{_.get(errors, 'additional_comment', '')}</span>
                    </div>

                    {virtualMeetingAllowed ? (
                      <div className={classnames('white-label__is-online-meeting')}>
                        <div className="white-label__amenities-services-title">
                          {_.get(translations, `booking.sidebar.isOnlineMeeting${ssoProvider}`, 'Virtual meeting')}
                        </div>
                        <Checkbox
                          checked={isOnlineMeeting}
                          onChange={e => {
                            this.setState({ isOnlineMeeting: !this.state.isOnlineMeeting });
                          }}
                        />
                      </div>
                    ) : null}
                  </>
                ) : null}

                <div
                  className={`booking-options-finish ${disabledButton}`}
                  onClick={() => {
                    if (_.isEmpty(errors)) {
                      blockIncompleteProfileAction()
                        .then(() => {
                          history.push({
                            pathname: '/account/profile/incomplete',
                            fromUrl: _.get(location, 'pathname', null),
                            state: { fromBooking: true },
                          });
                        })
                        .catch(() => {
                          this.makeBooking();
                        });
                    }
                  }}
                >
                  {completeBookingButton}
                </div>
              </div>
            ) : null}
          </div>
        </Scrollbars>
      </div>
    );
  }
}

export default withRouter(SidebarContent);
