/**
 * Uses the react-google-maps module to render a google map
 * @class Map
 * @module MapContainer
 */
import React from 'react';
import { withGoogleMap, GoogleMap, Marker } from 'react-google-maps';
import InfoBox from 'react-google-maps/lib/components/addons/InfoBox';
import _ from 'lodash';
import mapConfig from './config/map.config';
import MarkerInfo from './markerInfo';

const Map = withGoogleMap(props => {
  const onBookItem = marker => {
    props.onActionItem('book', marker);
  };

  /**
   * Renders a InfoWindow on the map. It uses an overlay layer because the default
   * InfoWindow in maps cannot be fully styled
   * @method getInfo
   * @param {Immutable.Map} marker
   * @returns {ReactElement}
   */
  const getInfo = marker => {
    if (marker.get('id') === props.activeMarker && !marker.neverShow) {
      let markerHeight = -170;
      if (marker.get('productsCount')) {
        markerHeight = _.size(_.filter(marker.get('productsCount').toJS())) > 2 ? -320 : -270;
      }

      return (
        <InfoBox
          onCloseClick={() => props.onMarkerClose(marker.get('key'))}
          options={{
            pixelOffset: new window.google.maps.Size(-150, markerHeight),
            enableEventPropagation: true,
            closeBoxURL: '',
          }}
          onMarkerClose={props.onMarkerClose}
        >
          <MarkerInfo
            key={marker.get('id')}
            markerID={marker.get('id')}
            marker={marker}
            geoLocationCoords={props.geoLocationCoords}
            officeTypesData={props.officeTypesData}
            onBookItem={() => {
              onBookItem(marker);
            }}
            showMessageModal={props.showMessageModal}
            onMarkerClose={() => {
              props.onMarkerClose(marker.get('key'));
            }}
            trans={_.get(props, 'trans.map', [])}
          />
        </InfoBox>
      );
    }
    return null;
  };

  /**
   * Generates all markers that should be displayed on map
   * @method generateMarkers
   * @param {Immutable.List<Immutable.Map>} markers
   * @returns {Immutable.List<ReactElement>}
   */
  const generateMarkers = (markers, map) => {
    return markers.map((marker, index) => {
      const location = marker.get('location').toJS();
      const availableZIndex = marker.toJS().officeStatus === 'available' ? 1000 : 1;
      return (
        <Marker
          onClick={() => {
            props.onMarkerClick(marker.get('id'), location);
          }}
          key={marker.get('id')}
          zIndex={props.activeMarker === marker.get('id') ? 999999 + index : availableZIndex + index}
          // position={ marker.get('position').toJS() }
          position={{ lat: location.latitude, lng: location.longitude }}
          icon={props.defaultIcon || marker.get('icon')}
        >
          {getInfo(marker)}
        </Marker>
      );
    });
  };

  const draggable = typeof props.draggable !== 'undefined' ? props.draggable : true;
  const center = typeof props.setCenter !== 'undefined' && props.setCenter === false ? null : props.mapData.get('center').toJS();
  const minZoom = _.get(props, 'minZoom', 8);

  return (
    <GoogleMap
      ref={props.onMapLoad}
      zoom={props.mapData.get('zoom')}
      onBoundsChanged={props.onBoundsChanged}
      center={center}
      defaultOptions={{
        disableDefaultUI: true,
        styles: mapConfig,
        zoomControl: true,
        gestureHandling: 'cooperative',
        draggable,
        scrollable: false,
        panControl: false,
        minZoom,
      }}
    >
      {generateMarkers(props.markers, props.mapData)}
    </GoogleMap>
  );
});

export default Map;
