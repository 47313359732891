import React from 'react';
import './css/index.scss';


class Sidebar extends React.Component {

    constructor(props) {
        super(props);

        this.state ={ isActive :true } ;
    }

    ToggleClass = (e) => {
        this.setState({ isActive: !this.state.isActive})
    };

    render() {
        return (
            <aside className={ `rightSidebar ${this.props.className}` }>
                <div className="rightSidebar-content">

            <div className={(this.state.isActive) ? "non-active" : "active"} onClick={this.ToggleClass}>
                {/*{(this.state.isActive) ? "non-active" : "active"}*/}
                <span></span>
            </div>
                    { this.props.children }
                </div>
            </aside>
        );
    }
}
export default Sidebar;
