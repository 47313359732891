import { fetchNewCompanyProfile } from './dashboard/new-company-profile';
import { fetchCoCreationProjects } from './dashboard/co-creation-projects';
import { fetchNews, resetNews } from './dashboard/news';
import { fetchHeroRecommendation } from './dashboard/hero-recommendation';
/* eslint-disable no-underscore-dangle */
export const TOGGLE_SUGGESTIONS = 'TOGGLE_SUGGESTIONS';
export const SUGGESTIONS_OFF = 'SUGGESTIONS_OFF';
export const GLOBAL_SEARCH = 'GLOBAL_SEARCH';
export const GLOBAL_SEARCH_TRIGGER = 'GLOBAL_SEARCH_TRIGGER';
export const GET_TRANSLATIONS = 'GET_TRANSLATIONS';
export const GET_VERTICALS = 'GET_VERTICALS';
export const SET_VERTICAL = 'SET_VERTICAL';
export const GLOBAL_SEARCH_HEROES = 'GLOBAL_SEARCH_HEROES';
export const SET_IS_WHITE_LABEL = 'SET_IS_WHITE_LABEL';
export const CHECK_SUBDOMAIN = 'CHECK_SUBDOMAIN';
export const FETCH_APP_TIMEZONE = 'FETCH_APP_TIMEZONE';

export const toggleSuggestions = () => {
  return {
    type: TOGGLE_SUGGESTIONS,
  };
};

export const suggestionsOff = () => {
  return {
    type: SUGGESTIONS_OFF,
  };
};

export const globalSearch = term => {
  const apiPrefix = localStorage.getItem('access-key') ? '' : '/public';
  return {
    type: GLOBAL_SEARCH,
    url: `${window.__env.apiUrl}${apiPrefix}/search`,
    term,
  };
};
export const globalSearchHeroes = (term, allProfiles = false) => {
  return {
    type: GLOBAL_SEARCH_HEROES,
    url: `${window.__env.apiUrl}/search`,
    term,
    allProfiles,
  };
};

export const globalSearchTrigger = () => {
  return {
    type: GLOBAL_SEARCH_TRIGGER,
  };
};

export const getTranslations = lang => {
  return {
    type: GET_TRANSLATIONS,
    url: `${window.__env.apiUrl}/translations`,
    lang,
  };
};
export const handleSubdomain = subdomain => {
  return {
    type: CHECK_SUBDOMAIN,
    url: `${window.__env.apiUrl}/subdomain`,
    subdomain,
  };
};
export const getVerticals = () => {
  const apiPrefix = localStorage.getItem('access-key') ? '' : '/public';
  return {
    type: GET_VERTICALS,
    url: `${window.__env.apiUrl}${apiPrefix}/dashboard/verticals/list`,
  };
};

export const setVertical = vertical => {
  return {
    type: SET_VERTICAL,
    vertical,
  };
};

export const setIsWhiteLabel = isWhiteLabel => {
  return {
    type: SET_IS_WHITE_LABEL,
    isWhiteLabel,
  };
};

export const fetchAppTimezone = () => {
  return {
    type: FETCH_APP_TIMEZONE,
    url: `${window.__env.apiUrl}/app-timezone`,
  };
};

/**
 * Register here actions that need to be recalled on vertical change
 */
export const fetchVerticalData = () => dispatch => {
  dispatch(fetchNewCompanyProfile());
  dispatch(resetNews());
  dispatch(fetchNews());
  dispatch(fetchCoCreationProjects());
  dispatch(fetchHeroRecommendation());
};

export const setVerticalAndFetch = vertical => dispatch => {
  dispatch(setVertical(vertical));
  dispatch(fetchVerticalData());
};
