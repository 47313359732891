import { connect } from 'react-redux';

import DirectChat from './../../components/directChat/directChat';
import {
  addImage,
  addMessage,
  clearModalData,
  deleteConversation,
  deleteConversationConfirmation,
  directChatTick,
  fetchDirectChatConversations,
  fetchConversationMessages,
  getDirectChatConnections,
  getDirectChatConversations,
  markConversationAsRead,
  muteConversation,
  muteConversationConfirmation,
  openConversation,
  removeImage,
  reportConversation,
  reportConversationConfirmation,
  toggleConversationOptions,
  toggleConversations,
  storeScrollHeight,
  storeScrollHeightContacts,
  fetchChatConnections,
  openConversationAndFetch,
  closeActiveConversation,
} from './../../actions/directChat';

const mapStateToProps = state => {
  return {
    connections: state.directChat.get('connections'),
    conversations: state.directChat.get('conversations'),
    connectionsInitialLoaded: state.directChat.get('connectionsInitialLoaded'),
    conversationsInitialLoaded: state.directChat.get('conversationsInitialLoaded'),
    meta: state.directChat.get('meta'),
    now: state.directChat.get('now'),
    scrollHeight: state.directChat.get('scrollHeight'),
    scrollHeightContacts: state.directChat.get('scrollHeightContacts'),
    lastFetchedEpoch: state.directChat.get('lastFetchedEpoch'),
    heroProfile: state.global.get('heroProfile'),
    translations: state.translations,
  }
};

const mapDispatchProps = dispatch => {
  return {
    actions: {
      addImage: image => dispatch(addImage(image)),
      addMessage: (id, message, heroId) => dispatch(addMessage(id, message, heroId)),
      clearModalData: () => dispatch(clearModalData()),
      deleteConversation: id => dispatch(deleteConversation(id)),
      deleteConversationConfirmation: id => dispatch(deleteConversationConfirmation(id)),
      directChatTick: () => dispatch(directChatTick()),
      fetchChatConnections: () => dispatch(fetchChatConnections()),
      fetchConversationMessages: (conversationId, startEpoch) => {
        dispatch(fetchConversationMessages(conversationId, startEpoch));
      },
      storeScrollHeight: (height) => dispatch(storeScrollHeight(height)),
      storeScrollHeightContacts: (height) => dispatch(storeScrollHeightContacts(height)),
      fetchDirectChatConversations: () => dispatch(fetchDirectChatConversations()),
      getDirectChatConnections: term => dispatch(getDirectChatConnections(term)),
      getDirectChatConversations: term => dispatch(getDirectChatConversations(term)),
      markConversationAsRead: id => dispatch(markConversationAsRead(id)),
      muteConversation: id => dispatch(muteConversation(id, true)),
      muteConversationConfirmation: id => dispatch(muteConversationConfirmation(id)),
      openConversation: (posterId, poster) => dispatch(openConversation(posterId, poster)),
      openConversationAndFetch: (posterId, conversationId) => dispatch(openConversationAndFetch(posterId, conversationId)),
      removeImage: () => dispatch(removeImage()),
      reportConversation: id => dispatch(reportConversation(id)),
      reportConversationConfirmation: id => dispatch(reportConversationConfirmation(id)),
      toggleConversationOptions: showing => dispatch(toggleConversationOptions(showing)),
      toggleConversations: () => dispatch(toggleConversations()),
      unmuteConversation: id => dispatch(muteConversation(id, false)),
      closeActiveConversation: () => dispatch(closeActiveConversation()),
    }
  }
};

const DirectChatView = connect(mapStateToProps, mapDispatchProps)(DirectChat);

export default DirectChatView;
