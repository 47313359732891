import { connect } from 'react-redux';
import _ from 'lodash';
import {
  fetchNotifications,
  getUnreadNotificationsCount,
  onNotificationClick,
  markAsRead,
  markOneAsRead,
  triggerRefreshNotifications,
  hideNotifications,
} from '../../actions/notificationsActions';
import { globalSearch, globalSearchTrigger } from '../../actions/global';
import { onLocationChange, onLocationChangeSearch } from '../../actions/map';
import { fetchProfile } from '../../actions/accountActions';
import Header from '../../components/header/header';
import { fetchConnections } from '../../actions/connections';
import { onFeedbackClick, feedbackSend } from '../../actions/feedbackActions';
import { clearHeroAuthenticationData } from '../../actions/openAuth/login';
import { setMustLogout } from '../../actions/whiteLabel/products';
import { translations } from '../../selectors/translations';

const mapStateToProps = state => {
  return {
    notificationClicked: state.notifications.get('notificationClicked'),
    feedbackClicked: state.feedback.get('feedbackClicked'),
    feedbackContent: state.feedback.get('feedbackContent'),
    feedbackError: state.feedback.get('feedbackError'),
    sendFeedbackLoader: state.feedback.get('sendFeedbackLoader'),
    modalOpened: state.notifications.get('modalOpened'),
    notificationsData: state.notifications.get('notificationsData'),
    notificationsCount: state.notifications.get('notificationsCount'),
    markedAsRead: state.notifications.get('markedAsRead'),
    refreshNotifications: state.connections.get('refreshNotifications'),
    heroProfile: state.global.get('heroProfile'),
    globalSearchResults: state.global.get('globalSearchResults'),
    globalSearching: state.global.get('globalSearching'),
    existing: state.connections.get('existing'),
    translations: state.translations,
    bookingSection: state.addBooking.get('bookingSection'),
    isAuthenticated: state.auth.get('isAuthenticated'),
    isWhiteLabel: state.global.get('isWhiteLabel'),
    mustLogout: state.whiteLabel.products.get('mustLogout'),
    ssoProvider: state.global.get('ssoProvider'),
    formatString: _.get(translations(state), 'formatString', () => {}),
  };
};

const mapDispatchProps = dispatch => {
  return {
    fetchNotifications: (pageNumber, pageSize) => {
      dispatch(fetchNotifications(pageNumber, pageSize));
    },
    getUnreadNotificationsCount: () => {
      dispatch(getUnreadNotificationsCount());
    },
    onLocationChangeSearch: () => {
      dispatch(onLocationChangeSearch());
    },
    fetchProfile: () => {
      dispatch(fetchProfile());
    },
    globalSearch: term => {
      dispatch(globalSearch(term));
    },
    globalSearchTrigger: () => {
      dispatch(globalSearchTrigger());
    },
    triggerRefreshNotifications: () => {
      dispatch(triggerRefreshNotifications());
    },
    onLocationChange: value => {
      dispatch(onLocationChange(value));
      dispatch(onLocationChangeSearch());
    },
    onNotificationClick: event => {
      if (event) {
        event.preventDefault();
      }
      dispatch(onNotificationClick());
    },
    markAsRead: () => {
      dispatch(markAsRead());
    },
    markOneAsRead: notificationId => {
      dispatch(markOneAsRead(notificationId));
    },
    fetchConnections: () => {
      dispatch(fetchConnections());
    },
    onFeedbackClick: event => {
      if (event) {
        event.preventDefault();
      }
      dispatch(onFeedbackClick());
    },
    feedbackSend: feedbackContent => {
      dispatch(feedbackSend(feedbackContent));
    },
    hideNotifications: () => {
      dispatch(hideNotifications());
    },
    clearHeroAuthenticationData: data => dispatch(clearHeroAuthenticationData(data)),
    setMustLogout: mustLogoutFlag => dispatch(setMustLogout(mustLogoutFlag)),
  };
};

const HeaderView = connect(mapStateToProps, mapDispatchProps)(Header);

export default HeaderView;
