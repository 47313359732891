import React from 'react';
import _ from 'lodash';
import { withRouter, useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import communityIcon from '../../../assets/svg/community/community-icon.svg';
import blockIncompleteProfileAction from '../../../utils/blockIncompleteProfileAction';

const NoCommunities = ({ translations, action }) => {
  const history = useHistory();
  const location = useLocation();
  return (
    <div className="no-communities__container">
      <img src={communityIcon} alt="Community Icon" />
      <p className="no-communities__main-title">{_.get(translations, 'noCommunityTitle', '')}</p>
      <p className="no-communities__sub-title">{_.get(translations, 'noCommunitySubtitle', '')}</p>
      <button
        type="button"
        className="entity-button entity-button--border no-communities__btn"
        onClick={() =>
          blockIncompleteProfileAction()
            .then(() => {
              history.push({ pathname: '/account/profile/incomplete', fromUrl: location.pathname });
            })
            .catch(() => {
              action();
            })
        }
      >
        {_.get(translations, 'startCommunityButton', '')}
      </button>
    </div>
  );
};

NoCommunities.propTypes = {
  translations: PropTypes.shape({
    noCommunityTitle: PropTypes.string,
    noCommunitySubtitle: PropTypes.string,
    startCommunityButton: PropTypes.string,
  }),
  action: PropTypes.func.isRequired,
};

NoCommunities.defaultProps = {
  translations: {},
};

export default withRouter(NoCommunities);
