import { connect } from 'react-redux';
import _ from 'lodash';
import WmsHomescreen from './WmsHomescreen';
import { translations } from '../../selectors/translations';
import './index.scss';

const mapStateToProps = state => ({
  translations: _.get(translations(state), 'wmsHomescreen', {}),
  openAuthTranslations: _.get(translations(state), 'openAuth', {}),
});

export default connect(mapStateToProps)(WmsHomescreen);
