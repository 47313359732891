import Immutable from 'immutable';
import {
  SET_COMMUNITY_COMMENT_FORM_ERRORS,
  SET_COMMUNITY_COMMENT_REPLY_FORM_ERRORS,
  SET_COMMUNITY_COMMENT_FORM_IS_LOADING,
  SET_COMMUNITY_COMMENT_REPLY_IS_LOADING,
} from '../../actions/community/create-comment';

const initialState = Immutable.Map({
  isLoading: false,
  replyIsLoading: false,
  isReplyLoading: false,
  errors: {},
  replyErrors: {},
});

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_COMMUNITY_COMMENT_FORM_IS_LOADING:
      return state.setIn(['isLoading'], action.isLoading);
    case SET_COMMUNITY_COMMENT_REPLY_IS_LOADING:
      return state.setIn(['isReplyLoading'], action.isReplyLoading);
    case SET_COMMUNITY_COMMENT_FORM_ERRORS:
      return state.setIn(['errors', action.postId], action.errors);
    case SET_COMMUNITY_COMMENT_REPLY_FORM_ERRORS:
      return state.setIn(['replyErrors', action.formUniqueIdentifier], action.replyErrors);
    default:
      return state;
  }
};
