import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import moment from 'moment';
import _ from 'lodash';
import getLang from "../../../../utils/lang";
import { getWorkDays } from '../../../../utils/weekDaysUtils';

const lang = getLang() !== null ? getLang() : 'en';
class OfficeBooked extends React.Component {

  getOfficeType = () => {
    let selectedType  = null;
    let roomName      = null;
    const {details, trans} = this.props;
    if(details.toJS().desk !== undefined)
    {
      selectedType  = 'desk';
      roomName      = details.toJS().desk.name;
    }
    if(details.toJS().parking !== undefined)
    {
      selectedType = 'parking';
      roomName      = details.toJS().parking.name;
    }
    if(details.toJS().meetingRoom !== undefined)
    {
      selectedType = 'meeting';
      roomName      = details.toJS().meetingRoom.name;
    }
    return (
      <>
        <div className="office-booked-title">{trans.bookingPage.officeSpace}</div>
        <div className={classnames(`icon-${selectedType}`)}>{roomName}</div>
      </>
    )
  }

  getCalendarLink = () => {
    const {details, trans} = this.props;
    const isCanceled = (_.get(details.toJS(), 'status') === 'canceled');
    const joinUrlText = details.toJS().calendar_provider === 'google' ? 'go_to_meet' : 'go_to_teams'
    if (!isCanceled && details.toJS().hasOwnProperty('join_url')) {
      return (
        <>
          <div className="office-booked-title">{trans.booking.sidebar[joinUrlText]}</div>
          <a href={details.toJS().join_url} target="_blank" rel="noopener noreferrer" className='office-booked-details-description'>
            {details.toJS().join_url.substr(0, 19)} ...
          </a>
        </>
      )
    }
  }

  getBookingPeriod (selectedOptions, appTimezone) {
    moment().locale(lang);
    if (typeof selectedOptions.endDate !== 'undefined' && typeof selectedOptions.startDate !== 'undefined') {
      if(moment.unix(selectedOptions.startDate).format('MMMM DD, YYYY') === moment.unix(selectedOptions.endDate).format('MMMM DD, YYYY')) {
        return (
          <div className="office-booked-details-description">
            {new Date(selectedOptions.startDate * 1000).toLocaleString(lang, {day: 'numeric', month: 'long', year: 'numeric', timeZone: appTimezone})}
          </div>
        )
      }
      return (
        <div className="office-booked-details-description">
          {new Date(selectedOptions.startDate * 1000).toLocaleString(lang, {day: 'numeric', month: 'long', timeZone: appTimezone})} - {new Date(selectedOptions.endDate * 1000).toLocaleString(lang, {day: 'numeric', month: 'long', year: 'numeric', timeZone: appTimezone})}
        </div>
      )
    }
    return null;
  }

  getWorkingHours (hours) {

    if (typeof hours !== 'undefined') {
      let hoursStart  = hours.from.hour + ':' + hours.from.minute;
      let hoursEnd    = hours.to.hour + ':' + hours.to.minute;
      return hoursStart + ' - ' + hoursEnd;
    }
    return null;
  }

  render() {
    const { details , trans, appTimezone} = this.props;
    let amenitiesList = details.toJS().amenities
        .filter(amenity => amenity.bookable)
        .map(amenity => (
      <div className={"amenity-available"} key={amenity.id}>
        <div className="label">{amenity.name}</div>
      </div>
    ));


    let equipmentsList = details.toJS().equipments
        .filter(equipment => equipment.bookable)
        .map(equipment => {
      return (
        <div key={equipment.id}>
          <div className={"amenity-available"}>{equipment.name}
          </div>
        </div>
      );
    });

    let rulesList = details.toJS().houseRules.map(rule =>

      (
        <div
          key={rule.id + rule.fileUrl}
          className={classnames({'attachments': rule.fileUrl, 'rule-available': !rule.fileUrl, 'rule-unavailable': rule.fileUrl})}>
          {_.capitalize(rule.name)}
          {rule.fileUrl ? <div className=""><a target="_blank" data-label={_.get(trans, 'chat.view', 'chat.view')} className="office-rules attachments" href={rule.fileUrl}> </a></div> : ''}
        </div>
      )
    );

    let featuresList = details.toJS().features.map(feature => (
        <div className="amenity-available" key={feature.id}>
          <div className="label">{feature.title}</div>
        </div>
    ));
    const weekDaysMap = _.get(trans, 'location.weekDaysMap', []);
    const weekDays = _.get(details.toJS(), 'weekDays', null);
    const checkedOutDayOfWeek = _.get(details.toJS(), 'checkedOutDayOfWeek', null);
    const checkedOutString = _.get(trans, 'whiteLabel.checkedOut', '(checked out)))');

    return (
      <div className="office-booked">
        <div className="office-booked-list">
          <div className="office-booked-details office-booked-details-with-titles">
              {this.getOfficeType()}
              {this.getCalendarLink()}
          </div>
          <div className="office-booked-details office-booked-details-with-titles">
              <div className="office-booked-title">{trans.bookingPage.bookingPeriod}</div>
              { this.getBookingPeriod({'startDate': details.toJS().fromDate, 'endDate': details.toJS().toDate}, appTimezone) }
              <div className="office-booked-title">{trans.bookingPage.chosenWorkingDays}</div>
              <div className="office-booked-details-description">{getWorkDays(weekDays, weekDaysMap, checkedOutDayOfWeek, checkedOutString)}</div>
              <div className="office-booked-title">{trans.bookingPage.chosenWorkingHours}</div>
              <div className="spacer-5"></div>
              {this.getWorkingHours(details.toJS().hours)}
              <div className="office-booked-title">{rulesList.length > 0 && trans.bookingPage.houseRules}</div>
              <div className="spacer-5"></div>
              <div className="booking-office-rules">{ rulesList }</div>
          </div>
          <div className="booking-office-services">
            {amenitiesList.length > 0 && (
                <>
                  <div className="office-booked-title">
                    {trans.bookingPage.bookingAmenitiesAndServices}
                  </div>
                  <div className="spacer-5"></div>
                </>
            )}

            { amenitiesList }
            { equipmentsList.length > 0 ? <div className="office-booked-title">{trans.bookingPage.bookingEquipments}</div> : null }
            { equipmentsList.length > 0 ? <div className="spacer-5"></div> : null}
            { equipmentsList }
            { featuresList.length > 0 ? <div className="office-booked-title">{trans.whiteLabel.features}</div> : null}
            { featuresList.length > 0 ? <div className="spacer-5"></div> : null}
            { featuresList }
          </div>
        </div>
      </div>
    )
  }
};

OfficeBooked.propTypes = {
  details: PropTypes.object.isRequired,
};

export default OfficeBooked;
