import { connect } from 'react-redux';
import SidebarContent from './SidebarContent';
import './index.scss';
import {
  selectedDateSelector,
  selectedProductIdSelector,
  selectedProductTypeSelector
} from '../../../../selectors/whiteLabel/booking';
import { makeBooking, bookingValidate, setSelectedDate } from '../../../../actions/whiteLabel/booking';
import { fetchStaffMembers } from '../../../../actions/whiteLabel/staff';

const mapStateToProps = state => {
  return {
    translations: state.translations,
    officeOpenDaysAndHours: state.location.get('details').get('openingHours'),
    selectDateRange: selectedDateSelector(state),
    amenitiesIds: state.location.get('details').get('amenityIds'),
    selectedProductTypeId: selectedProductTypeSelector(state),
    selectedProductId: selectedProductIdSelector(state),
    officeId: state.location.get('details').get('id'),
    officeTimezone: state.location.get('details').get('timezone'),
    completeBookingLoader: state.whiteLabel.booking.get('completeBookingLoader'),
    ssoProvider: state.global.get('ssoProvider'),
  };
};

const mapDispatchProps = dispatch => {
  return {
    bookingValidate: data => dispatch(bookingValidate(data)),
    makeBooking: data => dispatch(makeBooking(data)),
    fetchStaffMembers: (officeId, date) => dispatch(fetchStaffMembers(officeId, date)),
    setSelectedDate: (from, to) => dispatch(setSelectedDate(from, to)),
  };
};

export default connect(mapStateToProps, mapDispatchProps)(SidebarContent);
