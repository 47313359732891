import api from '../../components/dashboard/utils/api';
import { verticalSelector } from '../../selectors/vertical-selector';

/*
 * Constants
 */
export const SET_COMPANY = 'SET_COMPANY';
export const SET_COMPANY_LOGO = 'SET_COMPANY_LOGO';
export const SET_JOBS = 'SET_JOBS';
export const SET_TAGS = 'SET_TAGS';
export const SET_PRODUCT_TYPES = 'SET_PRODUCT_TYPES';
export const SET_NEW_COMPANY_PROFILE_IS_LOADING = 'SET_NEW_COMPANY_PROFILE_IS_LOADING';

/*
 * Sync Actions
 */
export const setCompany = company => ({
  type: SET_COMPANY,
  company,
});

export const setCompanyLogo = companyLogo => ({
  type: SET_COMPANY_LOGO,
  companyLogo,
});

export const setJobs = jobs => ({
  type: SET_JOBS,
  jobs,
});

export const setTags = tags => ({
  type: SET_TAGS,
  tags,
});

export const setProductTypes = productTypes => ({
  type: SET_PRODUCT_TYPES,
  productTypes,
});

export const setNewCompanyProfileIsLoading = isLoading => ({
  type: SET_NEW_COMPANY_PROFILE_IS_LOADING,
  isLoading,
});

/*
 * Async Actions
 */
export const fetchNewCompanyProfile = () => (dispatch, getState) => {
  const selectedVertical = verticalSelector(getState());
  const url = selectedVertical ? `/recommendation/company?vertical=${selectedVertical}` : '/recommendation/company';
  dispatch(setNewCompanyProfileIsLoading(true));
  api
    .get(url)
    .then(({ data = {} }) => {
      dispatch(setCompany(data.company));
      dispatch(setJobs(data.jobs));
      dispatch(setProductTypes(data.productTypes));
      dispatch(setCompanyLogo(data.companyLogo));
      dispatch(setTags(data.tags));
    })
    .catch(() => {})
    .finally(() => {
      dispatch(setNewCompanyProfileIsLoading(false));
    });
};
