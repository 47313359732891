import Immutable from 'immutable';
import { SET_COMMUNITIES_PAGE_TOAST_MESSAGE, SET_COMMUNITY_PAGE_TOAST_MESSAGE } from '../../actions/community/community-toasts';

const initialState = Immutable.Map({
  communityPageToastMessage: null,
  communitiesPageToastMessage: null,
});

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_COMMUNITY_PAGE_TOAST_MESSAGE:
      return state.setIn(['communityPageToastMessage'], action.communityPageToastMessage);
    case SET_COMMUNITIES_PAGE_TOAST_MESSAGE:
      return state.setIn(['communitiesPageToastMessage'], action.communitiesPageToastMessage);
    default:
      return state;
  }
};
