import React from 'react';
import PropTypes from 'prop-types';
import addLoader from '../../../dashboard/utils/add-loader';

const LoadMore = ({ fetchPopularCommunities, trans }) => (
  <div className="all-communities__load_more">
    <button
      id="all-communities-load-more"
      type="button"
      className="dashboard-button dashboard-button--border"
      onClick={fetchPopularCommunities}
    >
      {trans.loadMore}
    </button>
  </div>
);

LoadMore.propTypes = {
  fetchPopularCommunities: PropTypes.func.isRequired,
};

export default addLoader(LoadMore);
