import _ from 'lodash';
import api from '../../components/community/utils/api';
/*
 * Constants
 */
export const SET_COMMUNITY_COMMENT_FORM_IS_LOADING = 'SET_COMMUNITY_COMMENT_FORM_IS_LOADING';
export const SET_COMMUNITY_COMMENT_REPLY_IS_LOADING = 'SET_COMMUNITY_COMMENT_REPLY_IS_LOADING';
export const SET_COMMUNITY_COMMENT_FORM_ERRORS = 'SET_COMMUNITY_COMMENT_FORM_ERRORS';
export const SET_COMMUNITY_COMMENT_REPLY_FORM_ERRORS = 'SET_COMMUNITY_COMMENT_REPLY_FORM_ERRORS';
export const SET_COMMUNITY_NEW_COMMENT = 'SET_COMMUNITY_NEW_COMMENT';
/*
 * Sync Actions
 */

export const setCommunityCommentFormIsLoading = isLoading => ({
  type: SET_COMMUNITY_COMMENT_FORM_IS_LOADING,
  isLoading,
});

export const setCommunityCommentReplyIsLoading = isReplyLoading => ({
  type: SET_COMMUNITY_COMMENT_REPLY_IS_LOADING,
  isReplyLoading,
});

export const addNewComment = comment => ({
  type: SET_COMMUNITY_NEW_COMMENT,
  comment,
});

export const setFormError = (errors, postId) => ({
  type: SET_COMMUNITY_COMMENT_FORM_ERRORS,
  errors,
  postId,
});

export const setReplyFormError = (replyErrors, formUniqueIdentifier) => ({
  type: SET_COMMUNITY_COMMENT_REPLY_FORM_ERRORS,
  replyErrors,
  formUniqueIdentifier,
});

/*
 * Async Actions
 */

export const saveComment = data => dispatch => {
  const isReplyForm = _.get(data, 'isReplyForm', false);
  if (isReplyForm) {
    dispatch(setCommunityCommentReplyIsLoading(true));
  } else {
    dispatch(setCommunityCommentFormIsLoading(true));
  }
  const postId = _.get(data, 'postId', null);
  const formUniqueIdentifier = _.get(data, 'fieldRef', null);
  dispatch(setFormError({}, postId));
  dispatch(setReplyFormError({}, formUniqueIdentifier));

  return new Promise((resolve, reject) => {
    api
      .post(`comments/create`, data)
      .then(response => {
        dispatch(setCommunityCommentFormIsLoading(false));
        dispatch(setCommunityCommentReplyIsLoading(false));
        dispatch(addNewComment(response.data.data));
        resolve(true);
      })
      .catch(err => {
        if (err.response.data.error) {
          dispatch(setCommunityCommentFormIsLoading(false));
          dispatch(setCommunityCommentReplyIsLoading(false));
          if (isReplyForm) {
            dispatch(setReplyFormError(err.response.data.error, formUniqueIdentifier));
          } else {
            dispatch(setFormError(err.response.data.error, postId));
          }
          const resp = _.get(err, 'response', null);
          reject(resp);
        }
      });
  });
};

export const clearErrors = () => dispatch => {
  dispatch(setFormError([]));
};
