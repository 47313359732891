import _ from 'lodash';
import api from '../../components/dashboard/utils/api';
import { verticalSelector } from '../../selectors/vertical-selector';
import { mediaFilterSelector } from '../../selectors/dashboard/media-filters';
import { loadMoreOffsetSelector, newsCountSelector } from '../../selectors/dashboard/news';
import mapNewsArticle from '../../components/dashboard/utils/map-news-article';

/*
 * Constants
 */
export const SET_NEWS = 'SET_NEWS';
export const SET_NEWS_IS_LOADING = 'SET_NEWS_IS_LOADING';
export const SET_NEWS_IS_LOADING_MORE = 'SET_NEWS_IS_LOADING_MORE';
export const SET_LOAD_MORE_OFFSET = 'SET_LOAD_MORE_OFFSET';
export const INCREMENT_LOAD_MORE_OFFSET = 'INCREMENT_LOAD_MORE_OFFSET';
export const ADD_NEWS = 'ADD_NEWS';
export const SET_HAS_MORE = 'SET_HAS_MORE';

/*
 * Sync Actions
 */
export const setNews = news => ({
  type: SET_NEWS,
  news,
});

export const addNews = news => ({
  type: ADD_NEWS,
  news,
});

export const setLoadMoreOffset = loadMoreOffset => ({
  type: SET_LOAD_MORE_OFFSET,
  loadMoreOffset,
});

export const incrementLoadMoreOffset = () => ({
  type: INCREMENT_LOAD_MORE_OFFSET,
});

export const setNewsIsLoading = isLoading => ({
  type: SET_NEWS_IS_LOADING,
  isLoading,
});

export const setNewsIsLoadingMore = isLoadingMore => ({
  type: SET_NEWS_IS_LOADING_MORE,
  isLoadingMore,
});

export const setHasMore = hasMore => ({
  type: SET_HAS_MORE,
  hasMore,
});

export const resetNews = () => dispatch => {
  dispatch(setLoadMoreOffset(0));
  dispatch(setNews([]));
  dispatch(setHasMore(true));
};

/*
 * Async Actions
 */
export const fetchNews = () => (dispatch, getState) => {
  const selectedVertical = verticalSelector(getState());
  const selectedFilter = mediaFilterSelector(getState());
  const loadMoreOffset = loadMoreOffsetSelector(getState());
  const newsCount = newsCountSelector(getState());

  if (!newsCount) {
    dispatch(setNewsIsLoading(true));
  } else {
    dispatch(setNewsIsLoadingMore(true));
  }
  const mediaApiUrl = `media/index/vertical/${selectedVertical}/type/${selectedFilter}/offset/${loadMoreOffset}`;
  api
    .get(mediaApiUrl)
    .then(({ data = [] }) => {
      if (data.data) {
        dispatch(addNews(data.data.map(mapNewsArticle)));
        dispatch(incrementLoadMoreOffset());
        if (!data.hasMore) {
          dispatch(setHasMore(false));
        }
      }
    })
    .catch(() => {})
    .finally(() => {
      dispatch(setNewsIsLoading(false));
      dispatch(setNewsIsLoadingMore(false));
    });
};

export const fetchFirstNews = () => (dispatch, getState) => {
  const selectedVertical = verticalSelector(getState());
  const selectedFilter = mediaFilterSelector(getState());
  dispatch(setNewsIsLoading(true));
  const mediaApiUrl = `media/index/vertical/${selectedVertical}/type/${selectedFilter}/offset/0`;
  api
    .get(mediaApiUrl)
    .then(({ data = [] }) => {
      if (data.data) {
        dispatch(setNews(data.data.map(mapNewsArticle)));
        dispatch(incrementLoadMoreOffset());
        if (!data.hasMore) {
          dispatch(setHasMore(false));
        }
      }
    })
    .catch(() => {})
    .finally(() => {
      dispatch(setNewsIsLoading(false));
    });
};
