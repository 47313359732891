import popularCommunities from './popular-communities';
import heroCommunities from './hero-communities';
import createCommunity from './create-community';
import createPost from './create-post';
import createComment from './create-comment';
import editComment from './edit-comment';
import updateCommunity from './update-community';
import deleteCommunity from './delete-community';
import joinCommunity from './join-community';
import leaveCommunity from './leave-community';
import communityPage from './community-page';
import communityUploads from './community-uploads';
import communityPosts from './community-post';
import inviteMembers from './invite-members-community';
import communityToasts from './community-toasts';
import communityComments from './comments';
import allMembers from './all-members';
import communityModals from './community-modals';

export default {
  communityPage,
  heroCommunities,
  popularCommunities,
  createCommunity,
  createPost,
  createComment,
  editComment,
  updateCommunity,
  deleteCommunity,
  joinCommunity,
  leaveCommunity,
  communityUploads,
  communityComments,
  communityPosts,
  inviteMembers,
  communityToasts,
  allMembers,
  communityModals,
};
