import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import StarRating from '../starRating/starRating';

class FullBookingInfo extends React.Component {
  render() {
    return (
      <div className={`bookedOffice officeBox ${this.props.className}`}>
        <div className="bookedOffice-details">
          <div className="officeBox-title items-style1">{this.props.item.get('title')}</div>
          <div className="officeBox-infoSmall">
            {this.props.item.getIn(['rating', 'votes'])}{' '}
            <StarRating initialRate={this.props.item.getIn(['rating', 'average'])} theme="theme-yellow-small" readonly />
          </div>
          <div className="officeBox-infoSmall">0.25km away</div>
          <div className="officeBox-infoSection">
            <div className="officeBox-infoTitle">Active booking period</div>
            <div className="officeBox-infoData">{this.props.item.getIn(['bookedData', 'string'])}</div>
          </div>
          <div className="officeBox-infoSection">
            <div className="officeBox-infoTitle">Your Active Chosen Working Hours</div>
            <div className="officeBox-infoData">08:30 - 17:30</div>
          </div>
          <div className="officeBox-infoSection">
            <div className="officeBox-infoTitle">Active Booking Fee</div>
            <div className="officeBox-infoData">190.15€</div>
          </div>
          <div className="officeBox-infoSection">
            <div className="officeBox-infoTitle">Past Total Paid</div>
            <div className="officeBox-infoData">1,890.16€</div>
          </div>
        </div>
      </div>
    );
  }
}

FullBookingInfo.propTypes = {
  /**
   * Immutable map containing an office description
   * @param item
   * @type Immutable.Map
   */
  item: PropTypes.object.isRequired,
  /**
   * Flag which determines if connections are displayed or not
   * @param showConnections
   * @type boolean
   */
  showConnections: PropTypes.bool,
};

export default FullBookingInfo;
