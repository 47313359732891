const countState = (state = 0, action) => {
  switch (action.type) {
    case 'COUNT':
      return state + 1;

    default:
      return state;
  }
};

export default countState;
