import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const AudienceItem = props => {
  const { name, isChecked, handleOptionChange, label, extraLabel, interestCount, isCircle } = props;
  return (
    <div className={classnames('search-radio-group', { circled: isCircle })}>
      <label>
        <input
          type={isCircle ? 'radio' : 'checkbox'}
          name={name}
          checked={isChecked}
          onChange={() => {
            handleOptionChange();
          }}
          className="radio-button"
        />
        <span className="community-audience__label">{label}</span>
        {interestCount === 3 ? <span id="community-audience__label-extra" className="community-audience__label"> ({extraLabel})</span> : null}
      </label>
    </div>
  );
};

AudienceItem.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  isChecked: PropTypes.bool,
  isCircle: PropTypes.bool,
  handleOptionChange: PropTypes.func,
};

export default AudienceItem;
