import { combineReducers } from 'redux';

// stores
import account from './account';
import addBooking from './addBooking';
import booking from './booking';
import connections from './connections';
import count from './count';
import demo from './demoButton';
import directChat from './directChat';
import events from './events';
import filters from './filters';
import global from './global';
import home from './home';
import location from './location';
import map from './map';
import modals from './modals';
import notifications from './notifications';
import offices from './offices';
import feedback from './feedback';
import translations from './translations';
import dashboard from './dashboard';
import heroProfile from './heroProfile';
import community from './community';
import auth from './auth';
import meetingAttendees from './meetingAttendees';
import meeting from './meeting';
import vouchers from './accountPaymentSettings/vouchers';
import whiteLabel from './whitelabel';

const demoApp = combineReducers({
  translations,
  auth,
  account,
  addBooking,
  booking,
  connections,
  count,
  demo,
  directChat,
  events,
  filters,
  global,
  home,
  location,
  map,
  modals,
  notifications,
  offices,
  feedback,
  ...dashboard,
  heroProfile,
  ...community,
  meetingAttendees,
  meeting,
  vouchers,
  whiteLabel,
});

export default demoApp;
