import api from '../../components/community/utils/api';

/*
 * Constants
 */
export const SET_COMMUNITY_FORM_IS_LOADING = 'SET_COMMUNITY_FORM_IS_LOADING';
export const SET_COMMUNITY_FORM_ERRORS = 'SET_COMMUNITY_FORM_ERRORS';
/*
 * Sync Actions
 */

export const setCommunityFormIsLoading = isLoading => ({
  type: SET_COMMUNITY_FORM_IS_LOADING,
  isLoading,
});

export const setFormError = errors => ({
  type: SET_COMMUNITY_FORM_ERRORS,
  errors,
});

/*
 * Async Actions
 */

export const saveCommunity = data => dispatch => {
  dispatch(setCommunityFormIsLoading(true));
  const formData = new FormData();
  for (const key in data) {
    if (data[key] === null) {
      continue;
    }
    formData.append(key, data[key]);
  }

  return new Promise(resolve => {
    api
      .post(`create`, formData)
      .then(response => {
        dispatch(setCommunityFormIsLoading(false));
        resolve(`/community/${response.data.data.slug}`);
      })
      .catch(({ response }) => {
        if (response.data.error) {
          dispatch(setFormError(response.data.error));
          dispatch(setCommunityFormIsLoading(false));
        }
      });
  });
};

export const clearErrors = () => dispatch => {
  dispatch(setFormError([]));
};
