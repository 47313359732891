import { useState } from 'react';

const useHashMap = () => {
  const [data, setData] = useState({});

  const getKeyValue = key => {
    return data[key];
  };

  const setKeyValue = (key, value) => {
    setData({ ...data, [key]: value });
  };

  const deleteKeyValue = key => {
    setData({ ...data, [key]: undefined });
  };

  const dataArr = Object.values(data).filter(x => x !== undefined);

  const count = dataArr.length;

  return [getKeyValue, setKeyValue, deleteKeyValue, count, dataArr];
};

export default useHashMap;
