import _ from 'lodash';
import api from '../../components/community/utils/api';

/*
 * Constants
 */
export const SET_SEARCHED_INVITE_HEROES = 'SET_SEARCHED_INVITE_HEROES';
export const SET_MORE_SEARCHED_INVITE_HEROES = 'SET_MORE_SEARCHED_INVITE_HEROES';
export const SET_MORE_SEARCHED_INVITE_HEROES_IS_LOADING = 'SET_MORE_SEARCHED_INVITE_HEROES_IS_LOADING';
export const SET_SEARCHED_INVITE_HEROES_HAS_MORE = 'SET_SEARCHED_INVITE_HEROES_HAS_MORE';
export const SET_INVITE_FORM_IS_SUBMITTED = 'SET_INVITE_FORM_IS_SUBMITTED';
export const SET_SEARCHED_INVITE_HEROES_IS_LOADING = 'SET_SEARCHED_INVITE_HEROES_IS_LOADING';

/*
 * Sync Actions
 */
export const setSearchedInviteHeroes = searchedHeroes => ({
  type: SET_SEARCHED_INVITE_HEROES,
  searchedHeroes,
});

export const setMoreSearchedInviteHeroes = searchedHeroes => ({
  type: SET_MORE_SEARCHED_INVITE_HEROES,
  searchedHeroes,
});

export const setMoreSearchedInviteHeroesIsLoading = loadMoreIsLoading => ({
  type: SET_MORE_SEARCHED_INVITE_HEROES_IS_LOADING,
  loadMoreIsLoading,
});

export const setSearchedInviteHeroesHasMore = hasMore => ({
  type: SET_SEARCHED_INVITE_HEROES_HAS_MORE,
  hasMore,
});

export const setInviteFormIsSubmitted = isSubmitted => ({
  type: SET_INVITE_FORM_IS_SUBMITTED,
  isSubmitted,
});

export const setSearchedInviteHeroesIsLoading = isLoading => ({
  type: SET_SEARCHED_INVITE_HEROES_IS_LOADING,
  isLoading,
});

/*
 * Async Actions
 */
export const fetchSearchedInviteHeroes = (communityId, searchInput, offset) => dispatch => {
  dispatch(setSearchedInviteHeroesIsLoading(true));
  api
    .get(`/inviteHeroesSearch?communityId=${communityId}&offset=${offset}&searchInput=${searchInput}`)
    .then(response => {
      if (response && response.data.data) {
        dispatch(setSearchedInviteHeroes(_.get(response, 'data.data', [])));
        dispatch(setSearchedInviteHeroesHasMore(_.get(response, 'data.hasMore', false)));
      }
    })
    .catch(() => {})
    .finally(() => {
      dispatch(setSearchedInviteHeroesIsLoading(false));
    });
};

export const fetchMoreSearchedInviteHeroes = (communityId, searchInput, offset) => dispatch => {
  dispatch(setMoreSearchedInviteHeroesIsLoading(true));
  api
    .get(`/inviteHeroesSearch?communityId=${communityId}&offset=${offset}&searchInput=${searchInput}`)
    .then(response => {
      if (response && response.data.data) {
        dispatch(setMoreSearchedInviteHeroes(_.get(response, 'data.data', [])));
        dispatch(setSearchedInviteHeroesHasMore(_.get(response, 'data.hasMore', false)));
      }
    })
    .catch(() => {})
    .finally(() => {
      dispatch(setMoreSearchedInviteHeroesIsLoading(false));
    });
};

export const inviteHeroes = (communityId, heroes, guests) => dispatch => {
  dispatch(setInviteFormIsSubmitted(true));
  return new Promise((resolve, reject) => {
    api
      .post(`/inviteMembers`, { communityId, heroes, guests })
      .then(() => {
        resolve();
      })
      .catch(({ response }) => {
        reject();
      })
      .finally(() => {
        dispatch(setInviteFormIsSubmitted(false));
      });
  });
};
