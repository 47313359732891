import React from 'react';
import PropTypes from 'prop-types';

const OfficeServices = (props) => {

  let servicesData = props.servicesData;
  let availableServices = props.services;
  let renderServices = [];

  for(let i=0; i< servicesData.length; i++)
  {
    for (let j = 0; j < availableServices.length; j++) {
        if(servicesData[i].id === availableServices[j].id){
          renderServices.push(availableServices[j]);
        }
    }
  }
  
  let servicesList = renderServices.map(service =>
    {
      let subLabel = 'Complementary';
      if(service.priceType === 'time')
      {
        if((service.perMonth !== undefined && service.perMonth !== null && service.perMonth > 0) || (service.perWeek !== undefined && service.perWeek !== null && service.perWeek > 0) || (service.perHour !== undefined && service.perHour !== null && service.perHour > 0) || (service.perDay !== undefined && service.perDay !== null && service.perDay > 0))
        {
          let subLabelItems = [];
          if(service.perHour !== undefined && service.perHour !== null && service.perHour > 0)
          {
            subLabelItems.push(<div className="subLabelItem" key={'sh'+service.id}>{service.perHour.toFixed(2).toString().replace(".", window.__env.decimalSeparator)}€/hour</div>);
          }
          if(service.perDay !== undefined && service.perDay !== null && service.perDay > 0)
          {
            subLabelItems.push(<div className="subLabelItem" key={'sd'+service.id}>{service.perDay.toFixed(2).toString().replace(".", window.__env.decimalSeparator)}€/day</div>);
          }
          if(service.perWeek !== undefined && service.perWeek !== null && service.perWeek > 0)
          {
            subLabelItems.push(<div className="subLabelItem" key={'sw'+service.id}>{service.perWeek.toFixed(2).toString().replace(".", window.__env.decimalSeparator)}€/week</div>);
          }
          if(service.perMonth !== undefined && service.perMonth !== null && service.perMonth > 0)
          {
            subLabelItems.push(<div className="subLabelItem" key={'sm'+service.id}>{service.perMonth.toFixed(2).toString().replace(".", window.__env.decimalSeparator)}€/month</div>);
          }
          subLabel = <div>{subLabelItems}</div>;
        }
      }
      else
      {
        if(service.priceUnit !== undefined && service.priceUnit !== null && service.priceUnit > 0)
        {
          subLabel = service.priceUnit.toFixed(2).toString().replace(".", window.__env.decimalSeparator) + '€/' + service.priceType;
        }
      }

      return (
        <div
          key={service.id}
          className="service-available">
            {service.name}
            <div className="office-services-fieldDescription">{subLabel}</div>
        </div>
      );
    });

  if(servicesList.length > 0) {
    return (
      <div className="office-services">
        <div className="office-services-title">{props.trans.location.services}</div>
        <div className="office-services-list">
          {servicesList}
        </div>
      </div>
    )
  } else {
    return <div></div>
  }
};

OfficeServices.propTypes = {
  services: PropTypes.array.isRequired,
};

export default OfficeServices;
