import React, { Component } from 'react';
import _ from 'lodash';
import Truncate from 'react-truncate';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import BookingRecommendationFooter from './BookingRecommendationFooter';
import BookingRecommendationModal from '../../common/modals/booking-recommendation-modal';
import imagesCard from '../../../../assets/svg/dashboard/images-card.png';
import buildLocationUrl from '../../../../utils/buildLocationUrl';
import premiumPartnerIcon from '../../../../assets/svg/map/premium-partner.svg';
import healthSafetyMeasuresIcon from '../../../../assets/svg/health-measures/health-safety-measures.svg';
import temperatureControlIcon from '../../../../assets/svg/health-measures/temperature-control-in-place.svg';

class BookingRecommendation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };

    this.showModal = this.showModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleOnClick = this.handleOnClick.bind(this);
  }

  componentDidMount() {
    const { fetchBookingRecommendation } = this.props;
    fetchBookingRecommendation();
  }

  showModal() {
    this.setState({ showModal: true });
  }

  buildBookingRecommendationModal() {
    const { showModal } = this.state;
    const { bookingRecommendation, images, translations } = this.props;
    const description = _.get(bookingRecommendation, 'description', '');
    const name = _.get(bookingRecommendation, 'name', '');
    if (showModal) {
      return (
        <BookingRecommendationModal
          closeModal={this.closeModal}
          images={images}
          officeTitle={name}
          officeDescription={description}
          translations={translations}
          handleOnClick={this.handleOnClick}
        />
      );
    }
    return null;
  }

  buildImageContainer() {
    const { bookingRecommendation, translations } = this.props;
    const imageCount = _.get(bookingRecommendation, 'imageCount', '');
    const tileImage = _.get(bookingRecommendation, 'tileImage.url', []);
    if (!imageCount) {
      return (
        <div className="booking-recommendation__image-container image-container-no-photos">
          <span className="booking-recommendation__overlay-text overlay-text-no-photos">
            {_.get(translations, 'noPhotos', 'No photos available')}
          </span>
        </div>
      );
    }
    return (
      <div className="booking-recommendation__image-container" onClick={this.showModal}>
        <div className="booking-recommendation-image__info-container">
          <div className="booking-recommendation-image__info">
            <img className="booking-recommendation-image__info-image" src={imagesCard} alt="Card Images" />
            <span className="booking-recommendation-image__info-count">{imageCount}</span>
          </div>
          <span className="booking-recommendation__overlay-text">{_.get(translations, 'viewPhotos', 'View photos')}</span>
        </div>
        <img src={tileImage} alt="Booking" className="booking-recommendation-image" />
      </div>
    );
  }

  closeModal() {
    this.setState({ showModal: false });
  }

  handleOnClick() {
    const { bookingRecommendation } = this.props;
    window.location.href = buildLocationUrl(bookingRecommendation);
  }

  render() {
    const { bookingRecommendation, translations } = this.props;
    const name = _.get(bookingRecommendation, 'name', '');
    const addressLineOne = _.get(bookingRecommendation, 'addressLineOne', '');
    const addressLineTwo = _.get(bookingRecommendation, 'addressLineTwo', '');
    const footerData = _.get(bookingRecommendation, 'products', []);
    const isPremiumPartner = _.get(bookingRecommendation, 'premiumSupplier', 0);
    const temperatureControlStamp = _.get(bookingRecommendation, 'temperatureControlStamp', false);
    const healthMeasuresStamp = _.get(bookingRecommendation, 'healthMeasuresStamp', false);
    return (
      <div className="booking-recommendation__container">
        {isPremiumPartner ? (
          <div className="premium-partner__container">
            <img
              src={premiumPartnerIcon}
              alt={_.get(translations, 'premiumPartner', 'Premium partner')}
              className="premium-partner__icon"
            />
            <div className="health__stamps-tooltip health__stamps-tooltip--premium">
              {_.get(translations, 'premiumPartner', 'Premium Partner')}
            </div>
          </div>
        ) : null}
        {this.buildImageContainer()}
        <div className="health__stamps-container">
          {healthMeasuresStamp ? (
            <div className="health__stamps-container--health">
              <img
                src={healthSafetyMeasuresIcon}
                alt={_.get(translations, 'health_safety_measures_image_alt', 'Health Measures')}
                className="health__stamp-icon"
              />
              <div className="health__stamps-tooltip health__stamps-tooltip--health">
                {_.get(translations, 'health_safety_measures', 'Health Safety Measures')}
              </div>
            </div>
          ) : null}
          {temperatureControlStamp ? (
            <div className="health__stamps-container--temperature">
              <img
                src={temperatureControlIcon}
                alt={_.get(translations, 'temperature_control_points_image_alt', 'Temperature control')}
                className="health__stamp-icon"
              />
              <div className="health__stamps-tooltip health__stamps-tooltip--temperature">
                {_.get(translations, 'temperature_control_points', 'Temperature control points')}
              </div>
            </div>
          ) : null}
        </div>
        <div className="booking-recommendation__office">
          <div className="booking-recommendation__office-title">{name}</div>
          <div className="booking-recommendation__office-address">
            <div className="office-address-line1">
              <Truncate lines={1} ellipsis={<>...</>}>
                {addressLineOne}
              </Truncate>
            </div>
            <div className="office-address-line2">{addressLineTwo}</div>
          </div>
        </div>
        <button
          type="button"
          className="dashboard-button dashboard-button--border booking-recommendation__button"
          onClick={this.handleOnClick}
        >
          {_.get(translations, 'bookOfficeSpace', 'Book Office Space')}
        </button>
        <div className="booking-recommendation__office-space"> {_.get(translations, 'officeSpaces', 'Office spaces')}</div>
        <BookingRecommendationFooter footerData={footerData} url={buildLocationUrl(bookingRecommendation)} />
        {this.buildBookingRecommendationModal()}
      </div>
    );
  }
}

BookingRecommendation.propTypes = {
  fetchBookingRecommendation: PropTypes.func.isRequired,
  bookingRecommendation: PropTypes.shape({}).isRequired,
  images: PropTypes.arrayOf(PropTypes.shape([])).isRequired,
};

export default withRouter(BookingRecommendation);
