import { connect } from 'react-redux';
import _ from 'lodash';
import {
  coCreationProjectsSelector,
  activeIdSelector,
  coCreationProjectsIsLoadingSelector,
} from '../../../../selectors/dashboard/co-creation-projects';
import { fetchCoCreationProjects, setActiveId } from '../../../../actions/dashboard/co-creation-projects';
import toJS from '../../utils/to-js';
import addLoader from '../../utils/add-loader';
import CoCreationProjects from './CoCreationProjects';
import { translations } from '../../../../selectors/translations';

const mapStateToProps = state => ({
  coCreationProjects: coCreationProjectsSelector(state),
  activeId: activeIdSelector(state),
  isLoading: coCreationProjectsIsLoadingSelector(state),
  translations: _.get(translations(state), 'dashboard', {}),
});

const mapDispatchToProps = dispatch => ({
  fetchCoCreationProjects: () => dispatch(fetchCoCreationProjects()),
  setActiveId: id => dispatch(setActiveId(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(addLoader(toJS(CoCreationProjects), true));
