import Immutable from 'immutable';
import moment from 'moment';
import _ from 'lodash';
import {
  SET_COMMUNITY_POST,
  SET_MORE_COMMUNITY_POSTS,
  SET_POST_IS_LOADING,
  SET_POSTS_HAS_MORE,
  SET_LIKE_ON_POST,
} from '../../actions/community/community-posts';
import { DELETE_COMMUNITY_COMMENT, SET_COMMUNITY_POST_COMMENTS } from '../../actions/community/comments';
import { SET_COMMUNITY_NEW_COMMENT } from '../../actions/community/create-comment';
import { SET_COMMUNITY_UPDATED_COMMENT } from '../../actions/community/edit-comment';

const findPostAddComment = (statePosts, postId, comments, isFirst, parentCommentId) => {
  const posts = statePosts.slice();
  for (let i = 0; i < posts.length; i += 1) {
    const post = { ...posts[i] };
    if (post.id === postId) {
      if (parentCommentId) {
        const postComments = post.comments.slice();
        for (let j = 0; j < postComments.length; j += 1) {
          if (postComments[j].id === parentCommentId) {
            if (!_.isArray(postComments[j].replies)) {
              postComments[j].replies = [];
            }
            postComments[j].replies = postComments[j].replies.concat(comments);
            break;
          }
        }

        post.comments = postComments;
      } else if (isFirst) {
        post.comments = comments.concat(post.comments);
      } else {
        post.comments = post.comments.concat(comments);
      }
      post.numberOfComments += comments.length;
      posts[i] = post;
      break;
    }
  }
  posts.sort((a, b) => moment(a.created_at).diff(moment(b.created_at)));

  return _.uniqBy(_.cloneDeep(posts), 'id');
};

const findPostAndUpdateComment = (statePosts, postId, comment) => {
  const posts = statePosts.slice();
  for (let i = 0; i < posts.length; i += 1) {
    const post = { ...posts[i] };
    if (post.id === postId) {
      for (let j = 0; j < post.comments.length; j += 1) {
        const commentToUpdate = { ...post.comments[j] };
        if (comment.parent_comment_id !== null && comment.parent_comment_id === commentToUpdate.id) {
          // parent found, now find the reply
          for (let k = 0; k < commentToUpdate.replies.length; k += 1) {
            if (commentToUpdate.replies[k].id === comment.id) {
              post.comments[j].replies[k].content = comment.content;
              posts[i] = post;
              break;
            }
          }
        }
        if (commentToUpdate.id === comment.id) {
          post.comments[j].content = comment.content;
          posts[i] = post;
          break;
        }
      }
    }
  }

  return _.cloneDeep(posts);
};

const initialState = Immutable.Map({
  communityPosts: [],
  isLoading: false,
  hasMorePosts: false,
});

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_COMMUNITY_POST:
      return state.setIn(['communityPosts'], action.communityPosts);
    case SET_MORE_COMMUNITY_POSTS:
      return state.setIn(['communityPosts'], state.get('communityPosts', []).concat(action.communityPosts));
    case SET_POST_IS_LOADING:
      return state.setIn(['isLoading'], action.isLoading);
    case SET_POSTS_HAS_MORE:
      return state.setIn(['hasMorePosts'], action.hasMorePosts);
    case SET_LIKE_ON_POST:
      const index = state.get('communityPosts').findIndex(post => post.id === action.likeOnPost);
      return state
        .updateIn(['communityPosts', index, 'liked'], liked => true)
        .updateIn(['communityPosts', index, 'numberOfLikes'], numberOfLikes => numberOfLikes + 1);
    case SET_COMMUNITY_POST_COMMENTS: {
      const posts = state.get('communityPosts').slice();
      for (let i = 0; i < posts.length; i += 1) {
        const post = { ...posts[i] };
        if (post.id === action.postId) {
          post.comments = action.comments;
          posts[i] = post;
          break;
        }
      }

      return state.setIn(['communityPosts'], posts);
    }
    case SET_COMMUNITY_NEW_COMMENT: {
      const posts = findPostAddComment(
        state.get('communityPosts'),
        action.comment.post_id,
        [action.comment],
        true,
        action.comment.parent_comment_id
      );
      return state.setIn(['communityPosts'], posts);
    }

    case SET_COMMUNITY_UPDATED_COMMENT: {
      const posts = findPostAndUpdateComment(state.get('communityPosts'), action.comment.post_id, action.comment);
      return state.setIn(['communityPosts'], posts);
    }
    case DELETE_COMMUNITY_COMMENT: {
      const postIndex = state.get('communityPosts').findIndex(post => post.id === action.deleteComment.postId);
      const comments = _.get(state.get('communityPosts'), `${postIndex}.comments`);
      const commentIndex = comments.findIndex(comment => comment.id === action.deleteComment.commentId);
      const replies = _.get(comments, `${commentIndex}.replies`, []);
      const nbOfReplies = replies.length + 1;
      return state
        .deleteIn(['communityPosts', postIndex, 'comments', commentIndex])
        .updateIn(['communityPosts', postIndex, 'numberOfComments'], numberOfComments => numberOfComments - nbOfReplies);
    }
    default:
      return state;
  }
};
