/**
 * Renders a list of offices using a flex display
 *
 * Usage:
 *  <OfficesGrid
 *    sectionInfo="You have 3 past bookings"
 *    items={ immutableCollection }
 *  />
 *
 * @class OfficesGrid
 * @module OfficesGrid
 */
import React from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import classnames from 'classnames';

import OfficeBox from '../officeBox/officeBox';
import OfficeCard from '../officeCard/officeCard';

import './css/index.scss';

class OfficesGrid extends React.Component {
  /**
   * Returns a list of items to render
   * @method getItemsToRender
   * @returns {Array<ReactElement>}
   */
  getItemsToRender() {
    return this.props.items.map(item => {
      return (
        <div className="officesGrid-item" key={item.get('id')}>
          <OfficeCard
            item={item.toJS()}
            translations={this.props.trans}
            officeTypesData={this.props.officeTypesData}
          />
        </div>
      );
    });
  }

  render() {
    const itemClass = classnames(['officesGrid', `officesGrid-${this.props.type}`, this.props.className]);

    return (
      <div className={itemClass} ref={r => (this.grid = r)}>
        <div className="officesGrid-container">
          <div className="officesGrid-gridItems">{this.getItemsToRender()}</div>
        </div>
      </div>
    );
  }
}

OfficesGrid.propTypes = {
  /**
   * Decides on the type of the grid (primary/secondary) which influences the bg color display
   * @param type
   * @type string
   */
  type: PropTypes.string,

  /**
   * Additional info to add to the right side of the section
   * @param sectionInfo
   * @type string
   */
  sectionInfo: PropTypes.string,

  /**
   * Collection of offices to render
   * @param offices
   * @type Immutable.List
   */
  items: PropTypes.object,

  /**
   * Flag to enable/disable header display
   * @property hideHeader
   */
  hideHeader: PropTypes.bool,
};

OfficesGrid.defaultProps = {
  type: 'principal',
  sectionInfo: '',
  items: Immutable.fromJS([]),
};

export default OfficesGrid;
