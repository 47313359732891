import _ from 'lodash';
import { Link, useHistory } from 'react-router-dom';
import React, { useState, useRef } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import buildLocationUrl from '../../../utils/buildLocationUrl';
import sizedImage from '../../../utils/imageHelper';
import Accordion from '../accordion/Accordion';
import CreateBookingTile from '../../myMeetings/tile/CreateBookingTile';
import { getWorkDays } from '../../../utils/weekDaysUtils';
import getLang from "../../../utils/lang";

const lang = getLang() !== null ? getLang() : 'en';

const Tile = props => {
  const { office, distanceLabel, translations, numberOfBookings, isWhiteLabel } = props;
  const titleRef = useRef();
  const [isExtended, setIsExtended] = useState(false);
  const history = useHistory();

  const officeName = _.get(office, 'name', '');
  const newTile = _.get(office, 'newTile', false);
  const bookings = _.get(office, 'bookings', []);
  const bookingsToShow = isExtended ? bookings : _.take(bookings, numberOfBookings);
  const officeImageUrl = _.get(office, 'imageUrl', null);
  const officeLink = buildLocationUrl(office);
  const officeId = _.get(office, 'id', null);

  const renderCheckinCheckout = booking => {
    const bookingId = _.get(booking, 'id', null);
    const canCheckout = _.get(booking, 'canCheckOut', null);
    const canCheckedIn = _.get(booking, 'canCheckIn', null);
    const canExtendBooking = _.get(booking, 'canExtendHour', null);

    if (canCheckout) {
      return (
        <div className="bookedOffice-checkout">
          <button
            className="main-button bookedOffice-checkinbutton"
            onClick={() => history.push({ pathname: `/booking/${bookingId}`, state: { showConfirmationPopup: 'checkout' } })}
          >
            {_.get(translations, 'notifications.booking.checkout_action', '')}
          </button>
          {canExtendBooking ? (
            <button
              className="secondary-button bookedOffice-checkinbutton-extend"
              onClick={() => history.push({ pathname: `/booking/${bookingId}`, state: { showConfirmationPopup: 'extend' } })}
            >
              {_.get(translations, 'notifications.booking.extend_action', '')}
            </button>
          ) : null}
        </div>
      );
    }
    if (canCheckedIn)
      return (
        <div className="bookedOffice-checkin">
          <button
            className="main-button bookedOffice-checkinbutton"
            onClick={() => history.push({ pathname: `/booking/${bookingId}`, state: { showConfirmationPopup: 'checkin' } })}
          >
            {_.get(translations, 'notifications.booking.checkin_action', '')}
          </button>
          <span
            className="bookedOffice-cancelBooking"
            onClick={() => history.push({ pathname: `/booking/${bookingId}`, state: { showConfirmationPopup: 'cancel' } })}
          >
            {_.get(translations, 'notifications.booking.cancel_action', '')}
          </span>
        </div>
      );
    return null;
  };

  const buildExpandableSection = (booking, isWhiteLabel) => {
    const productName = _.get(booking, 'product.name', '');
    const bookingId = _.get(booking, 'id', '');
    const bookingStatus = _.get(booking, 'status', '');

    const detailsLink = `/booking/${bookingId}`;

    const fromDate = _.get(booking, 'fromDate', 0);
    const toDate = _.get(booking, 'toDate', 0);
    const startPeriod = moment.unix(fromDate).utc();
    const endPeriod = moment.unix(toDate).utc();
    const title =
      startPeriod.format('MMM D YYYY') === moment(endPeriod).format('MMM D YYYY')
        ? `${startPeriod.locale(lang).format('MMM D YYYY')}`
        : `${startPeriod.locale(lang).format('MMM D YYYY')} - ${moment(endPeriod).locale(lang).format('MMM D YYYY')}`;
    const fromHour = _.get(booking, 'hours.from.hour', 0);
    const fromMinute = _.get(booking, 'hours.from.minute', 0);
    const toHour = _.get(booking, 'hours.to.hour', 0);
    const toMinute = _.get(booking, 'hours.to.minute', 0);
    const bookingHours = `${fromHour}:${fromMinute} - ${toHour}:${toMinute}`;

    const bookingFee = _.get(booking, 'totalPricePayable', 0);
    const weekDays = _.get(booking, 'weekDays', '');

    return (
      <div className="accordion-wrapper">
        <Accordion
          title={title}
          officeName={productName}
          officeId={officeId}
          officeLink={detailsLink}
          bookingHours={bookingHours}
          bookingFee={bookingFee}
          bookingId={bookingId}
          bookingStatus={bookingStatus}
          bookingDays={getWorkDays(weekDays, _.get(translations, 'location.weekDaysMap', []))}
          translations={translations}
        />
        {isWhiteLabel ? renderCheckinCheckout(booking) : null}
      </div>
    );
  };

  if (!officeName && newTile) {
    return (
      <CreateBookingTile
        title={_.get(translations, 'myBookings.startNewBooking', 'START  new booking')}
        subtitle={_.get(
          translations,
          'myBookings.chooseYourLocation',
          'Why not choose your work location, avoid traffic and explore the true meaning of flexible work?'
        )}
        buttonText={_.get(translations, 'myBookings.startNewBooking', 'START  new booking')}
      />
    );
  }

  const handleScrollTo = () => {
    titleRef.current.scrollIntoView({ behavior: 'smooth' });
  };
  return (
    <div className="meetings-tile" ref={titleRef}>
      <Link to={officeLink}>
        <img className="meeting-main-image" src={sizedImage(officeImageUrl, 417, 0)} alt="Office" />
      </Link>
      <div className="meetings-wrapper">
        <div className="office-title">
          {officeName} <span>({bookings.length})</span>
        </div>
        <div className="office-distance">{distanceLabel}</div>
        <div className="rooms-wrapper">{bookingsToShow.map(booking => buildExpandableSection(booking, isWhiteLabel))}</div>
        {bookings.length > numberOfBookings && (
          <button
            type="button"
            onClick={() => {
              if (isExtended) {
                handleScrollTo();
              }
              setIsExtended(!isExtended);
            }}
            className="entity-button meetings__extend-button"
          >
            {isExtended ? _.get(translations, 'myBookings.showLess', '') : _.get(translations, 'myBookings.showMore', '')}
          </button>
        )}
      </div>
    </div>
  );
};

Tile.propTypes = {
  office: PropTypes.shape({}).isRequired,
  heroProfile: PropTypes.shape({}).isRequired,
  distanceLabel: PropTypes.string,
};

Tile.defaultProps = {
  distanceLabel: '',
};

export default Tile;
