import api from '../../components/myMeetings/utils/api';
/*
 * Constants
 */
export const SET_STAFF_MEMBERS = 'SET_STAFF_MEMBERS';
export const SET_STAFF_MEMBERS_LOADING = 'SET_STAFF_MEMBERS_LOADING';

/*
 * Sync Actions
 */
export const setStaffMembers = members => ({
  type: SET_STAFF_MEMBERS,
  members,
});

export const setStaffMembersLoading = isLoading => ({
  type: SET_STAFF_MEMBERS_LOADING,
  isLoading,
});

/*
 * Async Actions
 */
export const fetchStaffMembers = (officeId, date) => dispatch => {
  dispatch(setStaffMembersLoading(true));
  api
    .get(`whitelabel/staff/date/${date}/officeid/${officeId}`)
    .then(({ data = {} }) => {
      if (data) {
        dispatch(setStaffMembers(data));
      }
    })
    .catch(() => {})
    .finally(() => {
      dispatch(setStaffMembersLoading(false));
    });
};
