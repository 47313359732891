import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { Link, withRouter } from 'react-router-dom';
import _ from 'lodash';
import classnames from 'classnames';
import ThirdPartyAuth from './thirdPartyAuth';
import SignUpForm from './signUpForm';
import Footer from './Footer';
import setCommunityUrlInLocalStorage from './utils/setCommunityUrlInLocalStorage';

const queryString = require('query-string');

class OpenSignUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showForm: false,
      isLoading: true,
    };
    this.handleEmailButtonClick = this.handleEmailButtonClick.bind(this);
  }

  componentDidMount() {
    const { history, validateInvitation, setIsWhiteLabel } = this.props;
    const queryParams = _.get(history, 'location.state.queryParams', null);
    if (queryParams) {
      const params = queryString.parse(queryParams);
      const invitationKey = _.get(params, 'invitation_key', null);
      if (invitationKey) {
        localStorage.setItem('invitation-key', invitationKey);
        validateInvitation({ invitationKey })
          .then(data => {
            const email = _.get(data, 'email', null);
            if (email) {
              localStorage.setItem('invitation-email', email);
              localStorage.setItem('first-name', _.get(data, 'firstName', null));
              localStorage.setItem('last-name', _.get(data, 'lastName', null));
              const isWhitelabel = _.get(data, 'isWhitelabel', 0);
              localStorage.setItem('invitation-whitelabel', isWhitelabel);
              if (isWhitelabel) {
                this.setState({ showForm: true });
                setIsWhiteLabel(true);
              }
              this.setState({ isLoading: false });
            }
          })
          .catch(() => {});
      }
      const meetingKey = _.get(params, 'meeting_attendee_key', null);
      if (meetingKey) {
        localStorage.setItem('meeting-attendee-key', meetingKey);
        const meetingId = _.get(history, 'location.state.from', null).split('/')[2];
        localStorage.setItem('meeting-id', meetingId);
        this.setState({ isLoading: false });
      }
    } else {
      this.setState({ isLoading: false });
    }
    if (localStorage.getItem('invitation-whitelabel') == 1) {
      this.setState({ showForm: true });
    }
    const historyState = _.get(history, 'location.state', null);
    if (_.startsWith(_.get(historyState, 'from', null), '/community')) {
      setCommunityUrlInLocalStorage(historyState);
    }
  }

  handleEmailButtonClick() {
    const { showForm } = this.state;
    if (!showForm) {
      this.setState({ showForm: true });
    }
  }

  render() {
    const { translations, history, location } = this.props;
    const { showForm, isLoading } = this.state;
    const signupTranslations = _.get(translations, 'signup', null);
    if (isLoading) {
      return <div className="Loader" />;
    }

    return (
      <Scrollbars className="styleScroll">
        <div className="open-login__container--full">
          <button type="button" onClick={() => history.goBack()} className="navigation__back-to-button">
            {_.get(translations, 'backToPreviousPage', 'Back to previous page')}
          </button>
          <div className="open-login__title">{_.get(signupTranslations, 'title', 'Create an account')}</div>
          <div className="open-login__subtitle">
            {_.get(
              signupTranslations,
              'subtitle',
              'You are seconds away to join our amazing community, book your first office and explore new opportunities.'
            )}
          </div>
          <div className={classnames('open-login__container--small', { 'open-login__container--centered': !showForm })}>
            {_.isNull(localStorage.getItem('invitation-whitelabel')) || localStorage.getItem('invitation-whitelabel') == 0 ? (
              <ThirdPartyAuth translations={signupTranslations} handleEmailButtonClick={this.handleEmailButtonClick} />
            ) : null}
            {showForm ? <SignUpForm translations={translations} /> : null}
            <div className="open-login__account-message">
              <div className="open-login__info-message">
                {_.get(signupTranslations, 'footerMessage', 'Do you already have an user account?')}
              </div>
              <Link
                to={{
                  pathname: '/login',
                  fromUrl: _.get(location, 'fromUrl', location.pathname),
                }}
                className="open-login__action-message"
              >
                {_.get(signupTranslations, 'footerAction', 'Log in')}
              </Link>
            </div>
          </div>
        </div>
        <Footer translations={translations} />
      </Scrollbars>
    );
  }
}

export default withRouter(OpenSignUp);
