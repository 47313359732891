/**
 * Renders the main map container alog with the Map element inside
 *
 * Usage:
 *   <MapContainer
 *     mapData={ this.props.mapData }
 *     markers={ availableMapItems.filter(office => office.get('position')) }
 *     onMarkerClick={ this.props.markerClick }
 *     onMarkerClose={ this.props.markerClose }
 *     activeMarker={ this.props.mapData.get('activeMarkerID') } />
 * @class MapContainer
 * @module MapContainer
 */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';

import Map from './map';
import MapInfo from './mapInfo';

import './css/index.scss';
import buildLocationUrl from "../../utils/buildLocationUrl";

class MapContainer extends Component {
  constructor(props) {
    super(props);
    this.onActionItem = this.onActionItem.bind(this);
  }

  /**
   * Dynamically handles navigating to a location page. This is required because all
   * infoWindows on map are rendered outside Router context
   * @method bookItem
   * @param {string} itemID
   */
  onActionItem(action, marker) {
    const { history } = this.props;
    if (action === 'book') {
      history.push(buildLocationUrl(marker.toJS()));
    }
  }

  render() {
    const minZoom = _.get(this.props, 'minZoom', null);
    return (
      <div className={`mapContainer ${this.props.className}`}>
        <Map
          containerElement={<div className="mapContainer-element" />}
          mapElement={<div className="mapContainer-map" />}
          showMessageModal={this.props.showMessageModal}
          onMapLoad={this.props.onMapLoad}
          mapData={this.props.mapData}
          setCenter={this.props.setCenter}
          officeTypesData={this.props.officeTypesData}
          geoLocationCoords={this.props.geoLocationCoords}
          markers={this.props.markers || this.props.mapData.get('markers')}
          onMarkerClick={this.props.onMarkerClick}
          onMarkerClose={this.props.onMarkerClose}
          onActionItem={this.onActionItem}
          activeMarker={this.props.activeMarker}
          onBoundsChanged={this.props.onBoundsChanged}
          scrWheel={this.props.scrWheel}
          draggable={this.props.draggable}
          trans={this.props.trans}
          minZoom={minZoom}
        />
        <div className="mapContainer-bottomInfo">{this.props.hideLegend ? null : <MapInfo trans={this.props.trans} />}</div>
      </div>
    );
  }
}

MapContainer.propTypes = {
  /**
   * Property that contains different map initialization and display info, including
   * markers and infoWindows data
   * @propery mapData
   * @type {Immutable.Map}
   * @required
   */
  mapData: PropTypes.object.isRequired,

  /**
   * The ID value of the current selected map marker
   * @propery activeMarker
   * @type {string}
   */
  activeMarker: PropTypes.number,

  /**
   * Callback method to handle clicking on a marker
   * @property onMarkerClick
   * @type {function}
   */
  onMarkerClick: PropTypes.func,

  /**
   * Callback method to handle closing the infoWindow for a marker
   * @property onMarkerClose
   * @type {function}
   */
  onMarkerClose: PropTypes.func,

  /**
   * Provides message modal action where context is not present
   * @property showMessageModal
   * @type {function}
   */
  showMessageModal: PropTypes.func,
};

MapContainer.defaultProps = {
  onMarkerClick: () => null,
  onMarkerClose: () => null,
};

export default withRouter(MapContainer);
