import Immutable from 'immutable';
import {
  SET_INVITE_FORM_IS_SUBMITTED,
  SET_MORE_SEARCHED_INVITE_HEROES, SET_MORE_SEARCHED_INVITE_HEROES_IS_LOADING,
  SET_SEARCHED_INVITE_HEROES,
  SET_SEARCHED_INVITE_HEROES_HAS_MORE,
  SET_SEARCHED_INVITE_HEROES_IS_LOADING,
} from '../../actions/community/invite-members-community';

const initialState = Immutable.Map({
  searchedHeroes: [],
  hasMore: false,
  isSubmitted: false,
  isLoading: false,
  loadMoreIsLoading: false,
});

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_SEARCHED_INVITE_HEROES:
      return state.setIn(['searchedHeroes'], action.searchedHeroes);
    case SET_SEARCHED_INVITE_HEROES_IS_LOADING:
      return state.setIn(['isLoading'], action.isLoading);
    case SET_MORE_SEARCHED_INVITE_HEROES:
      return state.updateIn(['searchedHeroes'], list => list.concat(action.searchedHeroes));
    case SET_MORE_SEARCHED_INVITE_HEROES_IS_LOADING:
      return state.setIn(['loadMoreIsLoading'], action.loadMoreIsLoading);
    case SET_SEARCHED_INVITE_HEROES_HAS_MORE:
      return state.setIn(['hasMore'], action.hasMore);
    case SET_INVITE_FORM_IS_SUBMITTED:
      return state.setIn(['isSubmitted'], action.isSubmitted);
    default:
      return state;
  }
};
