import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Truncate from 'react-truncate';
import _ from 'lodash';

import { withRouter } from 'react-router-dom';
import buildTags from '../../utils/build-tags';
import buildUserProfilePicture from '../../utils/build-user-profile-picture';
import blockIncompleteProfileAction from '../../../../utils/blockIncompleteProfileAction';

const redirectToConnectionsPage = () => {
  window.location.href = '/connections';
};

class HeroRecommendation extends Component {
  constructor(props) {
    super(props);
    this.goToDirectChat = this.goToDirectChat.bind(this);
    this.getConnectionUrl = this.getConnectionUrl.bind(this);
  }

  componentDidMount() {
    const { fetchHeroRecommendation } = this.props;
    fetchHeroRecommendation();
  }

  getConnectionUrl(hero) {
    const { history } = this.props;
    history.push(`/connection/${_.get(hero, 'id')}`);
  }

  goToDirectChat(hero) {
    const { openConversation, history } = this.props;
    openConversation(_.get(hero, 'id', null), _.set(hero, 'profilePictureUrl', _.get(hero, 'picture', '')));
    history.push('/direct-chat');
  }

  render() {
    const { heroRecommendation, history, translations } = this.props;
    const picture = _.get(heroRecommendation, 'picture', null);
    const name = _.get(heroRecommendation, 'name', '');
    const firstName = _.get(heroRecommendation, 'firstName', '');
    const title = _.get(heroRecommendation, 'title', '');
    const currentVerticals = _.get(heroRecommendation, 'verticals.current', []);
    const interestedVerticals = _.get(heroRecommendation, 'verticals.interested', []);
    const heroInitials = _.get(heroRecommendation, 'heroInitials', null);

    return (
      <div ref={this.containerRef} className="hero-recommendation__container">
        <div className="hero-recommendation__header">
          <div className="hero-recommendation-profile" onClick={this.getConnectionUrl.bind(this, heroRecommendation)}>
            {picture ? (
              buildUserProfilePicture(picture, null, 'hero-recommendation-profile__picture-container')
            ) : (
              <div className="recommended-hero-initials hero-initials">{heroInitials}</div>
            )}
            <div className="hero-recommendation-profile__information">
              <h5 className="hero-recommendation-profile__name">{name}</h5>
              <p className="hero-recommendation-profile__title">
                <Truncate lines={2} ellipsis={<>...</>}>
                  {title}
                </Truncate>
              </p>
            </div>
          </div>
          <button
            type="button"
            className="dashboard-button dashboard-button--border"
            onClick={event => {
              event.stopPropagation();
              blockIncompleteProfileAction()
                .then(() => {
                  history.push({ pathname: '/account/profile/incomplete' });
                })
                .catch(() => {
                  this.goToDirectChat(heroRecommendation);
                });
            }}
          >
            {_.get(translations, 'introduceMe', 'Introduce Me')}
          </button>
        </div>
        <div className="hero-recommendation__tags">
          <h5 className="card__subtitle">{`${firstName}${_.get(translations, 'verticals', `'s verticals`)}`}</h5>
          {buildTags(currentVerticals, 'hero-recommendation__vertical-tags', translations)}
          <h5 className="card__subtitle">{`${firstName}${_.get(translations, 'lookingFor', `'s looking for`)}`}</h5>
          {buildTags(interestedVerticals, 'hero-recommendation__vertical-tags', translations)}
        </div>
        <div className="hero-recommendation__footer">
          <button type="button" className="dashboard-button" onClick={redirectToConnectionsPage}>
            {_.get(translations, 'discoverTheNetwork', 'Discover the Network')}
          </button>
        </div>
      </div>
    );
  }
}

HeroRecommendation.propTypes = {
  fetchHeroRecommendation: PropTypes.func.isRequired,
  heroRecommendation: PropTypes.shape({}).isRequired,
  history: PropTypes.shape({}),
  openConversation: PropTypes.func,
};
HeroRecommendation.defaultProps = {
  history: {},
  openConversation: () => null,
};

export default withRouter(HeroRecommendation);
