import React, {Component} from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import onClickOutside from 'react-onclickoutside';
import NotificationItem from './notificationItem'
import './css/notifications.scss';
import {Scrollbars} from 'react-custom-scrollbars';

class Notifications extends Component {

    constructor(props) {
        super(props);

        this.state = {
            page: 1,
            notificationHeight: 150
        };
        this.updateDimensions = this.updateDimensions.bind(this);
    }

    componentDidMount() {
        window.addEventListener("resize", this.updateDimensions);

    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
    }

    componentDidUpdate = () => {
        if (this.props.refreshNotifications === true) {
            this.props.fetchNotifications();
            this.props.getUnreadNotificationsCount();
        }
    }

    updateDimensions() {
        let notificationHeight = this.props.notificationsData.length > 4 ? 564 : ((this.props.notificationsData.length * 141));

        let documentHeight = document.documentElement.clientHeight;

        if ((notificationHeight + 150) > documentHeight) {
            notificationHeight = documentHeight - 150;
        }

        if (notificationHeight > 0 && notificationHeight !== this.state.notificationHeight) {
            this.setState({notificationHeight});
        }
    }

    handleClickOutside = evt => {
        if (this.props.notificationClicked)
            this.props.onNotificationClick(evt);
    };

    UNSAFE_componentWillMount() {
        this.updateDimensions();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.updateDimensions();
        if (nextProps.refreshNotifications === true) {
            this.props.fetchNotifications();
        }
    }

    onNotificationClick = () => {
        this.props.triggerRefreshNotifications();
        this.props.onNotificationClick();
    };

    getNotifications() {
        if (!this.props.notificationsData.length) {
            return null;
        }
        return this.props.notificationsData.map((item) => (
            <NotificationItem
                key={_.get(item, 'id', null)}
                notificationId={_.get(item, 'id', null)}
                type={_.get(item, 'type', null)}
                content={_.get(item, 'content', null)}
                seen={_.get(item, 'seen', null)}
                notificationDate={_.get(item, 'date', null)}
                markOneAsRead={this.props.markOneAsRead}
                hideNotifications={this.props.hideNotifications}
                trans={this.props.trans}
            />
        ));
    }

    getNotificationIcon() {
        let icon = null;
        if (this.props.notificationsCount > 0) {
            icon = <span id="hero-notifications-icon" className="notifications-icon notifications-icon-on" onClick={this.onNotificationClick}>{this.props.notificationsCount}</span>
        } else {
            icon = <span id="hero-notifications-icon" className="notifications-icon" onClick={this.onNotificationClick}></span>
        }
        return icon;
    }

    changePage = () => {
        this.setState({page: this.state.page+1}, () => {
            this.props.fetchNotifications(this.state.page, 10);
        })
    }

    handleScrollStop(scroll) {
        var scrollValues = scroll.refs.scrollbars.getValues();
        if (scrollValues.top === 1) {
            this.changePage();
        }
    }

    render() {
        const clicked = (this.props.notificationClicked ? ' notifications-active' : '');

        let trans = this.props.trans;
        if (trans === null) return null;

        return (
            <div className={'notifications' + clicked}>
                {this.getNotificationIcon()}
                <div className="notifications-results">
                    <div className="notifications-results-header">

                        <span>{_.get(trans, 'header.notifications.notificationLabel', '')}</span>
                        <a className="markAllAsRead" onClick={() => {
                            this.props.markAsRead();
                            return false;
                        }}>{_.get(trans, 'header.notifications.markAllAsRead', '')}</a>

                    </div>
                    <Scrollbars
                        style={{height: (this.state.notificationHeight + 28) + 'px'}}
                        ref="scrollbars"
                        onScrollStop={function () {
                            this.handleScrollStop(this)
                        }.bind(this)}
                    >
                        <ul>
                            {this.getNotifications()}
                            <li className="noNotifications">{_.get(trans, 'header.notifications.noNotifications', '')}</li>
                        </ul>
                    </Scrollbars>
                </div>
            </div>
        );
    }
};

export default withRouter(onClickOutside(Notifications));
