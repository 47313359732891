/**
 * Handles async data loading, general data that may need to be cached for the future that is why is separated
 * @class fetchGeneralDataMiddleware
 */

import {
  FETCH_OFFICE_TYPES,
  FETCH_AMENITIES,
  FETCH_SERVICES,
  FETCH_HOUSE_RULES,
  FETCH_SKILLS,
  FETCH_INDUSTRIES,
  FETCH_COUNTRY_CODES,
  FETCH_COUNTRIES,
} from '../../actions/generalDataActions';
import { GLOBAL_SEARCH, GLOBAL_SEARCH_HEROES, GET_TRANSLATIONS, CHECK_SUBDOMAIN, FETCH_APP_TIMEZONE } from '../../actions/global';
import processResponse from '../utils/processResponse';

const fetchGeneralDataMiddleware = store => next => action => {
  switch (action.type) {
    case FETCH_AMENITIES:
    case FETCH_OFFICE_TYPES:
    case FETCH_SERVICES:
    case FETCH_HOUSE_RULES:
    case FETCH_SKILLS:
    case FETCH_INDUSTRIES:
    case FETCH_COUNTRY_CODES:
    case FETCH_COUNTRIES:
    case FETCH_APP_TIMEZONE:
      fetch(action.url, { headers: { 'access-key': localStorage.getItem('access-key') } }).then(response => {
        processResponse(response).then(data => {
          action.data = data;
          next(action);
        });
      });
      break;
    case GLOBAL_SEARCH:
      if (action.term !== '') {
        fetch(`${action.url}?q=${encodeURIComponent(action.term)}&items[]=offices&items[]=heroes&items[]=cities`, {
          headers: { 'access-key': localStorage.getItem('access-key') },
        }).then(response => {
          processResponse(response).then(data => {
            action.data = data;
            next(action);
          });
        });
      } else {
        action.data = null;
        next(action);
      }

      break;
    case GLOBAL_SEARCH_HEROES:
      if (action.term !== '') {
        fetch(`${action.url}?q=${encodeURIComponent(action.term)}&items[]=heroes&allProfiles=${action.allProfiles}`, {
          headers: { 'access-key': localStorage.getItem('access-key') },
        }).then(response => {
          processResponse(response).then(data => {
            action.data = data;
            next(action);
          });
        });
      } else {
        action.data = null;
        next(action);
      }

      break;
    case GET_TRANSLATIONS:
      fetch(`${action.url}?lang=${action.lang}`, { headers: { 'access-key': localStorage.getItem('access-key') } }).then(response => {
        processResponse(response).then(data => {
          action.data = data;
          next(action);
        });
      });
      break;
    case CHECK_SUBDOMAIN:
      fetch(`${action.url}?subdomain=${action.subdomain}`, { headers: { 'access-key': localStorage.getItem('access-key') } }).then(
        response => {
          processResponse(response).then(data => {
            action.data = data;
            next(action);
          });
        }
      );
      break;
    default:
      next(action);
  }
};

export default fetchGeneralDataMiddleware;
